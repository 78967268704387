import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import UploadStoreProfile from '../components/UploadStoreProfile';
import VendorSelection from '../components/VendorSelection'
import StoreDesc from '../components/StoreDesc';
import api from '../helpers/apiClient';
import * as merchantStoreActionCreators from '../redux/actions/merchantStore';
import _ from 'lodash'
import handleize from '../helpers/handleize';
import TabHeader from '../components/TabHeader';
import store_link from '../images/store-link.svg'
import store_link_sample from '../images/store-link-sample.png';
import Clipboard from 'clipboard';
import GTRAlert from '../components/GTRAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ReactTooltip from 'react-tooltip';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import BootstrapTable from 'react-bootstrap-table-next';
import DeliveryFinePrintsEditModal from '../components/DeliveryFinePrintsEditModal';
import { removeInlineStyles, removeEntities, removeBlockTypes } from '../helpers/draftjsClearFormat';

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

const ClearFormatButton = ({clearFormat}) => {
  return (
    <Button variant="secondary" size="sm" className="mb-2" onClick={clearFormat}>Clear Format</Button>
  )
}

class Store extends Component {
  constructor(props) {
    super(props);

    const { user } = props
    let vendor = user.merchantId;

    if(user.role === 'admin' || user.role === 'staff'){
        vendor = 'Giftr'
    }

    this.state = {
        vendor,
        vendorList: [],
        item: {},
        savingChanges: false,
        submitting: false,
        deletingImage: false,
        editRedirect: false,
        redirectPath: '',
        alert: '',
        alertType: '',
        deliveryFinePrintsEditorState: EditorState.createEmpty(),
        deliveryFinePrintsName: ''

    };
  }

  componentDidMount() {
    const { fetchMerchantStore, submitting } = this.props;
    const { vendor } = this.state;

    const vendorList = vendor.split('#,');
    this.setState({submitting , vendorList})

    fetchMerchantStore(vendorList.length > 1 ? vendorList[0] : vendor)

    let clipboard = new Clipboard('.copy-store-link');

    clipboard.on('success', function(e) {

      e.trigger.innerText = 'Copied'
      e.clearSelection();
    });
  }

  componentWillReceiveProps(nextProps) {
    const { merchantStore: prevMerchantStore } = this.props;
    const { merchantStore } = nextProps;
    if (merchantStore !== prevMerchantStore) {
      if (merchantStore.item && merchantStore.item.store_redirect_id) {
        this.setState({ redirectPath: merchantStore.item.store_redirect_path.substring(1) })
      }
    }
  }

  handleOnVendorChange = (e) => {
    const { fetchMerchantStore } = this.props;
    this.setState({ vendor: e.target.value });

    fetchMerchantStore(e.target.value);
  }

  onDropImage(files) {
    if(files && files.length > 0){
      this.setState({
        file: files[0],
        image_url: files[0].preview,
        submitting: false
      });
    }

  }

  handleSaveStoreLogo = async (file) =>  {
    const { vendor } = this.state;

    var formData = new FormData();
    formData.append('file', file);
    formData.append('filename', `${vendor}_logo`);
    formData.append('no_shorten', true);
    return api(`/file/upload/store_profile${country === 'SG' ? '_sg' : ''}`, {
      method: 'POST',
      body: formData
    }, {'Accept': 'application/json'}).then(async data => {
      return api(`/merchants/store/${vendor}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          store_logo: data.file_short_url
        })
      }).then(() => {
        this.setState({
          savingChanges: true
        });
      })
      .catch(submitErr => {
        this.setState({
          savingChanges: false
        });
        console.log(submitErr)
      })
    }).catch(err => {
      this.setState({
        savingChanges: false
      });
      console.log(err)
    })

  }

  deliveryFinePrintsEditorChange = deliveryFinePrintsEditorState => {

    this.setState({ deliveryFinePrintsEditorState, savingChanges: false });
  }

  handleSaveStoreBanner = async (file) =>  {
    const { vendor, } = this.state;

    var formData = new FormData();
    formData.append('file', file);
    formData.append('filename', `${vendor}_banner`);
    formData.append('no_shorten', true);
    return api(`/file/upload/store_profile${country === 'SG' ? '_sg' : ''}`, {
      method: 'POST',
      body: formData
    }, {'Accept': 'application/json'}).then(async data => {
      return api(`/merchants/store/${vendor}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          store_banner: data.file_short_url
        })
      }).then(() => {
        this.setState({
          savingChanges: true
        })
      })
      .catch(submitErr => {
        this.setState({
          savingChanges: false
        });
        console.log(submitErr)
      })
    }).catch(err => {
      this.setState({
        savingChanges: false
      });
      console.log(err)
    })

  }

  handleSaveDesc = (value) => {
    const { vendor } = this.state

    api(`/merchants/store/${vendor}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        store_desc: value
      })
    }).then(() => {
      this.setState({
        savingChanges: true
      })
    }).catch(err => {
      this.setState({
        savingChanges: false
      });
      console.log(err)
    })
  }

  handleAddNewDeliveryFinePrints = () => {
    const { vendor, deliveryFinePrintsEditorState, deliveryFinePrintsName } = this.state
    const { fetchMerchantStore, deliveryFinePrintsEditName } = this.props;

    this.setState({
      savingChanges: true
    })

    let html = stateToHTML(deliveryFinePrintsEditorState.getCurrentContent())

    //check if html contains "Delivery Fine Prints:"
    if(html.length > 0 && html.indexOf('Delivery Fine Prints:') === -1){
      //bold the first line
      html = `<p><strong>Delivery Fine Prints:</strong></p>${html}`
    }

    api(`/merchants/store/${vendor}/delivery_fine_prints`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content: html,
        name: deliveryFinePrintsName
      })
    }).then(result => {
      this.setState({
        deliveryFinePrintsName: '',
        deliveryFinePrintsEditorState: EditorState.createEmpty()
      })

      const vendorList = vendor.split('#,')
      fetchMerchantStore(vendorList.length > 1 ? vendorList[0] : vendor)

    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.setState({
        savingChanges: false
      });
    })
  }

  async showConfirmDelete(id) {
    //show prompt to confirm delete
    let result = await confirm("Are you sure you want to delete this delivery fine prints?")

    if (result) {
      this.handleDeleteDeliveryFinePrints(id)
    }
  }

  handleDeleteDeliveryFinePrints = (id) => {
    const { vendor } = this.state
    const { fetchMerchantStore } = this.props;

    api(`/merchants/store/${vendor}/delivery_fine_prints/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(result => {

      const vendorList = vendor.split('#,')
      fetchMerchantStore(vendorList.length > 1 ? vendorList[0] : vendor)

    }).catch(err => {
      console.log(err)
    })
  }

  handleDeleteLogo = () => {
    const { vendor } = this.state

    return api(`/merchants/store/${vendor}/store_logo`, {
      method: 'DELETE'
    }).then(result => {
      if (result.errors){
        this.setState({
          deletingImage: true
        })
      }
    }).catch(err => {
      this.setState({
        deletingImage: false
      })
      console.log(err);
    })
  }

  handleDeleteBanner = () => {
    const { vendor } = this.state

    return api(`/merchants/store/${vendor}/store_banner`, {
      method: 'DELETE'
    }).then(result => {
      if (result.errors){
        this.setState({
          deletingImage: true
        })
      }
    }).catch(err => {
      this.setState({
        deletingImage: false
      })
      console.log(err);
    })
  }

  handleEditRedirect = async () => {
    const { vendor } = this.state;

    let body = {}

    return api(`/merchants/redirect/${vendor}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).then(() => {
      this.setState({
        savingChanges: true
      })
    }).catch(err => {
      this.setState({
        savingChanges: false
      });
      console.log(err)
    })
  }

  onClickEditRedirect = () => {

    this.setState({ editRedirect: true, editRedirectPath: this.state.redirectPath });
  }

  onChangeRedirectPath = e => {
    const { value } = e.target

    this.setState({ editRedirectPath: value })
  }

  onClickSaveRedirect = async () => {
    const { merchantStore: { item } } = this.props;
    const { editRedirectPath, vendor } = this.state;

    let body = {
      path: `/${editRedirectPath.substring(0, 20)}`,
      redirect_id: item.store_redirect_id,
    }

    return api(`/merchants/redirect/${vendor}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).then(() => {
      this.setState({ editRedirect: false, redirectPath: editRedirectPath, alert: "Saved", alertType: 'success' })
      setTimeout(() => {
        this.setState({ alert: "" })
      }, 5000)
    }).catch(err => {
      console.log(err)
      if(err.path){
        this.setState({ alert: "This URL link has been taken. Please try again.", alertType: 'danger' })
        setTimeout(() => {
          this.setState({ alert: "" })
        }, 5000)
      }
    })
  }

  getContentState = (html) => {
    const { contentBlocks, entityMap } = convertFromHTML(html ? html.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '') : '')
    return ContentState.createFromBlockArray(contentBlocks, entityMap)
  }

  onChangeDeliveryFinePrintsName = e => {
    const { value } = e.target

    this.setState({ deliveryFinePrintsName: value })
  }

  handleOnCloseDeliveryFinePrintsModal = () => {
    this.setState({ showDeliveryFinePrintsEditModal: false })
  }

  showDeliveryFinePrintsModal = async (row) => {

    this.setState({
      loadingDFPProductCount: true
    })

    const response = await api(`/dfp/product_count/${row._id}`);
    const { count } = response

    this.setState({
      showDeliveryFinePrintsEditModal: true,
      deliveryFinePrintsEditContent: row.content,
      deliveryFinePrintsEditName: row.name,
      deliveryFinePrintsEditId: row._id,
      deliveryFinePrintEditProductCount: count,
      loadingDFPProductCount: false
    })
  }

  renderDeleteButton = (cell, row) => {

    return <>
      <p>
        <Button
          bsStyle="danger"
          id={`${cell}`}
          onClick={() => {this.showConfirmDelete(cell)}}
        >Delete</Button></p>
      <p>
        <Button
          bsStyle="primary"
          id={`${cell}`}
          onClick={() => {this.showDeliveryFinePrintsModal(row)}}
        >Edit</Button>
      </p>
    </>
  }

  renderContent = (cell, row) => {
    //set max height to 500px to prevent overflow of content in table cell and make it scrollable
    return <div style={{ maxHeight:"500px", overflowY:"auto", overflowX:"hidden" }} dangerouslySetInnerHTML={{__html: cell}}></div>
  }

  handleOnSaveDeliveryFinePrints = (content, name) => {
    const { vendor, deliveryFinePrintsEditId } = this.state
    const { fetchMerchantStore } = this.props;

    api(`/merchants/store/${vendor}/delivery_fine_prints/${deliveryFinePrintsEditId}`, {
      method: 'POST',
      body: JSON.stringify({ content, name }),
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(result => {

      const vendorList = vendor.split('#,')
      fetchMerchantStore(vendorList.length > 1 ? vendorList[0] : vendor)

    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.setState({
        showDeliveryFinePrintsEditModal: false
      })
    })
  }

  editorClearFormat = () => {
    const { deliveryFinePrintsEditorState } = this.state

    const stateWithoutFormat = _.flow([
      removeInlineStyles,
      removeEntities,
      removeBlockTypes,
    ])(deliveryFinePrintsEditorState)

    this.setState({ deliveryFinePrintsEditorState: stateWithoutFormat })
  }

  render() {
    const { merchantStore: { loading, loaded, item } , user } = this.props
    const { savingChanges, vendor, vendorList, errorMessage, deletingImage, editRedirect, redirectPath,
      editRedirectPath, alert, alertType, deliveryFinePrintsEditorState, deliveryFinePrintsName,
      showDeliveryFinePrintsEditModal, deliveryFinePrintsEditContent, deliveryFinePrintsEditName,
      deliveryFinePrintEditProductCount, deliveryFinePrintsEditId } = this.state

    const isAdmin = user.role === 'admin' || user.role === 'staff'

    const storeLink = `giftr.${country.toLowerCase()}${redirectPath ? `/${redirectPath}` : (item ? item.store_redirect_path : '' )}`
    const canEditLink = user.role === 'admin' || user.role === 'merchant' || user.role === 'merchant-evouchers'

    const selectRowProp = {
      mode: 'radio',
      clickToSelect: true,
      selected: [1],
      // onSelect: this.onRowSelect,
      headerColumnStyle: { width: '30px' }
    };

    const ordersCol = [
      {
        dataField: 'name',
        text: 'Name',
        headerStyle: {
          width: '120px'
        },
      },
      {
        dataField: 'content',
        text: 'Content',
        formatter: this.renderContent,
      },
      {
        dataField: '_id',
        text: 'Actions',
        formatter: this.renderDeleteButton,
        headerStyle: {
          width: '100px'
        },
      }
    ]

    return (
      <div>
        {
          loading &&
            <Loader />
        }
        {
          alert &&
            <GTRAlert
              className="position-fixed translate-middle-x"
              style={{ zIndex: '2000', left: '50%', top: '10px' }}
              variant={alertType}
              alert={alert}/>
        }
        {
          !loading && loaded ?
            <>
              <TabHeader title={<>Store Profile - <span style={{color: '#80D7CF'}}>{vendor}</span></>} tab_id="store" user={user} />
              <Row className="align-items-end">
                <div className="col-xs-12 col-md-9 d-grid gap-1 store-link-btns" style={{gridTemplateColumns: 'repeat(4, max-content)'}}>
                  <a href={`https://${storeLink}`} target="_blank" className="btn-br-6 d-print-none btn btn-primary btn-br-6">View Live</a>
                  {
                    !editRedirect &&
                      <>
                        <div className="input-group">
                          <span className="input-group-text py-1 px-2" id="basic-addon1" style={{backgroundColor: '#81d8d0', color: "white"}}>
                            Store URL
                            <img src={store_link} height="20px" style={{marginLeft: "5px"}}/>
                          </span>
                          <input
                            type="text"
                            style={{backgroundColor: 'white'}}
                            className="form-control"
                            disabled={true}
                            aria-label="Store Link"
                            value={storeLink}
                            aria-describedby="basic-addon1"/>
                        </div>
                        <Button variant="secondary" className="copy-store-link" data-clipboard-text={storeLink}>Copy</Button>
                        {
                          canEditLink &&
                            <Button variant="secondary" onClick={this.onClickEditRedirect}>
                              Edit
                              <i className="ms-1" data-html={true} data-tip={`<div style="max-width: 500px"><img src="${store_link_sample}" /><p>Store URL is a shortened link to your online store at Giftr and it's shareable on your social media pages as website link (if you don't have own online store) so that your customers can place order themselves all-day 24/7 :)</p></div>`} data-for="link-edit"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="bottom" effect="solid" id="link-edit" />
                            </Button>
                        }
                      </>
                  }
                  {
                    editRedirect &&
                      <>
                        <div className="input-group">
                          <span className="input-group-text py-1 px-2" id="basic-addon1" style={{backgroundColor: '#81d8d0', color: "white"}}>
                            Store URL
                            <img src={store_link} height="20px" style={{marginLeft: "5px"}}/>
                          </span>
                          <span className="input-group-text p-1" id="basic-addon1">
                            giftr.{country.toLowerCase()}/
                          </span>
                          <input
                            type="text"
                            autoFocus
                            maxLength={20}
                            className="form-control"
                            aria-label="Store Link"
                            value={editRedirectPath}
                            onChange={this.onChangeRedirectPath}
                            aria-describedby="basic-addon1"/>
                        </div>
                        <Button variant="secondary" onClick={this.onClickSaveRedirect}>Save</Button>
                        <Button variant="secondary" onClick={() => {this.setState({ editRedirect: false })}}>Cancel</Button>
                      </>
                  }
                </div>
                {
                  isAdmin &&
                    <div className="col-xs-12 col-md-3" style={{textAlign: 'right'}} >
                      <VendorSelection handleOnVendorChange={this.handleOnVendorChange} selectedVendor={vendor} skipDefaultOption={true}/>
                    </div>
                }
                {
                  vendorList.length > 1 &&
                    <div className="col-xs-12 col-md-3">
                      <div className="vendor-selection">
                        <label style={{marginBottom: '5px'}}>Vendor</label>
                        <Form.Select className="form-control" onChange={this.handleOnVendorChange} value={vendor}>
                          {
                            vendorList.map((option, ind) => {
                              return (
                                <option key={ind} value={option}>{option}</option>
                              )
                            })
                          }
                        </Form.Select>
                      </div>
                    </div>
                }
                <div className="col-xs-12 col-md-9">
                </div>
              </Row>
              <Row style={{marginTop: '30px'}}>
                <Col xl={6} className="d-grid gap-4">
                  <Card className="tealCard shadow-sm">
                    <Card.Header className="tealCardHeader">Store Logo</Card.Header>
                    <Card.Body className="mx-2" style={{color: '#5A5A5A'}}>
                      <p>Recommended image resolution: 200 x 200 px, PNG format, maximum size 1 MB.</p>
                      <UploadStoreProfile image_url={item.store_logo} submitting={savingChanges} deleting={deletingImage} onClickSave={this.handleSaveStoreLogo} onClickDelete={this.handleDeleteLogo.bind(this)} aspectRatio={1}/>
                    </Card.Body>
                  </Card>
                  <Card className="tealCard shadow-sm">
                    <Card.Header className="tealCardHeader">Store Banner</Card.Header>
                    <Card.Body className="mx-2" style={{color: '#5A5A5A'}}>
                      <p>Recommended image resolution: 1170 x 300 px, PNG format, maximum size 1 MB.</p>
                      <UploadStoreProfile image_url={item.store_banner} submitting={savingChanges} deleting={deletingImage} onClickSave={this.handleSaveStoreBanner} onClickDelete={this.handleDeleteBanner.bind(this)} aspectRatio={39/10}/>
                    </Card.Body>
                  </Card>
                </Col>
                <div className="vr d-none d-xl-block col-xl-1 dashboard-vr" style={{maxHeight: '30em'}}></div>
                <Col>
                  <Card className="tealCard shadow-sm mt-4 mt-lg-0">
                    <Card.Header className="tealCardHeader">Store Description</Card.Header>
                    <Card.Body className="mx-2" style={{color: '#5A5A5A'}}>
                      <StoreDesc store_desc={item.store_desc} submitting={savingChanges} onClickSave={this.handleSaveDesc.bind(this)}/>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

            </>
            : null
        }
        {
          showDeliveryFinePrintsEditModal &&
          <DeliveryFinePrintsEditModal dfpId={deliveryFinePrintsEditId} count={deliveryFinePrintEditProductCount} content={deliveryFinePrintsEditContent} name={deliveryFinePrintsEditName} onClose={this.handleOnCloseDeliveryFinePrintsModal} onSaved={this.handleOnSaveDeliveryFinePrints} />
        }
      </div>
    );
  }
}


export default connect((state, props) => {
  const { match } = props;
  return {
    merchantStore: state.merchantStore,
    user: state.auth.user
  };
}, {
  ...merchantStoreActionCreators
})(Store);
