import { 
  FETCH_ORDERS_REQUEST, 
  FETCH_ORDERS_SUCCESS, 
  FETCH_ORDERS_FAILURE, 
  CLEAR_ORDERS,
  UPDATE_ORDERS
} from '../actions/orders';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
  merchantId: null, 
  financial_status: null, 
  fulfillment_status : null, 
  limit: null, 
  next: null
};

export default function orders(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ORDERS:
      return {
        ...state,
        defaultState
      };
    case UPDATE_ORDERS:
      return {
        ...state,
        items: [...action.payload],
      };
    case FETCH_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
        next: action.payload.next
      };
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}