import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import _ from 'lodash';

const EditCustomisationModal = ({ onClose, editProperties = [], onSave }) => {
  const [loading, setLoading] = useState(false);
  
  const onSubmit = (e) => {
    const form = new FormData(e.target);
    const entries = _.filter(Array.from(form.entries()), entry => {
      let property = _.find(editProperties, property => property.name == entry[0])
      return property && property.value != entry[1]
    })
    e.preventDefault();
    setLoading(true);

    onSave(entries)
  }

  return (
    <Modal style={{ top: '15%', border: 'none' }} show={true} onHide={onClose}>
      <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
        <h4 className="mb-0">Edit Customisation</h4>
      </Modal.Header>
      <form className="my-2" onSubmit={onSubmit}>
        <Modal.Body className="mx-4 px-0 py-4" style={{ borderTop: '1px solid #dee2e6' }}>
          {editProperties.length > 0 &&
            <>
              {editProperties.map(({ name, value }) => (
                <div className="form-group" key={name}>
                  <label htmlFor={name}>{name}</label>
                  <input type="text" className="form-control" id={name} name={name} defaultValue={value} />
                </div>
              ))}
              <p className="small mt-2 mb-0"><i>*To reset a value, leave the field blank</i></p>
            </>
          }
          { editProperties.length == 0 &&
          <span>No customisations</span>
          }
        </Modal.Body>
        { editProperties.length > 0 &&
        <Modal.Footer className="pt-0" style={{ borderTop: 'none' }}>
          <Button variant="danger-secondary" onClick={onClose} disabled={loading}>Cancel</Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
        }
      </form>
    </Modal>
  )
}

export default EditCustomisationModal