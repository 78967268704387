import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ToggleButton, ToggleButtonGroup, Card, Button } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import _ from 'lodash';
import { SingleDatePicker } from 'react-dates';
import "react-dates/lib/css/_datepicker.css";

import { fetchPickups } from '../redux/actions/pickups';
import Loader from '../components/Loader'

const PrintPickupHandoverModal = ({ onClose, shipments }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { items: pickups, loading, loaded } = useSelector(state => state.pickups);
  const isAdmin = user && (user.role === 'admin' || user.role === 'staff')

  const [pickupType, setPickupType] = useState('');
  const [selectedPickup, setSelectedPickup] = useState(null);
  const [pickupDate, setPickupDate] = useState(null);
  const [focused, setFocused] = useState(null);
  const [clickedPrint, setClickedPrint] = useState(false);

  useEffect(() => {
    if (pickupType === 'jobs') {
      let start_date = moment().add(-1, 'weeks').format('DD-MM-YYYY');
      dispatch(fetchPickups(start_date));
    }
  }, [pickupType])

  const handleChangePickupType = (val) => {
    setSelectedPickup(null);
    setPickupDate(null);
    setClickedPrint(false);
    setPickupType(val);
  }

  const dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = "2010-01-01";
    }

    if (!date2) {
      date2 = "2010-01-01";
    }

    if (order === "desc") {
      return moment(date2, "YYYY-MM-DD").unix() - moment(date1, "YYYY-MM-DD").unix();
    } else {
      return moment(date1, "YYYY-MM-DD").unix() - moment(date2, "YYYY-MM-DD").unix();
    }
  };

  const renderDate = (cell) => {

    if(cell){
      return (
        <div>{moment(cell, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>
      )
    }
  }

  const renderShipments = (cell, row) => {
    if (row.shipments.length) {
      const shipment_lines = _.map(row.shipments, shipment => {
        return `${shipment.orderName} - ${shipment.awb}`
      })

      return (
        <span>{cell} <i data-html={true} data-tip={`
            <p>
              ${shipment_lines.join('<br/>')}
            </p>
        `} data-for="shipment-lines"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="bottom" effect="solid" id="shipment-lines" />
        </span>
      )
    }
  }

  const renderAddress = (cell) => {
    return (
      <span>{cell.pick_name} <i data-html={true} data-tip={`
          <p>
            ${cell.pick_name}<br/>
            ${cell.pick_addr1}<br/>
            ${cell.pick_addr2}<br/>
            ${cell.pick_postcode}, ${cell.pick_city}<br/>
            ${cell.pick_state}, ${cell.pick_country}<br/>
            ${cell.pick_contact}<br/>
          </p>
      `} data-for="inventory-header"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="bottom" effect="solid" id="inventory-header" />
      </span>
    )
  }

  const onClickPrint = () => {
    setClickedPrint(true);
    let shipment_ids = _.map(shipments, "id").join(',')

    if (pickupType === 'jobs' && shipment_ids && selectedPickup) {
      window.open(location.origin + `/pickup_handover?shipment_ids=${shipment_ids}&pickup_id=${selectedPickup}`);
    }

    if (pickupType === "custom" && shipment_ids && pickupDate) {
      window.open(location.origin + `/pickup_handover?shipment_ids=${shipment_ids}&pickup_date=${pickupDate.format('YYYY-MM-DD')}`);
    }
  }

  const isOutsideRange = (date) => {

    let startDate = moment().startOf('day')
    let endDate = moment().endOf('day').add(2, 'weeks');

    return date.isBefore(startDate) || date.isAfter(endDate);
  }

  const pickups_col = [
    { dataField: '_id', hidden: true, text: 'ID', exportCSV: false, },
    { dataField: 'merchantId', headerStyle: { width: '120px', resize: 'horizontal' }, hidden: !isAdmin, text: 'Merchant' },
    { dataField: 'pickupDate', headerStyle: { width: '120px', resize: 'horizontal' }, sort: true, sortFunc: dateSort, formatter: renderDate, text: 'Pickup date', exportCSV: false, },
    { dataField: 'pickupAddress', headerStyle: { width: '120px', resize: 'horizontal' }, formatter: renderAddress, text: 'Pickup Address' },
    { dataField: 'totalShipments', headerStyle: { width: '120px', resize: 'horizontal' }, formatter: renderShipments, text: 'Shipments' },
  ]

  const onRowSelect = (row, isSelected, e) => {
    if (isSelected) {
      setSelectedPickup(row._id);
    } else {
      setSelectedPickup(null);
    }
  }

  let selectRowProp = {
    mode: "radio",
    clickToSelect: true,
    onSelect: onRowSelect,
    headerColumnStyle: { width: '30px' },
  };

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
        <h4>Print Pick-up Handover Sheet</h4>
      </Modal.Header>
      <Modal.Body className="d-grid gap-2">
        <div className="d-grid gap-2">
          <label>Select a method for generating handover sheet:</label>
          <ToggleButtonGroup type="radio" name="pickup-type" value={pickupType} onChange={handleChangePickupType}>
            <ToggleButton id="tbg-btn-1" variant="outline-primary" value="jobs">
              Pick-up Jobs
            </ToggleButton>
            <ToggleButton id="tbg-btn-2" variant="outline-primary" value="custom">
              Custom Date
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        { pickupType === 'jobs' &&
        <Card className="tealCard shadow-sm">
          <Card.Header className="tealCardHeader">Select a Pick-up Job</Card.Header>
          <Card.Body className="d-grid gap-2 label-strong grey-label">
            <p>Generated Handover sheet will use pick-up date & pick-up address from selected job</p>
            { loading &&
            <Loader />
            }
            { !loading &&
              <ToolkitProvider
                keyField='_id'
                data={pickups}
                columns={pickups_col}
              >
                {
                  props => (
                    <>
                      <BootstrapTable
                        { ...props.baseProps }
                        selectRow={selectRowProp}
                        bordered={ false }
                        hover
                        defaultSorted={[{
                          dataField: 'created',
                            order: 'desc',
                        }]}
                        noDataIndication={() => 'There is no data to display'}
                      />
                    </>
                  )
                }
              </ToolkitProvider>
            }
          </Card.Body>
        </Card>
        }

        { pickupType === 'custom' &&
          <>
            <Card className="tealCard">
              <Card.Header className="tealCardHeader">Select Pickup Date</Card.Header>
              <Card.Body className="d-grid gap-2 label-strong grey-label">
                <SingleDatePicker
                  numberOfMonths={1}
                  block
                  placeholder='Pickup Date'
                  isOutsideRange={isOutsideRange}
                  displayFormat="D/M/YY"
                  date={pickupDate} // momentPropTypes.momentObj or null
                  onDateChange={(date) => setPickupDate(date)} // PropTypes.func.isRequired
                  focused={focused} // PropTypes.bool
                  onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                />
                <hr />
                <p>Generated handover sheet will use your user profile address as the pick-up address</p>
              </Card.Body>
            </Card>
          </>
        }
      </Modal.Body>
      <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
        { pickupType &&
        <Button disabled={clickedPrint || (pickupType === 'jobs' && !selectedPickup) || (pickupType == 'custom' && !pickupDate)} onClick={onClickPrint}>Print</Button>
        }
      </Modal.Footer>
    </Modal>
  )
}

export default PrintPickupHandoverModal;
