import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as merchantsActionCreators from '../redux/actions/merchants';
import { Form } from 'react-bootstrap';
import _ from 'lodash'

class VendorSelection extends Component {

  componentDidMount() {
    const { fetchMerchants } = this.props;

    fetchMerchants();
  }

  renderVendor = (options, defaultValue, onChange, decorator, decorateOptions) => {
      return (
        <Form.Select className="form-control" onChange={onChange} value={defaultValue}>
          {
            options.map(option => {

              let found = _.findIndex(decorateOptions, (decor) => {
                return option.name === decor
              })
              return (
                <option key={option.key} value={option.value}>{option.name}{found > -1 ? decorator : ""}</option>
              )
            })
          }
        </Form.Select>
      )
  }

  render() {

    const { handleOnVendorChange, merchantsOptions, selectedVendor, rootClass, decorator, decorateOptions, showLabel = true } = this.props

    return (
      <div className={rootClass ? rootClass : 'vendor-selection'}>
        { showLabel &&
        <label style={{marginBottom: '5px'}}>Vendor</label>
        }
        {this.renderVendor(merchantsOptions, selectedVendor, handleOnVendorChange, decorator, decorateOptions)}
      </div>
    )
  }
}

const defaultOptions = [{key: 'any', value: 'any', name: 'Any'}]
const mapMerchantsOptions = (merchants, skipDefaultOption, decorator) => {

  let options = merchants.items.map(merchant => {

    return {
      key: merchant,
      value: merchant,
      name: merchant
    }
  })

  if(!skipDefaultOption){
    options.push({key: 'any', value: 'any', name: 'Any'})
  }

  return options
}

export default connect((state, props) => {

  const {skipDefaultOption} = props

  return {
    merchantsOptions: (state.merchants && state.merchants.items) ? mapMerchantsOptions(state.merchants, skipDefaultOption) : defaultOptions
  };
}, {...merchantsActionCreators})(VendorSelection);
