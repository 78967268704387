import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Switch, Redirect, Link } from 'react-router-dom';
import { matchPath } from 'react-router';
// import { Alert } from 'react-bootstrap';
import Orders from './Orders';
import Invoice from './Invoice';
import PickupHandover from './PickupHandover';
import OrderDetail from './OrderDetail';
import OrderDetailModal from './OrderDetailModal';
import Users from './Users';
import Merchants from './Merchants';
import User from './User';
import Merchant from './Merchant';
import Promotions from './Promotions';
import Promotion from './Promotion';
import TitlePrefix from './TitlePrefix';
import Shipments from './Shipments';
import Deliveries from './Deliveries';
import DeliveryDetail from './DeliveryDetail';
import Store from './Store';
import Profile from './Profile';
import Tickets from './Tickets';
import Billings from './Billings';
import Dashboard from './Dashboard';
import Giftcards from './Giftcards';
import Reviews from './Reviews';
import BillDetail from './BillDetail'
import BillDetailLegacy from './BillDetailLegacy'
import Announcements from './Announcements'
import BillDetailModal from './BillDetailModal'
import UserModal from './UserModal';
import MerchantModal from './MerchantModal';
import Logout from './Logout';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Forbidden from '../components/Forbidden';
import NotFound from '../components/NotFound';
import * as errorActionCreators from '../redux/actions/error';
import ManualOrderDetail from './ManualOrderDetail';
import Products from './Products';
import ProductAddOns from './ProductAddOns';
import ProductBulkEdit from './ProductBulkEdit';
import Help from './Help';
import Logs from './Logs';
import SyncErrorLogs from './SyncErrorLogs';
import ProductCreate from './ProductCreate';
import ProductAddOn from './ProductAddOn';
import ProductImport from './ProductImport';
// import ProductImportMY from './ProductImportMY';
import PendingProducts from './PendingProducts';
import PreviewProduct from './PreviewProduct';
import Taggings from './Taggings';
import api from '../helpers/apiClient';
import * as notificationsActionCreators from '../redux/actions/notifications';
import Customers from './Customers';
import Customer from './Customer';
import OffDays from './OffDays';
import * as quickHelpsActionCreators from '../redux/actions/quickHelp';
import * as authActionCreators from '../redux/actions/auth';
import Pickups from './Pickups';
import toast, { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ProcurementOrders from './ProcurementOrders';
import ProcurementOrderDetail from './ProcurementOrderDetail';
import ProcurementBillings from './ProcurementBillings';
import ProcurementBillDetail from './ProcurementBillDetail';
import GiftrPromotions from './GiftrPromotions';
import ArchivedProducts from './ArchivedProducts';
import DeliveryTemplates from './DeliveryTemplates';
import Medias from './Medias';

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

const StaffRoute = ({ path, user, component }) => {
  return (
    <Route path={path} render={props => (
      user && (user.role === 'admin' || user.role === 'staff') ? (
        React.createElement(component, props)
      ) : (
        <Redirect to="/403" />
      )
    )}/>
  )
}

const AdminRoute = ({ path, user, component }) => {
  return (
    <Route path={path} render={props => (
      user && user.role === 'admin' ? (
        React.createElement(component, props)
      ) : (
        <Redirect to="/403" />
      )
    )}/>
  )
}

const ProcurementRoute = ({ path, user, component }) => {
  return (
    <Route path={path} render={props => (
      user && (user.role === 'admin' || user.role === 'staff' || user.allow_procurement) ? (
        React.createElement(component, props)
      ) : (
        <Redirect to="/403" />
    ))}/>
  )
}

class App extends Component {
  constructor(props) {
    super(props);
    this.previousLocation = props.location;

    this.state = {
      height: 51
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location: { pathname: prevPathname } } = this.props;
    const { location: { pathname }, error, resetError } = nextProps;
    if (pathname !== prevPathname) {
      if (error) {
        resetError();
      }
    }
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props
    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== 'POP' &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location
    }
  }

  async componentDidMount() {
    const { openTicketsCount, pickupReminderCount, fetchQuickHelps, user, setRedirect, location } = this.props;

    setRedirect(location.pathname !== '/' ? location.pathname + location.search : null);

    fetchQuickHelps();

    api(`/merchant_open_tickets`, {
      method: 'GET'
    }).then(result => {
      openTicketsCount(result)
    }).catch(err => {
      console.log(err)
    })

    api(`/shipments/pickup/reminder`, {
      method: 'GET'
    }).then(result => {
      pickupReminderCount(result);
      if (result && result > 0) {
        toast(
          <Link to="/shipments" className="text-decoration-none text-dark">There {result > 1 ? 'are' : 'is'} {result} shipment{result > 1 ? 's' : ''} without pick-up</Link>,
          {
            icon: <FontAwesomeIcon icon={solid('circle-exclamation')}/>,
            position: 'bottom-right',
          }
        );
      }
    }).catch(err => {
      console.log(err)
    })
  }

  onAnnouncementHeightChange = (height) => {

    this.setState({ height: height });
  }

  render() {
    const { location, user } = this.props;
    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location // not initial render
    )

    if (!user || window.location.hostname === 'merchant.giftr.my' || window.location.hostname === 'merchant.giftr.sg') {
      return <Redirect to="/login"/>;
    }
    return (
      <div>
        <Toaster />
        <Header location={location} user={user} annoucementHeightChange={this.onAnnouncementHeightChange}/>
        <div className="vh-100">
          <div className="row mx-0 h-100">
            <div className="d-print-none d-none d-md-block sidebar">
              <Sidebar location={location} user={user} annoucementHeightChange={this.onAnnouncementHeightChange}/>
            </div>
            <div className="main col" style={{top: `${this.state.height}px`}}>
              <Switch location={isModal ? this.previousLocation : location}>
                <Route path="/" exact component={Dashboard} />
                <Route path="/Dashboard" exact component={Dashboard} />
                <Route path="/orders" exact component={Orders} />
                <Route path="/invoice" component={Invoice} />
                <StaffRoute path="/products/pending" user={user} exact component={PendingProducts} />
                <AdminRoute path="/taggings/:type" user={user} component={Taggings} />
                <Route path="/medias" user={user} exact component={Medias} />
                <Route path="/products" user={user} exact component={Products} />
                <Route path="/products/create" user={user} exact component={ProductCreate} />
                <Route path="/products/import" user={user} exact component={ProductImport} />
                <Route path="/products/pending/:productId" user={user} component={ProductCreate} />
                <Route path="/products/bulk-edit" user={user} component={ProductBulkEdit} />
                <Route path="/products/:productId" user={user} component={ProductCreate} />
                <Route path="/product_addons" user={user} exact component={ProductAddOns} />
                <Route path="/product_addons/create" user={user} exact component={ProductAddOn} />
                <Route path="/product_addons/:productId" user={user} component={ProductAddOn} />
                <AdminRoute path="/title_prefix" user={user} component={TitlePrefix} />
                <Route path="/archived_products" user={user} exact component={ArchivedProducts} />
                <Route path="/delivery_templates" exact component={DeliveryTemplates} />
                <Route path="/promotions" exact user={user} component={Promotions} />
                <Route path="/promotions/create" exact user={user} component={Promotion} />
                <Route path="/promotions/:promotionId" user={user} component={Promotion} />
                <StaffRoute path="/giftr_promotions" exact user={user} component={GiftrPromotions} />
                <Route path="/giftcards" exact component={Giftcards} />
                <Route path="/orders/:orderId" component={OrderDetail} />
                <Route path="/manual_orders/:orderId" component={ManualOrderDetail} />
                <AdminRoute path="/users/create" user={user} component={User} />
                <AdminRoute path="/users/:userId" user={user} component={User} />
                <AdminRoute path="/users" user={user} component={Users} />
                <StaffRoute path="/customers" exact user={user} component={Customers} />
                <StaffRoute path="/customers/:customerId" user={user} component={Customer} />
                <Route path="/merchants" user={user} component={Merchants} />
                <Route path="/merchants/:merchantId" user={user} component={Merchant} />
                <Route path="/store" exact component={Store} />
                <Route path="/reviews" user={user} component={Reviews} />
                <ProcurementRoute path="/procurement_orders" exact user={user} component={ProcurementOrders} />
                <ProcurementRoute path="/procurement_orders/:orderId" user={user} component={ProcurementOrderDetail} />
                <ProcurementRoute path="/procurement_bills" exact user={user} component={ProcurementBillings} />
                <ProcurementRoute path="/procurement_bills/:billId" user={user} component={ProcurementBillDetail} />
                <Route path="/shipments" exact user={user} component={Shipments} />
                <Route path="/courier" user={user} component={Shipments} />
                <Route path="/pickups" user={user} component={Pickups} />
                <Route path="/pickup_handover" user={user} component={PickupHandover} />
                <Route path="/on_demand" exact user={user} component={Deliveries} />
                <Route path="/on_demand/:id" user={user} component={DeliveryDetail} />
                <Route path="/bills" exact component={Billings} />
                <Route path="/bills/:billId" component={BillDetail} />
                <Route path="/legacy_bills/:billId" component={BillDetailLegacy} />
                <Route path="/tickets" component={Tickets} user={user} />
                <Route path="/profile" component={Profile} user={user}/>
                <Route path="/off_days" component={OffDays} user={user}/>
                <Route path="/announcements" component={Announcements} />
                <Route path="/help" component={Help} />
                <AdminRoute path="/sync_error_logs" user={user} component={SyncErrorLogs} />
                <AdminRoute path="/logs" user={user} component={Logs} />
                <Route path="/logout" component={Logout} />
                <Route path="/403" component={Forbidden} />
                <Route component={NotFound}/>
              </Switch>
            </div>
            {
              isModal ?
              <Switch>
                <Route path="/orders/:orderId" user={user} component={OrderDetailModal} />
                <Route path="/bills/:billId" component={BillDetailModal} />
                <AdminRoute path="/users/create" user={user} component={UserModal} />
                <AdminRoute path="/users/:userId" user={user} component={UserModal} />
                <Route path="/merchants/:merchantId" user={user} component={MerchantModal} />
              </Switch>
              :
              null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => {
  return {
    error: state.error,
    user: state.auth.user,
    tickets: state.tickets,
  }
}, {
  ...notificationsActionCreators,
  ...errorActionCreators,
  ...quickHelpsActionCreators,
  ...authActionCreators,
})(App);
