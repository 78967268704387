import { 
  FETCH_SHIPMENTS_REQUEST, 
  FETCH_SHIPMENTS_SUCCESS, 
  FETCH_SHIPMENTS_FAILURE, 
  CLEAR_SHIPMENTS,
} from '../actions/shipments';

const defaultState = {
  loading: false,
  loaded: false,
  items: []
};

export default function shipments(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_SHIPMENTS:
      return {
        ...state,
        defaultState
      };
    case FETCH_SHIPMENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_SHIPMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_SHIPMENTS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}