import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import GTRAlert from '../components/GTRAlert';
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import _ from 'lodash'
import * as errorActionCreators from '../redux/actions/error';
import qs from 'qs';
import { exportAsImage, exportAsPDF } from '../helpers/htmlToCanvas';

const giftrDomain = window.location.hostname === 'seller.giftr.my' ? 'https://giftr.my' : 'https://giftr.sg'
var QRCode = require('qrcode.react');

const OrderMessage = () => {

  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState(null)
  const [error, setError] = useState(null)
  const [layout, setLayout] = useState('landscape')

  const exportRef = useRef();

  useEffect(() => {
    const { ids } = qs.parse(location.search.substr(1));

    fetchMessage(ids)
  }, [])

  const fetchMessage = async (ids) => {

    setLoading(true);

    return api(`/messages?ids=${ids}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      setLoading(false)

      if(result){

        setMessages(result)
      }
    }).catch(err => {
      setLoading(false)
      if (err) {
        window.scrollTo(0, 0);
        if (err.validationError) {
          setError(err.validationError)
        }
        else if (err.message) {
          setError(err.message)
        }
        else {
          setError(err)
        }
      }
    });
  }

  const printOrder = () => {
    window.print();
  }

  return (
    <div className="container mt-2">
      {
        loading &&
          <Loader />
      }
      {
        error &&
          <GTRAlert variant="danger" alert={error} />
      }
      {
        !loading && messages &&
          <div>
            <div className="d-print-none d-flex align-items-center" data-html2canvas-ignore>
              <Button variant="success" onClick={printOrder}>Print Message Card</Button>
              <div className="vr" style={{margin: '0 15px'}}></div>
              <Button className='me-1' onClick={() => exportAsPDF(exportRef.current, layout)}>Download as PDF</Button>
              <Button className='me-1' onClick={() => exportAsImage(exportRef.current, 2480, layout === 'landscape' ? 1748 : 2480)}>Download as JPEG</Button>
              <span className="mx-2">Layout:</span>
              <ToggleButtonGroup type="radio" name="message-layout" onChange={(val) => setLayout(val)} value={layout}>
                <ToggleButton id="tbg-btn-1" variant="outline-secondary" value="landscape">
                  Landscape
                </ToggleButton>
                <ToggleButton id="tbg-btn-2" variant="outline-secondary" value="square">
                  Square
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div ref={exportRef}>
              {
                messages.map((message, i) => {
                  return (
                    <div key={i} id={message.name} className={layout === 'square' ? 'square-message' : ''}>
                      <div className="d-print-none" data-html2canvas-ignore style={{textAlign: 'right'}}>
                        Reference: {message.name}
                      </div>
                      <div className="preview-message-content">
                        <div style={{marginTop: "10px"}}>
                          <p className="order-detail-item-desc" style={{fontSize: '1.2em'}}>{message.message.value}</p>
                        </div>
                        <div style={{marginTop: "30px", textAlign: "center"}}>
                          <span style={{fontWeight: 400}}>Sender has left you a special message</span>
                        </div>
                        <div className="mb-3" style={{marginTop: "15px", textAlign: "center"}}>
                          <QRCode
                            value={`${giftrDomain}/pages/virtual-message?shortcode=${message.order_id}-${message.line_item_id}`}
                            renderAs={"svg"}
                            size={100}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                          />
                        </div>
                        <div style={{textAlign: "center"}}>
                          <span style={{fontWeight: 400}}>Scan here 👆</span>
                        </div>
                      </div>
                      <hr className="d-print-none" data-html2canvas-ignore/>
                      <div className="page-break"/>
                    </div>
                  )
                })
              }
            </div>
          </div>
      }
    </div>
  );
}

export default connect((state, props) => {
  return {
    error: state.error,
    user: state.auth.user
  };
}, {
  ...errorActionCreators
})(OrderMessage);
