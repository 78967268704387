import React, { Component } from 'react';
import { Card, Button, ListGroup, Accordion, Row, Col, Form, ToggleButton, Badge } from 'react-bootstrap';
import Select from 'react-select';
import FieldsModal from './FieldsModal';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import uploadIcon from '../images/upload-icon.svg'
import api from '../helpers/apiClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DayPickerSingleDateController } from 'react-dates';
import "react-dates/lib/css/_datepicker.css";
import moment from 'moment';
import PreviewSelectDelivery from './PreviewSelectDelivery';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import ReactTooltip from 'react-tooltip';
import DeliveryFinePrintsModal from './DeliveryFinePrintsModal';
import FieldCreate from './FieldCreate';
import { removeInlineStyles, removeEntities, removeBlockTypes } from '../helpers/draftjsClearFormat';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import SortCustomizeFieldRow from './SortCustomizeFieldRow';
import update from 'immutability-helper'
import CreateDeliveryTemplateModal from './CreateDeliveryTemplateModal';
import EditPickupAddressModal from './EditPickupAddressModal';
import InfoModal from './InfoModal';
import deliveryArea from "../images/self-delivery-area.png"
import deliveryAreaSetup from '../images/self-delivery-area-setup.png'
import deliveryMethod from '../images/self-delivery-method.png'
import deliveryMethodSetup from '../images/self-delivery-method-setup.png'

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'
const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : '$'

const ClearFormatButton = ({clearFormat}) => {
  return (
    <Button variant="secondary" size="sm" className="mb-2" onClick={clearFormat}>Clear Format</Button>
  )
}

class ProductAttributes extends Component {
  constructor(props){
    super(props);

    let { dfpHtml, dpfTemplateAssigned } = this.props
    let deliveryFinePrintsContentState = this.getContentState(dfpHtml)

    this.state = {
      shouldUpdateTags: true,
      showFieldsModal: false,
      attr: {},
      locationOptions: [],
      occasionOptions: [],
      forWhoSelected: [],
      flowerTypeOptions: [],
      uploadingFile: false,
      biaActiveKey: null,
      datesRangeMode: false,
      datesRangeStart: null,
      datePickerKey: 0,
      boRangeStart: null,
      loadingDeliveryFinePrints: false,
      showDeliveryFinePrintsModal: false,
      deliveryFinePrintsOptions: [],
      deliveryFinePrintsReadOnly: dpfTemplateAssigned,
      deliveryFinePrintsEditorState: EditorState.createWithContent(deliveryFinePrintsContentState),
      addConditional: false,
      sortFields: [],
      showCreateDeliveryTemplate: false,
      createDeliveryTemplateDisabled: false,
      showPickupAddressModal: false,
    };
  }

  async componentDidMount() {
    const { vendor } = this.props

    if (vendor) {
      let result = await api(`/merchants/${vendor}/delivery_fine_prints`)

      if (result && result.length >= 5) {
        this.setState({ createDeliveryTemplateDisabled: true })
      }
    }
  }

  getContentState = (html) => {
    const { contentBlocks, entityMap } = convertFromHTML(html ? html.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '') : '')
    return ContentState.createFromBlockArray(contentBlocks, entityMap)
  }

  deliveryFinePrintsEditorChange = deliveryFinePrintsEditorState => {
    const { handleDeliveryFinePrintEditorChange } = this.props;

    if(this.state.deliveryFinePrintsEditorState != deliveryFinePrintsEditorState){
      handleDeliveryFinePrintEditorChange(deliveryFinePrintsEditorState)
    }

    this.setState({ deliveryFinePrintsEditorState })
  }

  handleCopyDeliveryFinePrints = async () => {
    const { handleDeliveryFinePrintEditorChange, vendor } = this.props;

    this.setState({ loadingDeliveryFinePrints : true })

    //get delivery fine prints from API
    let result = await api(`/merchants/${vendor}/delivery_fine_prints`)

    this.setState({ loadingDeliveryFinePrints : false })

    if(result && result.length > 0) {

      if(result.length === 1) {
        let deliveryFinePrints = result[0].content
        this.setDeliveryFinePrints(deliveryFinePrints, result[0]._id)
        this.setState({ deliveryFinePrintsReadOnly: true })
      } else {
        //show modal to select delivery fine prints
        this.setState({ showDeliveryFinePrintsModal: true, deliveryFinePrintsOptions: result })
      }
    }
  }

  handleCreateDeliveryTemplate = () => {
    const { createDeliveryTemplateDisabled } = this.state

    if(createDeliveryTemplateDisabled) {
      return
    }

    this.setState({ showCreateDeliveryTemplate: true })
  }

  handleOnCloseCreateDeliveryTemplateModal = () => {
    this.setState({ showCreateDeliveryTemplate: false })
  }

  handleOnSaveCreateDeliveryTemplate = (deliveryFinePrints) => {
    this.setDeliveryFinePrints(deliveryFinePrints.content, deliveryFinePrints._id)
    this.setState({ showCreateDeliveryTemplate: false, deliveryFinePrintsReadOnly: true })
  }

  handleRemoveDeliveryFinePrints = () => {

    this.setDeliveryFinePrints('', '')
    this.setState({ deliveryFinePrintsReadOnly: false })
  }

  handleOnSaveDeliveryFinePrints = (deliveryFinePrints, deliveryFinePrintsId) => {

    this.setDeliveryFinePrints(deliveryFinePrints, deliveryFinePrintsId)
    this.setState({ showDeliveryFinePrintsModal: false, deliveryFinePrintsReadOnly: true })
  }

  setDeliveryFinePrints = (deliveryFinePrints, deliveryFinePrintsId) => {
    const { handleDeliveryFinePrintEditorChange } = this.props;

    const deliveryFinePrintsContentState = this.getContentState(deliveryFinePrints)
    const deliveryFinePrintsEditorState = EditorState.createWithContent(deliveryFinePrintsContentState)

    handleDeliveryFinePrintEditorChange(deliveryFinePrintsEditorState, deliveryFinePrintsId)

    this.setState({ deliveryFinePrintsEditorState })
  }

  setAttrState = item => {
    let { attr, updateAttrTags } = this.props;

    attr = {
      ...attr,
      ...item,
    }

    updateAttrTags(attr);
  }

  handleOnChange = e => {
    const { attr: { bia, ubia, customizeFields, locationsSelected: currentLocations, delivery_days, uboDates, boDates, shippingStart, self }, closed } = this.props;
    const { datePickerKey } = this.state;
    const { name, value, checked, id } = e.target;
    const index = id && parseInt(id.split('_')[1]);

    let exclude_days = 'W'
    let closedTags = []

    switch (name) {
      case 'days_in_advance':
        this.setAttrState({
          bia: {
            ...bia,
            days_in_advance: value
          },
        })
        this.setState({
          datePickerKey: datePickerKey + 1
        })
        break;

      case 'days_in_advance_seasonal':
        this.setAttrState({
          ubia: {
            ...ubia,
            days_in_advance: value
          },
        })
        this.setState({
          datePickerKey: datePickerKey + 1
        })
        break;

      case 'cutoff_time':
        this.setAttrState({
          cutOffTime: value
        })
        break;

      case 'delivery_days':
        let updateDeliveryDays = [...delivery_days]

        const dayFound = _.findIndex(delivery_days, day => day.value === value)

        if(dayFound === -1 && checked){
          updateDeliveryDays.push({ value })
        }
        if(dayFound !== -1 && !checked){
          updateDeliveryDays.splice(dayFound, 1)
        }

        let selectedDeliveryDays = _.map(updateDeliveryDays, day => day.value)

        if(!_.includes(selectedDeliveryDays, 'sun') && !_.includes(selectedDeliveryDays, 'sat')){
          exclude_days = 'NW'
        } else if(!_.includes(selectedDeliveryDays, 'sun')){
          exclude_days = 'WS'
        }

        _.each(closed, (day, key) => {
          if(!_.includes(selectedDeliveryDays, key)){
            closedTags.push(closed[key])
          }
        })

        this.setAttrState({
          delivery_days: updateDeliveryDays,
          closedTags,
          bia: {
            ...bia,
            exclude_days
          }
        })
        break;

      case 'primaryTitle':
      case 'maxChar':
      case 'secondTitle':
      case 'options':
      case 'limit':
        this.setAttrState({
          customizeFields: [
            ...customizeFields.slice(0, index),
            {
              ...customizeFields[index],
              [name]: value
            },
            ...customizeFields.slice(index + 1)
          ]
        });
        break;

      case 'required':
        this.setAttrState({
          customizeFields: [
            ...customizeFields.slice(0, index),
            {
              ...customizeFields[index],
              [name]: checked
            },
            ...customizeFields.slice(index + 1)
          ]
        });
        break;

      case 'self':
      case 'block_em':
      case 'ordered_fields':
      case 'pre_order':
        this.setAttrState({
          [name]: checked
        })
        break;

      case 'delivery_type':

        let courierTag = country === 'MY' ? 'Nationwide (Courier Delivery)' : 'Courier Delivery'
        let locationsSelected = [ ...currentLocations ]
        const nationwideIndex = locationsSelected.findIndex(location => {
          return location.value === courierTag
        })

        if(value === 'courier' && nationwideIndex === -1){
          locationsSelected.push({
            label: courierTag,
            value: courierTag,
          })
        } else if (nationwideIndex !== -1){
          locationsSelected.splice(nationwideIndex, 1)
        }

        this.setAttrState({
          [name]: value,
          self: value === 'courier' ? false : self,
          locationsSelected,
          bia: {
            ...bia,
            ...(Object.entries(bia).length <= 1 && {
              days_in_advance: '0',
              exclude_days: 'W'
            }),
            on: value === 'd2d'
          },
          ubia: {
            ...ubia,
            ...(Object.entries(ubia).length <= 1 && {
              days_in_advance: '0',
              exclude_days: 'W'
            }),
            on: value === 'seasonal'
          },
        })
        break;

      case 'ubo':
        const { datesRangeStart } = this.state;

        if(datesRangeStart){
          const diff = value.diff(datesRangeStart, 'days')
          let result = []

          for (let i = 0; i <= diff; i++){
            let subtractDay = value.clone().subtract(i, 'days');
            let wasPreviouslyPicked = uboDates.some((d) => d.isSame(subtractDay));

            if(!wasPreviouslyPicked){
              result.push(subtractDay)
            }
          }

          this.setState({
            datesRangeStart: null
          })

          this.setAttrState({
            uboDates: [...uboDates, ...result]
          })
          break
        }

        let wasPreviouslyPicked = uboDates.some((d) => d.isSame(value));
        if (wasPreviouslyPicked) {
          this.setAttrState({
            uboDates: uboDates.filter(d => !d.isSame(value))
          })
        } else {
          this.setAttrState({
            uboDates: [...uboDates, value]
          })
        }
        break;

      case 'ubo-start':
        this.setState({
          datesRangeStart: value,
        })
        break;

      case 'bo':
        const { boRangeStart } = this.state;

        if(boRangeStart){
          const diff = value.diff(boRangeStart, 'days')
          let result = []

          for (let i = 0; i <= diff; i++){
            let subtractDay = value.clone().subtract(i, 'days');

            if(boDates.some((d) => d.isSame(subtractDay))){
              result.push(subtractDay)
            }
          }

          this.setState({
            boRangeStart: null
          })

          this.setAttrState({
            boDates: [...boDates, ...result]
          })
          break
        }

        if (boDates.some((d) => d.isSame(value))) {
          this.setAttrState({
            boDates: boDates.filter(d => !d.isSame(value))
          })
        } else {
          this.setAttrState({
            boDates: [...boDates, value]
          })
        }
        break;

      case 'shipping_start':
        if (shippingStart && shippingStart.isSame(value, 'day')) {
          this.setAttrState({
            shippingStart: ''
          })
        } else {
          this.setAttrState({
            shippingStart: value
          })
        }
        break;

      default:
        this.setAttrState({
          [name]: value
        })
        break;
    }
  }

  handleOnClickAddFields = (e) => {
    e.preventDefault();
    e.stopPropagation()
    this.setState({ showFieldsModal: true })
  }

  handleOnClickSortFields = (e) => {
    const { attr: { customizeFields } } = this.props
    e.preventDefault();
    e.stopPropagation()
    this.setState({ sorting: true, sortFields: _.cloneDeep(customizeFields) })
  }

  handleOnClickCancelSortFields = (e) => {
    e.preventDefault();
    e.stopPropagation()
    this.setState({ sorting: false })
  }

  handleMoveField = (dragIndex, hoverIndex) => {
    let sortFields = _.cloneDeep(this.state.sortFields)

    this.setState({
      sortFields: [...update(sortFields, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, sortFields[dragIndex]]
        ]
      })]
    })
  }

  handleSaveSortFields = (e) => {
    e.preventDefault();
    e.stopPropagation()
    let { sortFields } = this.state;

    _.each(sortFields, (field, i) => {
      field.seq = i+1
    })

    this.setAttrState({
      customizeFields: [
        ...sortFields,
      ]
    })

    this.setState({ sorting: false })
  }

  handleOnCloseAddFields = () => {
    this.setState({ showFieldsModal: false, addConditional: false })
  }

  handleAddField = field => {
    const { attr: { customizeFields } } = this.props;

    this.setAttrState({
      customizeFields: [
        ...customizeFields,
        field,
      ]
    })

    this.setState({
      showFieldsModal: false,
      addConditional: false,
    })
  }

  handleRemoveField = index => {
    const { attr: { customizeFields } } = this.props;

    this.setAttrState({
      customizeFields: [
        ...customizeFields.slice(0, index),
        ...customizeFields.slice(index + 1)
      ]
    })
  }

  handleMoveUp = index => {
    const { attr: { customizeFields } } = this.props;

    if(index !== 0){
      const field = customizeFields[index];
      customizeFields.splice(index, 1);
      customizeFields.splice(index-1, 0, field);

      this.setAttrState({ customizeFields });
    }
  }

  handleMoveDown = index => {
    const { attr: { customizeFields } } = this.props;

    if(index !== customizeFields.length - 1){
      const field = customizeFields[index];
      customizeFields.splice(index, 1);
      customizeFields.splice(index+1, 0, field);

      this.setAttrState({ customizeFields });
    }
  }

  onDropHalalCert = async (files) => {
    const { addHalalCert } = this.props;
    this.setState({halal_cert: files[0].name })

    if (files && files.length == 1){
      this.setState({
        uploadingFile: true
      })

      var formData = new FormData();
      formData.append('product_image', files[0])
      formData.append('halal_cert', true)

      const data = await api(`/products/images`, {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'})

      this.setState({ uploadingFile: false })

      addHalalCert(data.link)
      this.setAttrState({ halal_cert: data.link })
    }

  }

  isOutsideRange = day => {
    const { bia, ubia, delivery_type } = this.props.attr;
    let startDate = moment()

    if(delivery_type === 'd2d' && bia && bia.days_in_advance){
      startDate.add(bia.days_in_advance, 'days')
    } else if (delivery_type === 'seasonal' && ubia && ubia.days_in_advance){
      startDate.add(ubia.days_in_advance, 'days')
    }

    return day.isBefore(startDate, 'day') || day.isAfter(moment().add(60, "days"));
  }

  handleOnCloseDeliveryFinePrintsModal = () => {
    this.setState({ showDeliveryFinePrintsModal: false })
  }

  handleEditOptions = (e, optionIndex, fieldIndex, tagType) => {
    let { name, value } = e.target
    let { customizeFields } = this.props.attr;

    let split = customizeFields[fieldIndex].options[optionIndex].value.split('#')

    if (tagType === 'ddaddon' || tagType === 'cbaddon') {
      if (name.includes('name')) {
        customizeFields[fieldIndex].options[optionIndex].value = value + '#' + (split[1] || '')
      } else if (name.includes('price')) {
        customizeFields[fieldIndex].options[optionIndex].value = (split[0] || '') + '#' + value
      }
    } else {
      customizeFields[fieldIndex].options[optionIndex].value = value
    }

    _.each(customizeFields[fieldIndex].conditionalFields, field => {
      field.valueOptions = customizeFields[fieldIndex].options
      if (name.includes('name')) {
        field.selected = _.filter(_.map(field.selected, selectedOption => {
          if (split[0] && selectedOption.value === split[0]) {
            return {
              label: value,
              value: value,
            }
          }

          return selectedOption
        }), selectedOption => selectedOption.value)
      }
    })

    this.setAttrState({ customizeFields });
  }

  handleRemoveOption = (optionIndex, fieldIndex) => {
    let { customizeFields } = this.props.attr;

    customizeFields[fieldIndex].options.splice(optionIndex, 1)

    if (customizeFields[fieldIndex].options.length === 0) {
      customizeFields[fieldIndex].options = [{value: ""}]
    }

    _.each(customizeFields[fieldIndex].conditionalFields, field => {
      field.valueOptions = customizeFields[fieldIndex].options
      field.selected = _.filter(field.selected, selectedOption => {
        return _.some(customizeFields[fieldIndex].options, option => option.value.includes(selectedOption.value))
      })
    })

    this.setAttrState({ customizeFields });
  }

  handleAddOption = (fieldIndex) => {
    let { customizeFields } = this.props.attr;

    customizeFields[fieldIndex].options = [...customizeFields[fieldIndex].options, {value: ""}]

    _.each(customizeFields[fieldIndex].conditionalFields, field => {
      field.valueOptions = customizeFields[fieldIndex].options
    })

    this.setAttrState({ customizeFields });
  }

  handleOnChangeConditionalField = (e, fieldIndex, conditionalIndex) => {
    const { attr: { customizeFields } } = this.props;
    const { name, value, checked } = e.target;
    let { conditionalFields } = customizeFields[fieldIndex];

    switch(name) {
      case 'primaryTitle':
      case 'maxChar':
      case 'secondTitle':
      case 'options':
      case 'limit':
        conditionalFields = [
          ...conditionalFields.slice(0, conditionalIndex),
          {
            ...conditionalFields[conditionalIndex],
            [name]: value
          },
          ...conditionalFields.slice(conditionalIndex + 1)
        ]
        break;

      case 'required':
        conditionalFields = [
          ...conditionalFields.slice(0, conditionalIndex),
          {
            ...conditionalFields[conditionalIndex],
            [name]: checked
          },
          ...conditionalFields.slice(conditionalIndex + 1)
        ]
        break;
      default:
        break;
    }

    this.setAttrState({
      customizeFields: [
        ...customizeFields.slice(0, fieldIndex),
        {
          ...customizeFields[fieldIndex],
          conditionalFields,
        },
        ...customizeFields.slice(fieldIndex + 1)
      ]
    });
  }

  handleEditOptionsConditional = (e, fieldIndex, conditionalIndex, optionIndex, tagType) => {
    let { name, value } = e.target
    let { customizeFields } = this.props.attr;

    let split = customizeFields[fieldIndex].conditionalFields[conditionalIndex].options[optionIndex].value.split('#')

    if (tagType === 'ddaddon' || tagType === 'cbaddon') {
      if (name.includes('name')) {
        customizeFields[fieldIndex].conditionalFields[conditionalIndex].options[optionIndex].value = value + '#' + (split[1] || '')
      } else if (name.includes('price')) {
        customizeFields[fieldIndex].conditionalFields[conditionalIndex].options[optionIndex].value = (split[0] || '') + '#' + value
      }
    } else {
      customizeFields[fieldIndex].conditionalFields[conditionalIndex].options[optionIndex].value = value
    }

    this.setAttrState({ customizeFields });
  }

  handleRemoveOptionConditional = (optionIndex, conditionalIndex, fieldIndex) => {
    let { customizeFields } = this.props.attr;

    customizeFields[fieldIndex].conditionalFields[conditionalIndex].options.splice(optionIndex, 1)

    if (customizeFields[fieldIndex].conditionalFields[conditionalIndex].options.length === 0) {
      customizeFields[fieldIndex].conditionalFields[conditionalIndex].options = [{value: ""}]
    }

    this.setAttrState({ customizeFields });
  }

  handleAddOptionConditional = (fieldIndex, conditionalIndex) => {
    let { customizeFields } = this.props.attr;

    customizeFields[fieldIndex].conditionalFields[conditionalIndex].options.push({value: ""})

    this.setAttrState({ customizeFields });
  }

  handleRemoveFieldConditional = (fieldIndex, conditionalIndex) => {
    let { attr: { customizeFields } } = this.props;

    customizeFields[fieldIndex].conditionalFields.splice(conditionalIndex, 1)

    this.setAttrState({ customizeFields })
  }

  handleOnClickAddFieldsConditional = (fieldIndex) => {
    const { customizeFields } = this.props.attr;
    let conditionalOptions = customizeFields[fieldIndex].options

    this.setState({
      showFieldsModal: true,
      addConditional: true,
      fieldIndex,
      conditionalOptions
    })
  }

  handleAddConditional = (conditionalField, fieldIndex) => {
    let { customizeFields } = this.props.attr;

    customizeFields[fieldIndex].conditionalFields = [
      ...customizeFields[fieldIndex].conditionalFields || [],
      conditionalField
    ]

    this.setAttrState({ customizeFields })
    this.setState({ showFieldsModal: false, addConditional: false })
  }

  handleOnChangeConditionalSelected = (selected, conditionalIndex, fieldIndex) => {
    let { customizeFields } = this.props.attr;

    customizeFields[fieldIndex].conditionalFields[conditionalIndex].selected = selected

    this.setAttrState({ customizeFields })
  }

  editorClearFormat = () => {
    const { editorState } = this.state

    const stateWithoutFormat = _.flow([
      removeInlineStyles,
      removeEntities,
      removeBlockTypes,
    ])(editorState)

    this.setState({ editorState: stateWithoutFormat })
  }

  onClickEditPickupAddress = () => {
    this.setState({ showPickupAddressModal: true })
  }

  handleOnCloseEditPickupAddressModal = () => {
    this.setState({ showPickupAddressModal: false })
  }

  handleOnSavePickupAddress = () => {
    const { onSavePickupAddress } = this.props

    this.setState({ showPickupAddressModal: false })

    onSavePickupAddress()
  }

  showSelfPickupExamples = () => {
    this.setState({ showSelfPickupExamples: true })
  }

  handleOnCloseSelfPickupExamples = () => {
    this.setState({ showSelfPickupExamples: false })
  }

  render(){
    const { isAdmin, user, hasDFPTemplates, corporate, ltfpHtml = '', vendor, vendorTools, 
      hasSelfVariant, variantConditionalTitles = [], archived, matchedTitleDecorations, actualProductTitle } = this.props

    const { bia, customizeFields, self, block_em, locationsSelected, occasionsSelected, 
      flowerTypesSelected, forWhoSelected, halal_cert, not_halal, have_halal_cert, 
      ordered_fields, selectOptions: { dayOptions, locationOptions, occasionOptions, forWhoOptions, 
        flowerTypeOptions }, delivery_type, delivery_days, ubia, uboDates, boDates, cutOffTime, 
        pre_order, shippingStart } = this.props.attr

    const { showFieldsModal, datesRangeMode, datesRangeStart, datePickerKey, boRangeStart,
      loadingDeliveryFinePrints, showDeliveryFinePrintsModal, deliveryFinePrintsOptions,
      deliveryFinePrintsReadOnly, deliveryFinePrintsEditorState, addConditional, fieldIndex, 
      conditionalOptions, sorting, sortFields, showCreateDeliveryTemplate, createDeliveryTemplateDisabled, 
      showPickupAddressModal, showSelfPickupExamples } = this.state;

    let days_in_advance_value = '0'
    if(delivery_type === 'd2d' && bia && bia.days_in_advance){
      days_in_advance_value = bia.days_in_advance
    }
    if(delivery_type === 'seasonal' && ubia && ubia.days_in_advance){
      days_in_advance_value = ubia.days_in_advance
    }

    const dfpLength = deliveryFinePrintsEditorState.getCurrentContent().getPlainText().length
    const dfpBackground = (deliveryFinePrintsReadOnly || archived) ? '#e9ecef' : '#ffffff'

    const validPickupAddress = vendorTools && vendorTools.pickup_address_line_1 && vendorTools.pickup_address_postcode && (country === 'SG' || vendorTools.pickup_address_city)

    const hasOccasionsMatchedTitleDecorations = matchedTitleDecorations && matchedTitleDecorations.some(decoration => { return  occasionsSelected.some(occasion => decoration.tag === occasion.value) })
    const hasLocationsMatchedTitleDecorations = matchedTitleDecorations && matchedTitleDecorations.some(decoration => { return  locationsSelected.some(location => decoration.tag === location.value) })
    const hasFlowerTypesMatchedTitleDecorations = matchedTitleDecorations && matchedTitleDecorations.some(decoration => { return  flowerTypesSelected.some(flowerType => decoration.tag === flowerType.value) })
    const hasForWhoMatchedTitleDecorations = matchedTitleDecorations && matchedTitleDecorations.some(decoration => { return  forWhoSelected.some(forWho => decoration.tag === forWho.value) })

    return (
      <Row>
      <Col xs={12}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0" className="tealCard my-4 shadow-sm">
            <Accordion.Header className="tealCardHeader product-accordion" style={{borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'}}>Delivery Info</Accordion.Header>
            <Accordion.Body className="d-grid gap-3">
              <Row className="d-grid gap-3">
                { corporate &&
                <>
                  <Col className="d-grid gap-1">
                    <label>Delivery Fine Prints</label>
                    <div dangerouslySetInnerHTML={{ __html: ltfpHtml }} className="b2b-fine-prints" />
                  </Col>
                  <span>*Delivery Fine Prints will be auto generated once the shipping lead times are filled for each tiering below</span>
                </>
                }
                { !corporate &&
                <>
                  <Col className="d-grid gap-1">
                    <div>
                      <label className="me-3">Delivery Fine Prints</label>
                      <Button variant="secondary" className="py-0 me-2" size="small" onClick={this.handleCreateDeliveryTemplate} disabled={deliveryFinePrintsEditorState.getCurrentContent().getPlainText() !== '' || createDeliveryTemplateDisabled || archived}>
                        Create Delivery Template
                      </Button>
                      <Button variant="secondary" disabled={!hasDFPTemplates || archived} className="py-0 me-2" size="small" onClick={this.handleCopyDeliveryFinePrints}>
                        { loadingDeliveryFinePrints ? "Loading" : "Apply Template" }
                        <>
                          <i className="ms-1" data-html={true} data-tip={`
                              You may edit your default delivery fine prints in Delivery Templates. <br/>Delivery fine prints template applied cannot be edited.
                            `} data-for="delivery-fine-prints"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip id="delivery-fine-prints" />
                        </>
                      </Button>
                      <Button variant="secondary" disabled={!deliveryFinePrintsReadOnly || dfpLength < 1 || archived } className="py-0 me-2" size="small" onClick={this.handleRemoveDeliveryFinePrints}>
                        Remove Template
                      </Button>
                    </div>
                    <div style={{ border: '1px solid #ced4da', borderRadius: '4px', fontFamily: 'sans-serif' }}>
                      <Editor
                        toolbarCustomButtons={[<ClearFormatButton clearFormat={this.editorClearFormat} />]}
                        readOnly={deliveryFinePrintsReadOnly || corporate || archived}
                        onEditorStateChange={this.deliveryFinePrintsEditorChange}
                        editorState={deliveryFinePrintsEditorState}
                        editorStyle={{ resize: 'vertical', height: '150px', padding: '0 1rem', backgroundColor: dfpBackground}} />
                    </div>
                  </Col>
                  <Col className="d-grid gap-1">
                    <label>Delivery Type</label>
                    <Form.Select className="form-control" name="delivery_type" value={delivery_type} required onChange={this.handleOnChange} disabled={archived}>
                      <option value="" disabled>Select an option</option>
                      <option value="d2d">On-Demand Delivery</option>
                      <option value="seasonal">On-Demand Delivery (Limited Period/Seasonal)</option>
                      <option value="courier">Courier Delivery</option>
                    </Form.Select>
                  </Col>
                      <Accordion activeKey={delivery_type || ""}>
                        <Accordion.Collapse eventKey="d2d">
                          <Row className="d-grid gap-3 my-2">
                            <Col className="d-grid gap-1">
                              <label>Booking in Advance</label>
                              <Form.Select className="form-control" name="days_in_advance" value={days_in_advance_value} onChange={this.handleOnChange} disabled={archived}>
                                <option value="0">Same Day Delivery</option>
                                <option value="1">1 Day (Next Day Delivery)</option>
                                <option value="2">2 Days (Min. 2 Days Booking)</option>
                                <option value="3">3 Days (Min. 3 Days Booking)</option>
                                { (isAdmin || (user.role === 'merchant' && user.merchantId.includes('The White Ombre'))) &&
                                <option value="5">5 Days (Min. 5 Days Booking)</option>
                                }
                              </Form.Select>
                            </Col>
                            <Accordion activeKey={days_in_advance_value || ""}>
                              <Accordion.Collapse eventKey="0">
                                <Col className="d-grid gap-1">
                                  <label>Cut-off Time</label>
                                  <Form.Select className="form-control" name="cutoff_time" value={cutOffTime} onChange={this.handleOnChange} disabled={archived}>
                                    <option value="12pm">12 PM</option>
                                    <option value="1pm">1 PM</option>
                                    <option value="2pm">2 PM</option>
                                    <option value="3pm">3 PM</option>
                                    <option value="4pm">4 PM</option>
                                    <option value="5pm">5 PM</option>
                                    <option value="6pm">6 PM</option>
                                  </Form.Select>
                                </Col>
                              </Accordion.Collapse>
                            </Accordion>
                            <Row>
                              <Col>
                                <div className="d-grid gap-1">
                                  <label>Available Delivery Day(s)</label>
                                  {
                                    _.map(dayOptions, (day, i) => {
                                      const checked = _.some(delivery_days, del => del.value === day.value)

                                      return(
                                        <div key={i} className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                                          <label className='custom-checkbox'>
                                            <input type="checkbox" name="delivery_days" onChange={this.handleOnChange} checked={checked || false} value={day.value} disabled={archived}/>
                                            <span className="checkmark checkmark-smaller"></span>
                                          </label>
                                          <label style={{fontWeight: '200'}}>{day.label}</label>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                                { isAdmin &&
                                <>
                                  <label>Block Out Day(s)</label>
                                  <div className="calendar-fixed-height">
                                    <DayPickerSingleDateController
                                      key={datePickerKey}
                                      isOutsideRange={archived ? () => true : this.isOutsideRange}
                                      hideKeyboardShortcutsPanel
                                      onDateChange={date => {
                                        this.handleOnChange({
                                          target: {
                                            name: 'bo',
                                            value: date.startOf('day'),
                                          }
                                        })
                                      }}
                                      focused={true}
                                      date={boRangeStart || null}
                                      isDayHighlighted={day => boDates.some(d => d.isSame(day, 'day'))}
                                      keepOpenOnDateSelect
                                    />
                                  </div>
                                </>
                                }
                              </Col>
                              <Col>
                                <PreviewSelectDelivery
                                  bia={bia}
                                  deliveryDays={_.map(delivery_days, d => d.value)}
                                  blockedDates={boDates}
                                  self={self}
                                />
                              </Col>
                            </Row>
                          </Row>
                        </Accordion.Collapse>
                        <Accordion.Collapse eventKey="seasonal">
                          <Row className="d-grid gap-3 my-2">
                            <Col className="d-grid gap-1">
                              <label>Booking in Advance</label>
                              <Form.Select className="form-control" name="days_in_advance_seasonal" value={days_in_advance_value} onChange={this.handleOnChange} disabled={archived}>
                                <option value="0">Same Day Delivery</option>
                                <option value="1">1 Day (Next Day Delivery)</option>
                                <option value="2">2 Days (Min. 2 Days Booking)</option>
                                <option value="3">3 Days (Min. 3 Days Booking)</option>
                                { (isAdmin || (user.role === 'merchant' && user.merchantId.includes('The White Ombre'))) &&
                                <option value="5">5 Days (Min. 5 Days Booking)</option>
                                }
                              </Form.Select>
                            </Col>
                            <Accordion activeKey={days_in_advance_value || ""}>
                              <Accordion.Collapse eventKey="0">
                                <Col className="d-grid gap-1">
                                  <label>Cut-off Time</label>
                                  <Form.Select className="form-control" name="cutoff_time" value={cutOffTime} onChange={this.handleOnChange} disabled={archived}>
                                    <option value="12pm">12 PM</option>
                                    <option value="1pm">1 PM</option>
                                    <option value="2pm">2 PM</option>
                                    <option value="3pm">3 PM</option>
                                    <option value="4pm">4 PM</option>
                                    <option value="5pm">5 PM</option>
                                    <option value="6pm">6 PM</option>
                                  </Form.Select>
                                </Col>
                              </Accordion.Collapse>
                            </Accordion>
                            <Col className="d-grid gap-1">
                              <div>
                                <label>Available Delivery Day(s)</label>
                                <div>
                                  <span>Selection Mode: </span>
                                  <ToggleButton
                                    size='sm'
                                    className="mx-1 text-black-50"
                                    id="toggle-check"
                                    type="checkbox"
                                    variant="secondary"
                                    checked={datesRangeMode}
                                    value="1"
                                    onChange={(e) => this.setState({ datesRangeMode: e.currentTarget.checked })}
                                  >
                                    {
                                      datesRangeMode ?
                                        <>Range</>
                                        :
                                        <>Single</>
                                    }
                                  </ToggleButton>
                                </div>
                              </div>
                              <Row>
                                <Col className="calendar-fixed-height">
                                  <DayPickerSingleDateController
                                    key={datePickerKey}
                                    isOutsideRange={archived ? () => true : this.isOutsideRange}
                                    hideKeyboardShortcutsPanel
                                    onDateChange={date => {
                                      if(datesRangeMode && !datesRangeStart){
                                        this.handleOnChange({
                                          target: {
                                            name: 'ubo-start',
                                            value: date.startOf('day'),
                                          }
                                        })
                                        return
                                      }
                                      this.handleOnChange({
                                        target: {
                                          name: 'ubo',
                                          value: date.startOf('day'),
                                        }
                                      })
                                    }}
                                    focused={true}
                                    onFocusChange={console.log}
                                    date={datesRangeStart || null}
                                    isDayHighlighted={day => uboDates.some(d => d.isSame(day, 'day'))}
                                    keepOpenOnDateSelect
                                  />
                                </Col>
                                <Col>
                                  <PreviewSelectDelivery
                                    ubia={ubia}
                                    allowedDates={uboDates}
                                    self={self}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Accordion.Collapse>
                      <Accordion.Collapse eventKey="courier">
                        <Row className="d-grid gap-3 my-2">
                          {
                            country === 'MY' &&
                            <Row>
                              <Col xs="auto"><label className="checkbox-inline">West Malaysia Only</label></Col>
                              <Col xs={1}>
                                <label className='custom-checkbox' style={{ marginTop: '2px', marginLeft: '0.1em' }}>
                                  <input type="checkbox" name="block_em" onChange={this.handleOnChange} checked={block_em} disabled={archived} />
                                  <span className="checkmark checkmark-smaller"></span>
                                </label>
                              </Col>
                            </Row>
                          }
                          <Row>
                            <Col xs="auto"><label className="checkbox-inline">Pre-Order</label></Col>
                            <Col xs={1}>
                              <label className='custom-checkbox' style={{ marginTop: '2px', marginLeft: '0.1em' }}>
                                <input type="checkbox" name="pre_order" onChange={this.handleOnChange} checked={pre_order || ''} disabled={archived} />
                                <span className="checkmark checkmark-smaller"></span>
                              </label>
                            </Col>
                          </Row>
                          <Accordion activeKey={pre_order ? "true" : ""}>
                            <Accordion.Collapse eventKey="true">
                              <Row>
                                <label className="mb-2">Select Shipping Start Date</label>
                                <Col className="calendar-fixed-height">
                                  <DayPickerSingleDateController
                                    hideKeyboardShortcutsPanel
                                    onDateChange={date => {
                                      this.handleOnChange({
                                        target: {
                                          name: 'shipping_start',
                                          value: date.startOf('day'),
                                        }
                                      })
                                    }}
                                    focused={true}
                                    isDayHighlighted={day => shippingStart && shippingStart.isSame(day, 'day')}
                                    keepOpenOnDateSelect
                                  />
                                </Col>
                              </Row>
                            </Accordion.Collapse>
                          </Accordion>
                        </Row>
                      </Accordion.Collapse>
                      </Accordion>
                  { (delivery_type === 'd2d' || delivery_type === 'seasonal') &&
                  <Row>
                    <Col xs="auto"><label>Self Pick-up Available</label></Col>
                    <Col xs={1}>
                      <label className='custom-checkbox' style={{marginTop: '2px', marginLeft: '0.1em'}}>
                        <input type="checkbox" name="self" onChange={this.handleOnChange} checked={self || ''} disabled={archived}/>
                        <span className="checkmark checkmark-smaller"></span>
                      </label>
                    </Col>
                  </Row>
                  }
                    {self === true && (delivery_type === 'd2d' || delivery_type === 'seasonal') && vendorTools && !archived &&
                      <>
                      <div>
                        <span>1. Setup Self Pick-up address: {validPickupAddress && <FontAwesomeIcon icon={solid('check-circle')} style={{color: '#81d8d0'}}/>}</span>
                        <Card>
                          <Card.Body>
                            <div>{vendorTools.pickup_address_line_1 || ''}</div>
                            <div>{vendorTools.pickup_address_line_2 || ''}</div>
                            <div>{vendorTools.pickup_address_postcode || ''}{vendorTools.pickup_address_city ? ', ' + vendorTools.pickup_address_city : ''}{vendorTools.pickup_address_state ? ', ' + vendorTools.pickup_address_state : ''}</div>
                            <div>{vendorTools.pickup_address_remarks || ''}</div>
                            <div>
                              <Button variant="secondary" size="sm" onClick={this.onClickEditPickupAddress}>
                                Add/Edit
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                        {validPickupAddress &&
                        <>
                          <div className="text-decoration-underline">The following line will be added under the Delivery Fine Prints section: </div>
                          <div>Self pick-up is available in {vendorTools.pickup_address_postcode}, {vendorTools.pickup_address_city}. Full address will be provided once order is placed.</div>
                        </>
                        }
                      </div>
                      <div>
                        <span>2. Setup Self Pick-up variant: {hasSelfVariant && <FontAwesomeIcon icon={solid('check-circle')} style={{color: '#81d8d0'}}/>}</span>
                        <ol type="i">
                          <li>Scroll down to Variants section.</li>
                          <li>If product has Delivery Area option, add on Self Pick-up after zone options.</li>
                          <li>If product does not have Delivery Area option, create an option called Delivery Method with values Delivery and Self Pick-up.</li>
                          <li>Please ensure that the new field "Shipping not required" for Self Pick-up value is checked.</li>
                          <li>Click <span className="text-primary cursor-pointer" onClick={this.showSelfPickupExamples}>here</span> to see examples.</li>
                        </ol>
                      </div>
                    </>
                    }
                    {self === true && (delivery_type === 'd2d' || delivery_type === 'seasonal') && !vendorTools &&
                      <div>Please select a vendor</div>
                    }
                  {
                    country === 'MY' &&
                      <div className="d-grid gap-1">
                        <label>Location</label>
                        <Select
                          value={locationsSelected}
                          isMulti
                          onChange={(selected) => this.handleOnChange({
                            target: {
                              name: 'locationsSelected',
                              value: selected
                            }
                          })}
                          required={true}
                          options={locationOptions}
                          isDisabled={archived}
                        />
                        {
                          hasLocationsMatchedTitleDecorations &&
                          <span className="text-muted small">Actual Product Title: {actualProductTitle}</span>
                        }
                      </div>
                  }
                </>
                }
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0" className="tealCard my-4 shadow-sm">
            <Accordion.Header className="tealCardHeader product-accordion" style={{borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'}}>
              Categorisation
            </Accordion.Header>
            <Accordion.Body className="d-grid gap-3">
            <Row className="d-grid gap-3">
              <div className="d-grid gap-1">
                <label>Occasions (Max. 6 Selections)</label>
                  <Select
                    isOptionDisabled={() => occasionsSelected.length >= 6}
                    value={occasionsSelected}
                    isMulti
                    onChange={(selected) => this.handleOnChange({
                      target: {
                        name: 'occasionsSelected',
                        value: selected
                      }
                    })}
                    options={occasionOptions}
                    isDisabled={archived}
                  />
                  {
                    hasOccasionsMatchedTitleDecorations &&
                    <span className="text-muted small">Actual Product Title: {actualProductTitle}</span>
                  }
              </div>
              <div className="d-grid gap-1">
                <label>For Who</label>
                <Select
                  value={forWhoSelected}
                  isMulti
                  onChange={(selected) => this.handleOnChange({
                    target: {
                      name: 'forWhoSelected',
                      value: selected
                    }
                  })}
                  options={forWhoOptions}
                  isDisabled={archived}
                />
                {
                  hasForWhoMatchedTitleDecorations &&
                  <span className="text-muted small">Actual Product Title: {actualProductTitle}</span>
                }
              </div>
              {
                country === 'MY' &&
                  <Row className="gap-3 gap-lg-0" style={{maxWidth: 'unset', width: 'unset'}}>
                    <Col className="d-grid gap-1">
                      <label>Does this product contain alcohol/pork?</label>
                      <Form.Select className="form-control" name="not_halal" value={not_halal} required onChange={this.handleOnChange} disabled={archived}>
                        <option value="">Select an option</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </Col>
                    {
                      not_halal == 'no' &&
                        <>
                          <Col lg={6} className="d-grid gap-1">
                            <label>Do you have Halal certificate?</label>
                            <Form.Select className="form-control" name="have_halal_cert" value={have_halal_cert} required onChange={this.handleOnChange} disabled={archived}>
                              <option value="">Select an option</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </Form.Select>
                          </Col>
                          {
                            have_halal_cert == 'yes' && !archived &&
                              <Col xs={12} className="d-grid gap-1 mt-0 mt-lg-3">
                                <label>Attach Halal Certificate</label>
                                <Dropzone
                                  className='dropzone-area'
                                  style={{display: 'inline-block'}}
                                  multiple={false}
                                  onDrop={this.onDropHalalCert}
                                  accept='image/*'
                                >
                                  <div className="dropzone-text">
                                    <img src={uploadIcon} style={{width: '30px', margin: '0 10px'}}></img>
                                    <span>Drag and drop files here, or click to upload</span>
                                  </div>
                                </Dropzone>
                                &nbsp;
                                {
                                  halal_cert &&
                                    <span>{halal_cert}</span>
                                }
                              </Col>
                          }
                        </>
                    }
                  </Row>
              }
              {
                this.props.selectFlowerType &&
                  <div className="d-grid gap-1">
                    <label>Flower Type</label>
                    <Select
                      value={flowerTypesSelected}
                      isMulti
                      onChange={(selected) => this.handleOnChange({
                        target: {
                          name: 'flowerTypesSelected',
                          value: selected
                        }
                      })}
                      options={flowerTypeOptions}
                      isDisabled={archived}
                    />
                    {
                      hasFlowerTypesMatchedTitleDecorations &&
                      <span className="text-muted small">Actual Product Title: {actualProductTitle}</span>
                    }
                  </div>
              }
            </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0" className="tealCard my-4 shadow-sm">
            <Accordion.Header className="tealCardHeader product-accordion customisation" style={{borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'}}>
              Product Customisation
              { !archived &&
              <div className="ms-auto">
                { sorting ?
                <>
                  <a className="d-print-none py-0 btn-br-6 ms-auto me-2 btn btn-secondary" onClick={this.handleOnClickCancelSortFields}>Cancel</a>
                  <a className="d-print-none py-0 btn-br-6 ms-auto me-2 btn btn-primary" onClick={this.handleSaveSortFields}>Confirm Reorder</a>
                </>
                :
                <>
                  { isAdmin &&
                  <a className="d-print-none py-0 btn-br-6 ms-auto me-2 btn btn-secondary" onClick={this.handleOnClickSortFields}><FontAwesomeIcon icon={solid('arrows-up-down')}/> Reorder Fields</a>
                  }
                  <a className="d-print-none py-0 btn-br-6 ms-auto me-2 btn btn-secondary" onClick={this.handleOnClickAddFields}><FontAwesomeIcon icon={solid('plus')}/> Add Fields</a>
                </>
                }
              </div>
              }
            </Accordion.Header>
            <Accordion.Body className="d-grid gap-3">
            <DndProvider backend={HTML5Backend}>
              { sorting ?
                _.map(sortFields, (field, i) => {
                  return <SortCustomizeFieldRow key={i} moveField={this.handleMoveField} field={field} index={i} />
                })
              :
          <ListGroup>
            {
              _.map(customizeFields, (field, i) => {
                let requiredFieldProps = _.filter(field, (value, key) => {
                  return _.includes(['maxChar', 'primaryTitle', 'limit', 'options'], key)
                })

                let propsRequired = _.reduce(requiredFieldProps, (req, prop) => {
                  if(prop == '' || (Array.isArray(prop) && prop.length <= 1)){
                    return true
                  }

                  return prop
                }, false)

                let values = {
                  primaryTitle: field.primaryTitle,
                  maxChar: field.maxChar,
                  secondTitle: field.secondTitle,
                  limit: field.limit,
                  options: field.options,
                  required: field.required,
                }

                let titles = [...variantConditionalTitles]
                _.each(customizeFields, (field, index) => {
                  if (field.conditionalFields) {
                    _.each(field.conditionalFields, cond => {
                      titles.push(cond.primaryTitle)
                    })
                  }
                  if (index !== i) {
                    titles.push(field.primaryTitle)
                  }
                })

                return (
                  <Card key={i} className="greyCard grey-label label-strong mb-3">
                    <Card.Header className="greyCardHeader">
                      <Row className="fields-header">
                        <Col className="d-grid gap-1">
                          <label>{field.title}</label>
                          <em className={propsRequired === true ? 'pull-right' : 'hidden'}>Please fill up the properties to create the input field</em>
                        </Col>
                        { !archived &&
                        <Col xs={2} style={{textAlign: 'right'}}>
                          <Button className="mt-auto"onClick={() => this.handleRemoveField(i)} variant="danger" size="small">
                            <FontAwesomeIcon icon={solid('close')}/>
                          </Button>
                        </Col>
                        }
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <FieldCreate tagType={field.tagType} values={values} onChange={this.handleOnChange} index={i} handleAddOption={this.handleAddOption} handleEditOptions={this.handleEditOptions} handleRemoveOption={this.handleRemoveOption} addConditionalField={() => this.handleOnClickAddFieldsConditional(i)} isAdmin={isAdmin} showAddConditionalField={!corporate} titles={titles} archived={archived}/>

                      { _.map(field.conditionalFields, (conditionalField, j) => {
                        let titles = [...variantConditionalTitles]
                        _.each(customizeFields, (field) => {
                          if (field.conditionalFields) {
                            _.each(field.conditionalFields, (cond, index) => {
                              if (index !== j) {
                                titles.push(cond.primaryTitle)
                              }
                            })
                          }
                          titles.push(field.primaryTitle)
                        })

                        return (
                          <Card key={j} className="greyCard mt-3 mx-2 border border-2">
                            <Card.Header className="greyCardHeader">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>
                                  <Badge bg="secondary" className="me-2">CONDITIONAL</Badge>
                                  {conditionalField.title}
                                </div>
                                <Button className="mt-auto px-2 py-0" onClick={() => this.handleRemoveFieldConditional(i, j)} variant="danger" size="small">
                                  <FontAwesomeIcon icon={solid('close')} />
                                </Button>
                              </div>
                              <hr className="my-2" />
                              <Row>
                                <Col xs={12} sm={3} className="d-flex align-items-center">
                                  <label>When Option(s) Is:</label>
                                </Col>
                                <Col xs={12} sm={9}>
                                  <Select
                                    value={conditionalField.selected || []}
                                    isMulti
                                    onChange={(selected) => this.handleOnChangeConditionalSelected(selected, j, i)}
                                    required={true}
                                    options={conditionalField.valueOptions.map(option => {
                                      let value = option.value.split('#')[0]
                                      return { label: value, value: value }
                                    })}
                                    isDisabled={archived}
                                  />
                                </Col>
                              </Row>
                            </Card.Header>
                            <Card.Body className="d-grid gap-2 label-strong grey-label">
                              <FieldCreate
                                tagType={conditionalField.tagType}
                                index={j}
                                values={conditionalField}
                                onChange={(e) => this.handleOnChangeConditionalField(e, i, j)}
                                handleAddOption={() => this.handleAddOptionConditional(i, j)}
                                handleEditOptions={(e, optionIndex) => this.handleEditOptionsConditional(e, i, j, optionIndex, conditionalField.tagType)}
                                handleRemoveOption={(optionIndex) => this.handleRemoveOptionConditional(optionIndex, j, i)}
                                isAdmin={isAdmin}
                                showAddConditionalField={false}
                                titles={titles}
                                archived={archived}
                              />
                            </Card.Body>
                          </Card>
                        )
                      })}
                    </Card.Body>
                  </Card>
                  )
                })
              }
          </ListGroup>
              }
            </DndProvider>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>

      {
        showFieldsModal &&
          <FieldsModal onSelectField={this.handleAddField} onClose={this.handleOnCloseAddFields} conditional={addConditional} mainAddConditional={(field) => this.handleAddConditional(field, fieldIndex)} isAdmin={isAdmin} conditionalOptions={conditionalOptions} corporate={corporate}/>
      }
      {
        showDeliveryFinePrintsModal &&
        <DeliveryFinePrintsModal deliveryFinePrints={deliveryFinePrintsOptions} onClose={this.handleOnCloseDeliveryFinePrintsModal} onSaved={this.handleOnSaveDeliveryFinePrints} />
      }
      {
        showCreateDeliveryTemplate &&
        <CreateDeliveryTemplateModal onClose={this.handleOnCloseCreateDeliveryTemplateModal} onSaved={this.handleOnSaveCreateDeliveryTemplate} vendor={vendor}/>
      }
      {
        showPickupAddressModal &&
        <EditPickupAddressModal onClose={this.handleOnCloseEditPickupAddressModal} address={vendorTools} vendor={vendor} onSaved={this.handleOnSavePickupAddress}/>
      }
        {
          showSelfPickupExamples &&
          <InfoModal onClose={this.handleOnCloseSelfPickupExamples} title="How to Create Self Pick-up Variant" size="xl" footerCloseButton={true}>
            <Row>
              <Col sm={6} className="d-flex flex-column align-items-baseline gap-2">
                <h5>Delivery Area</h5>
                <img src={deliveryArea} className="mw-100"/>
                <img src={deliveryAreaSetup} className="mw-100"/>
              </Col>
              <Col sm={6} className="d-flex flex-column align-items-baseline gap-2">
                <h5>Delivery Method/Option</h5>
                <img src={deliveryMethod} className="mw-100"/>
                <img src={deliveryMethodSetup} className="mw-100"/>
              </Col>
            </Row>
          </InfoModal>
        }
      </Row>
    )
  }
}

export default connect((state, props) => {
  return {
    user: state.auth.user,
  };
}, {})(ProductAttributes);
