import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class RemarkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: ""
    }
  }

  componentDidMount() {
    const { note } = this.props;

    this.setState({
      note: note
    });
  }

  handleOnClickSave = () => {
    const { onClickSave } = this.props;
    const { note } = this.state;
    onClickSave(note);
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleOnInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    const { item } = this.props;
    const { note } = this.state;

    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
            <h4 className="mb-0">Note</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          <form>
            <textarea className="form-control" id="note" name="note" placeholder="Enter note" value={note} onChange={this.handleOnInputChange}/>
          </form>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={this.handleOnClose}>Cancel</Button>
          <Button variant="primary" onClick={this.handleOnClickSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RemarkModal;