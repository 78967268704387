import {
  FETCH_TAGGINGS_REQUEST,
  FETCH_TAGGINGS_SUCCESS,
  FETCH_TAGGINGS_FAILURE,
} from '../actions/taggings';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
};

export default function taggings(state = defaultState, action) {
  switch (action.type) {
    case FETCH_TAGGINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_TAGGINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.payload.items,
        lastUpdated: action.payload.receivedAt,
        // next: action.payload.next
      };
    case FETCH_TAGGINGS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
