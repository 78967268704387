import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from '../helpers/apiClient';
import _ from 'lodash'
import * as errorActionCreators from '../redux/actions/error';
import * as previewProductActionCreators from '../redux/actions/previewProduct';

import config from '../config'
import qs from 'qs';
import { autoRehydrate } from 'redux-persist';
import ProductPreviewMessage from '../components/ProductPreviewMessage';
import DoorToDoorTemplate from '../components/DoorToDoorTemplate';
import CourierTemplate from '../components/CourierTemplate';
import SeasonalTemplate from '../components/SeasonalTemplate';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : '$'


class PreviewProduct extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        const { previewProduct } = this.props;

        console.log('previewProduct', previewProduct)
    }


    render () {
        const { previewProduct } = this.props;

        return(
            <div>
            {
                previewProduct.item.template_suffix == 'door-to-door' &&
                <DoorToDoorTemplate></DoorToDoorTemplate>
            }
            {
                previewProduct.item.template_suffix == 'courier' &&
                <CourierTemplate></CourierTemplate>
            }
            {
                previewProduct.item.template_suffix == 'seasonal' &&
                <SeasonalTemplate></SeasonalTemplate>
            }
            </div>
            )
        }
}

export default connect((state, props) => {
    return {
      error: state.error,
      user: state.auth.user,
      previewProduct: state.previewProduct
    };
  }, {
    ...errorActionCreators,
    ...previewProductActionCreators
  })(PreviewProduct);

