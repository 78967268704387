import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone'
import uploadIcon from '../images/upload-icon.svg'
import shortid from 'shortid';

class EditVMModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      uploadingFile: false,
    }
  }

  componentDidMount() {
    const { link } = this.props;

    this.setState({
      link
    });
  }

  handleOnClickSave = () => {
    const { onClickSave } = this.props;
    const { link } = this.state;
    onClickSave(link);
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleOnInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  onDrop = async file => {
    const media = (file && file.length > 0) && file[0]

    if(media){
      const err = this.checkMedia(media);
      if(err){
        this.setState({
          errorMsg: err,
        })
        return
      }

      const ext = media.type.includes('audio') ? 'm4a' : 'mp4'
      const type = media.type.includes('audio') ? 'audio/mp4' : 'video/mp4'

      let file = new File([media], `${shortid.generate()}.${ext}`, {
        type
      })
      const body = {
        ext,
        type
      }

      // get presigned url
      const data = await fetch('https://bh5uwl05lc.execute-api.ap-southeast-1.amazonaws.com/dev/vm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .catch(error => {
          this.setState({
            error,
            uploadingFile: false,
          })
        })

      const { shortUrl, presignedUrl, bucket, key } = data;

      // upload
      const uploaded = await fetch(presignedUrl, {
        method: "PUT",
        body: file,
      }).catch(error => {
        this.setState({
          error,
          uploadingFile: false,
        })
      })

      if(uploaded.status !== 200){
        console.log(uploaded);
        return
      }

      console.log('uploaded');

      this.setState({
        link: shortUrl
      });

      // transcode
      fetch('https://p4ao32k20d.execute-api.ap-southeast-1.amazonaws.com/v1/transcode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          bucket,
          key,
          ext,
          type: media.type.includes('audio') ? 'audio/mp4' : 'video/mp4',
        })
      })
    }
  }

  checkMedia = file => {
    let error = '';

    if(file && file.size > 20000000){
      error = error.concat(`File size is too big.`)
    }

    // if(this.outputRef.duration > 120){
    //   error = error.concat(`File length is too long.`)
    // }

    return error
  }

  render() {
    const { link, uploadingFile, errorMsg } = this.state;

    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
            <h4 className="mb-0">Edit Virtual Message Link</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          <form>
            <input type="text" className="form-control" id="link" name="link" placeholder="Add link or upload file" value={link} onChange={this.handleOnInputChange}/>
          </form>
          <Dropzone
            style={{}}
            onDrop={this.onDrop}
            multiple={false}
          >
            <Button className="btn-virtual-message btn-vm-record" disabled={uploadingFile} >
              <img src={uploadIcon} style={{ width: '30px', margin: '0 10px 0 0' }}></img>
              Upload
            </Button>
            <span>Max file size: 20MB</span>
          </Dropzone>
          {
            errorMsg &&
              <span>{errorMsg}</span>
          }
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={this.handleOnClose}>Cancel</Button>
          <Button variant="primary" onClick={this.handleOnClickSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditVMModal;
