import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, InputGroup, Badge, Button, Row, Col, Form } from 'react-bootstrap';
import _ from 'lodash'
import * as errorActionCreators from '../redux/actions/error';
import * as merchantsActionCreators from '../redux/actions/merchants';
import productTypeOptions from '../data/product_type.json';
import Dropzone from 'react-dropzone';
import MobileDetect from 'mobile-detect';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateFromHTML } from 'draft-js-import-html';
import ProductAttributes from '../components/ProductAttributes';
import uploadIcon from '../images/upload-icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'

class ProductB2B extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
    }
  }

  componentDidMount() {
    const { item: { body_html } } = this.props;

    const { contentBlocks, entityMap } = convertFromHTML(body_html ? body_html.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '') : '')
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)

    this.setState({
      editorState: EditorState.createWithContent(contentState),
    })
  }

  editorChange = editorState => {
    const { handleEditorChange } = this.props;

     if(this.state.editorState != editorState){
       handleEditorChange(editorState)
     }

    this.setState({ editorState })
  }

  render() {
    const { editorState } = this.state;
    const { user, merchantsOptions, uploadingFile, item, merchants, pending, attributes, match, closed } = this.props;
    const { updateForm, handleOnClickShowReviewModal, onDropImage, handleImageReorder, handleRemoveImage, addHalalCert, updateAttrTags, onClickB2B, fixTierQty, removeTier, addTier } = this.props; // actions

    const isAdmin = user.role === 'admin' || user.role === 'staff'
    const isMobile = new MobileDetect(window.navigator.userAgent).mobile() !== null

    const showFlowerType = _.includes(['Flower Bouquet', 'Flower Boxes', 'Flower Stand'], item.product_type)


    return (
      <div>
        {
          (_.includes(match.path, 'pending') || _.includes(match.path, 'create')) && !item.has_b2c &&
            <Row className="mt-4">
              <Col xs="auto" className="label-strong"><label htmlFor='b2b'>Sell in Bulk</label></Col>
              <Col xs={1}>
                <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                  <input onChange={e => onClickB2B(e)} name="b2b" type="checkbox" style={{marginLeft: "5px"}} checked={item.b2b}/>
                  <span className="checkmark checkmark-smaller"></span>
                </label>
              </Col>
            </Row>
        }
        {
          (item.b2b || _.includes(item.tags, 'corporate_order')) &&
          <>
            <Row>
              <Col lg={6}>
                <Card className="tealCard mt-4 shadow-sm">
                  <Card.Header className="tealCardHeader">
                    Product Info
                    {(item.status === 'draft' || item.status === 'active') && user && isAdmin &&
                      <a href={`https://helpr-asia.myshopify.com/admin/products/${item.id}`} target="_blank" className="pull-right btn btn-secondary btn-br-6 py-0 ">View in Shopify</a>}
                  </Card.Header>
                  <Card.Body className="grey-label label-strong">
                    <Row className="d-grid gap-3">
                      <Col className="d-grid gap-1">
                        <label>Title</label>
                        <input className="form-control" name="title" type="text" placeholder="Title" onChange={e => updateForm(e)} required value={item.title} />
                      </Col>
                      {item.status && (item.status === 'active' || item.status === 'draft') ?
                  <Col className="d-grid gap-1">
                    <label>Status</label>
                    <Form.Select className="form-control" name="status" onChange={e => updateForm(e)} required value={item.status}>
                      <option value="active">Active</option>
                      <option value="draft">Draft</option>
                    </Form.Select>
                  </Col>
                  :
                  <Col className="d-grid gap-1">
                    <div className="mb-2">
                      <label>Approval Status</label>
                      {item.approval_status === 'create' &&
                        <Badge className="mx-3" bg="primary">CREATE</Badge>}
                      {item.approval_status === 'disapproved' &&
                        <Badge className="mx-3" bg="danger">REJECTED</Badge>}
                      {item.approval_status === 'approved' &&
                        <Badge className="mx-3" bg="success">APPROVED</Badge>}
                      {item.approval_status === 'more_info' &&
                        <Badge className="mx-3" bg="info">MORE INFO</Badge>}
                      {item.approval_status === 'pending' &&
                        <Badge className="mx-3" bg="warning">CREATED</Badge>}
                      {item.approval_status === 'reviewing' &&
                        <Badge className="mx-3" bg="primary">IN REVIEW</Badge>}
                    </div>
                    {isAdmin &&
                      <div>
                        <label>Reviewer</label>
                        <span className="mx-3">{item.approved_by}</span>
                        {pending &&
                          <Button className="mx-3 py-0" variant="secondary" onClick={e => handleOnClickShowReviewModal(e)}>
                            Start to Review
                          </Button>}
                      </div>}
                  </Col>}
                {isAdmin &&
                  <Col className="d-grid gap-1">
                    <label>Vendor</label>
                    <Form.Select className="form-control" name="vendor" onChange={e => updateForm(e)} required value={item.vendor}>
                      <option value="">Select a vendor</option>
                      {merchantsOptions.map(option => (
                        <option key={option.key} value={option.value}>{option.name}</option>
                      ))}
                    </Form.Select>
                  </Col>}
                {merchants && merchants.length > 1 &&
                  <Col className="d-grid gap-1">
                    <label>Vendor</label>
                    <Form.Select className="form-control" name="vendor" onChange={e => updateForm(e)} required value={item.vendor}>
                      {merchants.map(merchant => (
                        <option key={merchant.name} value={merchant.name}>{merchant.name}</option>
                      ))}
                    </Form.Select>
                  </Col>}
                <Col className="d-grid gap-1">
                  <label>Type</label>
                  <Form.Select className="form-control" name="product_type" onChange={e => updateForm(e)} required value={item.product_type}>
                    <option value="">Select a product type</option>
                    {productTypeOptions.map(option => (
                      <option key={option.key} value={option.value}>{option.name}</option>
                    ))}
                  </Form.Select>
                </Col>
                <Col className="d-grid gap-1">
                  <label>Description</label>
                  <div style={{ border: '1px solid #ced4da', borderRadius: '4px' }}>
                    <Editor
                      onEditorStateChange={this.editorChange}
                      editorState={editorState}
                      editorStyle={{ resize: 'vertical', height: '150px', padding: '0 1rem' }} />
                  </div>
                </Col>
                {isAdmin &&
                  <Col className="d-grid gap-1">
                    <label>Tags</label>
                    <textarea readOnly name="tags" className="form-control" value={item.tags} onChange={e => updateForm(e)} style={{ height: '70px', resize: 'vertical' }} />
                  </Col>}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
                <Col lg={6}>
                  <Card className="tealCard shadow-sm mt-4">
                  <Card.Header className="tealCardHeader">Product Media</Card.Header>
                    <Card.Body className="d-grid gap-3">
                      <Dropzone
                        className='dropzone-area'
                        onDrop={e => onDropImage(e)}
                        multiple={true}
                        accept='image/*'>
                        <div className="dropzone-text">
                          <img src={uploadIcon} style={{ width: '30px', margin: '0 10px' }}></img>
                          <span>{uploadingFile ? 'Uploading...' : 'Drag and drop files here, or click to upload'}</span>
                        </div>
                      </Dropzone>
                      {item.images && item.images.length > 0 &&
                        <Row>
                          {_.map(item.images, (image, i) => {
                            return image && image.src &&
                              <Col xs={6} md={4} xl={3} key={i} className="product-detail-image">
                                <img src={image.src} />
                                <div style={{ visibility: 'hidden' }} className="d-flex">
                                  <Button variant="secondary" size="small" onClick={() => handleImageReorder(i, -1)}>
                                    <FontAwesomeIcon icon={solid('arrow-left')} />
                                  </Button>
                                  <Button className="ms-2" variant="secondary" size="small" onClick={() => handleImageReorder(i, 1)}>
                                    <FontAwesomeIcon icon={solid('arrow-right')} />
                                  </Button>
                                  <Button onClick={() => handleRemoveImage(i)} variant="danger" size="small" className="ms-auto">
                                    <FontAwesomeIcon icon={solid('close')} />
                                  </Button>
                                </div>
                              </Col>;
                          })}
                        </Row>}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <ProductAttributes attr={attributes} addHalalCert={e => addHalalCert(e)} selectFlowerType={showFlowerType} updateAttrTags={updateAttrTags} closed={closed}/>
              <Col>
              <Card className="tealCard shadow-sm mt-4">
              <Card.Header className="tealCardHeader">Pricing Tiers</Card.Header>
                <Card.Body className="grey-label label-strong d-grid gap-2">
                  <Row className='table-responsive mx-1'>
                    <table className="table">
                      <thead>
                        <tr>
                          <th width="200"></th>
                          <th width="200">Min</th>
                          <th width="200">Max</th>
                          <th width="200">Unit Price</th>
                          <th width="70"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          item.pricing_tier.map((tier, i) => {
                            console.log(tier);
                            return (
                              <tr key={i}>
                                <td>Price Tier {i+1}</td>
                                <td>
                                  <input className="form-control" name={`tier_min_quantity#${i}`} type="number" step="1" onChange={e => updateVariants(e)} onBlur={e => fixTierQty(false)} required value={tier.min_quantity} disabled={i > 0}/>
                                </td>
                                <td>
                                  <input className="form-control" name={`tier_max_quantity#${i}`} type="number" step="1" onBlur={() => fixTierQty(true)} onChange={e => updateVariants(e)} required value={tier.max_quantity}/>
                                </td>
                                <td>
                                  <InputGroup>
                                    <InputGroup.Text>RM</InputGroup.Text>
                                    <input className="form-control" name={`tier_price#${i}`} type="number" step=".01" onChange={e => updateVariants(e)} required value={tier.price}/>
                                  </InputGroup>
                                </td>
                                <td className="align-middle">
                                  <i id={`tier_remove_${i}`} onClick={e => removeTier(e)} style={{cursor: 'pointer', color: '#D15D5D'}}><FontAwesomeIcon icon={solid('trash')}/></i>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    </Row>
                    <Button className="w-100" onClick={e => addTier(e)}>
                      Add Price Tier&nbsp;
                      <FontAwesomeIcon icon={solid('plus')} transform="shrink-1" className="mx-2"/>
                    </Button>
                  </Card.Body>
                </Card>
                </Col>
                <Col>
                  <Card className="tealCard shadow-sm mt-4">
                  <Card.Header className="tealCardHeader">Shipping Rates</Card.Header>
                    <Card.Body className="grey-label label-strong">
                      <Row className="my-2">
                        <Col xs={6} className="d-grid gap-2 pe-5">
                          <label>East Malaysia</label>
                          <InputGroup>
                            <InputGroup.Text>RM</InputGroup.Text>
                            <input className="form-control" name="shipping_east_my" type="text" onChange={e => updateForm(e)} required value={item.shipping_east_my} />
                          </InputGroup>
                        </Col>
                        <Col xs={6} className="d-grid gap-2 ps-5">
                          <label>West Malaysia</label>
                          <InputGroup>
                            <InputGroup.Text>RM</InputGroup.Text>
                            <input className="form-control" name="shipping_west_my" type="text" onChange={e => updateForm(e)} required value={item.shipping_west_my} />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
              </Col>
            </>
        }
      </div>
    )
  }
}

const defaultOptions = [];

export default connect((state, props) => {
  return {
    error: state.error,
    user: state.auth.user,
    merchantsOptions: (state.merchants && state.merchants.items) ? state.merchants.items.map(merchant => {
      return {
        key: merchant,
        value: merchant,
        name: merchant,
      }
    }) : defaultOptions,
  };
}, {
  ...errorActionCreators,
  ...merchantsActionCreators,
})(ProductB2B);
