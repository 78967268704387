import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, Button, Badge, Row, Col, Container } from 'react-bootstrap';
import ConfirmBillModal from '../components/ConfirmBillModal';
import BillAdjustmentModal from './BillAdjustmentModal';
import AttachedRevisedInvoiceModal from './AttachedRevisedInvoiceModal';
import GTRAlert from '../components/GTRAlert';
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import * as billDetailActionCreators from '../redux/actions/billDetail';
import * as errorActionCreators from '../redux/actions/error';
import Dropzone from 'react-dropzone'
import ReactTooltip from 'react-tooltip';
import config from '../config'
import ConfirmModal from '../components/ConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import PaymentDateModal from '../components/PaymentDateModal';
import TabHeader from '../components/TabHeader';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

let billId = undefined

class BillDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedUnfulfilledItemIds: [],
      selectedFulfillmentId: null,
      showConfirmModal: false,
      showAdjustmentModal: false,
      showAttachRevisedInvoiceModal: false,
      showDeleteBillModal: false,
      uploadingFile: false,
      selectedIds: [],
      adjustmentSelectedIds: [],
      showPaymentDateModal: false,
    };
  }

  componentDidMount() {
    const { fetchBillDetail, match } = this.props;
    billId = match.params.billId
    fetchBillDetail(match.params.billId);

  }

  handleOnClickShowConfirmModal = () => {
    this.setState({ showConfirmModal: true });
  }

  handleOnCloseShowConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  }

  handleOnClickShowAdjustmentModal = () => {
    this.setState({ showAdjustmentModal: true });
  }

  handleOnCloseShowAdjustmentModal = () => {
    this.setState({ showAdjustmentModal: false });
  }

  handleOnClickShowAttachRevisedInvoiceModal = () => {
    this.setState({ showAttachRevisedInvoiceModal: true });
  }

  handleOnCloseShowAttachRevisedInvoiceModal = () => {
    this.setState({ showAttachRevisedInvoiceModal: false });
  }

  handleOnShowDeleteBillModel = () => {
    this.setState({ showDeleteBillModal: true });
  }

  handleOnHideDeleteBillModel = () => {
    this.setState({ showDeleteBillModal: false });
  }

  renderUnitPrice = (cell, row) => {

    return <span>{currency} {row.price.toFixed(2)}</span>
  }

  renderPrice = (cell, row) => {

    return cell ? <span>{currency} {cell.toFixed(2)}</span> : <span>{currency} 0.00</span>
  }

  renderLineItemPrice = (cell, row) => {

    return <span>{currency} {(row.price * row.quantity).toFixed(2)}</span>
  }

  exportLineItemPrice = (cell, row) => {

    return (row.price * row.quantity).toFixed(2)
  }

  renderItemCommission = (cell, row) => {

    return <span>-{currency} {((row.adjustment_commission ? row.adjustment_commission : 0) + row.commission).toFixed(2)}</span>
  }

  renderDiscount = (cell, row) => {

    return <span>-{currency} {row.discount.toFixed(2)}</span>
  }

  renderItemGst = (cell, row) => {

        return <span>-{currency} {((row.adjustment_tax ? row.adjustment_tax : 0) + row.gst).toFixed(2)}</span>
  }

  renderShippinFee = (cell, row) => {

        return <span>{currency} {row.shipping_fee.toFixed(2)} { row.shipping_fee_not_accurate && <i className="fa fa-info-circle"></i>}</span>
  }

  renderBalance = (cell, row) => {

    return <span>{currency} {((row.price * row.quantity) - row.commission - row.gst - row.discount).toFixed(2)}</span>
  }

  renderTotalBalance = (cell, row) => {

    return <span>{currency} {((row.price * row.quantity) - row.commission - row.gst - row.discount + row.shipping_fee - (row.adjustment_tax ? row.adjustment_tax : 0) - (row.adjustment_commission ? row.adjustment_commission : 0) + (row.adjustment_shipping ? row.adjustment_shipping : 0) + (row.adjustment_item_price ? row.adjustment_item_price : 0)).toFixed(2)}</span>
  }

  exportBalance = (cell, row) => {

    return ((row.price * row.quantity) - row.commission - row.gst - row.discount).toFixed(2)
  }

  renderFulfillAt = (cell, row) => {
    return <span>{moment(cell).format('DD-MM-YYYY')}</span>
  }

  renderAdjustmentDate = (cell, row) => {
    return <span>{moment(cell).format('DD-MM-YYYY')}</span>
  }

  renderAdjustmentAmount = (cell, row) => {

    if(cell){
      return <span>{currency} {cell.toFixed(2)}</span>
    }

    return <span>{currency} {cell}</span>
  }

  renderAdjustmentCommission = (cell, row) => {

    if(row.commission === undefined){
      return <span>{currency} 0.00</span>
    } else {
      return <span>{currency} {row.commission.toFixed(2)}</span>
    }
  }

  renderAdjustmentGst = (cell, row) => {

    if(row.gst === undefined){
      return <span>{currency} 0.00</span>
    }

    return <span>{currency} {row.gst.toFixed(2)}</span>
  }

  renderAdjustmentBalance = (cell, row) => {

    let commission = parseFloat(row.commission)
    let gst = parseFloat(row.gst)

    if(row.commission === undefined){
      commission = 0
    }

    if(row.gst === undefined){
      gst = 0
    }

    let balance = row.amount - commission - gst + row.shipping_fee

    return <span>{currency} {balance.toFixed(2)}</span>
  }

  exportAdjustmentBalance = (cell, row) => {

    let commission = parseFloat(row.commission)
    let gst = parseFloat(row.gst)

    if(row.commission === undefined){
      commission = 0
    }

    if(row.gst === undefined){
      gst = 0
    }

    let balance = row.amount - commission - gst + row.shipping_fee

    return balance.toFixed(2)
  }

  renderLink = (cell, row) => {

    if(row.manual_order){
      return <Link
        key={row.id}
        to={{
          pathname: `/manual_orders/${row.order_id}`,
          state: {modal: false}
        }}>
        {row.order_name}
      </Link>
    }

    return <Link
      key={row.id}
      to={{
        pathname: `/orders/${row.order_id}`,
        state: {modal: false}
      }}>
      {row.order_name}
    </Link>
  }

  handleExportBill = () => {

    const { fetchBillDetail, match } = this.props;
    let billId = match.params.billId

    let url = window.location.pathname
    url = url.replace("legacy_bills", "bills")
    return api(url + `/export` , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((result) => {

      if(result.url){
        window.location.href = result.url;
      }
    }).catch(err => {
      if (err) {
        alert(err.toString())
      }
    });
  }

  handleDeleteBill = () => {

    const { history } = this.props;

    let url = window.location.pathname
    url = url.replace("legacy_bills", "bills")
    return api(url + `/delete` , {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((result) => {
      history.push('/bills')
    })
  }

  handleDownloadInvoice = () => {
    const { billDetail: { item } } = this.props;
    window.open(item.invoice_link);
  }

  handleUnbill = () => {

    const { fetchBillDetail, match } = this.props;
    let billId = match.params.billId

    let url = window.location.pathname
    url = url.replace("legacy_bills", "bills")
    return api(url + `/unbill` , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((result) => {

      fetchBillDetail(billId);
    }).catch(err => {
      if (err) {
        alert(err.toString())
      }
    });
  }

  handleConfirmBill = () => {

    this.setState({ showConfirmModal: false });

    const { fetchBillDetail, match } = this.props;
    let billId = match.params.billId

    let url = window.location.pathname
    url = url.replace("legacy_bills", "bills")
    return api(url + '/confirm' , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((result) => {

      fetchBillDetail(billId);
    }).catch(err => {
      if (err) {
        alert(err.toString())
      }
    });
  }

  handleAddAdjustment = (name, amount, commission, gst, shippingFee, adjustmentInfo, purchaseDate) => {

      let numberAmount = parseFloat(amount)
      let body = {
        name, amount: numberAmount, commission, gst, shippingFee: parseFloat(shippingFee), adjustmentInfo, purchaseDate
      }

      this.setState({ showAdjustmentModal: false });

      const { fetchBillDetail, match } = this.props;
      let billId = match.params.billId

      let url = window.location.pathname
      url = url.replace("legacy_bills", "bills")
      return api(url + '/adjustment' , {
        method: 'POST',
        body: JSON.stringify(body)
      }).then((result) => {

        fetchBillDetail(billId);
      }).catch(err => {
        if (err) {
          alert(err.toString())
        }
      });
  }

  customConfirm = () => {

    const { selectedIds } = this.state;
    let dropRowKeysStr = null;
    if(selectedIds.length <= 0){
      return
    } else if(selectedIds.length === 1){
      dropRowKeysStr = "this row"
    } else {
      dropRowKeysStr = `${selectedIds.length} rows`
    }

    if (confirm(`Are you sure you want to delete ${dropRowKeysStr}?`)) {

      let url = window.location.pathname
      url = url.replace("legacy_bills", "bills")
      return api(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ids: selectedIds
        })
      }).then((result) => {

        window.location.reload()
      }).catch(err => {
        if (err) {

          alert(err.toString())
        }
      });
    }
  }

  confirmDeleteAdjustment = () => {

    const { adjustmentSelectedIds } = this.state;
    let dropRowKeysStr = null;
    if(adjustmentSelectedIds.length <= 0){
      return
    } else if(adjustmentSelectedIds.length === 1){
      dropRowKeysStr = "this row"
    } else {
      dropRowKeysStr = `${adjustmentSelectedIds.length} rows`
    }

    if (confirm(`Are you sure you want to delete ${dropRowKeysStr} (adjustment)?`)) {

      let url = window.location.pathname + '/adjustment'
      url = url.replace("legacy_bills", "bills")
      return api(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ids: adjustmentSelectedIds
        })
      }).then((result) => {

        window.location.reload()
      }).catch(err => {
        if (err) {

          alert(err.toString())
        }
      });
    }
  }

  onDropPaymentSlip(files) {

    if(files && files.length > 0){
      this.setState({
        uploadingFile: true
      });

      const { fetchBillDetail, billDetail: {item : { vendor }} } = this.props;

      var formData = new FormData();
      formData.append('payment_slip', files[0]);
      formData.append('vendor', vendor)

      let url = window.location.pathname
      url = url.replace("legacy_bills", "bills")
      api(url + '/attach/payment' , {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'}).then(data => {
        this.setState({
          uploadingFile: false
        });
        fetchBillDetail(billId);
      }).catch(err =>{
        this.setState({
          uploadingFile: false
        });
        console.log(err)
      })
    }
  }

  handleSaveRevisedInvoice(file, invoiceName) {

    const { fetchBillDetail, billDetail: {item : { vendor }} } = this.props;
    this.setState({
      uploadingFile: true
    });

    var formData = new FormData();
    formData.append('revised_invoice', file);
    formData.append('invoice_name', invoiceName)

    let url = window.location.pathname
    url = url.replace("legacy_bills", "bills")
    api(url + '/attach/revised_invoice' , {
      method: 'POST',
      body: formData
    }, {'Accept': 'application/json'}).then(data => {
      this.setState({
        uploadingFile: false
      });
      fetchBillDetail(billId);
      this.handleOnCloseShowAttachRevisedInvoiceModal()
    }).catch(err =>{
      this.setState({
        uploadingFile: false
      });
      this.handleOnCloseShowAttachRevisedInvoiceModal()

      console.log(err)
    })
  }

  onDropRevisedStatement(files) {

    if (files && files.length > 0) {
      this.setState({
        uploadingFile: true
      });

      const { fetchBillDetail, billDetail: { item: { vendor } } } = this.props;

      var formData = new FormData();
      formData.append('payment_slip', files[0]);
      formData.append('vendor', vendor)

      let url = window.location.pathname
      url = url.replace("legacy_bills", "bills")
      api(url + '/attach/revised', {
        method: 'POST',
        body: formData
      }, { 'Accept': 'application/json' }).then(data => {
        this.setState({
          uploadingFile: false
        });

        fetchBillDetail(billId);
      }).catch(err => {
        this.setState({
          uploadingFile: false
        });

        console.log(err)
      })
    }
  }

  onRowSelect = (row, isSelected, e) => {

    let { selectedIds } = this.state;

    if(isSelected){
      selectedIds.push(row._id)
    } else {
      selectedIds = _.reject(selectedIds, i => i === row._id)
    }

    this.setState({ selectedIds });
  }

  onSelectAll = (isSelected, rows, e) => {
    let { selectedIds } = this.state;

    if (isSelected) {
      _.each(rows, function (row) {
        selectedIds.push(row._id)
      })
    } else {
      _.each(rows, function (row) {
        selectedIds = _.reject(selectedIds, i => i === row._id);
      });
    }

    this.setState({ selectedIds });

  }

  adjustmentOnRowSelect = (row, isSelected, e) => {

    let { adjustmentSelectedIds } = this.state;

    if(isSelected){
      adjustmentSelectedIds.push(row._id)
    } else {
      adjustmentSelectedIds = _.reject(adjustmentSelectedIds, i => i === row._id)
    }

    this.setState({ adjustmentSelectedIds });
  }

  adjustmentOnSelectAll = (isSelected, rows, e) => {
    let { adjustmentSelectedIds } = this.state;

    if (isSelected) {
      _.each(rows, function (row) {
        adjustmentSelectedIds.push(row._id)
      })
    } else {
      _.each(rows, function (row) {
        console.log(adjustmentSelectedIds, row._id);
        adjustmentSelectedIds = _.reject(adjustmentSelectedIds, i => i === row._id);
      });
    }

    this.setState({ adjustmentSelectedIds });

  }

  handleSetPaymentDate = async (payment_date) => {
    const { fetchBillDetail } = this.props;

    let url = window.location.pathname
    url = url.replace("legacy_bills", "bills")
    return api(url + '/payment_date' , {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ payment_date }),
    }).then(data => {
      if(data.success){
        this.setState({ showPaymentDateModal: false });
        fetchBillDetail(billId);
      } else {
        console.log(data.error);
      }
    }).catch(err =>{
      console.log(err)
    })
  }

  handleOnClickShowPaymentDateModal = () => {
    this.setState({ showPaymentDateModal: true });
  }

  handleOnCloseShowPaymentDateModal = () => {
    this.setState({ showPaymentDateModal: false });
  }

  render() {
    const { billDetail: { loading, loaded, item }, error, user } = this.props;
    const { showConfirmModal, showAdjustmentModal, showAttachRevisedInvoiceModal, uploadingFile, showDeleteBillModal, showPaymentDateModal } = this.state;

    let selectRowProps = {
      selectRow: {
        mode: "checkbox",
        headerColumnStyle: { width: '30px' },
        onSelect: this.onRowSelect,
        onSelectAll: this.onSelectAll,
      }
    }

    let adjustmentSelectRowProps = {
      selectRow: {
        mode: "checkbox",
        clickToSelect: true,
        onSelect: this.adjustmentOnRowSelect,
        onSelectAll: this.adjustmentOnSelectAll,
        headerColumnStyle: { width: '30px' },
      }
    }

    const columns = [
      {
        dataField: 'order_created_at',
        text: 'Purchase Date',
        formatter: this.renderFulfillAt,
        headerStyle: {
          width: '130px',
          resize: 'horizontal'
        },
      },
      {
        dataField: '_id',
        text: 'Bill',
        hidden: true,
      },
      {
        dataField: 'order_name',
        sort: true,
        text: 'Order',
        formatter: this.renderLink,
        headerStyle: {
          width: '120px',
          resize: 'horizontal',
        }
      },
      {
        dataField: 'fulfilled_at',
        text: 'Fulfill Date',
        formatter: this.renderFulfillAt,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'name',
        text: 'Name',
        headerStyle: {
          resize: 'horizontal'
        }
      },
      {
        dataField: 'price',
        text: 'Unit Price',
        formatter: this.renderUnitPrice,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'quantity',
        text: 'Qty',
        headerStyle: {
          width: '50px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'total_price',
        text: 'Item Price',
        csvText: 'Item Price',
        formatter: this.renderLineItemPrice,
        csvFormatter: this.exportLineItemPrice,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'adjustment_item_price',
        text: 'Item Price Adjust.',
        formatter: this.renderPrice,
        headerStyle: {
          width: '140px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'discount',
        text: 'Discount',
        formatter: this.renderDiscount,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'commission',
        text: '',
        formatter: this.renderItemCommission,
        headerFormatter: () => {
          return (
            <>
              <span data-tip="Included Commission Adjust. (if any)">Comm ({item.commission_rate}%) <i className="fa fa-info-circle"></i></span><ReactTooltip />
            </>
          )
        },
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'gst',
        formatter: this.renderItemGst,
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
        text: '',
        headerFormatter: () => {
          return (
            <>
              <span data-tip="Included SST Adjust. (if any)">SST <i className="fa fa-info-circle"></i></span><ReactTooltip />
            </>
          )
        }
      },
      {
        dataField: 'shipping_fee',
        text: 'Ship. Fee',
        formatter: this.renderShippinFee,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'adjustment_shipping',
        text: 'Ship. Adjust.',
        formatter: this.renderPrice,
        headerStyle: {
          width: '110px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'payout_bal',
        text: 'Total Payout Bal.',
        formatter: this.renderTotalBalance,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
      },
    ];

    const adjustment_columns = [
      {
        dataField: 'purchase_date',
        text: 'Purchase Date',
        formatter: this.renderAdjustmentDate,
        headerStyle: {
          width: '130px',
          resize: 'horizontal'
        },
      },
      {
        dataField: '_id',
        text: 'id',
        hidden: true,
      },
      {
        dataField: 'name',
        text: 'Order ID',
        headerStyle: {
          resize: 'horizontal',
        }
      },
      {
        dataField: 'created',
        text: 'Created',
        sort: true,
        formatter: this.renderAdjustmentDate,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'adjustment_info',
        text: 'Adjustment Info',
        headerStyle: {
          resize: 'horizontal'
        }
      },
      {
        dataField: 'amount',
        text: 'Item Price',
        formatter: this.renderAdjustmentAmount,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'commission',
        text: 'Commission',
        formatter: this.renderAdjustmentCommission,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'gst',
        text: 'SST',
        formatter: this.renderAdjustmentGst,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'shipping_fee',
        text: 'Shipping Fee',
        formatter: this.renderAdjustmentAmount,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'balance',
        text: 'Balance',
        csvFormatter: this.exportAdjustmentBalance,
        formatter: this.renderAdjustmentBalance,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
      },
    ];

    const isAdmin = user.role === 'admin'

    return (
      <div>
        {
          loading &&
          <Loader />
        }
        {
          error &&
          <GTRAlert variant="danger" alert={error} />
        }
        {
          !loading && item &&
          <>
            <TabHeader title="Billing Detail" tab_id="billing_detail" user={user} />

            <div className='d-grid gap-4'>
              <Row>
                <Col sm={6} md={5} lg={4} className="d-grid gap-4">
                  <Card className="dashboard-card shadow-sm p-2">
                    <Card.Body>
                        <h4 className="mb-0 d-inline-block" style={{color: '#80d7cf'}}>{item.name} bill</h4>
                        {
                          isAdmin &&
                          <Button variant="danger" className="pull-right ms-auto py-0 d-print-none" onClick={this.handleOnShowDeleteBillModel}>Delete Bill</Button>
                        }
                        <p className="mt-3 mb-0">created on {moment(item.created).format('DD/MM/YYYY')}</p>
                    </Card.Body>
                  </Card>
                  <Card className="tealCard shadow-sm">
                  <Card.Header className="tealCardHeader">Merchant Info</Card.Header>
                    <Card.Body className="grey-label label-strong p-4">
                      <Row className="flex-row">
                        <Col xs={6} className="d-grid gap-1 order-1 mb-3">
                          <label>Name</label>
                          {item.vendor}
                        </Col>
                        <Col xs={6} className="d-grid gap-1 order-3">
                          <label>SST Registered</label>
                          {item.gst_registered ? <Badge bg="info" className="text-uppercase" style={{width:'50%'}}>{item.gst_registered.toString()}</Badge> : <Badge bg="warning" className="text-uppercase" style={{width:'50%'}}>{item.gst_registered.toString()}</Badge>}
                        </Col>
                        <Col xs={6} className="d-grid gap-1 order-2 mb-3">
                          <label>Commission Type</label>
                          {item.commission_type}
                        </Col>
                        <Col xs={6} className="d-grid gap-1 order-4">
                          <label>Commission Rate</label>
                          {item.commission_rate}%
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
                <Col>
                  <Card className="tealCard mt-4 mt-sm-0 shadow-sm">
                    <Card.Header className="tealCardHeader">Bill Info</Card.Header>
                    <Card.Body className="grey-label label-strong">
                        <Row className='flex-row'>
                          <Col className="pt-2">
                            <Row className="mb-1">
                              <Col className="text-end"><label>Billing Period</label></Col>
                              <Col>{item.billing_period}</Col>
                            </Row>
                            <Row className="mb-1">
                              <Col className="text-end"><label>Status</label></Col>
                              <Col>{item.status === 'paid' ? <Badge bg="success" className="text-uppercase">{item.status}</Badge> : <Badge bg="warning" className="text-uppercase">{item.status}</Badge>}</Col>
                            </Row>
                            <Row className="mb-1">
                              <Col className="text-end"><label>Total Item Price</label></Col>
                              <Col>{currency}{item.item_price.toFixed(2)}</Col>
                            </Row>
                            <Row className="mb-1">
                              <Col className="text-end"><label>Total Commission</label></Col>
                              <Col>-{currency}{item.commission.toFixed(2)}</Col>
                            </Row>
                            <Row className="mb-1">
                              <Col className="text-end"><label>Total SST</label></Col>
                              <Col>-{currency}{item.gst.toFixed(2)}</Col>
                            </Row>
                            {
                              item.total_discount > 0 &&
                              <Row className="mb-1">
                                <Col className="text-end"><label>Discount</label></Col>
                                <Col>-{currency}{item.total_discount.toFixed(2)}</Col>
                              </Row>
                            }
                            {
                              item.total_shipping > 0 &&
                              <Row className="mb-1">
                                <Col className="text-end"><label>Shipping Fee</label></Col>
                                <Col>{currency}{item.total_shipping.toFixed(2)}</Col>
                              </Row>
                            }
                            {
                              item.total_adjustment !== undefined && item.total_adjustment !== 0 &&
                              <Row className="mb-1">
                                <Col className="text-end"><label>Total Adjustment</label></Col>
                                <Col>{currency}{item.total_adjustment.toFixed(2)}</Col>
                              </Row>
                            }
                            <Row className="mb-1">
                              <Col className="text-end"><label>Total Balance</label></Col>
                              <Col>{currency}{item.balance.toFixed(2)}</Col>
                            </Row>
                            {
                              (item.total_adjustment_item_price !== 0 || item.total_adjustment_shipping !== 0) && <hr/>
                            }
                            {
                              item.total_adjustment_item_price !== 0 &&
                              <Row className="mb-1">
                                <Col className="text-end"><label>Total Item Price Adj.</label></Col>
                                <Col>{currency}{item.total_adjustment_item_price.toFixed(2)}</Col>
                              </Row>
                            }
                            {
                              item.total_adjustment_shipping !== 0 &&
                              <Row className="mb-1">
                                <Col className="text-end"><label>Total Shipping Adj.</label></Col>
                                <Col>{currency}{item.total_adjustment_shipping.toFixed(2)}</Col>
                              </Row>
                            }
                            {
                              item.total_adjustment_commission !== 0 &&
                              <Row className="mb-1">
                                <Col className="text-end"><label>Total Comm. Adj.</label></Col>
                                <Col>{currency}{item.total_adjustment_commission.toFixed(2)}</Col>
                              </Row>
                            }
                            {
                              item.total_adjustment_tax !== 0 &&
                              <Row className="mb-1">
                                <Col className="text-end"><label>Total SST Adj.</label></Col>
                                <Col>{currency}{item.total_adjustment_tax.toFixed(2)}</Col>
                              </Row>
                            }
                            {
                              item.total_adjustment_balance !== 0 &&
                              <Row className="mb-1">
                                <Col className="text-end"><label>Total Balance Adj.</label></Col>
                                <Col>{currency}{item.total_adjustment_balance.toFixed(2)}</Col>
                              </Row>
                            }
                          </Col>
                          {
                            item.total_balance !== 0 &&
                            <>
                              <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
                              <Col lg={3} className="m-2 my-3 my-lg-auto" style={{fontSize: '1.2em'}}>
                                <label className="me-4 me-md-0">Total Payout Bal.</label>
                                {currency}{item.total_balance.toFixed(2)}
                              </Col>
                            </>
                          }
                        </Row>
                        <Row className="align-items-center justify-content-center my-3">
                          {
                            user && user.role === 'admin' && item.status === 'unbilled' &&
                            <Col xs="auto">
                              <Button className="btn-br-6" variant="primary" onClick={this.handleOnClickShowConfirmModal}>Confirm Bill</Button>
                            </Col>
                          }
                          <Col xs="auto">
                            <Button className="btn-br-6" variant="secondary" onClick={this.handleExportBill}>Export Bill</Button>
                          </Col>
                          {
                            user && user.role === 'admin' && item.status === 'billed' &&
                            <Col xs="auto">
                              <Button className="btn-br-6" variant="danger-secondary" onClick={this.handleUnbill}>Unbill</Button>
                            </Col>
                          }
                          {
                            user && (user.role === 'admin' || user.role === 'staff') && (item.status === 'billed' || item.status === 'paid') &&
                            <Col xs="auto">
                              <Button variant="primary" className="btn-br-6" onClick={this.handleOnClickShowPaymentDateModal}>Update Payment Date</Button>
                            </Col>
                          }
                          {
                            item.payment_date &&
                            <Col xs="auto">
                              <p className="m-0 text-center" style={{whiteSpace: 'pre-line'}}>{`Paid on ${moment(item.payment_date).format('DD-MM-YYYY')}
(refer to email notification by bank)`}</p></Col>
                          }
                          {
                            item.payment_slip &&
                            <Col xs="auto">
                              <a target="_blank" href={item.payment_slip} style={{color: '#80d7cf'}}>Payment Slip</a>
                            </Col>
                          }
                          {
                            item.revised_statement &&
                            <Col xs="auto">
                              <a target="_blank" href={item.revised_statement} style={{color: '#80d7cf'}}>Revised Statement</a>
                            </Col>
                          }
                        </Row>
                        <Row className="align-items-center justify-content-center m-0">
                        {
                          (item.status === 'billed' || item.status === 'paid') && item.invoice_link &&
                          <>
                          <Col xs="auto my-2 my-xl-0">
                            <Button className="btn-br-6" variant="info" onClick={this.handleDownloadInvoice}>Open Invoice</Button>
                          </Col>
                          {
                            user && (user.role === 'admin' || user.role === 'staff') &&
                            <Col xs="auto my-2 my-xl-0">
                              <Button className="btn-br-6" variant="secondary" onClick={this.handleOnClickShowAttachRevisedInvoiceModal}>Overwrite Invoice</Button>
                            </Col>
                          }
                          </>
                        }
                        {
                          user && (user.role === 'admin' || user.role === 'staff') && item.status === 'billed' &&
                          <Col xs="auto my-2 my-xl-0">
                            <Dropzone
                              style={{}}
                              onDrop={this.onDropPaymentSlip.bind(this)}
                              disabled={item.status !== 'billed'}
                              multiple={false}
                              accept='application/pdf,application/zip'>
                              <Button className="btn-br-6" variant="primary" disabled={uploadingFile} >{uploadingFile ? "Uploading" : "Attach Payment Slip"}</Button>
                            </Dropzone>
                          </Col>
                        }
                        </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-3 bill-detail-line-items table-responsive">
                <h4>Line Items</h4>
                <Col xs={12}>
                  <ToolkitProvider
                    keyField='_id'
                    data={item.line_item}
                    columns={columns}
                    exportCSV={{
                      fileName: `${item.vendor}-line-items.csv`,
                      exportAll: false
                    }}
                  >
                    {
                      props => (
                        <>
                          <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                            <Col>
                              { isAdmin && item && item.status === 'unbilled' &&
                              <Button
                                variant="danger"
                                className="btn-br-6 pull-right"
                                onClick={this.customConfirm}
                              >
                                <FontAwesomeIcon icon={solid('trash')} style={{marginRight: '15px'}}/>
                                Delete
                              </Button>
                              }
                            </Col>
                          </Container>
                          <BootstrapTable
                            { ...props.baseProps }
                            bordered={ false }
                            { ...(item && item.status === 'unbilled' && selectRowProps) }
                            hover
                            defaultSorted={[{
                              dataField: 'order_name',
                                order: 'asc',
                            }]}
                            noDataIndication={() => 'There is no data to display'}
                          />
                        </>
                      )
                    }
                  </ToolkitProvider>
                </Col>
              </Row>
              <Row className="mt-3 bill-detail-line-items table-responsive">
                <h4>Adjustments</h4>
                <Col xs={12}>
                  <ToolkitProvider
                    keyField='_id'
                    data={item.adjustment}
                    columns={adjustment_columns}
                    exportCSV={{
                      filename: `${item.vendor}-adjustment.csv`,
                    }}
                  >
                    {
                      props => (
                        <>
                          <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                            <Col>
                              { isAdmin && !(item.status === 'paid' || item.status === 'billed') &&
                              <Button className="pull-right btn-br-6" variant="primary" onClick={this.handleOnClickShowAdjustmentModal}>Add Adjustment</Button>
                              }
                              { isAdmin && item && item.status === 'unbilled' &&
                                <Button
                                  variant="outline-danger"
                                  className="btn-br-6 pull-right mx-2"
                                  onClick={this.confirmDeleteAdjustment}
                                >
                                  <FontAwesomeIcon icon={solid('trash')} style={{marginRight: '15px'}}/>
                                  Delete
                                </Button>
                              }
                            </Col>
                          </Container>
                          <BootstrapTable
                            { ...props.baseProps }
                            bordered={ false }
                            { ...(item && item.status === 'unbilled' && adjustmentSelectRowProps) }
                            hover
                            defaultSorted={[{
                              dataField: 'created',
                                order: 'asc',
                            }]}
                            noDataIndication={() => 'There is no data to display'}
                          />
                        </>
                      )
                    }
                  </ToolkitProvider>
                </Col>
              </Row>
            </div>
          </>
        }
        {
          showConfirmModal &&
          <ConfirmBillModal item={item} onClose={this.handleOnCloseShowConfirmModal} onClickSave={this.handleConfirmBill}/>
        }
        {
          showAdjustmentModal &&
          <BillAdjustmentModal commissionRate={item.commission_rate} onClose={this.handleOnCloseShowAdjustmentModal} onClickSave={this.handleAddAdjustment}/>
        }
        {
          showAttachRevisedInvoiceModal &&
          <AttachedRevisedInvoiceModal submitting={uploadingFile} onClose={this.handleOnCloseShowAttachRevisedInvoiceModal} onClickSave={this.handleSaveRevisedInvoice.bind(this)}/>
        }
        {
          showDeleteBillModal &&
          <ConfirmModal onClickYes={this.handleDeleteBill} onClose={this.handleOnHideDeleteBillModel} title="Delete Bill">
            <p>Are you sure you want to delete this bill?</p>
          </ConfirmModal>
        }
        {
          showPaymentDateModal &&
            <PaymentDateModal onClose={this.handleOnCloseShowPaymentDateModal} onClickSave={this.handleSetPaymentDate}/>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    billDetail: state.billDetail,
    error: state.error,
    user: state.auth.user
  };
}, {
  ...billDetailActionCreators,
  ...errorActionCreators
})(BillDetail);
