import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

const PhoneNumberInput = (props) => {

  const { disabled, input, label, placeholder, name, onChangeValue, handleSubmit, type, isRenderLabel = true, meta: { touched, error } } = props

  return (
    <div className={`form-group ${touched && error ? "has-error has-feedback" : ""}`}>
      {
        isRenderLabel  &&
          <label htmlFor={name}>{label}</label>
      }
      <PhoneInput
        defaultCountry={country}
        international
        placeholder={placeholder}
        value={input.value}
        onChange={e => {
          console.log(e);
          input.onChange(e || "");
          if(onChangeValue){
            onChangeValue(e || "")
          }
        }}
      />
      {
        touched && error &&
          <span className="glyphicon glyphicon-remove form-control-feedback"></span>
      }
      {
        touched && error && error !== "Required" &&
          <span style={{color: '#d15d5d'}}>
            <FontAwesomeIcon icon={solid('exclamation')} style={{marginRight: '6px'}}/>
            {error}
          </span>
      }
    </div>
  )
}

export default PhoneNumberInput
