import { logout } from '../redux/actions/auth';
import { store } from '../index';
import { history } from '../redux/store/configureStore';

export default function apiClient(uri, options = {}, headersOveride) {
  options.headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...options.headers
  };

  if(headersOveride){
    options.headers = headersOveride
  }

  const { user }  = store.getState().auth;
  if (user && user.token){
    options.headers['Authorization'] = `Bearer ${user.token}`;
  }
  // const url = config.apiBaseUrl + uri;
  const url = `/api${uri}`;
  return fetch(url, options).then(res => {
    return res.json().then(json => {
      if(res.status >= 200 && res.status < 300) {
        return json;
      }
      else if (res.status === 401) {
        store.dispatch(logout(history.push, true));
        return Promise.reject(json);
      }
      else {
        console.log('api error', json)
        return Promise.reject(json);
      }
    });
  });
}

// export function setStore(reduxStore) {
//   store = reduxStore;
// }
