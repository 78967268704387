import qs from "qs";
import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_ORDER_DETAIL_REQUEST = 'FETCH_ORDER_DETAIL_REQUEST';
export const FETCH_ORDER_DETAIL_SUCCESS = 'FETCH_ORDER_DETAIL_SUCCESS';
export const FETCH_ORDER_DETAIL_FAILURE = 'FETCH_ORDER_DETAIL_FAILURE';
export const CLEAR_ORDER_DETAIL = 'CLEAR_ORDER_DETAIL';

function fetchOrderDetailRequest(orderId) {
  return {
    type: FETCH_ORDER_DETAIL_REQUEST,
    payload: {
      orderId, 
    }
  };
}

function fetchOrderDetailSuccess(json) { 
  return {
    type: FETCH_ORDER_DETAIL_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchOrderDetailFailure() {
  return {
    type: FETCH_ORDER_DETAIL_FAILURE
  };
}

function shouldFetchOrderDetail(state) {
  const results = state.order;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

function fetchOrderDetailFromApi(orderId) {
  return dispatch => {
    dispatch(fetchOrderDetailRequest(orderId));
    return api(`/orders/${orderId}`)
      .then(json => {
        dispatch(fetchOrderDetailSuccess(json));
      })
      .catch(err => {
        dispatch(fetchOrderDetailFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchOrderDetail(orderId) {
  return (dispatch, getState) => {
    if (shouldFetchOrderDetail(getState())) {
      return dispatch(fetchOrderDetailFromApi(orderId));
    }
  };
}

export function clearOrderDetail() {
  return {
    type: CLEAR_ORDER_DETAIL,
  };
}