import { useState, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReactCrop, { makeAspectCrop, centerCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasExportAsImage } from '../helpers/htmlToCanvas';

const EditImageModal = ({ onClose, image, done, aspectRatio }) => {
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState()
  const [submitting, setSubmitting] = useState(false)

  const imgRef = useRef();
  const canvasRef = useRef();

  const onImageLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: '%',
          width: 100,
        },
        aspectRatio || 1,
        width,
        height
      ),
      width,
      height
    )

    setCrop(crop)
  }

  const onClickDone = async () => {
    setSubmitting(true)

    let image = await canvasExportAsImage(
      imgRef.current,
      canvasRef.current,
      completedCrop,
    )

    done(image);
  }

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4 className="mb-0">Crop image</h4>
      </Modal.Header>
      <Modal.Body className="p-2" style={{borderTop: '1px solid #dee2e6'}}>
        <div className="d-flex justify-content-center align-items-center">
        <ReactCrop
          crop={crop}
          onChange={c => setCrop(c)}
          onComplete={c => setCompletedCrop(c)}
          aspect={aspectRatio}
          style={{ maxHeight: '100%' }}
        >
          <img ref={imgRef} src={image} onLoad={onImageLoad}  style={{ maxHeight: '75vh'}}/>
        </ReactCrop>
        </div>
        { !!completedCrop && (
          <canvas
            ref={canvasRef}
            style={{
              display: 'none',
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className='d-grid gap-1' style={{gridTemplateColumns: '1fr 1fr'}}>
          <Button variant="danger-secondary" onClick={onClose}>Cancel</Button>
          <Button onClick={onClickDone} disabled={submitting}>Done</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default EditImageModal;
