import React, { Component } from 'react';
import { connect } from 'react-redux'
import api from "../helpers/apiClient";
import { Alert, Button } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import Input from '../components/Input';
import LoginNavbar from '../components/LoginNavbar';
import { trimLeft } from 'url-parse';

const giftrLink = window.location.hostname === 'seller.giftr.my' ? 'https://giftr.my' : 'https://giftr.sg'

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required';
  }

  return errors
}


class ForgotPassword extends Component {

  constructor(props) {
    super(props)

    this.state = {
      reset: false,
      error: false
    };
  }

  submit = (values) => {
    const { email } = values;
    const { history } = this.props;
    return api('/auth/reset', {
      method: "post",
      body: JSON.stringify({ email })
    }).then(json => {
      console.log("success reset")
      this.setState({
        reset: true
      })
    }).catch(e => {
      this.setState({
        reset: false,
        error: true
      })
    })
  }

  render() {
    const { handleSubmit, globalError } = this.props
    const { reset, error } = this.state

    return (
      <div>
        <LoginNavbar host={giftrLink}></LoginNavbar>
        <div className="row" id="pwd-container">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <form className="login-form d-grid gap-3 mx-3 shadow-sm" style={{textAlign: 'left', color:'#212529'}} onSubmit={handleSubmit(this.submit)}>
              <h4 className="mt-3 mb-5 text-center" style={{color:'#000'}}>Reset Password</h4>
              {
                globalError || error &&
                <Alert variant="danger">
                  <div>Incorrect email address.</div>
                </Alert>
              }
              {
                reset &&
                <div className="panel panel-success">
                  <div className="panel-heading">We have sent a new password to your email address. Please check your email.</div>
                </div>
              }
              <Field className="form-control" name="email" label="Enter your email address" placeholder="e.g. johndoe@helpr.my" type="email" component={Input} />
              <Button
                block="true"
                type="submit"
                disabled={reset}
                className="mt-5"
              >RESET</Button><br/>
            </form>
            </div>
            <div className="col-md-4"></div>
        </div>
      </div>
    );
  }
}

ForgotPassword = reduxForm({
  form: 'ForgotPassword',
  validate,
  destroyOnUnmount: true
})(ForgotPassword);

export default connect(state => {
  return {
    globalError: state.error,
  }
}, {})(ForgotPassword);
