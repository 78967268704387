import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash'
import moment from 'moment';
import { Button, Alert, Badge, Container, Row, Col } from 'react-bootstrap';
import ConfirmModal from '../components/ConfirmModal';
import RedeemGiftcardModal from '../components/RedeemGiftcardModal'
import ResendGiftcardModal from '../components/ResendGiftcardModal'
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import TabHeader from '../components/TabHeader';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

class Giftcards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      giftcards: [],
      selectedIds: [],
      showConfirmFulfillmentModal: false,
      showConfirmRedeemModal: false,
      showResendModal: false,
      unselectableRows: [],
      redeemId: null,
      codePrefix: null,
      redeemError: false,
      redeeming: false,
      resending: false
    };
  }

  componentDidMount() {
    this.fetchGiftcards()
  }

  fetchGiftcards = () => {
    return api(`/giftcards`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      this.setState({ loading: false });


      if(result){
        let unselectableRows = []
        _.each(result, (giftcard) => {
          if(giftcard.fulfilled){
            unselectableRows.push(giftcard.id)
          }
        })
        this.setState({ giftcards: result, unselectableRows });
      }
    }).catch(err => {
      this.setState({ loading: false });
      if (err) {
        window.scrollTo(0, 0);
        if (err.validationError) {
          this.setState({ error: err.validationError });
        }
        else if (err.message) {
          this.setState({ error: err.message });
        }
        else {
          this.setState({ error: err });
        }
      }
    });
  }

  componentWillReceiveProps(nextProps) {
  }

  componentWillUpdate(nextProps, nextState) {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  renderShortcode = (cell, row) => {

    return (
      <a href={row.link} target="_blank" >
        {row.shortcode}
      </a>
    )
  }

  renderOrder = (cell, row) => {

    if(row.manual_order_id){
      return (
        <a href={`manual_orders/${row.manual_order_id}`} target="_blank" >
          {row.order_name}
        </a>
      )
    }

    return (
      <a href={`orders/${row.order_id}`} target="_blank" >
        {row.order_name}
      </a>
    )
  }

  renderDate = (cell, row) => {

    return (
      <span>
        {moment(cell).format('DD/MM/YYYY')}
      </span>
    )
  }

  renderDateTime = (cell, row) => {

    return (
      <span>
        {moment(cell).format('DD/MM/YYYY HH:mm')}
      </span>
    )
  }

  dateSort = (a, b, order) => {

    let date1 = a.created_at
    let date2 = b.created_at

    if(order === 'desc'){
      return moment(date2).unix() - moment(date1).unix()
    } else {
      return moment(date1).unix() - moment(date2).unix()
    }
  }

  renderReview = (row) => {

    return <div className="container-fluid">
      Redeemed on {moment(row.redeemed_on).utcOffset(8).format('DD/MM/YYYY HH:mm')} by {row.redeem_email}.
    </div>
  }

  renderFulfilled = (cell, row) => {
    if(cell){
      return <Badge bg="success" className="text-uppercase">Yes</Badge>
    } else {
      return <Badge bg="secondary" className="text-uppercase">No</Badge>
    }
  }

  handleSubmit = async (e) => {

    const { id, name } = e.target;
    const { user } = this.props;
    const isAdmin = user.role === 'admin'

    let idCode = _.split(_.replace(id, "***", ''), '#,')

    if(isAdmin && name === 'unredeem') {
      this.redeem(idCode[0], name)
    } else {
      this.handleOnClickRedeemVoucher(idCode[0], idCode[1])
    }
  }

  handleResend = async (e) => {

    const { id, name } = e.target;

    let idCode = _.split(id, '#,')

    this.setState({ showResendModal: true, resendId:idCode[0], receiverEmail:idCode[1], receiverMobile:idCode[2] });
  }

  handleRedeem = (code) => {
    const { redeemId } = this.state
    this.redeem(redeemId, 'redeem', code)
  }

  resend = (email, mobile) => {
    const { resendId } = this.state

    return api(`/giftcards/resend`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: resendId, email, mobile })
    }).then((result) => {
      this.setState({ resending: false, loading: false, showResendModal: false, resendId:undefined, deliveryMethod:undefined, receiverEmail:undefined, receiverMobile:undefined });
    }).catch(err => {
      this.setState({ resending: false, loading: false });
      if (err) {
        window.scrollTo(0, 0);
        alert(err.message);
      }
    });
  }

  redeem = (id, name, code) => {
    let method = 'POST'

    if(name === 'unredeem'){
      method = 'DELETE'
      this.setState({ loading: true });
    } else {
      this.setState({ redeeming: true });
    }

    return api(`/giftcards/redeem`, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, code })
    }).then((result) => {

      if(name === 'unredeem'){
        this.fetchGiftcards()
        this.setState({ loading: false });
      } else if(result.success){
        this.setState({ redeeming: false, showConfirmRedeemModal: false, redeemError: false });
        this.fetchGiftcards()
      } else {
        this.setState({ redeeming: false, redeemError: true });
      }

    }).catch(err => {
      this.setState({ redeeming: false, loading: false });
      if (err) {
        window.scrollTo(0, 0);
        alert(err.message);
      }
    });
  }


  renderResend = (cell, row) => {

    const { user } = this.props;
    const isAdmin = user.role === 'admin'

    return (
      <Button variant="warning"
        id={`${row.id}#,${row.receiver_email}#,${row.receiver_mobile}`}
        name="unredeem"
        onClick={this.handleResend}
      >
        Resend
      </Button>)

  }

  renderRedeem = (cell, row) => {

    const { user } = this.props;
    const isAdmin = user.role === 'admin'

    if(!cell){
      return (
        <Button variant="success"
            id={`${row.id}#,${row.giftcode_code}`}
            name="redeem"
            disabled={row.redemption_type === "pre"}
            onClick={this.handleSubmit}
          >
            Redeem
        </Button>
      )
    } else if(isAdmin) {
      return (
        <Button variant="danger"
            id={`${row.id}#,${row.giftcode_code}`}
            name="unredeem"
            onClick={this.handleSubmit}
          >
            Unredeem
        </Button>
      )
    } else {
      return (<span className="label label-primary text-uppercase">Redeemed</span>)
    }
  }

  onRowSelect = (row, isSelected, e) => {

    if(row.fulfilled){
      return false
    }

    let { selectedIds } = this.state;

    if(isSelected){
      selectedIds.push(row.id)
    } else {
      const index = selectedIds.indexOf(row.id);
      if (index > -1) {
        selectedIds.splice(index, 1);
      }
    }

    this.setState({ selectedIds });
  }

  onSelectAll = (isSelected, rows) => {
    if (isSelected) {
      return false
    }
  }

  handleOnClickSendVoucher = () => {
    this.setState({ showConfirmFulfillmentModal: true });
  }

  handleOnCloseSendVoucherModal = () => {
    this.setState({ showConfirmFulfillmentModal: false });
  }

  handleOnClickRedeemVoucher = (id, codePrefix) => {
    this.setState({ showConfirmRedeemModal: true, redeemId:id, codePrefix });
  }

  handleOnCloseRedeemVoucherModal = () => {
    this.setState({ showConfirmRedeemModal: false, redeemError: false });
  }

  handleOnCloseResendVoucherModal = () => {
    this.setState({ showResendModal: false, resendId:undefined, deliveryMethod:undefined, receiverEmail:undefined, receiverMobile:undefined });
  }

  handleSendVoucher = () => {

    const { selectedIds } = this.state;
    this.setState({ loading: true });

    return api(`/sendgiftcards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedIds)
    }).then((result) => {

      this.fetchGiftcards()
      this.setState({ showConfirmFulfillmentModal: false, loading: false, selectedIds:[] });

    }).catch(err => {
      this.setState({ showConfirmFulfillmentModal:false, loading: false, selectedIds:[] });
      if (err) {
        window.scrollTo(0, 0);
        alert(err.message);
      }
    });
  }

  expandableRow = (row) =>{
    return row.redeemed
  }

  render() {
    const { user } = this.props;
    const { error, loading, giftcards, selectedIds, showConfirmFulfillmentModal, showConfirmRedeemModal,
      unselectableRows, redeemId, codePrefix, redeemError, redeeming, resending, showResendModal, receiverEmail, receiverMobile,
    deliveryMethod } = this.state;

    const isAdmin = user.role === 'admin'

    let selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      clickToExpand: true,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      nonSelectable: unselectableRows,
      headerColumnStyle: { width: '30px' },
    }

    const options = {
      showTotal: true,
      sizePerPage: 30,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: giftcards.length
      }],
    };

    const columns = [
      {
        dataField: 'redeemed',
        text: 'Redeemed',
        formatter: this.renderRedeem,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'created_at',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        sort: true,
        sortFunc: this.dateSort,
        formatter: this.renderDate,
        text: 'Date',
      },
      {
        dataField: 'id',
        hidden: true,
        text: 'Id',
      },
      {
        dataField: 'giftcode_code',
        headerStyle: {
          width: '110px',
          resize: 'horizontal'
        },
        text: 'Code',
      },
      {
        dataField: 'shortcode',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        hidden: !isAdmin,
        text: 'Shortcode',
        formatter: this.renderShortcode,
      },
      {
        dataField: 'link',
        hidden: true,
        text: 'Link'
      },
      {
        dataField: 'order_name',
        text: 'Order Name',
        hidden: true,
      },
      {
        dataField: 'name',
        text: 'Order Name',
        sort: true,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        formatter: this.renderOrder,
      },
      {
        dataField: 'sku',
        text: 'SKU',
        headerStyle: {
          width: '130px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'vendor',
        text: 'Vendor',
        sort: true,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'value',
        text: 'Value',
        sort: true,
        formatter: (cell, row) => {
          return row.new_value_format ? row.new_value : cell
        },
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'receiver_name',
        text: 'Name',
        sort: true,
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'delivery_method',
        text: 'Delivery',
        sort: true,
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'receiver_email',
        text: 'Email',
        sort: true,
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'receiver_mobile',
        text: 'Mobile',
        sort: true,
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'fulfilled',
        formatter: this.renderFulfilled,
        text: 'Fulfilled',
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
      },

      {
        dataField: 'id',
        hidden: !isAdmin,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Resend',
        formatter: this.renderResend
      },
    ]

    return (
      <div>
        <TabHeader title="Giftcards" tab_id="giftcards" user={user} />
        {
          loading &&
          <Loader />
        }
        {
          error &&
          <Alert variant="danger">
            {
              Array.isArray(error) ?
              <ul>
              {
                error.map((err, i) => (
                  <li key={i}>
                    {err.msg}
                  </li>
                ))
              }
              </ul>
              :
              error
            }
          </Alert>
        }
        {
          !loading && giftcards &&
          <>
            <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap align-items-lg-end">
            {
              !loading && giftcards && isAdmin &&
              <Col sm="auto">
                <Button className="btn-br-6 w-100" disabled={selectedIds.length === 0} onClick={this.handleOnClickSendVoucher}>Send Voucher(s)</Button>
              </Col>
            }
            </Container>
            <ToolkitProvider
              keyField='id'
              data={giftcards}
              columns={columns}
              exportCSV={{
                exportAll: false
              }}
              search
            >
              {
                props => (
                  <>
                    <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                      <Col>
                        <ExportCSVButton
                          className="btn-info"
                          style={{borderRadius:'8px'}}
                          { ...props.csvProps }
                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                        Export to CSV</ExportCSVButton>
                      </Col>
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps } style={{borderRadius:'25px'}}/>
                      </Col>
                    </Container>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      selectRow={selectRowProp}
                      pagination={paginationFactory(options)}
                      hover
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </>
        }
        {
          showConfirmFulfillmentModal &&
          <ConfirmModal onClickYes={this.handleSendVoucher} onClose={this.handleOnCloseSendVoucherModal} title="Send Voucher(s)">
            <p>Confirm send vourcher(s)?</p>
          </ConfirmModal>
        }
        {
          showConfirmRedeemModal &&
          <RedeemGiftcardModal redeeming={redeeming} redeemError={redeemError} giftcardPrefix={codePrefix} onClickSave={this.handleRedeem} onClose={this.handleOnCloseRedeemVoucherModal} title="Redeem Voucher"/>
        }
        {
          showResendModal &&
          <ResendGiftcardModal loading={resending} redeemError={redeemError} receiverMobile={receiverMobile} receiverEmail={receiverEmail} onClickSave={this.resend} onClose={this.handleOnCloseResendVoucherModal} title="Resend Giftcard"/>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    user: state.auth.user,
  };
})(Giftcards);
