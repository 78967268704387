import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const CLEAR_USER = 'CLEAR_USER';

function fetchUserRequest() {
  return {
    type: FETCH_USER_REQUEST,
  };
}

function fetchUserSuccess(json) { 
  return {
    type: FETCH_USER_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchUserFailure() {
  return {
    type: FETCH_USER_FAILURE
  };
}

function shouldFetchUser(state) {
  const results = state.user;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

export function fetchUserFromApi(_id) {
  return dispatch => {
    dispatch(fetchUserRequest(_id));
    return api(`/users/${_id}`)
      .then(json => {
        dispatch(fetchUserSuccess(json));
      })
      .catch(err => {
        dispatch(fetchUserFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchUser(_id) {
  return (dispatch, getState) => {
    if (shouldFetchUser(getState())) {
      return dispatch(fetchUserFromApi(_id));
    }
  };
}
