import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirm: ""
    }
  }

  handleOnClickSave = () => {
    const { onClickSave } = this.props;
    const { password, confirm } = this.state;

    if(password !== confirm){
      alert("Password is not the same. Please check again.")
    } else {
      onClickSave(password);
    }
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleOnInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  render() {

    return (
      <Modal style={{top:'15%', border: 'none'}}  show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
            <h4>Change Password</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          <form>
            <div className="form-group">
              <label>Enter new password</label>
              <input type="password" className="form-control" id="password" name="password" onChange={this.handleOnInputChange}/><br/>
              <label>Confirm new password</label>
              <input type="password" className="form-control" id="confirm" name="confirm" onChange={this.handleOnInputChange}/>
            </div>        
          </form>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={this.handleOnClose}>Cancel</Button>
          <Button variant="primary" onClick={this.handleOnClickSave}>
            Change password
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ChangePasswordModal;