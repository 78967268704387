import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Alert, Badge, Container } from 'react-bootstrap';
import moment from 'moment';
import Select from '../components/Select';
import Loader from '../components/Loader';
import commissionTypeOptions from '../data/commission_type.json';
import api from '../helpers/apiClient';
import Textarea from '../components/Textarea';
import _ from 'lodash';
import ConfirmModal from '../components/ConfirmModal';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import TabHeader from '../components/TabHeader';
import LinkPromotionModal from '../components/LinkPromotionModal';
import CreatableSelect from 'react-select/creatable';
import cellEditFactory from 'react-bootstrap-table2-editor';

const { SearchBar } = Search;

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'
const shopifyStore = window.location.hostname === 'seller.giftr.my' ? 'helpr-asia' : 'giftr-singapore'

class Promotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      promotions:[],
      selectedIds: [],
      showBulkDeleteSuccess: false,
      shopifyDiscounts: [],
      tagOptions: [],
      tag: {},
      deleteId: '',
    };
  }

  componentDidMount() {
    this.fetchShopifyDiscounts().then(() => {
      this.fetchTaggings();
      this.fetchSavedDiscounts();
    });
  }

  fetchSavedDiscounts = () => {
    const { shopifyDiscounts } = this.state

    return api(`/giftr_discounts` , {
      method: 'GET',
    }).then(result => {
      const discounts = _.map(shopifyDiscounts, discount => {
        let foundSaved = _.find(result, saved => saved.price_rule_id == discount.id)
        let tag = foundSaved ? foundSaved.tag : ''

        return {
          ...discount,
          tag,
          originalTag: tag,
          saved: !!foundSaved,
          link_id: foundSaved ? foundSaved.id : null,
        }
      })
      this.setState({ promotions: result, shopifyDiscounts: discounts })
    })
  }

  fetchTaggings = () => {
    return api(`/product/taggings?group=Occasions`).then(tags => {
      let options = _.map(_.sortBy(tags, 'seq'), tag => {
        return {
          label: tag.tag,
          value: tag.tag
        }
      })

      this.setState({ tagOptions: options })
    })
  }

  fetchShopifyDiscounts = () => {
    this.setState({ loading: true })

    return api(`/shopify_discounts`).then(discounts => {
      this.setState({ shopifyDiscounts: discounts, loading: false })
    })
  }

  onRowSelect = (row, isSelected, e) => {

    let { selectedIds } = this.state;

    if(isSelected){
      selectedIds.push({
        id: row.id,
      })
    } else {
      selectedIds = _.reject(selectedIds, {id: row.id})
    }

    this.setState({ selectedIds });
  }

  onSelectAll = (isSelected, rows) => {

    let { selectedIds } = this.state;

    if(isSelected){
      _.each(rows, function (row) {
        selectedIds.push({
          id: row.id,
          discount_code_id: row.discount_code_id,
          price_rule_id: row.price_rule_id
        })
      })
    } else {
      _.each(rows, function (row) {
        selectedIds = _.reject(selectedIds, {id: row.id})
      })
    }

    this.setState({ selectedIds });

    return true
  }

  renderDate = (cell, row) => {

    return moment(cell).format("DD/MM/YYYY")
  }

  renderStatus = (cell, row) => {

    const startsAt = moment(row.starts_at)
    const endsAt = moment(row.ends_at)

    if(startsAt.unix() - moment().unix() > 0){
      return <Badge bg="info" className="text-uppercase">scheduled</Badge>
    } else if (moment().unix() - endsAt.unix() > 0){
      return <Badge bg="secondary" className="text-uppercase">expired</Badge>
    } else if (moment().unix() - startsAt.unix() > 0 && moment().unix() - endsAt.unix() < 0){
      return <Badge bg="success" className="text-uppercase">active</Badge>
    }
  }

  renderDiscountValue = (cell, row) => {
    return row.value_type === 'percentage' ? `${cell*-1}%` : `${currency}${cell*-1}`
  }

  renderOneUsePerCustomer = (cell, row) => {
    return row.once_per_customer ?
      <Badge bg="success" className="text-uppercase">Yes</Badge>
      :
      <Badge bg="warning" className="text-uppercase">No</Badge>
  }

  renderDiscountType = (cell, row) => {
    return cell === 'percentage' ? 'Percentage' : 'Fixed Value'
  }

  renderUsageLimit = (cell, row) => {
    return cell ? cell : 'None'
  }

  handleDelete = async () => {
    const { deleteId } = this.state;

    await api(`/giftr_discounts`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([deleteId])
    })

    this.setState({ showConfirmDeleteModal: false })
    this.fetchSavedDiscounts();
  }

  handleDismissBulkDeleteSuccess = () => {
    this.setState({ showBulkDeleteSuccess: false })
  }

  handleLinkPromotion = async (tag, title, price_rule_id) => {
    return api(`/giftr_discounts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tag,
        title,
        price_rule_id
      })
    }).then(() => {
      this.fetchSavedDiscounts();
    })
  }

  renderTagEditor = (editorProps, value, row) => {
    const { tag, tagOptions } = this.state;

    return (
      <CreatableSelect
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            position: "relative !important",
            zIndex: "10 !important",
          })
        }}
        onClick={e => e.stopPropagation()}
        value={tag}
        onChange={(tag) => {
          this.setState({ tag })
          editorProps.onUpdate(tag ? tag.value : '')
        }}
        isClearable
        required
        options={tagOptions}
      />
    )
  }

  handleAfterSaveCell = (oldValue, newValue, row) => {
    const { shopifyDiscounts } = this.state

    //check if the value is changed
    if (oldValue !== newValue) {
      //update isDirty
      let discounts = _.map(shopifyDiscounts, discount => {
        if (discount.id === row.id) {
          return {
            ...discount,
            isDirty: newValue !== row.originalTag
          }
        }
        return discount
      })

      this.setState({ shopifyDiscounts: discounts })
    }
  }

  render() {
    const { error, submitting, match, user } = this.props
    const { loading, promotions, selectedIds, showBulkDeleteSuccess, shopifyDiscounts, tagOptions, showConfirmDeleteModal, deleteId } = this.state

    let selectRowProp = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      headerColumnStyle: { width: '30px' },
    }

    const isAdmin = user.role === 'admin'

    const options = {
      showTotal: true,
      sizePerPage: 20,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: shopifyDiscounts.length
      }],
    };

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        hidden: true,
      },
      {
        dataField: 'title',
        sort: true,
        text: 'Giftr Discount',
        formatter: (cell, row) => {
          if (row.summary) {
            return (
              <div>
                <a href={`https://admin.shopify.com/store/${shopifyStore}/discounts/${row.id}`} target="_blank">{cell}</a>
                <br/>
                <span className="small">{row.summary}</span>
              </div>
            )
          }
        },
        editable: false,
        headerStyle: {
          width: '200px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'startsAt',
        sort: true,
        text: 'Starts At',
        formatter: (cell) => {
          return moment(cell).format("DD/MM/YYYY");
        },
        editable: false,
        headerStyle: {
          width: '50px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'endsAt',
        sort: true,
        text: 'Ends At',
        formatter: (cell) => {
          return moment(cell).isValid() ? moment(cell).format("DD/MM/YYYY") : '';
        },
        editable: false,
        headerStyle: {
          width: '50px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'tag',
        sort: true,
        text: 'Tag (Click to edit)',
        formatter: (cell) => {
          if (!cell && cell !== '') {
            return <Loader />
          }

          return cell
        },
        editCellStyle: {
          overflow: 'visible'
        },
        editorRenderer: this.renderTagEditor,
        editable: true,
        headerStyle: {
          width: '70px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'saved',
        text: 'Save/Delete',
        formatter: (cell, row) => {
          if (row.tag && cell) {
            return (
              <Button variant="danger" onClick={() => this.setState({ deleteId: row.link_id, showConfirmDeleteModal: true })}>
                Delete
              </Button>
            )
          }
          if (row.tag && row.isDirty) {
            return (
              <Button variant="primary" onClick={() => this.handleLinkPromotion(row.tag, row.title, row.id)}>
                Save
              </Button>
            )
          }
        },
        editable: false,
        headerStyle: {
          width: '50px',
          resize: 'horizontal'
        },
      }
    ];

    const cellEditOptions = {
      mode: 'click',
      blurToSave: true,
      afterSaveCell: this.handleAfterSaveCell,
      // nonEditableRows: () => [0, 3]
    }

    return (
      <div>
        <TabHeader title="Giftr Promotions" />
        {
          showBulkDeleteSuccess &&
          <Alert variant="success" dismissible onClose={this.handleDismissBulkDeleteSuccess}>
            <p>Bulk delete success</p>
          </Alert>
        }
        {
          loading &&
          <Loader />
        }
        {
          !loading && false &&
          <>
            <ToolkitProvider
              keyField='id'
              data={shopifyDiscounts}
              columns={columns}
              search
            >
              {
                props => (
                  <>
                    <Container fluid className="mb-3 mt-4 d-flex gap-3 justify-content-end flex-row flex-wrap">
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps } style={{borderRadius:'25px'}} />
                      </Col>
                    </Container>
                    <BootstrapTable
                      wrapperClasses='overflow-visible'
                      { ...props.baseProps }
                      bordered={ false }
                      selectRow={selectRowProp}
                      pagination={paginationFactory(options)}
                      hover
                      defaultSorted={[{
                        dataField: 'created',
                          order: 'desc',
                      }]}
                      cellEdit={ cellEditFactory(cellEditOptions) }
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </>
        }
        {
          !loading &&
          <BootstrapTable
              keyField='id'
              data={shopifyDiscounts}
              columns={columns}
            wrapperClasses='overflow-visible'
            bordered={false}
            pagination={paginationFactory(options)}
            hover
            defaultSorted={[{
              dataField: 'created',
              order: 'desc',
            }]}
            cellEdit={cellEditFactory(cellEditOptions)}
          />
        }
        {
          showConfirmDeleteModal &&
          <ConfirmModal onClickYes={this.handleDelete} onClose={() => this.setState({ showConfirmDeleteModal: false })} title="Delete Link">
            { shopifyDiscounts.find(discount => discount.link_id === deleteId).title } - { shopifyDiscounts.find(discount => discount.link_id === deleteId).tag }
            <p>Confirm delete tag link?</p>
          </ConfirmModal>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  const { match } = props;
  return {
    user: state.auth.user,
  };
}, {})(Promotions);
