import { Button, Col, Container } from 'react-bootstrap'

const TaggingToolBar = ({ removeMode, reorderMode, handleRemoveTags, cancelRemoveTags, handleReorderTags, cancelReorderTags, handleReorderMode, setRemoveMode }) => {
  if (removeMode) {
    return (
      <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap align-items-lg-end">
        <Col sm="auto"><Button className="btn-br-6 w-100" onClick={handleRemoveTags} variant="danger" size="sm">Confirm Remove</Button></Col>
        <Col sm="auto"><Button className="btn-br-6 w-100" onClick={cancelRemoveTags} variant="secondary" size="sm">Cancel</Button></Col>
      </Container>
    )
  } else if (reorderMode) {
    return (
      <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap align-items-lg-end">
        <Col sm="auto"><Button className="btn-br-6 w-100" onClick={handleReorderTags} variant="primary" size="sm">Confirm Reorder</Button></Col>
        <Col sm="auto"><Button className="btn-br-6 w-100" onClick={cancelReorderTags} variant="secondary" size="sm">Cancel</Button></Col>
      </Container>
    )
  } else {
    return (
      <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap align-items-lg-end">
        <Col sm="auto"><Button onClick={() => setRemoveMode(true)} className="btn-br-6 w-100" variant="danger" size="sm">Remove Tag(s)</Button></Col>
        <Col sm="auto"><Button onClick={handleReorderMode} className="btn-br-6 w-100" variant="primary" size="sm">Reorder Tag(s)</Button></Col>
      </Container>
    )
  }
}

export default TaggingToolBar
