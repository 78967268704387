import { addError } from './error';
import api from '../../helpers/apiClient';

export const OPEN_TICKETS_COUNT = 'OPEN_TICKETS_COUNT';
export const PICKUP_REMINDER_COUNT = 'PICKUP_REMINDER_COUNT';

export function openTicketsCount(count) {
  return (dispatch) => {

    dispatch({
      type: OPEN_TICKETS_COUNT,
      payload: count
    })
  };
}

export function pickupReminderCount(count) {
  return (dispatch) => {
    dispatch({
      type: PICKUP_REMINDER_COUNT,
      payload: count
    })
  };
}
