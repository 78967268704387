import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Alert, Row, Col, Container, Accordion } from 'react-bootstrap';
import Loader from '../components/Loader';
import * as customersActionCreators from '../redux/actions/customers';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

const { SearchBar } = Search;
const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: false,
      error: null,
      _id: null,
      email: null,
      query: ''
    };
  }

  componentDidMount() {
  }

  renderDetailLink = (cell, row) => {
    return (
      <Link
        key={row.id}
        to={{
          pathname: `/customers/${row.id}`,
        }}
      >
        {row.email}
      </Link>
    )
  }

  handleOnInputChange = (e) => {

    const { name, value, type } = e.target;

    this.setState({
      [name]: value
    });
  }

  handleOnClickFilter = () => {
    const { searchCustomers } = this.props
    const {
      query
    } = this.state;

    searchCustomers(query)
  }

  renderName = (cell, row) => {
    return (
      <span>{row.first_name} {row.last_name}</span>
    );
  };

  renderSpent = (cell, row) => {
    return (
      <span>{currency} {cell}</span>
    );
  };

  renderPhone = (cell, row) => {
    const { default_address, phone } = row;

    if(phone){
      return <span>{phone}</span>
    } else if (default_address && default_address.phone){
      return <span>{default_address.phone}</span>
    }
  }

  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleOnClickFilter();
    }
  }

  render() {
    const { customers: { loading, loaded, items } } = this.props;
    const { error, query } = this.state;

    const options = {
      showTotal: true,
      sizePerPage: 20,
      hideSizePerPage: true,
    };

    const columns = [
      {
        dataField: 'id',
        text: 'Email',
        sort: true,
        formatter: this.renderDetailLink,
        headerStyle: {
          // resize: 'horizontal'
        }
      },
      {
        dataField: 'first_name',
        text: 'Name',
        sort: true,
        formatter: this.renderName,
        headerStyle: {
          // resize: 'horizontal'
        }
      },
      {
        dataField: 'phone',
        text: 'Phone',
        formatter: this.renderPhone,
        headerStyle: {
          // resize: 'horizontal'
        }
      },
      {
        dataField: 'orders_count',
        text: 'Orders',
        sort: true,
        headerStyle: {
          // resize: 'horizontal'
        }
      },
      {
        dataField: 'total_spent',
        text: 'Spent',
        sort: true,
        formatter: this.renderSpent,
        headerStyle: {
          // resize: 'horizontal'
        }
      },
    ];


    return (
      <div>
        <div className="row mt-3 mb-4">
          <div className="col-xs-12 col-md-9">
            <h2 className="sub-header">Customers</h2>
          </div>
        </div>
        <Accordion style={{borderRadius: '18px'}} className="dashboard-card shadow-sm" defaultActiveKey="0">
          <Accordion.Item style={{borderRadius: '18px', border: 'none'}} eventKey="0">
            <Accordion.Body>
              <Row>
                <Col className="me-auto col-sm-4">
                  <div className="d-flex flex-row align-items-end">
                    <div className="me-2 flex-grow-1">
                      <label>Search</label>
                      <input type="text" className="form-control" placeholder='e.g. name, email, phone number' id="orderId" name="query" value={query} onChange={this.handleOnInputChange} onKeyPress={this.handleEnter}/>
                    </div>
                    <div className="search-order-btn">
                      <Button
                        variant="primary"
                        className="w-100"
                        onClick={this.handleOnClickFilter}
                      >
                        Search Customers
                      </Button>
                    </div>
                  </div>

                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {
          loading &&
          <Loader />
        }
        {
          error &&
          <Alert variant="danger">
            {
              Array.isArray(error) ?
              <ul>
              {
                error.map((err, i) => (
                  <li key={i}>
                    {err.msg}
                  </li>
                ))
              }
              </ul>
              :
              error
            }
          </Alert>
        }
        {
          !loading && items.length > 0 &&
          <>
            <ToolkitProvider
              keyField='email'
              data={items}
              columns={columns}
              search
            >
              {
                props => (
                  <>
                    <Container fluid className="mb-3 mt-4 d-flex gap-3 justify-content-end flex-row flex-wrap">
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps } style={{borderRadius:'25px'}} />
                      </Col>
                    </Container>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      pagination={paginationFactory(options)}
                      hover
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    customers: state.customers,
  };
}, customersActionCreators)(Customers);
