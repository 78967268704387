import React, { useEffect, useState } from 'react';
import { Modal, Button, Container, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Select from 'react-select';
import CreatableSelect, { useCreatable } from 'react-select/creatable';
import api from '../helpers/apiClient'
import _ from 'lodash'
import Loader from './Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

const { SearchBar } = Search;

const YTLinkModal = ({ onClose, onSave, size }) => {

  const [loading, setLoading] = useState(true);
  const [tag, setTag] = useState({})
  const [tagOptions, setTagOptions] = useState([])
  const [shopifyDiscounts, setShopifyDiscounts] = useState([])
  const [selectedDiscount, setSelectedDiscount] = useState(null)

  useEffect(() => {
    fetchTaggings()
    fetchShopifyDiscounts()
  }, [])

  useEffect(() => {
    if (tagOptions.length > 0 && shopifyDiscounts.length > 0) {
      setLoading(false)
    }
  }, [tagOptions, shopifyDiscounts])

  const fetchTaggings = async () => {
    return api(`/product/taggings?group=Occasions`).then(tags => {
      let options = _.map(_.sortBy(tags, 'seq'), tag => {
        return {
          label: `${tag.tag} (${tag.desc})`,
          value: tag.tag
        }
      })

      setTagOptions(options)
    })
  }

  const fetchShopifyDiscounts = async () => {
    return api(`/shopify_discounts`).then(discounts => {
      setShopifyDiscounts(discounts)
    })
  }

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true)
    onSave(tag.value, selectedDiscount.title, selectedDiscount.id)
  }

  const columns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
    },
  ]

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: (row) => setSelectedDiscount(row),
    headerColumnStyle: { width: '30px' },
  };

  const options = {
    showTotal: true,
    sizePerPage: 5,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: 'All', value: shopifyDiscounts.length
    }],
  };

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4 className="mb-0">Link Promotion</h4>
      </Modal.Header>
      <Modal.Body className="mx-4 px-0 py-4" style={{ borderTop: '1px solid #dee2e6' }}>
        {loading &&
          <Loader />
        }
        {!loading &&
          <div className="d-grid gap-3">
            <div>
              <label>Select Tagging from Occasions/Add Tagging</label>
              <CreatableSelect
                value={tag}
                onChange={setTag}
                isClearable
                required
                options={tagOptions}
              />
            </div>
            <ToolkitProvider
              keyField='id'
              data={shopifyDiscounts}
              columns={columns}
              exportCSV={{
                exportAll: true
              }}
              search
            >
              {
                props => (
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <label>Select Shopify Discount</label>
                      <SearchBar {...props.searchProps}
                        placeholder="Search" />
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      bordered={false}
                      selectRow={selectRowProp}
                      hover
                      noDataIndication={() => 'There is no data to display'}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                )}

            </ToolkitProvider>
          </div>
        }
      </Modal.Body>
      <Modal.Footer className="pt-0" style={{ borderTop: 'none' }}>
        <Button variant="danger-secondary" onClick={onClose}>Cancel</Button>
        <Button variant="primary" type="submit" onClick={submit} disabled={loading}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default YTLinkModal;
