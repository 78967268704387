import { addError } from './error';
import api from '../../helpers/apiClient';
import _ from 'lodash';

export const FETCH_LINKED_STORES_REQUEST = 'FETCH_LINKED_STORES_REQUEST';
export const FETCH_LINKED_STORES_SUCCESS = 'FETCH_LINKED_STORESS_SUCCESS';
export const FETCH_LINKED_STORES_FAILURE = 'FETCH_LINKED_STORES_FAILURE';

function fetchLinkedStoresRequest() {
  return {
    type: FETCH_LINKED_STORES_REQUEST,
  };
}

function fetchLinkedStoresSuccess(json) {

  return {
    type: FETCH_LINKED_STORES_SUCCESS,
    payload: {
      items: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchLinkedStoresFailure() {
  return {
    type: FETCH_LINKED_STORES_FAILURE
  };
}

function shouldFetchLinkedStores(state) {
  const results = state.linkedStores;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

function fetchLinkedStoresFromApi(merchantId) {
  return dispatch => {
    dispatch(fetchLinkedStoresRequest());
    return api(`/linked_stores`)
      .then(json => {
        dispatch(fetchLinkedStoresSuccess(json));
      })
      .catch(err => {
        dispatch(fetchLinkedStoresFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchLinkedStores() {
  return (dispatch, getState) => {
    if (shouldFetchLinkedStores(getState())) {
      return dispatch(fetchLinkedStoresFromApi());
    }
  }
}

