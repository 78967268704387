import React, { Component } from 'react';
import Moment from 'moment';
import { Badge, Button, Row, Col, ButtonToolbar } from 'react-bootstrap';
import _ from 'lodash';

const Fulfillment = ({ fulfillment, order, index, onClickEditFulfillment, onClickCancelFulfillment }) => {
  const nameSplit = fulfillment.name.split('.')
  return (
    <div className="my-4">
      <div>
        <h4 className="d-inline-block" style={{color: '#80d7cf'}}>{`${nameSplit[0]}-F${nameSplit[1] || index + 1}`}</h4>

        <Badge className="text-uppercase mx-3" style={{fontSize: '1em'}} bg={fulfillment.status === "success" ? "success" : "danger"}>{fulfillment.status === "success" ? "FULFILLED" : fulfillment.status}</Badge>
        <ButtonToolbar className="gap-3 my-2 my-lg-0">
        {
          fulfillment.status !== "cancelled" &&
          <>
            <Button className="btn-br-6" variant="secondary" onClick={() => onClickEditFulfillment(fulfillment.id)}>Edit Tracking</Button>
            <Button className="btn-br-6" variant="danger" onClick={() => onClickCancelFulfillment(fulfillment.id)}>Cancel Fulfillment</Button>
          </>
        }
        </ButtonToolbar>
      </div>
      <Row className="flex-column flex-lg-row grey-label label-strong">
      {
        fulfillment.tracking_number &&
        <Col lg={6} className="d-grid mb-2 p-lg-0 order-1">
          <label>Tracking Number</label>
          <span>{fulfillment.tracking_number}</span>
        </Col>
      }
      {
        fulfillment.created_at &&
        <Col lg={6} className="d-grid mb-2 p-lg-0 order-3 order-lg-2">
          <label>Fulfilled At</label>
          <span>{Moment(fulfillment.created_at).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
        </Col>
      }
      {
        fulfillment.created_original &&
        <Col lg={6} className="d-grid mb-2 p-lg-0 order-4">
          <label>Fulfilled At (Original)</label>
          <span>{Moment(fulfillment.created_original).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
        </Col>
      }
      {
        fulfillment.tracking_url &&
        <Col lg={6} className="d-grid mb-2 p-lg-0 order-2 order-lg-3">
            <label>Tracking Url</label>
            <a href={fulfillment.tracking_url} target="_blank">{fulfillment.tracking_url}</a>
        </Col>
      }
      {
        order.note_attributes.map((attribute, i) => {
          if(_.includes(attribute.name, `proof_of_delivery_${_.toString(fulfillment.id)}`)){
            return (
              <Col key={i} lg={6} className="d-grid mb-2 p-lg-0 order-4">
                <label>Proof of Delivery or Image</label>
                <a href={attribute.value} target="_blank">{attribute.value}</a>
              </Col>
            )
          }
        })
      }
      </Row>
      <Row className="grey-label label-strong">
      <Col>
        <label>Items</label>
        {
          fulfillment.line_items && fulfillment.line_items.length &&
          fulfillment.line_items.map(item => {
            return (
              <div key={item.id}>
                <div style={{color: '#7f35ff'}}>
                  <strong>{item.title} x {item.quantity}</strong>
                </div>
                <div>{item.name}</div>
                {
                  item.sku ?
                  <div>SKU: {item.sku}</div>
                  :
                  null
                }
                {
                  item.grams ?
                  <div>Weight: {item.grams} grams</div>
                  :
                  null
                }
              </div>
            )
          })
        }
      </Col>
      </Row>
      <hr style={{border: 'none', height: '0.5px', color: '#5A5A5A'}}/>
    </div>
  );
}

export default Fulfillment;
