import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash'
import moment from 'moment';
import { Button, Alert, Badge, Accordion, Row, Col, Form, Container } from 'react-bootstrap';
import Loader from '../components/Loader';
import TabHeader from '../components/TabHeader';
import * as productsActionCreators from '../redux/actions/productBulkEdit';
import * as taggingsActionCreators from '../redux/actions/taggings';
import api from '../helpers/apiClient';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { generateAttrBulkEdit } from '../helpers/productTags';
import ReactTooltip from 'react-tooltip';
import productTypes from '../data/product_type.json';
import productTypesSG from '../data/product_type_sg.json';
import Select from 'react-select';

const country = 'MY' //window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'
const countryProductType = country === "SG" ? productTypesSG : productTypes
const productTypeOptions = countryProductType.map((type) => {
    return {
        value: type.value,
        label: type.name
    }
})

const dayOptions = [
  { value: 'sun', label: 'Sunday' },
  { value: 'mon', label: 'Monday' },
  { value: 'tue', label: 'Tuesday' },
  { value: 'wed', label: 'Wednesday' },
  { value: 'thu', label: 'Thursday' },
  { value: 'fri', label: 'Friday' },
  { value: 'sat', label: 'Saturday' },
]

function createOptions(options) {
  return _.map(options, option => {
    return {
      label: option.name,
      value: option.value
    }
  })
}

const altTypeOptions = createOptions(country === 'MY' ? productTypes : productTypesSG)

class ProductBulkEdit extends Component {
    constructor(props) {
        super(props);
        const { location, user } = props;

        let ids = ""
        let isPendingProductMode = false
    
        if (location.search) {
          const queryString = qs.parse(location.search.substr(1));
    
          if (queryString.ids) {

            let ids = queryString.ids.split(',')

            //check if all the ids are numeric
            if(ids.every(id => !isNaN(id))){
              ids = queryString.ids;
            }
          }

          if(queryString.ids && queryString.ids.startsWith('P')){
            isPendingProductMode = true
          }
        }
    
        this.state = {
          showConfirmDuplicateProductModal: false,
          displayDuplicateProductSuccess: false,
          displayDuplicateProductFail: false,
          errorMessage: '',
          selectOptions: {},
          saving: false,
          successMessage: "",
          showSuccessModel: false,
          isPendingProductMode
        };
    }

    async componentDidMount() {
        const { fetchProducts, fetchTaggings } = this.props;
        const { ids } = qs.parse(location.search.substr(1));
    
        await fetchTaggings()
        await this.setSelectOptions()
        fetchProducts(ids);
    }

    setSelectOptions = async () => {
      const { taggings: { items: predefinedTags } } = this.props;
  
      function createOptions(taggings) {
        return _.map(taggings, tag => {
          return {
            label: tag.desc,
            value: tag.tag
          }
        })
      }
  
      this.setState({ 
        selectOptions: {
          dayOptions,
          locationOptions: createOptions(predefinedTags['Location']),
          occasionOptions: createOptions(predefinedTags['Occasions']),
          forWhoOptions: createOptions(predefinedTags['For Who']),
          flowerTypeOptions: createOptions(predefinedTags['Flower Type']),
        }
      })
    }

    showImage = (cell, row) => {

      if(row.parent_id && row.image_id){

        const { productBulkEdit: { items } }= this.props;

        let parent = _.find(items, {id: row.parent_id})
        if(parent){
          let image = _.find(parent.images, {id: row.image_id})

          if(image){
            return <img style={{height: '30px', maxWidth: '100%'}} src={image.src}/>;
          }
        }
      }

      if(row.parent_id && row.selected_image){
        return <img style={{height: '30px', maxWidth: '100%'}} src={row.selected_image.src}/>;
      }

      if(!cell){
        return
      }

      if(row.saving){
        return <Loader />
      } else if(cell && cell.length && cell[0]){
        return <img style={{height: '40px', maxWidth: '100%'}} src={cell[0].src}/>;
      }
    }

    renderSKU = (_, row) => {
        const { variants } = row;
    
        let skus = []
        for (let variant of variants) {
          if (variant.sku) {
            skus.push(variant.sku)
          }
        }
    
        return (
          skus.join(' ')
        )
    }

    renderStatus = cell => {
        switch (cell){
          case 'active':
            return <Badge bg="success">ACTIVE</Badge>
          case 'draft':
            return <Badge bg="default">DRAFT</Badge>
          case 'pending':
            return <Badge bg="warning">PENDING</Badge>
          case 'disapproved':
            return <Badge bg="danger">REJECTED</Badge>
          case 'reviewing':
            return <Badge bg="primary">IN REVIEW</Badge>
          case 'more_info':
            return <Badge bg="info">MORE INFO</Badge>
          default:
            break;
        }
    }

    createOptions(options) {
        return _.map(options, option => {
          return {
            label: option.name,
            value: option.value
          }
        })
    }

  renderAlternativeProductType = (cell, row) => {

    if(row.parent_id){
      return (<span className="variant-disabled">-</span>)
    }

    let altTypes = this.getAlternativeProductType(cell, row.product_type)

    return <div className="alt-type-container">
        {altTypes.map((type) => <Badge key={`${type.label}-${row.id}`} bg="secondary" className="alt-type-badge">{type.label}</Badge>)}
    </div>
  }

  renderProductType = (cell, row) => {

    if(row.parent_id){
      return (<span className="variant-disabled">-</span>)
    }

    return cell
  }

  renderTitle = (cell, row) => {

    if(row.parent_id){
      return (<span className="variant-title variant-disabled">{`\t${cell}`}</span>)
    }
    return cell
  }

  renderStatus = (cell, row) => {

    let badge = this.renderStatusBadge(cell)

    if(badge){
      return <div>
        <p style={{marginBottom: "5px"}}>{badge}</p>

        {
          !isNaN(row.id) &&
          <a href={`/products/${row.id}`} target="_blank" className="variant-disabled">View</a>
        }
        {
          isNaN(row.id) &&
          <a href={`/products/pending/${row.id}`} target="_blank" className="variant-disabled">View</a>
        }
      </div>
    }
  }

  renderStatusBadge = (cell) => {
    switch (cell){
      case 'active':
        return <Badge bg="success">ACTIVE</Badge>
      case 'draft':
        return <Badge bg="default">DRAFT</Badge>
      case 'pending':
        return <Badge bg="warning">PENDING</Badge>
      case 'disapproved':
        return <Badge bg="danger">REJECTED</Badge>
      case 'reviewing':
        return <Badge bg="primary">IN REVIEW</Badge>
      case 'more_info':
        return <Badge bg="info">MORE INFO</Badge>
      default:
        return 
        break;
    }
  }

  renderCell = (cell, row) => {

    if(cell === undefined){
      return (<span className="variant-disabled">-</span>)
    } else if(cell === null){
      return ""
    } else {
      return cell
    }
  }

  renderAttributes = (cell, row, fieldName) => {

    if(row.parent_id){
      return (<span className="variant-disabled">-</span>)
    }

    const { taggings: { items: predefinedTags } } = this.props;

    let attr = generateAttrBulkEdit(cell, predefinedTags)

    return <div className="alt-type-container">
      {attr[fieldName].map((type) => <Badge key={`${type.label}-${row.id}`} bg="secondary" className="alt-type-badge">{type.label}</Badge>)}
    </div>
  }

    getAlternativeProductType = (tags, primaryProductType) => {

        if (!tags) {
            return []
        }

        let tagsSplit = tags.split(', ');
        const typeRegex = new RegExp(/^type_/, 'i')
        let altTypes = tagsSplit.reduce((acc, tag) => {
            if (typeRegex.test(tag)) {
                let type = tag.match(/^type_(.*)/i);
        
                    if (type && type[1] !== primaryProductType){
                        acc.push({
                            label: type[1],
                            value: type[1]
                        })
                }
            }
            return acc
        }, [])

        return altTypes
    }

    updateAlternativeProductType = (tags, productType, altType) => {

      let tagsSplit = tags.split(', ')
      if(tagsSplit.length === 1 && tagsSplit[0] === ''){
        tagsSplit = []
      }
  
      tagsSplit = _.filter(tagsSplit, tag => !(/^type_|^$/i.test(tag)))
      let altTypes = _.map(altType, type => type.value)
      let allTypes = [productType, ...altTypes]
      _.each(allTypes, type => {
        tagsSplit.push(`Type_${type}`)
      })

      return tagsSplit.join(', ')
    }

    tagReTest = (item, tag) => {
      const reg = new RegExp(item.replace(/(?=[()])/g, '\\'), 'i')
      return reg.test(tag)
    }

    
    getFullTags = (oriTagStr, newTags, predefinedTagsFieldName) => {

      const { taggings: { items: predefinedTags } } = this.props;

      let tagsSplit = oriTagStr.split(', ')
      if(tagsSplit.length === 1 && tagsSplit[0] === ''){
        tagsSplit = []
      }

      let selectedTags = predefinedTags[predefinedTagsFieldName]
      const existing = _.filter(selectedTags, loc => _.some(tagsSplit, tag => {
        return this.tagReTest(loc.tag, tag)
      }))
      
      //filter out any existing tags in tagsSplit that matches lacationTags
      tagsSplit = _.filter(tagsSplit, tag => !(_.some(existing, loc => {
        return this.tagReTest(loc.tag, tag)
      })))

      let tags = _.map(newTags, nTag => nTag.value)

      _.each(tags, tag => {
        tagsSplit.push(tag)
      })

      return tagsSplit.join(', ')
    }

    renderAltTypeEditor = (editorProps, value, row, column, rowIndex, columnIndex) => {

      let alt = this.getAlternativeProductType(value, row.product_type)

      return(
          <Select
              styles={{
                  container: (baseStyles) => ({
                  ...baseStyles,
                  position: "relative !important",
                  zIndex: "10 !important",
                  })
              }}
              value={alt}
              isMulti
              onChange={(selected) => {
                  let tags = this.updateAlternativeProductType(value, row.product_type, selected)
                  editorProps.onUpdate(tags)
                }
              }
              options={altTypeOptions}
          />
      )
    }

    renderEditor = (editorProps, value, row, column, rowIndex, columnIndex, fieldName, predefinedTagsFieldName, selectOptionsFieldName) => {

      const { taggings: { items: predefinedTags } } = this.props;
      const { selectOptions } = this.state;

      let attr = generateAttrBulkEdit(value, predefinedTags)

      return(
          <Select
              value={attr[fieldName]}
              isMulti
              onChange={(selected) => {
                  let tags = this.getFullTags(value, selected, predefinedTagsFieldName)
                  editorProps.onUpdate(tags)
                }
              }
              options={selectOptions[selectOptionsFieldName]}
          />
      )
    }

    saveProductAPI = (item) => {

      let options = {
        method: 'PUT',
        body: JSON.stringify(item)
      }
    
      return api(`/products/${item.id}`, options)
    }

    renderIncludeDelivery = (cell, row) => {

      if(cell === undefined){
        return (<span className="variant-disabled">-</span>)
      }

      let checked = cell === 'true'
      return <Form.Check type="checkbox" checked={checked}/>
    }
    
    handleOnClickSaveProducts = async () => {
        const { productBulkEdit: { items }, saveProduct } = this.props;

        this.setState({ saving: true})

        let saved = 0

        for (let i = 0; i < items.length; i++) {

          if(!items[i].isDirty){
            continue
          }

          let item = _.cloneDeep(items[i]);

          if(item.variants && item.variants.length === 1){
            let variant = item.variants[0]

            variant.price = item.price
            variant.compare_at_price = item.compare_at_price
            variant.sku = item.sku
            variant.weight = item.weight

            //find m.key === "base_price" and update m.value with item.price
            let basePrice = _.find(variant.metafields, m => m.key === "base_price")
            if(basePrice){
              basePrice.value = item.price

              //find m.key === "markup" and update m.value with item.price
              let markup = _.find(variant.metafields, m => m.key === "markup")
              if(markup){
                variant.price = parseFloat(item.price) + parseFloat(markup.value)
              }
            }

            if(item.weight >= 0){
              variant.weight_unit = "kg"
            }

            items.variants = [variant]

            delete item.price
            delete item.compare_at_price
            delete item.sku
            delete item.weight
            delete item.isDirty
          }

          if(item.parent_id){
            continue
          }

          saveProduct(item.id, true)
          let result = await this.saveProductAPI(item)
          saveProduct(item.id, false)
          saved++
        }

        this.setState({ saving: false, showSuccessModel: true, successMessage: `${saved} products changes saved` })
    }

    variantCellEditable = (cell, row, rowIndex, colIndex) => {

      if(row.parent_id || (row.variants && row.variants.length == 1)){
          return true
      }

      return false
    }

    weightCellEditable = (cell, row, rowIndex, colIndex) => {

      if(row.include_delivery === 'true'){
        return false
      }

      if(row.parent_id || (row.variants && row.variants.length == 1)){
          return true
      }

      return false
    }

    handleAfterSaveCell = (oldValue, newValue, row, column, done) => {

      //check if the value is changed
      if (oldValue !== newValue) {
        //update isDirty
        row.isDirty = true
        if(row.parent_id){
          let parent = _.find(this.props.productBulkEdit.items, {id: row.parent_id})
          parent.isDirty = true
        }

        if(column.dataField === 'include_delivery' && newValue === 'true'){
          row.weight = this.getWeightMin()
        }
      }

      //force a rendering of the cell
      this.forceUpdate()
    }

    handleDismissAlert = () => {
      this.setState({
        showSuccessModel: false,
      })
    }

    getWeightMin = () => {
  
      if (country === 'MY') {
        return 0.001
      }

      if (country === 'SG') {
        return 0
      }
    }
          
    render() {

        const { productBulkEdit: { loading, items, next }, user } = this.props;
        const { selectOptions, saving, showSuccessModel, successMessage, isPendingProductMode } = this.state;

        const isAdmin = user.role === 'admin' || user.role === 'staff'

        //check if any entry inside items where item.isDirty is ture
        const isDirty = _.some(items, item => item.isDirty)

        const options = {
            showTotal: false,
            sizePerPage: 999,
            sizePerPageList: [],
        };

        const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

        const columns = [
            {
              dataField: 'id',
              text: 'Id',
              hidden: true,
            },
            {
                dataField: 'images',
                text: 'Image',
                headerStyle: {
                  width: '60px'
                },
                align: 'center',
                formatter: this.showImage,
                editable: false
            },
            {
              dataField: 'status',
              headerStyle: {
                width: '100px',
                resize: 'horizontal'
              },
              text: 'Status',
              formatter: this.renderStatus,
              editable: false
            },
            {
              dataField: 'title',
              headerStyle: {
                width: '200px',
                resize: 'horizontal'
              },
              text: 'Title',
              formatter: this.renderTitle,
              editable: (cell, row, rowIndex, colIndex) => {
                if(row.parent_id){
                  return false
                }

                return true
              }
            },
            {
                dataField: 'product_type',
                text: 'Primary Product Type',
                headerStyle: {
                  width: '200px',
                  resize: 'horizontal'
                },
                editor: {
                    type: Type.SELECT,
                    options: productTypeOptions
                },
                formatter: this.renderProductType,
                editable: (cell, row, rowIndex, colIndex) => {
                  if(row.parent_id){
                    return false
                  }
  
                  return true
                }
            },
            {
                dataField: 'tags',
                text: 'Alternative Product Type',
                sort: false,
                headerStyle: {
                  width: '200px',
                  resize: 'horizontal'
                },
                editCellStyle: {
                    overflow: 'visible'
                },
                formatter: this.renderAlternativeProductType,
                editorRenderer: this.renderAltTypeEditor,
                editable: (cell, row, rowIndex, colIndex) => {
                  if(row.parent_id){
                    return false
                  }
  
                  return true
                }
            },
            {
              dataField: 'tags',
              text: 'Location',
              sort: false,
              hidden: country === 'SG' ? true : false,
              headerStyle: {
                width: '200px',
                resize: 'horizontal'
              },
              editCellStyle: {
                  overflow: 'visible'
              },
              formatter: (cell, row) => { return this.renderAttributes(cell, row, "locationsSelected")},
              editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex, fieldName) => { return this.renderEditor(editorProps, value, row, column, rowIndex, columnIndex, "locationsSelected", "Location", "locationOptions")},
              editable: (cell, row, rowIndex, colIndex) => {
                if(row.parent_id){
                  return false
                }

                return true
              }
          },
          {
            dataField: 'tags',
            text: 'Occasions',
            sort: false,
            headerStyle: {
              width: '200px',
              resize: 'horizontal'
            },
            editCellStyle: {
                overflow: 'visible'
            },
            formatter: (cell, row) => { return this.renderAttributes(cell, row, "occasionsSelected")},
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex, fieldName) => { return this.renderEditor(editorProps, value, row, column, rowIndex, columnIndex, "occasionsSelected", "Occasions", "occasionOptions")},
            editable: (cell, row, rowIndex, colIndex) => {
              if(row.parent_id){
                return false
              }

              return true
            }
          },
          {
            dataField: 'tags',
            text: 'For Who',
            sort: false,
            headerStyle: {
              width: '150px',
              resize: 'horizontal'
            },
            editCellStyle: {
                overflow: 'visible'
            },
            formatter: (cell, row) => { return this.renderAttributes(cell, row, "forWhoSelected")},
            editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex, fieldName) => { return this.renderEditor(editorProps, value, row, column, rowIndex, columnIndex, "forWhoSelected", "For Who", "forWhoOptions")},
            editable: (cell, row, rowIndex, colIndex) => {
              if(row.parent_id){
                return false
              }

              return true
            }
          },
          {
            dataField: 'price',
            text: 'Price',
            sort: false,
            headerStyle: {
              width: '100px',
              resize: 'horizontal'
            },
            formatter: this.renderCell,
            editable: this.variantCellEditable
          },
          {
            dataField: 'compare_at_price',
            text: 'Compare at price',
            sort: false,
            headerStyle: {
              width: '100px',
              resize: 'horizontal'
            },
            formatter: this.renderCell,
            editable: this.variantCellEditable
          },
          {
            dataField: 'sku',
            text: 'SKU',
            sort: false,
            headerStyle: {
              width: '100px',
              resize: 'horizontal'
            },
            formatter: this.renderCell,
            editable: this.variantCellEditable
          },
          {
            dataField: 'include_delivery',
            text: 'Delivery Included in Selling Price',
            sort: false,
            headerStyle: {
              width: '100px',
              resize: 'horizontal'
            },
            formatter: this.renderIncludeDelivery,
            editor: {
              type: Type.CHECKBOX,
              value: 'true:false'
            },
            editable: this.variantCellEditable
          },
          {
            dataField: 'weight',
            text: 'Weight (kg)',
            sort: false,
            headerStyle: {
              width: '100px',
              resize: 'horizontal'
            },
            formatter: this.renderCell,
            editable: this.weightCellEditable
          },
          {
            dataField: 'isDirty',
            text: 'Dirty',
            sort: false,
            headerStyle: {
              width: '100px',
              resize: 'horizontal'
            },
            formatter: this.renderCell,
            editable: false,
            hidden: true
          }
        ];

        const cellEditOptions = { 
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.handleAfterSaveCell,
            // nonEditableRows: () => [0, 3]
        }

        return (
            <div>
              <TabHeader title="Product Bulk Edit" tab_id="productBulkEdit" user={user} />
                {
                    loading &&
                    <Loader />
                }
                {
                  showSuccessModel &&
                  <div className="sticky-alert">
                    <Alert variant="success" dismissible onClose={this.handleDismissAlert}>
                      <span>{successMessage}</span>
                    </Alert>
                  </div>
                }
                <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap">
                  <Col sm="auto">
                      <Button
                      disabled={saving || !isDirty }
                      variant="secondary"
                      className="w-100 btn-br-6"
                      onClick={this.handleOnClickSaveProducts}
                      >{saving ? 'Saving': 'Save'}</Button>
                  </Col>
                  {
                    isPendingProductMode &&
                    <Col sm="auto">
                      <Button
                      variant="secondary"
                      className="w-100 btn-br-6"
                      onClick={() => this.props.history.push('/products')}
                      >Go to Product Listing</Button>
                    </Col>
                  }
                </Container>
                <BootstrapTable
                    wrapperClasses="overflow-visible"
                    keyField='id'
                    data={items}
                    columns={columns}
                    pagination={paginationFactory(options)}
                    hover expandableRow={() => true}
                    cellEdit={ cellEditFactory(cellEditOptions) }
                    // expandComponent={this.renderExpandedNoteColumn}
                />
            </div>
        );
    }
}


export default connect((state, props) => {
    const { pathname, search, routeState } = props.location;
    return {
      user: state.auth.user,
      productBulkEdit: state.productBulkEdit,
      taggings: state.taggings,
    };
}, {...productsActionCreators,
  ...taggingsActionCreators,})(ProductBulkEdit);