import React, { Component } from "react";
import api from '../helpers/apiClient';
import { connect } from "react-redux";
import moment from "moment";
import TabHeader from "../components/TabHeader";
import { Badge, ButtonToolbar, Button } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import ReactTooltip from "react-tooltip";
import Loader from "../components/Loader";
import * as errorActionCreators from '../redux/actions/error';
import GTRAlert from '../components/GTRAlert';
import ShippingModal from '../components/ShippingModal';
import { Link } from "react-router-dom";
import ProcurementShippingAddressModal from '../components/ProcurementShippingAddressModal';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'
const giftrDomain = window.location.hostname === 'seller.giftr.my' ? 'https://giftr.my' : 'https://giftr.sg'

class ProcurementOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      orders: [],
      bills: [],
      showShippingModal: false,
      selectedIds: [],
      selectedIds: [],
      creatingBill: false,
      showSelectShippingAddressModal: false,
    }
  }

  componentDidMount() {
    this.fetchOrders();
    this.fetchBills();
  }

  fetchOrders = () => {
    return api(`/procurement_orders`).then(result => {
      this.setState({ loading: false });
      if (result && result.orders) {
        this.setState({ orders: result.orders });
      }
    })

  }

  fetchBills = () => {
    return api(`/procurement_bills`).then(result => {
      console.log(result)
      if (result && result.bills) {
        this.setState({ bills: result.bills });
      }
    })
  }

  dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = "01/01/2010";
    }

    if (!date2) {
      date2 = "01/01/2010";
    }

    if (order === "desc") {
      return (
        moment(date2, "DD/MM/YYYY").unix() - moment(date1, "DD/MM/YYYY").unix()
      );
    } else {
      return (
        moment(date1, "DD/MM/YYYY").unix() - moment(date2, "DD/MM/YYYY").unix()
      );
    }
  };

  renderFulfillmentStatus = (cell, row) => {
    if (row.cancelled_at || row.line_item_cancelled || row.fulfillment_status == 'cancelled') {
      return (
        <Badge bg="danger text-uppercase">Cancelled</Badge>
      );
    } else if (_.lowerCase(row.fulfillment_status) === "fulfilled") {
      return (
        <Badge bg="success text-uppercase">
          {row.fulfillment_status || "Unfulfilled"}
        </Badge>
      );
    } else if (row.fulfillment_status) {
      return (
        <Badge bg="warning text-uppercase">
          {row.fulfillment_status}
        </Badge>
      );
    } else {
      return (
        <Badge bg="warning text-uppercase">Unfulfilled</Badge>
      );
    }
  };

  renderShipBy = (cell, row) => {
    let shouldAlert = moment(
      row.ship_by,
      "DD/MM/YYYY"
    ).isSameOrBefore(moment(), "day");

    if (
      shouldAlert &&
      _.lowerCase(row.fulfillment_status) !== "fulfilled" &&
      !row.cancelled_at &&
      !row.line_item_fulfilled
    ) {
      return (
        <span className="ship-by-alert" key={`delivery_${row._id}`}>
          {row.ship_by}
        </span>
      );
    }
    return (
      <span key={`delivery_${row._id}`}>{row.ship_by}</span>
    );
  };

  renderReadyToShip = (cell, row) => {
    if (!row.ready_to_ship) {
      return (
        <Badge bg="warning text-uppercase">Pending</Badge>
      );
    }
    return <Badge bg="success text-uppercase">Accepted</Badge>;
  };

  renderBillStatus = (cell, row) => {
    if (cell === 'unbilled') {
      return <Badge bg="warning text-uppercase">{cell}</Badge>;
    }
    if (cell === 'pending') {
      return <Badge bg="secondary text-uppercase">{cell}</Badge>;
    }
    return <Badge bg="success text-uppercase">{cell}</Badge>;
  };

  handleOnClickShowShippingModal = (item) => {
    let shipping_address = {
      first_name: 'Giftr',
      address1: 'Unit 2-1, Level 2, The Podium, Tower 3, UOA Business Park No, 1, Jalan Pengaturcara U1/51, Seksyen U1',
      phone: '+60193596194',
      city: 'Shah Alam',
      zip: '40150',
      state: 'Selangor',
      country: 'Malaysia',
      name: 'Giftr',
      country_code: 'MY',
    }

    let parcel = {
      order_id: item.order_id,
      order_name: item.order_name,
      shipping_address: shipping_address,
      weight: item.grams,
      line_items: [item],
      value: item.price,
      vendor: item.vendor,
    }

    this.setState({
      showShippingModal: true,
      parcel: parcel
    });
  }

  handleOnCloseShowShippingModal = () => {
    this.setState({
      showShippingModal: false,
    });
  }

  handleConfirmShipment = () => {

  }

  handleOnClickMarkAsReady = async (item) => {
    return api(`/procurement_orders/${item._id}/ready_to_ship`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        itemId: item.line_item_id
      })
    }).then(() => {
      this.fetchOrders()
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
      }
    });
  }

  handleonClickShowAddFulfillImentModal = (item) => {
  }

  renderOrderDetail = (item) => {
    const { user } = this.props;
    const { shipment } = this.state;

    const isAdmin = user && (user.role === 'admin' || user.role === 'staff')

    let fulfillable = item.fulfillment_status !== 'fulfilled'

    return (
      <>
        <div className="mb-2">
          <h4 className="d-inline-block mt-2 text-uppercase" style={{color: '#FFC107'}}>{item.fulfillment_status || 'unfulfilled'}</h4>
          <ButtonToolbar className="pull-right d-print-none gap-3">
            {
              (isAdmin || user.allow_shipment) &&
                <Button className="btn-br-6" variant="primary" disabled={!fulfillable} onClick={() => this.handleOnClickShowShippingModal(item)}>Arrange Shipment</Button>
            }
            <Button className="btn-br-6" variant="secondary" disabled={item.ready_to_ship} onClick={() => this.handleOnClickMarkAsReady(item)}>Mark As Accepted</Button>
            <Button className="btn-br-6" variant="primary" disabled={!fulfillable} onClick={this.handleOnClickShowAddFulfillImentModal}>Mark selected items as Fulfilled</Button>
          </ButtonToolbar>
        </div>
        <div className="clearfix"></div>
        <table className="table">
          <tbody>
            <tr key={item._id}>
              <td width="70">
                { item.image &&
                <div className="line-item-image">
                  <img src={item.image} alt={item.title}/>
                </div>
                }
              </td>
              <td className="line-item-detail" colSpan="2">
                <div><a target="_blank" href={`${giftrDomain}/products/${item.handle}`}><strong>{item.title}</strong></a></div>
                { item.ship_by ?
                  <div className="d-grid mb-2">
                    <label>Ship By</label>
                    { item.ship_by && moment(item.ship_by, 'DD/MM/YYYY').isSameOrBefore(moment(), 'day') && _.lowerCase(item.fulfillment_status) !== 'fulfilled' && _.lowerCase(item.fulfillment_status) !== 'cancelled' ? <span style={{color: '#d15d5d'}}>{item.ship_by}</span> : <span>{item.ship_by}</span>}
                  </div>
                  :
                    null
                }
                { item.properties.map(prop => (
                  <div className="d-grid" key={prop.name}>
                    <div>
                      <label>{prop.name}</label>
                      <p className="order-detail-item-desc">{prop.value}</p>
                    </div>
                  </div>
                ))}
              </td>
              <td colSpan="1" className="text-end" style={{verticalAlign: "middle"}}>
                { shipment &&
                <div className="mb-5 d-grid gap-1" style={{wordBreak: 'break-word'}}>
                  <div>
                    <Badge bg="info" className="text-uppercase">Shipment</Badge>
                  </div>
                  {shipment[0]}
                  <div>
                    {item.shipment_status}
                  </div>
                </div>
                }
                <div className="d-inline-flex flex-column">
                  {item.ready_to_ship && <div><Badge bg="success" className="text-uppercase mb-2">Accepted</Badge><br/></div>}
                  <span className="white-space-no-wrap"><span className="d-print-none">{currency} {item.price} </span>x 1 Unit(s)</span><br/><span className="d-print-none white-space-no-wrap">= {currency} {item.price}</span><br/>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }

  onRowSelect = (row, isSelected, e) => {

    let rows = [row];
    let { selectedIds } = this.state;

    if (isSelected) {
      _.each(rows, row => {
        selectedIds.push(row._id)
      })
    } else {
      _.each(rows, row => {
        selectedIds = _.filter(selectedIds, id => id !== row._id)
      })
    }

    this.setState({ selectedIds });
  };

  onSelectAll = (isSelected, rows) => {

    let { selectedIds } = this.state;

    if (isSelected) {
      _.each(rows, row => {
        selectedIds.push(row._id)
      })
    } else {
      _.each(rows, row => {
        selectedIds = _.filter(selectedIds, id => id !== row._id)
      })
    }

    this.setState({ selectedIds });
  }

  renderDeliveryStatus = (cell, row) => {
    if (cell === 'on time') {
      return (
        <Badge bg="success text-uppercase">{cell}</Badge>
      )
    } else if (cell === 'delayed') {
      return (
        <Badge bg="danger text-uppercase">{cell}</Badge>
      )
    } else {
      return (
        <Badge bg="warning text-uppercase">{cell}</Badge>
      )
    }
  }

  priceSort = (price1, price2, order) => {
    price1 = Number(price1);
    price2 = Number(price2);

    if (!price1) {
      price1 = 0
    }

    if (!price2) {
      price2 = 0
    }

    if (order === 'desc') {
      return price2 - price1
    } else {
      return price1 - price2
    }
  }

  renderDetailLink = (cell, row) => {
    return (
      <Link
        key={row._id}
        to={`/procurement_orders/${row.order_mongo_id}`}
      >
        {row.order_name}
      </Link>
    );
  };

  handleGroupBill = () => {
    this.setState({ creatingBill: true })

    const { orders, selectedIds } = this.state

    let selectedLineItems = _.filter(orders, (line) => {
      return _.includes(selectedIds, line._id)
    })

    selectedLineItems = _.map(selectedLineItems, (order) => {
      return {
        order_id: order.order_mongo_id,
        id: order._id,
      }
    })

    return api(`/procurement_bills`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        lineItems: selectedLineItems
      })
    }).then(result => {
      console.log(result)
      this.setState({ creatingBill: false })
      this.fetchOrders();
      this.fetchBills();
    })
  }

  onClickSetShippingAddress = () => {
    this.setState({ showSelectShippingAddressModal: true })
  }

  renderBillLink = (cell, row) => {
    return (
      <Link
        key={row._id}
        to={`/procurement_bills/${row._id}`}
      >
        {moment(row.created_at).format('DD-MM-YYYY')}
      </Link>
    );
  }

  renderStatus = (cell, row) => {

    return row.status === 'paid' ?
      <Badge bg="success" className="text-uppercase">{row.status}</Badge>
      :
      <Badge bg="warning" className="text-uppercase">{row.status}</Badge>
  }

  renderPrice = (cell, row) => {

    return <span>{currency} {cell ? cell.toFixed(2) : "0.00"}</span>
  }

  renderShipping = (cell, row) => {

    return <span>{currency} {row.total_shipping.toFixed(2)}</span>
  }

  renderInvoiceNumber = (cell) => {

    if (cell) {
      return (
        <a
          target="_blank"
          href={cell}>
          Show
        </a>
      )
    }
  }

  render() {
    const { user, error } = this.props
    const { loading, orders, showShippingModal, parcel, selectedIds, bills, creatingBill, showSelectShippingAddressModal } = this.state

    const isAdmin = user && user.role === 'admin';
    const isStaff = user && user.role === 'staff';

    let unselectable = []

    let selectRowProp = {
      mode: "checkbox",
      clickToSelect: true,
      selected: selectedIds,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      nonSelectable: unselectable,
      headerColumnStyle: { width: '30px' },
    };

    const columns = [
      {
        dataField: 'created_date',
        text: 'Date',
        sort: true,
          headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'order_name',
        text: 'Order Id',
        csvExport: false,
        sort: true,
        formatter: this.renderDetailLink,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'fulfillment_status',
        text: 'Fulfillment',
        formatter: this.renderFulfillmentStatus,
        sort: true,
        headerStyle: {
          width: '110px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'title',
        text: 'Items',
        sort: true,
        headerStyle: {
          width: '170px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'ship_by',
        sort: true,
        sortFunc: this.dateSort,
        formatter: this.renderShipBy,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: '',
        headerFormatter: () => {
          return (
            <>
              <span data-tip="To be fufilled on or before this date.">
                Ship by <i className="fa fa-info-circle"></i>
              </span>
              <ReactTooltip/>
            </>
          )
        }
      },
      {
        dataField: 'delivery_status',
        hidden: !isAdmin && !isStaff,
        sort: true,
        formatter: this.renderDeliveryStatus,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: '',
        headerFormatter: () => {
          return (
            <>
              <span data-html="true" data-tip="<p>PENDING: Order is unfulfilled<br/>ON TIME: Order fulfillment is on time<br/>DELAYED: Order fulfillment is delayed</p>">
                Status <i className="fa fa-info-circle"></i>
              </span>
              <ReactTooltip/>
            </>
          )
        }
      },
      {
        dataField: 'vendor',
        hidden: !isAdmin && !isStaff,
        sort: true,
        text: 'Vendor',
        headerStyle: {
          width: '170px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'price',
        hidden: !isAdmin && !isStaff,
        sort: true,
        text: 'Price',
        sortFunc: this.priceSort,
        headerStyle: {
          width: '70px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'quantity',
        text: 'Qty',
        headerStyle: {
          width: '45px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'ready_to_ship',
        formatter: this.renderReadyToShip,
        text: 'Accepted',
        sort: true,
        headerStyle: {
          width: '95px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'bill_status',
        formatter: this.renderBillStatus,
        text: 'Bill Status',
        sort: true,
        headerStyle: {
          width: '95px',
          resize: 'horizontal'
        }
      },
    ]

    const billColumns = [
      {
        dataField: '_id',
        headerStyle: {
          width: '100px',
          resize: 'horizontal',
        },
        formatter: this.renderBillLink,
        text: 'Bill',
      },
      {
        dataField: 'status',
        sort: true,
        formatter: this.renderStatus,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Status',
      },
      {
        dataField: 'vendor',
        sort: true,
        hidden: !isAdmin,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Merchant',
      },
      {
        dataField: 'item_price',
        sort: true,
        text: 'Total Item Price',
        formatter: this.renderPrice,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'order_count',
        sort: true,
        text: 'No. of Orders',
        hidden: !isAdmin,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'total_shipping',
        sort: true,
        formatter: this.renderShipping,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'Total Shipping',
      },
      {
        dataField: 'balance',
        sort: true,
        formatter: this.renderPrice,
        headerStyle: {
          width: '125px',
          resize: 'horizontal'
        },
        text: 'Total Payout Bal.',
      },
      {
        dataField: 'invoice_link',
        formatter: this.renderInvoiceNumber,
        sort: true,
        headerStyle: {
          width: '135px',
          resize: 'horizontal'
        },
        text: 'Invoice',
      },
    ]

    return (
      <>
        {
          error &&
          <GTRAlert variant="danger" alert={error} />
        }
        <TabHeader
          title="Procurement Orders"
          tab_id="procurement"
          user={user}
          children={
            <>
              { isAdmin &&
              <Button variant="primary" onClick={this.onClickSetShippingAddress} className="btn-br-6">Set Shipping Address</Button>
              }
              <Button variant="primary" disabled={selectedIds.length === 0 || creatingBill} onClick={this.handleGroupBill} className="btn-br-6">Group Bill</Button>
            </>
          }
        />
        { loading && <Loader /> }
        { !loading && orders.length > 0 &&
            <ToolkitProvider
              keyField="_id"
              data={orders}
              columns={columns}
            >
              { props => (
                <BootstrapTable
                  { ...props.baseProps }
                  bordered={ false }
                  selectRow={selectRowProp}
                  // pagination={paginationFactory(options)}
                  expandRow={{ renderer: this.renderOrderDetail }}
                  hover
                  defaultSorted={[{
                    dataField: 'created_at',
                      order: 'desc',
                  }]}
                  noDataIndication={() => 'There is no data to display'}
                />
              )}
            </ToolkitProvider>
        }
        {!loading && bills.length > 0 &&
            <ToolkitProvider
              keyField="_id"
              data={bills}
              columns={billColumns}
            >
              { props => (
                <BootstrapTable
                  { ...props.baseProps }
                  bordered={ false }
                  // pagination={paginationFactory(options)}
                  hover
                  defaultSorted={[{
                    dataField: 'created_at',
                      order: 'desc',
                  }]}
                  noDataIndication={() => 'There is no data to display'}
                />
              )}
            </ToolkitProvider>
        }
        { showShippingModal &&
          <ShippingModal user={user} parcels={[parcel]} onClose={this.handleOnCloseShowShippingModal} confirmShipment={this.handleConfirmShipment} match={this.props.match} hasShippingFee={true} isProcurement={true}/>
        }
        { showSelectShippingAddressModal &&
            <ProcurementShippingAddressModal
              onClose={() => this.setState({ showSelectShippingAddressModal: false })}
            />
        }
      </>
    )
  }
}

export default connect((state) => {
  return {
    user: state.auth.user,
    error: state.error,
  }
}, {
  ...errorActionCreators
})(ProcurementOrders);
