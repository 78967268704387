import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_MERCHANTRATES_REQUEST = 'FETCH_MERCHANTRATES_REQUEST';
export const FETCH_MERCHANTRATES_SUCCESS = 'FETCH_MERCHANTRATES_SUCCESS';
export const FETCH_MERCHANTRATES_FAILURE = 'FETCH_MERCHANTRATES_FAILURE';
export const CLEAR_MERCHANTRATES = 'CLEAR_MERCHANTRATES';

function fetchMerchantRatesRequest() {
  return {
    type: FETCH_MERCHANTRATES_REQUEST,
  };
}

function fetchMerchantRatesSuccess(json) { 
  return {
    type: FETCH_MERCHANTRATES_SUCCESS,
    payload: {
      items: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchMerchantRatesFailure() {
  return {
    type: FETCH_MERCHANTRATES_FAILURE
  };
}

function shouldFetchMerchantRates(state) {
  const results = state.merchantRates;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

function fetchMerchantRatesFromApi() {
  return dispatch => {
    dispatch(fetchMerchantRatesRequest());
    return api('/merchants')
      .then(json => {
        dispatch(fetchMerchantRatesSuccess(json));
      })
      .catch(err => {
        dispatch(fetchMerchantRatesFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchMerchantRates() {
  return (dispatch, getState) => {
    if (shouldFetchMerchantRates(getState())) {
      return dispatch(fetchMerchantRatesFromApi());
    }
  };
}

export function clearMerchantRates() {
  return {
    type: CLEAR_MERCHANTRATES,
  };
}