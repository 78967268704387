import { renderToStaticMarkup } from 'react-dom/server'
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ReactTooltip from 'react-tooltip';
import conditionalPreview from '../images/conditional-preview.gif'
import { useEffect, useState } from 'react';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : '$'

const FieldCreate = ({tagType, values, onChange, index, handleAddOption, handleEditOptions, handleRemoveOption, addConditionalField, showAddConditionalField = true, isAdmin, titles = [], archived = false}) => {
  return (
    <Row className="d-grid gap-3">
      <Col className="d-grid gap-1">
        <label>Instruction To State *</label>
        <input className="form-control" name="primaryTitle" type="text" required defaultValue={(values && values.primaryTitle) || ''} id={`instruction_${index}`} onChange={onChange} disabled={archived}/>
      </Col>
      { (tagType === 'textfield' || tagType === 'textarea') &&
      <Col className="d-grid gap-1">
        <label>Max Characters</label>
        <input className="form-control" name="maxChar" type="number" required defaultValue={(values && values.maxChar) || ''} id={`maxchar_${index}`} onChange={onChange} disabled={archived}/>
      </Col>
      }
      { (tagType === 'textfield' || tagType === 'textarea' || tagType === 'dropdown') &&
        <Col className="d-grid gap-1">
          <label>Secondary Title (Optional)</label>
          <input className="form-control" name="secondTitle" type="text" defaultValue={(values && values.secondTitle) || ''} id={`secondarytitle_${index}`} onChange={onChange} disabled={archived}/>
        </Col>
      }
      { (tagType === 'multiupload' || tagType === 'checkbox' || tagType === 'cbaddon') &&
        <Col className="d-grid gap-1">
          <label>Limit *</label>
          <input className="form-control" name="limit" type="number" required defaultValue={(values && values.limit) || ''} id={`limit_${index}`} onChange={onChange} disabled={archived}/>
        </Col>
      }
      { (tagType === 'ddaddon' || tagType === 'cbaddon') &&
        <Row className='table-responsive mx-1'>
          <table className="table" style={{ fontSize: '0.9em' }}>
            <thead>
              <tr>
                <th width="70">Options *</th>
                <th width="70">Price ({currency}) *</th>
                <th width="20">Remove</th>
              </tr>
            </thead>
            <tbody>
              { values && values.options && values.options.map((option, j) => {
                let [name = '', price = ''] = option.value.split('#')
                return (
                  <tr key={j}>
                    <td>
                      <input className="form-control" name={`name_${j}`} type="text" value={name} onChange={(e) => handleEditOptions(e, j, index, tagType)} required  disabled={archived}/>
                    </td>
                    <td>
                      <input className="form-control" name={`price_${j}`} type="number" step=".1" value={price} onChange={(e) => handleEditOptions(e, j, index, tagType)} required  disabled={archived}/>
                    </td>
                    <td style={{verticalAlign: 'middle'}}>
                      <Button className="px-2 py-0" variant="danger" onClick={() => handleRemoveOption(j, index)} disabled={archived}><FontAwesomeIcon icon={solid('close')} /></Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div>
            { !archived &&
            <Button className="shadow-sm" variant="primary" onClick={() => handleAddOption(index)}><FontAwesomeIcon icon={solid('plus')} className="mx-1"/>&nbsp;Add Option</Button>
            }
            { showAddConditionalField && isAdmin && !archived &&
            <Button className="shadow-sm ms-2" variant="primary" onClick={addConditionalField}>
              <FontAwesomeIcon icon={solid('plus')} className="mx-1"/>
              &nbsp;Add Conditional Field
              <i className="ms-1" data-html={true} data-tip={renderToStaticMarkup(
                <div className="d-grid gap-2">
                  <img style={{maxWidth: '500px'}} src={conditionalPreview} />
                  <p className="m-0">Add fields that are displayed based on other fields.<br/>Select condition(s) to display under "When Option Is:"</p>
                </div>
              )} data-for="conditional-fields"><FontAwesomeIcon icon={solid('info-circle')} /></i><ReactTooltip id="conditional-fields" />
            </Button>
            }
          </div>
        </Row>
      }
      { (tagType === 'textfield' || tagType === 'dropdown' || tagType === 'imageupload' || tagType === 'multiupload') &&
        <Row>
          <Col xs="auto"><label>Required</label></Col>
          <Col xs={1}>
            <label className='custom-checkbox' style={{marginTop: '2px', marginLeft: '0.1em'}}>
              <input type="checkbox" name="required" onChange={onChange} defaultChecked={values && values.required} id={`required_${index}`} disabled={archived}/>
              <span className="checkmark checkmark-smaller"></span>
            </label>
          </Col>
        </Row>
      }
      { (tagType === 'dropdown' || tagType === 'timeslot' || tagType === 'checkbox') &&
        <Row className='table-responsive mx-1'>
          <table className="table" style={{ fontSize: '0.9em' }}>
            <thead>
              <tr>
                <th width="70">Options *</th>
                <th width="20">Remove</th>
              </tr>
            </thead>
            <tbody>
              { values && values.options && values.options.map((option, j) => {
                return (
                  <tr key={j}>
                    <td>
                      <input className="form-control" name={`name_${j}`} type="text" value={option.value || ''} onChange={(e) => handleEditOptions(e, j, index, tagType)} required disabled={archived}/>
                    </td>
                    <td style={{verticalAlign: 'middle'}}>
                      <Button className="px-2 py-0" variant="danger" onClick={() => handleRemoveOption(j, index)}disabled={archived}><FontAwesomeIcon icon={solid('close')} /></Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div>
            { !archived &&
            <>
            <Button className="shadow-sm" variant="primary" onClick={() => handleAddOption(index)}><FontAwesomeIcon icon={solid('plus')} className="mx-1"/>&nbsp;Add Option</Button>
            { showAddConditionalField && isAdmin &&
            <Button className="shadow-sm ms-2" variant="primary" onClick={addConditionalField}>
              <FontAwesomeIcon icon={solid('plus')} className="mx-1"/>
              &nbsp;Add Conditional Field
              <i className="ms-1" data-html={true} data-tip={renderToStaticMarkup(
                <div className="d-grid gap-2">
                  <img style={{maxWidth: '500px'}} src={conditionalPreview} />
                  <p className="m-0">Add fields that are displayed based on other fields.<br/>Select condition(s) to display under "When Option Is:"</p>
                </div>
              )} data-for="conditional-fields"><FontAwesomeIcon icon={solid('info-circle')} /></i><ReactTooltip id="conditional-fields" />
            </Button>
            }
            </>
            }
          </div>
        </Row>
      }
    </Row>
  )
}

export default FieldCreate;
