import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

const GTRAlert = ({ alert, variant, ...restProps }) => {
  return (
    <Alert variant={variant} {...restProps}>
      {
        Array.isArray(alert) ?
        <ul>
        {
          alert.map((alert, i) => (
            <li key={i}>
              {alert.msg}
            </li>
          ))
        }
        </ul>
        :
        alert
      }
    </Alert>
  )
}

export default GTRAlert;
