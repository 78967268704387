import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Card, Modal, Row, Col, Button, Alert } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import Input from '../components/Input';
import Select from '../components/Select';
import Loader from '../components/Loader';
import userRolesOptions from '../data/userRoles.json';
import api from '../helpers/apiClient';
import states from '../data/states.json'
import countries from '../data/countries.json'
import * as merchantsActionCreators from '../redux/actions/merchants';
import * as userActionCreators from '../redux/actions/user';

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

const validate = (values, props) => {
  const { match } = props;
  const errors = {}
  if (!match.params.userId && !values.password) {
    errors.password = 'Required';
  }
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.role) {
    errors.role = 'Required';
  }
  if ((values.role === 'merchant' || values.role === 'merchant-staff') && !values.merchantId1) {
    errors.merchantId1 = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  }
  // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
  //   errors.email = 'Invalid email address';
  // }
  return errors
}

class User extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { fetchMerchants, fetchUser, match } = this.props;
    fetchMerchants();
    if (match.params.userId) {
      fetchUser(match.params.userId);
    }

    document.querySelector('.modal-header').style.display = 'none'
  }

  submit = (values) => {
    const { match } = this.props;
    const { email, password, role, merchantId1, merchantId2, supplierPrefix, name, company, contact, address1, address2, postcode, city, state, country, allow_product } = values;
    const url = match.params.userId ? `/users/${match.params.userId}` : '/users';
    return api(url, {
      method: match.params.userId ? 'PUT': 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email, password, role, merchantId1, merchantId2, supplierPrefix, name, company, contact, address1, address2, postcode, city, state, country, allow_product
      })
    }).then(() => {
      const { history } = this.props;
      history.push('/users', { reload: true });
      // or goBack?
    }).catch(err => {
      if (err) {
        window.scrollTo(0, 0);
        if (err.validationError) {
          throw new SubmissionError({
            _error: err.validationError
          });
        }
        else if (err.message) {
          throw new SubmissionError({
            _error: err.message
          });
        }
        else {
          throw new SubmissionError({
            _error: 'Something bad happened'
          });
        }
      }
    });
  }
  render() {
    const { user: { loading, loaded, item }, roleValue, merchantsOptions, handleSubmit, error, submitting, match } = this.props
    const isReadyToRenderForm = ((match.params && match.params.userId && item) || (!match.params || !match.params.userId)) && merchantsOptions && merchantsOptions.length;

    const isCreateUser = match.path === '/users/create'

    return (
      <div>
        {
          (match.params && loading && (!merchantsOptions || !merchantsOptions.length)) ||
          (!merchantsOptions || !merchantsOptions.length) &&
          <Loader />
        }
        {
          isReadyToRenderForm ?
          <>
          <Modal.Header className="mx-2 mt-1 pt-0 px-0 merchant-modal-header" closeButton>
            { isCreateUser ?
              <h4>Create User </h4>
              :
              <h4>User Detail</h4>
            }
          </Modal.Header>
          <form onSubmit={handleSubmit(this.submit)}>
          <Row className="mt-4 mx-0 justify-content-center">
            <Col lg={6}>
            <Card className="tealCard shadow-sm">
              <Card.Header className="tealCardHeader">User Info</Card.Header>
              <Card.Body className="m-2 d-grid gap-3">
                {
                  error &&
                  <Alert variant="danger">
                    {
                      Array.isArray(error) ?
                      <ul>
                      {
                        error.map((err, i) => (
                          <li key={i}>
                            {err.msg}
                          </li>
                        ))
                      }
                      </ul>
                      :
                      error
                    }
                  </Alert>
                }
                  <Field className="form-control" required name="name" label="Contact Person" placeholder="Name" type="text" component={Input} />
                  <Field className="form-control" name="email" label="Email" placeholder="Email" type="text" component={Input} />
                  <Field className="form-control" required name="contact" label="Contact Number" placeholder="Contact" type="text" component={Input} />
                  <Field className="form-control" name="password" label="Password" placeholder="Password" type="password" component={Input} />
                  <Field className="form-control" name="role" label="Role" type="select" component={Select} options={userRolesOptions} defaultOptionName="Select a user role" />
                  {
                    (roleValue === 'merchant' || roleValue === 'merchant-evouchers' || roleValue === 'merchant-staff') &&
                    <>
                      <Field className="form-control" name="merchantId1" label="Merchant 1" type="select" component={Select} options={merchantsOptions} defaultOptionName="Select a merchant" />
                      <Field className="form-control" name="merchantId2" label="Merchant 2" type="select" component={Select} options={merchantsOptions} defaultOptionName="Select a merchant" />
                    </>
                  }
                  {
                    roleValue === 'supplier' &&
                    <Field className="form-control" required name="supplierPrefix" label="Supplier Prefix" type="text" component={Input} defaultOptionName="Supplier Prefix" />
                  }
              </Card.Body>
            </Card>
            </Col>
            {
              !isCreateUser &&
              <Col lg={6} className="mt-4 mt-lg-0">
                <Card className="tealCard shadow-sm">
                  <Card.Header className="tealCardHeader">Address</Card.Header>
                  <Card.Body className="m-2 d-grid gap-3">
                    <Field className="form-control" name="company" label="Company Name" placeholder="Company name (optional)" type="text" component={Input} />
                    <Field className="form-control" required name="address1" label="Address 1" placeholder="Address 1" type="text" component={Input} />
                    <Field className="form-control" name="address2" label="Address 2" placeholder="Address 2" type="text" component={Input} />
                    <Row>
                      <Col xs={6}>
                        <Field className="form-control" required name="postcode" label="Postcode" placeholder="Postcode" type="text" component={Input} />
                      </Col>
                      <Col xs={6}>
                        <Field className="form-control" required name="city" label="City" placeholder="City" type="text" component={Input} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <Field className="form-control" required name="state" label="State" type="select" component={Select} options={states.MY} defaultOptionName="Select a state" />
                      </Col>
                      <Col xs={6}>
                        <Field className="form-control" required name="country" label="Country" type="select" component={Select} options={countries.countries} defaultOptionName="Select a country"/>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            }
          </Row>
          <Row className="mx-2 mt-4">
            <Button
                variant="primary"
                type="submit"
                disabled={submitting}
              >
                {isCreateUser ?
                <>{submitting ? 'SUBMITTING' : 'SUBMIT'}</>
                :
                <>{submitting ? 'UPDATING' : 'UPDATE'}</>
                }

            </Button>
            </Row>
          </form>
          </>
          :
          null
        }
      </div>
    );
  }
}

User = reduxForm({
  form: 'user',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true,
  // asyncValidate,
})(User);

const defaultOptions = [];
const defaultObject = {};
const selector = formValueSelector('user')

export default connect((state, props) => {
  const { match } = props;
  const roleValue = selector(state, 'role');
  return {
    merchantsOptions: (state.merchants && state.merchants.items) ? state.merchants.items.map(merchant => {
      return {
        key: merchant,
        value: merchant,
        name: merchant
      }
    }) : defaultOptions,
    roleValue,
    user: state.user,
    initialValues: (match.params && match.params.userId && state.user && state.user.item) ? state.user.item : defaultObject
  };
}, {
  ...merchantsActionCreators,
  ...userActionCreators
})(User);
