import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class AdjustmentModal extends Component {
  constructor(props) {
    super(props);

    const { adjustmentLineItem } = props
    
    this.state = {
      itemPriceAdjustment: '',
      shippingAdjustment: '',
      adjustmentRemarks: '',
      isLoading: false
    }
  }

  componentDidMount() {
    const { adjustmentLineItem } = this.props;

    if (adjustmentLineItem.adjustment_item_price){
      this.setState({
        itemPriceAdjustment: adjustmentLineItem.adjustment_item_price
      })
    }
    if (adjustmentLineItem.adjustment_shipping){
      this.setState({
        shippingAdjustment: adjustmentLineItem.adjustment_shipping
      })
    }
    if (adjustmentLineItem.adjustment_remarks){
      this.setState({
        adjustmentRemarks: adjustmentLineItem.adjustment_remarks
      })
    }
  }

  handleOnInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleOnSelectChange = (e) => {
    const { value } = e.target;
    this.setState({
      trackingUrl: value
    });
  }

  handleOnClickSave = () => {
    const { onClickSave,  adjustmentLineItem } = this.props;
    const { itemPriceAdjustment, shippingAdjustment, adjustmentRemarks } = this.state;
    this.setState({ isLoading: true });
    onClickSave(adjustmentLineItem.id, itemPriceAdjustment, shippingAdjustment, adjustmentRemarks);
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    const { isLoading } = this.state;
    if (!isLoading) {
      onClose();
    }
  }
  
  render() {
    const { onClose, okLabel, adjustmentLineItem} = this.props;
    const { itemPriceAdjustment, shippingAdjustment, adjustmentRemarks, isLoading } = this.state;
    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose}>
        <Modal.Header  className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0">{adjustmentLineItem.name} Adjustment</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          <form className="d-grid gap-3">
            <div className="d-grid gap-1">
              <label htmlFor="itemPriceAdjustment">Item Price Adjustment</label>
              <input type="number" className="form-control" id="itemPriceAdjustment" name="itemPriceAdjustment" placeholder="Item Price Adjustment" value={itemPriceAdjustment} onChange={this.handleOnInputChange} />
            </div>  
            <div className="d-grid gap-1">
              <label htmlFor="shippingAdjustment">Shipping Adjustment</label>
              <input type="number" className="form-control" id="shippingAdjustment" name="shippingAdjustment" placeholder="Shipping Adjustment" value={shippingAdjustment} onChange={this.handleOnInputChange} />
            </div>  
            <div className="d-grid gap-1">
              <label htmlFor="adjustmentRemarks">Adjustment Remarks</label>
              <input type="text" className="form-control" id="adjustmentRemarks" name="adjustmentRemarks" placeholder="Enter remark" value={adjustmentRemarks} onChange={this.handleOnInputChange}/>
            </div>  
          </form>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={this.handleOnClose} disabled={isLoading}>Cancel</Button>
          <Button variant="primary" onClick={this.handleOnClickSave} disabled={isLoading}>
            {isLoading ? "Loading..." : okLabel || "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AdjustmentModal;