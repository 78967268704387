import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Form, Button, Card } from "react-bootstrap"
import api from '../helpers/apiClient';
import states from '../data/states.json'

const ProcurementShippingAddressModal = ({ onClose }) => {
  const [addresses, setAddresses] = useState([])
  const [addAddress, setAddAddress] = useState(false)

  useEffect(() => {
    fetchAddresses()
  }, [])

  const fetchAddresses = async () => {
    const addresses = await api('/procurement_shipping_addresses')
    setAddresses(addresses)
  }

  const submit = async (e) => {
    e.preventDefault();

    let form = new FormData(e.target);

    form.set('country_code', 'MY')
    const province = JSON.parse(form.get('province'))
    form.set('province', province.name)
    form.set('province_code', province.value)
    form.set('name', `${form.get('first_name')} ${form.get('last_name')}`)

    let address = Object.fromEntries(form);

    await api('/procurement_shipping_addresses', {
      method: 'POST',
      body: JSON.stringify(address),
      headers: {'Content-Type': 'application/json'}
    })

    setAddAddress(false)
    fetchAddresses()
  }

  const setSelectedAddress = (address) => {
    addresses.forEach((a) => {
      a.selected = false
    })
    address.selected = true
    setAddresses([...addresses])
  }

  const onSave = () => {
    const body = addresses.map(a => ({id: a._id, selected: a.selected}))

    return api('/procurement_shipping_addresses', {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {'Content-Type': 'application/json'}
    }).then(() => {
      onClose()
    })
  }

  return (
    <Modal show={true} onHide={onClose} size="xl">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4 className="mb-0">Procurement Shipping Address</h4>
      </Modal.Header>
      <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
        {!addAddress &&
          <Button variant="primary" onClick={() => setAddAddress(true)} className="mb-3">
            Add Address
          </Button>
        }
        {!addAddress && addresses.map((address, index) => (
          <Card key={index} onClick={() => setSelectedAddress(address)} className={`tealCard shadow-sm mb-2 cursor-pointer`} style={address.selected ? {backgroundColor: '#81d8d0', color: 'white'} : {}}>
            <Card.Body className="m-2 d-grid gap-1">
              <div><strong>{address.first_name} {address.last_name}</strong></div>
              <div>{address.address1}</div>
              <div>{address.address2}</div>
              <div>{address.city}</div>
              <div>{address.province} {address.province_code}</div>
              <div>{address.zip}</div>
              <div>{address.phone}</div>
            </Card.Body>
          </Card>
        ))}
        {addAddress &&
        <form onSubmit={submit}>
          <Row>
            <Col md={6}>
              <label>First Name</label>
              <input className="form-control" name="first_name" type="text" />
            </Col>
            <Col md={6}>
              <label>Last Name</label>
              <input className="form-control" name="last_name" type="text" />
            </Col>
          </Row>
          <div>
            <label>Phone</label>
            <input className="form-control" name="phone" type="text" />
          </div>
          <div>
            <label>Address 1</label>
            <input className="form-control" name="address1" type="text" />
          </div>
          <div>
            <label>Address 2</label>
            <input className="form-control" name="address2" type="text" />
          </div>
          <Row>
            <Col md={4}>
              <label>Postcode</label>
              <input className="form-control" name="zip" type="text"/>
            </Col>
            <Col md={4}>
              <label>City</label>
              <input className="form-control" name="city" type="text"/>
            </Col>
            <Col md={4}>
              <label>State/territory</label>
              <Form.Select name="province">
                { states.MY.map(option => (
                  <option key={option.key} value={JSON.stringify(option)}>{option.name}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label>Longitude</label>
              <input className="form-control" name="longitude" type="text" />
            </Col>
            <Col md={6}>
              <label>Latitude</label>
              <input className="form-control" name="latitude" type="text" />
            </Col>
          </Row>
          <div class="d-flex justify-content-end gap-2 mt-4">
            <Button variant="danger-secondary" onClick={() => setAddAddress(false)}>Cancel</Button>
            <Button variant="primary" type="submit">
              Add
            </Button>
          </div>
        </form>
        }
      </Modal.Body>
      { !addAddress && addresses.length > 0 &&
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={onClose}>Cancel</Button>
          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </Modal.Footer>
      }
    </Modal>
  )
}

export default ProcurementShippingAddressModal
