import { 
  FETCH_ORDER_DETAIL_REQUEST, 
  FETCH_ORDER_DETAIL_SUCCESS, 
  FETCH_ORDER_DETAIL_FAILURE, 
  CLEAR_ORDER_DETAIL,
} from '../actions/orderDetail';

const defaultState = {
  loading: false,
  loaded: false,
  orderId: null,
  item: null
};

export default function orders(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ORDER_DETAIL:
      return {
        ...state,
        defaultState
      };
    case FETCH_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        orderId: action.payload.orderId
      };
    case FETCH_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.payload.item,
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}