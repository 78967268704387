import {
  FETCH_QUICKHELPS_REQUEST,
  FETCH_QUICKHELPS_SUCCESS,
  FETCH_QUICKHELPS_FAILURE,
} from '../actions/quickHelp';

const defaultState = {
  loading: false,
  loaded: false,
  item: null
};

export default function quick_helps(state = defaultState, action) {
  switch (action.type){
    case FETCH_QUICKHELPS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_QUICKHELPS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.payload.item,
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_QUICKHELPS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
