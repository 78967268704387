import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
import en from 'react-phone-number-input/locale/en';
import { parseNumber } from 'libphonenumber-js';
import { Modal, Button, Row, Col, Form, Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import messageTemplateOptions from '../data/whatsapp_sender_template.json';
import api from "../helpers/apiClient";

const WhatsappSenderModal = ({ senderNo, billingNo, onClose, orderName, orderId }) => {
  const [options, setOptions] = useState([]);
  const [country, setCountry] = useState('MY');
  const [no, setNo] = useState('');
  const [final, setFinal] = useState('');
  const [invalid, setInvalid] = useState(false);
  const [message, setMessage] = useState('');
  const [review, setReview] = useState(false);

  useEffect(() => {
    let options = []
    if (senderNo && senderNo.value) {
      options.push({
        source: "Sender's phone number",
        value: senderNo.value.replace(/\s|-/g, '')
      });
    }
    if (billingNo) {
      options.push({
        source: "Billing phone number",
        value: billingNo.replace(/\s|-/g, ''),
      });
    }

    options = _.uniqBy(options, "value");

    let parsed = parseNumber(options[0].value)

    if (parsed.country) {
      setCountry(parsed.country);
    }

    setOptions(options);
    setNo(options[0].value);
    buildFinalNo(country, options[0].value)
  }, [])

  const buildFinalNo = (country, no) => {
    let parsed = parseNumber(no, country);

    setInvalid(!parsed.phone);

    if (!parsed.phone) {
      return
    }

    setFinal(getCountryCallingCode(parsed.country) + parsed.phone)
  }

  const onChangeCountry = (e) => {
    let country = e.target.value;

    setCountry(country)
    buildFinalNo(country, no)
  }

  const onChangeNo = (e) => {
    let no = e.target.value;

    setNo(no)
    buildFinalNo(country, no)
  }

  const handleChat = () => {
    let url = `https://wa.me/${final}?text=${encodeURIComponent(message)}`
    window.open(url);

    api(`/log/whatsapp/sender`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ url, order_name: orderName, order_id: orderId })
    })
  }

  const handleOnChangeTemplate = (e) => {
    if (e.target.value) {
      let message = e.target.value;
      message = message.replace(/#GTRXXXXX/, orderName);
      setMessage(message);
    }
  }

  return (
    <Modal style={{ border: 'none' }} show={true} onHide={onClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
        <h4>WhatsApp Sender</h4>
      </Modal.Header>
      <Modal.Body className="mx-4 px-0 py-4" style={{ borderTop: '1px solid #dee2e6' }}>
        { !review &&
        <div className="d-grid gap-3">
          <Row>
            <Col sm={6} className="d-grid gap-1">
              <label>Country Code</label>
              <Form.Select className="form-select-sm d-inline" onChange={onChangeCountry} value={country}>
                {getCountries().map((country) => (
                  <option key={country} value={country}>
                    {en[country]} +{getCountryCallingCode(country)}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col sm={6} className="d-grid gap-1">
              <label>Phone Number</label>
              <Form.Select className="form-select-sm d-inline" onChange={onChangeNo} value={no}>
                { options.map((option, i) => (
                  <option key={i} value={option.value}>{option.value} - {option.source}</option>
                ))}
              </Form.Select>
            </Col>
            { invalid &&
            <span className="small text-danger">Country code is incompatible with phone number</span>
            }
          </Row>
          <div className="d-grid gap-1">
            <label>Edit Manually</label>
            <input className="form-control" type="text" onChange={(e) => setFinal(e.target.value)} value={final} />
          </div>
          <div className="d-grid gap-1">
            <label>Custom Message</label>
            <label className="small">Template</label>
            <Form.Select className="form-select d-inline" onChange={handleOnChangeTemplate}>
              <option value="">Select a template</option>
              { messageTemplateOptions.map(option => (
                <option key={option.key} value={option.value}>{option.name}</option>
              ))}
            </Form.Select>
            <label className="small">Message</label>
            <textarea name="message" className="form-control" style={{ height: '200px', resize: 'vertical' }} value={message} onChange={(e) => setMessage(e.target.value)} />
          </div>
          <div className="mt-2">
            <span className="white-space-pre-line" dangerouslySetInnerHTML={{__html: "Reminder:\n1) Sender may update recipient's phone number in billing info, please ensure you check the phone number so that you are reaching out to the sender.\n2) For collection of additional payment or refund, please contact your Partner Manager and Giftr Operations Team to liaise with the customer to ensure a seamless and secured gifting experience.\n3) Only use this contact channel to enquire for more info related to order purchased. Strictly no marketing or irrelevant content."}}></span>
          </div>
        </div>
        }
        { message && review &&
          <>
            <p>
              <span className="fw-bold">Phone Number: </span>
              {final}
            </p>
            <div className="p-3">
              <div className="whatsapp-preview-box">
                <svg className="whatsapp-preview-tail" viewBox="0 0 8 13" height="13" width="8" x="0px" y="0px"><path opacity="0.13" d="M5.188,1H0v11.193l6.467-8.625 C7.526,2.156,6.958,1,5.188,1z"></path><path fill="currentColor" d="M5.188,0H0v11.193l6.467-8.625C7.526,1.156,6.958,0,5.188,0z"></path></svg>
                {message}
              </div>
            </div>
          </>
        }
      </Modal.Body>
      <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
        { !review &&
        <Button variant="primary" onClick={() => setReview(true)} disabled={message === ''}>
          Review message
        </Button>
        }
        { review &&
        <>
          <Button variant="secondary" onClick={() => setReview(false)}>
            Back
          </Button>
          <Button variant="primary" onClick={handleChat}>
            Chat
          </Button>
        </>
        }
      </Modal.Footer>
    </Modal>
  )
}

export default WhatsappSenderModal;
