import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Row, Card, Col, Button, Alert, Form, Accordion, ListGroup } from 'react-bootstrap';
import OverlayLoader from '../components/OverlayLoader';
import _ from 'lodash'
import * as errorActionCreators from '../redux/actions/error';
import api from '../helpers/apiClient';
import * as merchantsActionCreators from '../redux/actions/merchants';
import * as previewProductActionCreators from '../redux/actions/previewProduct';
import ImagesModal from  '../components/ImagesModal';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import ConfirmModal from '../components/ConfirmModal';
import * as taggingsActionCreators from '../redux/actions/taggings';
import { generateTagsString, generateAttr, convertAttr, getDeliveryTags } from '../helpers/productTags';
import TabHeader from '../components/TabHeader';
import LinkProductModal from '../components/LinkProductModal';
import toast from 'react-hot-toast';
import Promise from 'bluebird';
import { Link } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import Dropzone from 'react-dropzone';
import uploadIcon from '../images/upload-icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ReactTooltip from 'react-tooltip';
import YTLinkModal from '../components/YTLinkModal';
import media_placeholder from '../images/media-placeholder.svg';
import FieldCreate from '../components/FieldCreate';
import FieldsModal from '../components/FieldsModal';

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'
const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'
const shopifyDomain = window.location.hostname === 'seller.giftr.my' ? 'helpr-asia.myshopify.com' : 'giftr-singapore.myshopify.com'

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const defaultState = {
  loading: true,
  displayProductCreateFail: false,
  displayProductCreateSuccess: false,
  showConfirmSellInBulkModal: false,
  showImagesModal: false,
  showDeleteModal: false,
  showLinkProductModal: false,
  assignImageVariant: 0,
  uploadingFile: false,
  has_variants: false,
  submitting: false,
  not_found: false,
  item: {
    title: '',
    body_html: '',
    vendor: '',
    product_type: '',
    alt_product_type: [],
    status: 'active',
    tags: 'is_addon, SEARCHANISE_IGNORE',
    variants: [
      {
        option1: "Default Title",
        option2: '',
        option3: '',
        price: '0',
        compare_at_price: '',
        sku: '',
        inventory_management: null,
        inventory_quantity: 0,
        selected_image: {},
        requires_shipping: false,
        weight: '',
        weight_unit: 'kg',
        base_price: '0',
        markup: '0',
        metafields: [{
          namespace: 'price',
          key: 'markup_type',
          value: 'fixed',
          type: 'single_line_text_field',
        }, {
          namespace: 'price',
          key: 'base_price',
          value: '0',
          type: 'number_decimal',
        }, {
          namespace: 'price',
          key: 'markup',
          value: '0',
          type: 'number_decimal',
        }],
      }
    ],
    options: [
      {
        name: "Title",
        values: ["Default Title"]
      }
    ],
    images: [],
    approval_status: '',
    halal_cert: '',
    "pricing_tier": [
      {
        "min_quantity": undefined,
        "max_quantity": undefined,
        "price": undefined
      },
    ],
  },
  remarks: '',
  showReviewModal: false,
  sold_out: [false],
  update_variant_image: [],
  attributes: {},
  reorder_media: false,
  delete_media: [],
  productTemplateKey: 0,
  merchantsOptions: [],
  editorState: EditorState.createEmpty(),
  showShippingInfo: false,
  showAddYTLink: false,
  initialMetafieldAddOns: [],
  initialMetafieldInputs: [],
}

class ProductAddOn extends Component {
  constructor(props) {
    super(props);
    this.toastIdRef = createRef();
    this.updateBtnRef = createRef();

    this.state = defaultState;
  }

  async componentDidMount() {
    const { match, fetchTaggings } = this.props;
    let { item } = this.state;

    if(_.includes(match.path, 'create')){
      this.setState({ item: {
        ...item,
        approval_status: 'create'
      }})
    } else {
      item = await this.fetchProduct();
    }

    const { contentBlocks, entityMap } = convertFromHTML(item.body_html ? item.body_html.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '') : '')
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)

    this.setState({
      editorState: EditorState.createWithContent(contentState),
    })

    await this.fetchMerchants();
    await fetchTaggings()
    await this.setAttributes();
    this.setState({ loading: false });
  }

  componentWillUnmount() {
    toast.dismiss();
  }

  // reloadProduct = async () => {
  //   this.setState({ loading: true });
  //   await this.fetchProduct();
  //   this.setState({ loading: false });
  // }

  resetState = async () => {
    const { fetchTaggings } = this.props;

    this.setState(defaultState)

    await this.fetchMerchants();
    await fetchTaggings()
    await this.setAttributes();
    this.setState({ loading: false });
  }

  showUpdatePrompt = () => {
    const { match } = this.props;

    if (_.includes(match.path, 'create')) {
      return
    }

    this.toastIdRef.current = toast(
      <div>
        <span>Update Product?</span>
        <Button className="ms-2 btn-br-6" variant="info" onClick={() => {
          if (this.updateBtnRef.current) {
            this.updateBtnRef.current.click();
          }
        }}>
          UPDATE
        </Button>
      </div>
      , { duration: Infinity });
  }

  setItemState = (item, showPrompt = true, callback) => {
    const approvedPendingProduct = item.approval_status == 'approved' && !isNumeric(item.id)

    if (!this.toastIdRef.current && showPrompt && !approvedPendingProduct) {
      this.showUpdatePrompt();
    }

    this.setState({ item }, callback)
  }

  fetchMerchants = async () => {
    const { user, match } = this.props;
    const isAdmin = user.role === 'admin' || user.role === 'staff'

    return api(`/merchants/product/create`, {
      method: 'GET'
    }, {'Accept': 'application/json'}).then(result => {
      if(result.length > 0){
        let newState = {
          merchants: result,
          merchantsOptions: result ? result.map(({ name }) => {
            return {
              key: name,
              value: name,
              name: name,
            }
          }) : [],
        }

        let { name, markup, markup_type } = result[0]
        markup = parseFloat(markup) || 0;
        if(markup < 0) markup = 0;

        if(_.includes(match.path, 'create') && !isAdmin){

          newState.item = this.state.item
          newState.item.vendor = name
          let tagsSplit = this.state.item.tags.split(', ')
          if (markup && markup > 0 && markup_type) {
            tagsSplit.push('remove_pmg')
          }
          newState.item.tags = tagsSplit.join(', ')
        }

        if (!isAdmin && markup && markup > 0 && markup_type) {
          newState.defaultMarkup = markup
          newState.defaultMarkupType = markup_type

          let { variants } = this.state.item;

          let mu_index = _.findIndex(variants[0].metafields, m => m.key === 'markup')
          let mt_index = _.findIndex(variants[0].metafields, m => m.key === 'markup_type')

          let markupTypeMeta = { ...variants[0].metafields[mt_index] }

          let markupMeta = { ...variants[0].metafields[mu_index] }

          markupTypeMeta.value = markup_type

          newState.item.variants[0].metafields[mt_index] = markupTypeMeta
          newState.item.variants[0].markup_type = markup_type

          markupMeta.value = markup;

          newState.item.variants[0].markup = markup
          newState.item.variants[0].metafields[mu_index] = markupMeta
        }

        this.setState(newState)
      }
    })
  }

  handleOnPreviewProduct = () => {
    const { setPreviewProduct } = this.props;

    let { body } = this.getItemBody();

    setPreviewProduct(body)

    window.open('/products/preview', 'child-window')
  };

  setAttributes = async () => {
    const { item } = this.state;
    const { match, taggings: { items: predefinedTags } } = this.props;

    const { attributes } = await generateAttr(item, dayOptions, closed, predefinedTags, match)

    // function createOptions(taggings) {
    //   return _.map(taggings, tag => {
    //     return {
    //       label: tag.desc,
    //       value: tag.tag
    //     }
    //   })
    // }

    this.setState({ attributes: {
      ...attributes,
      // selectOptions: {
      //   dayOptions,
      //   locationOptions: createOptions(predefinedTags['Location']),
      //   occasionOptions: createOptions(predefinedTags['Occasions']),
      //   forWhoOptions: createOptions(predefinedTags['For Who']),
      //   flowerTypeOptions: createOptions(predefinedTags['Flower Type']),
      // }
    }})
  }

  fetchProduct = async () => {
    if (this.toastIdRef.current) {
      toast.dismiss(this.toastIdRef.current);
      this.toastIdRef.current = undefined;
    }
    const { match } = this.props;
    const { item } = this.state;

    const result = await api(`/products/${match.params.productId}`)

    if(result.errors){
      return this.setState({
        loading: false,
        displayProductCreateFail: true,
        displayProductCreateSuccess: false,
        errorMessage: result.message,
        not_found: true
      })
    }

    if (!result.id) {
      return this.setState({
        loading: false,
        not_found: true
      })
    }

    let sold_out = _.map(result.variants, variant => {
      return (variant.inventory_management === 'shopify' && variant.inventory_quantity <= 0) ? true : false
    })

    let variants = _.map(result.variants, variant => {
      const { price } = variant

      const markup = _.find(variant.metafields, m => m.key === 'markup')
      const base_price = _.find(variant.metafields, m => m.key === 'base_price')

      let result = { ...variant }
      if(!result.metafields){
        result.metafields = []
      }

      if(markup){
        result.markup = markup.value
      } else {
        result.markup = '0'
        result.metafields.push({
          namespace: 'price',
          key: 'markup',
          value: '0',
          type: 'number_decimal',
        })
      }

      if(base_price){
        result.base_price = base_price.value
      } else {
        result.base_price = parseFloat(price) - parseFloat(result.markup)
        result.metafields.push({
          namespace: 'price',
          key: 'base_price',
          value: parseFloat(price) - parseFloat(result.markup),
          type: 'number_decimal',
        })
      }

      return result
    })

    if (variants.length < 1) {
      variants = [
        {
          option1: '',
          option2: '',
          option3: '',
          price: '0',
          compare_at_price: '',
          sku: '',
          inventory_management: null,
          inventory_quantity: 0,
          selected_image: {},
          requires_shipping: false,
          weight: '',
          weight_unit: 'kg',
          base_price: '0',
          markup: '0',
          metafields: [{
            namespace: 'price',
            key: 'markup_type',
            value: 'fixed',
            type: 'single_line_text_field',
          }, {
            namespace: 'price',
            key: 'base_price',
            value: '0',
            type: 'number_decimal',
          }, {
            namespace: 'price',
            key: 'markup',
            value: '0',
            type: 'number_decimal',
          }],
        }
      ]
      sold_out = [...sold_out, false]
    }

    let state = {
      item: {
        ...item,
        ...result,
        variants,
      },
      sold_out,
      has_variants: result.variants.length > 1,
    }

    if (result && _.includes(match.path, 'pending')){
      state.pending = result.approval_status == 'pending'
    }

    if (result && result.tags) {
      state.item.enable_vm = result.tags.includes('enable_vm');
      state.item.enable_qna = result.tags.includes('enable_qna');
      state.item.remove_pmg = result.tags.includes('remove_pmg');
      state.item.enable_postcode = result.tags.includes('enable_postcode');

      let tagsSplit = result.tags.split(', ');
      const typeRegex = new RegExp(/^type_/, 'i')
      state.item.alt_product_type = tagsSplit.reduce((acc, tag) => {
        if (typeRegex.test(tag)) {
          let type = tag.match(/^type_(.*)/i);

          if (type && type[1] !== result.product_type){
            acc.push({
              label: type[1],
              value: type[1]
            })
          }
        }
        return acc
      }, [])
    }

    this.setState(state)

    return state.item
  }

  onDropImage = async files => {
    if (!files || files.length === 0) {
      return toast.error('Invalid filetype/no file selected');
    }

    const { item } = this.state;
    const { match } = this.props;
    let { images, media } = item;

    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create'))

    const upload = async (files) => {
      this.setState({
        uploadingFile: true
      })

      if (isLiveProduct) {
        var formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append('media', files[i])
        }

        const result = await api(`/products/add_media/${item.id}`, {
          method: 'POST',
          body: formData
        }, {'Accept': 'application/json'})

        let newMedia = result.data.productCreateMedia.media

        let newMediaState = []
        _.each(newMedia, (mediaItem) => {
          newMediaState.push({ id: mediaItem.id })
        })

        this.setItemState({
          ...item,
          media: [
            ...media,
            ...newMediaState
          ]
        }, false)
      } else {
        let newImages = []
        for (let i = 0; i < files.length; i++) {
          var formData = new FormData();
          formData.append('product_image', files[i])

          const data = await api(`/products/images`, {
            method: 'POST',
            body: formData
          }, {'Accept': 'application/json'})

          newImages.push({ src: data.link })
        }

        this.setItemState({
          ...item,
          images: [
            ...images,
            ...newImages
          ]
        })
      }

      this.setState({ uploadingFile: false })
    }

    return Promise.map(files, (file) => {
      if (file.type.includes('image')) {

        if (files[0].size > 20971520) {
          return Promise.reject('Image file size too big');
        }

        return new Promise((resolve, reject) => {
          let imageObjectUrl = URL.createObjectURL(file);
          let image = new Image();
          image.onload = function() {

            if (this.width > 4472 || this.height > 4472) {
              URL.revokeObjectURL(imageObjectUrl);
              return reject('Image height/width too big');
            }
            URL.revokeObjectURL(imageObjectUrl);

            resolve();
          }

          image.src = imageObjectUrl;
        })
      }

      if (file.type.includes('video')) {
        if (files[0].size > 52428800) {
          return Promise.reject('Video file size too big');
        }

        let videoObjectUrl = URL.createObjectURL(file);
        const video = document.createElement('video')
        video.addEventListener('loadedmetadata', () => {

          if (video.videoWidth > 4096 || video.videoHeight > 2160) {
            video.remove();
            return reject('Video resolution too big');
          }
          URL.revokeObjectURL(videoObjectUrl);

          video.remove();
          resolve();
        });

        video.src = videoObjectUrl
      }
    }, { concurrency: 1 }).then(() => {
      return upload(files);
    }).catch((err) => {
      toast.error(err.message || err)
    });

  }

  onClickHasVariants = e => {
    this.setState({ has_variants: e.target.checked })
  }

  onClickB2B = e => {
    const { item } = this.state

    this.setState({
      item: {
        ...item,
        b2b: e.target.checked
      }
    })
  }

  addTier = () => {
    const { item } = this.state;
    const { pricing_tier } = item
    const prev_tier_max = pricing_tier[pricing_tier.length - 1].max_quantity

    this.setState({
      item: {
        ...item,
        pricing_tier: [
          ...pricing_tier,
          {
            "min_quantity": prev_tier_max ? parseInt(prev_tier_max)+1 : undefined,
            "max_quantity": undefined,
            "price": undefined
          },
        ],
      },
    })
  }

  removeTier = e => {
    const { id } = e.target;
    const { item } = this.state;
    const { pricing_tier } = item;
    const index = parseInt(id.slice(-1))

    const prev_tier_max = pricing_tier[index-1].max_quantity
    const tierFiltered = _.filter(pricing_tier, (v, i) => i != index)

    this.setState({
      item: {
        ...item,
        pricing_tier: [
          ...tierFiltered.slice(0, index),
          {
            ...tierFiltered[index],
            min_quantity: prev_tier_max ? parseInt(prev_tier_max)+1 : undefined,
          },
          ...tierFiltered.slice(index + 1)
        ],
      }
    })

  }

  removeVariant = e => {
    const { id } = e.currentTarget;
    const { item } = this.state;
    const { variants, options } = item;
    const optionsEdit = options;
    const removeIndex = id.replace('remove_', '')

    const variantsFiltered = _.filter(variants, (v, i) => i != removeIndex)

    for (let options of optionsEdit){
      options.values = _.filter(options.values, (o, i) => i != removeIndex)
    }

    this.setItemState({
      ...item,
      variants: [
        ...variantsFiltered
      ],
      options: [
        ...optionsEdit
      ]
    })
  }

  updateForm = e => {
    const { item, merchantsOptions, merchants } = this.state;
    let { name, value, checked } = e.target;

    if (_.includes(name, 'options')){
      const { options } = item
      const index = parseInt(name.slice(-1))

      this.setItemState({
        ...item,
        options: [
          ...options.slice(0, index),
          {
            ...options[index],
            name: value
          },
          ...options.slice(index + 1)
        ]
      })
      return
    }

    if(name === 'enable_qna' || name === 'enable_vm' || name === 'remove_pmg' || name === 'enable_postcode'){
      value = checked
    }

    let saveItem = {
      ...item,
      [name]: value
    }

    if (name === 'status' && value === 'active') {
      saveItem.published = true
    }

    // non attributes related tags
    let tagsSplit = item.tags.split(', ')
    if(tagsSplit.length === 1 && tagsSplit[0] === ''){
      tagsSplit = []
    }

    if(name === 'vendor'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^vendor_|^$/i.test(tag)))
      tagsSplit.push(`Vendor_${value}`)

      let merchant = merchants.find(merchant => merchant.name === value)
      let { markup, markup_type } = merchant

      tagsSplit = _.filter(tagsSplit, tag => !(/^enable_vm$|^enable_qna$|^remove_pmg$|^$/i.test(tag)))
      if (markup && markup > 0 && markup_type) {
        saveItem.remove_pmg = true
        tagsSplit.push('remove_pmg')

        let { variants } = item;

        _.each(variants, (variant, i) => {
          const { base_price, metafields } = variant;

          let mu_index = _.findIndex(metafields, m => m.key === 'markup')
          let mt_index = _.findIndex(metafields, m => m.key === 'markup_type')

          let markupTypeMeta = { ...metafields[mt_index] }

          let markupMeta = { ...metafields[mu_index] }

          markupTypeMeta.value = markup_type

          saveItem.variants[i].metafields[mt_index] = markupTypeMeta
          saveItem.variants[i].markup_type = markup_type

          markup = parseFloat(markup) || 0;
          if(markup < 0) markup = 0;

          markupMeta.value = markup;

          saveItem.variants[i].markup = markup
          saveItem.variants[i].metafields[mu_index] = markupMeta

          if (markupTypeMeta.value === 'percentage') {
            saveItem.variants[i].price = parseFloat(base_price * markupMeta.value / 100) + parseFloat(base_price)
          } else if (markupTypeMeta.value === 'fixed') {
            saveItem.variants[i].price = parseFloat(markupMeta.value) + parseFloat(base_price)
          }
        })
      } else {
        saveItem.remove_pmg = false
      }
    }

    if(name === 'product_type' || name === 'alt_product_type'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^type_|^$/i.test(tag)))
      let altTypes = _.map(saveItem.alt_product_type, type => type.value)
      let allTypes = [saveItem.product_type, ...altTypes]
      _.each(allTypes, type => {
        tagsSplit.push(`Type_${type}`)
      })
    }
    if(name === 'enable_vm'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^enable_vm$|^$/i.test(tag)))
      if(value){
        tagsSplit.push('enable_vm')
      }
    }
    if(name === 'enable_qna'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^enable_qna$|^$/i.test(tag)))
      if(value){
        tagsSplit.push('enable_qna')
      }
    }
    if(name === 'remove_pmg'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^remove_pmg$|^$/i.test(tag)))
      if(value){
        tagsSplit.push('remove_pmg')
      }
    }
    if(name === 'enable_postcode'){
      tagsSplit = _.filter(tagsSplit, tag => !(/^enable_postcode$|^$/i.test(tag)))
      if(value){
        tagsSplit.push('enable_postcode')
      }
    }

    if (name === 'delivery_link') {
      tagsSplit = _.filter(tagsSplit, tag => !(/^DLINK_|^$/i.test(tag)))
      if(value){
        tagsSplit.push(value)
      }
    }

    saveItem.tags = tagsSplit.join(', ')

    this.setItemState(saveItem)
  }

  updateVariants = e => {
    const { item, sold_out, attributes: { delivery_type } } = this.state;
    let { name, value, checked } = e.target;

    const nameSplit = _.split(name, '#')
    const { variants, options } = item;
    const variantIndex = parseInt(nameSplit[1])

    switch (true) {
      case nameSplit[0] === 'soldout':
        this.setState({
          sold_out: [
            ...sold_out.slice(0, variantIndex),
            checked,
            ...sold_out.slice(variantIndex + 1)
          ],
        })
        this.setItemState({
          ...item,
          variants: [
            ...variants.slice(0, variantIndex),
            {
              ...variants[variantIndex],
              inventory_quantity: 0,
              inventory_management: 'shopify'
            },
            ...variants.slice(variantIndex + 1)
          ],
        });
        return

      case _.includes(name, 'tier'):
        const { pricing_tier } = item
        const index = parseInt(name.slice(-1))
        name = nameSplit[0].replace('tier_', '')
        value = parseInt(value)

        if(_.includes(name, 'max_quantity')){
          this.setItemState({
            ...item,
            pricing_tier: [
              ...pricing_tier.slice(0, index),
              {
                ...pricing_tier[index],
                max_quantity: value
              },
              ...(Boolean(pricing_tier[index+1]) && [
                {
                  ...pricing_tier[index+1],
                  min_quantity: value + 1
                },
                ...pricing_tier.slice(index + 2)
              ]),
            ]
          })
        } else {
          this.setItemState({
            ...item,
            pricing_tier: [
              ...pricing_tier.slice(0, index),
              {
                ...pricing_tier[index],
                [name]: value < 0 ? 0 : value
              },
              ...pricing_tier.slice(index + 1)
            ]
          });
        }
        return;

      case nameSplit[0] === 'inventory_management':
        value = checked === true ? 'shopify' : null
        break;

      case nameSplit[0] === 'inventory_policy':
        value = checked === true ? 'continue' : 'deny'
        break;

      case nameSplit[0] === 'inventory_quantity':
        value = Math.max(0, value)
        break;
    }

    function updateOptions(){
      const optionIndex = parseInt(nameSplit[0].slice(-1)) - 1;
      const { values } = options[optionIndex];

      return { options: [
        ...options.slice(0, optionIndex),
        {
          ...options[optionIndex],
          values: [
            ...values.slice(0, variantIndex),
            value,
            ...values.slice(variantIndex + 1)
          ]
        },
        ...options.slice(optionIndex + 1)
      ]}
    }

    this.setItemState({
      ...item,
      variants: [
        ...variants.slice(0, variantIndex),
        {
          ...variants[variantIndex],
          [nameSplit[0]]: value
        },
        ...variants.slice(variantIndex + 1)
      ],
      ...(_.includes(name, 'option') && updateOptions())
    });
  }

  handleBasePrice = e => {
    let { name, value } = e.target;

    value = parseFloat(value) || 0;
    if(value < 0) value = 0;

    const nameSplit = _.split(name, '#')
    const { variants } = this.state.item;
    const index = parseInt(nameSplit[1])

    let bp_index = _.findIndex(variants[index].metafields, m => m.key === 'base_price')

    let base_price = {
      ...variants[index].metafields[bp_index],
      value
    }

    let item = { ...this.state.item };

    item.variants[index][nameSplit[0]] = value
    item.variants[index].metafields[bp_index] = base_price

    this.setItemState(item, true, () => this.calculateTotalPrice(index));
  }

  handleMarkup = e => {
    const { user } = this.props;
    if(user.role !== 'admin' && user.role !== 'staff'){
      return
    }

    let { name, value } = e.target;
    const nameSplit = _.split(name, '#')
    const { variants } = this.state.item;
    const index = parseInt(nameSplit[1])

    let mu_index = _.findIndex(variants[index].metafields, m => m.key === 'markup')
    let mt_index = _.findIndex(variants[index].metafields, m => m.key === 'markup_type')

    let markup_type = { ...variants[index].metafields[mt_index] }

    let markup = { ...variants[index].metafields[mu_index] }

    let item = { ...this.state.item };

    if (nameSplit[0] === 'markup_type') {
      markup_type.value = value

      item.variants[index].metafields[mt_index] = markup_type
      item.variants[index].markup_type = value
    } else if (nameSplit[0] === 'markup') {
      value = parseFloat(value) || 0;
      if(value < 0) value = 0;

      markup.value = value;

      item.variants[index].markup = value
      item.variants[index].metafields[mu_index] = markup
    }

    let tagsSplit = item.tags.split(', ')
    tagsSplit = _.filter(tagsSplit, tag => !(/^remove_pmg$|^$/i.test(tag)))

    let hasMarkup = _.some(item.variants, variant => {
      let markupMeta = _.find(variant.metafields, m => m.key === 'markup')
      let markupTypeMeta = _.find(variant.metafields, m => m.key === 'markup_type')

      return markupMeta && markupMeta.value && markupMeta.value > 0 && markupTypeMeta && markupTypeMeta.value
    })

    if (hasMarkup) {
      item.remove_pmg = true
      tagsSplit.push('remove_pmg')
    } else {
      item.remove_pmg = false
    }

    item.tags = tagsSplit.join(', ')

    this.setItemState(item, true, () => this.calculateTotalPrice(index));
  }

  calculateTotalPrice = (index) => {
    let item = { ...this.state.item };
    const { base_price, metafields } = item.variants[index];

    let markup = _.find(metafields, m => m.key === 'markup')
    let markup_type = _.find(metafields, m => m.key === 'markup_type')

    if (markup_type.value === 'percentage') {
      let price = parseFloat(base_price * markup.value / 100) + parseFloat(base_price)
      item.variants[index].price = _.ceil(price, 1).toFixed(2)
    } else if (markup_type.value === 'fixed') {
      item.variants[index].price = (parseFloat(markup.value) + parseFloat(base_price)).toFixed(2)
    }

    this.setItemState(item);
  }

  fixTierQty = (isMax) => {
    const { item, item: { pricing_tier } } = this.state;
    let fixed_tiers = [...pricing_tier]

    _.each(fixed_tiers, (tier, i) => {
      if(isMax){
        if (tier.max_quantity < tier.min_quantity){
          fixed_tiers[i].max_quantity = pricing_tier[i].min_quantity
          if(fixed_tiers[i+1]){
            fixed_tiers[i+1].min_quantity = pricing_tier[i].min_quantity + 1
          }
        }
      } else if (tier.min_quantity > tier.max_quantity){
        fixed_tiers[i].min_quantity = pricing_tier[i].max_quantity
      }
    })

    this.setItemState({
      ...item,
      pricing_tier: [
        ...fixed_tiers
      ]
    })
  }

  stripHtml = html => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }


  getItemBody = () => {
    const { item, has_variants, attributes: { delivery_type } } = this.state;

    if(!this.stripHtml(item.body_html)){
      this.setState({
        submitting: false,
      })
      window.scrollTo(0, 400);
      toast.error('Please add a description')
      return
    }

    let inventoryChanged = []
    // variants that require update inventory
    if(item.status == 'active' || item.status == 'draft'){
      for (let variant of item.variants){

        if(variant.id && variant.inventory_quantity !== variant.old_inventory_quantity){
          inventoryChanged.push({...variant})
          variant.inventory_quantity = variant.old_inventory_quantity
        }
      }
    }

    let body = {
      ...item,
    }

    _.each(item.variants, (_, i) => {
      if (country === 'MY') {
        body.variants[i].weight = Math.max(0.001, body.variants[i].weight)
      }

      if (country === 'SG') {
        body.variants[i].weight = '0'
      }

      body.variants[i].requires_shipping = false
    })

    // filter out unwanted variants and options
    if(!has_variants){
      const { variants, options } = body;

      body = {
        ...body,
        variants: [{
          ...variants[0],
          option1: "Default Title",
        }]
      }

      if(options){
        body = {
          ...body,
          options: [{
            name: "Title",
            values: ["Default Title"],
          }]
        }
      }
    }

    // recalculate selling price
    _.each(item.variants, (_, i) => {
      const { base_price, metafields } = body.variants[i]

      let markup = metafields.find(m => m.key === 'markup')
      let markup_type = metafields.find(m => m.key === 'markup_type')

      if (markup_type.value === 'percentage') {
        body.variants[i].price = parseFloat(base_price * markup.value / 100) + parseFloat(base_price)
      } else if (markup_type.value === 'fixed') {
        body.variants[i].price = parseFloat(markup.value) + parseFloat(base_price)
      }
    })

    let tags = _.uniq(_.map(_.split(body.tags, ','), _.trim));
    body.tags = tags

    body.template_suffix = 'addon'

    if(!tags.includes('logo_halal')){
      body.halal_cert = ''
    }

    body = getDeliveryTags(body)

    return { body, inventoryChanged }
  }

  submit = async e => {
    if (this.toastIdRef.current) {
      toast.dismiss(this.toastIdRef.current);
      this.toastIdRef.current = undefined;
    }
    e.preventDefault();
    const { item, update_variant_image, reorder_media, delete_media } = this.state;
    const { match } = this.props;

    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create'))

    const itemBody = this.getItemBody();
    if(!itemBody){
      return
    }
    let { body, inventoryChanged } = itemBody

    this.setState({
      submitting: true,
      loading: true,
    })

    // api for create or update product
    const edit = item.approval_status !== 'create'
    const toastLoading = toast.loading(edit ? 'Updating...' : 'Creating...');
    let result
    if(edit){
      if (isLiveProduct) {
        delete body.images;
      }

      // update product
      result = await api(`/products/${item.id}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }).catch(err => {
        this.setState({
          submitting: false,
          loading: false,
        })
        toast.error(err.message)
      })

      // update media order
      if (reorder_media) {
        const media_order = _.map(item.media, "id")
        await api(`/products/reorder_images/${item.id}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json' },
          body: JSON.stringify({ media_order }),
        })
      }

      if (delete_media.length > 0) {
        await api(`/products/delete_media/${item.id}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json' },
          body: JSON.stringify({ delete_media }),
        })
      }

      // update variant image
      if(update_variant_image.length > 0){
        for (let v of update_variant_image){
          await api('/products/variant_image', {
            method: 'POST',
            headers: {'Content-Type': 'application/json' },
            body: JSON.stringify(v),
          })
        }
      }

      // update variant inventory
      for (let j = 0; j < inventoryChanged.length; j++){
        const { inventory_item_id, inventory_quantity } = inventoryChanged[j]

        await new Promise(r => setTimeout(r, 2000));
        await api(`/products/inventory`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            inventory_item_id,
            available: inventory_quantity,
            product: result
          })
        })
      }
    } else {
      // create product
      result = await api('/products/addon', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      })
    }

    toast.dismiss(toastLoading);

    if(!result){
      return
    }

    if(result.errors){
      this.setState({
        submitting: false,
        loading: false,
      })
      toast.error(edit ? 'Failed to update product.' : 'Failed to create product.')
    } else {
      this.setState({
        submitting: false,
      })
      if (edit) {
        toast.success('Product updated.');
      } else {
        toast.success(<span>Product created. View it <a href={"/product_addons/" + result.product.id}>here</a></span>, { duration: 60000 });
        this.resetState();
      }
      this.setState({ loading: false });
    }

  }

  handleOnClickShowImagesModal = i => {
    this.setState({
      assignImageVariant: i
    }, () => {
      this.setState({ showImagesModal: true })
    })
  }

  handleOnCloseImagesModal = () => {
    this.setState({ showImagesModal: false });
  }

  handleOnSaveVariantImage = selected_image => {
    const { item, assignImageVariant: vIndex, update_variant_image } = this.state;
    const { variants } = item;

    if(item.approval_status === ''){
      this.setState({
        update_variant_image: [
          ...update_variant_image,
          {
            variant_id: variants[vIndex].id,
            image_id: selected_image.id,
          }
        ]
      })
    }

    this.setItemState({
      ...item,
      variants: [
        ...variants.slice(0, vIndex),
        {
          ...variants[vIndex],
          selected_image
        },
        ...(variants.slice(vIndex + 1))
      ]
    })

    this.setState({ showImagesModal: false });
  }

  handleOnClickAddOption = () => {
    const { item } = this.state;
    const { options } = item

    this.setItemState({
      ...item,
      options: [
        ...options,
        {
          name: '',
          values: ['']
        }
      ]
    });
  }

  handleOnClickRemoveOption = index => {
    const { item } = this.state;
    const { options, variants } = item;

    const variantsEdited = _.map(variants, variant => {
      variant[`option${index+1}`] = ''

      if(variant.option1 === ''){
        variant = {
          ...variant,
          option1: variant.option2,
          option2: variant.option3,
          option3: ''
        }
      }

      if(variant.option2 === ''){
        variant = {
          ...variant,
          option2: variant.option3,
          option3: ''
        }
      }

      return variant
    })

    this.setItemState({
      ...item,
      options: [
        ...options.slice(0, index),
        ...options.slice(index+1)
      ],
      variants:[
        ...variantsEdited
      ]
    });
  }

  handleOnClickShowApproveModal = () => {
    this.setState({ showApproveModal: true });
  }

  handleOnCloseShowApproveModal = () => {
    this.setState({ showApproveModal: false });
  }

  handleOnClickShowDisapproveModal = () => {
    this.setState({ showDisapproveModal: true });
  }

  handleOnCloseShowDisapproveModal = () => {
    this.setState({ showDisapproveModal: false });
  }

  handleOnClickShowMoreInfoModal = () => {
    this.setState({ showMoreInfoModal: true });
  }

  handleOnCloseShowMoreInfoModal = () => {
    this.setState({ showMoreInfoModal: false });
  }

  handleProductApproval = approve => {
    this.setState({ showDisapproveModal: false, showApproveModal: false, submitting: true });
    const { item: { id, remarks } } = this.state;
    const { match } = this.props;

    const url = `/pending_products/${approve ? 'approve' : 'disapprove'}`
    const body = {
      id,
      ...(remarks && { remarks })
    }

    return api(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then(async result => {
      if(result.errors){
        this.setState({
          submitting: false,
          displayProductCreateSuccess: false,
          displayProductCreateFail: true,
          errorMessage: result.errors.base || result.errors
        })
      } else {
        this.setState({
          displayProductCreateFail: false,
          displayProductCreateSuccess: true,
          successMessage: approve ? `Product approved. View the active product page <a href="/products/${result.product.id}">here</a>` : 'Product disapproved.'
        })
      }

      if(!approve){
        let pendingProduct = await api(`/products/${match.params.productId}`)

        if(pendingProduct){
          this.setState({
            item: {
              ...pendingProduct
            },
            has_variants: pendingProduct.variants.length > 1,
            pending: pendingProduct.approval_status == 'pending'
          })
        }
      }
    })
  }

  handleEditorChange = editorState => {
    if(this.state.editorState != editorState){
      const { item } = this.state;

      this.setItemState({
        ...item,
        body_html: stateToHTML(editorState.getCurrentContent())
      });
    }

    this.setState({ editorState })
  }

  handleProductMoreInfo = () => {
    this.setState({ showDisapproveModal: false, showApproveModal: false, showMoreInfoModal: false, submitting: true });
    const { item: { id, remarks } } = this.state;
    const { match } = this.props;

    const url = `/pending_products/more_info`
    const body = {
      id,
      ...(remarks && { remarks })
    }

    return api(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then(async result => {
      if(result.errors){
        this.setState({
          submitting: false,
        })
        toast.error(result.errors);
      } else {
        toast.success('More info required for this product.');
      }

      let pendingProduct = await api(`/products/${match.params.productId}`)

      if(pendingProduct){
        this.setState({
          item: {
            ...pendingProduct
          },
          has_variants: pendingProduct.variants.length > 1,
          pending: pendingProduct.approval_status == 'pending'
        })
      }
    })
  }

  handleOnRemarkChange = e => {
    const { item } = this.state;
    const { value : remarks } = e.target;

    this.setItemState({
      ...item,
      remarks
    })
  }

  handleOnClickShowReviewModal = () => {
    this.setState({ showReviewModal: true })
  }

  handleOnCloseReviewModal = () => {
    this.setState({ showReviewModal: false })
  }

  handleOnStartReview = async () => {
    const { item, pending } = this.state;
    const { match } = this.props;

    await api(`/pending_products/review`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json' },
      body: JSON.stringify({ id: item.id })
    }).catch(err => {
      this.setState({
        showReviewModal: false,
        displayProductCreateFail: true,
        errorMessage: err.error
      })
    })

    this.setState({
      showReviewModal: false,
    })

    if(pending){
      let pendingProduct = await api(`/products/${match.params.productId}`)

      if(pendingProduct){
        this.setState({
          item: {
            ...pendingProduct
          },
          has_variants: pendingProduct.variants.length > 1,
          pending: pendingProduct.approval_status == 'pending'
        })
      }
    }
  }

  updateAttrTags = async (attr) => {

    let { item, initialMetafieldAddOns, initialMetafieldInputs } = this.state;
    const { taggings: { items: predefinedTags } } = this.props;

    const { attributeTags, metafields, metafieldsToRemove } = convertAttr(attr, item, initialMetafieldAddOns, initialMetafieldInputs)
    item.tags = generateTagsString(item, predefinedTags, attributeTags)
    item.metafields = metafields
    item.metafieldsToRemove = metafieldsToRemove

    this.setState({
      attributes: {
        ...this.state.attributes,
        ...attr
      },
    })
    this.setItemState(item)
  }

  addHalalCert = cert => {
    const { item, productTemplateKey } = this.state;
    const certHTML = `<p><br></p>\n<figure><img src=\"${cert}\" height=\"300px\" width=\"auto\"/></figure>\n`
    const { body_html } = item;

    this.setState({
      productTemplateKey: productTemplateKey + 1
    })
    this.setItemState({
      ...item,
      halal_cert: cert,
      body_html: body_html + certHTML
    })
  }

  handleDismissAlert = () => {
    this.setState({
      displayProductCreateSuccess: false,
      displayProductCreateFail: false,
    })
  }

  handleOnClickDeleteProduct = () => {
    this.setState({
      showDeleteModal: true
    })
  }

  handleDeleteProduct = () => {
    const { item: { id } } = this.state;

    return api(`/products/${id}`, {
      method: 'DELETE'
    }).then(result => {
      if(result.errors){
        this.setState({
          submitting: false,
          showDeleteModal: false,
        })
        toast.error('Failed to delete product.');
      } else {
        this.setState({
          showDeleteModal: false,
          not_found: true,
        })
        toast.success('Product deleted.');
      }
    }).catch(err => {
      console.log(err);
    })
  }

  handleOnCloseDeleteModal = () => {
    this.setState({
      showDeleteModal: false
    })
  }

  handleImageReorder = (index, direction) => {
    const { item, productTemplateKey } = this.state;
    const { match } = this.props;
    let { media, images } = item;

    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create'))

    if (isLiveProduct) {
      if(index !== 0 && direction === -1){
        const img = media[index];
        media.splice(index, 1);
        media.splice(index-1, 0, img);
      }

      if(index !== media.length - 1 && direction === 1){
        const img = media[index];
        media.splice(index, 1);
        media.splice(index+1, 0, img);
      }

      this.setState({ reorder_media: true });
      this.setItemState({ ...item, media, productTemplateKey: productTemplateKey + 1 });
    } else {
      if(index !== 0 && direction === -1){
        const img = images[index];
        images.splice(index, 1);
        images.splice(index-1, 0, img);
      }

      if(index !== images.length - 1 && direction === 1){
        const img = images[index];
        images.splice(index, 1);
        images.splice(index+1, 0, img);
      }

      this.setItemState({ ...item, images, productTemplateKey: productTemplateKey + 1 });
    }
  }

  handleRemoveImage = index => {
    const { item, productTemplateKey, delete_media } = this.state;
    const { match } = this.props;
    let { media, images } = item;

    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create'))

    if (isLiveProduct) {
      let deleted = media.splice(index, 1);
      delete_media.push(deleted[0].id);

      this.setItemState({
        ...item,
        media,
        delete_media,
        productTemplateKey: productTemplateKey + 1
      })
    } else {
      images.splice(index, 1);

      this.setItemState({ ...item,
        images,
        productTemplateKey: productTemplateKey + 1
      })
    }
  }

  handleSellInBulk = () => {
    this.setState({ showConfirmSellInBulkModal: true })
  }

  handleOnCloseSellInBulkModal = () => {
    this.setState({ showConfirmSellInBulkModal: false })
  }

  handleCreateProductB2B = () => {
    const { item: { id, title } } = this.state;

    let body = {
      id,
      title: title + ' - Copy',
      b2b: true
    }

    // return api(`/products/duplicate`, {
    //   method: 'POST',
    //   headers: {'Content-Type': 'application/json'},
    //   body: JSON.stringify(body)
    // }).then(result => {
      this.setState({
        showConfirmSellInBulkModal: false,
    //     displayProductCreateSuccess: true,
    //     successMessage: `Product created. View it <a href="/products/pending/${result.id}">here</a>`,
      })
    // })
  }

  handleLinkProduct = () => {
    this.setState({ showLinkProductModal: true })
  }

  handleOnCloseLinkProduct = () => {
    this.setState({ showLinkProductModal: false })
  }

  handleOnClickShowShipping = () => {
    this.setState({ showShippingInfo: true })
  }

  handleOnCloseShipping = () => {
    this.setState({ showShippingInfo: false })
  }

  onClickAddYTLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showAddYTLink: true });
  }

  onCloseYTLink = () => {
    this.setState({ showAddYTLink: false });
  }

  saveYTLink = async (videoId) => {
    this.setState({ showAddYTLink: false });
    const { item } = this.state;
    const { match } = this.props;
    let { media, images } = item;

    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create'))

    if (isLiveProduct) {
      const result = await api(`/products/youtube_link/${item.id}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify({ id: videoId })
      })

      let newMedia = result.data.productCreateMedia.media

      let newMediaState = []
      _.each(newMedia, (mediaItem) => {
        newMediaState.push({
          id: mediaItem.id,
          isYoutube: true,
        })
      })

      this.setItemState({
        ...item,
        media: [
          ...media,
          ...newMediaState
        ]
      }, false)
    } else {
      this.setItemState({
        ...item,
        images: [
          ...images,
          {
            src: `https://youtu.be/${videoId}`,
            isYoutube: true,
            thumbnail: `https://img.youtube.com/vi/${videoId}/0.jpg`,
          }
        ]
      })
    }
  }

  handleOnClickAddFields = (e) => {
    e.preventDefault();
    e.stopPropagation()
    this.setState({ showFieldsModal: true })
  }

  handleRemoveField = index => {
    const { attributes: { customizeFields } } = this.state;

    this.setAttrState({
      customizeFields: [
        ...customizeFields.slice(0, index),
        ...customizeFields.slice(index + 1)
      ]
    })
  }

  setAttrState = item => {
    let { attributes } = this.state;

    this.updateAttrTags({
      ...attributes,
      ...item,
    });
  }

  handleOnChange = e => {
    const { attributes: { bia, ubia, customizeFields, locationsSelected: currentLocations, delivery_days, uboDates, boDates}, closed, datePickerKey } = this.state;
    const { name, value, checked, id } = e.target;
    const index = id && parseInt(id.split('_')[1]);

    let exclude_days = 'W'
    let closedTags = []

    switch (name) {
      case 'days_in_advance':
        this.setAttrState({
          bia: {
            ...bia,
            days_in_advance: value
          },
        })
        this.setState({
          datePickerKey: datePickerKey + 1
        })
        break;

      case 'days_in_advance_seasonal':
        this.setAttrState({
          ubia: {
            ...ubia,
            days_in_advance: value
          },
        })
        this.setState({
          datePickerKey: datePickerKey + 1
        })
        break;

      case 'cutoff_time':
        this.setAttrState({
          cutOffTime: value
        })
        break;

      case 'delivery_days':
        let updateDeliveryDays = [...delivery_days]

        const dayFound = _.findIndex(delivery_days, day => day.value === value)

        if(dayFound === -1 && checked){
          updateDeliveryDays.push({ value })
        }
        if(dayFound !== -1 && !checked){
          updateDeliveryDays.splice(dayFound, 1)
        }

        let selectedDeliveryDays = _.map(updateDeliveryDays, day => day.value)

        if(!_.includes(selectedDeliveryDays, 'sun') && !_.includes(selectedDeliveryDays, 'sat')){
          exclude_days = 'NW'
        } else if(!_.includes(selectedDeliveryDays, 'sun')){
          exclude_days = 'WS'
        }

        _.each(closed, (day, key) => {
          if(!_.includes(selectedDeliveryDays, key)){
            closedTags.push(closed[key])
          }
        })

        this.setAttrState({
          delivery_days: updateDeliveryDays,
          closedTags,
          bia: {
            ...bia,
            exclude_days
          }
        })
        break;

      case 'primaryTitle':
      case 'maxChar':
      case 'secondTitle':
      case 'options':
      case 'limit':
        this.setAttrState({
          customizeFields: [
            ...customizeFields.slice(0, index),
            {
              ...customizeFields[index],
              [name]: value
            },
            ...customizeFields.slice(index + 1)
          ]
        });
        break;

      case 'required':
        this.setAttrState({
          customizeFields: [
            ...customizeFields.slice(0, index),
            {
              ...customizeFields[index],
              [name]: checked
            },
            ...customizeFields.slice(index + 1)
          ]
        });
        break;

      case 'self':
      case 'block_em':
      case 'ordered_fields':
        this.setAttrState({
          [name]: checked
        })
        break;

      case 'delivery_type':

        let courierTag = country === 'MY' ? 'Nationwide (Courier Delivery)' : 'Courier Delivery'
        let locationsSelected = [ ...currentLocations ]
        const nationwideIndex = locationsSelected.findIndex(location => {
          return location.value === courierTag
        })

        if(value === 'courier' && nationwideIndex === -1){
          locationsSelected.push({
            label: courierTag,
            value: courierTag,
          })
        } else if (nationwideIndex !== -1){
          locationsSelected.splice(nationwideIndex, 1)
        }

        this.setAttrState({
          [name]: value,
          locationsSelected,
          bia: {
            ...bia,
            ...(Object.entries(bia).length <= 1 && {
              days_in_advance: '0',
              exclude_days: 'W'
            }),
            on: value === 'd2d'
          },
          ubia: {
            ...ubia,
            ...(Object.entries(ubia).length <= 1 && {
              days_in_advance: '0',
              exclude_days: 'W'
            }),
            on: value === 'seasonal'
          },
        })
        break;

      case 'ubo':
        const { datesRangeStart } = this.state;

        if(datesRangeStart){
          const diff = value.diff(datesRangeStart, 'days')
          let result = []

          for (let i = 0; i <= diff; i++){
            let subtractDay = value.clone().subtract(i, 'days');
            let wasPreviouslyPicked = uboDates.some((d) => d.isSame(subtractDay));

            if(!wasPreviouslyPicked){
              result.push(subtractDay)
            }
          }

          this.setState({
            datesRangeStart: null
          })

          this.setAttrState({
            uboDates: [...uboDates, ...result]
          })
          break
        }

        let wasPreviouslyPicked = uboDates.some((d) => d.isSame(value));
        if (wasPreviouslyPicked) {
          this.setAttrState({
            uboDates: uboDates.filter(d => !d.isSame(value))
          })
        } else {
          this.setAttrState({
            uboDates: [...uboDates, value]
          })
        }
        break;

      case 'ubo-start':
        this.setState({
          datesRangeStart: value,
        })
        break;

      case 'bo':
        const { boRangeStart } = this.state;

        if(boRangeStart){
          const diff = value.diff(boRangeStart, 'days')
          let result = []

          for (let i = 0; i <= diff; i++){
            let subtractDay = value.clone().subtract(i, 'days');

            if(boDates.some((d) => d.isSame(subtractDay))){
              result.push(subtractDay)
            }
          }

          this.setState({
            boRangeStart: null
          })

          this.setAttrState({
            boDates: [...boDates, ...result]
          })
          break
        }

        if (boDates.some((d) => d.isSame(value))) {
          this.setAttrState({
            boDates: boDates.filter(d => !d.isSame(value))
          })
        } else {
          this.setAttrState({
            boDates: [...boDates, value]
          })
        }
        break;

      default:
        this.setAttrState({
          [name]: value
        })
        break;
    }
  }

  handleAddOption = (fieldIndex) => {
    let { customizeFields } = this.state.attributes;

    customizeFields[fieldIndex].options = [...customizeFields[fieldIndex].options, {value: ""}]

    this.setAttrState({ customizeFields });
  }

  handleEditOptions = (e, optionIndex, fieldIndex, tagType) => {
    let { name, value } = e.target
    let { customizeFields } = this.state.attributes

    let split = customizeFields[fieldIndex].options[optionIndex].value.split('#')

    if (tagType === 'ddaddon' || tagType === 'cbaddon') {
      if (name.includes('name')) {
        customizeFields[fieldIndex].options[optionIndex].value = value + '#' + (split[1] || '')
      } else if (name.includes('price')) {
        customizeFields[fieldIndex].options[optionIndex].value = (split[0] || '') + '#' + value
      }
    } else {
      customizeFields[fieldIndex].options[optionIndex].value = value
    }

    this.setAttrState({ customizeFields });
  }

  handleRemoveOption = (optionIndex, fieldIndex) => {
    let { customizeFields } = this.state.attributes;

    customizeFields[fieldIndex].options.splice(optionIndex, 1)

    if (customizeFields[fieldIndex].options.length === 0) {
      customizeFields[fieldIndex].options = [{value: ""}]
    }

    this.setAttrState({ customizeFields });
  }

  handleAddField = field => {
    const { attributes: { customizeFields } } = this.state;

    this.setAttrState({
      customizeFields: [
        ...customizeFields,
        field,
      ]
    })

    this.setState({
      showFieldsModal: false,
      addConditional: false,
    })
  }

  handleOnCloseAddFields = () => {
    this.setState({ showFieldsModal: false, addConditional: false })
  }

  render() {
    const { user, match } = this.props;
    const { uploadingFile, has_variants, item, submitting, displayProductCreateFail, displayProductCreateSuccess, showImagesModal, assignImageVariant, merchants, loading, showApproveModal, showDisapproveModal, showMoreInfoModal, successMessage, showReviewModal, errorMessage, pending, sold_out, showDeleteModal, not_found, attributes, productTemplateKey, showConfirmSellInBulkModal, merchantsOptions, showLinkProductModal } = this.state;
    const { editorState, showShippingInfo, showAddYTLink, attributes: { customizeFields }, showFieldsModal, addConditional } = this.state;
    const isAdmin = user.role === 'admin' || user.role === 'staff'
    const isLiveProduct = !(_.includes(match.path, 'pending') || _.includes(match.path, 'create')) || not_found
    const markupType = item.variants[0].metafields.find(m => m.key === 'markup_type')
    const markup = item.variants[0].metafields.find(m => m.key === 'markup')

    return (
      <div>
        {
          loading &&
          <OverlayLoader />
        }
        {
          !loading &&
          <>
          <TabHeader
            title={
              <>
                {
                  isLiveProduct ?
                    `Product Add-on`
                    :
                    'Create Product Add-on'
                }
              </>
            }
            children={
              <>
                { isAdmin &&
                <Button variant="primary" onClick={this.handleOnPreviewProduct} className="btn-br-6">Preview</Button>
                }
                { isLiveProduct &&
                  <LinkProductModal
                    item={item}
                    onClose={this.handleOnCloseLinkProduct}
                    deliveryType={attributes.delivery_type}
                    updateForm={this.updateForm}
                  />
                }
                { isAdmin && item.has_b2c &&
                  <Button
                    variant="success"
                    onClick={() => {
                      window.location = `/products/${item.b2c_product_id}`
                    }}
                  >B2C Product</Button>
                }
              </>
            }
            tab_id="product_detail"
            user={user}
          />
          <div>

        {
          item.remarks &&
            <Alert variant="warning">
              <span>Remarks: {item.remarks}</span>
            </Alert>
        }
        {
          displayProductCreateSuccess &&
            <div className="sticky-alert" style={{zIndex:'1000'}}>
              <Alert variant="success" dismissible onClose={this.handleDismissAlert}>
                <span dangerouslySetInnerHTML={{__html: successMessage}}></span>
              </Alert>
            </div>
        }
        {
          displayProductCreateFail &&
            <div className="sticky-alert" style={{zIndex:'1000'}}>
              <Alert variant="danger" dismissible onClose={this.handleDismissAlert}>
                <span>{errorMessage}</span>
              </Alert>
            </div>
        }
        {
          !loading && not_found && !displayProductCreateFail && !displayProductCreateSuccess &&
            <Alert variant="warning">
              <span>Product not found or has been deleted. <Link to={{ pathname: '/products' }} >Back to all products.</Link></span>
            </Alert>
        }
        {
          !loading && !not_found && Object.entries(attributes).length > 0 &&
            <form onSubmit={this.submit} className="d-grid gap-4">
              <div>
                <Row>
                  <Col lg={6}>
                    <Card className="tealCard mt-4 shadow-sm">
                      <Card.Header className="tealCardHeader">
                        Product Info
                        {!_.includes(match.path, 'create') && !_.includes(match.path, 'pending') && user && isAdmin &&
                          <a href={`https://${shopifyDomain}/admin/products/${item.id}`} target="_blank" className="pull-right btn btn-secondary btn-br-6 py-0 ">View in Shopify</a>}
                      </Card.Header>
                      <Card.Body className="grey-label label-strong">
                        <Row className="d-grid gap-3">
                          <Col className="d-grid gap-1">
                            <label>Title</label>
                            <input className="form-control" name="title" type="text" placeholder="Title" onChange={e => this.updateForm(e)} required value={item.title} />
                          </Col>
                          { isLiveProduct &&
                          <Col className="d-grid gap-1">
                            <label>Status</label>
                            <Form.Select className="form-control" name="status" onChange={e => this.updateForm(e)} required value={item.status}>
                              <option value="active">Active</option>
                              <option value="draft">Draft</option>
                            </Form.Select>
                          </Col>
                          }
                          {
                            merchantsOptions && merchantsOptions.length > 1 &&
                              <Col className="d-grid gap-1">
                                <label>Vendor</label>
                                <Form.Select className="form-control" name="vendor" onChange={e => this.updateForm(e)} required value={item.vendor}>
                                  {
                                    isAdmin &&
                                      <option value="">Select a vendor</option>
                                  }
                                  {merchantsOptions.map((option, i) => (
                                    <option key={i} value={option.value}>{option.name}</option>
                                  ))}
                                </Form.Select>
                              </Col>
                          }
                          <Col className="d-grid gap-1">
                            <label>Description</label>
                            <div style={{ border: '1px solid #ced4da', borderRadius: '4px' }}>
                              <Editor
                                onEditorStateChange={this.handleEditorChange}
                                editorState={editorState}
                                editorStyle={{ resize: 'vertical', height: '150px', padding: '0 1rem' }} />
                            </div>
                          </Col>
                          {isAdmin &&
                          <Col className="d-grid gap-1">
                            <label>Tags</label>
                            <textarea readOnly name="tags" className="form-control" value={item.tags} onChange={e => this.updateForm(e)} style={{ height: '70px', resize: 'vertical' }} />
                          </Col>}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={6}>
                    <Card className="tealCard mt-4 shadow-sm">
                      <Card.Header className="tealCardHeader">Product Media</Card.Header>
                      <Card.Body className="d-grid gap-3">
                        <Dropzone
                          className='dropzone-area'
                          onDrop={e => this.onDropImage(e)}
                          multiple={true}
                          accept={isLiveProduct ? '.jpeg, .jpg, .png, .mp4, .mov' : '.jpeg, .jpg, .png'}>
                          <div className="dropzone-text">
                            <img src={uploadIcon} style={{ width: '30px', margin: '0 10px' }}></img>
                            { uploadingFile ?
                              <span className="white-space-pre-line" style={{ maxWidth: '350px' }}>Uploading...</span>
                              :
                              <span className="white-space-pre-line" style={{ maxWidth: '350px' }}>Drag and drop files here, or click to upload, or click <a href="#" onClick={this.onClickAddYTLink}>here</a> to add YouTube link</span>
                            }
                            { !uploadingFile &&
                              <>
                                <i className="dropzone-info" data-html={true} data-tip={`
                      <div style="text-align: left; max-width: 300px">
                      Image formats allowed: .jpeg and .png, with maximum height/width of 4472 pixels. <br/>Video formats allowed: .mp4 and .mov, with size limit of 50MB.<br/><br/>Note: Video upload only available once product is approved
                      </div>
                `} data-for="media-restriction"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="left" id="media-restriction" />
                              </>
                            }
                          </div>
                        </Dropzone>
                        {item.images && item.images.length > 0 &&
                        <Row>
                          {isLiveProduct ?
                              _.map(item.media, (media, i) => {
                                return (
                                  <Col xs={6} md={4} xl={3} key={i} className="product-detail-image">
                                    { media.preview ?
                                      <a href={media.src} target="_blank">
                                        <img src={media.preview} />
                                      </a>
                                      :
                                      <div className="text-center border border-2 rounded-3 d-flex flex-column align-items-center" style={{padding: '15px 10px'}}>
                                        <img src={media_placeholder} style={{width: '50%'}} />
                                        <span className="small text-muted" style={{lineHeight: '1.2rem'}}>Processing media. Refresh after a while to load preview</span>
                                      </div>
                                    }
                                    <div style={{ visibility: 'hidden' }} className="d-flex">
                                      <Button variant="secondary" size="small" onClick={() => this.handleImageReorder(i, -1)}>
                                        <FontAwesomeIcon icon={solid('arrow-left')} />
                                      </Button>
                                      <Button className="ms-2" variant="secondary" size="small" onClick={() => this.handleImageReorder(i, 1)}>
                                        <FontAwesomeIcon icon={solid('arrow-right')} />
                                      </Button>
                                      <Button onClick={() => this.handleRemoveImage(i)} variant="danger" size="small" className="ms-auto">
                                        <FontAwesomeIcon icon={solid('close')} />
                                      </Button>
                                    </div>
                                  </Col>
                                )
                              })
                              :
                              _.map(item.images, (image, i) => {
                                return (
                                  <Col xs={6} md={4} xl={3} key={i} className="product-detail-image">
                                    <a href={image.src} target="_blank">
                                      <img src={image.isYoutube ? image.thumbnail : image.src} />
                                    </a>
                                    <div style={{ visibility: 'hidden' }} className="d-flex">
                                      <Button variant="secondary" size="small" onClick={() => this.handleImageReorder(i, -1)}>
                                        <FontAwesomeIcon icon={solid('arrow-left')} />
                                      </Button>
                                      <Button className="ms-2" variant="secondary" size="small" onClick={() => this.handleImageReorder(i, 1)}>
                                        <FontAwesomeIcon icon={solid('arrow-right')} />
                                      </Button>
                                      <Button onClick={() => this.handleRemoveImage(i)} variant="danger" size="small" className="ms-auto">
                                        <FontAwesomeIcon icon={solid('close')} />
                                      </Button>
                                    </div>
                                  </Col>
                                )
                              })
                          }
                        </Row>}
                      </Card.Body>
                    </Card>
                    {
                      isAdmin &&
                        <Card className="tealCard mt-4 shadow-sm">
                          <Card.Header className="tealCardHeader">Tools & Misc.</Card.Header>
                          <Card.Body className="d-grid gap-3">
                            <Row>
                              <Col sm={6}>
                                <div className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                                  <label className='custom-checkbox'>
                                    <input type="checkbox" name="remove_pmg" onChange={e => this.updateForm(e)} checked={item.remove_pmg} value="1"/>
                                    <span className="checkmark checkmark-smaller"></span>
                                  </label>
                                  <label style={{fontWeight: '200'}}>Markup (No PMG)</label>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0" className="tealCard my-4 shadow-sm">
                        <Accordion.Header className="tealCardHeader product-accordion customisation" style={{borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'}}>
                          Product Customisation
                          <div className="ms-auto">
                            <a className="d-print-none py-0 btn-br-6 ms-auto me-2 btn btn-secondary" onClick={this.handleOnClickAddFields}><FontAwesomeIcon icon={solid('plus')}/> Add Fields</a>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="d-grid gap-3">
                          <ListGroup>
                            {
                              _.map(customizeFields, (field, i) => {
                                let requiredFieldProps = _.filter(field, (value, key) => {
                                  return _.includes(['maxChar', 'primaryTitle', 'limit', 'options'], key)
                                })

                                let propsRequired = _.reduce(requiredFieldProps, (req, prop) => {
                                  if(prop == '' || (Array.isArray(prop) && prop.length <= 1)){
                                    return true
                                  }

                                  return prop
                                }, false)

                                let values = {
                                  primaryTitle: field.primaryTitle,
                                  maxChar: field.maxChar,
                                  secondTitle: field.secondTitle,
                                  limit: field.limit,
                                  options: field.options,
                                  required: field.required,
                                }

                                return (
                                  <Card key={i} className="greyCard grey-label label-strong mb-3">
                                    <Card.Header className="greyCardHeader">
                                      <Row className="fields-header">
                                        <Col className="d-grid gap-1">
                                          <label>{field.title}</label>
                                          <em className={propsRequired === true ? 'pull-right' : 'hidden'}>Please fill up the properties to create the input field</em>
                                        </Col>
                                        <Col xs={2} style={{textAlign: 'right'}}>
                                          <Button className="mt-auto"onClick={() => this.handleRemoveField(i)} variant="danger" size="small">
                                            <FontAwesomeIcon icon={solid('close')}/>
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Card.Header>
                                    <Card.Body>
                                      <FieldCreate tagType={field.tagType} values={values} onChange={this.handleOnChange} index={i} handleAddOption={this.handleAddOption} handleEditOptions={this.handleEditOptions} handleRemoveOption={this.handleRemoveOption} isAdmin={isAdmin} showAddConditionalField={false}/>
                                    </Card.Body>
                                  </Card>
                                )
                              })
                            }
                          </ListGroup>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="tealCard shadow-sm mt-4">
                      <Card.Header className="tealCardHeader">Complete your product details</Card.Header>
                      <Card.Body className="grey-label label-strong d-grid gap-2">
                        <Row>
                          <Col lg={4}>
                            <Card className="greyCard grey-label label-strong my-2">
                              <Card.Header className="greyCardHeader">Pricing</Card.Header>
                              <Card.Body className="d-grid gap-2">
                                <Row>
                                  <Col xs={6} className="text-end"><label>Price</label></Col>
                                  <Col xs={6}><input className="form-control" name="base_price#0" type="number" step=".01" placeholder="Unit Price" onChange={e => this.handleBasePrice(e)} required value={item.variants[0].base_price} /></Col>
                                </Row>
                                <Row>
                                  <Col xs={6} className="text-end ps-0"><label>Compare at price</label></Col>
                                  <Col xs={6}><input className="form-control" name="compare_at_price#0" type="number" step=".01" onChange={e => this.updateVariants(e)} value={item.variants[0] && item.variants[0].compare_at_price || ''} /></Col>
                                </Row>
                                <Row>
                                  <Col xs={6} className="text-end ps-0"><label>Markup Type</label>
                                  </Col>
                                  <Col xs={6}>
                                    <Form.Select className="form-control" name={`markup_type#0`} onChange={isAdmin ? (e => this.handleMarkup(e)) : undefined} required value={markupType && markupType.value} disabled={!isAdmin}>
                                      <option value="fixed">Fixed</option>
                                      <option value="percentage">Percentage</option>
                                    </Form.Select>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6} className="text-end ps-0"><label>Markup</label>
                                  </Col>
                                  <Col xs={6}><input className="form-control" name={`markup#0`} type="number" step=".01" onChange={isAdmin ? (e => this.handleMarkup(e)) : undefined} value={markup && markup.value} disabled={!isAdmin}/></Col>
                                </Row>
                                { isAdmin &&
                                <Row className="shipping-lines">
                                  <Col className='text-end'>
                                    Actual Selling Price
                                    { markupType && markupType.value === 'percentage' &&
                                      <>
                                        <i data-html={true} data-tip={`<div style="max-width: 500px; text-align: left">Round-up applied on 2nd decimal point to standardize the pricing display and easier consideration for customer's purchase decision.<br/>E.g.: RM99.32 will be rounded up to RM99.40</div>`} data-for="markup-info" className="ms-1"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="top" id="markup-info" />
                                      </>
                                    }
                                  </Col>
                                  <Col>{currency} {parseFloat(item.variants[0].price).toFixed(2)}</Col>
                                </Row>
                                }
                                {
                                  !_.includes(match.path, 'create') && !_.includes(match.path, 'pending') && isAdmin &&
                                    <a href={`https://${shopifyDomain}/admin/products/${item.id}/variants/${item.variants[0].id}/metafields`} target="_blank" className="d-block btn btn-secondary btn-br-6 py-0 ">View Markup in Shopify</a>
                                }
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col lg={4}>
                            <Card className="greyCard grey-label label-strong my-2">
                              <Card.Header className="greyCardHeader">Inventory</Card.Header>
                              <Card.Body className="d-grid gap-2">
                                <Row>
                                  <Col xs={6} className="text-end"><label>SKU</label></Col>
                                  <Col xs={6}><input className="form-control" name={`sku#0`} type="text" onChange={e => this.updateVariants(e)} value={item.variants[0] && item.variants[0].sku || ''} /></Col>
                                </Row>
                                <Row>
                                  <Col xs={6} className="text-end"><label>Sold Out <i data-html={true} data-tip={`
                        <p style="text-align: left">
                          <u><b>How to Edit Inventory</b></u><br/>
                          Variant Sold Out:<br/>
                          1. Check "Sold Out"<br/><br/>
                          Variant Available in Limited Quantity:<br/>
                          1. Check "Quantity Tracked"<br/>
                          2. Set "Quantity Available" to available amount<br/><br/>
                          Variant Available (No Limited Quantity):<br/>
                          1. Uncheck "Quantity Tracked"
                        </p>`} data-for="sold-out-header"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="top" id="sold-out-header" />
                                  </label></Col>
                                  <Col xs={6}>
                                    <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                      <input type="checkbox" onChange={e => this.updateVariants(e)} name={`soldout#0`} checked={sold_out[0]} />
                                      <span className="checkmark checkmark-smaller"></span>
                                    </label>
                                  </Col>
                                </Row>
                                {!sold_out[0] &&
                                <Row>
                                  <Col xs={6} className="text-end ps-0"><label>Quantity Tracked</label></Col>
                                  <Col xs={6}>
                                    <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                      <input onChange={e => this.updateVariants(e)} name="inventory_management#0" type="checkbox" style={{ marginRight: "5px" }} checked={(item.variants[0] && item.variants[0].inventory_management === 'shopify') ? true : false} />
                                      <span className="checkmark checkmark-smaller"></span>
                                    </label>
                                  </Col>
                                </Row>}
                                {!sold_out[0] &&
                                  <>
                                    {item.variants[0] && item.variants[0].inventory_management &&
                                    <Row>
                                      <Col xs={6} className="text-end p-0"><label>Quantity Available</label></Col>
                                      <Col xs={6}><input className="form-control" name="inventory_quantity#0" type="text" placeholder="Quantity Available" onChange={e => this.updateVariants(e)} value={item.variants[0] && item.variants[0].inventory_quantity} /></Col>
                                    </Row>}
                                  </>}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            <Row className="align-items-center justify-content-center">
              {
                isLiveProduct ?
                  <>
                    <Col sm={3} className="mb-3 mb-sm-0">
                      <Button ref={this.updateBtnRef} className="w-100 btn-br-6" variant="info" disabled={submitting || loading} type="submit">
                        UPDATE
                      </Button>
                    </Col>
                    <Col sm={3} className="mb-3 mb-sm-0">
                      <Button className="w-100 btn-br-6" variant="danger-secondary" disabled={submitting || loading} onClick={this.handleOnClickDeleteProduct}>DELETE</Button>
                    </Col>
                  </>
                  :
                  <Col sm={3}>
                    <Button className="w-100 btn-br-6" variant="primary" disabled={submitting || loading} type="submit">
                      CREATE
                    </Button>
                  </Col>
              }
            </Row>
          </form>
        }
        {
          showImagesModal &&
            <ImagesModal images={item.images} variantIndex={assignImageVariant} variants={item.variants} onClose={this.handleOnCloseImagesModal} onClickSave={this.handleOnSaveVariantImage}/>
        }
        {
          showDeleteModal &&
            <ConfirmModal onClickYes={this.handleDeleteProduct} onClose={this.handleOnCloseDeleteModal} title="Delete Product">
              <p>Are you sure you want to <strong>delete</strong> {item.title}? This cannot be undone.</p>
            </ConfirmModal>
        }
      </div>
      </>
      }
        { showAddYTLink &&
          <YTLinkModal onClose={this.onCloseYTLink} onSave={this.saveYTLink} />
        }
        { showFieldsModal &&
          <FieldsModal onSelectField={this.handleAddField} onClose={this.handleOnCloseAddFields} conditional={addConditional} isAdmin={isAdmin} showAddConditionalField={false} isProductAddOn={true}/>
        }
    </div>
    )
  }
}

const closed = {
  mon: 'CLOSED-1',
  tue: 'CLOSED-2',
  wed: 'CLOSED-3',
  thu: 'CLOSED-4',
  fri: 'CLOSED-5',
  sat: 'CLOSED-6',
}

const dayOptions = [
  { value: 'sun', label: 'Sunday' },
  { value: 'mon', label: 'Monday' },
  { value: 'tue', label: 'Tuesday' },
  { value: 'wed', label: 'Wednesday' },
  { value: 'thu', label: 'Thursday' },
  { value: 'fri', label: 'Friday' },
  { value: 'sat', label: 'Saturday' },
]

export default connect((state, props) => {
  return {
    error: state.error,
    user: state.auth.user,
    taggings: state.taggings,
    previewProduct: state.previewProduct
  };
}, {
  ...errorActionCreators,
  ...merchantsActionCreators,
  ...taggingsActionCreators,
  ...previewProductActionCreators
})(ProductAddOn);

