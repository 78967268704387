import { 
  FETCH_USERS_REQUEST, 
  FETCH_USERS_SUCCESS, 
  FETCH_USERS_FAILURE, 
  CLEAR_USERS,
} from '../actions/users';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
};

export default function orders(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_USERS:
      return {
        ...state,
        defaultState
      };
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}