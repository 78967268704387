import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class ConfirmBillModal extends Component {
  constructor(props) {
    super(props);
  }

  handleOnClickSave = () => {
    const { onClickSave } = this.props;
    onClickSave();
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleOnInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    const { item } = this.props;

    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4>Confirm Bill</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          <div>
            <p>Bill line item cannot be changed once confirmed.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={this.handleOnClose}>Cancel</Button>
          <Button variant="primary" onClick={this.handleOnClickSave}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmBillModal;