import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone'
import uploadIcon from '../images/upload-icon.svg'
import toast from 'react-hot-toast';

class ConfirmBillModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      submitting: false,
    };
  }

  handleOnClickSave = () => {
    const { onClickSave } = this.props;
    const { file } = this.state;

    if (!file) {
      return toast.error('Please attach an invoice');
    }
    this.setState({ submitting: true });
    onClickSave(file);
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleOnInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleOnDrop = (files) => {
    if (files && files.length > 0) {
      this.setState({ file: files[0] });
    }
  }

  render() {
    const { item } = this.props;
    const { file, submitting } = this.state;

    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="my-0">Confirm Bill</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          <p>Bill line item cannot be changed once confirmed.</p>
          <div>
            <label>Attach an Invoice (PDF/ZIP)</label>
            <Dropzone
              className='dropzone-area'
              onDrop={this.handleOnDrop}
              multiple={false}
              accept='application/pdf,application/zip'>
              <div className="dropzone-text">
                <img src={uploadIcon} style={{ width: '30px', margin: '0 10px' }}></img>
                <span>Drag and drop files here, or click to upload</span>
              </div>
            </Dropzone><br />
            { file &&
              <label className="my-auto">{file.name}</label>
            }
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={this.handleOnClose}>Cancel</Button>
          <Button variant="primary" onClick={this.handleOnClickSave} disabled={submitting}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmBillModal;