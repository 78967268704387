import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_BILL_DETAIL_REQUEST = 'FETCH_BILL_DETAIL_REQUEST';
export const FETCH_BILL_DETAIL_SUCCESS = 'FETCH_BILL_DETAIL_SUCCESS';
export const FETCH_BILL_DETAIL_FAILURE = 'FETCH_BILL_DETAIL_FAILURE';
export const CLEAR_BILL_DETAIL = 'CLEAR_BILL_DETAIL';

function fetchBillDetailRequest(billId) {
  return {
    type: FETCH_BILL_DETAIL_REQUEST,
    payload: {
      billId, 
    }
  };
}

function fetchBillDetailSuccess(json) { 
  return {
    type: FETCH_BILL_DETAIL_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchBillDetailFailure() {
  return {
    type: FETCH_BILL_DETAIL_FAILURE
  };
}

function shouldFetchBillDetail(state) {
  const results = state.bill;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

function fetchBillDetailFromApi(billId) {
  return dispatch => {
    dispatch(fetchBillDetailRequest(billId));
    return api(`/bills/${billId}`)
      .then(json => {
        dispatch(fetchBillDetailSuccess(json));
      })
      .catch(err => {
        dispatch(fetchBillDetailFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchBillDetail(billId) {
  return (dispatch, getState) => {
    if (shouldFetchBillDetail(getState())) {
      return dispatch(fetchBillDetailFromApi(billId));
    }
  };
}

export function clearBillDetail() {
  return {
    type: CLEAR_BILL_DETAIL,
  };
}