import React, { useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import moment from 'moment';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

const MobileTicketModal = ({ handleSubmit, replyText, setReplyText, ticket, onClose, isMobile, isAdmin }) => {
  useEffect(() => {
    if(isMobile){
      window.onpopstate = () => {
        onClose();
      }
    }
  }, [])

  const getIconColor = (str) => {

    let total = 0;
    let charlength = 0
    if(str && str.length){
      charlength = str.length
    }

    for(let i = 0; str && i < str.length; i++){
      total += str.charCodeAt(i);
    }

    while(total > 1){
      total = total/charlength
    }

    return Math.floor(total*16777215).toString(16)
  }


  const buyerReply = {
    "width": "auto",
    "maxWidth": "90%",
    "display": "inline-block",
    "backgroundColor": "#fff",
    "marginRight" : "auto",
    "marginLeft" : "0",
    "lineHeight": "1.6",
    "fontSize": "1.2em",
    "fontWeight": "400",
    "fontFamily": "Varela Round, sans-serif",
    "padding": "1em",
    "boxShadow": "none",
    "borderRadius": "10px"
  }

  const vendorReply = {
    "width": "auto",
    "maxWidth": "90%",
    "display": "inline-block",
    "backgroundColor": "#80d7cf",
    "lineHeight": "1.6",
    "fontSize": "1.2em",
    "marginRight" : "0",
    "marginLeft" : "auto",
    "fontWeight": "400",
    "color" : "#fff",
    "fontFamily": "Varela Round, sans-serif",
    "padding": "1em",
    "boxShadow": "none",
    "borderRadius": "10px"
  }

  const titleStyle = {
    "fontSize": "1.2em",
    "fontWeight": "300",
    "fontFamily": "Varela Round, Arial, sans-serif",
  }

  const iconStyle	= {
    "fontWeight": "600",
    "fontSize": "16px"
  }

  return (
    <Modal show={true} onHide={onClose} fullscreen={true}>
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        {
          ticket.ticket_id &&
            <h4 className="mb-0">{ticket.ticket_id}</h4>
        }
    </Modal.Header>
      <Modal.Body className="px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
        <div className="d-flex flex-column-reverse" style={{height: '100%', overflowY: 'scroll'}}>
          <div className='d-grid gap-1 mx-1'>
              <input type="text" id={ticket._id} placeholder="Enter your reply here" value={replyText} onChange={e => setReplyText(e)} className="form-control"/>
              <Button variant="primary"
                className="btn-br-6"
                id={ticket._id}
                name="submit"
                size='sm'
                onClick={handleSubmit}
                disabled={!replyText}
              >
                Submit as Solved
              </Button>
              <Button variant="danger-secondary"
                className="btn-br-6"
                id={ticket._id}
                name="on-hold"
                size='sm'
                onClick={handleSubmit}
              >
                Change Status to On-Hold
              </Button>
            { isAdmin &&
            <Button variant="success"
              className="btn-br-6"
              id={ticket._id}
              name="solved"
              size="sm"
              onClick={handleSubmit}
            >
              Mark as Solved
            </Button>
            }
          </div>
          <Timeline lineStyle={{background: 'none'}}>
            <TimelineEvent
              title={ticket.created_by}
              key={ticket.created}
              createdAt={moment(ticket.created).format('DD/MM h:mm a')}
              iconColor={"#" + getIconColor(ticket.created_by)}
              icon={_.toUpper(ticket.created_by.substr(0, 1))}
              bubbleStyle={{background: '#fff', borderColor: '#7f35ff', color: '#7f35ff'}}
              titleStyle={titleStyle}
              contentStyle={buyerReply}
              iconStyle={iconStyle}
            >
              <div className="buyer-speech-bubble">
                <a href={ticket.link}>
                  <Row className="align-items-center justify-content-start">
                    <Col xs="auto" className="pe-0">
                      <img target="_blank" src={ticket.product_image_url} alt={ticket.product_name} height="70px"/>
                    </Col>
                    <Col xs="auto">
                      <div style={{fontSize: 'smaller', whiteSpace: 'pre-line'}}>
                        {ticket.product_name}<br/>
                        {currency}{ticket.price}<br/>
                      </div>
                    </Col>
                  </Row>
                </a>
                <hr/>
                <div style={{whiteSpace: 'pre-line'}}>
                  {ticket.body}
                </div>
              </div>
            </TimelineEvent>
            {
              ticket.replies.map(reply =>
              {
                if (reply.created_by == ticket.created_by){  //Buyer
                  return (
                    <TimelineEvent
                  title={reply.created_by}
            key={reply.created}
            createdAt={moment(reply.created).format('DD/MM h:mm a')}
            iconColor={"#" + getIconColor(reply.created_by)}
            icon={_.toUpper(reply.created_by ? reply.created_by.substr(0, 1) : "A")}
            bubbleStyle={{background: '#fff', borderColor: '#7f35ff', color: '#7f35ff'}}
            titleStyle={titleStyle}
            contentStyle={buyerReply}
            iconStyle={iconStyle}>
            <div className="buyer-speech-bubble" style={{whiteSpace: 'pre-line'}}>
              {reply.body}
            </div>
          </TimelineEvent>
            )
            }
            else {  //Vendor
              return (
                <TimelineEvent
              title={reply.created_by}
            key={reply.created}
            createdAt={moment(reply.created).format('DD/MM h:mm a')}
            iconColor={"#" + getIconColor(reply.created_by)}
            icon={_.toUpper(reply.created_by ? reply.created_by.substr(0, 1) : "A")}
            bubbleStyle={{background: '#fff', borderColor: '#80d7cf', color: '#80D7CF', right: '0', left:"unset"}}
            titleStyle={titleStyle}
            contentStyle={vendorReply}
            iconStyle={iconStyle}
            style={{textAlign: 'right', paddingLeft: 'unset', paddingRight:'45px'}}>
            <div className="vendor-speech-bubble" style={{whiteSpace: 'pre-line'}}>
              {reply.body}
            </div>
        </TimelineEvent>
            )
            }
            })
            }
      </Timeline>
    </div>
      </Modal.Body>
      </Modal>
  );
}

export default MobileTicketModal;
