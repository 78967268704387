import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash'
import { useTable, useRowSelect, useExpanded, useFlexLayout, useResizeColumns } from 'react-table';
import LineItemRow from './LineItemRow';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import whatsappLogo from '../images/whatsapp.svg';

const giftrDomain = window.location.hostname === 'seller.giftr.my' ? 'https://giftr.my' : 'https://giftr.sg'

const findSubRows = (lineItems, currency) => {
  // Product Add Ons
  let depth = 0;
  function getProductAddOns(lineItems) {
    let mainNotFound = false
    let reordered = _.reduce(lineItems, (arr, lineItem) => {
      const { bundleId, main, title } = lineItem;

      if (bundleId && main) {
        let main = Object.assign({}, lineItem)
        if (!main.subRows) {
          main.subRows = []
        }
        arr.push(main)

        let foundSubs = _.reduce(arr, (subs, item, index) => {
          if (!item.main && depth === 1 && _.includes(item.title, title)) {
            subs.push(index)
          } else if (!item.main && item.bundleId && bundleId === item.bundleId) {
            subs.push(index)
          }

          return subs
        }, [])

        if (foundSubs.length > 0) {
          mainNotFound = false
          _.each(foundSubs, index => {
            arr[arr.length - 1].subRows.push(arr[index])
          })
          arr = _.filter(arr, (item, i) => !_.includes(foundSubs, i))
        }

      } else if (bundleId && !main) {
        let foundMain = _.findIndex(arr, item => {
          if (depth === 1) {
            return _.includes(title, item.title) && item.main && item.bundleId && bundleId === item.bundleId
          }
          return item.main && item.bundleId && bundleId === item.bundleId
        })

        if (foundMain > -1) {
          arr[foundMain].subRows.push({ ...lineItem, title: '(Add-on) ' + lineItem.title })
        } else {
          let addOnTitle = _.startsWith(lineItem.title, '(Add-on) ') ? lineItem.title : '(Add-on) ' + lineItem.title
          arr.push({ ...lineItem, title: addOnTitle })
          mainNotFound = true
        }

      } else {
        arr.push(lineItem)

      }

      return arr
    }, [])

    if (mainNotFound && depth < 1) {
      depth++
      return getProductAddOns(reordered)
    } else {
      return reordered
    }
  }
  let reordered = getProductAddOns(lineItems)

  function getRowData(lineItem, subRow = false) {
    let { image, title, properties, delivery, ship_by, quantity, vendor, price, handle } = lineItem;
    if (!vendor) {
      let foundVendor = _.find(properties, prop => prop.name === "Fulfilled by")

      if (foundVendor) {
        vendor = foundVendor.value
      }
    }

    let message = _.find(properties, prop => prop.name === 'Message on Card')
    message = message ? message.value : ''

    let result = {
      image: { src: image, alt: title, quantity },
      title,
      handle,
      delivery,
      ship_by,
      message,
      quantity,
      lineItem,
      vendor,
      price: `${currency} ${price}`,
      // quantity_price: `${quantity} x ${price}`,
      total_price: `${subRow ? '+ ': ''}${currency} ${(price * quantity).toFixed(2)}`,
      expand_details: { showToggle: !subRow, isExpanded: lineItems.length === 1 },
      mobile: {
        title,
        handle,
        delivery,
        ship_by,
        message,
        quantity,
        vendor,
        price: `${currency} ${price}`,
        // quantity_price: `${quantity} x ${price}`,
        total_price: `${subRow ? '+ ': ''}${currency} ${(price * quantity).toFixed(2)}`,
      }
    }

    if (title === 'Add-ons') {
      delete result.delivery
      delete result.ship_by
      delete result.message
      delete result.mobile.delivery
      delete result.mobile.ship_by
      delete result.mobile.message
    }

    if (lineItem.subRows) {
      result.subRows = _.map(lineItem.subRows, item => getRowData(item, true))
    }

    return result
  }

  return _.map(reordered, lineItem => getRowData(lineItem))
}

const LineItems = ({ items, onCheckItem, isMobile, ...rest }) => {
  const { user, noteAttributes, displaySection, currency } = rest;
  const [lineItems, setLineItems] = useState([]);

  const data = useMemo(
    () => {
      if (lineItems.length > 0) {
        return findSubRows(lineItems, currency);
      }

      return []
    },
    [lineItems]
  )

  const columns = useMemo(
    () => {
      let columns = [
        { Header: 'Details', accessor: 'expand_details' },
        { Header: 'Line Item', accessor: 'lineItem', },
        { Header: 'Handle', accessor: 'handle', },
      ]

      if (isMobile) {
        columns.splice(0, 0, { Header: 'Item Details', accessor: 'mobile' })
      } else {
        columns.splice(0, 0,
          { Header: 'Image', accessor: 'image', width: 120, Cell: ({ value: { src, alt, quantity } }) =>
            src ?
              <div className="line-item-row-image" style={{ textAlign: 'left' }}>
                <img src={src} alt={alt} />
                <span className="badge badge-quantity">{quantity}</span>
              </div>
            :
            null
          }, // accessor is the "key" in the data
          { Header: 'Title', accessor: 'title', width: 220, Cell:
            ({ row }) => {
              const { title, handle } = row.values;
              if (title === 'Add-ons') {
                return 'Add-ons'
              }
              if (handle) {
                return (
                  <a target="_blank" href={`${giftrDomain}/products/${handle}`} className="text-decoration-none" style={{ color: 'inherit' }}>{title}</a>
                )
              }

              return title
            }
          },
          { Header: 'Delivery', accessor: 'delivery', width: 120, },
          { Header: 'Ship by', accessor: 'ship_by', width: 120, },
          { Header: 'Message', accessor: 'message', width: 120, },
          { Header: 'Quantity', accessor: 'quantity', width: 120, },
          { Header: 'Price', accessor: 'price', width: 120, },
          // { Header: 'Qty x Price', accessor: 'quantity_price', width: 120, },
          { Header: 'Total Price', accessor: 'total_price', width: 120, },
        )

        if (user && (user.role === 'admin' || user.role === 'staff')) {
          columns.splice(2, 0, { Header: 'Vendor', accessor: 'vendor', width: 120, Cell: ({ value, row }) => {
            const { lineItem } = row.values
            return <span>{value} {(lineItem.operations_contact_method && lineItem.operations_contact) ?
              <a href={`${lineItem.operations_contact_method === 'phone_number' ? 'https://wa.me/' : ''}${lineItem.operations_contact}`} target="_blank">
                <img height='25px' src={whatsappLogo} />
              </a>
              : ''}</span>
          }})
        }
      }

      return columns
    },
    []
  )

  const expanded = useMemo(() => {
    let rows = _.map(lineItems, _ => true)

    return Object.assign({}, rows)
  }, [lineItems])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    { columns, data, initialState: {
      hiddenColumns: ['expand_details', 'lineItem', 'handle'],
      expanded,
    }},
    useExpanded,
    useRowSelect,
    useFlexLayout,
    // useResizeColumns,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection_and_expand',
          width: isMobile ? 20 : 60,
          Cell: ({ row }) => {
            const { lineItem } = row.values;
            if (displaySection !== "unfulfilled") {
              return null
            }

            const redemptionTypePre = _.find(noteAttributes, note => _.includes(note.name, `redemption_type_pre_${lineItem.id}`))
            if (redemptionTypePre) {
              return null
            }

            return (
              <div className={row.depth > 0 ? "ps-2" : ""}>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={row.depth > 0} />
              </div>
            )
          },
        },
        ...columns,
      ])
    },
  )

  useEffect(() => {
    if (!selectedFlatRows || !rows || displaySection !== 'unfulfilled') {
      return
    }

    let selected = [];
    _.each(rows, row => {
      let isSelected = _.some(selectedFlatRows, flatRow => flatRow.values.lineItem.id === row.values.lineItem.id)

      if (isSelected) {
        selected.push(row.values.lineItem.id.toString())
      }
    })

    onCheckItem(selected)
  }, [selectedFlatRows, rows, displaySection])

  useEffect(() => {
    reorderItems(items)
  }, [])

  const reorderItems = (items) => {
    if (!Array.isArray(items)) {
      return;
    }

    let copy = [...items];

    _.each(items, item => {
      if (item.name.match(/(- add ons)/i)) {
        copy = _.filter(copy, copyItem => copyItem.id !== item.id)
        const actualItemIndex = _.findIndex(copy, filtered => filtered.aoid === item.aoid)
        copy.splice(actualItemIndex + 1, 0, item)
      }
    })

    setLineItems(copy);
  }

  return (
    <table {...getTableProps()} className="table">
      <thead>
        { headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            { headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
                {/* { column.id === 'title' && */}
                {/*   <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? 'isResizing' : ''}`}></div> */}
                {/* } */}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        { rows.map(row => {
          prepareRow(row)

          return (
            <LineItemRow
              isMobile={isMobile}
              row={row}
              {...rest}
              {...row.getRowProps()}
            />
          )
        })}
      </tbody>
    </table>
  );
}

export default LineItems;
