import {
  FETCH_LINKED_STORES_REQUEST,
  FETCH_LINKED_STORES_SUCCESS,
  FETCH_LINKED_STORES_FAILURE
} from '../actions/linkedStores';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
};

export default function taggings(state = defaultState, action) {
  switch (action.type) {
    case FETCH_LINKED_STORES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_LINKED_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: action.payload.items,
        lastUpdated: action.payload.receivedAt,
        // next: action.payload.next
      };
    case FETCH_LINKED_STORES_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
