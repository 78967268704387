import qs from 'qs';
import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_PRODUCT_DETAIL_REQUEST = 'FETCH_PRODUCT_DETAIL_REQUEST';
export const FETCH_PRODUCT_DETAIL_SUCCESS = 'FETCH_PRODUCT_DETAIL_SUCCESS';
export const FETCH_PRODUCT_DETAIL_FAILURE = 'FETCH_PRODUCT_DETAIL_FAILURE';

function fetchProductDetailRequest(productId){
  return {
    type: FETCH_PRODUCT_DETAIL_REQUEST,
    payload: {
      productId
    }
  }
}

function fetchProductDetailSuccess(json) {
  return {
    type: FETCH_PRODUCT_DETAIL_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now()
    }
  }
}

function fetchProductDetailFailure() {
  return {
    type: FETCH_PRODUCT_DETAIL_FAILURE
  }
}

function shouldFetchProductDetail(state) {
  const results = state.productDetail;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

function fetchProductDetailFromApi(productId){
  return dispatch => {
    dispatch(fetchProductDetailRequest(productId));
    return api(`/products/${productId}`)
      .then(json => {
        dispatch(fetchProductDetailSuccess(json));
      })
      .catch(err => {
        dispatch(fetchProductDetailFailure());
      })
  }
}

export function fetchProductDetail(productId) {
  return (dispatch, getState) => {
    if(shouldFetchProductDetail(getState())) {
      return dispatch(fetchProductDetailFromApi(productId));
    }
  }
}
