import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_DELIVERY_DETAIL_REQUEST = 'FETCH_DELIVERY_DETAIL_REQUEST';
export const FETCH_DELIVERY_DETAIL_SUCCESS = 'FETCH_DELIVERY_DETAIL_SUCCESS';
export const FETCH_DELIVERY_DETAIL_FAILURE = 'FETCH_DELIVERY_DETAIL_FAILURE';
export const CLEAR_DELIVERY_DETAIL = 'CLEAR_DELIVERY_DETAIL';

function fetchDeliveryDetailRequest(id) {
  return {
    type: FETCH_DELIVERY_DETAIL_REQUEST,
    payload: {
      id,
    }
  };
}

function fetchDeliveryDetailSuccess(json) {
  return {
    type: FETCH_DELIVERY_DETAIL_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchDeliveryDetailFailure() {
  return {
    type: FETCH_DELIVERY_DETAIL_FAILURE
  };
}

function shouldFetchDeliveryDetail(state) {
  const results = state.delivery;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

function fetchDeliveryDetailFromApi(id) {
  return dispatch => {
    dispatch(fetchDeliveryDetailRequest(id));
    return api(`/deliveries/${id}`)
      .then(json => {
        dispatch(fetchDeliveryDetailSuccess(json));
      })
      .catch(err => {
        dispatch(fetchDeliveryDetailFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchDeliveryDetail(id) {
  return (dispatch, getState) => {
    if (shouldFetchDeliveryDetail(getState())) {
      return dispatch(fetchDeliveryDetailFromApi(id));
    }
  };
}

export function clearDeliveryDetail() {
  return {
    type: CLEAR_DELIVERY_DETAIL,
  };
}
