import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Card, Badge, Form, Container, Accordion, ListGroup } from 'react-bootstrap';
import _ from 'lodash'
import * as errorActionCreators from '../redux/actions/error';
import * as merchantsActionCreators from '../redux/actions/merchants';
import productTypeOptions from '../data/product_type.json';
import productTypeOptionsSG from '../data/product_type_sg.json';
import Dropzone from 'react-dropzone';
import ProductAttributes from '../components/ProductAttributes';
import ReactTooltip from 'react-tooltip';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import uploadIcon from '../images/upload-icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import InfoModal from './InfoModal';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Select from 'react-select';
import media_placeholder from '../images/media-placeholder.svg';
import YTLinkModal from './YTLinkModal';
import EditOptionModal from './EditOptionModal'
import ConfirmModal from '../components/ConfirmModal';
import toast from 'react-hot-toast';
import { removeInlineStyles, removeEntities, removeBlockTypes } from '../helpers/draftjsClearFormat';
import EditImageModal from './EditImageModal'
import moment from 'moment';

let country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'
// country = 'MY'
const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'
const shopifyDomain = window.location.hostname === 'seller.giftr.my' ? 'helpr-asia.myshopify.com' : 'giftr-singapore.myshopify.com'
const giftrDomain = window.location.hostname === 'seller.giftr.my' ? 'giftr.my' : 'giftr.sg'

const shippingInfoSG = [
  { weight: '0.1 to 19.99', price: 4.0 },
  { weight: '20.0 to 99.99', price: 5.0 },
  { weight: '100 and above', price: 6.0 },
]

function createOptions(options) {
  return _.map(options, option => {
    return {
      label: option.name,
      value: option.value
    }
  })
}

const ClearFormatButton = ({clearFormat}) => {
  return (
    <Button variant="secondary" size="sm" className="mb-2" onClick={clearFormat}>Clear Format</Button>
  )
}

const shippingInfo = [
  { weight: '0.02 to 0.5', west_my: 6.9, sarawak: 16.6, sabah_labuan: 16.6 },
  { weight: '0.6 to 1.0', west_my: 6.9, sarawak: 22.7, sabah_labuan: 23.1 },
  { weight: '1.1 to 1.5', west_my: 9.4, sarawak: 36.0, sabah_labuan: 39.3 },
  { weight: '1.6 to 2.0', west_my: 9.4, sarawak: 39.3, sabah_labuan: 39.3 },
  { weight: '2.1 to 3.0', west_my: 9.4, sarawak: 58.4, sabah_labuan: 58.4 },
  { weight: '3.1 to 4.0', west_my: 10.9, sarawak: 77.6, sabah_labuan: 77.6 },
  { weight: '4.1 to 5.0', west_my: 12.4, sarawak: 96.7, sabah_labuan: 96.7 },
  { weight: '5.1 to 6.0', west_my: 13.9, sarawak: 115.4, sabah_labuan: 115.9 },
  { weight: '6.1 to 7.0', west_my: 15.40, sarawak: 133.9, sabah_labuan: 135.0 },
  { weight: '7.1 to 8.0', west_my: 16.9, sarawak: 152.4, sabah_labuan: 154.1 },
  { weight: '8.1 to 9.0', west_my: 18.4, sarawak: 171.0, sabah_labuan: 173.3 },
  { weight: '9.1 to 10.0', west_my: 19.9, sarawak: 189.5, sabah_labuan: 192.4 },
]

const shippingColSG = [
  { dataField: 'weight', text: 'Weight (kg)', align: 'center', headerAlign: 'center' },
  { dataField: 'price', text: 'Price (SGD)', formatter: (col) => { return col.toFixed(2) }, align: 'center', headerAlign: 'center' },
]

const shippingCol = [
  { dataField: 'weight', text: 'Weight (kg)', align: 'center', headerAlign: 'center' },
  { dataField: 'west_my', text: 'West MY (RM)', formatter: (col) => { return col.toFixed(2) }, align: 'center', headerAlign: 'center' },
  { dataField: 'sarawak', text: 'Sarawak (RM)', formatter: (col) => { return col.toFixed(2) }, align: 'center', headerAlign: 'center' },
  { dataField: 'sabah_labuan', text: 'Sabah & Labuan (RM)', formatter: (col) => { return col.toFixed(2) }, align: 'center', headerAlign: 'center' },
]

class ProductB2C extends Component {
  constructor(props) {
    super(props);
    this.optionDeletedToast = createRef();

    this.state = {
      editorState: EditorState.createEmpty(),
      showShippingInfo: false,
      showAddYTLink: false,
      showEditImage: false,
      editImage: '',
      editImageId: '',
      editImageIndex: null
    }
  }

  componentDidMount() {
    const { item: { body_html }, merchants } = this.props;

    const merchant = _.find(merchants, { name: this.props.item.vendor })

    const contentState = this.getContentState(body_html)

    this.setState({
      editorState: EditorState.createWithContent(contentState)
    })
  }

  componentWillUnmount() {
    if (this.optionDeletedToast.current) {
      toast.dismiss(this.optionDeletedToast.current);
      this.optionDeletedToast.current = null
    }
    this.optionDeletedToast.current = null
  }

  getContentState = (html) => {
    const { contentBlocks, entityMap } = convertFromHTML(html ? html.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '') : '')
    return ContentState.createFromBlockArray(contentBlocks, entityMap)
  }

  editorClearFormat = () => {
    const { editorState } = this.state
    const { handleEditorChange } = this.props;

    const stateWithoutFormat = _.flow([
      removeInlineStyles,
      removeEntities,
      removeBlockTypes,
    ])(editorState)

    handleEditorChange(stateWithoutFormat)
    this.setState({ editorState: stateWithoutFormat })
  }

  editorChange = editorState => {
    const { handleEditorChange } = this.props;

     if(this.state.editorState != editorState){
       handleEditorChange(editorState)
     }

    this.setState({ editorState })
  }

  handleOnClickShowShipping = () => {
    this.setState({ showShippingInfo: true })
  }

  handleOnCloseShipping = () => {
    this.setState({ showShippingInfo: false })
  }

  getWeightMin = (i) => {
    const { attributes: { delivery_type }, shipping_delivery_included } = this.props;

    let weightMin = false
    if (country === 'MY' && delivery_type === 'courier') {
      weightMin = '0.001'
    }
    if (country === 'SG' && delivery_type === 'courier' && !shipping_delivery_included[i]) {
      weightMin = '0.1'
    }

    return weightMin
  }

  onClickAddYTLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showAddYTLink: true });
  }

  onCloseYTLink = () => {
    this.setState({ showAddYTLink: false });
  }

  onSaveYTLink = (videoId) => {
    const { saveYTLink } = this.props;

    this.setState({ showAddYTLink: false });
    saveYTLink(videoId)
  }

  onClickEditOption = (index) => {
    if (this.optionDeletedToast.current) {
      toast.dismiss(this.optionDeletedToast.current);
      this.optionDeletedToast.current = null
    }
    this.setState({ showAddEditOptionModal: true, editOptionIndex: index, optionModalMode: 'edit' })
  }

  onCloseEditOptionModal = () => {
    this.setState({ showAddEditOptionModal: false })
  }

  handleEditOption = (name, values, conditionalFields) => {
    const { editOptionIndex } = this.state;
    const { item, updateOptionsAndVariants } = this.props;
    let { variants, options } = item
    let position = editOptionIndex

    let newValues = _.map(_.filter(values, value => value.new && !value.old), "new")

    let optionIndex = _.findIndex(options, option => option.position === editOptionIndex)
    if (optionIndex !== -1) {
      options[optionIndex].name = name
      options[optionIndex].values = values
      options[optionIndex].conditionalFields = conditionalFields
    } else {
      position = options.length + 1
      options.push({
        name: name,
        values: values,
        position: position,
        conditionalFields: conditionalFields
      })
    }

    options = _.sortBy(options, 'position')

    // get all permutations
    let newVariants = [], max = options.length - 1
    function helper(arr, i) {
      for (let j = 0, l = options[i].values.length; j < l; j++) {
        let a = arr.slice(0)
        a.push(options[i].values[j])
        if (i < max) {
          helper(a, i + 1)
        } else {
          newVariants.push(a)
        }
      }
    }
    helper([], 0)
    /*
    eg:
      opt1: m, n
      opt2: a, b
      opt3: x, y

    helper([], 0)
    - [m], 1
      - [m, a], 2
        - [m, a, x], 3 - push
        - [m, a, y], 3 - push
      - [m, b], 2
        - [m, b, x], 3 - push
        - [m, b, y], 3 - push
    - [n], 1
      - [n, a], 2
        - [n, a, x], 3 - push
        - [n, a, y], 3 - push
      - [n, b], 2
        - [n, b, x], 3 - push
        - [n, b, y], 3 - push
    */

    // reformat newVariants
    newVariants = _.map(newVariants, (v) => {
      let props = {}
      _.each(v, (val, i) => {
        props[`option${i + 1}`] = val
      })

      if (v.shipping_not_required) {
        props.shipping_not_required = true
      }

      return props
    })

    function getOptionValue(option, type = 'new') {
      if (_.isObject(option)) {
        option = option[type]
      }
      if (option === undefined || !option) {
        return null
      }
      return option
    }

    function findOldOption(variants, option1, option2, option3) {
      return _.find(variants, variant => {
        let varOption1 = getOptionValue(variant.option1, 'old')
        let varOption2 = getOptionValue(variant.option2, 'old')
        let varOption3 = getOptionValue(variant.option3, 'old')

        return (varOption1 === option1 && varOption2 === option2 && varOption3 === option3)
      })
    }

    function isVariantRequiresShipping(variant) {
      if (variant.option1 && variant.option1.shipping_not_required) {
        return false
      }
      if (variant.option2 && variant.option2.shipping_not_required) {
        return false
      }
      if (variant.option3 && variant.option3.shipping_not_required) {
        return false
      }
      return true
    }

    // update existing option values & mark variants for removal
    _.each(variants, (oldVar) => {
      let matchedNewVariant = findOldOption(newVariants, oldVar.option1, oldVar.option2, oldVar.option3)
      if (matchedNewVariant) {
        oldVar.option1 = getOptionValue(matchedNewVariant.option1)
        oldVar.option2 = getOptionValue(matchedNewVariant.option2)
        oldVar.option3 = getOptionValue(matchedNewVariant.option3)
        oldVar.requires_shipping = isVariantRequiresShipping(matchedNewVariant)
      } else {
        oldVar.toRemove = true
      }
    })

    function findNewOptionIndex(variants, option1, option2, option3) {
      return _.findIndex(variants, variant => {
        option1 = getOptionValue(option1)
        option2 = getOptionValue(option2)
        option3 = getOptionValue(option3)

        return (variant.option1 === option1 && variant.option2 === option2 && variant.option3 === option3)
      })
    }

    // get new variants & restore variants marked for removal
    newVariants = _.reduce(newVariants, (acc, newVar) => {
      let matchedOldVariant = findNewOptionIndex(variants, newVar.option1, newVar.option2, newVar.option3)
      if (matchedOldVariant !== -1){
        if (variants[matchedOldVariant].toRemove) {
          variants[matchedOldVariant].toRemove = false
        }
        return acc
      }
      let isNewValue = _.includes(newValues, getOptionValue(newVar[`option${position}`]))
      if (!isNewValue) {
        return acc
      }
      let accVariant = {
        option1: getOptionValue(newVar.option1),
        option2: getOptionValue(newVar.option2),
        option3: getOptionValue(newVar.option3),
        requires_shipping: isVariantRequiresShipping(newVar),
      }
      acc.push(accVariant)
      return acc
    }, [])

    // reformat options
    options = _.map(options, opt => {
      return {
        ...opt,
        values: _.map(opt.values, val => {
          if (_.isObject(val)) {
            return val.new
          }
          return val
        })
      }
    })
    let removeVariants = _.filter(variants, v => v.toRemove)
    if (removeVariants.length) {
      toast(
        `${removeVariants.length} variant(s) will be removed`,
        {
          icon: <FontAwesomeIcon icon={solid('circle-exclamation')}/>,
        }
      );
    }

    updateOptionsAndVariants(variants, options, newVariants)
    this.setState({ showAddEditOptionModal: false })
  }

  onClickAddOption = () => {
    if (this.optionDeletedToast.current) {
      toast.dismiss(this.optionDeletedToast.current);
      this.optionDeletedToast.current = null
    }
    this.setState({ showAddEditOptionModal: true, editOptionIndex: -1, optionModalMode: 'add' })
  }

  onClickUndoRemoveVariant = (option1, option2, option3, index) => {
    const { item, updateOptionsAndVariants } = this.props;
    let { variants, options } = item
    let value = '', name = '', position = 0
    _.each(item.options, (option) => {
      if (option.position === 1 && !_.includes(option.values, option1)) {
        value = option1
        name = option.name
        position = 1
      }
      if (option.position === 2 && !_.includes(option.values, option2)) {
        value = option2
        name = option.name
        position = 2
      }
      if (option.position === 3 && !_.includes(option.values, option3)) {
        value = option3
        name = option.name
        position = 3
      }
    })

    if (!value) {
      variants[index].toRemove = false
      updateOptionsAndVariants(variants, options)
      return
    }

    let permutation = options[position - 1].values.length + 1
    _.each(item.options, (option) => {
      if (option.position !== position) {
        permutation = permutation * option.values.length
      }
    })

    this.setState({ showRestoreOptionModal: true, permutationError: permutation > 100, editOptionIndex: position, optionToRestore: { value, name } })
  }

  handleOnCloseRestoreOptionModal = () => {
    this.setState({ showRestoreOptionModal: false })
  }

  handleRestoreOption = () => {
    const { optionToRestore, editOptionIndex } = this.state;
    const { item } = this.props;
    const { name, value } = optionToRestore
    let { options } = item;

    let optionIndex = _.findIndex(options, option => option.position === editOptionIndex)

    let values = [
      ...options[optionIndex].values,
      {
        old: undefined,
        new: value
      }
    ]
    this.handleEditOption(name, values)
    this.setState({ showRestoreOptionModal: false })
  }

  handleUndoDeleteOption = () => {
    let { undoOptions, undoVariants } = this.state;
    let { updateOptionsAndVariants } = this.props;

    if (this.optionDeletedToast.current) {
      toast.dismiss(this.optionDeletedToast.current);
      this.optionDeletedToast.current = null
    }
    updateOptionsAndVariants(undoVariants, undoOptions)
  }

  handleDeleteOption = () => {
    const { editOptionIndex } = this.state;
    const { item, updateOptionsAndVariants } = this.props;
    let { variants, options } = item

    this.setState({
      undoVariants: _.cloneDeep(variants),
      undoOptions: _.cloneDeep(options),
    })
    options = _.filter(options, option => option.position !== editOptionIndex)

    for (let i = 1; i < 4; i++) {
      let foundOption = _.find(options, option => option.position === i)
      if (!foundOption && (i + 1) < 4) {
        foundOption = _.find(options, option => option.position === i + 1)
      }

      if (foundOption) {
        foundOption.position = i
      }
    }

    for (let variant of variants) {
      if (editOptionIndex === 1) {
        variant.option1 = variant.option2
        variant.option2 = variant.option3
        variant.option3 = null
      }
      if (editOptionIndex === 2) {
        variant.option2 = variant.option3
        variant.option3 = null
      }
      if (editOptionIndex === 3) {
        variant.option3 = null
      }
    }

    let uniqVariants = [], removed = 0
    _.each(variants, (variant) => {
      if (!_.find(uniqVariants, { option1: variant.option1, option2: variant.option2, option3: variant.option3 })) {
        uniqVariants.push(variant)
      } else {
        removed += 1
      }
    })
    let copyVariants = [...variants]
    for (let variant of copyVariants) {
      if (variant.new && variant.toRemove) {
        variants.splice(variants.indexOf(variant), 1)
      }
    }

    updateOptionsAndVariants(uniqVariants, options)
    this.setState({ showAddEditOptionModal: false })
    this.optionDeletedToast.current = toast(
      <div>
        <span>1 Option & {removed} Variant(s) Removed</span>
        <Button className="ms-2 btn-br-6" variant="secondary" onClick={this.handleUndoDeleteOption}>
          Undo
        </Button>
      </div>
      , { duration: Infinity, style: { maxWidth: 500 } });
  }

  onClickImage = async (media, index) => {
    const { setLoading } = this.props
    setLoading(true)
    return fetch(media.src, {
      mode: 'cors',
      cache: 'no-store',
    }).then(response => response.blob()).then(blob => {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.setState({
          showEditImage: true,
          editImage: reader.result,
          editImageId: media.id || '' ,
          editImageIndex: index,
        })
        setLoading(false)
      }
      reader.onerror = (err) => {
        console.log(err)
        setLoading(false)
      }
      reader.readAsDataURL(blob)
    })
  }

  handleCloseEditImage = () => {
    this.setState({ showEditImage: false })
  }

  handleCropImage = async (image) => {
    const { updateImage, setLoading } = this.props;
    const { editImageId, editImageIndex } = this.state;

    setLoading(true)
    return updateImage(image, editImageId, editImageIndex).then((success) => {
      if (success) {
        this.setState({ showEditImage: false })
      }
      setLoading(false)
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }

  handleOnChangeTitle = (e) => {
    const { updateForm } = this.props;
    const { prefixText, suffixText, actualProductTitle } = this.props;

    if (prefixText.length > 0) {
      e.target.value = `${prefixText} ${e.target.value}`
    }

    if (suffixText.length > 0){
      e.target.value = `${e.target.value} ${suffixText}`
    }

    updateForm(e)
  }


  // handleRemoveVariant = (e) => {
  //   const { id } = e.currentTarget;
  //   const index = id.replace('remove_', '')

  //   const { item, updateOptionsAndVariants } = this.props;
  //   let { variants, options } = item

  //   variants[index].toRemove = true

  //   updateOptionsAndVariants(variants, options)
  // }

  render() {
    const { editorState, showShippingInfo, showAddYTLink, showAddEditOptionModal, editOptionIndex, optionModalMode, 
      showRestoreOptionModal, optionToRestore, permutationError, showEditImage, editImage } = this.state;

    const { user, merchantsOptions, has_variants, item, merchants, pending, sold_out, attributes, closed,
      match, shipping_delivery_included, isLiveProduct, handleDeliveryFinePrintEditorChange, onSavePickupAddress, 
      updateForm, handleOnClickShowReviewModal, onDropImage, handleImageReorder, handleRemoveImage, addHalalCert, 
      updateAttrTags, onClickHasVariants, handleOnClickAddOption, handleOnClickRemoveOption, handleOnClickShowImagesModal, 
      removeVariant, removeTier, addVariant, addTier, updateVariants, updateTiers, handleMarkup, handleBasePrice, 
      uploadingFile, onClickUnarchive, displayProductTitle, actualProductTitle, prefixText, suffixText, 
      matchedTitleDecorations } = this.props; // actions

    const { delivery_fine_prints_html, dpfTemplateAssigned, has_delivery_fine_prints, lead_time_fine_prints_html } = item
    const isAdmin = user.role === 'admin' || user.role === 'staff'

    const showFlowerType = _.includes(['Flower Bouquet', 'Flower Boxes', 'Flower Stand'], item.product_type)
    const isSyncedWithShopify = item.tags && item.tags.includes('sync_shopify')
    const tagsSplit = item.tags.split(', ')

    let markupType, markup
    if (!has_variants) {
      markupType = item.variants[0].metafields.find(m => m.key === 'markup_type')
      markup = item.variants[0].metafields.find(m => m.key === 'markup')
    }

    let foundUnpublish = tagsSplit.find(tag => _.startsWith(tag, 'UNPUB_'))

    let unpublishSplit = []
    if (foundUnpublish) {
      unpublishSplit = foundUnpublish.split('_')
    }

    let variantConditionalTitles = []
    _.each(item.options, (option) => {
      if (option.conditionalFields) {
        _.each(option.conditionalFields, (field) => {
          variantConditionalTitles.push(field.primaryTitle)
        })
      }
    })

    const archived = item.status === 'archived'

    return (
      <>
        <Row>
        <Col lg={6}>
          <Card className="tealCard mt-4 shadow-sm">
            <Card.Header className="tealCardHeader">
              Product Info
              {!_.includes(match.path, 'create') && !_.includes(match.path, 'pending') && user && isAdmin &&
                <a href={`https://${shopifyDomain}/admin/products/${item.id}`} target="_blank" className="pull-right btn btn-secondary btn-br-6 py-0 ms-1">View in Shopify</a>}
              {!_.includes(match.path, 'create') && !_.includes(match.path, 'pending') && item.status === 'active' &&
                <a href={`https://${giftrDomain}/products/${item.handle}`} target="_blank" className="pull-right btn btn-secondary btn-br-6 py-0 ">View Live</a>}
            </Card.Header>
            <Card.Body className="grey-label label-strong">
              <Row className="d-grid gap-3">
                <Col className="d-grid gap-1">
                  <label>Title</label>
                  <input className="form-control" name="title" type="text" placeholder="Title" onChange={this.handleOnChangeTitle} required value={displayProductTitle || item.title} disabled={archived} />
                  {
                    matchedTitleDecorations.length > 0 &&
                    <span className="text-muted small">Actual Product Title: {actualProductTitle}</span>
                  }
                </Col>
                {item.status && item.status === 'archived' &&
                  <Col className="d-grid gap-1">
                    <div className="mb-2">
                      <label>Status</label>
                      <Badge className="mx-3" bg="secondary">ARCHIVED</Badge>
                      <Button size="sm" variant="secondary" onClick={onClickUnarchive}>Unarchive Product</Button>
                    </div>
                    <span className="small">Currently hidden from your product list.</span>
                  </Col>
                }
                {item.status && (item.status === 'active' || item.status === 'draft') &&
                  <Row className="mx-0 px-0">
                    <Col className="d-grid gap-1">
                      <label>Status</label>
                      <Form.Select className="form-control" name="status" onChange={e => updateForm(e)} required value={item.status}>
                        <option value="active">Active</option>
                        <option value="draft">Draft</option>
                      </Form.Select>
                    </Col>
                    { item.status === 'active' && unpublishSplit.length > 0 &&
                      <Col className="d-grid gap-1">
                        <label>Unpublish Date</label>
                        <span>{moment(unpublishSplit[1], 'D-M-YY').format('D MMM YYYY')} {unpublishSplit[2]}</span>
                      </Col>
                    }
                  </Row>
                  }
                {item.status && !(item.status === 'active' || item.status === 'draft' || item.status === 'archived') &&
                  <Col className="d-grid gap-1">
                    <div className="mb-2">
                      <label>Approval Status</label>
                      {item.approval_status === 'create' &&
                        <Badge className="mx-3" bg="primary">CREATE</Badge>}
                      {item.approval_status === 'disapproved' &&
                        <Badge className="mx-3" bg="danger">REJECTED</Badge>}
                      {item.approval_status === 'approved' &&
                        <Badge className="mx-3" bg="success">APPROVED</Badge>}
                      {item.approval_status === 'more_info' &&
                        <Badge className="mx-3" bg="info">MORE INFO</Badge>}
                      {item.approval_status === 'pending' &&
                        <Badge className="mx-3" bg="warning">CREATED</Badge>}
                      {item.approval_status === 'reviewing' &&
                        <Badge className="mx-3" bg="primary">IN REVIEW</Badge>}
                    </div>
                    {isAdmin &&
                      <div>
                        <label>Reviewer</label>
                        <span className="mx-3">{item.approved_by}</span>
                        {pending &&
                          <Button className="mx-3 py-0" variant="secondary" onClick={e => handleOnClickShowReviewModal(e)}>
                            Start to Review
                          </Button>}
                      </div>}
                  </Col>}
                {
                  merchantsOptions && merchantsOptions.length > 1 &&
                  <Col className="d-grid gap-1">
                    <label>Vendor</label>
                    <Form.Select className="form-control" name="vendor" onChange={e => updateForm(e)} required value={item.vendor} disabled={archived}>
                      {
                        isAdmin &&
                          <option value="">Select a vendor</option>
                      }
                      {merchantsOptions.map((option, i) => (
                        <option key={i} value={option.value}>{option.name}</option>
                      ))}
                    </Form.Select>
                  </Col>
                }
                <Col className="d-grid gap-1">
                  <label>Primary Product Type</label>
                  <Form.Select className="form-control" name="product_type" onChange={e => updateForm(e)} required value={item.product_type} disabled={archived}>
                    <option value="">Select a product type</option>
                    {
                      country === 'MY' ?
                        productTypeOptions.map(option => (
                          <option key={option.key} value={option.value}>{option.name}</option>
                        ))
                        :
                        productTypeOptionsSG.map(option => (
                          <option key={option.key} value={option.value}>{option.name}</option>
                        ))
                    }
                  </Form.Select>
                </Col>
                <Col className="d-grid gap-1">
                  <label>Alternative Product Types</label>
                  <Select
                    isOptionDisabled={() => item.alt_product_type.length >= 4}
                    styles={{
                      container: (baseStyles) => ({
                        ...baseStyles,
                        zIndex: "10",
                      })
                    }}
                    value={item.alt_product_type}
                    isMulti
                    onChange={(selected) => updateForm({
                      target: {
                        name: 'alt_product_type',
                        value: selected
                      }
                    })}
                    options={createOptions(country === 'MY' ? productTypeOptions : productTypeOptionsSG)}
                    isDisabled={archived}
                  />
                </Col>
                <Col className="d-grid gap-1">
                  <label>Description</label>
                  <div style={{ border: '1px solid #ced4da', borderRadius: '4px' }}>
                    <Editor
                      readOnly={archived}
                      toolbarCustomButtons={[<ClearFormatButton clearFormat={this.editorClearFormat} />]}
                      onEditorStateChange={this.editorChange}
                      editorState={editorState}
                      editorStyle={{ resize: 'vertical', height: '150px', padding: '0 1rem', background: archived ? '#e9ecef' : '#ffffff' }} />
                  </div>
                </Col>
                {isAdmin &&
                  <Col className="d-grid gap-1">
                    <label>Tags</label>
                    <textarea readOnly name="tags" className="form-control" value={item.tags} onChange={e => updateForm(e)} style={{ height: '70px', resize: 'vertical' }} />
                  </Col>}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Accordion defaultActiveKey="0" className="mt-4">
            <Accordion.Item eventKey="0" className="tealCard shadow-sm">
              <Accordion.Header className="tealCardHeader product-accordion" style={{borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'}}>
                Product Media
              </Accordion.Header>
              <Accordion.Body className="d-grid gap-3">
              { !archived &&
              <Dropzone
                className='dropzone-area'
                onDrop={e => onDropImage(e)}
                multiple={true}
                accept={isLiveProduct ? '.jpeg, .jpg, .png, .mp4, .mov' : '.jpeg, .jpg, .png'}>
                <div className="dropzone-text">
                  <img src={uploadIcon} style={{ width: '30px', margin: '0 10px' }}></img>
                    { uploadingFile ?
                  <span className="white-space-pre-line" style={{ maxWidth: '350px' }}>Uploading...</span>
                  :
                  <span className="white-space-pre-line" style={{ maxWidth: '350px' }}>Drag and drop files here, or click to upload, or click <a href="#" onClick={this.onClickAddYTLink}>here</a> to add YouTube link</span>
                  }
                  { !uploadingFile &&
                  <>
                    <i className="dropzone-info" data-html={true} data-tip={`
                    <div style="text-align: left; max-width: 300px">
                    Image formats allowed: .jpeg and .png, with maximum height/width of 4472 pixels. <br/>Video formats allowed: .mp4 and .mov, with size limit of 50MB.<br/><br/>Note: Video upload only available once product is approved
                    </div>
                `} data-for="media-restriction"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="left" id="media-restriction" />
                  </>
                  }
                </div>
              </Dropzone>
              }
              {item.images && item.images.length > 0 &&
                <Row>
                  {isLiveProduct ?
                      _.map(item.media, (media, i) => {
                        return (
                          <Col xs={6} md={4} xl={3} key={i} className="product-detail-image">
                            { media.preview ?
                              <a onClick={archived ? null : () => this.onClickImage(media)} className="cursor-pointer">
                                <img src={media.preview} />
                              </a>
                              :
                              <div className="text-center border border-2 rounded-3 d-flex flex-column align-items-center" style={{padding: '15px 10px'}}>
                                <img src={media_placeholder} style={{width: '50%'}} />
                                <span className="small text-muted" style={{lineHeight: '1.2rem'}}>Processing media. Refresh after a while to load preview</span>
                              </div>
                            }
                            { !archived &&
                            <div style={{ visibility: 'hidden' }} className="d-flex">
                              <Button variant="secondary" size="small" onClick={() => handleImageReorder(i, -1)}>
                                <FontAwesomeIcon icon={solid('arrow-left')} />
                              </Button>
                              <Button className="ms-2" variant="secondary" size="small" onClick={() => handleImageReorder(i, 1)}>
                                <FontAwesomeIcon icon={solid('arrow-right')} />
                              </Button>
                              <Button onClick={() => handleRemoveImage(i)} variant="danger" size="small" className="ms-auto">
                                <FontAwesomeIcon icon={solid('close')} />
                              </Button>
                            </div>
                            }
                          </Col>
                        )
                      })
                      :
                      _.map(item.images, (image, i) => {
                        return (
                          <Col xs={6} md={4} xl={3} key={i} className="product-detail-image">
                            <a onClick={archived ? null : () => this.onClickImage(image, i)} className="cursor-pointer">
                              <img src={image.isYoutube ? image.thumbnail : image.src} />
                            </a>
                            { !archived &&
                            <div style={{ visibility: 'hidden' }} className="d-flex">
                              <Button variant="secondary" size="small" onClick={() => handleImageReorder(i, -1)}>
                                <FontAwesomeIcon icon={solid('arrow-left')} />
                              </Button>
                              <Button className="ms-2" variant="secondary" size="small" onClick={() => handleImageReorder(i, 1)}>
                                <FontAwesomeIcon icon={solid('arrow-right')} />
                              </Button>
                              <Button onClick={() => handleRemoveImage(i)} variant="danger" size="small" className="ms-auto">
                                <FontAwesomeIcon icon={solid('close')} />
                              </Button>
                            </div>
                            }
                          </Col>
                        )
                      })
                  }
                </Row>}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="0" className="mt-4">
            <Accordion.Item eventKey="0" className="tealCard shadow-sm">
              <Accordion.Header className="tealCardHeader product-accordion" style={{borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'}}>
                Tools & Misc.
              </Accordion.Header>
              <Accordion.Body className="d-grid gap-3">
                  <Row>
                    <Col sm={6} className="product-detail-image">
                      <div className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                        <label className='custom-checkbox'>
                          <input type="checkbox" name="enable_addons" onChange={e => updateForm(e)} checked={item.enable_addons} value="1" disabled={archived}/>
                          <span className="checkmark checkmark-smaller"></span>
                        </label>
                        <label style={{fontWeight: '200'}}>Enable Product Add-ons</label>
                      </div>
                      { isAdmin &&
                      <>
                        <div className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                          <label className='custom-checkbox'>
                            <input type="checkbox" name="enable_vm" onChange={e => updateForm(e)} checked={item.enable_vm} value="1" disabled={archived}/>
                            <span className="checkmark checkmark-smaller"></span>
                          </label>
                          <label style={{fontWeight: '200'}}>Virtual Message</label>
                        </div>
                        <div className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                          <label className='custom-checkbox'>
                            <input type="checkbox" name="enable_qna" onChange={e => updateForm(e)} checked={item.enable_qna} value="1" disabled={archived}/>
                            <span className="checkmark checkmark-smaller"></span>
                          </label>
                          <label style={{fontWeight: '200'}}>Q&A</label>
                        </div>
                        <div className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                          <label className='custom-checkbox'>
                            <input type="checkbox" name="exclude_free_message" onChange={e => updateForm(e)} checked={item.exclude_free_message} value="1" disabled={archived}/>
                            <span className="checkmark checkmark-smaller"></span>
                          </label>
                          <label style={{fontWeight: '200'}}>Exclude Free Message</label>
                        </div>
                      </>
                      }
                    </Col>
                    { isAdmin &&
                    <Col sm={6}>
                      <div className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                        <label className='custom-checkbox'>
                          <input type="checkbox" name="remove_pmg" onChange={e => updateForm(e)} checked={item.remove_pmg} value="1" disabled={archived}/>
                          <span className="checkmark checkmark-smaller"></span>
                        </label>
                        <label style={{fontWeight: '200'}}>Markup (No PMG)</label>
                      </div>
                      <div className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                        <label className='custom-checkbox'>
                          <input type="checkbox" name="enable_postcode" onChange={e => updateForm(e)} checked={item.enable_postcode} value="1" disabled={archived}/>
                          <span className="checkmark checkmark-smaller"></span>
                        </label>
                        <label style={{fontWeight: '200'}}>Postcode Checker</label>
                      </div>
                      <div className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                        <label className='custom-checkbox'>
                          <input type="checkbox" name="corporate" onChange={updateForm} checked={item.corporate} value="1" disabled={archived}/>
                          <span className="checkmark checkmark-smaller"></span>
                        </label>
                        <label>Bulk Order</label>
                      </div>
                    </Col>
                    }
                  </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
        <ProductAttributes
          matchedTitleDecorations={matchedTitleDecorations}
          actualProductTitle={actualProductTitle}
          vendor={item.vendor}
          vendorTools={_.find(merchants, { name: item.vendor })}
          hasDFPTemplates={has_delivery_fine_prints}
          handleDeliveryFinePrintEditorChange={handleDeliveryFinePrintEditorChange}
          dfpHtml={delivery_fine_prints_html}
          dpfTemplateAssigned={dpfTemplateAssigned}
          attr={attributes}
          addHalalCert={e => addHalalCert(e)}
          selectFlowerType={showFlowerType}
          updateAttrTags={updateAttrTags}
          closed={closed}
          isAdmin={isAdmin}
          corporate={item.corporate}
          ltfpHtml={lead_time_fine_prints_html}
          onSavePickupAddress={onSavePickupAddress}
          hasSelfVariant={_.some(item.variants, variant => {
            let title = variant.option1 + variant.option2 + variant.option3

            return title && _.toLower(title).includes("pick-up") || _.toLower(title).includes("pickup") || _.toLower(title).includes("pick up") || _.toLower(title).includes("self")
          })}
          variantConditionalTitles={variantConditionalTitles}
          archived={archived}
        />
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0" className="tealCard shadow-sm">
            <Accordion.Header className="tealCardHeader product-accordion" style={{borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0'}}>
              { item.corporate ? 'Tiers' : 'Variants' }
            </Accordion.Header>
            <Accordion.Body className="d-grid gap-3">
              { item.corporate ?
                <>
                  <Row className="d-flex align-items-center">
                    <Col xs="auto"><label>Single Unit Compare At Price ({currency})</label></Col>
                    <Col xs={3}>
                      <input className="form-control" name="corporate_compare_at_price" onChange={updateForm} value={item.corporate_compare_at_price || ''} required type="number" step=".01" disabled={archived}/>
                    </Col>
                  </Row>
                  <Row className='table-responsive mx-1'>
                    <table className="table" style={{ fontSize: '0.9em' }}>
                      <thead>
                        <tr>
                          <th width="70">Tier</th>
                          <th width="90">Min. Quantity</th>
                          <th width="90">Max. Quantity</th>
                          <th width="230">Pricing ({currency})</th>
                          <th width="230">Inventory <i data-html={true} data-tip={`
                    <p>
                      <u><b>How to Edit Inventory</b></u><br/>
                      Variant Sold Out:<br/>
                      1. Check "Sold Out"<br/><br/>
                      Variant Available in Limited Quantity:<br/>
                      1. Check "Quantity Tracked"<br/>
                      2. Set "Quantity Available" to available amount<br/><br/>
                      Variant Available (No Limited Quantity):<br/>
                      1. Uncheck "Quantity Tracked"
                    </p>
                `} data-for="inventory-header"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="top" id="inventory-header" /></th>
                          <th width="230">
                            Shipping ({currency}) <i data-html={true} data-tip={`
                    <p>Single Location - Shipping fee required to ship to single destination in bulk.</p>
                    <p>Multiple Locations - Shipping fee per location to ship separate destinations.</p>
                `} data-for="shipping-header"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="top" id="shipping-header" />
                          </th>
                          <th width="78">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.variants.map((variant, i) => {
                          let markup = variant.metafields.find(m => m.key === 'markup')
                          let markupType = variant.metafields.find(m => m.key === 'markup_type')

                          let minQty, maxQty
                          if (item.corporate) {
                            const optionSplit = variant.option1.split(' - ')
                            minQty = optionSplit[0] || ''
                            maxQty = optionSplit[1] || ''
                          }

                          return (
                            <tr key={i} className={`variant-details ${variant.toRemove ? 'bg-default' : ''}`}>
                              <td>{i + 1}</td>
                              <td>
                                <Col className="ps-0"><input className="form-control" name={`min_qty#${i}`} onChange={updateTiers} value={minQty || ''} required type="number" step="1" disabled={archived}/></Col>
                              </td>
                              <td>
                                <Col className="ps-0"><input className="form-control" name={`max_qty#${i}`} onChange={updateTiers} value={maxQty || ''} required type="number" step="1" disabled={archived}/></Col>
                              </td>
                              <td>
                                <Row className="mb-2">
                                  <Col xs={6}><label>Price</label></Col>
                                  <Col xs={6} className="ps-0"><input className="form-control" name={`base_price#${i}`} type="number" step=".01" placeholder="Unit Price" onChange={e => handleBasePrice(e)} required value={variant.base_price} disabled={archived}/></Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col xs={6}><label>Compare at price</label></Col>
                                  <Col xs={6} className="ps-0"><input className="form-control" name={`compare_at_price#${i}`} type="number" step=".01" onChange={e => updateVariants(e)} value={variant.compare_at_price || ''} disabled={archived}/></Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col xs={6}><label>Markup Type</label>
                                  </Col>
                                  <Col xs={6} className="ps-0">
                                    <Form.Select className="form-control" name={`markup_type#${i}`} onChange={isAdmin ? (e => handleMarkup(e)) : undefined} required value={markupType && markupType.value} disabled={!isAdmin || archived}>
                                      <option value="fixed">Fixed</option>
                                      <option value="percentage">Percentage</option>
                                    </Form.Select>
                                  </Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col xs={6}><label>Markup</label>
                                  </Col>
                                  <Col xs={6} className="ps-0"><input className="form-control" name={`markup#${i}`} type="number" step=".01" onChange={isAdmin ? (e => handleMarkup(e)) : undefined} value={markup && markup.value} disabled={!isAdmin || archived} /></Col>
                                </Row>
                                <Row className="shipping-lines">
                                  <Col className='text-end'>
                                    Actual Selling Price
                                    { markupType && markupType.value === 'percentage' &&
                                      <>
                                        <i data-html={true} data-tip={`<div style="max-width: 500px; text-align: left">Round-up applied on 2nd decimal point to standardize the pricing display and easier consideration for customer's purchase decision.<br/>E.g.: RM99.32 will be rounded up to RM99.40</div>`} data-for="markup-info" className="ms-1"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="top" id="markup-info" />
                                      </>
                                    }
                                  </Col>
                                  <Col>{currency} {parseFloat(item.variants[i].price).toFixed(2)}</Col>
                                </Row>
                                {
                                  !_.includes(match.path, 'create') && !_.includes(match.path, 'pending') && isAdmin &&
                                    <a href={`https://${shopifyDomain}/admin/products/${item.id}/variants/${item.variants[i].id}/metafields`} target="_blank" className="d-block btn btn-secondary btn-br-6 py-0 ">View Markup in Shopify</a>
                                }
                                { variant.showLimit &&
                                  <p className="text-danger mt-1">Our platform's maximum transaction limit is {currency}5000. Please adjust the pricing, quantity, or shipping to meet this limit.</p>
                                }
                              </td>
                              <td>
                                <Row className="mb-2">
                                  <Col xs={6}><label>SKU</label></Col>
                                  <Col xs={6} className="ps-0"><input className="form-control" name={`sku#${i}`} type="text" onChange={e => updateVariants(e)} value={variant.sku || ''} disabled={archived}/></Col>
                                </Row>
                                {
                                  isSyncedWithShopify &&
                                  <Row>
                                    <Col xs={6}><label>Continue selling when out of stock</label></Col>
                                    <Col xs={6} className="ps-0">
                                      <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                        <input type="checkbox" onChange={e => updateVariants(e)} name={`inventory_policy#${i}`} checked={variant.inventory_policy == 'continue' ? true : false} disabled={archived}/>
                                        <span className="checkmark checkmark-smaller"></span>
                                      </label>
                                    </Col>
                                  </Row>
                                }
                                <Row>
                                  <Col xs={6}><label>Sold Out</label></Col>
                                  <Col xs={6} className="ps-0">
                                    <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                      <input type="checkbox" onChange={e => updateVariants(e)} name={`soldout#${i}`} checked={sold_out[i]} disabled={archived}/>
                                      <span className="checkmark checkmark-smaller"></span>
                                    </label>
                                  </Col>
                                </Row>
                                {!sold_out[i] &&
                                <>
                                  <Row>
                                    <Col xs={6}><label>Quantity Tracked</label></Col>
                                    <Col xs={6} className="ps-0">
                                      <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                        <input onChange={e => updateVariants(e)} name={`inventory_management#${i}`} type="checkbox" style={{ marginRight: "5px" }} checked={variant.inventory_management == 'shopify' ? true : false} disabled={archived}/>
                                        <span className="checkmark checkmark-smaller"></span>
                                      </label>
                                    </Col>
                                  </Row>
                                  {variant && variant.inventory_management &&
                                  <Row>
                                    <Col xs={6}><label>Quantity Available</label></Col>
                                    <Col xs={6} className="ps-0"><input className="form-control" name={`inventory_quantity#${i}`} type="number" placeholder="Quantity Available" onChange={e => updateVariants(e)} value={variant.inventory_quantity} disabled={archived}/></Col>
                                  </Row>}
                                </>}
                              </td>
                              <td>
                                { country === 'MY' ?
                                  <>
                                    <strong className="text-decoration-underline">West Malaysia</strong>
                                    <Row className="mb-2">
                                      <Col xs={6}><label>Single Location</label></Col>
                                      <Col xs={6} className="ps-0"><input className="form-control" name={`west_single#${i}`} onChange={updateTiers} value={variant.shipping && variant.shipping.west_single || ''} type="number" step=".01" disabled={archived}/></Col>
                                    </Row>
                                    <Row className="mb-2">
                                      <Col xs={6}><label>Multiple Locations</label></Col>
                                      <Col xs={6} className="ps-0"><input className="form-control" name={`west_multi#${i}`} onChange={updateTiers} value={variant.shipping && variant.shipping.west_multi || ''} type="number" step=".01" disabled={archived}/></Col>
                                    </Row>
                                    <Row className="mb-2">
                                      <Col><label>Lead Time (Working Days)</label></Col>
                                    </Row>
                                    <Row className="mb-2">
                                      <Col className="d-flex gap-2 align-items-center"><input className="form-control d-inline-block" name={`west_lead_min#${i}`} onChange={updateTiers} value={variant.lead_time && variant.lead_time.west_lead_min || ''} type="number" step="1" /> to <input className="form-control d-inline-block" name={`west_lead_max#${i}`} onChange={updateTiers} value={variant.lead_time && variant.lead_time.west_lead_max || ''} type="number" step="1" disabled={archived}/> days</Col>
                                    </Row>
                                    <strong className="mt-2 d-block text-decoration-underline">East Malaysia (Optional)</strong>
                                    <Row className="mb-2">
                                      <Col xs={6}><label>Single Location</label></Col>
                                      <Col xs={6} className="ps-0"><input className="form-control" name={`east_single#${i}`} onChange={updateTiers} value={variant.shipping && variant.shipping.east_single || ''} type="number" step=".01" disabled={archived}/></Col>
                                    </Row>
                                    <Row className="mb-2">
                                      <Col xs={6}><label>Multiple Locations</label></Col>
                                      <Col xs={6} className="ps-0"><input className="form-control" name={`east_multi#${i}`} onChange={updateTiers} value={variant.shipping && variant.shipping.east_multi || ''} type="number" step=".01" disabled={archived}/></Col>
                                    </Row>
                                    <Row className="mb-2">
                                      <Col><label>Lead Time (Working Days)</label></Col>
                                    </Row>
                                    <Row className="mb-2">
                                      <Col className="d-flex gap-2 align-items-center"><input className="form-control d-inline-block" name={`east_lead_min#${i}`} onChange={updateTiers} value={variant.lead_time && variant.lead_time.east_lead_min || ''} type="number" step="1" disabled={archived}/> to <input className="form-control d-inline-block" name={`east_lead_max#${i}`} onChange={updateTiers} value={variant.lead_time && variant.lead_time.east_lead_max || ''} type="number" step="1" disabled={archived}/> days</Col>
                                    </Row>
                                  </>
                                  :
                                  <>
                                    <Row className="mb-2">
                                      <Col xs={6}><label>Single Location</label></Col>
                                      <Col xs={6} className="ps-0"><input className="form-control" name={`single#${i}`} onChange={updateTiers} value={variant.shipping && variant.shipping.single || ''} type="number" step=".01" disabled={archived}/></Col>
                                    </Row>
                                    <Row className="mb-2">
                                      <Col xs={6}><label>Multiple Locations</label></Col>
                                      <Col xs={6} className="ps-0"><input className="form-control" name={`multiple#${i}`} onChange={updateTiers} value={variant.shipping && variant.shipping.multiple || ''} type="number" step=".01" disabled={archived}/></Col>
                                    </Row>
                                    <Row className="mb-2">
                                      <Col><label>Lead Time (Working Days)</label></Col>
                                    </Row>
                                    <Row className="mb-2">
                                      <Col className="d-flex gap-2 align-items-center"><input className="form-control d-inline-block" name={`lead_min#${i}`} onChange={updateTiers} value={variant.lead_time && variant.lead_time.lead_min || ''} type="number" step="1" disabled={archived}/> to <input className="form-control d-inline-block" name={`lead_max#${i}`} onChange={updateTiers} value={variant.lead_time && variant.lead_time.lead_max || ''} type="number" step="1" disabled={archived}/> days</Col>
                                    </Row>
                                  </>
                                }
                              </td>
                              <td>
                                <Button className="px-2 py-0" variant="danger" id={`remove_${i}`} onClick={e => removeTier(e)}><FontAwesomeIcon icon={solid('close')} disabled={archived}/></Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Row>
                  <Button className="shadow-sm" variant="primary" onClick={archived ? () => {} : addTier}>Add Tier&nbsp;<FontAwesomeIcon icon={solid('plus')} transform="shrink-1" className="mx-2" /></Button>
                </>
                :
              <>
                <Row className="hasMultipleVariants">
                  <Col xs="auto"><label htmlFor='has_variants'>Has Multiple Variants</label></Col>
                  <Col xs={1}>
                    <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                      <input onChange={e => onClickHasVariants(e)} name="has_variants" type="checkbox" style={{ marginLeft: '1.1em' }} checked={has_variants} disabled={archived}/>
                      <span className="checkmark checkmark-smaller"></span>
                    </label>
                  </Col>
                </Row>
                <div>
                  { has_variants &&
                  <>
                    <div className="d-flex justify-content-between mb-2">
                      <label>Options</label>
                      {
                        item.options.length < 3 && !archived &&
                          <Button onClick={this.onClickAddOption} className="py-0 px-2" variant="secondary">
                            <FontAwesomeIcon icon={solid('plus')} className="mx-1"/>
                            &nbsp;Add another option
                          </Button>
                      }
                    </div>
                    <ListGroup className="mb-2">
                      { item.options && item.options.map((option, i) => (
                        <ListGroup.Item key={i} className="d-flex justify-content-between align-items-center">
                            <div className="d-flex flex-column gap-1">
                            { option.name }
                              <div className="d-flex gap-2 flex-wrap">
                            { option.values.map((value, j) => (
                              <Badge size="sm" bg="secondary" key={j}>{value}</Badge>
                            ))}
                              </div>
                              { option.conditionalFields && option.conditionalFields.length > 0 &&
                              <Badge size="sm" bg="primary" className="align-self-start">{option.conditionalFields.length} Conditional Field{option.conditionalFields.length > 1 ? 's' : ''}</Badge>
                              }
                            </div>
                          { !archived &&
                          <Button onClick={() => this.onClickEditOption(option.position)} variant="secondary">Edit</Button>
                          }
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </>
                  }
                </div>
                {has_variants ?
                  <>
                    <Row className='table-responsive mx-1'>
                      <table className="table" style={{ fontSize: '0.9em' }}>
                        <thead>
                          <tr>
                            <th width="70">Variant</th>
                            <th width="70">Image</th>
                            <th width="160">Options</th>
                            <th width="230">Pricing ({currency})</th>
                            <th width="230">Inventory <i data-html={true} data-tip={`
                    <p>
                      <u><b>How to Edit Inventory</b></u><br/>
                      Variant Sold Out:<br/>
                      1. Check "Sold Out"<br/><br/>
                      Variant Available in Limited Quantity:<br/>
                      1. Check "Quantity Tracked"<br/>
                      2. Set "Quantity Available" to available amount<br/><br/>
                      Variant Available (No Limited Quantity):<br/>
                      1. Uncheck "Quantity Tracked"
                    </p>
                `} data-for="inventory-header"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="top" id="inventory-header" /></th>
                            <th width="150">
                              Shipping <FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf', textAlign: 'left', cursor: 'pointer' }} onClick={this.handleOnClickShowShipping} />
                            </th>
                            <th width="70"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.variants.map((variant, i) => {
                            let markup = variant.metafields.find(m => m.key === 'markup')
                            let markupType = variant.metafields.find(m => m.key === 'markup_type')

                            return (
                              <tr key={i} className={`variant-details ${variant.toRemove ? 'bg-default' : ''}`}>
                                <td>
                                  {i + 1}
                                  <div className="d-flex gap-1">
                                    {variant.new && <Badge className="d-block" bg="success" size="sm">New</Badge>}
                                  </div>
                                </td>
                                <td>
                                  {(variant.selected_image && variant.selected_image.src) ?
                                    <img onClick={archived ? () => {} : () => handleOnClickShowImagesModal(i)} style={{ maxWidth: '60px', width: 'auto', height: 'auto', cursor: 'pointer' }} src={variant.selected_image.src} />
                                    :
                                      variant.image_id && item.images.find(img => img.id == variant.image_id) ?
                                        <img onClick={archived ? () => {} : () => handleOnClickShowImagesModal(i)} style={{ maxWidth: '60px', width: 'auto', height: 'auto', cursor: 'pointer' }} src={item.images.find(img => img.id == variant.image_id).src} />
                                        :
                                        <Button variant="secondary" className="py-0 px-1" onClick={archived ? () => {} : () => handleOnClickShowImagesModal(i)}><FontAwesomeIcon icon={solid('plus')} /></Button>}
                                </td>
                                <td>
                                  {variant.option1}
                                  {variant.option2 ? ' / ' + variant.option2 : ''}
                                  {variant.option3 ? ' / ' + variant.option3 : ''}
                                </td>
                                <td>
                                  <Row className="mb-2">
                                    <Col xs={6}><label>Price</label></Col>
                                    <Col xs={6} className="ps-0"><input className="form-control" name={`base_price#${i}`} type="number" step=".01" placeholder="Unit Price" onChange={e => handleBasePrice(e)} required value={variant.base_price} disabled={archived}/></Col>
                                  </Row>
                                  <Row className="mb-2">
                                    <Col xs={6}><label>Compare at price</label></Col>
                                    <Col xs={6} className="ps-0"><input className="form-control" name={`compare_at_price#${i}`} type="number" step=".01" onChange={e => updateVariants(e)} value={variant.compare_at_price || ''} disabled={archived}/></Col>
                                  </Row>
                                  <Row className="mb-2">
                                    <Col xs={6}><label>Markup Type</label>
                                    </Col>
                                    <Col xs={6} className="ps-0">
                                      <Form.Select className="form-control" name={`markup_type#${i}`} onChange={isAdmin ? (e => handleMarkup(e)) : undefined} required value={markupType && markupType.value} disabled={!isAdmin || archived}>
                                        <option value="fixed">Fixed</option>
                                        <option value="percentage">Percentage</option>
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                  <Row className="mb-2">
                                    <Col xs={6}><label>Markup</label>
                                    </Col>
                                    <Col xs={6} className="ps-0"><input className="form-control" name={`markup#${i}`} type="number" step=".01" onChange={isAdmin ? (e => handleMarkup(e)) : undefined} value={markup && markup.value} disabled={!isAdmin || archived} /></Col>
                                  </Row>
                                  <Row className="shipping-lines">
                                    <Col className='text-end'>
                                      Actual Selling Price
                                      { markupType && markupType.value === 'percentage' &&
                                        <>
                                          <i data-html={true} data-tip={`<div style="max-width: 500px; text-align: left">Round-up applied on 2nd decimal point to standardize the pricing display and easier consideration for customer's purchase decision.<br/>E.g.: RM99.32 will be rounded up to RM99.40</div>`} data-for="markup-info" className="ms-1"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="top" id="markup-info" />
                                        </>
                                      }
                                    </Col>
                                    <Col>{currency} {parseFloat(item.variants[i].price).toFixed(2)}</Col>
                                  </Row>
                                  {
                                    !_.includes(match.path, 'create') && !_.includes(match.path, 'pending') && isAdmin &&
                                      <a href={`https://${shopifyDomain}/admin/products/${item.id}/variants/${item.variants[i].id}/metafields`} target="_blank" className="d-block btn btn-secondary btn-br-6 py-0 ">View Markup in Shopify</a>
                                  }
                                </td>
                                <td>
                                  <Row className="mb-2">
                                    <Col xs={6}><label>SKU</label></Col>
                                    <Col xs={6} className="ps-0"><input className="form-control" name={`sku#${i}`} type="text" onChange={e => updateVariants(e)} value={variant.sku || ''} disabled={archived}/></Col>
                                  </Row>
                                  {
                                    isSyncedWithShopify &&
                                    <Row>
                                      <Col xs={6}><label>Continue selling when out of stock</label></Col>
                                      <Col xs={6} className="ps-0">
                                        <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                          <input type="checkbox" onChange={e => updateVariants(e)} name={`inventory_policy#${i}`} checked={variant.inventory_policy == 'continue' ? true : false} disabled={archived}/>
                                          <span className="checkmark checkmark-smaller"></span>
                                        </label>
                                      </Col>
                                    </Row>
                                  }
                                  <Row>
                                    <Col xs={6}><label>Sold Out</label></Col>
                                    <Col xs={6} className="ps-0">
                                      <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                        <input type="checkbox" onChange={e => updateVariants(e)} name={`soldout#${i}`} checked={sold_out[i]} disabled={archived}/>
                                        <span className="checkmark checkmark-smaller"></span>
                                      </label>
                                    </Col>
                                  </Row>
                                  {!sold_out[i] &&
                                    <>
                                      <Row>
                                        <Col xs={6}><label>Quantity Tracked</label></Col>
                                        <Col xs={6} className="ps-0">
                                          <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                            <input onChange={e => updateVariants(e)} name={`inventory_management#${i}`} type="checkbox" style={{ marginRight: "5px" }} checked={variant.inventory_management == 'shopify' ? true : false} disabled={archived}/>
                                            <span className="checkmark checkmark-smaller"></span>
                                          </label>
                                        </Col>
                                      </Row>
                                      {variant && variant.inventory_management &&
                                        <Row>
                                          <Col xs={6}><label>Quantity Available</label></Col>
                                          <Col xs={6} className="ps-0"><input className="form-control" name={`inventory_quantity#${i}`} type="number" placeholder="Quantity Available" onChange={e => updateVariants(e)} value={variant.inventory_quantity} disabled={archived}/></Col>
                                        </Row>}
                                    </>}
                                </td>
                                <td>
                                  <div className="d-grid gap-1">
                                  {attributes.self &&
                                  <Row>
                                    <Col xs={6}><label>Shipping Not Required</label></Col>
                                    <Col xs={6} className="ps-0">
                                      <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                        <input onChange={e => updateVariants(e)} name={`requires_shipping#${i}`} type="checkbox" style={{ marginRight: "5px" }} checked={!variant.requires_shipping} disabled={archived}/>
                                        <span className="checkmark checkmark-smaller"></span>
                                      </label>
                                    </Col>
                                  </Row>
                                  }
                                  {(!attributes.self || variant.requires_shipping) &&
                                  <>
                                  <Row>
                                    <Col xs={6}><label>Delivery Included in Selling Price</label></Col>
                                    <Col xs={6} className="ps-0">
                                      <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                        <input onChange={e => updateVariants(e)} name={`shipping_delivery_included#${i}`} type="checkbox" style={{ marginRight: "5px" }} checked={shipping_delivery_included[i]} disabled={archived}/>
                                        <span className="checkmark checkmark-smaller"></span>
                                      </label>
                                    </Col>
                                  </Row>
                                  {!shipping_delivery_included[i] && variant && variant.weight !== undefined &&
                                    <Row>
                                      <Col xs={6}><label>Weight (kg)</label></Col>
                                      <Col xs={6}><input className="form-control" name={`weight#${i}`} type="number" onChange={e => updateVariants(e)} value={variant.weight} required min={this.getWeightMin(i) || ''} step="any" onFocus={e => e.target.select()} disabled={archived}/></Col>
                                    </Row>}
                                  </>
                                  }
                                  </div>
                                </td>
                                <td>
                                  {variant.toRemove &&
                                  <div className="d-flex gap-1">
                                    <Badge className="d-block lh-base" bg="danger" size="sm">Removing</Badge>
                                    { !variant.duplicate &&
                                    <Button size="sm" variant="secondary" className="py-0 px-1" onClick={archived ? () => {} : () => this.onClickUndoRemoveVariant(variant.option1, variant.option2, variant.option3, i)}><FontAwesomeIcon icon={solid('undo')} /></Button>
                                    }
                                  </div>
                                  }
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Row>
                  </>
                  : // INFO: above: multi variant; below: single variant
                  <Row>
                    <Col lg={4}>
                      <Card className="greyCard grey-label label-strong my-2">
                        <Card.Header className="greyCardHeader">Pricing</Card.Header>
                        <Card.Body className="d-grid gap-2">
                          <Row>
                            <Col xs={6} className="text-end"><label>Price</label></Col>
                            <Col xs={6}><input className="form-control" name="base_price#0" type="number" step=".01" placeholder="Unit Price" onChange={e => handleBasePrice(e)} required value={item.variants[0].base_price} disabled={archived}/></Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="text-end ps-0"><label>Compare at price</label></Col>
                            <Col xs={6}><input className="form-control" name="compare_at_price#0" type="number" step=".01" onChange={e => updateVariants(e)} value={item.variants[0] && item.variants[0].compare_at_price || ''} disabled={archived}/></Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="text-end ps-0"><label>Markup Type</label>
                            </Col>
                            <Col xs={6}>
                              <Form.Select className="form-control" name={`markup_type#0`} onChange={isAdmin ? (e => handleMarkup(e)) : undefined} required value={item.variants[0].metafields.find(m => m.key === 'markup_type') && item.variants[0].metafields.find(m => m.key === 'markup_type').value} disabled={!isAdmin || archived}>
                                <option value="fixed">Fixed</option>
                                <option value="percentage">Percentage</option>
                              </Form.Select>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={6} className="text-end ps-0"><label>Markup</label>
                            </Col>
                            <Col xs={6}><input className="form-control" name={`markup#0`} type="number" step=".01" onChange={isAdmin ? (e => handleMarkup(e)) : undefined} value={item.variants[0].metafields.find(m => m.key === 'markup') && item.variants[0].metafields.find(m => m.key === 'markup').value} disabled={!isAdmin || archived}/></Col>
                          </Row>
                          <Row className="shipping-lines">
                            <Col className='text-end'>
                              Actual Selling Price
                              { markupType && markupType.value === 'percentage' &&
                                <>
                                  <i data-html={true} data-tip={`<div style="max-width: 500px; text-align: left">Round-up applied on 2nd decimal point to standardize the pricing display and easier consideration for customer's purchase decision.<br/>E.g.: RM99.32 will be rounded up to RM99.40</div>`} data-for="markup-info" className="ms-1"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="top" id="markup-info" />
                                </>
                              }
                            </Col>
                            <Col>{currency} {parseFloat(item.variants[0].price).toFixed(2)}</Col>
                          </Row>
                          {
                            !_.includes(match.path, 'create') && !_.includes(match.path, 'pending') && isAdmin &&
                              <a href={`https://${shopifyDomain}/admin/products/${item.id}/variants/${item.variants[0].id}/metafields`} target="_blank" className="d-block btn btn-secondary btn-br-6 py-0 ">View Markup in Shopify</a>
                          }
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card className="greyCard grey-label label-strong my-2">
                        <Card.Header className="greyCardHeader">Inventory</Card.Header>
                        <Card.Body className="d-grid gap-2">
                          <Row>
                            <Col xs={6} className="text-end"><label>SKU</label></Col>
                            <Col xs={6}><input className="form-control" name={`sku#0`} type="text" onChange={e => updateVariants(e)} value={item.variants[0] && item.variants[0].sku || ''} disabled={archived}/></Col>
                          </Row>
                          {
                            isSyncedWithShopify &&
                            <Row>
                              <Col xs={6}><label>Continue selling when out of stock</label></Col>
                              <Col xs={6} className="ps-0">
                                <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                  <input type="checkbox" onChange={e => updateVariants(e)} name={`inventory_policy#0`} checked={(item.variants[0] && item.variants[0].inventory_policy === 'continue') ? true : false} disabled={archived}/>
                                  <span className="checkmark checkmark-smaller"></span>
                                </label>
                              </Col>
                            </Row>
                          }
                          <Row>
                            <Col xs={6} className="text-end"><label>Sold Out <i data-html={true} data-tip={`
                        <p style="text-align: left">
                          <u><b>How to Edit Inventory</b></u><br/>
                          Variant Sold Out:<br/>
                          1. Check "Sold Out"<br/><br/>
                          Variant Available in Limited Quantity:<br/>
                          1. Check "Quantity Tracked"<br/>
                          2. Set "Quantity Available" to available amount<br/><br/>
                          Variant Available (No Limited Quantity):<br/>
                          1. Uncheck "Quantity Tracked"
                        </p>`} data-for="sold-out-header"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="top" id="sold-out-header" />
                            </label></Col>
                            <Col xs={6}>
                              <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                <input type="checkbox" onChange={e => updateVariants(e)} name={`soldout#0`} checked={sold_out[0]} disabled={archived}/>
                                <span className="checkmark checkmark-smaller"></span>
                              </label>
                            </Col>
                          </Row>
                          {!sold_out[0] &&
                            <Row>
                              <Col xs={6} className="text-end ps-0"><label>Quantity Tracked</label></Col>
                              <Col xs={6}>
                                <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                  <input onChange={e => updateVariants(e)} name="inventory_management#0" type="checkbox" style={{ marginRight: "5px" }} checked={(item.variants[0] && item.variants[0].inventory_management === 'shopify') ? true : false} disabled={archived}/>
                                  <span className="checkmark checkmark-smaller"></span>
                                </label>
                              </Col>
                            </Row>}
                          {!sold_out[0] &&
                            <>
                              {item.variants[0] && item.variants[0].inventory_management &&
                                <Row>
                                  <Col xs={6} className="text-end p-0"><label>Quantity Available</label></Col>
                                  <Col xs={6}><input className="form-control" name="inventory_quantity#0" type="text" placeholder="Quantity Available" onChange={e => updateVariants(e)} value={item.variants[0] && item.variants[0].inventory_quantity} disabled={archived}/></Col>
                                </Row>}
                            </>}
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card className="greyCard grey-label label-strong my-2">
                        <Card.Header className="greyCardHeader">Shipping</Card.Header>
                        <Card.Body className="d-grid gap-2">
                          <Row>
                            <Col xs={6} className="text-end ps-0"><label>Delivery Included in Selling Price <FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf', textAlign: 'left', cursor: 'pointer' }} onClick={this.handleOnClickShowShipping} /></label></Col>
                            <Col xs={6}>
                              <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                                <input onChange={e => updateVariants(e)} name={`shipping_delivery_included#0`} type="checkbox" style={{ marginRight: "5px" }} checked={shipping_delivery_included[0]} disabled={archived}/>
                                <span className="checkmark checkmark-smaller"></span>
                              </label>
                            </Col>
                          </Row>
                          {!shipping_delivery_included[0] && item.variants[0] && item.variants[0].weight !== undefined &&
                            <Row>
                              <Col xs={6} className="text-end"><label>Weight (kg)</label></Col>
                              <Col xs={6}><input className="form-control" name={`weight#0`} type="number" onChange={e => updateVariants(e)} value={item.variants[0].weight} required min={this.getWeightMin(0) || ''} step="any" onFocus={e => e.target.select()} disabled={archived}/></Col>
                            </Row>}
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>}
              </>
              }
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {
          showShippingInfo &&
            <InfoModal onClose={this.handleOnCloseShipping} title="How to Edit Shipping" size="lg">
              <p>
                1. For self pick-up option or shipping fee is included in the product price, check "Delivery Included in Selling Price".<br/>
              </p>
              <p>
                2. If shipping fee is not included, uncheck "Delivery Included in Selling Price" and state the item weight in kilograms. Refer to standard shipping rate as below:
              </p>
              {
                country === 'MY' &&
                  <ToolkitProvider
                    keyField='weight'
                    data={shippingInfo}
                    columns={shippingCol}
                    exportCSV={{
                      exportAll: false
                    }}
                    search
                  >
                    {
                      props => (
                        <>
                          <BootstrapTable
                            { ...props.baseProps }
                          />
                        </>
                      )
                    }
                  </ToolkitProvider>
              }
              {
                country === 'SG' &&
                  <ToolkitProvider
                    keyField='weight'
                    data={shippingInfoSG}
                    columns={shippingColSG}
                    exportCSV={{
                      exportAll: false
                    }}
                    search
                  >
                    {
                      props => (
                        <>
                          <BootstrapTable
                            { ...props.baseProps }
                          />
                        </>
                      )
                    }
                  </ToolkitProvider>
              }
            </InfoModal>
        }
        { showAddYTLink &&
          <YTLinkModal onClose={this.onCloseYTLink} onSave={this.onSaveYTLink} />
        }
        { showAddEditOptionModal &&
          <EditOptionModal
            onHide={this.onCloseEditOptionModal}
            initialOption={editOptionIndex && item.options.find(opt => opt.position === editOptionIndex)}
            handleEditOption={this.handleEditOption}
            mode={optionModalMode}
            deleteOption={this.handleDeleteOption}
            options={item.options}
            customizeFields={attributes.customizeFields}
            isAdmin={isAdmin}
            self={attributes.self}
            variants={item.variants}
          />
        }
        { showRestoreOptionModal &&
          <ConfirmModal onClickYes={permutationError ? this.handleOnCloseRestoreOptionModal : this.handleRestoreOption} onClose={this.handleOnCloseRestoreOptionModal} title="Restore Variant & Option" showNoBtn={!permutationError} yesBtnTitle={permutationError ? 'OK' : undefined}>
            {permutationError ?
            <p>The number of variants will exceed 100. Please consider reducing the number of options available.</p>
            :
            <p>This will <strong>restore</strong> all variants for option <strong>{optionToRestore.value} ({optionToRestore.name})</strong>. Do you want to continue?</p>
            }
          </ConfirmModal>
        }
        { showEditImage &&
          <EditImageModal image={editImage} onClose={this.handleCloseEditImage} done={this.handleCropImage} />
        }
      </>
    )
  }
}

export default connect((state, props) => {
  return {
    error: state.error,
    user: state.auth.user,
  };
}, {
  ...errorActionCreators,
  ...merchantsActionCreators,
})(ProductB2C);
