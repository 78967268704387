import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';

class ConfirmBillModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payment_date: null,
    }
  }

  handleOnClickSave = () => {
    const { payment_date } = this.state;
    const { onClickSave } = this.props;
    onClickSave(payment_date);
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleSelectDate = date => {
    this.setState({
      payment_date: date
    })
  }

  render() {
    const { payment_date } = this.state;
    const { bills } = this.props;

    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4>Update Payment Date</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          {
            (bills && bills.length > 0) ?
              <>
                <label>Payment Date for:</label>
                <ul className='overflow-scroll' style={{maxHeight: '300px'}}>
                {
                  bills.map((bill, i) => {
                    return (
                      <li key={i}>{bill.name} - {bill.vendor}</li>
                    )
                  })
                }
                </ul>
              </>
              :
              <label>Payment Date</label>
          }
          <SingleDatePicker
            date={payment_date}
            onDateChange={this.handleSelectDate}
            displayFormat="DD-MM-YYYY"
            numberOfMonths={1}
            isOutsideRange={() => false}
            showClearDate
            required
            block
            focused={this.state.paymentDateFocused}
            onFocusChange={({ focused: paymentDateFocused }) => this.setState({ paymentDateFocused })}
          />
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={this.handleOnClose}>Cancel</Button>
          <Button variant="primary" onClick={this.handleOnClickSave}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmBillModal;
