import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_QUICKHELPS_REQUEST = 'FETCH_QUICKHELPS_REQUEST';
export const FETCH_QUICKHELPS_SUCCESS = 'FETCH_QUICKHELPS_SUCCESS';
export const FETCH_QUICKHELPS_FAILURE = 'FETCH_QUICKHELPS_FAILURE';

function fetchQuickHelpsRequest() {
  return {
    type: FETCH_QUICKHELPS_REQUEST,
  };
}

function fetchQuickHelpsSuccess(json) {
  return {
    type: FETCH_QUICKHELPS_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchQuickHelpsFailure() {
  return {
    type: FETCH_QUICKHELPS_FAILURE
  };
}

function shouldFetchQuickHelps(state) {
  const results = state.quick_helps;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

export function fetchQuickHelpsFromApi() {
  return dispatch => {
    dispatch(fetchQuickHelpsRequest());
    return api(`/quick_help/list`)
      .then(json => {
        dispatch(fetchQuickHelpsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchQuickHelpsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchQuickHelps() {
  return (dispatch, getState) => {
    if (shouldFetchQuickHelps(getState())) {
      return dispatch(fetchQuickHelpsFromApi());
    }
  };
}
