import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="jumbotron">
      <h1><i className="fa fa-frown-o red"></i> 404 Not Found</h1>
      <p className="lead">We couldn't find what you're looking for</p>
        <p>
          <Link to="/" className="btn btn-primary btn-lg green">Take Me To The Homepage</Link>
        </p>
    </div>
  )
}

export default NotFound;
