import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button, Alert, Badge, Container, Row, Col, Form } from 'react-bootstrap';
import Loader from '../components/Loader';
import MobileDetect from 'mobile-detect'
import VendorSelection from '../components/VendorSelection'
import * as pendingProductsActionCreators from '../redux/actions/pendingProducts';
import api from '../helpers/apiClient';
import ConfirmModal from '../components/ConfirmModal';
import ReviewerModal from '../components/ReviewerModal';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import ProductEditModal from '../components/ProductEditModal';
import * as taggingsActionCreators from '../redux/actions/taggings';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

class PendingProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vendorList: [],
      status: "any",
      inventory: "any",
      selectedIds: [],
      showConfirmDuplicateProductModal: false,
      displayDuplicateProductSuccess: false,
      displayDuplicateProductFail: false,
      errorMessage: '',
      duplicatedProductId: '',
      showReviewerModal: false,
      status: 'any',
      vendor: 'any',
      assignedTo: 'any',
    };
  }

  async componentDidMount() {
    const { fetchPendingProducts, fetchTaggings, location } = this.props;
    const { vendor, status, inventory } = this.state;
    const { merchantId, limit } = qs.parse(location.search.substr(1));

    const vendorList = vendor.split('#,');
    // this.setState({ vendorList, vendor: vendorList[0] });

    // fetchProducts(vendorList.length > 1 ? vendorList[0] : vendor, status, inventory, limit);
    await fetchTaggings()
    await this.setSelectOptions()
    fetchPendingProducts();
  }

  renderExpandedNoteColumn = (row) => {
    return <div>{row.note}</div>
  }

  renderStatus = (cell, row) => {
    switch (cell){
      case 'approved':
        return <Badge bg="success">APPROVED</Badge>
      case 'disapproved':
        return <Badge bg="danger">REJECTED</Badge>
      case 'pending':
        return <Badge bg="warning">CREATED</Badge>
      case 'reviewing':
        return <Badge bg="primary">IN REVIEW</Badge>
      case 'more_info':
        return <Badge bg="info">MORE INFO</Badge>
      default:
        break;
    }
  }

  renderTime = (cell, row) => {
    if(cell){
      return `${moment.duration(cell, 'seconds').asDays().toFixed(1)} days`
    }
  }

  renderCSVImages = (cell, row) => {
    const images = _.map(cell, img => {
      if(img && img.src){
        return img.src
      }
      return ''
    })

    return images
  }

  renderDetailLink = (cell, row) => {
    return (
      <Link
        key={row.id}
        to={{
          pathname: `/products/pending/${row.id}`
        }}
      >
        {cell}
      </Link>
    )
  }

  onRowSelect = (row, isSelected, e) => {

    let { selectedIds } = this.state;

    if(isSelected){
      selectedIds.push({id: row.id, title: row.title})
    } else {
      selectedIds = _.reject(selectedIds, {id: row.id})
    }

    this.setState({ selectedIds });
  }

  onSelectAll = (isSelected, rows) => {
    let { selectedIds } = this.state;

    if(isSelected) {
      _.each(rows, function (row) {
        selectedIds.push({
          id: row.id,
          title: row.title
        })
      })
    } else {
      _.each(rows, function (row) {
        selectedIds = _.reject(selectedIds, {id: row.id})
      })
    }

    this.setState({ selectedIds });
  }

  handleOnClickDuplicateProduct = () => {
    this.setState({ showConfirmDuplicateProductModal: true })
  }

  handleOnCloseDuplicateProductModal = () => {
    this.setState({ showConfirmDuplicateProductModal: false })
  }

  handleDuplicateProduct = () => {
    const { selectedIds } = this.state;
    const { pendingProducts: { items }, fetchPendingProducts } = this.props;
    const { id, title } = selectedIds[0];

    let body = {
      id,
      title: title + ' - Copy'
    }

    return api(`/products/duplicate`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then(result => {
      this.setState({
        showConfirmDuplicateProductModal: false,
        selectedIds: [],
        displayDuplicateProductSuccess: true,
        displayDuplicateProductFail: false,
        duplicatedProductId: result.id
      });
    }).catch(err => {
      this.setState({
        displayDuplicateProductSuccess: false,
        displayDuplicateProductFail: true,
        errorMessage: err.message
      })
    })
  }

  handleDeleteProducts = async () => {
    const { selectedIds } = this.state;

    //iterate selectedIds and delete each product
    for(let i = 0; i < selectedIds.length; i++){
      await api(`/products/${selectedIds[i].id}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
      })
    }

    this.setState({ showDeleteModal: false });
  }

  handleOnClickBulkEditProducts = () => {
    //open a new tab for bulk edit
    const { selectedIds } = this.state;

    let productIds = _.map(selectedIds, 'id');

    window.open(`/products/bulk-edit?ids=${productIds.join(',')}`, '_blank');
  }

  handleOnCloseEditProductModal = (updating) => {
    this.setState({ showProductEditModal: false });

    if (updating) {
      alert("Updating products... this may take up to a couple minutes, please refresh the page in a moment")
    }
  }

  handleOnClickEditProducts = () => {
    this.setState({ showProductEditModal: true });
  }

  handleShowDeleteProductModel = () => {
    this.setState({ showDeleteModal: true });
  }

  handleCloseDeleteProductModel = () => {
    this.setState({ showDeleteModal: false });
  }

  handleOnClickAssignReviewer = () => {
    this.setState({ showReviewerModal: true });
  }

  handleOnCloseReviewerModal = () => {
    this.setState({ showReviewerModal: false });
  }

  handleReviewerSelected = () => {
    const { fetchPendingProducts } = this.props;

    this.setState({
      showReviewerModal: false,
      selectedIds: []
    });

    fetchPendingProducts();
  }

  showImage = (cell, row) => {
    if(cell && cell.length){
      if (cell[0] !== null){
        return <img style={{height: '70px'}} src={cell[0].thumbnail || cell[0].src}/>;
      }
    }
  }

  renderDate = (cell, row) => {
    return moment(cell).format('DD/MM/YYYY')
  }

  dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = moment("01/01/2010", "DD/MM/YYYY");
    }

    if (!date2) {
      date2 = moment("01/01/2010", "DD/MM/YYYY");
    }

    if (order === "desc") {
      return (
        moment(date2).unix() - moment(date1).unix()
      );
    } else {
      return (
        moment(date1).unix() - moment(date2).unix()
      );
    }
  };

  handleFilterChange = (e, filter) => {
    this.setState({ [filter]: e.target.value })
  }

  setSelectOptions = async () => {

    const { taggings: { items: predefinedTags } } = this.props;

    function createOptions(taggings) {
      return _.map(taggings, tag => {
        return {
          label: tag.desc,
          value: tag.tag
        }
      })
    }

    const dayOptions = [
      { value: 'sun', label: 'Sunday' },
      { value: 'mon', label: 'Monday' },
      { value: 'tue', label: 'Tuesday' },
      { value: 'wed', label: 'Wednesday' },
      { value: 'thu', label: 'Thursday' },
      { value: 'fri', label: 'Friday' },
      { value: 'sat', label: 'Saturday' },
    ]

    this.setState({
      selectOptions: {
        dayOptions,
        locationOptions: createOptions(predefinedTags['Location'])
      }
    })
  }

  render() {
    const { pendingProducts: { loading, items, unselectableRows }, user } = this.props;
    const { selectedIds, showConfirmDuplicateProductModal, displayDuplicateProductSuccess, duplicatedProductId, 
      displayDuplicateProductFail, errorMessage, showReviewerModal, showDeleteModal, status, vendor, assignedTo, 
      showProductEditModal, selectOptions } = this.state;

    const isAdmin = user.role === 'admin'
    const isStaff = user.role === 'staff'

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      nonSelectable: unselectableRows,
      headerColumnStyle: { width: '30px' },
    }

    const options = {
      showTotal: true,
      sizePerPage: 20,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: items.length
      }],
    };

    const columns = [
      {
        dataField: 'id',
        sort: true,
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
        text: 'Id',
      },
      {
        dataField: 'created',
        sort: true,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        formatter: this.renderDate,
        sortFunc: this.dateSort,
        text: 'Created'
      },
      {
        dataField: 'images',
        text: 'Image',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        align: 'center',
        formatter: this.showImage,
        csvFormatter: this.renderCSVImages
      },
      {
        dataField: 'title',
        headerStyle: {
          width: '400px',
          resize: 'horizontal'
        },
        text: 'Name',
        formatter: this.renderDetailLink,
        csvText: 'name',
      },
      {
        dataField: 'approval_status',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Approval Status',
        sort: true,
        formatter: this.renderStatus,
      },
      {
        dataField: 'product_type',
        text: 'Type',
        sort: true,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
        csvText: 'type',
        csvExport: false,
      },
      {
        dataField: 'vendor',
        text: 'Vendor',
        sort: true,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
        hidden: !isAdmin && !isStaff
      },
      {
        dataField: 'approved_by',
        text: 'Assigned To',
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
        sort: true,
        hidden: !isAdmin && !isStaff
      },
      {
        dataField: 'review_time',
        text: 'Wait Time',
        sort: true,
        formatter: this.renderTime,
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
        hidden: !isAdmin && !isStaff
      },
      {
        dataField: 'approval_time',
        text: 'Final State Time',
        sort: true,
        formatter: this.renderTime,
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
        hidden: !isAdmin && !isStaff
      },
    ];

    return (
      <div>
        <div className="row mt-3 mb-4">
          <div className="col-xs-12 col-md-9">
            <h2 className="sub-header">Pending Products</h2>
          </div>
        </div>
            {
              displayDuplicateProductSuccess &&
              <div className="sticky-alert" style={{zIndex:'1000'}}>
                <Alert variant="success">
                  <span>Product duplicated successfully. </span>
                    <Link
                          to={{
                            pathname: `/products/pending/${duplicatedProductId}`,
                          }}
                    >Click here to view product.</Link>
                </Alert>
              </div>
            }
            {
              displayDuplicateProductFail &&
              <div className="sticky-alert" style={{zIndex:'1000'}}>
                <Alert variant="danger">
                  <span>{errorMessage}</span>
                </Alert>
              </div>
            }

          <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap">
            <Col sm="auto">
              <Link
                className="btn btn-primary w-100 btn-br-6"
                to={{
                  pathname: `/products/create`,
                }}
              >Create Product</Link>
            </Col>
            <Col sm="auto">
              <Button className="w-100 btn-br-6" variant="secondary" disabled={selectedIds.length===0 || selectedIds.length>1} onClick={this.handleOnClickDuplicateProduct}>Duplicate Product</Button>
            </Col>
            <Col sm="auto">
            {
              isAdmin &&
              <Button className="w-100 btn-br-6" variant="secondary" onClick={this.handleOnClickAssignReviewer} disabled={selectedIds.length===0}>Assign Reviewer</Button>
            }
            </Col>
            <Col sm="auto">
            {
              (isAdmin || isStaff) &&
              <Button className="w-100 btn-br-6" variant="secondary" onClick={this.handleShowDeleteProductModel} disabled={selectedIds.length===0}>Delete</Button>
            }
            </Col>
            <Col sm="auto">
            {
              (isAdmin || isStaff) &&
              <Button className="w-100 btn-br-6" variant="secondary" 
              onClick={this.handleOnClickEditProducts}
              disabled={selectedIds.length===0}>Edit {selectedIds.length} Product(s)</Button>
            }
            </Col>
          </Container>
        {
          loading &&
          <Loader />
        }
        {
          !loading && items &&
          <ToolkitProvider
              keyField='id'
              data={_.filter(items, item => {
                let validStatus = false, validVendor = false, validAssigned = false

                if (status === 'any' || item.approval_status === status) {
                  validStatus = true
                }
                if (vendor === 'any' || item.vendor === vendor) {
                  validVendor = true
                }
                if (assignedTo === 'any' || item.approved_by === assignedTo) {
                  validAssigned = true
                }

                return validStatus && validVendor && validAssigned
              })}
              columns={columns}
              exportCSV={{
                exportAll: true
              }}
              search
            >
              {
                props => (
                  <>
                  <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                    <Col className="d-flex align-items-center gap-2">
                      <label style={{marginBottom: '5px'}}>Status:</label>
                      <Form.Select className="form-control" onChange={(e) => this.handleFilterChange(e, 'status')} value={status}>
                        <option value="any">Any</option>
                        <option value="pending">Created</option>
                        <option value="reviewing">In Review</option>
                        <option value="more_info">More Info</option>
                        <option value="disapproved">Disapproved</option>
                      </Form.Select>
                    </Col>
                    <Col className="d-flex align-items-center gap-2">
                      <label style={{marginBottom: '5px'}}>Vendor:</label>
                      <Form.Select className="form-control" onChange={(e) => this.handleFilterChange(e, 'vendor')} value={vendor}>
                        <option value="any">Any</option>
                        { _.map(_.uniq(_.map(items, 'vendor')).sort(), vendor => {
                          return <option value={vendor}>{vendor}</option>
                        })
                        }
                      </Form.Select>
                    </Col>
                    <Col className="d-flex align-items-center gap-2">
                      <label style={{marginBottom: '5px'}}>Assigned:</label>
                      <Form.Select className="form-control" onChange={(e) => this.handleFilterChange(e, 'assignedTo')} value={assignedTo}>
                        <option value="any">Any</option>
                        { _.map(_.uniq(_.filter(_.map(items, 'approved_by'), approved_by => approved_by)), approved_by => {
                          return <option value={approved_by}>{approved_by}</option>
                        })
                        }
                      </Form.Select>
                    </Col>
                      <Col>
                        <ExportCSVButton
                          className="btn-info"
                          style={{borderRadius:'8px'}}
                          { ...props.csvProps }
                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                          Export to CSV</ExportCSVButton>
                      </Col>
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps }
                        style={{borderRadius:'25px'}} placeholder="Search"/>
                      </Col>
                    </Container>
                  <BootstrapTable
                  { ...props.baseProps }
                    bordered={false}
                    selectRow={selectRowProp}
                    pagination={paginationFactory(options)}
                    hover expandableRow={() => true}
                    defaultSorted={[{
                      dataField: 'id',
                        order: 'desc',
                    }]}
                  expandComponent={this.renderExpandedNoteColumn}
                  />
            </>
            )
          }
          </ToolkitProvider>
        }
        {
          showConfirmDuplicateProductModal &&
            <ConfirmModal onClickYes={this.handleDuplicateProduct} onClose={this.handleOnCloseDuplicateProductModal} title="Duplicate Product">
              <p>Confirm duplicate {_.find(items, i => i.id === selectedIds[0].id).title}?</p>
            </ConfirmModal>
        }
        {
          showReviewerModal &&
          <ReviewerModal products={selectedIds} onClose={this.handleOnCloseReviewerModal} reviewerSelected={this.handleReviewerSelected}/>
        }
        {
          showDeleteModal &&
          <ConfirmModal onClickYes={this.handleDeleteProducts} onClose={this.handleCloseDeleteProductModel} title="Delete Products">
            <p>Are you sure you want to delete {selectedIds.length} products(s)?</p>
          </ConfirmModal>
        }
        {
          showProductEditModal &&
            <ProductEditModal isPendingProduct selectedProducts={selectedIds} onBulkEditMoreFields={this.handleOnClickBulkEditProducts} onClose={this.handleOnCloseEditProductModal} selectOptions={selectOptions}/>
        }
      </div>
    );
  }
}

const defaultOptions = [{key: 'any', value: 'any', name: 'Any'}]

export default connect((state, props) => {
  const { pathname, search, routeState } = props.location;
  return {
    user: state.auth.user,
    taggings: state.taggings,
    pendingProducts: state.pendingProducts,
  };
}, {...pendingProductsActionCreators,
  ...taggingsActionCreators})(PendingProducts);
