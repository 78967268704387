import { useDispatch, useSelector } from "react-redux"
import TabHeader from "../components/TabHeader"
import { useEffect, useMemo, useState } from "react"
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { fetchMerchantStore } from '../redux/actions/merchantStore';
import { Button, Row, Col } from 'react-bootstrap';
import api from '../helpers/apiClient';
import BootstrapTable from 'react-bootstrap-table-next';
import { removeInlineStyles, removeEntities, removeBlockTypes } from '../helpers/draftjsClearFormat';
import DeliveryFinePrintsEditModal from "../components/DeliveryFinePrintsEditModal";
import Loader from '../components/Loader'
import VendorSelection from "../components/VendorSelection";
import Clipboard from 'clipboard'

const ClearFormatButton = ({clearFormat}) => {
  return (
    <Button variant="secondary" size="sm" className="mb-2" onClick={clearFormat}>Clear Format</Button>
  )
}

const DeliveryTemplates = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)
  const { item, loading, loaded } = useSelector(state => state.merchantStore)

  const vendorList = useMemo(() => user.merchantId ? user.merchantId.split('#,') : [])
  const isAdmin = user.role === 'admin' || user.role === 'staff'
  const [deliveryFinePrintsName, setDeliveryFinePrintsName] = useState('')
  const [deliveryFinePrintsEditorState, setDeliveryFinePrintsEditorState] = useState(EditorState.createEmpty())
  const [savingChanges, setSavingChanges] = useState(false)
  const [vendor, setVendor] = useState(vendorList[0] || (isAdmin ? 'Giftr' : ''))
  // const [loadingDFPProductCount, setLoadingDFPProductCount] = useState(false)
  const [deliveryFinePrintsModal, setDeliveryFinePrintsModal] = useState({
    show: false,
    content: '',
    name: '',
    id: '',
    productCount: 0
  })

  const dfpCol = [
    {
      dataField: '_id',
      text: 'ID',
      formatter: (cell) => (
      <div>
          <span className="text-muted">{cell.slice(-3)}</span><br/>
          <Button onClick={e => e.stopPropagation()} className="copy-to-clipboard" size="sm" variant="secondary" data-clipboard-text={cell.slice(-3)}>COPY</Button>
      </div>
      ),
      headerStyle: {
        width: '80px'
      }
    },
    {
      dataField: 'name',
      text: 'Name',
      headerStyle: {
        width: '120px'
      },
    },
    {
      dataField: 'content',
      text: 'Content',
      formatter: (cell, row) => {
        //set max height to 500px to prevent overflow of content in table cell and make it scrollable
        return <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }} dangerouslySetInnerHTML={{ __html: cell }}></div>
      },
    },
    {
      dataField: '_id',
      text: 'Actions',
      formatter: (cell, row) => {
        return <>
          <p>
            <Button
              bsStyle="danger"
              id={`${cell}`}
              onClick={() => { showConfirmDelete(cell) }}
            >Delete</Button></p>
          <p>
            <Button
              bsStyle="primary"
              id={`${cell}`}
              onClick={() => { showDeliveryFinePrintsModal(row) }}
            >Edit</Button>
          </p>
        </>
      },
      headerStyle: {
        width: '100px'
      },
    }
  ]

  useEffect(() => {
    if (vendor) {
      dispatch(fetchMerchantStore(vendor))
    }

    let clipboard = new Clipboard('.copy-to-clipboard');

    clipboard.on('success', function(e) {
      e.trigger.innerText = 'COPIED'
      e.clearSelection();
      setTimeout(() => {
        e.trigger.innerText = 'COPY'
      }, 5000)
    });
  }, [])

  const editorClearFormat = () => {
    const stateWithoutFormat = _.flow([
      removeInlineStyles,
      removeEntities,
      removeBlockTypes,
    ])(deliveryFinePrintsEditorState)

    setDeliveryFinePrintsEditorState(stateWithoutFormat)
  }

  const showConfirmDelete = async (id) => {
    //show prompt to confirm delete
    let result = await confirm("Are you sure you want to delete this delivery fine prints?")

    if (result) {
      handleDeleteDeliveryFinePrints(id)
    }
  }

  const handleDeleteDeliveryFinePrints = (id) => {
    api(`/merchants/store/${vendor}/delivery_fine_prints/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(result => {
      dispatch(fetchMerchantStore(vendor))

    }).catch(err => {
      console.log(err)
    })
  }

  const showDeliveryFinePrintsModal = async (row) => {

    // setLoadingDFPProductCount(true)

    const response = await api(`/dfp/product_count/${row._id}`);
    const { count } = response

    // setLoadingDFPProductCount(false)
    setDeliveryFinePrintsModal({
      show: true,
      content: row.content,
      name: row.name,
      id: row._id,
      productCount: count
    })
  }

  const deliveryFinePrintsEditorChange = deliveryFinePrintsEditorState => {

    setDeliveryFinePrintsEditorState(deliveryFinePrintsEditorState)
    setSavingChanges(false)
  }

  const handleAddNewDeliveryFinePrints = () => {
    setSavingChanges(true)

    let html = stateToHTML(deliveryFinePrintsEditorState.getCurrentContent())

    //check if html contains "Delivery Fine Prints:"
    if(html.length > 0 && html.indexOf('Delivery Fine Prints:') === -1){
      //bold the first line
      html = `<p><strong>Delivery Fine Prints:</strong></p>${html}`
    }

    api(`/merchants/store/${vendor}/delivery_fine_prints`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content: html,
        name: deliveryFinePrintsName
      })
    }).then(result => {
      setDeliveryFinePrintsName('')
      setDeliveryFinePrintsEditorState(EditorState.createEmpty())

      dispatch(fetchMerchantStore(vendor))
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setSavingChanges(false)
    })
  }

  const handleOnSaveDeliveryFinePrints = (content, name) => {

    api(`/merchants/store/${vendor}/delivery_fine_prints/${deliveryFinePrintsModal.id}`, {
      method: 'POST',
      body: JSON.stringify({ content, name }),
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(result => {

      dispatch(fetchMerchantStore(vendor))

    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setDeliveryFinePrintsModal({
        ...deliveryFinePrintsModal,
        show: false,
      })
    })
  }

  const handleOnVendorChange = (e) => {
    setVendor(e.target.value)
    dispatch(fetchMerchantStore(e.target.value))
  }

  return (
    <>
      <TabHeader title="Delivery Templates" tab_id="delivery_templates" user={user} />
      <Row className="align-items-end">
        <div className="col-xs-12 col-md-9"></div>
        {
          isAdmin &&
          <div className="col-xs-12 col-md-3" style={{ textAlign: 'right' }} >
            <VendorSelection handleOnVendorChange={handleOnVendorChange} selectedVendor={vendor} skipDefaultOption={true} />
          </div>
        }
        {
          vendorList.length > 1 &&
          <div className="col-xs-12 col-md-3">
            <div className="vendor-selection">
              <label style={{ marginBottom: '5px' }}>Vendor</label>
              <Form.Select className="form-control" onChange={handleOnVendorChange} value={vendor}>
                {
                  vendorList.map((option, ind) => {
                    return (
                      <option key={ind} value={option}>{option}</option>
                    )
                  })
                }
              </Form.Select>
            </div>
          </div>
        }
      </Row>
      <p>Add Delivery Fine Print</p>
      <input
        type="text"
        style={{ backgroundColor: 'white' }}
        className="form-control"
        placeholder='Delivery Fine Print Name'
        onChange={(e) => setDeliveryFinePrintsName(e.target.value)}
        value={deliveryFinePrintsName} />
      <div style={{ border: '1px solid #ced4da', borderRadius: '4px', marginBottom: '1rem', marginTop: '1rem', background: 'white' }}>
        <Editor
          toolbarCustomButtons={[<ClearFormatButton clearFormat={editorClearFormat} />]}
          onEditorStateChange={deliveryFinePrintsEditorChange}
          editorState={deliveryFinePrintsEditorState}
          editorStyle={{ resize: 'vertical', height: '150px', padding: '0 1rem' }} />
      </div>
      { loading &&
        <Loader />
      }
      { !loading && loaded &&
        <>
          <Row className="justify-content-end mb-3">
            <Col sm="auto">
              <Button
                variant="primary"
                type="button"
                style={{ marginBottom: '1rem' }}
                disabled={savingChanges || deliveryFinePrintsName === '' || deliveryFinePrintsEditorState.getCurrentContent().getPlainText() === '' || (item.delivery_fine_prints_template && item.delivery_fine_prints_template.length >= 5)}
                onClick={handleAddNewDeliveryFinePrints}
                className="btn-br-6 w-100"
              >
                {savingChanges ? 'Adding' : 'Add New Delivery Fine Prints Template'}
              </Button>
            </Col>
          </Row>
          <p>Delivery Fine Prints Templates</p>
          <div style={{ "fontFamily": "sans-serif" }}>
            <BootstrapTable
              bordered={true}
              hover
              keyField='_id'
              data={item.delivery_fine_prints_template}
              // selectRow={selectRowProp}
              columns={dfpCol}
              noDataIndication={() => 'No Delivery Fine Prints'}
            />
          </div>
          {
            item.delivery_fine_prints_template && item.delivery_fine_prints_template.length >= 5 &&
            <p style={{ color: 'red' }}>You have reach the limit of 5 Delivery Fine Prints Templates</p>
          }
        </>
      }
      {
        deliveryFinePrintsModal.show &&
        <DeliveryFinePrintsEditModal dfpId={deliveryFinePrintsModal.id} count={deliveryFinePrintsModal.productCount} content={deliveryFinePrintsModal.content} name={deliveryFinePrintsModal.name} onClose={() => setDeliveryFinePrintsModal({ ...deliveryFinePrintsModal, show: false })} onSaved={handleOnSaveDeliveryFinePrints} />
      }
    </>
  )
}

export default DeliveryTemplates