import { addError } from './error';
import api from '../../helpers/apiClient';
import _ from 'lodash'

export const FETCH_PENDING_PRODUCTS_REQUEST = 'FETCH_PENDING_PRODUCTS_REQUEST';
export const FETCH_PENDING_PRODUCTS_SUCCESS = 'FETCH_PENDING_PRODUCTS_SUCCESS';
export const FETCH_PENDING_PRODUCTS_FAILURE = 'FETCH_PENDING_PRODUCTS_FAILURE';

function fetchPendingProductsRequest(limit) {
  return {
    type: FETCH_PENDING_PRODUCTS_REQUEST,
    payload: {
      limit,
    }
  };
}

function fetchPendingProductsSuccess(json){
  return {
    type: FETCH_PENDING_PRODUCTS_SUCCESS,
    payload: {
      items: json,
      receivedAt: Date.now()
    }
  }
}

function fetchPendingProductsFailure(){
  return {
    type: FETCH_PENDING_PRODUCTS_FAILURE
  }
}

function shouldFetchPendingProducts(state){
  const results = state.pendingProducts;
  if(results && results.loading){
    return false;
  }
  else {
    return true;
  }
}

function fetchPendingProductsFromApi(limit){
  return dispatch => {
    dispatch(fetchPendingProductsRequest(limit));
    return api(`/pending_products`)
      .then(json => {
        dispatch(fetchPendingProductsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchPendingProductsFailure());
        dispatch(addError(err));
      })
  }
}

export function fetchPendingProducts(limit){
  return (dispatch, getState) => {
    if(shouldFetchPendingProducts(getState())){
      return dispatch(fetchPendingProductsFromApi(limit));
    }
  }
}
