import qs from 'qs';
import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_LOGS_REQUEST = 'FETCH_SYNC_ERROR_LOGS_REQUEST';
export const FETCH_LOGS_SUCCESS = 'FETCH_SYNC_ERROR_LOGS_SUCCESS';
export const FETCH_LOGS_FAILURE = 'FETCH_SYNC_ERROR_LOGS_FAILURE';

function fetchLogsRequest(limit, vendor, message, log_period_start, log_period_end){
  return {
    type: FETCH_LOGS_REQUEST,
    payload: {
      limit,
      vendor,
      message,
      log_period_start,
      log_period_end,
    }
  };
}

function fetchLogsSuccess(json){

  return {
    type: FETCH_LOGS_SUCCESS,
    payload: {
      items: json,
      receivedAt: Date.now()
    }
  }
}

function fetchLogsFailure(){
  return {
    type: FETCH_LOGS_FAILURE
  };
}

function shouldFetchLogs(state){
  const results = state.syncErrorLogs;
  if(results && results.loading) {
    return false;
  } else {
    return true;
  }
}

function fetchLogsFromApi(limit, vendor, message, log_period_start, log_period_end){
  const queryString = qs.stringify({
    limit,
    vendor,
    message,
    log_period_start,
    log_period_end
  }, { skipNulls: true });
  return dispatch => {
    dispatch(fetchLogsRequest(limit, vendor, message, log_period_start, log_period_end));
    return api(`/error_sync_logs?${queryString}`)
      .then(json => {
        dispatch(fetchLogsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchLogsFailure());
        dispatch(addError(err));
      })
  }
}

export function fetchLogs(limit, vendor, message, log_period_start, log_period_end) {
  return (dispatch, getState) => {
    if (shouldFetchLogs(getState())) {
      return dispatch(fetchLogsFromApi(limit, vendor, message, log_period_start, log_period_end));
    }
  };
}
