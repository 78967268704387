import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Tabs, Tab, Card, Col, Button, Alert, Container, Badge} from 'react-bootstrap';
import Loader from '../components/Loader';
import _ from 'lodash'
import * as errorActionCreators from '../redux/actions/error';
import api from '../helpers/apiClient';
import moment from 'moment';
import VendorSelection from '../components/VendorSelection'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Form } from 'react-bootstrap';
import TabHeader from '../components/TabHeader';
import Dropzone from 'react-dropzone'
import uploadIcon from '../images/upload-icon.svg'
import promptIcon from '../images/adminPrompt.svg';
import * as taggingsActionCreators from '../redux/actions/taggings';
import Clipboard from 'clipboard';

const { SearchBar } = Search;

class Medias extends Component {
  constructor(props) {
    super(props);
    const { location, user } = props;

    let vendorList = [];
    let vendor = 'any';

    if(user.merchantId){
      vendorList = user.merchantId.split('#,');
      vendor = vendorList[0]
    }

    const isAdmin = user.role === 'admin' || user.role === 'staff'

    if(isAdmin){
      vendor = 'Giftr'
    }

    this.state = {
      vendor,
      vendorList,
      loading: false,
      error: "",
      file: null,
      selectedIds: [],
      uploadingFile: false,
      items: [],
      isAdmin
    }
  }

  async componentDidMount() {

    await this.getMedias()

    let clipboard = new Clipboard('.copy-to-clipboard');

    clipboard.on('success', function(e) {

      e.trigger.innerText = 'COPIED'
      e.clearSelection();
    });
  }

  refreshMedias = async () => {

    //sleep for 1 seconds
    await new Promise(resolve => setTimeout(resolve, 1000));

    await this.getMedias()
  }

  getMedias = async () => {
    const { user } = this.props;
    const { vendor } = this.state;
    this.setState({ loading: true });

    //load all medias files from the server
    const medias = await api(`/media/${vendor}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    })

    this.setState({
      items: medias,
      loading: false
    })
  }

  onRowSelect = (row, isSelected, e) => {

    let { selectedIds } = this.state;

    if(isSelected){
      selectedIds.push({id: row._id, key: row.key})
    } else {
      selectedIds = _.reject(selectedIds, {id: row._id})
    }

    this.setState({ selectedIds });
  }

  onSelectAll = (isSelected, rows) => {
    if(isSelected) {
      this.setState({ selectedIds: _.map(rows, row => { return {id: row.id, key: row.key}})})
      return false
    }

    this.setState({ selectedIds: [] })
  }

  // handleVendorChange = async (e) => {

  //   const { provider } = this.state
  //   let vendor = e.target.value

  //   const products = await api(`/products/import/list/existing?merchantId=${vendor}`, {
  //     method: 'GET'
  //   })

  //   this.setState({
  //     products
  //   })
  // }

  showImage = (cell, row) => {

    if(!cell){
      return
    }

    //check cell is a video
    if(cell.includes('.mp4') || cell.includes('.mov')){
      return <video style={{height: '150px'}} src={cell} controls/>
    }

    return <img style={{height: '100px'}} src={cell}/>;
  }

  handleOnDrop = (files) => {
    if(files && files.length > 0){
      this.setState({
        uploadingFile: true
      });

      var formData = new FormData();
      formData.append('file', files[0]);

      api('/media' , {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'}).then(data => {

        if(data.error){
          this.setState({
            error: data.error
          });
          return;
        }

      }).catch(err =>{
        this.setState({
          uploadingFile: false
        });
        console.log(err)
      }).finally(() => {
        this.refreshMedias()
        this.setState({
          uploadingFile: false
        });
      })
    }
  }

  renderDate = (cell, row) => {

    return cell ? moment(cell).format("DD/MM/YYYY") : ''
  }

  handleOnClickDelete = async () => {

    const { selectedIds } = this.state;
    const { user } = this.props;

    if(selectedIds.length < 1){
      return;
    }

    this.setState({ 
      loading: true, 
      selectedIds: [] 
    });

    const medias = selectedIds

    await api(`/media`, {
      method: 'DELETE',
      body: JSON.stringify({ medias })
    })

    await this.refreshMedias()
  }

  renderMediaUrl = (cell, row) => {

    let style = {
      textWrap: 'pretty',
      overflowWrap: 'anywhere'
    }

    return (
      <div style={style}>
        <Button className="copy-to-clipboard ms-2 py-1" variant="secondary" data-clipboard-text={cell}>COPY</Button> {cell}
      </div>)
  }

  handleVendorChange = async (e) => {
    const vendor = e.target.value;
    await new Promise(resolve => setTimeout(resolve, 500));
    this.setState({ vendor,  items: [] });
    await this.getMedias()
  }

  render() {
    const { user, match } = this.props;
    const { items, vendor, vendorList, selectedIds, uploadingFile, loading, error, isAdmin } = this.state;

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll
    }

    const options = {
      showTotal: true,
      sizePerPage: 20,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: items.length
      }],
    };

    const columns = [
      {
        dataField: '_id',
        text: 'Id',
        hidden: true,
      },
      {
        dataField: 'media_url',
        text: 'Media',
        headerStyle: {
          width: '350px',
          resize: 'horizontal'
        },
        formatter: this.showImage,
      },
      {
        dataField: 'created',
        text: 'Created At',
        sort: true,
        formatter: this.renderDate,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'media_url',
        headerStyle: {
          resize: 'horizontal'
        },
        formatter: this.renderMediaUrl,
        text: 'Media URL'
      },
    ];

    return (
      <div>
        <TabHeader title="Media Files" user={user} />
        <Row className="align-items-end">
          <div className="col-xs-12 col-md-9"></div>
          {
            isAdmin &&
            <div className="col-xs-12 col-md-3" style={{ textAlign: 'right' }} >
              <VendorSelection handleOnVendorChange={this.handleVendorChange} selectedVendor={vendor} skipDefaultOption={true} />
            </div>
          }
          {
            vendorList.length > 1 &&
            <div className="col-xs-12 col-md-3">
              <div className="vendor-selection">
                <label style={{ marginBottom: '5px' }}>Vendor</label>
                <Form.Select className="form-control" onChange={this.handleVendorChange} value={vendor}>
                  {
                    vendorList.map((option, ind) => {
                      return (
                        <option key={ind} value={option}>{option}</option>
                      )
                    })
                  }
                </Form.Select>
              </div>
            </div>
          }
        </Row>
        <div className="row mt-3 mb-4 justify-content-between">
          <Col xs={12}>
            <Card className="shadow-sm" style={{border: 'none', borderRadius:'10px'}}>
              <Card.Header className="my-2 mx-4 px-0" style={{background: '#fff',}}>
                <h5 className="mb-0">Upload media files</h5>
              </Card.Header>
              <Card.Body className="grey-label label-strong mx-3">
                <div>
                  <Dropzone
                    className='dropzone-area'
                    onDrop={this.handleOnDrop}
                    multiple={false}
                    accept='image/*,video/mp4,video/quicktime'>
                    <div className="dropzone-text">
                      { !uploadingFile &&
                        <>
                          <img src={uploadIcon} style={{ width: '30px', margin: '0 10px' }}></img>
                          <span>Drag and drop an image file here, or click to upload</span>
                        </>
                      }
                      { uploadingFile &&
                        <>
                          <Loader size="sm" />
                          <span>Uploading File</span>
                        </>
                      }
                    </div>
                  </Dropzone>
                </div>
                <label className="mt-3">
                  Accepted File: jpg, jpeg, png, gif, mp4, mov<br/>
                  Max File Size: 30MB<br/>
                  *All files will be automatically be deleted after 1 month
                </label><br/>
              </Card.Body>
            </Card>
          </Col>
        </div>
        {
          error &&
          <Alert variant="danger">
            {error}
          </Alert>
        }
        {
          items.length > 0 &&
          <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap">
            <Col sm="auto">
              <Button
                variant="primary"
                className="w-100 btn-br-6"
                disabled={ selectedIds.length < 1}
                onClick={this.handleOnClickDelete}
              >Delete {selectedIds.length} media(s) </Button>
            </Col>
          </Container>
        }
        {
          loading &&
          <Loader />
        }
        {
          !loading && items && items.length > 0 &&
          <ToolkitProvider
              keyField='_id'
              data={items}
              columns={columns}
              search
            >
              {
                props => (
                  <>
                  <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps }
                        style={{borderRadius:'25px'}} placeholder="Search"/>
                      </Col>
                    </Container>

                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={false}
                      selectRow={selectRowProp}
                      pagination={paginationFactory(options)}
                      hover 
                      expandableRow={() => true}
                      expandComponent={this.renderExpandedComponent}
                      options={{
                        expandBy: "column",
                        sizePerPage: 20,
                        paginationShowsTotal: true,
                        sortName: "created",
                        sortOrder: "desc"
                      }}
                    />
                </>
            )
          }
          </ToolkitProvider>
        }
        {
          !loading && (!items || items.length == 0) &&
          <div className="admin-prompt">
            <img src={promptIcon}></img>
            <h4>No media files found</h4>
          </div>
        }
      </div>
    )
  }
}

const defaultOptions = [];

export default connect((state, props) => {
  return {
    error: state.error,
    user: state.auth.user,
  };
}, {
  ...errorActionCreators,
})(Medias);

