import qs from "qs";
import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_DELIVERIES_REQUEST = 'FETCH_DELIVERIES_REQUEST';
export const FETCH_DELIVERIES_SUCCESS = 'FETCH_DELIVERIES_SUCCESS';
export const FETCH_DELIVERIES_FAILURE = 'FETCH_DELIVERIES_FAILURE';
export const CLEAR_DELIVERIES = 'CLEAR_DELIVERIES';

function fetchDeliveriesRequest() {
  return {
    type: FETCH_DELIVERIES_REQUEST
  };
}

function fetchDeliveriesSuccess(json) {
  return {
    type: FETCH_DELIVERIES_SUCCESS,
    payload: {
      items: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchDeliveriesFailure() {
  return {
    type: FETCH_DELIVERIES_FAILURE
  };
}

function shouldFetchDeliveries(state) {
  const results = state.deliveries;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

function fetchDeliveriesFromApi(bill) {
  const queryString = qs.stringify({
    bill
  }, { skipNulls: true });
  return dispatch => {
    dispatch(fetchDeliveriesRequest());
    return api(`/deliveries?${queryString}`)
      .then(json => {
        dispatch(fetchDeliveriesSuccess(json));
      })
      .catch(err => {
        dispatch(fetchDeliveriesFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchDeliveries(bill) {
  return (dispatch, getState) => {
    if (shouldFetchDeliveries(getState())) {
      return dispatch(fetchDeliveriesFromApi(bill));
    }
  };
}

export function clearDeliveries() {
  return {
    type: CLEAR_DELIVERIES,
  };
}
