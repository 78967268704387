import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_ANNOUNCEMENTS_REQUEST = 'FETCH_ANNOUNCEMENTS_REQUEST';
export const FETCH_ANNOUNCEMENTS_SUCCESS = 'FETCH_ANNOUNCEMENTS_SUCCESS';
export const FETCH_ANNOUNCEMENTS_FAILURE = 'FETCH_ANNOUNCEMENTS_FAILURE';

function fetchAnnouncementsRequest() {
  return {
    type: FETCH_ANNOUNCEMENTS_REQUEST,
    payload: {
    }
  };
}

function fetchAnnouncementsSuccess(json) { 

  return {
    type: FETCH_ANNOUNCEMENTS_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchAnnouncementsFailure() {
  return {
    type: FETCH_ANNOUNCEMENTS_FAILURE
  };
}

function shouldFetchAnnouncements(state) {
  const results = state.announcements;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

function fetchAnnouncementsFromApi() {
  return dispatch => {
    dispatch(fetchAnnouncementsRequest());
    return api(`/announcement`)
      .then(json => {
        dispatch(fetchAnnouncementsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchAnnouncementsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchAnnouncements() {
  return (dispatch, getState) => {
    if (shouldFetchAnnouncements(getState())) {
      return dispatch(fetchAnnouncementsFromApi());
    }
  };
}