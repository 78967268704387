import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_MERCHANTS_BY_COUNTRY_REQUEST = 'FETCH_MERCHANTS_BY_COUNTRY_REQUEST';
export const FETCH_MERCHANTS_BY_COUNTRY_SUCCESS = 'FETCH_MERCHANTS_BY_COUNTRY_SUCCESS';
export const FETCH_MERCHANTS_BY_COUNTRY_FAILURE = 'FETCH_MERCHANTS_BY_COUNTRY_FAILURE';
export const CLEAR_MERCHANTS_BY_COUNTRY = 'CLEAR_MERCHANTS_BY_COUNTRY';

function fetchMerchantsRequest() {
  return {
    type: FETCH_MERCHANTS_BY_COUNTRY_REQUEST,
  };
}

function fetchMerchantsSuccess(json, country) { 
  return {
    type: FETCH_MERCHANTS_BY_COUNTRY_SUCCESS,
    payload: {
      items: json,
      country,
      receivedAt: Date.now(),
    }
  };
}

function fetchMerchantsFailure() {
  return {
    type: FETCH_MERCHANTS_BY_COUNTRY_FAILURE
  };
}

function shouldFetchMerchants(state) {
  const results = state.merchantsByCountry;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

function fetchMerchantsFromApi(country) {
  return dispatch => {
    dispatch(fetchMerchantsRequest());
    return api(`/lookup/merchants/${country}`)
      .then(json => {
        dispatch(fetchMerchantsSuccess(json, country));
      })
      .catch(err => {
        dispatch(fetchMerchantsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchMerchantsByCountry(country) {
  return (dispatch, getState) => {
    if (shouldFetchMerchants(getState())) {
      return dispatch(fetchMerchantsFromApi(country));
    }
  };
}

export function clearMerchantsByCountry() {
  return {
    type: CLEAR_MERCHANTS_BY_COUNTRY,
  };
}