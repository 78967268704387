import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/Loader';
import ChangePasswordModal from '../components/ChangePasswordModal';
import { Card, Button, Label, Row, Col, Form } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import Input from '../components/Input';
import Select from '../components/Select';
import api from '../helpers/apiClient';
import states from '../data/states.json'
import countries from '../data/countries.json'
import * as profileActionCreators from '../redux/actions/profile';
import 'react-dates/lib/css/_datepicker.css';
import _ from 'lodash'
import TabHeader from '../components/TabHeader';

const validate = (values, props) => {
  const { match } = props;
  const errors = {}
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.contact) {
    errors.contact = 'Required';
  }
  if (!values.address1) {
    errors.address1 = 'Required';
  }
  if (!values.city) {
    errors.city = 'Required';
  }
  if (!values.state) {
    errors.state = 'Required';
  }
  if (!values.postcode) {
    errors.postcode = 'Required';
  }
  if (!values.country) {
    errors.country = 'Required';
  }
  return errors
}

class Profile extends Component {
    constructor(props) {
        super(props);


        this.state = {
            saved: false,
            showChangePasswordModal: false,
        }
    }

    componentDidMount() {
        const { fetchProfile, user } = this.props;
        fetchProfile();

    }

    handleOnCloseChangePasswordModal = () => {
        this.setState({showChangePasswordModal: false})
    }

    handleOnChangePassword = (newPassword) =>  {

        let body = {
            password: newPassword
        }

        return api(`/profile/password`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }).then((result) => {
            if(result.success){
                alert("Password updated.")
            } else {
                alert("Fail to update password, please contact Giftr support.")
            }
            this.setState({showChangePasswordModal: false})
        })
    }

    handleOnClickShowChangePasswordModal = () => {
        this.setState({showChangePasswordModal: true})
    }

    submit = (values, dispatch, props) => {
        // const { match } = this.props;
        const initialValues = props.initialValues;
        const { name, contact, company, address1, address2, postcode, city, state, country } = values;
        let locationChanged
        if (address1 !== initialValues.address1 || address2 !== initialValues.address2 || postcode !== initialValues.postcode || city !== initialValues.city || state !== initialValues.state || country !== initialValues.country) {
            locationChanged = true
        }
        const url = '/profile'//match.params.userId ? `/users/${match.params.userId}` : '/users';
        return api(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name, contact, company, address1, address2, postcode, city, state, country, locationChanged,
            })
        }).then((result) => {
            this.setState({saved: true})
        }).catch(err => {
        if (err) {
            window.scrollTo(0, 0);
            if (err.validationError) {
                throw new SubmissionError({
                    _error: err.validationError
                });
            }
            else if (err.message) {
                throw new SubmissionError({
                    _error: err.message
                });
            }
            else {
                throw new SubmissionError({
                    _error: 'Something bad happened'
                });
            }
        }
        });
    }

    handleBackToPrevModal = e => {
        e.preventDefault();
        const { backToPrevModal, backDest } = this.props;

        backToPrevModal(backDest);
    }

    render() {

        const { merchants, selectedMerchant, saved, showChangePasswordModal } = this.state
        const { user, profile: { loading, loaded, item }, stateValue, handleSubmit, error, submitting, match, showHeader = true, showPassword = true, prevModal} = this.props

        return (
            <div>
            {
                showHeader &&
                    <TabHeader title="My Profile" tab_id="profile" user={user} />
            }
            {
                loading && <Loader />
            }
            <Row className="justify-content-center">
                <Col lg="auto">
                <Card className="tealCard shadow-sm">
                    <Card.Header className="tealCardHeader">Profile</Card.Header>
                    <Card.Body className="mx-2">
                        {
                            showPassword &&
                                <Button className="pull-right p-0" variant="clear-danger" onClick={this.handleOnClickShowChangePasswordModal}>Change Password</Button>
                        }
                    <div className='clearfix'></div>
                    <form className='d-grid gap-3' onSubmit={handleSubmit(this.submit)}>
                        <Field className="form-control" required name="name" label="Contact Person" placeholder="Name" type="text" component={Input} />
                        <Field className="form-control" required name="contact" label="Contact Number" placeholder="Contact" type="text" component={Input} />
                        <Field className="form-control" name="company" label="Company Name" placeholder="Company name (optional)" type="text" component={Input} />
                        <Field className="form-control" required name="address1" label="Address 1" placeholder="Address 1" type="text" component={Input} />
                        <Field className="form-control" name="address2" label="Address 2" placeholder="Address 2" type="text" component={Input} />
                        <Row>
                            <Col xs={6}><Field className="form-control" required name="postcode" label="Postcode" placeholder="Postcode" type="text" component={Input} /></Col>
                            <Col xs={6}><Field className="form-control" required name="city" label="City" placeholder="City" type="text" component={Input} /></Col>
                        </Row>
                        <Row>
                            <Col xs={6}><Field className="form-control" required name="state" label="State" type="select" component={Select} options={states.MY} defaultOptionName="Select a state" /></Col>
                            <Col xs={6}><Field className="form-control" required name="country" label="Country" type="select" component={Select} options={countries.countries} defaultOptionName="Select a country"/></Col>
                        </Row>
                        <Button
                            variant="primary"
                            type="submit"
                            className="mt-2 btn-br-6 text-uppercase"
                            disabled={submitting}
                        >
                            {submitting ? 'Saving changes' : 'Save changes'}
                        </Button>
                    </form>
                    </Card.Body>
                </Card>
                </Col>
            </Row>
            {
                saved &&
                <div className='alert alert-success mb-0 mt-3'>
                    Profile saved.{' '}
                    {
                        prevModal &&
                            <a onClick={this.handleBackToPrevModal} href="">Back to {prevModal}</a>
                    }
                </div>
            }
            {
                showChangePasswordModal &&
                <ChangePasswordModal onClose={this.handleOnCloseChangePasswordModal} onClickSave={this.handleOnChangePassword}/>
            }
            </div>
        )
    }

}

Profile = reduxForm({
  form: 'profile',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  // asyncValidate,
})(Profile);

const defaultOptions = [];
const defaultObject = { country: 'Malaysia'};
const selector = formValueSelector('profile')

export default connect((state, props) => {

    const { match } = props;
    const stateValue = selector(state, 'state');
    const postcodeValue = selector(state, 'postcode');
    const nameValue = selector(state, 'name');

    // console.log('profile.item', state.profile.item ? state.profile.item : defaultObject)

    return {
        profile: state.profile,
        stateValue,
        postcodeValue,
        nameValue,
        user: state.auth.user,
        initialValues: state.profile.item ? state.profile.item : defaultObject,
    };
}, {...profileActionCreators})(Profile);
