import {
  FETCH_PRODUCT_DETAIL_REQUEST,
  FETCH_PRODUCT_DETAIL_SUCCESS,
  FETCH_PRODUCT_DETAIL_FAILURE,
} from '../actions/productDetail';

const defaultState = {
  loading: false,
  loaded: false,
  productId: null,
  item: null
}

export default function productDetail(state = defaultState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        productId: action.payload.productId
      };
    case FETCH_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.payload.item,
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_PRODUCT_DETAIL_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
