import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Alert, Badge, Container } from 'react-bootstrap';
import moment from 'moment';
import Select from '../components/Select';
import Loader from '../components/Loader';
import commissionTypeOptions from '../data/commission_type.json';
import api from '../helpers/apiClient';
import Textarea from '../components/Textarea';
import _ from 'lodash';
import ConfirmModal from '../components/ConfirmModal';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import TabHeader from '../components/TabHeader';

const { SearchBar } = Search;

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class Promotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      promotions:[],
      selectedIds: [],
      showConfirmDeleteModel: false,
      showBulkDeleteSuccess: false
    };
  }

  async componentDidMount() {

    this.setState({ loading: true })

    let result = await api(`/discounts` , {
      method: 'GET',
    })

    this.setState({ loading: false, promotions: result })
  }

  onRowSelect = (row, isSelected, e) => {

    let { selectedIds } = this.state;

    if(isSelected){
      selectedIds.push({
        id: row._id,
        discount_code_id: row.discount_code_id,
        price_rule_id: row.price_rule_id
      })
    } else {
      selectedIds = _.reject(selectedIds, {id: row._id})
    }

    this.setState({ selectedIds });
  }

  onSelectAll = (isSelected, rows) => {

    let { selectedIds } = this.state;

    if(isSelected){
      _.each(rows, function (row) {
        selectedIds.push({
          id: row._id,
          discount_code_id: row.discount_code_id,
          price_rule_id: row.price_rule_id
        })
      })
    } else {
      _.each(rows, function (row) {
        selectedIds = _.reject(selectedIds, {id: row._id})
      })
    }

    this.setState({ selectedIds });

    return true
  }

  renderDate = (cell, row) => {

    return moment(cell).format("DD/MM/YYYY")
  }

  renderStatus = (cell, row) => {

    const startsAt = moment(row.starts_at)
    const endsAt = moment(row.ends_at)

    if(startsAt.unix() - moment().unix() > 0){
      return <Badge bg="info" className="text-uppercase">scheduled</Badge>
    } else if (moment().unix() - endsAt.unix() > 0){
      return <Badge bg="secondary" className="text-uppercase">expired</Badge>
    } else if (moment().unix() - startsAt.unix() > 0 && moment().unix() - endsAt.unix() < 0){
      return <Badge bg="success" className="text-uppercase">active</Badge>
    }
  }

  renderDiscountValue = (cell, row) => {
    return row.value_type === 'percentage' ? `${cell*-1}%` : `${currency}${cell*-1}`
  }

  renderOneUsePerCustomer = (cell, row) => {
    return row.once_per_customer ?
      <Badge bg="success" className="text-uppercase">Yes</Badge>
      :
      <Badge bg="warning" className="text-uppercase">No</Badge>
  }

  renderDiscountType = (cell, row) => {
    return cell === 'percentage' ? 'Percentage' : 'Fixed Value'
  }

  renderDiscountCode = (cell, row) => {

    return (
      <Link
        key={row.title}
        to={{
          pathname: `/promotions/${row._id}`
        }}
      >
        {row.title}
      </Link>
    )
    return cell === 'percentage' ? 'Percentage' : 'Fixed Value'
  }

  renderUsageLimit = (cell, row) => {
    return cell ? cell : 'None'
  }

  handleOnClickBulkDelete = () => {
    this.setState({ showConfirmDeleteModel: true });
  }

  handleOnCloseBulkFulfillModal = () => {
    this.setState({ showConfirmDeleteModel: false });
  }

  handleOnBulkfDelete = async () => {
    const { selectedIds } = this.state;

    // this.setState({ loading: true });

    let result = await api(`/discounts/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedIds)
    })

    this.setState({ showConfirmDeleteModel: false, selectedIds: [], showBulkDeleteSuccess: true, loading: true });

    result = await api(`/discounts` , {
      method: 'GET',
    })

    this.setState({ loading: false, promotions: result })
  }

  handleDismissBulkDeleteSuccess = () => {
    this.setState({ showBulkDeleteSuccess: false })
  }

  render() {
    const { error, submitting, match, user } = this.props
    const { loading, promotions, selectedIds, showBulkDeleteSuccess, showConfirmDeleteModel } = this.state

    let selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      headerColumnStyle: { width: '30px' },
    }

    const isAdmin = user.role === 'admin'

    const options = {
      showTotal: true,
      sizePerPage: 20,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: promotions.length
      }],
    };

    const columns = [
      {
        dataField: 'title',
        sort: true,
        formatter: this.renderDiscountCode,
        text: 'Discount Code',
        headerStyle: {
          width: '200px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'value_type',
        sort: true,
        text: 'Discount Type',
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        formatter: this.renderDiscountType
      },
      {
        dataField: 'value',
        sort: true,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'Discount Value',
        formatter: this.renderDiscountValue,
      },
      {
        dataField: 'starts_at',
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'Starts at',
        sort: true,
        formatter: this.renderDate
      },
      {
        dataField: 'ends_at',
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'Ends at',
        sort: true,
        formatter: this.renderDate
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        formatter: this.renderStatus
      },
      {
        dataField: 'vendor',
        text: 'Vendor',
        hidden: !isAdmin,
        sort: true,
        headerStyle: {
          resize: 'horizontal'
        },
      },
      {
        dataField: 'created_by',
        text: 'Created by',
        sort: true,
        hidden: !isAdmin,
        headerStyle: {
          resize: 'horizontal'
        },
      },
      {
        dataField: 'once_per_customer',
        text: 'Once per cust.',
        sort: true,
        formatter: this.renderOneUsePerCustomer,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'usage_limit',
        text: 'Usage Limit',
        sort: true,
        formatter: this.renderUsageLimit,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: '_id',
        hidden: true,
      }
    ];

    return (
      <div>
        <TabHeader title="Promotions" tab_id="promotions" user={user} children={
          <>
            <Link
              className="btn btn-primary btn-br-6"
              to={{
                pathname: `/promotions/create`,
              }}
            >Create Promotion
            </Link>
            { isAdmin &&
              <>
              <Button className="btn-br-6" variant="danger-secondary" disabled={selectedIds.length === 0} onClick={this.handleOnClickBulkDelete}><FontAwesomeIcon icon={solid('trash')} className="me-3" />Delete</Button>
              </>
            }
          </>
        }/>
        {
          showBulkDeleteSuccess &&
          <Alert variant="success" dismissible onClose={this.handleDismissBulkDeleteSuccess}>
            <p>Bulk delete success</p>
          </Alert>
        }
        {
          loading &&
          <Loader />
        }
        {
          !loading &&
          <>
            <ToolkitProvider
              keyField='_id'
              data={promotions}
              columns={columns}
              search
            >
              {
                props => (
                  <>
                    <Container fluid className="mb-3 mt-4 d-flex gap-3 justify-content-end flex-row flex-wrap">
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps } style={{borderRadius:'25px'}} />
                      </Col>
                    </Container>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      { ...(isAdmin ? { selectRow: selectRowProp } : {}) }
                      pagination={paginationFactory(options)}
                      hover
                      defaultSorted={[{
                        dataField: 'created',
                          order: 'desc',
                      }]}
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </>
        }
        {
          showConfirmDeleteModel &&
          <ConfirmModal onClickYes={this.handleOnBulkfDelete} onClose={this.handleOnCloseBulkFulfillModal} title="Bulk Fulfill">
            <p>Are you sure you want to delete {selectedIds.length} item(s)?</p>
          </ConfirmModal>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  const { match } = props;
  return {
    user: state.auth.user,
  };
}, {})(Promotions);
