import { forwardRef, useRef, useEffect } from 'react';

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, disabled, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" disabled={disabled} ref={resolvedRef} {...rest} />
      </>
    )
  }
)

export default IndeterminateCheckbox;
