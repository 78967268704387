import React, { Component } from 'react';
import { Modal, Label, Button, Row, Col} from 'react-bootstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import imageCheck from '../images/imageCheck.png'

class ImagesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      selectedImage: {
        index: undefined,
        src: ''
      },
    }
  }

  handleOnClose = () => {
    const { onClose } = this.props;

    onClose();
  }

  selectImage = index => {
    const { images } = this.props;

    this.setState({
      selectedImage: {
        index,
        ...images[index]
      }
    })
  }

  handleOnSave = () => {
    const { selectedImage } = this.state;
    const { onClickSave } = this.props;

    this.setState({ submitting: true });
    onClickSave(selectedImage);
  }

  render(){
    const { selectedImage: { index }, submitting } = this.state;
    const { images, variantIndex } = this.props;

    return (
      <Modal style={{border: 'none'}} size="lg" show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0">Assign Image to Variant {variantIndex + 1}</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          {
            (images && images.length > 0) ?
              <Row>
                {
                  _.map(images, (image, i) => {
                    return image.src &&
                      <Col key={i} xs={6} md={3} className="product-detail-image" style={{position: 'relative'}}>
                        <Button variant="secondary" className="select-image" onClick={e => this.selectImage(i)}>
                          <img src={image.src}/>
                          {
                            index == i &&
                              <img className="imageCheck" src={imageCheck} width="50"/>
                          }
                        </Button>
                      </Col>
                  })
                }
              </Row>
            :
            <p>
              No images found. Please upload image in the Product Media panel.
            </p>
          }
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={this.handleOnClose}>Cancel</Button>
          <Button variant="primary" disabled={submitting} onClick={this.handleOnSave}>Confirm</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ImagesModal;
