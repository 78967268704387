import {
  FETCH_PICKUPS_REQUEST,
  FETCH_PICKUPS_SUCCESS,
  FETCH_PICKUPS_FAILURE,
  CLEAR_PICKUPS,
} from '../actions/pickups';

const defaultState = {
  loading: false,
  loaded: false,
  items: []
};

export default function pickups(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_PICKUPS:
      return {
        ...state,
        defaultState
      };
    case FETCH_PICKUPS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PICKUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_PICKUPS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
