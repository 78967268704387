import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
import api from '../helpers/apiClient';

const EditBillingAddressModal = ({ onClose, firstName, lastName, address, phoneNumber, onSaved, orderId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let form = new FormData(e.target);
    let billing_info = Object.fromEntries(form);

    let order = await api(`/manual_orders/${orderId}/update`, {
      method: 'POST',
      body: JSON.stringify(billing_info),
      headers: {'Content-Type': 'application/json'}
    })

    onSaved(order);
  }

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4 className="mb-0">Edit Billing Address</h4>
      </Modal.Header>
      <form onSubmit={submit}>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
          <Row>
            <Col md={6}>
              <label>First Name</label>
              <input className="form-control" name="first_name_billing" type="text" defaultValue={firstName} />
            </Col>
            <Col md={6}>
              <label>Last Name</label>
              <input className="form-control" name="last_name_billing" type="text" defaultValue={lastName} />
            </Col>
          </Row>
          <div className='d-grid gap-1'>
            <label htmlFor='delivery_address'>Address</label>
            <textarea className="form-control" name="address_billing" placeholder="Address" defaultValue={address} style={{height: '150px'}} />
          </div>
          <div className='d-grid gap-1'>
            <label htmlFor="phone_number_billing">Phone Number</label>
            <input className="form-control" name="phone_number_billing" type="text" placeholder="Phone Number" defaultValue={phoneNumber} />
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={onClose} disabled={isLoading}>Cancel</Button>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default EditBillingAddressModal;
