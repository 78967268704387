import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class PriorityFeeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fee: "",
      clickedSave: false,
    }
  }

  handleOnClickSave = () => {
    const { onClickSave } = this.props;
    const { fee } = this.state;

    this.setState({ clickedSave: true })
    onClickSave(fee);
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleOnInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    const { fee, clickedSave } = this.state;

    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
            <h4 className="mb-0">Add Priority Fee</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          <p className="m-0">Note:<br/>1. The amount added as a priority fee each time will replace the previous amount.<br/>2. The amount added subsequently must be greater than the previous one.</p>
          <input className="form-control" type="number" step=".01" name="fee" placeholder={`Enter priority fee (${currency})`} value={fee} onChange={this.handleOnInputChange}/>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={this.handleOnClose}>Cancel</Button>
          <Button variant="primary" onClick={this.handleOnClickSave} disabled={!fee || clickedSave}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PriorityFeeModal;
