import { renderToStaticMarkup } from 'react-dom/server'
import { Modal, ListGroup, Button, Card } from "react-bootstrap"
import config from '../config'
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useEffect, useMemo, useState, useRef } from "react";
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import plusCode from '../images/plus-code.png'

const key = config.googleApiKey

const ConfirmAddressModal = ({ onHide, title = 'Confirm Address', backdropClassName, defaultAddress = '', confirmAddress }) => {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: key
  })

  return (
    <Modal show={true} onHide={onHide} className="nested-modal map-modal" backdropClassName={backdropClassName} size="xl">
      <Modal.Header closeButton>
        <h4 className="mb-0">{title}</h4>
      </Modal.Header>
      { isLoaded ?
        <ConfirmAddressModalBody defaultValue={defaultAddress} onHide={onHide} confirmAddress={confirmAddress}/>
        :
        <div>Loading...</div>
      }
    </Modal>
  )
}

const preferredAddressTypes = ["premise", "street_address"]

const ConfirmAddressModalBody = ({ defaultValue, onHide, confirmAddress }) => {
  const center = useMemo(() => ({ lat: 3.14, lng: 101.69 }), [])

  const [map, setMap] = useState(null)
  const [selectedLoc, setSelectedLoc] = useState(null)
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [selectedAddressTypes, setSelectedAddressTypes] = useState([])
  const [roomFloor, setRoomFloor] = useState('')

  useEffect(() => {
    // if address is a premise/street address
    if (selectedAddressTypes.length === 0 || _.some(selectedAddressTypes, addressType => _.includes(preferredAddressTypes, addressType))) {
      return
    }

    // get default room/floor based on selectedAddress
    let roomFloor = []
    let addressSplit = defaultValue.split(' ')
    for (let str of addressSplit) {
      if (_.includes(selectedAddress[0].formatted_address, str)) {
        break
      }
      roomFloor.push(str)
    }

    setRoomFloor(roomFloor.join(' '))
  }, [selectedAddressTypes])

  useEffect(() => {
    if (map && selectedLoc && selectedLoc.lat && selectedLoc.lng) {
      map.panTo(selectedLoc)
      if (map.zoom < 15) {
        map.setZoom(15)
      }
    }
  }, [selectedLoc])

  const handleSelectSuggestion = (location, address, types) => {
    setSelectedLoc(location)
    setSelectedAddress([{ formatted_address: address, selected: true }])
    setSelectedAddressTypes(types)
  }

  const onClickConfirm = () => {
    let address = roomFloor ? `${roomFloor} ${selectedAddress[0].formatted_address}` : selectedAddress[0].formatted_address
    confirmAddress(selectedLoc, address)
  }

  return (
    <>
      <Modal.Body>
        <p className="small text-end mb-0">Initial Address: {defaultValue}</p>
        <PlacesAutocomplete selectSuggestion={handleSelectSuggestion} defaultValue={defaultValue} />
        <GoogleMap
          zoom={10}
          mapContainerClassName="map-container my-2"
          center={center}
          onLoad={setMap}
        >
          {selectedLoc && <Marker position={selectedLoc}/>}
        </GoogleMap>
        <label>Remarks (Floor/Block/Room)</label>
        <input
          className="form-control"
          type="text"
          placeholder="Specify address details or any remarks"
          value={roomFloor}
          onChange={e => setRoomFloor(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger-secondary" onClick={onHide}>Cancel</Button>
        <Button
          variant="primary"
          onClick={onClickConfirm}
          disabled={!selectedAddress}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </>
  )
}

const useDebounce = (callback, wait) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      if (ref.current) {
        ref.current();
      }
    };

    return _.debounce(func, wait);
  }, []);

  return debouncedCallback;
};

const PlacesAutocomplete = ({ selectSuggestion, defaultValue }) => {

  const searchInput = useRef(null)

  const [focusedSearch, setFocusedSearch] = useState(false)
  const [hoverSuggestions, setHoverSuggestions] = useState(false)
  const [value, setValue] = useState(defaultValue)
  const [gcData, setGcData] = useState([])

  // const {
  //   ready,
  //   value,
  //   setValue,
  //   suggestions: { status, data },
  //   clearSuggestions,
  // } = usePlacesAutocomplete({
  //   defaultValue: defaultValue,
  //   debounce: 500,
  // });

  useEffect(() => {
    debounceGeocode()
  }, [])

  const suggestions = useMemo(() => {
    let result = []
    if (gcData.length > 0) {
      result.push(..._.map(gcData, address => {
        const isPlusCode = _.includes(address.address_components[0].types, 'plus_code')

        return {
          place_id: address.place_id,
          description: address.formatted_address,
          types: isPlusCode ? ['plus_code'] : address.types,
        }
      }))
    }
    // if (status === 'OK' && data.length > 0) {
    //   result.push(...data)
    // }
    return result
  }, [
    // status,
    // data,
    gcData
  ])

  const handleSelect = async (address, types) => {
    // if (ready) {
    //   setValue(address, false);
    //   clearSuggestions();
    // }

    setValue(address)
    setGcData([])

    const results = await getGeocode({ address });
    const { lat, lng } = getLatLng(results[0]);
    selectSuggestion({ lat, lng }, address, types);
  }

  const debounceGeocode = useDebounce(() => {
    getGeocode({ address: value }).then(results => {
      setGcData(results)
    }).catch(err => {
      console.log(err)
      setGcData([])
    })
  }, 500)

  const onChangeSearch = (e) => {
    setValue(e.target.value)

    // if (ready) {
    //   setValue(e.target.value, true)
    // }

    debounceGeocode()
  }

  const onBlurSearch = () => {
    if (!hoverSuggestions) {
      setFocusedSearch(false)
    }
  }

  return (
    <>
      <label>Search an address</label>
      <div className="position-relative" onFocus={() => setFocusedSearch(true)}>
        <input
          ref={searchInput}
          className="form-control"
          value={value}
          onChange={onChangeSearch}
          placeholder="Search an address"
          autoFocus
          onBlur={onBlurSearch}
        />
        <ListGroup style={{ zIndex: "1", padding: "inherit", left: "0" }} className={`position-absolute py-0 ${focusedSearch ? '' : 'd-none'}`}
          onMouseEnter={() => setHoverSuggestions(true)}
          onMouseLeave={() => setHoverSuggestions(false)}
        >
          { suggestions.map(({ place_id, description, types }) => (
            <ListGroup.Item key={place_id} action onClick={() => handleSelect(description, types)}>
              { description }
            </ListGroup.Item>
          ))
          }
        </ListGroup>
      </div>
      <div className="small mt-1">
        <span>Not found? </span>
        <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(defaultValue)}`} target="_blank">Search Google Maps</a>
        <span> and copy the location's plus code</span>
        <i
          data-html="true"
          data-tip={renderToStaticMarkup(
            <img style={{maxHeight: '600px'}} src={plusCode} />
          )}
          data-for="plus-code"
          className="fa fa-info-circle"
        />
        <ReactTooltip id="plus-code" place="bottom"/>
        <span> into the search bar.</span>
      </div>
    </>
  )
}

export default ConfirmAddressModal
