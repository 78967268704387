import React, { Component } from 'react';
import { Link, NavLink, Route } from 'react-router-dom';
import { Navbar, Nav, Offcanvas, Container, Accordion, Badge } from 'react-bootstrap';
import { connect } from 'react-redux'
import logo from '../images/Giftr-Logo-Main.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import NewAnnouncements from './NewAnnouncements'
import _ from 'lodash'
import api from '../helpers/apiClient';

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

const isActiveRoute = (pathName, route) => {
  return pathName === route;
}

class Header extends Component {
  constructor(props) {
    super(props);

    let announcements = []
    this.state = {
      expanded: false,
      navExpanded: false,
      announcements
    };
  }

  componentDidMount() {
    const { annoucementHeightChange } = this.props
    const height = this.divElement.clientHeight;

    annoucementHeightChange(height)

    return api(`/announcements/valid`, {
      method: 'GET'
    }).then(result => {

      let closedAnn = localStorage.getItem('closedAnn')

      closedAnn = _.split(closedAnn, ',')

      let balanceAnnouncements = _.filter(result, (announcement) =>{
        let index = _.findIndex(closedAnn, (closedId) => {
          return announcement._id == closedId
        })

        return index < 0
      })

      this.setState({ announcements: balanceAnnouncements });
    }).catch(err => {
      console.log(err)
    })
  }

  componentDidUpdate(){
    const { annoucementHeightChange } = this.props
    const height = this.divElement.clientHeight;

    annoucementHeightChange(height)
  }

  handleOnToggleNavbar = (navExpanded) => {
    this.setState({
      navExpanded
    });
  }

  handleToggleClose = (e) => {
    const { expanded } = this.state;
    expanded ?
      this.setState({
        expanded: false
      })
      :
      this.setState({
        expanded: true
      })
  }

  handleAnnouncementsCloseClick = (id) => {
    const { announcements } = this.state

    let balanceAnnouncements = _.filter(announcements, (announcement) =>{
      return announcement._id != id
    })

    let closedAnn = localStorage.getItem("closedAnn")

    if(closedAnn && closedAnn.length > 1000){
      closedAnn = ""
    }

    closedAnn = _.split(closedAnn, ",")

    if(!closedAnn || closedAnn.length == 0){
      closedAnn = []
    }

    closedAnn.push(id)

    localStorage.setItem("closedAnn", _.join(closedAnn, ","))

    this.setState({announcements: balanceAnnouncements})
  }

  render() {
    const {  location: {pathname}, user, notifications: { open_count, pickup_reminder_count } } = this.props;
    const { navExpanded, expanded, announcements } = this.state;

    return (
      <>
        <Navbar className="header py-0" bg="light" fixed="top" expand={false} style={{width: '100%'}}>
          <div ref={ (divElement) => { this.divElement = divElement } }>
            <NewAnnouncements announcements={announcements} onCloseClicked={this.handleAnnouncementsCloseClick}/>
          </div>
          <Container className="d-print-none d-md-none">
            <Navbar.Brand href="/">
              <img src={logo} alt="Giftr Seller Center" className="header-branding align-text-bottom"/>
              <h4 style={{display: 'inline-block', color: '#80d7cf'}}>Seller Center</h4>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={this.handleToggleClose}/>
            <Navbar.Offcanvas id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" placement="end" onHide={this.handleToggleClose} show={expanded}>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {
                    user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'supplier' || user.role === 'staff' || user.role === 'merchant-evouchers') &&
                      <Nav.Link
                        className={`${(isActiveRoute(pathname, '/dashboard') || isActiveRoute(pathname, '/')) && 'active'}`}
                        as={Link}
                        to='/dashboard' onClick={this.handleToggleClose}
                      ><FontAwesomeIcon icon={solid('house')} style={{margin: '0 14px'}}/>Dashboard</Nav.Link>
                  }
                  {
                    user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'supplier' || user.role === 'staff' || user.role === 'merchant-staff') &&
                      <Nav.Link
                        className={`${(isActiveRoute(pathname, '/orders')) && 'active'}`}
                        as={Link}
                        to='/orders' onClick={this.handleToggleClose}
                      ><FontAwesomeIcon icon={solid('clipboard')} transform="grow-2" style={{margin: '0 18px'}}/>Orders</Nav.Link>
                  }
                  {
                    user && (user.role === 'admin' || user.role === 'staff' || user.allow_procurement) &&
                      <Nav.Link
                        className={`${(isActiveRoute(pathname, '/procurement_orders')) && 'active'}`}
                        as={Link}
                        to='/procurement_orders' onClick={this.handleToggleClose}
                      ><FontAwesomeIcon icon={regular('clipboard')} transform="grow-2" style={{margin: '0 18px'}}/>Procurement Orders</Nav.Link>
                  }
                  {
                    user && (user.role === 'admin' || user.role === 'staff') &&
                      <Accordion className="header-accordion nav-accordion" defaultActiveKey="0">
                        <Accordion.Item style={{border:'none'}} eventKey="0">
                          <Accordion.Header>
                            <FontAwesomeIcon icon={solid('layer-group')} className="mx-3"/>
                              Products
                          </Accordion.Header>
                          <Accordion.Body className="ms-4">
                            <Nav.Link
                              className={`${(isActiveRoute(pathname, '/products')) && 'active'}`}
                              as={Link}
                              to='/products' onClick={this.handleToggleClose}
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >
                              Product Listing
                            </Nav.Link>
                            <Nav.Link
                              className={`${isActiveRoute(pathname, '/product_addons') && 'active'}`}
                              as={Link}
                              to="/product_addons" onClick={this.handleToggleClose}
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >
                              Product Add-ons
                            </Nav.Link>
                            <Nav.Link
                              className={`${isActiveRoute(pathname, '/delivery_templates') && 'active'}`}
                              as={Link}
                              to="/delivery_templates" onClick={this.handleToggleClose}
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >
                              Delivery Templates
                              <Badge className="mx-1" bg="danger">NEW</Badge>
                            </Nav.Link>
                            <Nav.Link
                              className={`${(isActiveRoute(pathname, '/products/pending')) && 'active'}`}
                              as={Link}
                              to='/products/pending' onClick={this.handleToggleClose}
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >Pending Products
                            </Nav.Link>
                            <Nav.Link
                              className={`${(isActiveRoute(pathname, '/taggings/product')) && 'active'}`}
                              as={Link}
                              to='/taggings/product' onClick={this.handleToggleClose}
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >Product Taggings
                            </Nav.Link>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                  }
                  {
                    ['merchant', 'merchant-staff'].includes(user.role) &&
                      <Accordion className="header-accordion nav-accordion" defaultActiveKey="0">
                        <Accordion.Item style={{border:'none'}} eventKey="0">
                          <Accordion.Header style={{color: '#80d7cf'}}>
                          <FontAwesomeIcon icon={solid('layer-group')} className="mx-3"/>
                            Products
                          </Accordion.Header>
                          <Accordion.Body className="pe-2" style={{marginLeft: '1.6rem'}}>
                            <Nav.Link
                              className={`${(isActiveRoute(pathname, '/products')) && 'active'}`}
                              as={Link}
                              to='/products' onClick={this.handleToggleClose}
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >
                              Product Listing
                            </Nav.Link>
                            <Nav.Link
                              className={`${isActiveRoute(pathname, '/product_addons') && 'active'}`}
                              as={Link}
                              to="/product_addons" onClick={this.handleToggleClose}
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >
                              Product Add-ons
                            </Nav.Link>
                            <Nav.Link
                              className={`${isActiveRoute(pathname, '/delivery_templates') && 'active'}`}
                              as={Link}
                              to="/delivery_templates" onClick={this.handleToggleClose}
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >
                              Delivery Templates
                              <Badge className="mx-1" bg="danger">NEW</Badge>
                            </Nav.Link>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                  }
                  {
                    user && (user.role === 'admin') &&
                      <Accordion className="header-accordion nav-accordion" defaultActiveKey="0">
                        <Accordion.Item style={{border:'none'}} eventKey="0">
                          <Accordion.Header style={{color: '#80d7cf'}}>
                          <FontAwesomeIcon icon={solid('percent')} style={{ margin: '0 18px' }} />
                            Promotions
                          </Accordion.Header>
                          <Accordion.Body className="pe-2" style={{marginLeft: '1.6rem'}}>
                            <Nav.Link
                              className={`${(isActiveRoute(pathname, '/promotions')) && 'active'}`}
                              as={Link}
                              to='/promotions' onClick={this.handleToggleClose}
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >
                              Merchant
                            </Nav.Link>
                            <Nav.Link
                              className={`${(isActiveRoute(pathname, '/giftr_promotions')) && 'active'}`}
                              as={Link}
                              to='/giftr_promotions' onClick={this.handleToggleClose}
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >
                              Giftr
                            </Nav.Link>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                  }
                  {
                    user && (user.role === 'merchant' || user.role === 'merchant-evouchers') &&
                      <Nav.Link
                        className={`${(isActiveRoute(pathname, '/promotions')) && 'active'}`}
                        as={Link}
                        to='/promotions' onClick={this.handleToggleClose}
                      ><FontAwesomeIcon icon={solid('percent')} style={{margin: '0 18px'}}/>
                        Promotions
                      </Nav.Link>
                  }
                  {
                    user && (user.role === 'admin' || user.role === 'merchant-evouchers' || user.role === 'staff') &&
                      <Nav.Link
                        className={`${isActiveRoute(pathname, '/giftcards') && 'active'}`}
                        as={Link}
                        to="/giftcards" onClick={this.handleToggleClose}
                      ><FontAwesomeIcon icon={solid('gift')} className="mx-3"/>
                        Giftcards
                      </Nav.Link>
                  }
                  {
                    user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'supplier' || user.role === 'staff' || user.role === 'merchant-staff') &&
                      <Nav.Link
                        className={`${isActiveRoute(pathname, '/tickets') && 'active'}`}
                        as={Link}
                        to="/tickets" onClick={this.handleToggleClose}
                      ><FontAwesomeIcon icon={solid('comments')} style={{margin: '0 14px'}}/>
                        Q&amp;A
                        {
                          open_count > 0 &&
                            <div className="ms-2 notify-badge">
                              {open_count}
                            </div>
                        }
                      </Nav.Link>
                  }
                  {
                    user && (user.role === 'admin') &&
                      <Nav.Link
                        className={`${isActiveRoute(pathname, '/merchants') && 'active'}`}
                        as={Link}
                        to="/merchants" onClick={this.handleToggleClose}
                      ><FontAwesomeIcon icon={solid('users')} style={{margin: '0 14px'}}/>
                        Merchants
                      </Nav.Link>
                  }
                  { country === 'MY' &&
                  <Accordion className="header-accordion nav-accordion" defaultActiveKey="0">
                    <Accordion.Item style={{border:'none'}} eventKey="0">
                      <Accordion.Header style={{color: '#80d7cf'}}>
                        <FontAwesomeIcon icon={solid('truck')} className="ms-3" style={{marginRight: '12px'}}/>
                        Shipments
                      </Accordion.Header>
                      <Accordion.Body className="pe-2" style={{marginLeft: '1.6rem'}}>
                        <Nav.Link
                          className={`${(isActiveRoute(pathname, '/courier')) && 'active'}`}
                          as={Link}
                          to='/courier' onClick={this.handleToggleClose}
                          style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                        >
                          Courier
                          {
                            pickup_reminder_count > 0 &&
                              <div className="ms-2 notify-badge">
                                {pickup_reminder_count}
                              </div>
                          }
                        </Nav.Link>
                        <Nav.Link
                          className={`${isActiveRoute(pathname, '/on_demand') && 'active'}`}
                          as={Link}
                          to="/on_demand" onClick={this.handleToggleClose}
                          style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                        >
                          On-Demand
                        </Nav.Link>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  }
                  {
                    user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'merchant-evouchers' || user.role === 'staff') &&
                      <Nav.Link
                        className={`${isActiveRoute(pathname, '/bills') && 'active'}`}
                        as={Link}
                        to="/bills" onClick={this.handleToggleClose}
                      ><FontAwesomeIcon icon={solid('receipt')} transform="grow-2" style={{margin: '0 19px'}}/>
                        Billings
                      </Nav.Link>
                  }
                  {
                    user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'merchant-evouchers' || user.role === 'staff' || user.role === 'merchant-staff') && (location.hostname && !location.hostname.endsWith('.sg')) &&
                      <Nav.Link
                        className={`${isActiveRoute(pathname, '/reviews') && 'active'}`}
                        as={Link}
                        to="/reviews" onClick={this.handleToggleClose}
                      ><FontAwesomeIcon icon={solid('star')} className="mx-3"/>
                        Reviews
                      </Nav.Link>
                  }
                  {
                    user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'merchant-evouchers' || user.role === 'staff' || user.role === 'merchant-staff') &&
                      <Accordion className="header-accordion nav-accordion" defaultActiveKey="0">
                        <Accordion.Item style={{border:'none'}} eventKey="0">
                          <Accordion.Header className="my-2">
                            <FontAwesomeIcon icon={solid('wrench')} className="mx-3"/>
                            Store Profile
                          </Accordion.Header>
                          <Accordion.Body className="ms-4">
                            {
                              user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'merchant-evouchers' || user.role === 'staff' || user.role === 'merchant-staff') && (location.hostname && !location.hostname.endsWith('.sg')) &&
                            <Nav.Link
                              className={`${(isActiveRoute(pathname, '/store')) && 'active'}`}
                              as={Link}
                              to='/store' onClick={this.handleToggleClose}
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >
                              Store Profile
                            </Nav.Link>
                            }
                            {
                              user.role !== 'admin' &&
                                <Nav.Link
                                  className={`${isActiveRoute(pathname, '/off_days') && 'active'}`}
                                  as={Link}
                                  to="/off_days"
                                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                                >
                                  Off Days
                                </Nav.Link>
                            }
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                  }
                  <Nav.Link
                    className={`${isActiveRoute(pathname, '/profile') && 'active'}`}
                    as={Link}
                    to="/profile" onClick={this.handleToggleClose}
                  ><FontAwesomeIcon icon={solid('user')} style={{margin: '0 18px'}}/>
                    My Account
                  </Nav.Link>
                  {
                    user && (user.role === 'admin' || user.role === 'staff' || user.role === 'merchant' || user.role === 'merchant-staff') &&
                      <Nav.Link
                        className={`${isActiveRoute(pathname, '/help') && 'active'}`}
                        as={Link}
                        to="/help" onClick={this.handleToggleClose}
                      ><FontAwesomeIcon icon={solid('question')} style={{margin: '0 20px'}}/>
                        Help
                      </Nav.Link>
                  }
                  {
                    user && (user.role === 'admin' || user.role === 'staff') &&
                      <Accordion className="header-accordion nav-accordion" defaultActiveKey="0">
                        <Accordion.Item style={{border:'none'}} eventKey="0">
                          <Accordion.Header className="my-2">
                            <FontAwesomeIcon icon={solid('wrench')} className="mx-3"/>
                            Admin Tools
                          </Accordion.Header>
                          <Accordion.Body className="ms-4">
                            {
                              user && user.role === 'admin' &&
                                <Nav.Link
                                  className={`${isActiveRoute(pathname, '/users') && 'active'}`}
                                  as={Link}
                                  to="/users"
                                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                                >
                                  Users
                                </Nav.Link>
                            }
                            <Nav.Link
                              className={`${isActiveRoute(pathname, '/customers') && 'active'}`}
                              as={Link}
                              to="/customers"
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >
                              Customers
                            </Nav.Link>
                            <Nav.Link
                              className={`${isActiveRoute(pathname, '/announcements') && 'active'}`}
                              as={Link}
                              to="/announcements"
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            >
                              Announcements
                            </Nav.Link>
                            <Nav.Link
                              className={`${isActiveRoute(pathname, '/logs') && 'active'}`}
                              as={Link}
                              to="/logs"
                              style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                            > Activity Log
                            </Nav.Link>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                  }
                  <Nav.Link className={`${isActiveRoute(pathname, '/logs') && 'active'}`} style={{color: '#D15D5D'}}
                    as={Link} to="/logout"><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="flip-h" className="mx-3"/>
                    Log Out
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default connect(state => {
  return {
    notifications: state.notifications
  }
})(Header);
