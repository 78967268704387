import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const renderField = (props) => {

  const { disabled, input, label, placeholder, name, onChangeValue, handleSubmit, type, isRenderLabel = true, meta: { touched, error }, small_label } = props

  return (
  <div className={`form-group ${touched && error ? "has-error has-feedback" : ""}`}>
    {
      isRenderLabel &&
      <label htmlFor={name}>{label}</label>
    }
    {
      isRenderLabel && small_label &&
      <span className="d-block small text-muted">{small_label}</span>
    }
    <textarea className="form-control" {...input} value={input.value} disabled={disabled} placeholder={placeholder} type={type} onChange={(e) => {
      input.onChange(e);
      if (onChangeValue) {
        onChangeValue(e);
      }
    }} />
    {
      touched && error &&
        <span style={{color: '#d15d5d'}}>
        <FontAwesomeIcon icon={solid('exclamation')} style={{marginRight: '6px'}}/>
        {error}
      </span>
    }
  </div>
  )
}

export default renderField;
