import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Alert } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import Dropzone from 'react-dropzone'
import { times } from 'lodash';
import uploadIcon from '../images/upload-icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import EditImageModal from '../components/EditImageModal';

class UploadStoreProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      image_url: '',
      deleting: false,
      edit_image_url: '',
      showEditImage: false,
    }
  }

  componentDidMount() {
    const { submitting , image_url, deleting } = this.props;
    this.setState({submitting, image_url, deleting})
    if (image_url) {
      this.setEditImageAsBase64(image_url)
    }
  }

  setEditImageAsBase64 = async (url) => {
    return fetch(url).then(response => response.blob()).then(blob => {
      const reader = new FileReader()
      reader.onloadend = () => {
        this.setState({ edit_image_url: reader.result })
      }
      reader.onerror = console.log
      reader.readAsDataURL(blob)
    })
  }

  setBase64ToFile = async (base64) => {
    base64 = base64.replace('data:image/jpeg;base64,', '')

    const imageContent = atob(base64);
    const buffer = new ArrayBuffer(imageContent.length);
    const view = new Uint8Array(buffer);

    for (let n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
    const type = 'image/jpeg';
    const blob = new Blob([buffer], { type });

    return new File([blob], 'image.jpeg', { lastModified: new Date().getTime(), type })
  }

  submit = async (values) => {
    const { image_url } = this.state
    const { onClickSave } = this.props;

    let image = await this.setBase64ToFile(image_url)

    this.setState({ submitting: true });
    onClickSave(image)
  }

  delete = (files) => {
    const { file } = this.state;
    const { onClickDelete } = this.props;

    this.setState({
      deleting: true,
      image_url: null
    });

    onClickDelete(file);
  }

  onClickEditImage = () => {
    this.setState({
      showEditImage: true,
    });
  }

  onCloseEditImage = () => {
    this.setState({
      showEditImage: false,
    });
  }

  onDropImage(files) {
    if(files && files.length > 0){
      this.setState({
        file: files[0],
        edit_image_url: files[0].preview,
        showEditImage: true,
        submitting: false,
        deleting: false
      });
    }
  }

  updateImage = (image) => {
    this.setState({
      showEditImage: false,
      image_url: image,
      edit_image_url: image
    });
  }

  render() {
    const { handleSubmit, error, aspectRatio } = this.props
    const { image_url, edit_image_url, submitting, deleting, showEditImage } = this.state
    return (
      <>
        <form onSubmit={handleSubmit(this.submit)}>
            {
                error &&
                <Alert variant="danger">
                {
                    Array.isArray(error) ?
                    <ul>
                    {
                    error.map((err, i) => (
                        <li key={i}>
                        {err.msg}
                        </li>
                    ))
                    }
                    </ul>
                    :
                    error
                }
                </Alert>
            }
            <Dropzone
              className='dropzone-area'
              onDrop={this.onDropImage.bind(this)}
              multiple={false}
              accept='image/*'>
              <div className="dropzone-text">
                <img src={uploadIcon} style={{width: '30px', margin: '0 10px'}}></img>
                <span>Drag and drop files here, or click to upload</span>
              </div>
            </Dropzone>
            <div className="row" style={{margin: '20px 0'}}>
            {
              deleting &&
              <div className="row" style={{textAlign: 'center'}}>
                <span style={{color: '#d15d5d'}}>Image removed</span>
              </div>
            }
            <div className="col-sm-8" style={{padding: '0'}}>
                <img onClick={this.onClickEditImage} src={image_url} style={{maxWidth: '300px'}}/>
            </div>
            { image_url &&
            <div className="col-sm-4 col-xs-12 mt-2 mt-md-0" style={{textAlign: 'center', padding: '0'}}>
              <Button
                style={{width: '100%', float: 'right', margin: '10px 0'}}
                className="btn-br-6"
                variant="primary"
                type="submit"
                disabled={submitting}
              >
                  {submitting ? 'Changes saved' : 'Save changes'}
              </Button>

              <Button
              variant="danger-secondary"
              className="btn-br-6"
              style={{width: '100%', float: 'right', margin: '10px 0'}}
              onClick={this.delete}
              > Remove
              </Button>
              </div>
            }
            </div>

        </form>
        { showEditImage &&
        <EditImageModal onClose={this.onCloseEditImage} image={edit_image_url} done={this.updateImage} aspectRatio={aspectRatio}/>
        }
        </>
    );
  }
}

UploadStoreProfile = reduxForm({
    form: 'UploadStoreProfile',
    destroyOnUnmount: true,
    enableReinitialize: true,
  })(UploadStoreProfile);

export default connect((state, props) => {
  const { match } = props;
  return {};
}, {
})(UploadStoreProfile);
