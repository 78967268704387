import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import GTRAlert from '../components/GTRAlert';

const EditHelpModal = ({onClose, value, setValue, onClickSave, title, tab_id, saved}) => {
  const { item: quick_helps } = useSelector(state => state.quick_helps)

  useEffect(() => {
    if(quick_helps && quick_helps.length){
      const tab = _.find(quick_helps, i => i.tab_id === tab_id)

      if(tab){
        const lines = _.map(tab.pages, page => {
          return `${page.title} | ${page.link}`
        });

        setValue(lines.join('\n'));
      }
    }
  }, [])

  return (
    <>
      <Modal show={true} onHide={onClose} size="lg">
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0">Edit Help for {title}</h4>
        </Modal.Header>
        <Modal.Body className="px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          <div className="mx-4 d-grid gap-3">
            <p>
              - Format: title | link<br/>
              - New line for each help page<br/><br/>
              E.g.<br/>
              Mark as Accepted | https://doc.clickup.com/123<br/>
              Fulfill Order | https://doc.clickup.com/456
            </p>
            <textarea value={value} className="form-control" onChange={e => setValue(e.target.value)} style={{height: '150px'}}/>
            <Button className="btn-br-6 mb-3" onClick={onClickSave}>
              Save
            </Button>
            {
              saved &&
                <GTRAlert className="m-0 text-center" variant="success" alert={saved} />
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditHelpModal;
