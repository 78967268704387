import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const YTLinkModal = ({ onClose, onSave, size }) => {

  const [error, setError] = useState('');

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = e.target.link.value.match(regExp);
    if (match && match[2].length == 11) {
      onSave(match[2])
    } else {
      setError('Invalid link');
    }
  }

  return (
    <Modal show={true} onHide={onClose} size={size}>
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4 className="mb-0">YouTube Link</h4>
      </Modal.Header>
      <form onSubmit={submit}>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          <input type="text" className="form-control" id="link" name="link" placeholder="Add link"/>
          { error &&
          <span style={{color: '#d15d5d'}}>
            <FontAwesomeIcon icon={solid('exclamation')} style={{marginRight: '6px'}}/>
            {error}
          </span>
          }
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={onClose}>Cancel</Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default YTLinkModal;
