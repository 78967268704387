import React, { Component } from 'react';
import { connect } from 'react-redux';

const docLink = window.location.hostname === 'seller.giftr.my' ? 'https://doc.clickup.com/3851406/d/h/3nh4e-7048/aba3461aa44c259/3nh4e-13248' : 'https://doc.clickup.com/3851406/d/h/3nh4e-6268/ee57803f424e6d3'

class Help extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {

    return (
      <div style={{height: 'calc(100vh - 90px)'}}>
        <iframe src={docLink} width="100%" height="100%" style={{background: 'transparent', border: '1px solid #ccc'}}></iframe><script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    user: state.auth.user,
  };
}, )(Help);

