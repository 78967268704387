import qs from "qs";
import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_MANUAL_ORDER_DETAIL_REQUEST = 'FETCH_MANUAL_ORDER_DETAIL_REQUEST';
export const FETCH_MANUAL_ORDER_DETAIL_SUCCESS = 'FETCH_MANUAL_ORDER_DETAIL_SUCCESS';
export const FETCH_MANUAL_ORDER_DETAIL_FAILURE = 'FETCH_MANUAL_ORDER_DETAIL_FAILURE';
export const CLEAR_MANUAL_ORDER_DETAIL = 'CLEAR_MANUAL_ORDER_DETAIL';

function fetchManualOrderDetailRequest(orderId) {
  return {
    type: FETCH_MANUAL_ORDER_DETAIL_REQUEST,
    payload: {
      orderId,
    }
  };
}

function fetchManualOrderDetailSuccess(json) {
  return {
    type: FETCH_MANUAL_ORDER_DETAIL_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchManualOrderDetailFailure() {
  return {
    type: FETCH_MANUAL_ORDER_DETAIL_FAILURE
  };
}

function shouldFetchManualOrderDetail(state) {
  const results = state.order;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

function fetchManualOrderDetailFromApi(orderId) {
  return dispatch => {
    dispatch(fetchManualOrderDetailRequest(orderId));
    return api(`/manual_orders/${orderId}`)
      .then(json => {
        dispatch(fetchManualOrderDetailSuccess(json));
      })
      .catch(err => {
        dispatch(fetchManualOrderDetailFailure());
        dispatch(addError(err));
      });
  };
}

export function setManualOrderDetail(order) {
  return dispatch => {
    dispatch(fetchManualOrderDetailSuccess(order));
  }
}

export function fetchManualOrderDetail(orderId) {
  return (dispatch, getState) => {
    if (shouldFetchManualOrderDetail(getState())) {
      return dispatch(fetchManualOrderDetailFromApi(orderId));
    }
  };
}

export function clearManualOrderDetail() {
  return {
    type: CLEAR_MANUAL_ORDER_DETAIL,
  };
}
