import qs from "qs";
import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_DASHBOARD_REQUEST = 'FETCH_DASHBOARD_REQUEST';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';

function fetchDashboardRequest() {
  return {
    type: FETCH_DASHBOARD_REQUEST,
    payload: {
    }
  };
}

function fetchDashboardSuccess(json) { 

  return {
    type: FETCH_DASHBOARD_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchDashboardFailure() {
  return {
    type: FETCH_DASHBOARD_FAILURE
  };
}

function shouldFetchDashboard(state) {
  const results = state.dashboard;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

function fetchDashboardFromApi(vendor) {

  const queryString = qs.stringify({
    vendor
  }, { skipNulls: true });

  return dispatch => {
    dispatch(fetchDashboardRequest());
    return api(`/dashboard?${queryString}`)
      .then(json => {
        dispatch(fetchDashboardSuccess(json));
      })
      .catch(err => {
        dispatch(fetchDashboardFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchDashboard(vendor) {
  return (dispatch, getState) => {
    if (shouldFetchDashboard(getState())) {
      return dispatch(fetchDashboardFromApi(vendor));
    }
  };
}