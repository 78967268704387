import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { Modal, Button, Card, Row, Col, Form } from 'react-bootstrap';
import Loader from './Loader';
import moment from 'moment';
import _ from 'lodash';
import api from "../helpers/apiClient";
import BootstrapTable from 'react-bootstrap-table-next';
import * as shippingActionCreators from '../redux/actions/shipping';
import { SingleDatePicker } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { exportAsPDFCN, exportAsPDFCNMulti } from '../helpers/htmlToCanvas';
import Profile from '../containers/Profile';
import GTRAlert from './GTRAlert';
import * as errorActionCreators from '../redux/actions/error';
import motorcycleImg from '../images/lalamove_motorcycle.png'
import carImg from '../images/lalamove_car.png'
import pickupImg from '../images/lalamove_4x4.png'
import vanImg from '../images/lalamove_van.png'
import lorryImg from '../images/lalamove_lorry.png'
import ReactTooltip from "react-tooltip";
import { parseNumber } from 'libphonenumber-js';
import { getCountryCallingCode } from 'react-phone-number-input';
import toast from 'react-hot-toast';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import config from '../config'
import ConfirmAddressModal from './ConfirmAddressModal';

const DATE_FORMAT = config.lalamoveDateFormat;
const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

const ShippingModal = (props) => {
  const { user, onClose, confirmShipment, match, parcels = [], error, resetError, hasShippingFee, isProcurement = false } = props;
  const { fetchShippingRates, makeOrder, undoMakeOrder, makePayment, resetData, makeDHLShipment } = props;
  const isAdmin = user && user.role === 'admin' || user.role === 'staff'

  const { shipping } = useSelector(state => state);
  const [address, setAddress] = useState({});
  const [confirmedSenderAddress, setConfirmedSenderAddress] = useState(null);
  const [senderConfirmedAddressChanged, setSenderConfirmedAddressChanged] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [hasSenderAddress, setHasSenderAddress] = useState(false);
  const [pickupDate, setPickupDate] = useState(null);
  const [focused, setFocused] = useState(null);
  const [service, setService] = useState(null);
  // const [pickup, setPickup] = useState(true);
  const [dhlLabels, setDhlLabels] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showPickupAddressModal, setShowPickupAddressModal] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [editingDeliveryAddress, setEditingDeliveryAddress] = useState(false);
  const [parcelShippingAddress, setParcelShippingAddress] = useState([]);
  const [confirmedParcelShippingAddress, setConfirmedParcelShippingAddress] = useState(null);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [vehicleSelected, setVehicleSelected] = useState(null);
  const [specialRequests, setSpecialRequests] = useState([]);
  const [quotation, setQuotation] = useState(null);
  const [lalamoveShipmentCreated, setLalamoveShipmentCreated] = useState(false);
  const [lalamoveAgree, setLalamoveAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultTime] = useState(moment());
  const [scheduleAt, setScheduleAt] = useState(null);
  const [showConfirmAddressModal, setShowConfirmAddressModal] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState('');
  const [addressToConfirm, setAddressToConfirm] = useState('');
  const cnMultiRef = useRef(null);

  const dhlShipmentCreated = shipping.shipments && shipping.shipments.length > 0 && shipping.shipments[0].dhlShipmentId;
  const easyparcelShipmentCreated = shipping.payment && shipping.payment[0]['REQ_ID'];

  useEffect(() => {
    let parcelShippingAddress = _.map(parcels, parcel => {
      return {
        ...parcel.shipping_address,
        phone: parcel.shipping_address.phone.replace(/\s|-/g, '')
      }
    })
    setParcelShippingAddress(JSON.parse(JSON.stringify(parcelShippingAddress)));
    resetError();
  }, [])

  useEffect(() => {
    if (!address || !parcelShippingAddress || !vehicleSelected || !specialRequests || !confirmedParcelShippingAddress || !confirmedSenderAddress) {
      return
    }
    getQuotation()
  }, [address, parcelShippingAddress, vehicleSelected, specialRequests, scheduleAt, confirmedParcelShippingAddress, confirmedSenderAddress])

  // useEffect(() => {
  //   if (service) {
  //     fetchHolidays();
  //   }
  // }, [service])

  const fetchHolidays = async () => {
    const hol = await api(`/shipments/holidays`);
    setHolidays(hol)
  }

  useEffect(() => {
    if(dhlShipmentCreated) {
      confirmShipment(shipping.shipments, pickupDate ? pickupDate.format("YYYY-MM-DD") : "", service);
      if (shipping.shipments[0].content) { // INFO: old method
        setDhlLabels(_.map(shipping.shipments, shipment => shipment.content));
      }
    }
    if(easyparcelShipmentCreated && pickupDate){
      confirmShipment(shipping.payment, pickupDate.format("YYYY-MM-DD"), service);
    }
    if(shipping.rates.length !== 0 && !easyparcelShipmentCreated && !selectedRow) { // default select pgeon
      let row = _.find(shipping.rates, rate => {
        return rate.service_id === 'EP-CS0K3'
      })
      setServiceId(row.service_id);
      setSelectedRow(row);
      setLoading(false)
    }
  }, [shipping])

  const handleOnClose = () => {
    setDhlLabels([]);
    resetData();

    if (!shipping.loading) {
      onClose();
    }
  }

  const handleRowSelected = (row, isSelected, e) => {

    setServiceId(row.service_id);
    setSelectedRow(row);
  }

  const handleOnClickNext = () => {

    if (service === 'easyparcel') {
      makeOrder(pickupDate.format("YYYY-MM-DD"), serviceId, address, parcels)
    }

    if (service === 'dhl') {
      setShowDhlConfirm(true);
    }
  }

  const handleOnClickConfirm = () => {

    makePayment(parcels, selectedRow.courier_name, pickupDate.format("YYYY-MM-DD"), address);
  }

  const handleOnClickBack = () => {
    undoMakeOrder();
  }

  const isOutsideRange = (date) => {

    let now = moment()
    let startDate = moment().startOf('day')
    let endDate = moment().endOf('day')
    if(now.hour() > 11){
      startDate.add(1, 'days')
      endDate.add(1, 'days')
    }

    if(startDate.day() === 0){
      endDate.add(5, 'days')
    } else if(startDate.day() === 6){
      endDate.add(6, 'days')
    } else if(startDate.day() === 5){
      endDate.add(6, 'days')
    } else if(startDate.day() === 4){
      endDate.add(6, 'days')
    } else {
      endDate.add(4, 'days')
    }

    let isHoliday = false;
    _.each(holidays, holiday => {
      if (date.isSame(moment(holiday, 'MM/DD/YYYY'), 'day')) {
        isHoliday = true;
      }
    })
    if(isHoliday) {
      return true
    }

    if (service === 'easyparcel' && date.day() == 6) {
      return true
    }

    return date.day() == 0 || date.isBefore(startDate) || date.isAfter(endDate);
  }

  const scheduleIsOutsideRange = (date) => {

    let startDate = moment().startOf('day')
    let endDate = moment().endOf('day')
    endDate.add(29, 'days')

    return date.isBefore(startDate) || date.isAfter(endDate);
  }

  const handleSelectPickupDate = (date) => {
    if(moment().isSame(date, 'day')){
      date = moment(date).utcOffset(8).set('hour', '1')
    } else {
      date = moment(date).utcOffset(8).set('hour', '10')
    }

    setPickupDate(date);
  }

  const handleSelectScheduleAt = (date, type) => {
    if (type === 'date') {
      let scheduleDate = scheduleAt ? moment(scheduleAt) : moment().add(5, 'minutes')
      date = scheduleDate.set({
        'year': moment(date).get('year'),
        'month': moment(date).get('month'),
        'date': moment(date).get('date')
      })
    }

    setScheduleAt(date);
  }

  const handleClearScheduleAt = () => {
    setScheduleAt(null)
  }

  const fetchProfile = async () => {
    return api(`/profile`).then(profile => {
      setAddress(profile);
      if (profile.confirmedLocation && profile.confirmedLocation.saved) {
        const { latitude, longitude , address } = profile.confirmedLocation
        setConfirmedSenderAddress({
          coordinates: {
            lat: latitude.toString(),
            lng: longitude.toString(),
          },
          address: address,
        })
        setSenderConfirmedAddressChanged(false);
      }

      return profile
    })
  }

  const loadService = async (service) => {
    setLoading(true)

    let profile = await fetchProfile()

    if(!profile.name || !profile.address1 || !profile.postcode){
      setHasSenderAddress(false);
      setLoading(false)
      return
    }

    setHasSenderAddress(true);

    if (service === "easyparcel") {
      return fetchShippingRates(profile.postcode, parcels)
    }

    if (service === 'lalamove' && vehicleOptions.length === 0) {
      const result = await api(`/delivery/services`)
      setVehicleOptions(_.filter(result, service => !_.includes(service.key, 'TRUCK')))
    }
    setLoading(false)
  }

  const confirmDHLShipment = async () => {
    // if (pickup) {
    //   makeDHLShipmentWithPickup(parcels, parcelShippingAddress, pickupDate.format("YYYY-MM-DDThh:mm:ssZ"), address);
    // } else {
      makeDHLShipment(parcels, parcelShippingAddress, isProcurement, address)
    // }
  }

  const printDHLCN = async (isDoc) => {
    if (loading) {
      return
    }

    if (dhlLabels.length > 0) { // INFO: old method
      if (isDoc) {
        exportAsPDFCNMulti(cnMultiRef.current);
      } else {
        exportAsPDFCN(cnMultiRef.current);
      }
      return
    }

    setLoading(true);
    let ids = _.map(shipping.shipments, "dhlShipmentId");
    // INFO: new method
    return api(`/dhl/label/custom/print?ids=${_.join(ids, ',')}&isDoc=${isDoc}`).then(function(result) {
      window.open(result, '_blank');
      setLoading(false);
    })
  }

  const handleOnClickService = (service) => {
    if (!service) {
      return
    }

    loadService(service);

    setService(service);
  }

  const handleEditPickupAddress = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowPickupAddressModal(true);
  }

  const handleBackToShipment = async () => {
    setShowPickupAddressModal(false);

    return loadService(service);
  }

  const editDeliveryAddress = () => {
    setEditingDeliveryAddress(true);
  }

  const saveDeliveryAddress = (e, index) => {
    e.preventDefault();
    let addresses = [...parcelShippingAddress];
    addresses[index].zip = e.target.zip.value
    addresses[index].phone = e.target.phone.value
    setParcelShippingAddress(addresses);
    setEditingDeliveryAddress(false);
  }

  const onClickVehicle = async (vehicle) => {
    if (vehicleSelected && vehicleSelected.key === vehicle.key) {
      return
    }

    setSpecialRequests([])
    setVehicleSelected(vehicle)
  }

  const onCheckSpecialRequest = async (e, key, item) => {
    let selectedSpecialRequests = _.filter(specialRequests, request => request && request.name && !request.name.includes(key))
    if (e.target.checked) {
      selectedSpecialRequests.push(item)
      setSpecialRequests(selectedSpecialRequests)
    } else {
      setSpecialRequests(selectedSpecialRequests)
    }
  }

  const getQuotation = async () => {

    if (!confirmedParcelShippingAddress || !confirmedSenderAddress) {
      return
    }

    setLoading(true)
    return api(`/delivery/quote`, {
      method: 'POST',
      body: JSON.stringify({
        pickAddress: confirmedSenderAddress,
        sendAddress: confirmedParcelShippingAddress,
        service: vehicleSelected.key,
        specialRequests: _.map(specialRequests, request => request.name),
        scheduleAt: scheduleAt && moment(scheduleAt).utcOffset(0).format(DATE_FORMAT),
      })
    }).then((result) => {
      setQuotation(result)
      setLoading(false)
    }).catch(err => {
      if (err.includes('scheduleAt')) {
        return toast.error('Cannot schedule a past date/time.');
      }
      toast.error(err);
    })
  }

  const placeLalamoveOrder = async () => {
    setLoading(true)
    let senderPhone = parseNumber(address.contact, 'MY');
    let recipientPhone = parseNumber(parcelShippingAddress[0].phone, 'MY')

    if (!senderPhone.phone || !recipientPhone.phone) {
      setLoading(false)
      return
    }

    senderPhone = "+" + getCountryCallingCode('MY') + senderPhone.phone
    recipientPhone = "+" + getCountryCallingCode('MY') + recipientPhone.phone

    let lineItems = parcels[0].line_items.map(lineItem => {
      return {
        line_item_id: lineItem.id,
        name: lineItem.name,
        orderId: lineItem.order_id,
        orderName: lineItem.order_name,
      }
    })

    return api(`/delivery/order`, {
      method: 'POST',
      body: JSON.stringify({
        quotationId: quotation.id,
        senderName: address.name,
        senderPhone: senderPhone,
        recipientName: parcelShippingAddress[0].first_name + " " + parcelShippingAddress[0].last_name,
        recipientPhone: recipientPhone,
        orderId: parcels[0].order_id,
        orderName: parcels[0].order_name,
        specialRequests: specialRequests,
        lineItems: lineItems,
        scheduleAt: scheduleAt && scheduleAt.format(),
      })
    }).then(shipment => {
      confirmShipment(shipment, "", "lalamove")
      setLalamoveShipmentCreated(true)
      setLoading(false)
      toast(
        <div>Shipment created. View it <a href={`/on_demand/${shipment._id}`}>here</a></div>
        , { duration: 15000 });
    })

  }

  const onClickConfirmSenderAddress = () => {
    setShowConfirmAddressModal(true)
    let defaultAddress = address.address1
    if (address.address2) {
      defaultAddress += " " + address.address2
    }
    defaultAddress += " " + address.postcode + " " + address.city
    setDefaultAddress(defaultAddress)
    setAddressToConfirm('sender')
  }

  const onClickConfirmDeliveryAddress = () => {
    setShowConfirmAddressModal(true)
    let defaultAddress = parcelShippingAddress[0].address1
    if (parcelShippingAddress[0].address2) {
      defaultAddress += " " + parcelShippingAddress[0].address2
    }
    defaultAddress += " " + parcelShippingAddress[0].zip + " " + parcelShippingAddress[0].city
    setDefaultAddress(defaultAddress)
    setAddressToConfirm('delivery')
  }

  const handleConfirmLocation = async (latitude, longitude, address) => {
    let url = `/profile/confirm/location`
    return api(url, {
      method: 'POST',
      body: JSON.stringify({
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        address,
      })
    }).then(fetchProfile)
  }

  const onClickSaveSenderConfirmedAddress = () => {
    const { coordinates, address } = confirmedSenderAddress
    handleConfirmLocation(coordinates.lat, coordinates.lng, address)
  }

  const handleConfirmAddress = (location, formatted_address) => {
    if (addressToConfirm === 'sender') {
      setConfirmedSenderAddress({
        coordinates: {
          lat: location.lat.toString(),
          lng: location.lng.toString(),
        },
        address: formatted_address,
      })
      if (!address.confirmedLocation.saved) {
        handleConfirmLocation(location.lat, location.lng, formatted_address)
      } else {
        setSenderConfirmedAddressChanged(true)
      }
    }

    if (addressToConfirm === 'delivery') {
      setConfirmedParcelShippingAddress({
        coordinates: {
          lat: location.lat.toString(),
          lng: location.lng.toString(),
        },
        address: formatted_address,
      })
    }

    setShowConfirmAddressModal(false)
  }

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    selected: ['EP-CS0K3'],
    onSelect: handleRowSelected,
    headerColumnStyle: { width: '30px' },
  };

  const ordersCol = [
    {
      dataField: 'service_id',
      text: 'Service ID',
      hidden: true,
    },
    {
      dataField: 'service_name',
      text: 'Name',
      headerStyle: { width: '300px' },
      formatter: (_, row) => {
        return (
          <div>
            <span>{row.courier_name}</span><br/>
            <img src={row.courier_logo} alt={row.service_name} className='shipping-service-image' />
          </div>
        )
      }
    },
    {
      dataField: 'pickup_date',
      text: 'Delivery',
      formatter: (_, row) => {
        return (
          <div>
            <span>{row.delivery}</span>
          </div>
        )
      },
    },
  ];

  return (
    <>
      <Modal style={{border: 'none'}} show={true} onHide={handleOnClose} size="xl">
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0 text-capitalize">Arrange Shipment
            <span className="text-capitalize">
              { service === 'dhl' && ' - DHL' }
              { service === 'lalamove' && ' - Lalamove' }
            </span>
          </h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{borderTop: '1px solid #dee2e6'}}>
          {
            parcels.length === 0 &&
              <p className="text-center mb-0">Shipments created for all orders</p>
          }
          {
            service === 'easyparcel' && shipping.shipments > 0 && shipping.shipments[0].status === 'Fail' &&
              <div role="alert" className="alert alert-danger">{shipping.shipments[0].status}: {shipping.shipments[0].remarks}</div>
          }
          {
            !service && parcels.length > 0 &&
              <Row>
                <div className="col-sm-6 col-xs-12 d-grid gap-3">
                  <h5 className="mb-0">Courier</h5>
                  <Card onClick={() => handleOnClickService((isAdmin || hasShippingFee) && "dhl")} className={`help-option tealCard shadow-sm ${(isAdmin || hasShippingFee) ? '' : 'faded'}`}>
                    <Card.Body className="m-2 d-grid gap-3">
                      DHL
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-sm-6 col-xs-12 d-grid gap-3">
                  <h5 className="mb-0">On-Demand</h5>
                  <Card onClick={() => handleOnClickService("lalamove")} className={`help-option tealCard shadow-sm`}>
                    <Card.Body className="m-2 d-grid gap-3">
                      Lalamove
                    </Card.Body>
                  </Card>
                </div>
              </Row>
          }
          { (service === 'dhl' || service === 'lalamove') && parcels &&
              parcels.map((parcel, i) => {
                return (
                  <Card key={i} className="tealCard shadow-sm">
                    <Card.Header className="tealCardHeader">Parcel #{i + 1}</Card.Header>
                    <Card.Body className="d-grid gap-2 grey-label label-strong">
                      <div className="d-grid gap-1">
                        <label>Items</label>
                        {parcel.line_items.map((single, j) => {
                          return (
                            <div key={j} className="delivery-info-value">{single.name || single.title} x {single.quantity} {service === 'dhl' ? single.grams + "g" : ""}</div>
                          )
                        })}
                      </div>
                      { service !== 'lalamove' &&
                      <div className="d-grid gap-1">
                        <label>Total Weight</label>
                        <span className="delivery-info-value">{parcel.weight}g</span>
                      </div>
                      }
                      {
                        isAdmin && parcel.shipping_lines && parcel.shipping_lines[0] && parcel.shipping_lines[0].price !== '0.00' &&
                          <div className="d-grid gap-1">
                            <label>Shipping Paid</label>
                            <span>{currency} {parcel.shipping_lines[0].price}</span>
                          </div>
                      }
                    </Card.Body>
                  </Card>
                )
              })
          }
          { (service === 'dhl' || service === 'lalamove') &&
            <Row>
              <div className="col-sm-6 col-xs-12">
                <Card className="tealCard shadow-sm">
                  <Card.Header className="tealCardHeader">
                    { service === 'lalamove' ?
                      'Pick-up Address'
                      :
                      'Sender Address'
                    }
                  </Card.Header>
                  <Card.Body className="d-grid gap-2 label-strong grey-label">
                    {
                      !hasSenderAddress && !loading &&
                        <Card className="redCard">
                          <Card.Header className="redCardHeader">
                            { service === 'lalamove' ?
                                'Pick-up address not found'
                                :
                                'Sender address not found'
                            }
                          </Card.Header>
                          <Card.Body>
                            Please enter your address <a onClick={handleEditPickupAddress} href="">here</a>.
                          </Card.Body>
                        </Card>
                    }
                    {
                      !hasSenderAddress && loading &&
                        <Loader/>
                    }
                    {
                      hasSenderAddress && address.name &&
                        <Card className="position-relative">
                          <Button variant="secondary" className="btn-sm position-absolute btn-sm end-0 top-0 m-3" onClick={handleEditPickupAddress}>
                            Edit
                          </Button>
                          <Card.Body className="d-grid gap-1">
                            <label>{address.name}</label>
                            {
                              address.company &&
                                <>{address.company}<br/></>
                            }
                            {address.address1},<br/>
                            {
                              address.address2 &&
                                <>{address.address2},<br/></>
                            }
                            {address.postcode}, {address.city},<br/>
                            {address.state}, {address.country}<br/>
                            {address.contact}
                          </Card.Body>
                        </Card>
                    }
                  </Card.Body>
                  { service === 'lalamove' &&
                  <Card.Footer className="d-flex justify-content-end">
                    { confirmedSenderAddress ?
                      <Card bg="warning" className="bg-opacity-75 w-100">
                        <Card.Body className="d-grid gap-1 p-2 small">
                          <label>Confirmed Address</label>
                          <i>{confirmedSenderAddress.address}</i>
                          <div className="d-flex gap-1 justify-content-end">
                            <Button variant="primary" size="sm" onClick={onClickSaveSenderConfirmedAddress} disabled={!senderConfirmedAddressChanged}>
                              {senderConfirmedAddressChanged ? 'Save' : 'Saved'}
                            </Button>
                            <Button variant="secondary" size="sm" onClick={onClickConfirmSenderAddress}>Change</Button>
                          </div>
                        </Card.Body>
                      </Card>
                      :
                      <Button onClick={onClickConfirmSenderAddress} size="sm" disabled={!hasSenderAddress}>Confirm Address</Button>
                    }
                  </Card.Footer>
                  }
                </Card>
              </div>
              <div className="col-sm-6 col-xs-12">
                <Card className="tealCard shadow-sm">
                  <Card.Header className="tealCardHeader">Delivery Address</Card.Header>
                  <Card.Body className="d-grid gap-2 label-strong grey-label">
                    { parcels &&
                        parcels.map((parcel, i) => {
                          return (
                            <React.Fragment key={i}>
                              <label>Parcel #{i + 1}</label>
                              <Card>
                                <Card.Body className="position-relative">
                                  { editingDeliveryAddress ?
                                    <>
                                      <Form onSubmit={(e) => saveDeliveryAddress(e, i)}>
                                        <Button type="submit" className="position-absolute btn-sm end-0 top-0 m-3">
                                          Save
                                        </Button>
                                        <Form.Group className="mb-3">
                                          <Form.Label>Postcode</Form.Label>
                                          <Form.Control defaultValue={parcelShippingAddress[i].zip} name="zip" type="text" placeholder="Enter postcode" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                          <Form.Label>Phone Number</Form.Label>
                                          <Form.Control defaultValue={parcelShippingAddress[i].phone} name="phone" type="text" placeholder="Enter phone number" />
                                        </Form.Group>
                                      </Form>
                                    </>
                                    :
                                    <>
                                      { service === 'dhl' && !isProcurement &&
                                      <Button variant="secondary" className="position-absolute btn-sm end-0 top-0 m-3" onClick={editDeliveryAddress}>
                                        Edit
                                      </Button>
                                      }
                                      {
                                        parcelShippingAddress[i] &&
                                          <>
                                            <label>{parcelShippingAddress[i].name}</label><br/>
                                            {parcelShippingAddress[i].address1},<br/>
                                            {
                                              parcelShippingAddress[i].address2 &&
                                                <>{parcelShippingAddress[i].address2},<br/></>
                                            }
                                            {parcelShippingAddress[i].zip}, {parcelShippingAddress[i].city}<br/>
                                            {parcelShippingAddress[i].province_code}, {parcelShippingAddress[i].country_code}<br/>
                                            {parcelShippingAddress[i].phone}
                                          </>
                                      }
                                    </>
                                  }
                                </Card.Body>
                              </Card>
                            </React.Fragment>
                          )
                        })
                    }
                  </Card.Body>
                  { service === 'lalamove' &&
                  <Card.Footer className="d-flex justify-content-end">
                    { confirmedParcelShippingAddress ?
                      <Card bg="warning" className="bg-opacity-75 w-100">
                        <Card.Body className="d-grid gap-1 p-2 small">
                          <label>Confirmed Address</label>
                          <i>{confirmedParcelShippingAddress.address}</i>
                          <div className="text-end">
                            <Button variant="secondary" size="sm" onClick={onClickConfirmDeliveryAddress}>Change</Button>
                          </div>
                        </Card.Body>
                      </Card>
                      :
                      <Button onClick={onClickConfirmDeliveryAddress} size="sm">Confirm Address</Button>
                    }
                  </Card.Footer>
                  }
                </Card>
              </div>
            </Row>
          }
          { service === 'lalamove' && vehicleOptions &&
            <Card className="tealCard">
              <Card.Header className="tealCardHeader">Vehicles</Card.Header>
              <Card.Body className="d-grid gap-2 label-strong grey-label">
                {
                  vehicleOptions.length === 0 && loading &&
                    <Loader/>
                }
                <Row>
                  { _.map(_.sortBy(vehicleOptions, v => parseInt(v.load.value)), (vehicle) => {
                    let img = '', title = ''
                    switch (vehicle.key) {
                      case 'MOTORCYCLE':
                        img = motorcycleImg
                        title = 'Motorcycle'
                        break;
                      case 'CAR':
                        img = carImg
                        title = 'Car'
                        break;
                      case '4X4':
                        img = pickupImg
                        title = 'Pickup (4x4)'
                        break;
                      case 'VAN':
                        img = vanImg
                        title = 'Van 7-ft'
                        break;
                      case 'LARGEVAN':
                        img = vanImg
                        title = 'Large Van 9-ft'
                        break;
                      case 'TRUCK330':
                        img = lorryImg
                        title = 'Small Lorry 10-ft'
                        break;
                      case 'TRUCK550':
                        img = lorryImg
                        title = 'Medium Lorry 14-ft'
                        break;
                      default:
                        break;
                    }

                    let selected = vehicleSelected && vehicleSelected.key === vehicle.key

                    return (
                      <Col md={3} xs={6} key={vehicle.key}>
                        <div className={`border rounded-3 m-2 cursor-pointer py-3 ${selected ? 'border-select' : ''}`} onClick={() => onClickVehicle(vehicle)}>
                          <div style={{height: '125px'}} className="d-flex justify-content-center">
                            <img src={img} className="h-100"/>
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <span>{title}</span>
                            <span>{vehicle.dimensions.length.value + ' x ' + vehicle.dimensions.width.value + ' x ' + vehicle.dimensions.height.value + ' meter'}</span>
                            <span>{vehicle.load.value} {vehicle.load.unit}</span>
                          </div>
                        </div>
                      </Col>
                    )
                  })
                }
                </Row>
                { vehicleSelected && vehicleSelected.specialRequests &&
                <>
                  <h5 style={{fontSize: '1.15rem', margin: '0'}}>Additional Services (Optional)</h5>
                  { _.map(_.toPairs(_.groupBy(_.filter(vehicleSelected.specialRequests, request => request.name.includes('DOOR_TO_DOOR') || request.name.includes('ROUND_TRIP') || request.name.includes('THERMAL_BAG') || request.name.includes('TAILBOARD') || request.name.includes('FULL_MOVING_SERVICE')), item => item.name.replace(/_\d$/, ''))), (entry, i) => {
                    return (
                      <React.Fragment key={i}>
                        { entry[1] && entry[1].length > 1 &&
                        <>
                          { entry[0] === 'DOOR_TO_DOOR' &&
                          <label>Door-to-Door Service (Select 1)</label>
                          }
                          { entry[0] === 'THERMAL_BAG' &&
                            <label>Cold Truck (Select 1)</label>
                          }
                        </>
                        }
                        { entry[1].map(item => {
                          return (
                            <div key={item.name} className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                              <label className='custom-checkbox'>
                                <input type="checkbox" onChange={(e) => onCheckSpecialRequest(e, entry[0], item)} checked={_.find(specialRequests, request => request.name === item.name)}/>
                                <span className="checkmark checkmark-smaller"></span>
                              </label>
                              <label style={{fontWeight: '200'}}>{item.description.replace('   ', ' + ')}</label>
                            </div>
                          )
                        })}
                      </React.Fragment>
                    )
                  })
                  }
                </>
                }
              </Card.Body>
            </Card>
          }
          { service === 'lalamove' &&
            <Card className="tealCard">
              <Card.Header className="tealCardHeader">Schedule At (Optional)</Card.Header>
              <Card.Body className="d-grid gap-2 label-strong grey-label">
                <Row>
                  <Col className="shipping-schedule-at d-flex align-items-center">
                    <SingleDatePicker
                      numberOfMonths={1}
                      isOutsideRange={scheduleIsOutsideRange}
                      displayFormat="DD/MM/YYYY"
                      date={scheduleAt} // momentPropTypes.momentObj or null
                      onDateChange={(date) => handleSelectScheduleAt(date, "date")} // PropTypes.func.isRequired
                      focused={focused} // PropTypes.bool
                      onFocusChange={({ focused }) => setFocused( focused )} // PropTypes.func.isRequired
                      openDirection="up"
                    />
                    <TimePicker
                      placement="topLeft"
                      placeholder="Time"
                      allowEmpty={false}
                      showSecond={false}
                      hideDisabledOptions
                      minuteStep={5}
                      use12Hours
                      value={scheduleAt}
                      onChange={handleSelectScheduleAt}
                    />
                    { scheduleAt &&
                    <Button className="mx-2" size="sm" variant={"secondary"} onClick={handleClearScheduleAt}>
                      Clear (Now)
                    </Button>
                    }
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          }
          { service === 'lalamove' && vehicleSelected && quotation &&
            <Card className="tealCard">
              <Card.Body className="d-flex flex-row justify-content-between label-strong grey-label">
                <div>
                  <h5 className="mt-2" data-html="true" data-tip={`
                  <p class="m-0">Base Fare: ${currency}${quotation.priceBreakdown.base}</p>
                  ${quotation.priceBreakdown.extraMileage ? `<p class="m-0">Additional Distance Fee: ${currency}${quotation.priceBreakdown.extraMileage}</p>` : ''}
                  ${quotation.priceBreakdown.surcharge ? `<p class="m-0">Surcharge: ${currency}${quotation.priceBreakdown.surcharge}</p>` : ''}
                  ${quotation.priceBreakdown.specialRequests ? `<p class="m-0">Additional Services: ${currency}${quotation.priceBreakdown.specialRequests}</p>` : ''}
                  `}>
                    Total: {currency}{quotation.priceBreakdown.total}
                    <i className="ms-1 fa fa-info-circle"></i>
                  </h5>
                  <ReactTooltip/>
                  <div className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                    <label className='custom-checkbox'>
                      <input type="checkbox" name="lalamove-agree" onChange={e => setLalamoveAgree(e.target.checked)} checked={lalamoveAgree} />
                      <span className="checkmark checkmark-smaller"></span>
                    </label>
                    <label style={{fontWeight: '200'}}>*I agree to deduct this amount from the Total Payout Balance in monthly billing.</label>
                  </div>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  { lalamoveShipmentCreated ?
                    <Button variant={"danger-secondary"} onClick={handleOnClose}>
                      Done
                    </Button>
                    :
                    <>
                      <Button variant={"danger-secondary"} onClick={handleOnClose} disabled={loading}>
                        Cancel
                      </Button>
                      <Button onClick={placeLalamoveOrder} disabled={loading || !lalamoveAgree || !confirmedParcelShippingAddress || !confirmedSenderAddress}>
                        Place Order
                      </Button>
                    </>
                  }
                </div>
              </Card.Body>
            </Card>
          }
          { service === 'dhl' && dhlShipmentCreated &&
            <Card className="tealCard">
              <Card.Header className="tealCardHeader">DHL Consignment Note</Card.Header>
              <Card.Body className="d-grid gap-2 label-strong grey-label">
                {
                  shipping.shipments.map((item, i) => {
                    return (
                      <Card key={i}>
                        <Card.Body className="d-grid gap-1">
                          <div className="d-grid gap-1">
                            <label>Shipment ID/AWB</label>
                            <span>{item.dhlShipmentId}</span>
                          </div>
                        </Card.Body>
                      </Card>
                    )
                  })
                }
                {
                  shipping.shipments.length > 0 &&
                    <div>
                      <Button className='me-1' onClick={() => printDHLCN(false)}>Print as Label</Button>
                      <Button className='me-1' onClick={() => printDHLCN(true)}>Print as Document</Button>
                    </div>
                }
              </Card.Body>
            </Card>
          }
          {/* { */}
          {/*   service && */}
          {/*     <Card className="tealCard shadow-sm"> */}
          {/*       <Card.Header className="tealCardHeader">Pick-up</Card.Header> */}
          {/*       <Card.Body className="d-grid gap-2 label-strong grey-label"> */}
          {/*         { */}
          {/*           service === 'dhl' && */}
          {/*             <> */}
          {/*               <Form.Select className="form-control mb-2" value={pickup ? "now" : "later"} onChange={(e) => { */}
          {/*                 setPickup(e.target.value === "now"); */}
          {/*               }}> */}
          {/*                 <option value="now">Schedule Pick-up Now</option> */}
          {/*                 <option value="later">Schedule Pick-up Later</option> */}
          {/*               </Form.Select> */}
          {/*             </> */}
          {/*         } */}
          {/*         { */}
          {/*           pickup && */}
          {/*             <> */}
          {/*               { */}
          {/*                 !(service === "easyparcel" && shipping.rates.length === 0) && shipping.shipments.length === 0 && holidays.length > 0 && */}
          {/*                   <> */}
          {/*                     <label>Select Pickup Date: </label> */}
          {/*                     <SingleDatePicker */}
          {/*                       numberOfMonths={1} */}
          {/*                       isOutsideRange={isOutsideRange} */}
          {/*                       displayFormat="D/M/YY" */}
          {/*                       date={pickupDate} // momentPropTypes.momentObj or null */}
          {/*                       onDateChange={handleSelectPickupDate} // PropTypes.func.isRequired */}
          {/*                       focused={focused} // PropTypes.bool */}
          {/*                       onFocusChange={({ focused }) => setFocused( focused )} // PropTypes.func.isRequired */}
          {/*                     /> */}
          {/*                   </> */}
          {/*               } */}
          {/*               { */}
          {/*                 shipping.shipments.length === 0 && !shipping.payment && shipping.rates.length > 0 && !shipping.loading && */}
          {/*                   <> */}
          {/*                     <label>Select Pickup Service: </label> */}
          {/*                     <BootstrapTable */}
          {/*                       keyField='service_id' */}
          {/*                       data={shipping.rates} */}
          {/*                       columns={ordersCol} */}
          {/*                       bordered={ false } */}
          {/*                       selectRow={selectRowProp} */}
          {/*                       hover */}
          {/*                       defaultSorted={[{ */}
          {/*                         dataField: 'created_at', */}
          {/*                           order: 'desc', */}
          {/*                       }]} */}
          {/*                       noDataIndication={() => 'There is no data to display'} */}
          {/*                     /> */}
          {/*                   </> */}
          {/*               } */}
          {/*               { */}
          {/*                 service === 'easyparcel' && shipping.loading && */}
          {/*                   <Loader /> */}
          {/*               } */}
          {/*               { */}
          {/*                 service === 'easyparcel' && shipping.rates.length == 0 && !shipping.loading && shipping.loaded && shipping.shipments.length === 0 && */}
          {/*                   <div className="text-center my-3"> */}
          {/*                     <h5 className="mb-0" style={{color: '#d15d5d'}}><FontAwesomeIcon icon={solid('warning')}/> No pickup service available at this time</h5> */}
          {/*                   </div> */}
          {/*               } */}
          {/*               { */}
          {/*                 shipping.shipments.length === 0 && */}
          {/*                   <div> */}
          {/*                     Same Day Pick Up Service is only available for booking before 12 p.m.<br/> */}
          {/*                     All pick-up is available on working days only (exclude Sat, Sun and PH).<br/> */}
          {/*                     Pick-up date subject to delivery partner's availability.<br/> */}
          {/*                   </div> */}
          {/*               } */}
          {/*               { */}
          {/*                 shipping.shipments.length > 0 && pickupDate && */}
          {/*                   <div className="d-grid gap-1"> */}
          {/*                     <label>Pick-up date</label> */}
          {/*                     <span>{pickupDate.format('D-M-YYYY')}</span> */}
          {/*                   </div> */}
          {/*               } */}
          {/*               { */}
          {/*                 shipping.shipments.length > 0 && selectedRow && */}
          {/*                   <> */}
          {/*                     <div className="d-grid gap-1"> */}
          {/*                       <label>Courier name</label> */}
          {/*                       <span>{selectedRow.courier_name}</span> */}
          {/*                     </div> */}
          {/*                     { */}
          {/*                       isAdmin && */}
          {/*                         <div className="d-grid gap-1"> */}
          {/*                           <label>Shipping fee</label> */}
          {/*                           <span>{currency} {selectedRow.price}</span> */}
          {/*                         </div> */}
          {/*                     } */}
          {/*                   </> */}
          {/*               } */}
          {/*               <hr/> */}
          {/*               <Row className="align-items-center justify-content-between"> */}
          {/*                 <Col xs="auto"><label>Pickup Address</label></Col> */}
          {/*                 <Col xs="auto"> */}
          {/*                   { */}
          {/*                     hasPickupAddress && address.name && shipping.shipments.length === 0 && !shipping.loading && */}
          {/*                       <Button variant="secondary" className="btn-sm" onClick={handleEditPickupAddress}> */}
          {/*                         Edit */}
          {/*                       </Button> */}
          {/*                   } */}
          {/*                 </Col> */}
          {/*               </Row> */}
          {/*               { */}
          {/*                 !hasPickupAddress && */}
          {/*                   <Card className="redCard"> */}
          {/*                     <Card.Header className="redCardHeader">Pickup address not found</Card.Header> */}
          {/*                     <Card.Body> */}
          {/*                       Please enter your pickup address <a onClick={handleEditPickupAddress} href="">here</a>. */}
          {/*                     </Card.Body> */}
          {/*                   </Card> */}
          {/*               } */}
          {/*               { */}
          {/*                 hasPickupAddress && address.name && */}
          {/*                   <Card> */}
          {/*                     <Card.Body className="d-grid gap-1"> */}
          {/*                       <label>{address.name}</label> */}
          {/*                       { */}
          {/*                         address.company && */}
          {/*                           <>{address.company}<br/></> */}
          {/*                       } */}
          {/*                       {address.address1},<br/> */}
          {/*                       { */}
          {/*                         address.address2 && */}
          {/*                           <>{address.address2},<br/></> */}
          {/*                       } */}
          {/*                       {address.postcode}, {address.city},<br/> */}
          {/*                       {address.state}, {address.country}<br/> */}
          {/*                       {address.contact} */}
          {/*                     </Card.Body> */}
          {/*                   </Card> */}
          {/*               } */}
          {/*             </> */}
          {/*         } */}
          {/*       </Card.Body> */}
          {/*     </Card> */}
          {/* } */}
          {/* { // easyparcel */}
          {/*   shipping.payment && */}
          {/*     <Card className="tealCard"> */}
          {/*       <Card.Header className="tealCardHeader">Status</Card.Header> */}
          {/*       <Card.Body className="d-grid gap-2 label-strong grey-label"> */}
          {/*         { */}
          {/*           shipping.payment.map((item, i) => { */}
          {/*             return ( */}
          {/*               <Card key={i}> */}
          {/*                 <Card.Body className="d-grid gap-1"> */}
          {/*                   <div className="d-grid gap-1"> */}
          {/*                     <label>Order No.</label> */}
          {/*                     <span className="delivery-info-value">{item.orderno}</span> */}
          {/*                   </div> */}
          {/*                   <div className="d-grid gap-1"> */}
          {/*                     <label>Status</label> */}
          {/*                     <span className="delivery-info-value">{item.messagenow}</span> */}
          {/*                   </div> */}
          {/*                   <div className="d-grid gap-1"> */}
          {/*                     <label>AWB No.</label> */}
          {/*                     <dd className="delivery-info-value">{item.parcel[0].awb}</dd> */}
          {/*                   </div> */}
          {/*                   <div className="d-grid gap-1"> */}
          {/*                     <label>AWB Link</label> */}
          {/*                     <a className="delivery-info-value" href={item.parcel[0].awb_id_link} target="_blank">Download</a> */}
          {/*                   </div> */}
          {/*                 </Card.Body> */}
          {/*               </Card> */}
          {/*             ) */}
          {/*           }) */}
          {/*         } */}
          {/*       </Card.Body> */}
          {/*     </Card> */}
          {/* } */}
          {/* { */}
          {/*   service === 'easyparcel' && shipping.loading && */}
          {/*     <Loader /> */}
          {/* } */}
          { error &&
            <GTRAlert variant="danger" alert={error}/>
          }
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          {/* { */}
          {/*   service === 'easyparcel' && */}
          {/*     <> */}
          {/*       { */}
          {/*         !shipping.payment && shipping.shipments.length === 0 && */}
          {/*           <Button variant="danger-secondary" onClick={handleOnClose} disabled={shipping.loading}> */}
          {/*             {!shipping.payment ? "Cancel" : "Done"} */}
          {/*           </Button> */}
          {/*       } */}
          {/*       { */}
          {/*         shipping.payment && */}
          {/*           <Button variant="danger-secondary" onClick={handleOnClose} disabled={shipping.loading}> */}
          {/*             {!shipping.payment ? "Cancel" : "Done"} */}
          {/*           </Button> */}
          {/*       } */}
          {/*       { */}
          {/*         shipping.shipments.length === 0 && !shipping.payment && */}
          {/*           <Button variant="primary" onClick={handleOnClickNext} disabled={!pickupDate || shipping.loading || !selectedRow}> */}
          {/*             Next */}
          {/*           </Button> */}
          {/*       } */}
          {/*       { */}
          {/*         shipping.shipments.length > 0 && !shipping.payment && */}
          {/*           <> */}
          {/*             <Button variant="danger-secondary" onClick={handleOnClickBack} disabled={shipping.loading}> */}
          {/*               Back */}
          {/*             </Button> */}
          {/*             <Button variant="primary" onClick={handleOnClickConfirm} disabled={shipping.loading}> */}
          {/*               Confirm */}
          {/*             </Button> */}
          {/*           </> */}
          {/*       } */}
          {/*     </> */}
          {/* } */}
          {
            service === 'dhl' &&
              <>
                {
                  !dhlShipmentCreated &&
                    <>
                      <Button variant={dhlShipmentCreated ? "primary" : "danger-secondary"} onClick={handleOnClose} disabled={shipping.loading}>
                        Cancel
                      </Button>
                      <Button className="btn-br-6" onClick={confirmDHLShipment} disabled={shipping.loading || editingDeliveryAddress || !hasSenderAddress}>Generate Consignment Note</Button>
                    </>
                }
                {
                  dhlShipmentCreated &&
                    <Button variant={"secondary"} onClick={handleOnClose}>
                      Done
                    </Button>
                }
              </>
          }
        </Modal.Footer>
      </Modal>
      {
        showPickupAddressModal &&
          <Modal show={true} onHide={handleBackToShipment} size="lg" dialogClassName="modal-shadow">
            <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
              <h4 className="mb-0">Edit Pickup Address</h4>
            </Modal.Header>
            <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{ borderTop: '1px solid #dee2e6' }}>
              <Profile showHeader={false} showPassword={false} match={match} prevModal="Arrange Shipment" backToPrevModal={handleBackToShipment}/>
            </Modal.Body>
          </Modal>
      }
      {
        <div className='position-relative' ref={cnMultiRef}>
          {
            _.map(dhlLabels, (label, i) => {
              return (
                <div key={i} className="cn-container text-center position-absolute" style={{left: '-9999px', top: '-9999px'}}>
                  <img src={`data:image/png;base64, ${label}`}/>
                </div>
              )
            })
          }
        </div>
      }
      { showConfirmAddressModal &&
        <ConfirmAddressModal onHide={() => setShowConfirmAddressModal(false)} backdropClassName="nested-modal-backdrop" defaultAddress={defaultAddress} confirmAddress={handleConfirmAddress}/>
      }
    </>
  );
}

export default connect((state, props) => {
  return {
    error: state.error,
  }
}, {
  ...shippingActionCreators,
  ...errorActionCreators,
})(ShippingModal);
