import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as taggingsActionCreators from '../redux/actions/taggings';
import { Button, Card, Row, Col } from 'react-bootstrap';
import api from '../helpers/apiClient';
import _ from 'lodash';
import Loader from '../components/Loader';
import TaggingGroup from '../components/TaggingGroup';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

class Taggings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newTagPanel: false,
      removeMode: false,
      tagsToRemove: [],
      smart_collections: []
    };
  }

  componentDidMount() {
    const { fetchTaggings } = this.props;

    fetchTaggings();
  }

  submitTagging = async (tag, desc, group) => {
    return api(`/product/taggings`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tag,
        desc,
        group
      })
    }).then(result => {
      if(result.success){
        const { fetchTaggings } = this.props;

        fetchTaggings();

        this.setState({ newTagPanel: false });
      }
    })
  }

  submitNewTagGroup = async e => {
    e.preventDefault();

    const inputs = e.target.getElementsByTagName('input')

    const group = inputs[0].value
    const tag = inputs[1].value
    const desc = inputs[2].value

    this.submitTagging(tag, desc, group);
  }

  render() {
    const { taggings: { items }, loading } = this.props;

    return (
      <div>
        <div className="row mt-3 mb-4">
          <div className="col-xs-12 col-md-9">
            <h2 className="sub-header">Product Taggings</h2>
          </div>
        </div>
          {
            loading &&
              <Loader />
          }
          <Row className="justify-content-center my-4">
          <Col xs={8}>
            <Card className="shadow-sm" style={{border: 'none', borderRadius:'10px'}}>
              <Card.Header className="my-2 mx-4 px-0" style={{background: '#fff',}}><h5 className="mb-0">New Tag Group</h5></Card.Header>
              <Card.Body className="grey-label label-strong mx-3">
                <form onSubmit={this.submitNewTagGroup} className="d-grid gap-2">
                  <div className="d-grid gap-1">
                    <label htmlFor="new_tag_group">New Tag Group</label>
                    <input className="form-control" type="text" name="new_tag_group" required/>
                  </div>
                  <div className="d-grid gap-1">
                    <label htmlFor="initial_tag">First Tag</label>
                    <input className="form-control" type="text" name="initial_tag" required/>
                  </div>
                  <div className="d-grid gap-1">
                    <label htmlFor="initial_tag_label">Label</label>
                    <input className="form-control" type="text" name="initial_tag_label" required/>
                  </div>
                  <Button className="btn-br-6 mt-3 mx-auto w-100" type="submit">Create</Button>
                </form>
              </Card.Body>
            </Card>
          </Col>
          </Row>
          <Row>
            <DndProvider backend={HTML5Backend}>
              {
                _.map(items, (tags, key) => {
                  return (
                    <TaggingGroup key={key} title={key} defaultTags={tags} fetchTaggings={this.props.fetchTaggings}/>
                  )
                })
              }
            </DndProvider>
          </Row>
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    user: state.auth.user,
    taggings: state.taggings
  }
}, {...taggingsActionCreators})(Taggings);

