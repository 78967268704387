import { useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import states from '../data/states.json'
import api from '../helpers/apiClient';

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

const EditDeliveryInfoModal = ({ orderType = 'shopify', ...restProps }) => {
  return ( orderType === 'manual' ?
    <ManualOrder {...restProps} />
    :
    <ShopifyOrder {...restProps} />
  )
}

const ShopifyOrder = ({ onClose, shippingAddress, orderId, orderName, onSaved }) => {
  const {
    first_name,
    last_name,
    company,
    address1,
    address2,
    zip,
    city,
    province,
    province_code,
    phone,
  } = shippingAddress;

  const [isLoading, setIsLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let form = new FormData(e.target);
    let shipping_address = Object.fromEntries(form);

    if (e.target.province && e.target.province.selectedOptions.length == 1) {
      let province = e.target.province.selectedOptions[0].dataset.merge
      let province_split = province.split('_')

      shipping_address.province_code = province_split[0]
      shipping_address.province = province_split[1]
    }

    await api(`/orders/edit/delivery/${orderId}`, {
      method: 'POST',
      body: JSON.stringify({ shipping_address, order_name: orderName }),
      headers: {'Content-Type': 'application/json'}
    })

    onSaved();
  }

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4 className="mb-0">Edit Delivery Info</h4>
      </Modal.Header>
      <form onSubmit={submit}>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
          <Row>
            <Col md={6}>
              <label>First Name</label>
              <input className="form-control" name="first_name" type="text" defaultValue={first_name} />
            </Col>
            <Col md={6}>
              <label>Last Name</label>
              <input className="form-control" name="last_name" type="text" defaultValue={last_name} />
            </Col>
          </Row>
          <div>
            <label>Company</label>
            <input className="form-control" name="company" type="text" defaultValue={company} />
          </div>
          <div>
            <label>Address</label>
            <input className="form-control" name="address1" type="text" defaultValue={address1} />
          </div>
          <div>
            <label>Apartment, suite, etc.</label>
            <input className="form-control" name="address2" type="text" defaultValue={address2} />
          </div>
          <Row>
            <Col md={4}>
              <label>Postcode</label>
              <input className="form-control" name="zip" type="text" defaultValue={zip} />
            </Col>
            { country === 'MY' &&
            <>
              <Col md={4}>
                <label>City</label>
                <input className="form-control" name="city" type="text" defaultValue={city} />
              </Col>
              <Col md={4}>
                <label>State/territory</label>
                <Form.Select name="province" defaultValue={province_code}>
                  { states.MY.map(option => (
                    <option key={option.key} value={option.value} data-merge={option.value + '_' + option.name}>{option.name}</option>
                  ))}
                </Form.Select>
              </Col>
            </>
            }
          </Row>
          <div>
            <label>Phone</label>
            <input className="form-control" name="phone" type="text" defaultValue={phone} />
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={onClose} disabled={isLoading}>Cancel</Button>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const ManualOrder = ({ onClose, receiver, deliveryAddress, contact, orderId, onSaved }) => {
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let form = new FormData(e.target);
    let delivery_info = Object.fromEntries(form);

    let order = await api(`/manual_orders/${orderId}/update`, {
      method: 'POST',
      body: JSON.stringify(delivery_info),
      headers: {'Content-Type': 'application/json'}
    })

    onSaved(order);
  }

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4 className="mb-0">Edit Delivery Info</h4>
      </Modal.Header>
      <form onSubmit={submit}>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
          <div className=' d-grid gap-1'>
            <label htmlFor='receiver'>Receiver</label>
            <input className="form-control" name="receiver" type="text" placeholder="Receiver" defaultValue={receiver} />
          </div>
          <div className=' d-grid gap-1'>
            <label htmlFor='delivery_address'>Delivery Address</label>
            <textarea className="form-control" name="delivery_address" placeholder="Delivery Address" defaultValue={deliveryAddress} style={{height: '150px'}} />
          </div>
          <div className=' d-grid gap-1'>
            <label htmlFor='contact'>Contact</label>
            <input className="form-control" name="contact" type="text" placeholder="Contact" defaultValue={contact} />
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={onClose} disabled={isLoading}>Cancel</Button>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default EditDeliveryInfoModal;
