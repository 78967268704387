import { useState, useRef } from 'react';
import { Modal, Row, Col, Button, Form, Card } from 'react-bootstrap';
import toast from 'react-hot-toast';
import messageTemplateOptions from '../data/email_sender_template.json';
import api from '../helpers/apiClient';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

const EmailSenderModal = ({ onClose, to, from, orderName, orderId }) => {
  const [fromEmail, setFromEmail] = useState(from);
  const [cc, setCc] = useState(false);
  const [subject, setSubject] = useState(`More info required for your order ${orderName} from Giftr`);
  const [message, setMessage] = useState('');
  const [review, setReview] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [previewAttachments, setPreviewAttachments] = useState([]);
  const [fileError, setFileError] = useState('');

  const fileInput = useRef(null);

  const handleOnChangeTemplate = (e) => {
    if (e.target.value) {
      let message = e.target.value
      message = message.replace(/#GTRXXXXX/, orderName);
      setMessage(message);
    }

    let subject = e.target.selectedOptions[0].dataset.subject;
    if (/#GTRXXXXX/ig.test(subject)) {
      subject = subject.replace(/#GTRXXXXX/, orderName);
      setSubject(subject);
    } else {
      setSubject(`More info required for your order ${orderName} from Giftr`);
    }
  }

  const handleSubmit = async (e) => {
    setSending(true);
    e.preventDefault();
    let form = new FormData(e.target)

    form.set('to', to)
    form.set('order_name', orderName)
    form.set('order_id', orderId)

    return api(`/contact/email_sender`, {
      method: 'POST',
      body: form
    }, {}).then(() => {
      setSent(true);
      toast.success('Successfully sent', { position: 'top-center' });
    }).catch(err => {
      console.log(err);
      toast.error('Something went wrong', { position: 'top-center' });
    })
  }

  const handleClearAttachments = () => {
    fileInput.current.fileInputEl.value = ''
    setPreviewAttachments([]);
  }

  const handleOnDropAttachments = (files) => {
    setFileError('');

    let totalSize = files.reduce((sum, file) => {
      return sum + file.size
    }, 0)

    if (totalSize > 10000000 || files.length > 3) {
      setFileError('Maximum 3 files or total size of 10MB');
      fileInput.current.fileInputEl.value = ''
      setPreviewAttachments([]);
      return
    }
    setPreviewAttachments(files);
  }

  return (
    <Modal style={{ border: 'none' }} show={true} onHide={onClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
        <h4>Email Sender</h4>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{ borderTop: '1px solid #dee2e6' }}>
          <div className={`d-grid gap-4 ${review ? 'd-none' : ''}`}>
            <Row>
              <Col sm={6}>
                <label>To</label>
                <input disabled readOnly className="form-control" name="to" type="text" defaultValue={to} />
              </Col>
              <Col sm={6}>
                <label>From</label>
                <input className="form-control mb-1" name="from" type="text" required value={fromEmail} onChange={(e) => setFromEmail(e.target.value)}/>
                <div className="d-grid gap-2" style={{ gridTemplateColumns: '20px 1fr' }}>
                  <label className='custom-checkbox'>
                    <input type="checkbox" name="cc" onChange={() => setCc(!cc)} checked={cc} />
                    <span className="checkmark checkmark-smaller"></span>
                  </label>
                  <label>Send a copy to my email/CC</label>
                </div>
              </Col>
            </Row>
            <div className="d-grid gap-1">
              <label>Custom Message</label>
              <label className="small">Template</label>
              <Form.Select className="form-control d-inline" onChange={handleOnChangeTemplate}>
                <option value="">Select a template</option>
                { messageTemplateOptions.map(option => (
                  <option key={option.key} value={option.value} data-subject={option.name}>{option.name}</option>
                ))}
              </Form.Select>
              <label className="small">Subject</label>
              <input className="form-control" name="subject" type="text" required value={subject} onChange={(e) => setSubject(e.target.value)}/>
              <label className="small">Message</label>
              <textarea name="message" maxLength="800" className="form-control" style={{ height: '300px', resize: 'vertical' }} value={message} onChange={(e) => setMessage(e.target.value)} />
              <label className="small">800 Characters Max</label>
            </div>
            <div className="d-grid gap-1">
              <label>Attachment (Up to 3 Files or max. 10MB)</label>
              <div className="d-flex gap-2">
                <Dropzone
                  style={{cursor: 'pointer', width: '150px'}}
                  multiple={true}
                  onDrop={handleOnDropAttachments}
                  name="attachment"
                  ref={fileInput}
                  >
                  <Button variant="secondary" className="small">
                    <FontAwesomeIcon icon={solid('folder-open')} className="me-2"/>Choose Files
                  </Button>
                </Dropzone>
                <Button variant="link" size="sm" className="p-0" onClick={handleClearAttachments}>Clear</Button>
              </div>
              { fileError &&
                <span className="small text-danger">{fileError}</span>
              }
              <div className="d-flex flex-wrap">
              { previewAttachments.map((file, i) => {
                if (file.type.includes('image')) {
                  return <img key={file.preview} src={file.preview} height="100px" title={file.name}/>
                }
                return (
                  <div key={i} className="d-flex justify-content-center align-items-center" style={{height: '100px', width: '100px', backgroundColor: '#e9ecef'}} title={file.name}>
                    <FontAwesomeIcon icon={solid('folder-open')} style={{fontSize: '2rem', color: '#5a5a5a'}}/>
                  </div>
                )
              })}
              </div>
            </div>
            <div>
              <span className="white-space-pre-line" dangerouslySetInnerHTML={{__html: 'Reminder:\n1) For collection of additional payment or refund, please contact your Partner Manager and Giftr Operations Team to liaise with the customer to ensure a seamless and secured gifting experience.\n2) Only use this contact channel to enquire for more info related to order purchased. Strictly no marketing or irrelevant content.'}}></span>
            </div>
          </div>

          { review &&
            <div>
              <Row>
                <Col xs={3} className="fw-bold">
                  From:
                </Col>
                <Col>
                  {fromEmail}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={3} className="fw-bold">
                  CC:
                </Col>
                <Col>
                  {cc ? 'Yes' : 'No'}
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col xs={3} className="fw-bold">
                  To:
                </Col>
                <Col>
                  {to}
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col xs={3} className="fw-bold">
                  Subject:
                </Col>
                <Col>
                  {subject}
                </Col>
              </Row>
              <Card className="tealCard mt-4 shadow-sm">
                <Card.Body className="grey-label label-strong">

                  <table className="body" style={{height: '100% !important', width: '100% !important', borderSpacing: 0, borderCollapse: 'collapse'}}>
                    <tbody><tr>
                      <td style={{fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'}}>
                        <table style={{width: '100%', borderSpacing: 0, borderCollapse: 'collapse', margin: '40px 0 20px'}}>
                          <tbody><tr>
                            <td className="header__cell" style={{fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'}}>
                              <center>
                                <table className="container" style={{maxWidth: '560px', textAlign: 'left', borderSpacing: 0, borderCollapse: 'collapse', margin: '0 auto'}}>
                                  <tbody><tr>
                                    <td style={{fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'}}>
                                      <table className="row" style={{width: '100%', borderSpacing: 0, borderCollapse: 'collapse'}}>
                                        <tbody><tr>
                                          <td className="shop-name__cell" style={{fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif', width: '100%'}}>
                                            <img src="https://cdn.shopify.com/s/files/1/1428/2106/email_settings/Giftr_Favicon_Colour_2.png?70601" alt={`Giftr - ${country === 'MY' ? 'Malaysia' : 'Singapore'}'s Leading Online Gift Shop`} width={70} />
                                          </td>
                                          <td className="order-number__cell" style={{fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif', textTransform: 'uppercase', fontSize: '14px', color: '#999'}} align="right">
                                            <span className="order-number__text" style={{fontSize: '16px'}}>
                                              Order {orderName}
                                            </span>
                                          </td>
                                        </tr>
                                        </tbody></table>
                                    </td>
                                  </tr>
                                  </tbody></table>
                              </center>
                            </td>
                          </tr>
                          </tbody></table>
                        <table className="content" style={{width: '100%', borderSpacing: 0, borderCollapse: 'collapse'}}>
                          <tbody><tr>
                            <td className="content__cell" style={{fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif', paddingBottom: '40px', border: 0}}>
                              <center>
                                <table className="container" style={{maxWidth: '560px', textAlign: 'left', borderSpacing: 0, borderCollapse: 'collapse', margin: '0 auto'}}>
                                  <tbody><tr>
                                    <td style={{fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'}}>
                                      <span style={{color: '#777', lineHeight: '150%', fontSize: '16px', margin: 0, whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: message}}></span>
                                    </td>
                                  </tr>
                                  </tbody></table>
                              </center>
                            </td>
                          </tr>
                          </tbody></table>
                        <table className="footer" style={{width: '100%', borderSpacing: 0, borderCollapse: 'collapse', borderTopWidth: '1px', borderTopColor: '#e5e5e5', borderTopStyle: 'solid'}}>
                          <tbody><tr>
                            <td className="footer__cell" style={{fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif', padding: '35px 0'}}>
                              <center>
                                <table className="container" style={{maxWidth: '560px', textAlign: 'left', borderSpacing: 0, borderCollapse: 'collapse', margin: '0 auto'}}>
                                  <tbody><tr>
                                    <td style={{fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'}}>
                                      <p className="disclaimer__subtext" style={{color: '#999', lineHeight: '150%', fontSize: '14px', margin: 0}}>If you have any questions, reply to this email</p>
                                    </td>
                                  </tr>
                                  </tbody></table>
                              </center>
                            </td>
                          </tr>
                          </tbody></table>
                        { previewAttachments.length > 0 &&
                        <table className="footer" style={{width: '100%', borderSpacing: 0, borderCollapse: 'collapse'}}>
                          <tbody><tr>
                            <td className="footer__cell" style={{fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'}}>
                              <center>
                                <table className="container" style={{maxWidth: '560px', textAlign: 'left', borderSpacing: 0, borderCollapse: 'collapse', margin: '0 auto'}}>
                                  <tbody><tr>
                                    <td style={{fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'}}>
                                      <p className="disclaimer__subtext" style={{color: '#999', fontStyle: 'italic', lineHeight: '150%', fontSize: '14px', margin: 0}}>{previewAttachments.length} Attachment(s)</p>
                                      <div className="d-flex flex-wrap">
                                        { previewAttachments.map((file, i) => {
                                          if (file.type.includes('image')) {
                                            return <img key={file.preview} src={file.preview} height="100px" title={file.name}/>
                                          }
                                          return (
                                            <div key={i} className="d-flex justify-content-center align-items-center" style={{height: '100px', width: '100px', backgroundColor: '#e9ecef'}} title={file.name}>
                                              <FontAwesomeIcon icon={solid('folder-open')} style={{fontSize: '2rem', color: '#5a5a5a'}}/>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </td>
                                  </tr>
                                  </tbody></table>
                              </center>
                            </td>
                          </tr>
                          </tbody></table>
                        }
                      </td>
                    </tr>
                    </tbody></table>
                </Card.Body>
              </Card>
            </div>
          }
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          { !review &&
          <Button variant="primary" onClick={() => setReview(true)} disabled={message === ''}>
            Review email
          </Button>
          }
          { review && !sent &&
            <>
              <Button variant="secondary" onClick={() => setReview(false)}>
                Back
              </Button>
              <Button variant="primary" type="submit" disabled={sent || sending}>
                Send email
              </Button>
            </>
          }
          { sent &&
            <Button variant="primary" onClick={onClose}>
              Done
            </Button>
          }
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default EmailSenderModal;
