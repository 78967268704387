import { EditorState } from 'draft-js'
import { useState } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { removeInlineStyles, removeEntities, removeBlockTypes } from '../helpers/draftjsClearFormat';
import _ from 'lodash'
import { stateToHTML } from 'draft-js-export-html';
import api from '../helpers/apiClient';

const ClearFormatButton = ({clearFormat}) => {
  return (
    <Button variant="secondary" size="sm" className="mb-2" onClick={clearFormat}>Clear Format</Button>
  )
}

const CreateDeliveryTemplateModal = ({ onClose, vendor, onSaved }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [name, setName] = useState('')
    const [saving, setSaving] = useState(false)

    const editorClearFormat = () => {
        const stateWithoutFormat = _.flow([
            removeInlineStyles,
            removeEntities,
            removeBlockTypes,
        ])(editorState)

        setEditorState(stateWithoutFormat)
    }

    const deliveryFinePrintsEditorChange = editorState => {

        setEditorState(editorState)
        setSaving(false)
    }

    const handleAddNewDeliveryFinePrints = () => {
        setSaving(true)

        let html = stateToHTML(editorState.getCurrentContent())

        //check if html contains "Delivery Fine Prints:"
        if (html.length > 0 && html.indexOf('Delivery Fine Prints:') === -1) {
            //bold the first line
            html = `<p><strong>Delivery Fine Prints:</strong></p>${html}`
        }

        api(`/merchants/store/${vendor}/delivery_fine_prints`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                content: html,
                name: name
            })
        }).then(result => {
            onSaved(result)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <Modal show={true} onHide={onClose} size="lg">
            <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
                <h4 className="mb-0">Create Delivery Template</h4>
            </Modal.Header>
            <Modal.Body>
                <p>Add delivery fine print</p>
                <input
                    type="text"
                    style={{ backgroundColor: 'white' }}
                    className="form-control"
                    placeholder='Delivery Fine Print Name'
                    onChange={(e) => setName(e.target.value)}
                    value={name} 
                    />
                <div style={{ border: '1px solid #ced4da', borderRadius: '4px', marginBottom: '1rem', marginTop: '1rem', backgroundColor: 'white' }}>
                    <Editor
                        toolbarCustomButtons={[<ClearFormatButton clearFormat={editorClearFormat} />]}
                        onEditorStateChange={deliveryFinePrintsEditorChange}
                        editorState={editorState}
                        editorStyle={{ resize: 'vertical', height: '150px', padding: '0 1rem' }} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger-secondary" onClick={onClose}>Cancel</Button>
                <Button
                    variant="primary"
                    type="button"
                    disabled={saving || name == '' || editorState.getCurrentContent().getPlainText() === ''}
                    // disabled={savingChanges || deliveryFinePrintsName === '' || deliveryFinePrintsEditorState.getCurrentContent().getPlainText() === '' || (item.delivery_fine_prints_template && item.delivery_fine_prints_template.length >= 5)}
                    onClick={handleAddNewDeliveryFinePrints}
                    className="btn-br-6"
                >
                    Create & Apply Template
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateDeliveryTemplateModal