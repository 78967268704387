import qs from "qs";
import { addError } from './error';
import api from '../../helpers/apiClient';
import _ from 'lodash'

export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';

function fetchOrdersRequest(merchantId, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date, order_period_start, order_period_end, order_id) {
  return {
    type: FETCH_ORDERS_REQUEST,
    payload: {
      merchantId, 
      financial_status, 
      fulfillment_status, 
      limit, 
      createdAtMax,
      start_date,
      end_date,
      order_period_start, 
      order_period_end,
      order_id
    }
  };
}

function fetchOrdersSuccess(json) { 
  return {
    type: FETCH_ORDERS_SUCCESS,
    payload: {
      items: json.orders,
      next: json.next,
      receivedAt: Date.now(),
    }
  };
}

function fetchOrdersFailure() {
  return {
    type: FETCH_ORDERS_FAILURE
  };
}

function shouldFetchOrders(state) {
  const results = state.orders;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

function fetchOrdersFromApi(merchantId, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date, order_period_start, order_period_end, order_id) {
  const queryString = qs.stringify({
    merchantId, 
    financial_status, 
    fulfillment_status, 
    limit, 
    createdAtMax,
    start_date,
    end_date,
    order_period_start,
    order_period_end,
    order_id
  }, { skipNulls: true });
  return dispatch => {
    dispatch(fetchOrdersRequest(merchantId, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date, order_period_start, order_period_end, order_id));
    return api(`/orders?${queryString}`)
      .then(json => {
        dispatch(fetchOrdersSuccess(json));
      })
      .catch(err => {
        dispatch(fetchOrdersFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchOrders(merchantId, financial_status, fulfillment_status, limit, createdAtMax, startDate, endDate, order_period_start, order_period_end, order_id) {
  return (dispatch, getState) => {
    if (shouldFetchOrders(getState())) {
      return dispatch(fetchOrdersFromApi(merchantId, financial_status, fulfillment_status, limit, createdAtMax, startDate, endDate, order_period_start, order_period_end, order_id));
    }
  };
}

export function clearOrders() {
  return {
    type: CLEAR_ORDERS,
  };
}

export function updateOrders(fulfillments) {

  return (dispatch, getState) => {

    const {items} = getState().orders

    let updatedItems = _.map(items, (item) => {

      if(_.find(fulfillments, function (o) { return o.line_items[0].id === item.line_item.id })){
        let update = { ...item }
        update.line_item_fulfilled = true
        update.fulfillment_status = 'fulfilled'

        return update
      }

      return item
    })

    dispatch({
      type: UPDATE_ORDERS,
      payload: updatedItems
    })
  }
}

