import { addError } from './error';
import api from '../../helpers/apiClient';
import qs from "qs";

export const FETCH_PICKUPS_REQUEST = 'FETCH_PICKUPS_REQUEST';
export const FETCH_PICKUPS_SUCCESS = 'FETCH_PICKUPS_SUCCESS';
export const FETCH_PICKUPS_FAILURE = 'FETCH_PICKUPS_FAILURE';
export const CLEAR_PICKUPS = 'CLEAR_PICKUPS';

function fetchPickupsRequest() {
  return {
    type: FETCH_PICKUPS_REQUEST
  };
}

function fetchPickupsSuccess(json) {
  return {
    type: FETCH_PICKUPS_SUCCESS,
    payload: {
      items: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchPickupsFailure() {
  return {
    type: FETCH_PICKUPS_FAILURE
  };
}

function shouldFetchPickups(state) {
  const results = state.pickups;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

function fetchPickupsFromApi(start_date) {
  const queryString = qs.stringify({
    start_date
  }, { skipNulls: true });
  return dispatch => {
    dispatch(fetchPickupsRequest(start_date));
    return api(`/pickups?${queryString}`)
      .then(json => {
        dispatch(fetchPickupsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchPickupsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchPickups(start_date) {
  return (dispatch, getState) => {
    if (shouldFetchPickups(getState())) {
      return dispatch(fetchPickupsFromApi(start_date));
    }
  };
}

export function clearPickups() {
  return {
    type: CLEAR_PICKUPS,
  };
}
