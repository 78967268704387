import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button, Alert, Badge, Accordion, Row, Col, Form, Container } from 'react-bootstrap';
import Loader from '../components/Loader';
import VendorSelection from '../components/VendorSelection'
import * as productsActionCreators from '../redux/actions/products';
import * as linkedStoresActionCreators from '../redux/actions/linkedStores';
import api from '../helpers/apiClient';
import ConfirmModal from '../components/ConfirmModal';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import promptIcon from '../images/adminPrompt.svg';
import TabHeader from '../components/TabHeader';
import ReactTooltip from 'react-tooltip';
import ProductEditModal from '../components/ProductEditModal';
import * as taggingsActionCreators from '../redux/actions/taggings';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

class Products extends Component {
  constructor(props) {
    super(props);
    const { location, user } = props;

    let vendorList = [];
    let vendor = 'any';
    let status = 'any';
    let inventory = 'any';
    let dfpId = ''

    if(user.merchantId){
      vendorList = user.merchantId.split('#,');
    }

    if (location.search) {
      const queryString = qs.parse(location.search.substr(1));

      if (queryString.vendor) {
        vendor = queryString.vendor;
      } else if (vendorList.length > 1 && !queryString.vendor){
        vendor = vendorList[0]
      }

      if (queryString.status) {
        status = queryString.status;
      }

      if (queryString.inventory) {
        inventory = queryString.inventory;
      }

      if(queryString.dfpId){
        dfpId = queryString.dfpId
      }
    }

    this.state = {
      vendor,
      vendorList,
      status,
      dfpId,
      inventory,
      selectedIds: [],
      showConfirmArchiveProductModal: false,
      showConfirmDuplicateProductModal: false,
      displayDuplicateProductSuccess: false,
      alertOperationFail: false,
      errorMessage: '',
      duplicatedProductId: '',
      showProductEditModal: false,
      selectedPending: false,
      exporting: false
    };
  }

  async componentDidMount() {
    const { fetchProductsSimple, location, user, fetchTaggings, fetchLinkedStores } = this.props;
    const { vendor, dfpId } = qs.parse(location.search.substr(1));

    if(!vendor && ['admin', 'staff'].includes(user.role)){
      this.setState({
        adminPrompt: true
      })
    } else {
      fetchProductsSimple(vendor, dfpId);
    }

    await fetchTaggings()
    await fetchLinkedStores()
    await this.setSelectOptions()
  }

  componentWillReceiveProps(nextProps) {
    const { location: prevLocation, user } = this.props;
    const { fetchProductsSimple, location } = nextProps;

    if (location.search !== prevLocation.search) {
      const { vendor, dpfId } = qs.parse(location.search.substr(1));

      let adminPrompt = false
      if(!vendor && ['admin', 'staff'].includes(user.role)){
        adminPrompt = true
      }

      this.setState({
        adminPrompt,
        vendor: vendor || 'any',
        selectedIds: [],
      });

      if(!adminPrompt){
        fetchProductsSimple(vendor, dpfId);
      }
    }
  }

  setSelectOptions = async () => {

    const { taggings: { items: predefinedTags } } = this.props;

    function createOptions(taggings) {
      return _.map(taggings, tag => {
        return {
          label: tag.desc,
          value: tag.tag
        }
      })
    }

    this.setState({
      selectOptions: {
        dayOptions,
        locationOptions: createOptions(predefinedTags['Location'])
      }
    })
  }

  renderExpandedNoteColumn = (row) => {
    return <div>{row.note}</div>
  }

  renderDetailLink = (cell, row) => {

    return (
      <Link
        key={row.id}
        to={{
          pathname: `/products/${_.isNumber(row.id) ? row.id : `pending/${row.id}`}`
        }}
      >
        {cell}
      </Link>
    )
  }

  getInventoryStatus = variants => {
    let tracked = [], available = false, in_stock = false, out_of_stock = false

    for (let i = 0; i < variants.length; i++){
      if(variants[i].inventory_management == null){
        available = true
        continue
      }

      tracked.push(variants[i].inventory_quantity)
    }

    if (tracked.length > 0) {
      in_stock = _.some(tracked, i => i > 0)
      out_of_stock = _.some(tracked, i => i <= 0)
    }

    return { tracked, available, in_stock, out_of_stock }
  }

  calcInventory = variants => {
    if(!variants){
      return ''
    }

    let {
      tracked,
      available,
      in_stock,
      out_of_stock
    } = this.getInventoryStatus(variants);

    let outputString = ''
    if(available){
      outputString = outputString.concat('available<br>')
    }

    if(in_stock || out_of_stock){
      const inStock = _.filter(tracked, i => i > 0)
      const outOfStock = _.filter(tracked, i => i <= 0)

      if(inStock.length > 0){
        outputString = outputString.concat(`${inStock.reduce((s, i) => s + i)} ${inStock.length > 1 ? `in ${inStock.length} variants` : 'in 1 variant'}<br>`)
      }

      if(outOfStock.length > 0){
        if(outputString.length > 0){
          outputString = outputString.concat(`out of stock ${outOfStock.length > 1 ? `in ${outOfStock.length} variants` : 'in 1 variant'}`)
        } else {
          outputString = outputString.concat('out of stock')
        }
      }
    }

    const returnContent = outputString.replace(/<br>$/m, '');

    return <p style={{whiteSpace: 'pre-line', margin: '0'}} dangerouslySetInnerHTML={{__html: returnContent}}/>
  }

  renderSKU = (_, row) => {
    const { variants } = row;

    let skus = []
    for (let variant of variants) {
      if (variant.sku) {
        skus.push(variant.sku)
      }
    }

    return (
      skus.join(' ')
    )
  }

  renderStatus = (cell, row) => {
    if (row.approval_status) {
      switch (row.approval_status) {
        case 'pending':
          return <Badge bg="warning">PENDING</Badge>
      case 'disapproved':
        return <Badge bg="danger">REJECTED</Badge>
      case 'reviewing':
        return <Badge bg="primary">IN REVIEW</Badge>
      case 'more_info':
        return <Badge bg="info">MORE INFO</Badge>
        default:
          break;
      }
    }
    switch (cell){
      case 'active':
        return <Badge bg="success">ACTIVE</Badge>
      case 'draft':
        return <Badge bg="default">DRAFT</Badge>
      default:
        break;
    }
  }

  renderCSVImages = (cell, row) => {
    const images = _.map(cell, img => img.src)

    return images
  }

  renderCSVInventory = (cell, row) => {
    let output = this.calcInventory(cell, row)
    if(typeof output !== 'string'){
      output = (output.props && output.props.dangerouslySetInnerHTML && output.props.dangerouslySetInnerHTML.__html) || ''
    }

    return output.replace('<br>', ',')
  }

  renderLinkage = (cell, row) => {
    if (!row.tags) {
      return <Badge bg="danger">Unlinked</Badge>
    }
    //split then trim tags
    let tags = _.map(row.tags.split(','), tag => tag.trim())

    //check if tags contains entry starting with 'sync_'
    let syncTags = _.find(tags, tag => tag.startsWith('sync_'))
    if(syncTags){
      //return badge with sync tag
      return <Badge bg="primary">Linked</Badge>
    } else {
      return <Badge bg="danger">Unlinked</Badge>
    }
  }

  renderMarkupType = (cell, row) => {
    const { variants } = row;

    let types = []
    for (let variant of variants) {
      let foundType = _.find(variant.metafields, m => m.key === 'markup_type')

      if (foundType) {
        types.push(foundType.value)
      } else {
        types.push('-')
      }
    }

    if (_.every(types, type => type === types[0])) {
      types = types.splice(0, 1)
    }

    return types.join('\n')
  }

  renderMarkupValue = (cell, row) => {
    const { variants } = row;

    let values = []
    for (let variant of variants) {
      let foundValue = _.find(variant.metafields, m => m.key === 'markup')

      if (foundValue) {
        values.push(foundValue.value)
      } else {
        values.push('-')
      }
    }

    if (_.every(values, value => value === values[0])) {
      values = values.splice(0, 1)
    }

    return values.join('\n')
  }

  handleStatusChange = (e) => {
    this.setState({ status: e.target.value })
  }

  handleInventoryChange = (e) => {
    this.setState({ inventory: e.target.value })
  }

  handleVendorChange = (e) => {
    this.setState({ vendor: e.target.value })
  }

  handleOnClickFilter = () => {
    const { history, location: { pathname }, user } = this.props;
    const { vendor, dfpId } = this.state

    let queryBody = {
      vendor,
      dfpId
    }

    const queryString = qs.stringify(queryBody)
    history.push(`${pathname}?${queryString}`)
  }

  onRowSelect = (row, isSelected, e) => {

    let { selectedIds } = this.state;

    if(isSelected){
      selectedIds.push({id: row.id, title: row.title, variants: row.variants, tags: row.tags, status: row.status})
    } else {
      selectedIds = _.reject(selectedIds, {id: row.id})
    }

    let selectedPending = _.some(selectedIds, product => product.status !== 'active' && product.status !== 'draft')
    let selectedDisapproved = _.some(selectedIds, product => product.status === 'disapproved')

    this.setState({ selectedIds, selectedPending, selectedDisapproved });
  }

  onSelectAll = (isSelected, rows) => {
    let { selectedIds } = this.state;

    if (isSelected) {
      _.each(rows, function (row) {
        selectedIds.push({id: row.id, title: row.title, variants: row.variants, tags: row.tags, status: row.status})
      })
    } else {
      _.each(rows, function (row) {
        selectedIds = _.reject(selectedIds, {id: row.id})
      });
    }

    let selectedPending = _.some(selectedIds, product => product.status !== 'active' && product.status !== 'draft')
    let selectedDisapproved = _.some(selectedIds, product => product.status === 'disapproved')

    this.setState({ selectedIds, selectedPending, selectedDisapproved });

    return true;
  }

  handleOnClickDuplicateProduct = () => {
    this.setState({ showConfirmDuplicateProductModal: true })
  }

  handleOnCloseDuplicateProductModal = () => {
    this.setState({ showConfirmDuplicateProductModal: false })
  }

  handleDuplicateProduct = () => {
    const { selectedIds } = this.state;
    const { products: { items } } = this.props;
    const { id, title } = selectedIds[0];

    let body = {
      id,
      title: title + ' - Copy'
    }

    return api(`/products/duplicate`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then(result => {
      this.setState({
        showConfirmDuplicateProductModal: false,
        selectedIds: [],
        displayDuplicateProductSuccess: true,
        alertOperationFail: false,
        duplicatedProductId: result.id
      });
    }).catch(err => {
      this.setState({
        displayDuplicateProductSuccess: false,
        alertOperationFail: true,
        errorMessage: err.message
      })
    })
  }

  renderDate = (cell, row) => {

    return cell ? moment(cell).format("DD/MM/YYYY") : ''
  }

  showImage = (cell, row) => {
    if(cell && cell.length && cell[0]){
      return <img style={{height: '70px'}} src={cell[0].thumbnail || cell[0].src}/>;
    }
  }

  handleOnClickEditProducts = () => {
    this.setState({ showProductEditModal: true });
  }

  handleOnClickBulkEditProducts = () => {
    //open a new tab for bulk edit
    const { selectedIds } = this.state;

    let productIds = _.map(selectedIds, 'id');

    window.open(`/products/bulk-edit?ids=${productIds.join(',')}`, '_blank');
  }

  handleOnCloseEditProductModal = (updating) => {
    this.setState({ showProductEditModal: false });

    if (updating) {
      alert("Updating products... this may take up to a couple minutes, please refresh the page in a moment")
    }
  }

  onClickArchiveProducts = () => {
    this.setState({ showConfirmArchiveProductModal: true })
  }

  handleOnCloseConfirmArchiveProductsModal = () => {
    this.setState({ showConfirmArchiveProductModal: false })
  }

  handleOnClickExportAsCSV = async () => {
    const { selectedIds } = this.state;
    const { products: { items } } = this.props;

    this.setState({ exporting: true })

    //call /products/export/csv dowlnoad csv file
    let productIds = _.map(selectedIds, 'id');

    //call api to export selected products as csv
    let result = await api(`/products/export/csv`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ ids: _.map(selectedIds, 'id') })
    })

    if(result && result.url){
      window.open(result.url, '_blank')
    }

    this.setState({ exporting: false })
  }

  handleOnClickDeleteRejectedProduct = async () => {
    this.setState({ showConfirmDeleteProductModal: true })
  }

  handleOnCloseDeleteProductModal = () => {
    this.setState({ showConfirmDeleteProductModal: false })
  }

  handleDeleteRejectedProducts = async () => {
    const { selectedIds } = this.state;
    const { fetchProductsSimple, location } = this.props;
    const { vendor, dfpId } = qs.parse(location.search.substr(1));

    try {
      for(let i = 0; i < selectedIds.length; i++){
        if (selectedIds[i].status === 'disapproved') {
          await api(`/products/${selectedIds[i].id}`, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
          })
        }
      }    
      this.setState({
        selectedIds: [],
        selectedDisapproved: false,
        displayDeleteProductSuccess: true,
        alertOperationFail: false,
        showConfirmDeleteProductModal: false,
      });
      setTimeout(() => {
        fetchProductsSimple(vendor, dfpId);
      }, 5000)
    } catch (err) {
      this.setState({
        displayDeleteProductSuccess: false,
        alertOperationFail: true,
        errorMessage: err.message,
        showConfirmDeleteProductModal: false,
      })
    }
  }

  render() {
    const { products: { loading, items, next, unselectableRows }, user, linkedStores } = this.props;
    const { vendor, vendorList, status, dfpId, inventory, selectedIds, showConfirmDuplicateProductModal, displayDuplicateProductSuccess, duplicatedProductId, alertOperationFail, errorMessage, adminPrompt, showProductEditModal, selectedPending, selectedDisapproved, selectOptions, exporting, displayDeleteProductSuccess, showConfirmDeleteProductModal } = this.state;

    const isAdmin = user.role === 'admin' || user.role === 'staff'

    //check if linkedStores contains any entry
    let linkedStoresExist = false
    if(linkedStores.items && linkedStores.items.length > 0){
      //find vendor in linkedStores

      let linkedStore = _.find(linkedStores.items, {vendor: vendor})

      if((linkedStore && linkedStore.stores && linkedStore.stores.length > 0) || vendor === 'any'){
        linkedStoresExist = true
      }
    }

    if(isAdmin){
      linkedStoresExist = true
    }

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      nonSelectable: unselectableRows,
      headerColumnStyle: { width: '30px' },
    }

    const options = {
      showTotal: true,
      sizePerPage: 20,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: items.length
      }],
    };

    const columns = [
      {
        dataField: 'id',
        text: 'Id',
        hidden: true,
      },
      {
        dataField: 'images',
        text: 'Image',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        align: 'center',
        formatter: this.showImage,
        csvFormatter: this.renderCSVImages,
        csvText: 'Image Src'
      },
      {
        dataField: 'handle',
        text: 'Handle',
        hidden: true,
        csvExport: isAdmin,
      },
      {
        dataField: 'title',
        headerStyle: {
          width: '400px',
          resize: 'horizontal'
        },
        sort: true,
        text: 'Title',
        formatter: this.renderDetailLink,
      },
      {
        dataField: 'titlesearch',
        hidden: true,
        text: 'titlesearch',
        formatter: (_, row) => row.title,
      },
      {
        dataField: 'variants_sku',
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'SKU',
        sort: true,
        formatter: this.renderSKU,
        csvFormatter: this.renderSKU,
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'status',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Status',
        sort: true,
        formatter: this.renderStatus,
      },
      {
        dataField: 'statussearch',
        text: 'statussearch',
        hidden: true,
        formatter: (_, row) => row.status,
      },
      {
        dataField: 'variants',
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'Inventory',
        sort: true,
        formatter: this.calcInventory,
        csvFormatter: this.renderCSVInventory,
      },
      {
        dataField: 'linked',
        text: 'Linked',
        hidden: !linkedStoresExist,
        csvExport: false,
        formatter: this.renderLinkage,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'product_type',
        text: 'Type',
        sort: true,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'vendor',
        text: 'Vendor',
        sort: true,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
        hidden: !isAdmin
      },
      {
        dataField: 'updated_at',
        text: 'Updated At',
        sort: true,
        formatter: this.renderDate,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'created_at',
        text: 'Created At',
        sort: true,
        formatter: this.renderDate,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'template_suffix',
        text: 'Template',
        hidden: true,
        csvExport: isAdmin,
      },
      {
        dataField: 'tags',
        text: 'Tags',
        hidden: true,
        csvExport: isAdmin,
      },
      {
        dataField: 'variants_markup_type',
        text: 'Markup Type',
        csvFormatter: this.renderMarkupType,
        hidden: true,
        csvExport: isAdmin,
      },
      {
        dataField: 'variants_markup_value',
        text: 'Markup Value',
        csvFormatter: this.renderMarkupValue,
        hidden: true,
        csvExport: isAdmin,
      }
    ];

    let currentLinkedStore
    if(vendor === 'any'){
      currentLinkedStore = _.find(linkedStores.items, {vendor: vendorList[0]})
    } else {
      currentLinkedStore = _.find(linkedStores.items, {vendor: vendor})
    }

    return (
      <div>
        <TabHeader
          title="Products"
          tab_id="products"
          user={user}
          children={
            <>
            <Link to="/archived_products" className="btn btn-secondary">Archived Products</Link>
            </>
          }
        />
        { currentLinkedStore && currentLinkedStore.stores && currentLinkedStore.stores.length > 0 &&
          <div className="mb-2">
            <label className="me-2">Linked Stores :</label>
            {
              currentLinkedStore.stores.map((item, ind) => {
                // display badges in one row
                return (
                  <Badge key={ind} bg="primary" className="me-2">{item}</Badge>
                )
              })
            }
          </div>
        }
        { currentLinkedStore && currentLinkedStore.stores && currentLinkedStore.stores.length === 0 &&
          <div>
            <Link
              className="btn btn-primary btn-br-6 me-2"
              to={{
                pathname: `/products/import`,
              }}
            >Link Your Store(s) Now</Link>
          </div>
        }
        {
          displayDuplicateProductSuccess &&
          <div className="sticky-alert">
            <Alert variant="success">
              <span>Product duplicated successfully. </span>
                <Link to={{pathname: `/products/pending/${duplicatedProductId}`,}}>Click here to view product.</Link>
            </Alert>
          </div>
        }
        {
          displayDeleteProductSuccess &&
          <div className="sticky-alert">
            <Alert variant="success">
              <span>Product(s) deleted successfully. Product list will refresh momentarily.</span>
            </Alert>
          </div>
        }
        {
          alertOperationFail &&
          <div className="sticky-alert">
            <Alert variant="danger">
              <span>{errorMessage}</span>
            </Alert>
          </div>
        }

        { (isAdmin || vendorList.length > 1) &&
        <Accordion style={{borderRadius: '18px'}} className="dashboard-card shadow-sm" defaultActiveKey="0">
          <Accordion.Item style={{borderRadius: '18px', border: 'none'}} eventKey="0">
            <Accordion.Header><h5 style={{marginBottom: '0'}}>Vendor</h5></Accordion.Header>
            <Accordion.Body>
              <Row>
                {
                  isAdmin &&
                    <Col sm={12} xl={4} className="mb-3 mb-sm-3" style={{textAlign: 'left'}}>
                      <VendorSelection handleOnVendorChange={this.handleVendorChange} selectedVendor={vendor} showLabel={false}/>
                    </Col>
                }
                { //For dual vendor
                  vendorList.length > 1 &&
                    <Col sm={6} xl={4} className="mb-3 mb-sm-3" style={{textAlign: 'left'}}>
                      <div>
                        <Form.Select className="form-control" onChange={this.handleVendorChange} value={vendor}>
                          {
                            vendorList.map((option, ind) => {
                              return (
                                <option key={ind} value={option}>{option}</option>
                              )
                            })
                          }
                        </Form.Select>
                      </div>
                    </Col>
                }
                <Col sm={3} className="filter-buttons d-flex flex-sm-column flex-lg-row justify-content-between mb-auto">
                  <Button
                      className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                      variant="primary"
                      style={{width: '100%'}}
                      onClick={this.handleOnClickFilter}
                    >Apply
                  </Button>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        }

        <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap">
          { 
            <Col sm="auto">
              <Button
                variant="secondary"
                className="w-100 btn-br-6"
                disabled={selectedIds.length===0 || exporting}
                onClick={this.handleOnClickExportAsCSV}>{exporting ? "Exporting" : `Export ${selectedIds.length} Product(s) to XLSX`}</Button>
            </Col>
          }
          <Col sm="auto">
            <Link
              className="btn btn-primary w-100 btn-br-6"
              to={{
                pathname: `/products/import`,
              }}
            >Import by Excel Sheet or Linked Store</Link>
          </Col>
          <Col sm="auto">
            <Button
              variant="danger-secondary"
              className="w-100 btn-br-6"
              disabled={!selectedDisapproved}
              onClick={this.handleOnClickDeleteRejectedProduct}
            >Delete Rejected Products</Button>
          </Col>
          <Col sm="auto">
            <Link
              className="btn btn-primary w-100 btn-br-6"
              to={{
                pathname: `/products/create`,
              }}
            >Create Product</Link>
          </Col>
          <Col sm="auto">
            <Button
              variant="secondary"
              className="w-100 btn-br-6"
              disabled={selectedIds.length===0 || selectedIds.length>1}
              onClick={this.handleOnClickDuplicateProduct}
            >Duplicate Product</Button>
          </Col>
          <Col sm="auto">
            <Button
              variant="secondary"
              className="w-100 btn-br-6"
              disabled={selectedIds.length===0 || selectedPending}
              onClick={this.handleOnClickEditProducts}
              data-tip="Available for approved products only"
              data-for="active-products-only"
            >Edit {selectedIds.length} Product(s)</Button>
            { selectedPending &&
              <ReactTooltip place="bottom" id="active-products-only" />
            }
          </Col>
        </Container>
        {
          loading &&
          <Loader />
        }
        {
          adminPrompt &&
          <div className="admin-prompt">
            <img src={promptIcon}></img>
            <h3 className="mb-0">Please select filter options</h3>
          </div>
        }
        {
          !loading && items && !adminPrompt &&
          <ToolkitProvider
              keyField='id'
              data={_.filter(items, item => {
                let validStatus = false, validInventory = false

                let {
                  available,
                  in_stock,
                  out_of_stock
                } = this.getInventoryStatus(item.variants || []);

                if (status === 'any' || item.status === status) {
                  validStatus = true
                }
                if (inventory === 'any') {
                  validInventory = true
                } else if (inventory === 'available' && available) {
                  validInventory = true
                } else if (inventory === 'in_stock' && in_stock) {
                  validInventory = true
                } else if (inventory === 'out_of_stock' && out_of_stock) {
                  validInventory = true
                }

                return validStatus && validInventory
              })}
              columns={columns}
              exportCSV={{
                exportAll: true
              }}
              search={{
                searchFormatted: true
              }}
            >
              {
                props => (
                  <>
                  <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                    <Col className="d-flex align-items-center gap-2">
                      <label style={{marginBottom: '5px'}}>Status:</label>
                      <Form.Select className="form-control" onChange={this.handleStatusChange} value={status}>
                        <option value="any">Any</option>
                        <option value="active">Active</option>
                        <option value="draft">Draft</option>
                        <option value="pending">Pending</option>
                        <option value="reviewing">Reviewing</option>
                        <option value="more_info">More Info</option>
                        <option value="disapproved">Disapproved</option>
                      </Form.Select>
                    </Col>
                    <Col className="d-flex align-items-center gap-2">
                      <label style={{marginBottom: '5px'}}>Inventory:</label>
                      <Form.Select className="form-control" onChange={this.handleInventoryChange} value={inventory}>
                        <option value="any">Any</option>
                        <option value="available">Available (Untracked)</option>
                        <option value="in_stock">In Stock</option>
                        <option value="out_of_stock">Out of Stock</option>
                      </Form.Select>
                    </Col>
                      {/* <Col>
                        <ExportCSVButton
                          className="btn-info"
                          style={{borderRadius:'8px'}}
                          { ...props.csvProps }
                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                          Export to CSV</ExportCSVButton>
                      </Col> */}
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps }
                        style={{borderRadius:'25px'}} placeholder="Search"/>
                      </Col>
                    </Container>

                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={false}
                      selectRow={selectRowProp}
                      pagination={paginationFactory(options)}
                      hover expandableRow={() => true}
                      options={{
                        expandBy: "column",
                        sizePerPage: 20,
                        paginationShowsTotal: true,
                        sortName: "created_at",
                        sortOrder: "desc"
                      }}
                      expandComponent={this.renderExpandedNoteColumn}
                    />
                </>
            )
          }
          </ToolkitProvider>
        }
        {
          showConfirmDuplicateProductModal &&
            <ConfirmModal onClickYes={this.handleDuplicateProduct} onClose={this.handleOnCloseDuplicateProductModal} title="Duplicate Product">
              <p>Confirm duplicate {_.find(items, i => i.id === selectedIds[0].id).title}?</p>
            </ConfirmModal>
        }
        {
          showConfirmDeleteProductModal &&
            <ConfirmModal onClickYes={this.handleDeleteRejectedProducts} onClose={this.handleOnCloseDeleteProductModal} title="Delete Rejected Products">
              <p>Confirm delete {_.filter(selectedIds, i => i.status === 'disapproved').length} rejected product(s)?</p>
            </ConfirmModal>
        }
        {
          showProductEditModal &&
            <ProductEditModal selectedProducts={selectedIds} onBulkEditMoreFields={this.handleOnClickBulkEditProducts} onClose={this.handleOnCloseEditProductModal} selectOptions={selectOptions}/>
        }
      </div>
    );
  }
}

const dayOptions = [
  { value: 'sun', label: 'Sunday' },
  { value: 'mon', label: 'Monday' },
  { value: 'tue', label: 'Tuesday' },
  { value: 'wed', label: 'Wednesday' },
  { value: 'thu', label: 'Thursday' },
  { value: 'fri', label: 'Friday' },
  { value: 'sat', label: 'Saturday' },
]

const defaultOptions = [{key: 'any', value: 'any', name: 'Any'}]

export default connect((state, props) => {
  const { pathname, search, routeState } = props.location;
  return {
    taggings: state.taggings,
    user: state.auth.user,
    products: state.products,
    linkedStores: state.linkedStores};
}, {...productsActionCreators,
    ...taggingsActionCreators,
    ...linkedStoresActionCreators})(Products);
