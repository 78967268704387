import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { Alert, Button, Row, Col, Card } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import {
  Input,
  TextArea,
  Dropzone
} from '../components/Form/index';
import TimelineActionButton from '../components/TimelineActionButton'
import { DateRangePicker, DayPickerRangeController } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import api from '../helpers/apiClient';
import moment from 'moment';
import _ from 'lodash'
import { SingleDatePicker } from 'react-dates';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import * as announcementActionCreators from '../redux/actions/announcement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

class Announcements extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      showRemarkModal: false,
      showDcidkModal: false,
      startDate: null,
      endDate: null,
      mode: "create",
      announcement: null,
      id: null,
      editorState: EditorState.createEmpty(),
      title: "",
      desc: ""
    }
  }

  componentDidMount() {
    const { fetchAnnouncements } = this.props;
    const { mode } = this.state;

    fetchAnnouncements();
  }

  submit = (values) => {
    const {file, mode, startDate, endDate, title, desc, id, editorState} = this.state
    const { fetchAnnouncements } = this.props;

    var formData = new FormData();
    formData.append('file', file);
    formData.append('title', title);
    formData.append('desc', desc);
    formData.append('startDate', startDate.unix());
    formData.append('endDate', endDate.unix());
    formData.append('id', id);

    return api(`/announcement`, {
      method: mode == "create" ? 'POST' : 'PUT',
      body: formData
    }, {'Accept': 'application/json'}).then(result => {
      this.setState({
        mode: "create",
        file: null,
        title: "",
        editorState: EditorState.createEmpty(),
        desc: "",
        startDate: null,
        endDate: null,
      })
      fetchAnnouncements()
    })
  }

  handleOnInputChange = (e) => {

    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  }

  handleChangeFile = (e) =>{
    if (e.target.files && e.target.files[0]) {
      this.setState({
        file: e.target.files[0]
      });
    }
  }

  handleDeleteClick = (updateId) => {

    const { fetchAnnouncements } = this.props;

    if (confirm(`Are you sure you want to delete this announcement?`)) {

      return api(`/announcement/${updateId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((result) => {

        fetchAnnouncements()
      }).catch(err => {
        if (err) {

          alert(err.toString())
        }
      });
    }
  }

  handleEditClick = (id, annTitle, desc, startDate, endDate) => {
    window.scrollTo(0, 0);

    const { fetchAnnouncements } = this.props;

    this.setState({
      mode: "edit",
      startDate: moment(startDate),
      endDate: moment(endDate),
      title: annTitle,
      desc: desc,
      editorState: EditorState.createWithContent(stateFromHTML(desc)),
      id
    });
  }

  handleCancelEdit = () => {
    this.setState({
      mode: "create",
      title: "",
      desc: "",
      editorState: EditorState.createEmpty(),
      startDate: null,
      endDate: null,
     })
  }

  editorChange = editorState => {
    // let content = (stateToHTML(editorState.getCurrentContent());

    this.setState({
      editorState,
      desc: stateToHTML(editorState.getCurrentContent())
    })

  }

  render() {
    const { announcement:{ loading, loaded, detail, fetchError }, error, submitting, handleSubmit } = this.props
    const { mode, title, desc, editorState } = this.state
    const eventStyle = {
      "backgroundColor": "#fff",
      "boxShadow": 'none',
      "borderRadius": "18px",
      "lineHeight": "1.6",
      "fontSize": "16px",
      "fontWeight": "400",
      "fontFamily": "Varela Round, sans-serif",
      "padding": "1.5em 1em",
    }
    const titleStyle =  {
      "width": '70%'
    }

    return (
      <div>
        <div className="row mt-3 mb-4">
          <div className="col-xs-12 col-md-9">
            <h2 className="sub-header">Announcements</h2>
          </div>
        </div>
        {
          error &&
          <Alert variant="danger">
            <div>{error}</div>
          </Alert>
        }
        <Row className="flex-column-reverse flex-lg-row">
          <Col lg={6}>
            {
              detail && detail.length > 0 &&
              <Timeline lineStyle={{background: 'none'}} style={{margin: '0', width: '100%'}}>
                {
                  detail.map(event=> (
                    <TimelineEvent
                        key={event._id}
                        title={event.title}
                        titleStyle={titleStyle}
                        createdAt={`${moment(event.created).format('ddd DD/MM/YYYY h.mmA')} | Active: ${moment(event.start_date).format("DD/MM")} - ${moment(event.end_date).format("DD/MM")}`}
                        icon={<FontAwesomeIcon icon={solid('bullhorn')} transform="grow-4" style={{color: '#80d7cf'}}/>}
                        bubbleStyle={{background: 'none', border: 'none'}}
                        contentStyle={eventStyle}
                        buttons={
                        <div>
                          <span style={{color: '#D15D5D', cursor:'pointer'}}><TimelineActionButton icon={<FontAwesomeIcon icon={solid('trash')} transform="grow-4"/>} title="Delete" id={event._id} onButtonClick={this.handleDeleteClick}/></span>
                          <span style={{color: '#5A5A5A', cursor:'pointer'}}><TimelineActionButton id={event._id} onButtonClick={this.handleEditClick} title="Edit" icon={<FontAwesomeIcon icon={solid('pencil')} transform="grow-4"/>} annTitle={event.title} desc={event.desc} startDate={event.start_date} endDate={event.end_date}/></span>
                        </div>}
                        >
                        <div dangerouslySetInnerHTML={{__html: event.desc}}>
                        </div>
                        { event.file_url && event.is_image &&
                          <img className="img-responsive" src={event.file_url} />
                        }
                        { event.file_url && !event.is_image &&
                          <p>
                            Attached File: <a target="_blank" href={event.file_url}>Download file</a>
                          </p>
                        }
                    </TimelineEvent>
                  ))
                }
              </Timeline>
            }
            {
              loading &&
              <h2>Loading...</h2>
            }
          </Col>
          <div className="vr d-none d-lg-block col-lg-1 dashboard-vr" style={{maxHeight: '40em'}}></div>
          <Col lg={5}>
            { !loading && detail && !fetchError &&
              <div>
                <form onSubmit={handleSubmit(this.submit.bind(this))}>
                <Card className="d-print-none tealCard mt-4 mt-sm-0 shadow-sm">
                  <Card.Header className="tealCardHeader">
                    {mode == "create" ?
                    'Create Announcement'
                    : 'Edit Announcement'}
                  </Card.Header>
                  <Card.Body className="grey-label label-strong d-grid gap-3">
                    <div className="d-grid gap-1">
                      <label>Title</label>
                      <input type="text" className="form-control" id="title" name="title" value={title} placeholder="Title" onChange={this.handleOnInputChange}/>
                    </div>
                    <div className="d-grid gap-1">
                      <label>Description</label>
                      <div style={{ border: '1px solid #ced4da', borderRadius: '4px' }}>
                        <Editor
                          onEditorStateChange={this.editorChange}
                          editorState={editorState}
                          editorStyle={{ resize: 'vertical', height: '150px', padding: '0 1rem' }} />
                      </div>
                      {/* <textarea className="form-control" id="desc" name="desc" value={desc} placeholder="Description" rows="3" onChange={this.handleOnInputChange}/> */}
                    </div>
                    <div className="d-grid gap-1">
                      <label>Active Date</label>
                      <DateRangePicker
                        small
                        minimumNights={0}
                        displayFormat="D/M/YY"
                        showClearDates={true}
                        showDefaultInputIcon={true}
                        isOutsideRange={() => false}
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        onDatesChange={({ startDate, endDate }) =>
                          this.setState({
                            startDate: startDate ? startDate.startOf('day') : startDate,
                            endDate: endDate ? endDate.endOf('day') : endDate
                          })
                        } // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(focusedInput) =>
                          this.setState({ focusedInput })
                        } // PropTypes.func.isRequired,
                      />
                    </div>
                      {
                        mode == "create" &&
                        <Button
                          variant="primary"
                          className="btn-br-6 my-2"
                          type="submit"
                          disabled={submitting}
                        >
                          {submitting ? 'LOADING...' : 'CREATE'}
                        </Button>
                      }
                      {
                        mode == "edit" &&
                        <Row>
                          <Col xs={6}>
                            <Button
                              className="btn-br-6 w-100 my-2"
                              variant="danger-secondary"
                              onClick={this.handleCancelEdit}
                              disabled={submitting}
                            >
                              CANCEL
                            </Button>
                          </Col>
                          <Col xs={6}>
                            <Button
                              variant="primary"
                              type="submit"
                              className="btn-br-6 w-100 my-2"
                              disabled={submitting}
                            >
                              {submitting ? 'LOADING...' : 'UPDATE'}
                            </Button>
                          </Col>
                        </Row>
                      }
                  </Card.Body>
                </Card>
                </form>
              </div>
            }
          </Col>
        </Row>
      </div>
    );
  }
}

Announcements = reduxForm({
  form: 'update_status',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(Announcements);

export default connect(state => {

  return {
    announcement: state.announcement
  }
}, {...announcementActionCreators })(Announcements)
