import { addError, resetError } from './error';
import api from "../../helpers/apiClient";

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN';
export const FAILED_LOGIN = 'FAILED_LOGIN';

export const LOGOUT = 'LOGOUT';
export const SET_REDIRECT = 'SET_REDIRECT';
// const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
// const LOGOUT_FAIL = 'LOGOUT_FAIL';

function postLogin(email, password) {
  return (dispatch, getState) => {
    dispatch(requestLogin());
    return api('/auth/login', {
      method: "post",
      body: JSON.stringify({ email, password })
    })
    .then(json => {
      const { auth } = getState();
      dispatch(successLogin(json));
      dispatch(resetError());

      if (auth.redirect) {
        return auth.redirect;
      }

      if(json && json.user && json.user.role === 'merchant-staff'){
        return '/orders'
      } else {
        return '/'
      }
    })
    .catch((err) => {
      dispatch(failedLogin());
      dispatch(addError(err.message));
    });
  };
}

function shouldPostLogin(state, email, password) {
  if (!email || !password) return false;

  const result = state.auth;
  if (result && result.loading) {
    return false;
  } else {
    return true;
  }
}

function requestLogin() {
  return {
    type: REQUEST_LOGIN
  };
}

function successLogin(json) {
  return {
    type: SUCCESS_LOGIN,
    payload: {
      user: {
        ...json.user,
        token: json.token,
      },
      receivedAt: Date.now(),
    }
  };
}

function failedLogin() {
  return {
    type: FAILED_LOGIN
  };
}

function logUserOut(redirect) {
  return {
    type: LOGOUT,
    payload: {
      redirect,
    }
  }
}

export function login(email, password, done) {
  return (dispatch, getState) => {
    return dispatch(postLogin(email, password)).then(result => {
      done(result)
    });
    // if (shouldPostLogin(getState(), email, password)) {

    // }
  };
}

export function logout(done, redirect = false){
  return (dispatch, getState) => {
    done('/login')
    dispatch(logUserOut(redirect));
  }
}

function setLoginRedirect(link) {
  return {
    type: SET_REDIRECT,
    payload: {
      redirect: link
    }
  }
}

export function setRedirect(link) {
  return (dispatch) => {
    dispatch(setLoginRedirect(link));
  }
}
