import { EditorState, Modifier, SelectionState } from 'draft-js';
import { getSelectedBlocksMap } from 'draftjs-utils';

const styles = ['BOLD', 'ITALIC', 'UNDERLINE'];
export function removeInlineStyles(editorState) {
  const contentState = editorState.getCurrentContent();
  const contentWithoutStyles = styles.reduce(
    (newContentState, style) =>
    Modifier.removeInlineStyle(
      newContentState,
      editorState.getSelection(),
      style
    ),
    contentState
  );

  const newEditorState = EditorState.push(
    editorState,
    contentWithoutStyles,
    'change-inline-style'
  );

  return newEditorState;
}

export function removeEntities(editorState) {
  const contentState = editorState.getCurrentContent();
  const contentWithoutEntities = Modifier.applyEntity(
    contentState,
    editorState.getSelection(),
    null
  );

  const newEditorState = EditorState.push(
    editorState,
    contentWithoutEntities,
    'apply-entity'
  );

  return newEditorState;
};

export function removeBlockTypes(editorState) {
  const contentState = editorState.getCurrentContent();
  const blocksMap = getSelectedBlocksMap(editorState);
  const contentWithoutBlocks = blocksMap.reduce((newContentState, block) => {
    const selectionState = SelectionState.createEmpty(block.getKey());
    const updatedSelection = selectionState.merge({
      focusOffset: 0,
      anchorOffset: block.getText().length
    });

    return Modifier.setBlockType(newContentState, updatedSelection, 'unstyled');
  }, contentState);

  const newEditorState = EditorState.push(
    editorState,
    contentWithoutBlocks,
    'change-block-type'
  );

  return newEditorState;
};
