import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const InfoModal = ({ title, onClose, children, size, footerCloseButton = false }) => {

  return (
    <Modal show={true} onHide={onClose} size={size}>
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        {
          title &&
            <h4 className="mb-0">{title}</h4>
        }
      </Modal.Header>
      <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
        {children}
      </Modal.Body>
      { footerCloseButton && 
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          OK
        </Button>
      </Modal.Footer>
      }
    </Modal>
  );
}

export default InfoModal;
