import {
  OPEN_TICKETS_COUNT,
  PICKUP_REMINDER_COUNT,
} from '../actions/notifications';

const defaultState = {
  open_count: 0,
  pickup_reminder_count: 0,
};

export default function tickets(state = defaultState, action) {
  switch (action.type) {
    case OPEN_TICKETS_COUNT:
      return {
        ...state,
        open_count: action.payload
      }
    case PICKUP_REMINDER_COUNT:
      return {
        ...state,
        pickup_reminder_count: action.payload
      }
    default:
      return state;
  }
}
