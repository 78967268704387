import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Row, Col, Badge } from 'react-bootstrap';
import * as customerActionCreators from '../redux/actions/customer';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loader from '../components/Loader';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class Customer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { match, fetchCustomer } = this.props;
    if (match.params.customerId) {
      fetchCustomer(match.params.customerId);
    }

  }

  renderOrderDetailLink = (cell, row) => {
    return (
      <Link
        key={row.id}
        to={{
          pathname: row.manual_order
            ? `/manual_orders/${row.manual_order_id}`
            : `/orders/${row.id}`,
          // state: {modal: true}
        }}
      >
        {row.name}
      </Link>
    );
  };

  renderFulfillmentStatus = (cell, row) => {
    if (row.cancelled_at || row.line_item_cancelled) {
      return (
        <Badge bg="danger text-uppercase">Cancelled</Badge>
      );
    } else if (_.lowerCase(row.fulfillment_status) === "fulfilled") {
      return (
        <Badge bg="success text-uppercase">
          {row.fulfillment_status || "Unfulfilled"}
        </Badge>
      );
    } else if (row.fulfillment_status) {
      return (
        <Badge bg="warning text-uppercase">
          {row.fulfillment_status}
        </Badge>
      );
    } else {
      return (
        <Badge bg="warning text-uppercase">Unfulfilled</Badge>
      );
    }
  };

  renderDate = col => {
    return moment(col).format("DD/MM/YYYY")
  }

  dateSort = (a, b, order) => {
    let date1 = a.created_date;
    let date2 = b.created_date;

    if (!date1) {
      date1 = "01/01/2010";
    }

    if (!date2) {
      date2 = "01/01/2010";
    }

    if (order === "desc") {
      return (
        moment(date2, "DD/MM/YYYY").unix() - moment(date1, "DD/MM/YYYY").unix()
      );
    } else {
      return (
        moment(date1, "DD/MM/YYYY").unix() - moment(date2, "DD/MM/YYYY").unix()
      );
    }
  };

  render() {
    const { customer: { loading, loaded, item }} = this.props

    const ordersCol = [
      {
        dataField: 'id',
        text: 'ID',
        hidden: true,
        sort: true,
        headerStyle: {
          // resize: 'horizontal'
        }
      },
      {
        dataField: 'created_at',
        text: 'Date',
        sort: true,
        formatter: this.renderDate,
        sortFunc: this.dateSort,
        headerStyle: {
          width: '120px',
          // resize: 'horizontal'
        },
      },
      {
        dataField: 'id',
        text: 'Order Id',
        csvExport: false,
        sort: true,
        formatter: this.renderOrderDetailLink,
        headerStyle: {
          width: '120px',
          // resize: 'horizontal'
        }
      },
      {
        dataField: 'fulfillment_status',
        text: 'Fulfillment',
        sort: true,
        formatter: this.renderFulfillmentStatus,
        headerStyle: {
          width: '110px',
          // resize: 'horizontal'
        }
      },
    ];

    const options = {
      showTotal: true,
      sizePerPage: 10,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: item && item.orders.length
      }],
    };

    return (
      <>
        {
          loading &&
          <Loader />
        }
        {
          !loading && item &&
            <>
              <div className="row mt-3 mb-4">
                <div className="col-xs-12 col-md-9">
                  <h2 className="sub-header">Customer Detail</h2>
                </div>
              </div>
              <div className='d-grid gap-4 label-strong'>
                <Row>
                  <Col sm={6}>
                    <Card className="dashboard-card shadow-sm p-2">
                      <Card.Body>
                        <h4 className="mb-0" style={{color: '#80d7cf'}}>{item.first_name} {item.last_name}</h4>
                        <p className="d-print-none my-3">Customer for {moment.duration(moment().diff(moment(item.created_at))).humanize()}</p>
                        <Row style={{textAlign: 'center'}}>
                          <Col sm={6}>
                            <label>Last Order</label><br/>
                            <Link to={{pathname: `/orders/${item.last_order_id}`, state: {modal: false}}}>
                              {item.last_order_name}
                            </Link>
                          </Col>
                          <Col sm={6}>
                            <label>Total Spent</label><br/>
                            {currency} {item.total_spent}<br/>
                            {item.orders_count} order{item.orders_count > 1 ? 's' : ''}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
                  <Col>
                    <Card className="d-print-none tealCard mt-4 mt-sm-0 shadow-sm">
                      <Card.Header className="tealCardHeader">Customer Info</Card.Header>
                      <Card.Body className="grey-label">
                        <Row className="flex-row">
                          {
                            item.email &&
                              <Col lg={6} className="d-grid mb-2">
                                <label>Email</label>
                                <span>{item.email}</span>
                              </Col>
                          }
                          {
                            item.phone &&
                              <Col lg={6} className="d-grid mb-2">
                                <label>Phone</label>
                                <span>{item.phone}</span>
                              </Col>
                          }
                          {
                            !item.phone && item.default_address && item.default_address.phone &&
                              <Col lg={6} className="d-grid mb-2">
                                <label>Phone</label>
                                <span>{item.default_address.phone}</span>
                              </Col>
                          }
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="overflowX-auto">
                  <Col>
                    <Card className="tealCard shadow-sm">
                      <Card.Header className="tealCardHeader">Orders</Card.Header>
                      <Card.Body className="mx-2">
                        <ToolkitProvider
                          keyField='id'
                          data={item.orders}
                          columns={ordersCol}
                        >
                          {
                            props => (
                              <>
                                <BootstrapTable
                                  { ...props.baseProps }
                                  bordered={ false }
                                  pagination={paginationFactory(options)}
                                  hover
                                />
                              </>
                            )
                          }
                        </ToolkitProvider>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
        }
      </>
    );
  }
}

export default connect((state, props) => {
  return {
    customer: state.customer
  };
}, {
  ...customerActionCreators
})(Customer);

