import { useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import states from '../data/states.json'
import api from '../helpers/apiClient';

let country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'
country = 'MY'

const EditPickupAddressModal = ({ onClose, address, onSaved, vendor }) => {
  const {
    pickup_address_line_1,
    pickup_address_line_2,
    pickup_address_postcode,
    pickup_address_city,
    pickup_address_state,
    pickup_address_remarks,
  } = address;

  const [isLoading, setIsLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    let form = new FormData(e.target);
    let pickup_address = Object.fromEntries(form);

    await api(`/merchant/pickup_address`, {
      method: 'POST',
      body: JSON.stringify({ ...pickup_address, merchantId: vendor }),
      headers: { 'Content-Type': 'application/json' }
    })

    onSaved();
  }

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
        <h4 className="mb-0">Edit Pickup Address</h4>
      </Modal.Header>
      <form onSubmit={submit}>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{ borderTop: '1px solid #dee2e6' }}>
          <div>
            <label>Address Line 1</label>
            <input className="form-control" name="pickup_address_line_1" type="text" defaultValue={pickup_address_line_1} />
          </div>
          <div>
            <label>Address Line 2</label>
            <input className="form-control" name="pickup_address_line_2" type="text" defaultValue={pickup_address_line_2} />
          </div>
          <Row>
            <Col md={4}>
              <label>Postcode</label>
              <input className="form-control" name="pickup_address_postcode" type="text" defaultValue={pickup_address_postcode} />
            </Col>
            {country === 'MY' &&
              <>
                <Col md={4}>
                  <label>City</label>
                  <input className="form-control" name="pickup_address_city" type="text" defaultValue={pickup_address_city} />
                </Col>
                <Col md={4}>
                  <label>State/territory</label>
                  <Form.Select name="pickup_address_state" defaultValue={pickup_address_state}>
                    <option value="">Select One</option>
                    {states.MY.map(option => (
                      <option key={option.key} value={option.name}>{option.name}</option>
                    ))}
                  </Form.Select>
                </Col>
              </>
            }
          </Row>
          <div>
            <label>Remarks</label>
            <input className="form-control" name="pickup_address_remarks" type="text" defaultValue={pickup_address_remarks} />
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{ borderTop: 'none' }}>
          <Button variant="danger-secondary" onClick={onClose} disabled={isLoading}>Cancel</Button>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Save"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default EditPickupAddressModal;
