import html2canvas from "html2canvas";
import { jsPDF } from 'jspdf';
import Promise from 'bluebird';
import moment from "moment";

export async function exportAsImage(el, custom_width, custom_height) {
  const elements = Array.from(el.children)

  Promise.map(elements, async (el, i) => {
    return html2canvas(el, {
      scale: 3,
    })
      .then(canvas => {
        var extra_canvas = document.createElement("canvas");
        if (custom_width && custom_height) {
          extra_canvas.setAttribute('width', custom_width);
          extra_canvas.setAttribute('height', custom_height);
        }
        var ctx = extra_canvas.getContext('2d');
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0,0,extra_canvas.width,extra_canvas.height);

        let newWidth = canvas.width
        let newHeight = canvas.height
        let yCoordinate = 0
        if(custom_width && custom_height) {
          newWidth = custom_width
          newHeight = canvas.height/canvas.width * custom_width
          yCoordinate = (custom_height - newHeight)/2
        }
        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, yCoordinate, newWidth, newHeight);
        const image = extra_canvas.toDataURL("image/jpeg");

        downloadImage(image, `${el.id}.jpg`);
      });
  }, {
    concurrency: 1
  })
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

export async function exportAsPDF(el, layout) {
  const elements = Array.from(el.children)

  Promise.map(elements, async (el, i) => {
    return html2canvas(el, {
      scrollY: -window.scrollY,
      scale: 3,
    })
      .then(canvas => {
        const image = canvas.toDataURL('image/jpeg', 1.0);
        const doc = new jsPDF('l', 'px', layout === 'landscape' ? 'a5' : [210, 210]);
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;

        const marginX = (pageWidth - canvasWidth) / 2;
        const marginY = (pageHeight - canvasHeight) / 2;

        doc.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
        doc.save(`${el.id}.pdf`);
      })
  }, {
    concurrency: 1
  })


};

export async function exportAsPDFCN(el) {
  const doc = new jsPDF('p', 'px', 'a6');
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const cns = Array.from(el.children);

  for (let i = 1; i < cns.length; i++) {
    doc.addPage();
  }

  return Promise.map(cns, async (cn, i) => {
    return html2canvas(cn, {
      scrollY: -window.scrollY,
      scale: 3,
    }).then(canvas => {
      doc.setPage(i + 1);

      const image = canvas.toDataURL('image/jpeg', 1.0);

      doc.addImage(image, 'JPEG', 0, 0, pageWidth, pageHeight);
    })
  }, {
    concurrency: 1
  }).then(() => {
    doc.save(`DHLeCommerce(${moment().format('DDMMYYYY_HHmmss')}).pdf`);
  })
};

export async function exportAsPDFCNMulti(el) {
  const doc = new jsPDF('p', 'px', 'a4');
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const cns = Array.from(el.children);

  const pages = parseInt(cns.length/4);
  for (let i = 1; i <= pages; i++) {
    doc.addPage();
  }

  return Promise.map(cns, async (cn, i) => {
    return html2canvas(cn, {
      onclone: child => {
        const cn_container = child.getElementsByClassName('cn-container')[i]
        cn_container.style.padding = '20px'
      }
    }).then(canvas => {
      const page = parseInt(i / 4) + 1;
      doc.setPage(page);

      const image = canvas.toDataURL('image/jpeg', 1.0);
      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio / 2;
      const canvasHeight = canvas.height * ratio / 2;

      const posX = i % 2;
      const posY = parseInt((i / 2) % 2);

      const marginX = posX * (pageWidth / 2);
      const marginY = posY * (pageHeight / 2);

      doc.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
    })
  }, {
    concurrency: 1
  }).then(() => {
    doc.save(`DHLeCommerce(${moment().format('DDMMYYYY_HHmmss')}).pdf`);
  })
};

export async function canvasExportAsImage(imgEl, canvasEl, crop) {
  const ctx = canvasEl.getContext('2d');
  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = imgEl.naturalWidth / imgEl.width
  const scaleY = imgEl.naturalHeight / imgEl.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio
  // const pixelRatio = 1

  canvasEl.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvasEl.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  // const rotateRads = rotate * TO_RADIANS
  const centerX = imgEl.naturalWidth / 2
  const centerY = imgEl.naturalHeight / 2

  ctx.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 3) Rotate around the origin
  // ctx.rotate(rotateRads)
  // 2) Scale the image
  // ctx.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    imgEl,
    0,
    0,
    imgEl.naturalWidth,
    imgEl.naturalHeight,
    0,
    0,
    imgEl.naturalWidth,
    imgEl.naturalHeight,
  )

  const imageUrl = canvasEl.toDataURL("image/jpeg");

  ctx.restore()

  return imageUrl;
}
