import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Col, Row, Button, Alert } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import Input from '../components/Input';
import Select from '../components/Select';
import Loader from '../components/Loader';
import config from '../config'
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import uploadIcon from '../images/upload-icon.svg'

class AttachedRevisedInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
      invoiceName: undefined,
      submitting: false
    }
  }

  componentDidMount() {
    const { submitting } = this.props;
    this.setState({submitting})
  }

  submit = (values) => {
    const { file, invoiceName } = this.state
    const { onClickSave } = this.props;

    onClickSave(file, invoiceName)
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  onChangeInvoiceName = (value) => {
    this.setState({invoiceName: value.target.value})
  }

  onDropRevisedInvoice(files) {

    if(files && files.length > 0){
      this.setState({
        file: files[0]
      });
    }
  }

  render() {
    const { handleSubmit, error, match} = this.props
    const { file, invoiceName, submitting } = this.state
    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4>Overwrite Invoice</h4>
        </Modal.Header>
        <form onSubmit={handleSubmit(this.submit)}>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          {
            error &&
            <Alert variant="danger">
              {
                Array.isArray(error) ?
                <ul>
                {
                  error.map((err, i) => (
                    <li key={i}>
                      {err.msg}
                    </li>
                  ))
                }
                </ul>
                :
                error
              }
            </Alert>
            }

          <Row>
            <Col xs={6}>
              <Field className="form-control" name="invoiceName" label="Invoice Name" onChange={this.onChangeInvoiceName} type="text" component={Input} />

            </Col>
            { file &&
              <Col xs={6} className="d-flex">
                <label className="my-auto">{file.name}</label>
              </Col>
            }
          </Row>
          <Dropzone
            className='dropzone-area mt-4'
            onDrop={this.onDropRevisedInvoice.bind(this)}
            multiple={false}
            accept='application/pdf,application/zip'>
            <div className="dropzone-text">
              <img src={uploadIcon} style={{width: '30px', margin: '0 10px'}}></img>
              <span>Drag and drop files here, or click to upload</span>
            </div>
          </Dropzone><br/>

        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button
              variant="primary"
              type="submit"
              disabled={submitting || !file || !invoiceName }
              className="w-100 btn-br-6 mx-2"
            >
            {submitting ? 'SUBMITTING' : 'SUBMIT'}
            </Button>
        </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

AttachedRevisedInvoice = reduxForm({
  form: 'AttachedRevisedInvoice',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AttachedRevisedInvoice);

export default connect((state, props) => {
  const { match } = props;
  return {};
}, {
})(AttachedRevisedInvoice);
