import { useEffect, useState } from 'react';
import { Modal, Form, Button, Row, Col, Accordion, ToggleButton} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Dropzone from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import api from '../helpers/apiClient';
import uploadIcon from '../images/upload-icon.svg'
import { DateRangePicker, DayPickerSingleDateController } from 'react-dates';
import PreviewSelectDelivery from './PreviewSelectDelivery';
import * as taggingsActionCreators from '../redux/actions/taggings';
import Select from 'react-select';
import moment from 'moment';
import _ from 'lodash'

const country = 'MY'//window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

const ProductEditModal = ({ selectedProducts, onClose, onBulkEditMoreFields, selectOptions, isPendingProduct, isImportFlow }) => {
  const [available, setAvailable] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [not_halal, setNotHalal] = useState(false);
  const [delivery_type, setDeliveryType] = useState(false);
  const [block_em = false, setBlockEm] = useState(false);
  const [self = false, setSelf] = useState(false);
  const [have_halal_cert, setHaveHalalCert] = useState(false);
  const [halal_cert, setHalalCert] = useState(false);
  const [days_in_advance, setDaysInAdvance] = useState("0");
  const [cutoff_time, setCutoffTime] = useState("12pm");
  //set default to 7 days
  const [delivery_days, setDeliveryDays] = useState(['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']);
  const [exclude_days, setExcludeDays] = useState(false);
  const [bia, setbia] = useState(false);
  const [ubia, setUbia] = useState(false);
  const [closed_tags, setClosedTags] = useState(false);
  const [location, setLocation] = useState([]);
  const [datesRangeMode, setDatesRangeMode] = useState(false);
  const [datesRangeStart, setDatesRangeStart] = useState(null);
  const [datePickerKey, setDatePickerKey] = useState(0);
  const [uboDates, setUboDates] = useState([]);
  const [status, setStatus] = useState('');

  //count the number of new and update products
  const containsNewProducts = _.filter(selectedProducts, (product) => !product.is_update)
  const containsUpdateProducts = _.filter(selectedProducts, (product) => product.is_update)

  const closed = {
    mon: 'CLOSED-1',
    tue: 'CLOSED-2',
    wed: 'CLOSED-3',
    thu: 'CLOSED-4',
    fri: 'CLOSED-5',
    sat: 'CLOSED-6',
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    let formData = new FormData(e.target);

    if(!isImportFlow){
      formData.set('products', JSON.stringify(selectedProducts));
    }
  
    formData.set('bia', JSON.stringify(bia))
    formData.set('ubia', JSON.stringify(ubia))
    formData.set('delivery_days', delivery_days)

    if(uboDates.length > 0){
      let uboDatesStr = uboDates.map((item) => moment(item).format('DDMMYYYY')).join(',')
      formData.set('ubo_dates', uboDatesStr)
    }

    if(location && location.length > 0){
      //location is an array of objects, so we need to convert it to an array of strings
      let locationStr = location.map((item) => item.value).join(',')
      formData.set('location', locationStr)
    }

    //formData.entries() is an iterator, so we need to convert it to an array
    // let entries = Array.from(formData.entries());
    // console.log(entries);

    if(halal_cert){
      formData.set('halal_cert', halal_cert);
    }

    if (formData.get('inventory') || formData.get('status') || 
      formData.get('not_halal') || formData.get('delivery_type') || 
      formData.get('block_em') || (isImportFlow && !containsNewProducts.length > 0)){

      setSubmitting(true);
      
      if(isImportFlow){
        onClose(true, formData);
      } else {
        await api('/products/bulk/edit', {
          method: 'POST',
          body: formData
        }, {'Accept': 'application/json'}).catch(err => {
          console.log(err);
        })

        onClose(true);
      }
    }
  }

  const onDropHalalCert = async (files) => {

    setHalalCert(`Uploading ${files[0].name}...`)

    if (files && files.length == 1){

      var formData = new FormData();
      formData.append('product_image', files[0])
      formData.append('halal_cert', 'true')

      const data = await api(`/products/images`, {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'})

      setHalalCert(data.link)
    }
  }

  const handleDeliveryTypeChange = (value) => {

    setDeliveryType(value)

    //if value is courier and location does not include Nationwide (Courier Delivery), then add Nationwide (Courier Delivery) to location
    // {label : "Nationwide (Courier Delivery)", value : "Nationwide (Courier Delivery)" }
    //else if value is not courier and location includes Nationwide (Courier Delivery), then remove Nationwide (Courier Delivery) from location
    if(value === 'courier' && !_.find(location, {value: 'Nationwide (Courier Delivery)'})){
      setLocation([...location, {label : "Nationwide (Courier Delivery)", value : "Nationwide (Courier Delivery)" }])
    } else if(value !== 'courier' && _.find(location, {value: 'Nationwide (Courier Delivery)'})){
      setLocation(_.filter(location, (item) => item.value !== 'Nationwide (Courier Delivery)'))
    }

    if(value === 'd2d'){
      setbia({
        ...bia,
        ...(Object.entries(bia).length <= 1 && {
          days_in_advance: '0',
          exclude_days: 'W'
        }),
        on: value === 'd2d'
      })
    } else if(value === 'seasonal'){
      setUbia ({
        ...ubia,
        ...(Object.entries(ubia).length <= 1 && {
          days_in_advance: '0',
          exclude_days: 'W'
        }),
        on: value === 'seasonal'
      })
    }
  }

  const handleDaysInAdvanceChange = (e) => {

    if(delivery_type == 'd2d'){
      setbia({
        ...bia,
        days_in_advance: e.target.value
      })
    } else if(delivery_type == 'seasonal'){
      setUbia({
        ...ubia,
        days_in_advance: e.target.value
      })

      setDatePickerKey(datePickerKey + 1)
    }
    
    setDaysInAdvance(e.target.value)
  }

  const handleDeliveryDaysChange = (e) => {

    let exclude_days = 'W'
    let closedTags = []
    let checked = e.target.checked
    let value = e.target.value

    let updateDeliveryDays = [...delivery_days]

    const dayFound = _.findIndex(delivery_days, day => day === value)

    if(dayFound === -1 && checked){
      updateDeliveryDays.push({ value })
    }
    if(dayFound !== -1 && !checked){
      updateDeliveryDays.splice(dayFound, 1)
    }

    if(!_.includes(updateDeliveryDays, 'sun') && !_.includes(updateDeliveryDays, 'sat')){
      exclude_days = 'NW'
    } else if(!_.includes(updateDeliveryDays, 'sun')){
      exclude_days = 'WS'
    }

    _.each(closed, (day, key) => {
      if(!_.includes(updateDeliveryDays, key)){
        closedTags.push(closed[key])
      }
    })

    setDeliveryDays(updateDeliveryDays)
    setbia({
      ...bia,
      exclude_days
    })

    setClosedTags(closedTags)
  }

  const handleUboStartChange = (date) => {
    setDatesRangeStart(date)
  }

  const handleUboChange = (value) => {

    if(datesRangeStart){
      const diff = value.diff(datesRangeStart, 'days')
      let result = []

      for (let i = 0; i <= diff; i++){
        let subtractDay = value.clone().subtract(i, 'days');
        let wasPreviouslyPicked = uboDates.some((d) => d.isSame(subtractDay));

        if(!wasPreviouslyPicked){
          result.push(subtractDay)
        }
      }

      setDatesRangeStart(null)
      setUboDates([...uboDates, ...result])
      return
    }

    let wasPreviouslyPicked = uboDates.some((d) => d.isSame(value));
    if (wasPreviouslyPicked) {
      setUboDates(uboDates.filter(d => !d.isSame(value)))
    } else {
      setUboDates([...uboDates, value])
    }
  }

  const isOutsideRange = day => {

    let startDate = moment()

    if(delivery_type === 'd2d' && bia && bia.days_in_advance){
      startDate.add(bia.days_in_advance, 'days')
    } else if (delivery_type === 'seasonal' && ubia && ubia.days_in_advance){
      startDate.add(ubia.days_in_advance, 'days')
    }

    return day.isBefore(startDate, 'day') || day.isAfter(moment().add(60, "days"));
  }

  return (
    <Modal style={{ border: 'none' }} show={true} onHide={() => {onClose(false)}} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
        <h4>{`${isImportFlow? 'Import' : 'Edit' } ${selectedProducts.length} Product(s)`}</h4>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{ borderTop: '1px solid #dee2e6' }}>
          <Row>
            <Col sm={6} className="d-grid gap-2">
              { !isImportFlow && 
                <Row>
                  <Col xs={6}><label htmlFor="inventory">Inventory</label></Col>
                  <Col xs={6}>
                    <Form.Select className="form-control" name="inventory" onChange={(e) => setAvailable(e.target.value === 'available')}>
                      <option value="">- No Change -</option>
                      <option value="sold_out">Sold Out</option>
                      <option value="available">Available</option>
                    </Form.Select>
                  </Col>
                </Row>
              }
              { available &&
              <>
                <Row>
                  <Col xs={6}><label htmlFor="inventory_management">Quantity Tracked <i data-html={true} data-tip={`
                        <p style="text-align: left">
                          Variant Available in Limited Quantity:<br/>
                          1. Check "Quantity Tracked"<br/>
                          2. Set "Quantity Available" to available amount<br/><br/>
                          Variant Available (No Limited Quantity):<br/>
                          1. Uncheck "Quantity Tracked"
                        </p>`} data-for="sold-out-header"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="top" id="sold-out-header" /></label></Col>
                  <Col xs={6}>
                    <label className='custom-checkbox' style={{ marginTop: '2px' }}>
                      <input name="inventory_management" type="checkbox" style={{ marginRight: "5px" }} />
                      <span className="checkmark checkmark-smaller"></span>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}><label htmlFor="inventory_quantity">Quantity Available</label></Col>
                  <Col xs={6}><input className="form-control" name="inventory_quantity" type="number" min="0" /></Col>
                </Row>
              </>
              }
            </Col>
          </Row>
          { 
            (!isPendingProduct && !isImportFlow) &&
            <Row>
              <Col sm={6} className="d-grid gap-2">
                <Row>
                  <Col xs={6}><label htmlFor="status">Status</label></Col>
                  <Col xs={6}>
                    <Form.Select className="form-control" name="status" value={status} onChange={e => setStatus(e.target.value)}>
                      <option value="">- No Change -</option>
                      <option value="active">Active</option>
                      <option value="draft">Draft</option>
                      <option value="archived">Archived</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
              {status === 'archived' &&
                <span className="mt-2 small">These products will be hidden from your product list. You can still find them on the Archived Products page.</span>
              }
            </Row>
          }
          {
            isImportFlow && containsNewProducts.length > 0 &&
            <>
              <h5>{containsNewProducts.length} new product to be uploaded</h5>
              <ul>
                {containsNewProducts.map((product, i) => {
                  return <li key={i}>{product.title}</li>
                })}
              </ul>
            </>
          }
          { 
            ((isImportFlow && containsNewProducts.length > 0) || !isImportFlow) &&
            <Row>
              <Col sm={6} className="d-grid gap-2">
                <Row>
                  <Col xs={6}><label htmlFor="delivery_type">{isImportFlow ? "*" : ""}Delivery Type</label></Col>
                  <Col xs={6}>
                    <Form.Select className="form-control" name="delivery_type" value={delivery_type} onChange={(e) => handleDeliveryTypeChange(e.target.value)}>
                      <option value="">- No Change -</option>
                      <option value="d2d">On-Demand Delivery</option>
                      <option value="seasonal">On-Demand Delivery (Limited Period/Seasonal)</option>
                      <option value="courier">Courier Delivery</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
          {
            delivery_type === 'courier' && //country === 'MY' &&
            <Row>
              <Col sm={6} className="d-grid gap-2">
                <Row>
                  <Col xs={6}><label className="checkbox-inline">West Malaysia Only</label></Col>
                  <Col xs={6}>
                    <label className='custom-checkbox' style={{ marginTop: '2px', marginLeft: '0.1em' }}>
                      <input type="checkbox" name="block_em" onChange={(e) => setBlockEm(e.target.checked)} checked={block_em} />
                      <span className="checkmark checkmark-smaller"></span>
                    </label>
                  </Col>
                </Row>
              </Col>
            </Row>  
          }
          { delivery_type === 'd2d' &&
                <Row className="d-grid gap-3 my-2">
                  <Col className="d-grid gap-1">
                    <label>Booking in Advance</label>
                    <Form.Select className="form-control" name="days_in_advance" value={days_in_advance} onChange={(e) => handleDaysInAdvanceChange(e)}>
                      <option value="0">Same Day Delivery</option>
                      <option value="1">1 Day (Next Day Delivery)</option>
                      <option value="2">2 Days (Min. 2 Days Booking)</option>
                      <option value="3">3 Days (Min. 3 Days Booking)</option>
                    </Form.Select>
                  </Col>
                  <Accordion activeKey={days_in_advance || ""}>
                    <Accordion.Collapse eventKey="0">
                      <Col className="d-grid gap-1">
                        <label>Cut-off Time</label>
                        <Form.Select className="form-control" name="cutoff_time" value={cutoff_time} onChange={(e) => setCutoffTime(e.target.value)}>
                          <option value="12pm">12 PM</option>
                          <option value="1pm">1 PM</option>
                          <option value="2pm">2 PM</option>
                          <option value="3pm">3 PM</option>
                          <option value="4pm">4 PM</option>
                          <option value="5pm">5 PM</option>
                          { country === 'MY' &&
                            <option value="6pm">6 PM</option>
                          }
                        </Form.Select>
                      </Col>
                    </Accordion.Collapse>
                  </Accordion>
                  <Row>
                    <Col>
                      <div className="d-grid gap-1">
                        <label>Available Delivery Day(s)</label>
                        {
                          _.map(selectOptions.dayOptions, (day, i) => {
                            const checked = _.some(delivery_days, del => del === day.value)

                            return(
                              <div key={i} className="d-grid gap-2" style={{gridTemplateColumns: '20px 1fr'}}>
                                <label className='custom-checkbox'>
                                  <input type="checkbox" name="delivery_days" onChange={(e) => handleDeliveryDaysChange(e)} checked={checked || false} value={day.value}/>
                                  <span className="checkmark checkmark-smaller"></span>
                                </label>
                                <label style={{fontWeight: '200'}}>{day.label}</label>
                              </div>
                            )
                          })
                        }
                      </div>
                    </Col>
                    <Col>
                      <PreviewSelectDelivery
                        bia={bia}
                        deliveryDays={delivery_days}
                        // blockedDates={boDates}
                      />
                    </Col>
                  </Row>
                </Row>
          }
          { delivery_type === 'seasonal' && 
                <Row className="d-grid gap-3 my-2">
                  <Col className="d-grid gap-1">
                    <label>Booking in Advance</label>
                    <Form.Select className="form-control" name="days_in_advance_seasonal" value={days_in_advance} onChange={(e) => handleDaysInAdvanceChange(e)}>
                      <option value="0">Same Day Delivery</option>
                      <option value="1">1 Day (Next Day Delivery)</option>
                      <option value="2">2 Days (Min. 2 Days Booking)</option>
                      <option value="3">3 Days (Min. 3 Days Booking)</option>
                    </Form.Select>
                  </Col>
                  <Accordion activeKey={days_in_advance || ""}>
                    <Accordion.Collapse eventKey="0">
                      <Col className="d-grid gap-1">
                        <label>Cut-off Time</label>
                        <Form.Select className="form-control" name="cutoff_time" value={cutoff_time} onChange={(e) => setCutoffTime(e.target.value)}>
                          <option value="12pm">12 PM</option>
                          <option value="1pm">1 PM</option>
                          <option value="2pm">2 PM</option>
                          <option value="3pm">3 PM</option>
                          {country === 'MY' &&
                            <option value="6pm">6 PM</option>
                          }
                        </Form.Select>
                      </Col>
                    </Accordion.Collapse>
                  </Accordion>
                  <Col className="d-grid gap-1">
                    <div>
                      <label>Available Delivery Day(s)</label>
                      <div>
                      <span>Selection Mode: </span>
                      <ToggleButton
                        size='sm'
                        className="mx-1 toggle-text"
                        id="toggle-check"
                        type="checkbox"
                        variant="secondary"
                        checked={datesRangeMode}
                        value="1"
                        onChange={(e) => setDatesRangeMode(e.target.checked)}
                      >
                        {
                          datesRangeMode ?
                            "Range"
                            :
                            "Single"
                        }
                      </ToggleButton>
                      </div>
                    </div>
                    <Row>
                      <Col className="calendar-fixed-height">
                        <DayPickerSingleDateController
                          key={datePickerKey}
                          isOutsideRange={isOutsideRange}
                          hideKeyboardShortcutsPanel
                          onDateChange={date => {
                            if(datesRangeMode && !datesRangeStart){
                              handleUboStartChange(date.startOf('day'))
                              return
                            }
                            handleUboChange(date.startOf('day'))
                          }}
                          focused={true}
                          onFocusChange={console.log}
                          date={datesRangeStart || null}
                          isDayHighlighted={day => uboDates.some(d => d.isSame(day, 'day'))}
                          keepOpenOnDateSelect
                        />
                      </Col>
                      <Col>
                        <PreviewSelectDelivery
                          ubia={ubia}
                          allowedDates={uboDates}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
          }
          {
            (delivery_type == 'd2d' || delivery_type == 'seasonal') &&
            <Row>
              <Col sm={6} className="d-grid gap-2">
                <Row>
                  <Col xs={7}>
                    <div>
                    <label>Self Pick-Up Available</label>
                      <i data-html={true} data-tip={`
                        <p style="text-align: left; max-width: 500px; margin-bottom: 0">
                        Self Pick-up Available checkbox is not editable in bulk due to changes required on amending the variant for pick-up option. Please edit on product page accordingly.
                        </p>`} data-for="self-pickup"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf', marginLeft: '5px' }} /></i><ReactTooltip place="top" id="self-pickup" />
                    </div>
                  </Col>
                  <Col xs={5}>
                    <label className='custom-checkbox' style={{marginTop: '2px', marginLeft: '0.1em'}}>
                      <input type="checkbox" disabled readOnly/>
                      <span className="checkmark checkmark-smaller checkmark-disabled"></span>
                    </label>
                </Col>
                </Row>
              </Col>
            </Row>                      
          }
          {
            delivery_type && country === 'MY' &&
            <Row>
              <Col className="d-grid gap-2">
                <Row>
                    <Col xs={6} sm={3}><label>Location</label></Col>
                    <Col xs={6}>
                      <Select
                        value={location}
                        isMulti
                        onChange={(selected) => setLocation(selected)}
                        required={true}
                        options={selectOptions.locationOptions}
                      />
                    </Col>
                </Row>
              </Col>
            </Row>
          }
          { country === 'MY' && ((isImportFlow && containsNewProducts.length > 0) || !isImportFlow) &&
            <Row>
              <Col sm={6} className="d-grid gap-2">
                <Row>
                  <Col xs={6}><label htmlFor="not_halal">Does this product contain alcohol/pork?</label></Col>
                  <Col xs={6}>
                    <Form.Select className="form-control" name="not_halal" onChange={(e) => setNotHalal(e.target.value)}>
                      <option value="">- No Change -</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
          {
            not_halal == 'no' &&
            <Row>
              <Col sm={6} className="d-grid gap-2">
                <Row>
                  <Col xs={6}><label htmlFor="have_halal_cert">Do you have Halal certificate?</label></Col>
                  <Col xs={6}>
                    <Form.Select className="form-control" name="have_halal_cert" onChange={(e) => setHaveHalalCert(e.target.value)}>
                      <option value="">- No Change -</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
          {
            have_halal_cert == 'yes' &&
              <Col xs={12} className="d-grid gap-1 mt-0 mt-lg-3">
                <label>Attach Halal Certificate</label>
                <Dropzone
                  className='dropzone-area'
                  style={{display: 'inline-block'}}
                  multiple={false}
                  onDrop={onDropHalalCert}
                  accept='image/*'
                >
                  <div className="dropzone-text">
                    <img src={uploadIcon} style={{width: '30px', margin: '0 10px'}}></img>
                    <span>Drag and drop files here, or click to upload</span>
                  </div>
                </Dropzone>
                &nbsp;
                {
                  halal_cert && <span>{halal_cert}</span>
                }
              </Col>
          }
          {
            containsUpdateProducts.length > 0 && containsNewProducts.length > 0 &&
            <hr/>
          }
          {
            isImportFlow && containsUpdateProducts.length > 0 &&
            <>
              <h5>{containsUpdateProducts.length} existing product(s) to be updated</h5>
              <ul>
                {containsUpdateProducts.map((product, i) => {
                  return <li key={i}>{product.id} - {product.title}</li>
                })}
              </ul>
              <Row>
                <Col className="d-grid gap-2">
                  ⚠️ Existing products with matching product ID will be overwritten
                </Col>
              </Row>
            </>
          }
        </Modal.Body>
        <Modal.Footer className="mx-4 px-0 pt-0" style={{borderTop: 'none'}}>
          {
            submitting &&
            <span>Loading...this make take up to a few minutes</span>
          }
          { !isImportFlow && 
            <Button className="justify-start" variant="secondary" type="button" onClick={onBulkEditMoreFields} disabled={submitting}>
              Bulk Edit More Product Fields
            </Button>
          }
          <Button variant="primary" type="submit" disabled={submitting || (isImportFlow && !delivery_type && containsNewProducts.length > 0)}>
            { submitting ? 
              <>
                <span>Loading </span>
                <FontAwesomeIcon icon={solid('spinner')} spin />
              </>
              : (isImportFlow ? 'Continue' : 'Submit') 
            }
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ProductEditModal;
