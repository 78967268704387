import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';

class NewAnnouncements extends Component {
  constructor(props) {
    super(props);
  }

  handleOnCloseClick = (event) => {
    const { onCloseClicked } = this.props;

    onCloseClicked(event.target.id)
  }


  render() {
    const { announcements, onCloseClicked } = this.props;
    return (
      <div>
        {
          announcements.map((announcement, i) => {
            return (
              <div className='d-print-none' key={i}>
                <Row className="announcement text-center align-items-center m-0" key={announcement._id}>
                  <Col xs={10} md={11} dangerouslySetInnerHTML={{__html: announcement.desc}} className="announcement-text my-2"></Col>
                  <Col xs={2} md={1} id={announcement._id} className="close" style={{cursor:'pointer'}} onClick={this.handleOnCloseClick}>X</Col>
                </Row>
                <hr className="announcement-hr"/>
              </div>
            )
          })
        }
      </div>
    );
  }
}

export default NewAnnouncements;
