import { 
  FETCH_DASHBOARD_REQUEST, 
  FETCH_DASHBOARD_SUCCESS, 
  FETCH_DASHBOARD_FAILURE
} from '../actions/dashboard';

const defaultState = {
  loading: false,
  loaded: false,
  item: null,
  next: null
};

export default function dashboard(state = defaultState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.payload.item,
      };
    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}