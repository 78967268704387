import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class FinancialStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      financial_status: '',
      partial_paid_amount: 0,
      payment_due_date: null
    }
  }

  componentDidMount() {
    const { item: { financial_status, partial_paid_amount, payment_due_date } } = this.props;
    this.setState({
      financial_status,
      partial_paid_amount,
      payment_due_date: moment(payment_due_date),
    })
  }

  handleOnClickSave = () => {
    const { financial_status, partial_paid_amount, payment_due_date } = this.state;
    const { onClickSave } = this.props;
    onClickSave(financial_status, partial_paid_amount, payment_due_date);
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleOnInputChange = (e) => {
    let { name, value, type } = e.target;

    if(type === 'number'){
      value = parseFloat(value)
    }

    this.setState({
      [name]: value
    });
  }

  handleSelectDueDate = date => {
    this.setState({
      payment_due_date: date
    })
  }

  render() {
    const { financial_status, partial_paid_amount, payment_due_date } = this.state;

    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0">Financial Status</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          <form className="my-2 gap-2">
            <div>
              <Form.Select className='form-control' name='financial_status' value={financial_status} onChange={this.handleOnInputChange}>
                <option value="paid">Paid</option>
                <option value="partially_paid">Partially Paid</option>
                <option value="unpaid">Unpaid</option>
              </Form.Select>
            </div>
            {
              financial_status === 'partially_paid' &&
                <>
                  <div>
                    <label htmlFor='partial_paid_amount'>Partial Paid Amount*</label>
                    <input className="form-control" name="partial_paid_amount" placeholder={`Partial Paid Amount (${currency})`} type="number" step=".01" value={partial_paid_amount} onChange={this.handleOnInputChange} required/>
                  </div>
                  <div>
                    <label htmlFor='payment_due_date'>Due Date*</label>
                    <SingleDatePicker
                      date={payment_due_date}
                      onDateChange={this.handleSelectDueDate}
                      displayFormat="DD-MM-YYYY"
                      numberOfMonths={1}
                      showClearDate
                      small
                      required
                      block
                      focused={this.state.paymentDueDateFocused}
                      onFocusChange={({ focused: paymentDueDateFocused }) => this.setState({ paymentDueDateFocused })}
                    />
                  </div>
                </>
            }
          </form>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={this.handleOnClose}>Cancel</Button>
          <Button variant="primary" onClick={this.handleOnClickSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FinancialStatusModal;

