import { 
  FETCH_PROFILE_REQUEST, 
  FETCH_PROFILE_SUCCESS, 
  FETCH_PROFILE_FAILURE, 
  CLEAR_USER,
} from '../actions/profile';

const defaultState = {
  loading: false,
  loaded: false,
  item: {},
};

export default function profile(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_USER:
      return {
        ...state,
        defaultState
      };
    case FETCH_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.payload.item,
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_PROFILE_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}