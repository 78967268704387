import React, { Component } from "react";
import { Panel, Button, ListGroup } from "react-bootstrap";
import FieldsModal from "./FieldsModal";
import _ from "lodash";
import { generateTagsFromAttr } from "../helpers/productTags";
import Dropzone from "react-dropzone";
import api from "../helpers/apiClient";
import { combineReducers } from "redux";

const messageValues = [
    { key: "Write my own message", textMessage: ""},
    { key: "Leave it blank", textMessage: " "},
    { key: "Chinese New Year Wishes #1", textMessage: "Dear  ,\n\nMay my wishes of health, happiness, and prosperity be conveyed to you through this gift! Happy Chinese New Year!\n\nFrom:"},
    { key: "Chinese New Year Wishes #2", textMessage: "Dear  ,\n\nOut with the old, in with the new, may the new year bring your biggest wishes to fruition! Happy CNY! \n\nFrom:"},
    { key: "Chinese New Year Wishes #3", textMessage: "Dear  ,\n\nHappy Chinese New Year of the Tiger 2022! May you have peace at every age. Enjoy this festive to the fullest and make this celebration best of them all.\n\nFrom:"},
    { key: "Chinese New Year Wishes #4", textMessage: "Dear  ,\n\n虎年到來，祝您：逢虎年，辦虎事，虎年祝你財源滾滾，全家樂悠悠！\n\nFrom:"},
    { key: "Hari Raya Malay #1", textMessage: "Kepada  ,\n\nSelamat Hari Raya Aidilfitri! Dengan ikhlas hati menyusun sepuluh jari tanda memohon maaf zahir dan batin.\n\nDaripada:"},
    { key: "Hari Raya Malay #2", textMessage: "Kepada  ,\n\nSelamat Hari Raya! Ampun dan maaf dipinta, moga Syawal kali ini memberi seribu makna.\n\nDaripada:"},
    { key: "Hari Raya Malay #3", textMessage: "Kepada  ,\n\nMohon seribu kemaafan dari kalian, andai ada tersalah bahasa dan tersilap perlakuan sepanjang kita bersama. Selamat Hari Raya!\n\nDaripada:"},
    { key: "Hari Raya English #1", textMessage: "Dear  ,\n\nSending you my warm wishes full of love, peace and prosperity on Hari Raya Aidilfitri. Salam Aidilfitri!\n\nFrom:"},
    { key: "Hari Raya English #2", textMessage: "Dear  ,\n\nHope you enjoy the festivities and have a great time! Selamat Hari Raya!\n\nFrom:"},
    { key: "Hari Raya English #3", textMessage: "Dear  ,\n\nSelamat Hari Raya Aidilfitri! Wishing you and your family a prosperous and blessing celebration.\n\nFrom:"},
    { key: "Birthday #1", textMessage: "Dear  ,\n\nHappiest of birthdays! May you have a blessed year ahead and endless happiness.\n\nFrom:"},
    { key: "Birthday #2", textMessage: "Dear  ,\n\nLet this day be full of joy and celebration. I wish you an outstanding and fabulous birthday!\n\nFrom:"},
    { key: "Birthday #3", textMessage: "Dear  ,\n\nHappy birthday! On your birthday I’m going to share the secret to staying young: lie about your age 😉\n\nFrom:"},
    { key: "Love Wishes #1", textMessage: "Dear  ,\n\nYou are the reason for my smile and my happiness. I love you!❤️\n\nFrom:"},
    { key: "Love Wishes #2", textMessage: "Dear  ,\n\nMy dear, I loved you once, love you still, always have and always will.❤️\n\nFrom:"},
    { key: "Love Wishes #3", textMessage: "Dear  ,\n\nSince no human can live without air, I can’t live without you. Wish you the best always, love you!❤️❤️❤️\n\nFrom:"},
    { key: "Anniversary #1", textMessage: "Dear  ,\n\nHappy Anniversary, with all my love, to the strongest, kindest, funniest, best person I know ❤️ \n\nFrom:"},
    { key: "Anniversary #2", textMessage: "Dear  ,\n\nI still remember the way my heart leaped when it was our wedding day. Of course I said yes I do, and I’d say it all over again. Yes to you. Yes to this life we’ve made. A thousand times YES to our love!❤️\n\nFrom:"},
    { key: "Anniversary #3", textMessage: "Dear  ,\n\nHope you find time to look back on all your sweet memories together. Warmest wishes to the wonderful two of you on your anniversary.\n\nFrom:"},
    { key: "Anniversary #4", textMessage: "Dear  ,\n\nThe two of you have your own reasons to celebrate on your anniversary, and as someone who loves you dearly, I have so many reasons to celebrate you both. Wishing the best for you always.\n\nFrom:"},
    { key: "Best Wishes #1", textMessage: "Dear  ,\n\nMoving on to a new stage in life can be a challenging process. Wish you the best of luck in all of your future endeavors, you will be great!\n\nFrom:"},
    { key: "Best Wishes #2", textMessage: "Dear  ,\n\nThe future is bright and beautiful. Love it, strive for it and work for it. Best of luck!\n\nFrom:"},
    { key: "Best Wishes #3", textMessage: "Dear  ,\n\nI am thrilled to know that luck has finally found you. Dream Big. Sparkle More. Shine Bright. Best wishes for you always.\n\nFrom:"},
    { key: "Appreciation #1", textMessage: "Dear  ,\n\nSometimes the simplest things mean the most. Your thoughtfulness is a gift I will always treasure. Thank you!\n\nFrom:"},
    { key: "Appreciation #2", textMessage: "Dear  ,\n\nI can never thank you enough. But this is a start. For all the little and big ways you’ve pitched in…thanks! You have no idea how much your help has meant, I appreciate you so much.\n\nFrom:"},
    { key: "Appreciation #3", textMessage: "Dear  ,\n\nYour generosity never ceases to amaze me. I will always appreciate all your kindness and support that you have always shown me.\n\nFrom:"},
    { key: "Congratulatory #1", textMessage: "Dear  ,\n\nSo pleased to see you accomplishing great milestones. This calls for celebrating! Congratulations!\n\nFrom:"},
    { key: "Congratulatory #2", textMessage: "Dear  ,\n\nIt makes me so happy to think about your success. It couldn’t have come to a more deserving person.\n\nFrom:"},
    { key: "Congratulatory #3", textMessage: "Dear  ,\n\nCongratulations! I’ve got a feeling this is only the beginning of even more great things to come for you!\n\nFrom:"},
    { key: "Get Well Soon #1", textMessage: "Dear  ,\n\nRemember that you’re not alone. We’ll be here by your side as you get through this. Get well soon!\n\nFrom:"},
    { key: "Get Well Soon #2", textMessage: "Dear  ,\n\nDon’t worry, we’ll fight this together. I know we can win! You’ll be feeling better before you know it.\n\nFrom:"},
    { key: "Get Well Soon #3", textMessage: "Dear  ,\n\nSending you the biggest hugs and wishing you love and good health!\n\nFrom:"},
    { key: "Newborn #1", textMessage: "Dear  ,\n\nCongratulations on the arrival of your baby! At last, all your wishes came true. May the little guy be blessed with good health, happiness, and prosperity throughout his life.\n\nFrom:"},
    { key: "Newborn #2", textMessage: "Dear  ,\n\nToday will remain a special day for the rest of your life, as today you became parents for the cutest little baby. Congratulations and may God bless your family.\n\nFrom:"},
    { key: "Newborn #3", textMessage: "Dear  ,\n\nBuckle up for those sleepless nights, diaper changes and the endless joy of parenting. Welcome to the club and congratulations on the arrival of your baby.\n\nFrom:"},
    { key: "Apology #1", textMessage: "Dear  ,\n\nI may not be as wise as you but I am wise enough to see the damage I have caused to our friendship. I know you will forgive me in the end but I am really sorry!\n\nFrom:"},
    { key: "Apology #2", textMessage: "Dear  ,\n\nEver since I have hurt you I have been feeling really miserable, let’s chill and be cool again! I’m sorry please forgive me!\n\nFrom:"},
    { key: "Apology #3", textMessage: "Dear  ,\n\nI have done many mistakes in life but none of them came back to haunt me with such strength. I am deeply sorry for the pain I have caused you. Please forgive me.\n\nFrom:"},
    { key: "Cheer Up #1", textMessage: "Dear  ,\n\nThings will get better. It may be today or tomorrow - it is just a matter of time. Chin up and never give up!\n\nFrom:"},
    { key: "Cheer Up #2", textMessage: "Dear  ,\n\nSmile always, even if the sadness knocks on your window, it will go blind from your gorgeous smile.\n\nFrom:"},
    { key: "Cheer Up #3", textMessage: "Dear  ,\n\nEnjoy everything that makes life worth living for. Take care of yourself; life is great when you smile more often. Sending you all my warm wishes.\n\nFrom:"},
    { key: "Graduation #1", textMessage: "Dear  ,\n\nGood looks, brains, loving heart and now graduated, too? You’ve really got it all! Congrats!\n\nFrom:"},
    { key: "Graduation #2", textMessage: "Dear  ,\n\nSo happy to share in the excitement of your graduation day, and so very proud of you, too!\n\nFrom:"},
    { key: "Graduation #3", textMessage: "Dear  ,\n\nHope you’re able to take a well-earned rest, vacation—whatever kind of break sounds like fun. You’ve earned it!\n\nFrom:"},
    { key: "Housewarming #1", textMessage: "Dear  ,\n\nYour first steps into your new home are your first steps into your new life. Here’s to the beginning of something beautiful. Wishing a great and amazing housewarming!\n\nFrom:"},
    { key: "Housewarming #2", textMessage: "Dear  ,\n\nHome is a word that symbolizes love, care, trust, progress, memories, and relationships. May you be blessed with all of these and more in your new home, congratulations!\n\nFrom:"},
    { key: "Housewarming #3", textMessage: "Dear  ,\n\nMoving into a new house is synonymous to starting a new life. Embrace the new scent of success & leave all your worries behind. May blessings and happiness shower in your new house! Congratulations!\n\nFrom:"},
    { key: "Condolences #1", textMessage: "Dear  ,\n\nMy heart aches to hear this news. I’m praying for everyone. Please know that I love you and will always be here for you.\n\nFrom:"},
    { key: "Condolences #2", textMessage: "Dear  ,\n\nMy thoughts and prayers are with you in your time of grief. I'm so sorry for your loss.\n\nFrom:"},
    { key: "Condolences #3", textMessage: "Dear  ,\n\nMy sincere condolences, just know that I am here for you, please don’t hesitate to reach out, especially during this difficult time.\n\nFrom:"},
    { key: "Grand Opening #1", textMessage: "Dear  ,\n\nCongratulations on your grand opening! May your business prosper in the years to come!\n\nFrom:"},
    { key: "Grand Opening #2", textMessage: "Dear  ,\n\nHeartiest congratulations on starting your new business. With your determination and perseverance, there is nothing that you cannot overcome. All the best!\n\nFrom:"},
    { key: "Grand Opening #3", textMessage: "Dear  ,\n\nAs you start your new business, I wish that failure is behind you, success is in front of you, good luck is by your side.  Congratulations!\n\nFrom:"},

];

class ProductPreviewMessage extends Component{
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        const { previewProduct } = this.props;

        this.setState({
            placeholder: "Dear RECEIVER_NAME,\n\nType your message/wishes here or select one of the templates.\n\nFrom: YOUR_NAME"
        })

    }

    handleOnMessageChange = (e) => {
        const { value } = e.target;

        console.log('value', value)

        this.setState({
            textValue: value
        });
    }

    render() {
        const { textValue, placeholder } = this.state;
        return(
            <div className="col-sm-8 col-xs-12">
                <label className="product-extra-fields-label">Message Template (Pick an occasion)</label>
                <select className="preview-select" onChange={this.handleOnMessageChange}>
                {
                    _.map(messageValues, (key) => {
                        return(
                            <option key={key.key} value={key.textMessage}>{key.key}</option>
                        )
                    })
                }
                </select>
                <div>
                    <label className="product-extra-fields-label">Message on card</label>
                    <textarea id="message"className="message-on-card" maxLength="250"
                    value={textValue} placeholder={placeholder} onChange={this.handleOnMessageChange}></textarea>
                    <p className="message-char-count">250 Characters Max</p>
                </div>
            </div>
        )
    }
}

export default ProductPreviewMessage;
