import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button, Alert, Badge, Accordion, Row, Col, Form, Container } from 'react-bootstrap';
import Loader from '../components/Loader';
import OverlayLoader from '../components/OverlayLoader';
import VendorSelection from '../components/VendorSelection'
import * as productAddOnsActionCreators from '../redux/actions/productAddOns';
import api from '../helpers/apiClient';
import ConfirmModal from '../components/ConfirmModal';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import promptIcon from '../images/adminPrompt.svg';
import TabHeader from '../components/TabHeader';
import ProductEditModal from '../components/ProductEditModal';
import * as taggingsActionCreators from '../redux/actions/taggings';
import EnableAddOnsModal from '../components/EnableAddOnsModal';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import SortAddOnsRow from '../components/SortAddOnsRow';
import update from 'immutability-helper'
import toast from 'react-hot-toast';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

class Products extends Component {
  constructor(props) {
    super(props);
    const { location, user } = props;

    let vendorList = [];
    let vendor = 'any';
    let status = 'any';
    let inventory = 'any';

    if(user.merchantId){
      vendorList = user.merchantId.split('#,');
    }

    if (location.search) {
      const queryString = qs.parse(location.search.substr(1));

      if (queryString.vendor) {
        vendor = queryString.vendor;
      } else if (vendorList.length > 1 && !queryString.vendor){
        vendor = vendorList[0]
      }

      if (queryString.status) {
        status = queryString.status;
      }

      if (queryString.inventory) {
        inventory = queryString.inventory;
      }
    }

    this.state = {
      vendor,
      vendorList,
      status,
      inventory,
      selectedIds: [],
      showConfirmDuplicateProductModal: false,
      displayDuplicateProductSuccess: false,
      displayDuplicateProductFail: false,
      errorMessage: '',
      duplicatedProductId: '',
      showProductEditModal: false,
      selectedPending: false,
      sorting: false,
      overlayLoading: false,
    };
  }

  async componentDidMount() {
    const { fetchProductAddOns, location, user, fetchTaggings } = this.props;
    const { vendor } = qs.parse(location.search.substr(1));

    if(!vendor && ['admin', 'staff'].includes(user.role)){
      this.setState({
        adminPrompt: true
      })
    } else {
      fetchProductAddOns(vendor);
    }

    await fetchTaggings()
    await this.setSelectOptions()
  }

  componentWillReceiveProps(nextProps) {
    const { location: prevLocation, user } = this.props;
    const { fetchProductAddOns, location } = nextProps;

    if (location.search !== prevLocation.search) {
      const { vendor } = qs.parse(location.search.substr(1));

      let adminPrompt = false
      if(!vendor && ['admin', 'staff'].includes(user.role)){
        adminPrompt = true
      }

      this.setState({
        adminPrompt,
        vendor: vendor || 'any',
        selectedIds: [],
      });

      if(!adminPrompt){
        fetchProductAddOns(vendor);
      }
    }
  }

  setSelectOptions = async () => {

    const { taggings: { items: predefinedTags } } = this.props;

    function createOptions(taggings) {
      return _.map(taggings, tag => {
        return {
          label: tag.desc,
          value: tag.tag
        }
      })
    }

    this.setState({
      selectOptions: {
        dayOptions,
        locationOptions: createOptions(predefinedTags['Location'])
      }
    })
  }

  renderExpandedNoteColumn = (row) => {
    return <div>{row.note}</div>
  }

  renderDetailLink = (cell, row) => {

    return (
      <Link
        key={row.id}
        to={{
          pathname: `/product_addons/${_.isNumber(row.id) ? row.id : `pending/${row.id}`}`
        }}
      >
        {cell}
      </Link>
    )
  }

  getInventoryStatus = variants => {
    let tracked = [], available = false, in_stock = false, out_of_stock = false

    for (let i = 0; i < variants.length; i++){
      if(variants[i].inventory_management == null){
        available = true
        continue
      }

      tracked.push(variants[i].inventory_quantity)
    }

    if (tracked.length > 0) {
      in_stock = _.some(tracked, i => i > 0)
      out_of_stock = _.some(tracked, i => i <= 0)
    }

    return { tracked, available, in_stock, out_of_stock }
  }

  calcInventory = variants => {
    if(!variants){
      return ''
    }

    let {
      tracked,
      available,
      in_stock,
      out_of_stock
    } = this.getInventoryStatus(variants);

    let outputString = ''
    if(available){
      outputString = outputString.concat('available<br>')
    }

    if(in_stock || out_of_stock){
      const inStock = _.filter(tracked, i => i > 0)
      const outOfStock = _.filter(tracked, i => i <= 0)

      if(inStock.length > 0){
        outputString = outputString.concat(`${inStock.reduce((s, i) => s + i)} ${inStock.length > 1 ? `in ${inStock.length} variants` : 'in 1 variant'}<br>`)
      }

      if(outOfStock.length > 0){
        if(outputString.length > 0){
          outputString = outputString.concat(`out of stock ${outOfStock.length > 1 ? `in ${outOfStock.length} variants` : 'in 1 variant'}`)
        } else {
          outputString = outputString.concat('out of stock')
        }
      }
    }

    const returnContent = outputString.replace(/<br>$/m, '');

    return <p style={{whiteSpace: 'pre-line', margin: '0'}} dangerouslySetInnerHTML={{__html: returnContent}}/>
  }

  renderSKU = (_, row) => {
    const { variants } = row;

    let skus = []
    for (let variant of variants) {
      if (variant.sku) {
        skus.push(variant.sku)
      }
    }

    return (
      skus.join(' ')
    )
  }

  renderStatus = cell => {
    switch (cell){
      case 'active':
        return <Badge bg="success">ACTIVE</Badge>
      case 'draft':
        return <Badge bg="default">DRAFT</Badge>
      case 'pending':
        return <Badge bg="warning">PENDING</Badge>
      case 'disapproved':
        return <Badge bg="danger">REJECTED</Badge>
      case 'reviewing':
        return <Badge bg="primary">IN REVIEW</Badge>
      case 'more_info':
        return <Badge bg="info">MORE INFO</Badge>
      default:
        break;
    }
  }

  renderCSVImages = (cell, row) => {
    const images = _.map(cell, img => img.src)

    return images
  }

  renderCSVInventory = (cell, row) => {
    let output = this.calcInventory(cell, row)
    if(typeof output !== 'string'){
      output = (output.props && output.props.dangerouslySetInnerHTML && output.props.dangerouslySetInnerHTML.__html) || ''
    }

    return output.replace('<br>', ',')
  }

  handleStatusChange = (e) => {
    this.setState({ status: e.target.value })
  }

  handleInventoryChange = (e) => {
    this.setState({ inventory: e.target.value })
  }

  handleVendorChange = (e) => {
    this.setState({ vendor: e.target.value })
  }

  handleResetFilter = () => {
    this.setState({
      vendor: 'any',
      status: 'any',
      inventory: 'any'
    });
  }

  handleOnClickFilter = () => {
    const { history, location: { pathname }, user } = this.props;
    const { vendor } = this.state

    let queryBody = {
      vendor,
    }

    const queryString = qs.stringify(queryBody)
    history.push(`${pathname}?${queryString}`)
  }

  onRowSelect = (row, isSelected, e) => {

    let { selectedIds } = this.state;

    if(isSelected){
      selectedIds.push({id: row.id, title: row.title, variants: row.variants, tags: row.tags, status: row.status})
    } else {
      selectedIds = _.reject(selectedIds, {id: row.id})
    }

    let selectedPending = _.some(selectedIds, product => product.status !== 'active' && product.status !== 'draft')

    this.setState({ selectedIds, selectedPending });
  }

  onSelectAll = (isSelected, rows) => {
    let { selectedIds } = this.state;

    if (isSelected) {
      _.each(rows, function (row) {
        selectedIds.push({id: row.id, title: row.title, variants: row.variants, tags: row.tags, status: row.status})
      })
    } else {
      _.each(rows, function (row) {
        selectedIds = _.reject(selectedIds, {id: row.id})
      });
    }

    let selectedPending = _.some(selectedIds, product => product.status !== 'active' && product.status !== 'draft')

    this.setState({ selectedIds, selectedPending });

    return true;
  }

  handleOnClickDuplicateProduct = () => {
    this.setState({ showConfirmDuplicateProductModal: true })
  }

  handleOnCloseDuplicateProductModal = () => {
    this.setState({ showConfirmDuplicateProductModal: false })
  }

  handleDuplicateProduct = () => {
    const { selectedIds } = this.state;
    const { id, title } = selectedIds[0];

    let body = {
      id,
      title: title + ' - Copy'
    }

    return api(`/products/duplicate`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then(result => {
      this.setState({
        showConfirmDuplicateProductModal: false,
        selectedIds: [],
        displayDuplicateProductSuccess: true,
        displayDuplicateProductFail: false,
        duplicatedProductId: result.id
      });
    }).catch(err => {
      this.setState({
        displayDuplicateProductSuccess: false,
        displayDuplicateProductFail: true,
        errorMessage: err.message
      })
    })
  }

  renderDate = (cell, row) => {

    return cell ? moment(cell).format("DD/MM/YYYY") : ''
  }

  showImage = (cell, row) => {
    if(cell && cell.length && cell[0]){
      return <img style={{height: '70px'}} src={cell[0].thumbnail || cell[0].src}/>;
    }
  }

  handleOnClickEditProducts = () => {
    this.setState({ showProductEditModal: true });
  }

  handleOnClickBulkEditProducts = () => {
    //open a new tab for bulk edit
    const { selectedIds } = this.state;

    let productIds = _.map(selectedIds, 'id');

    window.open(`/products/bulk-edit?ids=${productIds.join(',')}`, '_blank');
  }

  handleOnCloseEditProductModal = (updating) => {
    this.setState({ showProductEditModal: false });

    if (updating) {
      alert("Updating products... this may take up to a couple minutes, please refresh the page in a moment")
    }
  }

  onClickApplyAddOns = () => {
    this.setState({ showEnableAddOnsModal: true })
  }

  onClickReorderAddOns = () => {
    let items = _.cloneDeep(this.props.productAddOns.items);
    items = _.map(items, item => {
      let tagsSplit = _.split(item.tags, ', ');
      let position = _.find(tagsSplit, tag => _.startsWith(tag, 'pos_'));
      position = _.split(position, '_')[1];

      return {
        ...item,
        position: parseInt(position)
      }
    })
    items = _.sortBy(items, 'position')

    this.setState({ sorting: true, sortingItems: items })
  }

  handleMoveField = (dragIndex, hoverIndex) => {
    let sortingItems = _.cloneDeep(this.state.sortingItems)

    this.setState({
      sortingItems: [...update(sortingItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, sortingItems[dragIndex]]
        ]
      })]
    })
  }

  handleOnClickCancelReorderAddOns = () => {
    this.setState({ sorting: false })
  }

  handleSaveReorderAddOns = () => {
    const { setProductAddOns } = this.props;
    let { sortingItems } = this.state;

    toast.success('Applying changes... this may take up to a couple minutes.', { position: 'top-center' });

    let updateItems = []
    sortingItems = _.map(sortingItems, (item, i) => {
      let product = {
        ...item,
        new_position: i
      }

      if (product.new_position !== product.original_position) {
        updateItems.push(product)
      }

      return product
    })
    setProductAddOns(sortingItems)
    this.setState({ sorting: false })

    return api(`/products/addon/reorder`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ products: updateItems })
    }).catch(err => {
      console.log(err)
    })
  }

  formatPosition = (col, row) => {
    if (row.new_position >= 0) {
      return row.new_position + 1
    }
    if (isNaN(col)) {
      return ''
    }
    return col + 1
  }

  render() {
    const { productAddOns: { loading, items, next }, user, location, unselectableRows } = this.props;
    const { vendor, vendorList, status, inventory, selectedIds, showConfirmDuplicateProductModal, displayDuplicateProductSuccess, duplicatedProductId, displayDuplicateProductFail, errorMessage, adminPrompt, showProductEditModal, selectedPending, selectOptions, showEnableAddOnsModal, sorting, sortingItems, overlayLoading } = this.state;

    const isAdmin = user.role === 'admin' || user.role === 'staff'

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      nonSelectable: unselectableRows,
      headerColumnStyle: { width: '30px' },
    }

    const options = {
      showTotal: true,
      sizePerPage: 20,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: items.length
      }],
    };

    const columns = [
      {
        dataField: 'id',
        text: 'Id',
        hidden: true,
      },
      {
        dataField: 'original_position',
        text: 'Position',
        sort: true,
        formatter: this.formatPosition,
        sortValue: this.formatPosition,
        sortFunc: (a, b, order) => {
          if (!a) {
            a = 999
          }

          if (!b) {
            b = 999
          }

          if (order === 'desc') {
            return b - a
          } else {
            return a - b
          }
        },
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'images',
        text: 'Image',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        align: 'center',
        formatter: this.showImage,
        csvFormatter: this.renderCSVImages,
        csvText: 'Image Src'
      },
      {
        dataField: 'handle',
        text: 'Handle',
        hidden: true,
        csvExport: isAdmin,
      },
      {
        dataField: 'title',
        headerStyle: {
          width: '400px',
          resize: 'horizontal'
        },
        sort: true,
        text: 'Title',
        formatter: this.renderDetailLink,
      },
      {
        dataField: 'titlesearch',
        hidden: true,
        text: 'titlesearch',
        formatter: (_, row) => row.title,
      },
      {
        dataField: 'variants_sku',
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'SKU',
        sort: true,
        formatter: this.renderSKU,
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'status',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Status',
        sort: true,
        formatter: this.renderStatus,
      },
      {
        dataField: 'statussearch',
        text: 'statussearch',
        hidden: true,
        formatter: (_, row) => row.status,
      },
      {
        dataField: 'variants',
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'Inventory',
        sort: true,
        formatter: this.calcInventory,
        csvFormatter: this.renderCSVInventory,
      },
      {
        dataField: 'product_type',
        text: 'Type',
        sort: true,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'vendor',
        text: 'Vendor',
        sort: true,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
        hidden: !isAdmin
      },
      {
        dataField: 'updated_at',
        text: 'Updated At',
        sort: true,
        formatter: this.renderDate,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'created_at',
        text: 'Created At',
        sort: true,
        formatter: this.renderDate,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'template_suffix',
        text: 'Template',
        hidden: true,
        csvExport: isAdmin,
      },
      {
        dataField: 'tags',
        text: 'Tags',
        hidden: true,
        csvExport: isAdmin,
      }
    ];

    return (
      <div>
        <TabHeader title="Product Add-ons" tab_id="product_addons" user={user} helpQuickLink={true}/>
        {
          displayDuplicateProductSuccess &&
          <div className="sticky-alert">
            <Alert variant="success">
              <span>Product duplicated successfully. </span>
                <Link to={{pathname: `/products/pending/${duplicatedProductId}`,}}>Click here to view product.</Link>
            </Alert>
          </div>
        }
        {
          displayDuplicateProductFail &&
          <div className="sticky-alert">
            <Alert variant="danger">
              <span>{errorMessage}</span>
            </Alert>
          </div>
        }

        { (isAdmin || vendorList.length > 1) &&
        <Accordion style={{borderRadius: '18px'}} className="dashboard-card shadow-sm" defaultActiveKey="0">
          <Accordion.Item style={{borderRadius: '18px', border: 'none'}} eventKey="0">
            <Accordion.Header><h5 style={{marginBottom: '0'}}>Vendor</h5></Accordion.Header>
            <Accordion.Body>
              <Row>
                {
                  isAdmin &&
                    <Col sm={12} xl={4} className="mb-3 mb-sm-3" style={{textAlign: 'left'}}>
                      <VendorSelection handleOnVendorChange={this.handleVendorChange} selectedVendor={vendor} showLabel={false}/>
                    </Col>
                }
                { //For dual vendor
                  vendorList.length > 1 &&
                    <Col sm={6} xl={4} className="mb-3 mb-sm-3" style={{textAlign: 'left'}}>
                      <div>
                        <Form.Select className="form-control" onChange={this.handleVendorChange} value={vendor}>
                          {
                            vendorList.map((option, ind) => {
                              return (
                                <option key={ind} value={option}>{option}</option>
                              )
                            })
                          }
                        </Form.Select>
                      </div>
                    </Col>
                }
                <Col sm={3} className="filter-buttons d-flex flex-sm-column flex-lg-row justify-content-between mb-auto">
                  <Button
                      className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                      variant="primary"
                      style={{width: '100%'}}
                      onClick={this.handleOnClickFilter}
                    >Apply
                  </Button>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        }

        <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap">
          { !sorting ?
          <>
            <Col sm="auto">
              <Link
                className="btn btn-primary w-100 btn-br-6"
                to={{
                  pathname: `/product_addons/create`,
                }}
              >Create Add-on</Link>
            </Col>
            <EnableAddOnsModal
              user={user}
              location={location}
              onClose={() => {}}
              deliveryType={null}
              updateForm={() => {}}
            />
            <Button variant="secondary" onClick={this.onClickReorderAddOns}>Reorder Add-ons</Button>
          </>
          :
          <>
            <Button variant="primary" onClick={this.handleSaveReorderAddOns}>Confirm Reorder</Button>
            <Button variant="secondary" onClick={this.handleOnClickCancelReorderAddOns}>Cancel</Button>
          </>
          }
        </Container>
        {
          loading &&
          <Loader />
        }
        { overlayLoading &&
          <OverlayLoader />
        }
        {
          adminPrompt &&
          <div className="admin-prompt">
            <img src={promptIcon}></img>
            <h3 className="mb-0">Please select filter options</h3>
          </div>
        }
        {
          !loading && items && !adminPrompt && !sorting &&
          <ToolkitProvider
              keyField='id'
              data={_.filter(items, item => {
                let validStatus = false, validInventory = false

                let {
                  available,
                  in_stock,
                  out_of_stock
                } = this.getInventoryStatus(item.variants || []);

                if (status === 'any' || item.status === status) {
                  validStatus = true
                }
                if (inventory === 'any') {
                  validInventory = true
                } else if (inventory === 'available' && available) {
                  validInventory = true
                } else if (inventory === 'in_stock' && in_stock) {
                  validInventory = true
                } else if (inventory === 'out_of_stock' && out_of_stock) {
                  validInventory = true
                }

                return validStatus && validInventory
              })}
              columns={columns}
              exportCSV={{
                exportAll: true
              }}
              search={{
                searchFormatted: true
              }}
            >
              {
                props => (
                  <>
                  <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                    <Col className="d-flex align-items-center gap-2">
                      <label style={{marginBottom: '5px'}}>Status:</label>
                      <Form.Select className="form-control" onChange={this.handleStatusChange} value={status}>
                        <option value="any">Any</option>
                        <option value="active">Active</option>
                        <option value="draft">Draft</option>
                        <option value="pending">Pending</option>
                        <option value="reviewing">Reviewing</option>
                        <option value="more_info">More Info</option>
                        <option value="disapproved">Disapproved</option>
                      </Form.Select>
                    </Col>
                    <Col className="d-flex align-items-center gap-2">
                      <label style={{marginBottom: '5px'}}>Inventory:</label>
                      <Form.Select className="form-control" onChange={this.handleInventoryChange} value={inventory}>
                        <option value="any">Any</option>
                        <option value="available">Available (Untracked)</option>
                        <option value="in_stock">In Stock</option>
                        <option value="out_of_stock">Out of Stock</option>
                      </Form.Select>
                    </Col>
                      <Col>
                        <ExportCSVButton
                          className="btn-info"
                          style={{borderRadius:'8px'}}
                          { ...props.csvProps }
                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                          Export to CSV</ExportCSVButton>
                      </Col>
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps }
                        style={{borderRadius:'25px'}} placeholder="Search"/>
                      </Col>
                    </Container>

                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={false}
                      pagination={paginationFactory(options)}
                      hover expandableRow={() => true}
                      options={{
                        expandBy: "column",
                        sizePerPage: 20,
                        paginationShowsTotal: true,
                        sortName: "created_at",
                        sortOrder: "desc"
                      }}
                      expandComponent={this.renderExpandedNoteColumn}
                      defaultSorted={[{
                        dataField: 'original_position',
                        order: 'asc',
                      }]}
                    />
                </>
            )
          }
          </ToolkitProvider>
        }
        <DndProvider backend={HTML5Backend}>
          { !loading && items && !adminPrompt && sorting &&
              _.map(sortingItems, (addon, i) => (
                <SortAddOnsRow key={i} moveField={this.handleMoveField} addon={addon} index={i} />
              ))
          }
        </DndProvider>
        { sorting &&
        <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap">
          <Button variant="primary" onClick={this.handleSaveReorderAddOns}>Confirm Reorder</Button>
          <Button variant="secondary" onClick={this.handleOnClickCancelReorderAddOns}>Cancel</Button>
        </Container>
        }
        {
          showConfirmDuplicateProductModal &&
            <ConfirmModal onClickYes={this.handleDuplicateProduct} onClose={this.handleOnCloseDuplicateProductModal} title="Duplicate Product">
              <p>Confirm duplicate {_.find(items, i => i.id === selectedIds[0].id).title}?</p>
            </ConfirmModal>
        }
        {
          showProductEditModal &&
            <ProductEditModal selectedProducts={selectedIds} onBulkEditMoreFields={this.handleOnClickBulkEditProducts} onClose={this.handleOnCloseEditProductModal} selectOptions={selectOptions}/>
        }
      </div>
    );
  }
}

const dayOptions = [
  { value: 'sun', label: 'Sunday' },
  { value: 'mon', label: 'Monday' },
  { value: 'tue', label: 'Tuesday' },
  { value: 'wed', label: 'Wednesday' },
  { value: 'thu', label: 'Thursday' },
  { value: 'fri', label: 'Friday' },
  { value: 'sat', label: 'Saturday' },
]

export default connect((state, props) => {
  return {
    taggings: state.taggings,
    user: state.auth.user,
    productAddOns: state.productAddOns,};
}, {...productAddOnsActionCreators,
  ...taggingsActionCreators})(Products);
