import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_MERCHANTRATE_REQUEST = 'FETCH_MERCHANTRATE_REQUEST';
export const FETCH_MERCHANTRATE_SUCCESS = 'FETCH_MERCHANTRATE_SUCCESS';
export const FETCH_MERCHANTRATE_FAILURE = 'FETCH_MERCHANTRATE_FAILURE';

export const CLEAR_MERCHANTRATE = 'CLEAR_MERCHANTRATE';

function fetchMerchantRateRequest() {
  return {
    type: FETCH_MERCHANTRATE_REQUEST,
  };
}

function fetchMerchantRateSuccess(json) { 
  return {
    type: FETCH_MERCHANTRATE_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchMerchantRateFailure() {
  return {
    type: FETCH_MERCHANTRATE_FAILURE
  };
}

function shouldFetchMerchantRate(state) {
  const results = state.merchantRate;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

export function fetchMerchantRateFromApi(_id) {
  return dispatch => {
    dispatch(fetchMerchantRateRequest(_id));
    return api(`/merchants/${_id}`)
      .then(json => {
        dispatch(fetchMerchantRateSuccess(json));
      })
      .catch(err => {
        dispatch(fetchMerchantRateFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchMerchantRate(_id) {
  return (dispatch, getState) => {
    if (shouldFetchMerchantRate(getState())) {
      return dispatch(fetchMerchantRateFromApi(_id));
    }
  };
}
