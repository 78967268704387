import { useSelector } from "react-redux";
import TabHeader from "../components/TabHeader"
import { useState, useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";
import { Button, Alert, Badge, Accordion, Row, Col, Form, Container } from 'react-bootstrap';
import VendorSelection from '../components/VendorSelection'
import api from '../helpers/apiClient'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import moment from 'moment'
import Loader from '../components/Loader';
import ConfirmModal from '../components/ConfirmModal';

const { SearchBar } = Search;

const ArchivedProducts = () => {
  const { user } = useSelector(state => state.auth);
  const location = useLocation()
  const history = useHistory()

  const vendorList = useMemo(() => user.merchantId ? user.merchantId.split('#,') : [])
  const [ vendor, setVendor ] = useState(vendorList[0] || 'any')
  const [ showAdminPrompt, setShowAdminPrompt ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ items, setItems ] = useState([])
  const [ selectedIds, setSelectedIds ] = useState([])
  const [ showConfirmUnarchive, setShowConfirmUnarchive ] = useState(false)
  const isAdmin = user.role === 'admin' || user.role === 'staff'

  useEffect(() => {
    const queryString = qs.parse(location.search.substr(1));
    if (queryString.vendor) {
      setVendor(queryString.vendor)
    }

    if(!queryString.vendor && ['admin', 'staff'].includes(user.role)){
      setShowAdminPrompt(true)
    } else {
      fetchProducts()
    }
  }, [location])

  const fetchProducts = async () => {
    setLoading(true)
    const queryString = qs.stringify({
      merchantId: vendor,
    }, { skipNulls: true });
    return api(`/archived_products?${queryString}`).then(result => {
      setItems(result)
      setLoading(false)
      setShowAdminPrompt(false)
    })
  }

  const onClickFilter = () => {
    const queryString = qs.stringify({ vendor })

    history.push(`${location.pathname}?${queryString}`)
  }

  const showImage = (cell, row) => {
    if(cell && cell.length && cell[0]){
      return <img style={{height: '70px'}} src={cell[0].thumbnail || cell[0].src}/>;
    }
  }

  const renderCSVImages = (cell, row) => {
    const images = _.map(cell, img => img.src)

    return images
  }

  const renderDetailLink = (cell, row) => {

    return (
      <Link
        key={row.id}
        to={{
          pathname: `/products/${_.isNumber(row.id) ? row.id : `pending/${row.id}`}`
        }}
      >
        {cell}
      </Link>
    )
  }

  const renderSKU = (_, row) => {
    const { variants } = row;

    let skus = []
    for (let variant of variants) {
      if (variant.sku) {
        skus.push(variant.sku)
      }
    }

    return (
      skus.join(' ')
    )
  }

  const getInventoryStatus = variants => {
    let tracked = [], available = false, in_stock = false, out_of_stock = false

    for (let i = 0; i < variants.length; i++){
      if(variants[i].inventory_management == null){
        available = true
        continue
      }

      tracked.push(variants[i].inventory_quantity)
    }

    if (tracked.length > 0) {
      in_stock = _.some(tracked, i => i > 0)
      out_of_stock = _.some(tracked, i => i <= 0)
    }

    return { tracked, available, in_stock, out_of_stock }
  }

  const calcInventory = variants => {
    if(!variants){
      return ''
    }

    let {
      tracked,
      available,
      in_stock,
      out_of_stock
    } = getInventoryStatus(variants);

    let outputString = ''
    if(available){
      outputString = outputString.concat('available<br>')
    }

    if(in_stock || out_of_stock){
      const inStock = _.filter(tracked, i => i > 0)
      const outOfStock = _.filter(tracked, i => i <= 0)

      if(inStock.length > 0){
        outputString = outputString.concat(`${inStock.reduce((s, i) => s + i)} ${inStock.length > 1 ? `in ${inStock.length} variants` : 'in 1 variant'}<br>`)
      }

      if(outOfStock.length > 0){
        if(outputString.length > 0){
          outputString = outputString.concat(`out of stock ${outOfStock.length > 1 ? `in ${outOfStock.length} variants` : 'in 1 variant'}`)
        } else {
          outputString = outputString.concat('out of stock')
        }
      }
    }

    const returnContent = outputString.replace(/<br>$/m, '');

    return <p style={{whiteSpace: 'pre-line', margin: '0'}} dangerouslySetInnerHTML={{__html: returnContent}}/>
  }

  const renderCSVInventory = (cell, row) => {
    let output = calcInventory(cell, row)
    if(typeof output !== 'string'){
      output = (output.props && output.props.dangerouslySetInnerHTML && output.props.dangerouslySetInnerHTML.__html) || ''
    }

    return output.replace('<br>', ',')
  }

  const renderDate = (cell, row) => {

    return cell ? moment(cell).format("DD/MM/YYYY") : ''
  }

  const renderMarkupType = (cell, row) => {
    const { variants } = row;

    let types = []
    for (let variant of variants) {
      let foundType = _.find(variant.metafields, m => m.key === 'markup_type')

      if (foundType) {
        types.push(foundType.value)
      } else {
        types.push('-')
      }
    }

    if (_.every(types, type => type === types[0])) {
      types = types.splice(0, 1)
    }

    return types.join('\n')
  }

  const renderMarkupValue = (cell, row) => {
    const { variants } = row;

    let values = []
    for (let variant of variants) {
      let foundValue = _.find(variant.metafields, m => m.key === 'markup')

      if (foundValue) {
        values.push(foundValue.value)
      } else {
        values.push('-')
      }
    }

    if (_.every(values, value => value === values[0])) {
      values = values.splice(0, 1)
    }

    return values.join('\n')
  }

  const onRowSelect = (row, isSelected, e) => {

    if(isSelected){
      setSelectedIds([...selectedIds, row.id])
    } else {
      setSelectedIds(_.reject(selectedIds, id => id == row.id))
    }
  }

  const onSelectAll = (isSelected, rows) => {
    let selected = [...selectedIds]
    if (isSelected) {
      _.each(rows, function (row) {
        selected.push(row.id)
      })
    } else {
      _.each(rows, function (row) {
        selected = _.reject(selected, id => id == row.id)
      });
    }

    setSelectedIds(selected)

    return true;
  }

  const handleUnarchive = async () => {
    return api(`/archived_products/unarchive`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        ids: selectedIds
      })
    }).then(() => {
      alert("Unarchiving products... this may take up to a couple minutes, please refresh the page in a moment")
      setShowConfirmUnarchive(false)
    })
  }

  const selectRowProp = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
    headerColumnStyle: { width: '30px' },
  }

  const options = {
    showTotal: true,
    sizePerPage: 20,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: 'All', value: items.length
    }],
  };

  const columns = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true,
    },
    {
      dataField: 'images',
      text: 'Image',
      headerStyle: {
        width: '100px',
        resize: 'horizontal'
      },
      align: 'center',
      formatter: showImage,
      csvFormatter: renderCSVImages,
      csvText: 'Image Src'
    },
    {
      dataField: 'handle',
      text: 'Handle',
      hidden: true,
      csvExport: isAdmin,
    },
    {
      dataField: 'title',
      headerStyle: {
        width: '400px',
        resize: 'horizontal'
      },
      sort: true,
      text: 'Title',
      formatter: renderDetailLink,
    },
    {
      dataField: 'titlesearch',
      hidden: true,
      text: 'titlesearch',
      formatter: (_, row) => row.title,
    },
    {
      dataField: 'variants_sku',
      headerStyle: {
        width: '120px',
        resize: 'horizontal'
      },
      text: 'SKU',
      sort: true,
      formatter: renderSKU,
      csvFormatter: renderSKU,
      style: {
        whiteSpace: 'pre-line'
      }
    },
    {
      dataField: 'status',
      headerStyle: {
        width: '100px',
        resize: 'horizontal'
      },
      text: 'Status',
      sort: true,
      formatter: () => <Badge bg="secondary">ARCHIVED</Badge>
    },
    {
      dataField: 'statussearch',
      text: 'statussearch',
      hidden: true,
      formatter: (_, row) => row.status,
    },
    {
      dataField: 'variants',
      headerStyle: {
        width: '120px',
        resize: 'horizontal'
      },
      text: 'Inventory',
      sort: true,
      formatter: calcInventory,
      csvFormatter: renderCSVInventory,
    },
    {
      dataField: 'product_type',
      text: 'Type',
      sort: true,
      headerStyle: {
        width: '150px',
        resize: 'horizontal'
      },
    },
    {
      dataField: 'vendor',
      text: 'Vendor',
      sort: true,
      headerStyle: {
        width: '150px',
        resize: 'horizontal'
      },
      hidden: !isAdmin
    },
    {
      dataField: 'updated_at',
      text: 'Updated At',
      sort: true,
      formatter: renderDate,
      headerStyle: {
        width: '150px',
        resize: 'horizontal'
      },
    },
    {
      dataField: 'created_at',
      text: 'Created At',
      sort: true,
      formatter: renderDate,
      headerStyle: {
        width: '150px',
        resize: 'horizontal'
      },
    },
    {
      dataField: 'template_suffix',
      text: 'Template',
      hidden: true,
      csvExport: isAdmin,
    },
    {
      dataField: 'tags',
      text: 'Tags',
      hidden: true,
      csvExport: isAdmin,
    },
    {
      dataField: 'variants_markup_type',
      text: 'Markup Type',
      csvFormatter: renderMarkupType,
      hidden: true,
      csvExport: isAdmin,
    },
    {
      dataField: 'variants_markup_value',
      text: 'Markup Value',
      csvFormatter: renderMarkupValue,
      hidden: true,
      csvExport: isAdmin,
    }
  ];

  return (
    <>
      <TabHeader title="Archived Products" tab_id="archived_products" children={
        <Button
          variant="primary"
          className="btn-br-6"
          disabled={selectedIds.length === 0}
          onClick={() => setShowConfirmUnarchive(true)}
        >Unarchive</Button>
      } />
      {(isAdmin || vendorList.length > 1) &&
        <Accordion style={{ borderRadius: '18px' }} className="dashboard-card shadow-sm mb-4" defaultActiveKey="0">
          <Accordion.Item style={{ borderRadius: '18px', border: 'none' }} eventKey="0">
            <Accordion.Header><h5 style={{ marginBottom: '0' }}>Vendor</h5></Accordion.Header>
            <Accordion.Body>
              <Row>
                {
                  isAdmin &&
                  <Col sm={12} xl={4} className="mb-3 mb-sm-3" style={{ textAlign: 'left' }}>
                    <VendorSelection handleOnVendorChange={(e) => setVendor(e.target.value)} selectedVendor={vendor} showLabel={false} />
                  </Col>
                }
                { //For dual vendor
                  vendorList && vendorList.length > 1 &&
                  <Col sm={6} xl={4} className="mb-3 mb-sm-3" style={{ textAlign: 'left' }}>
                    <div>
                      <Form.Select className="form-control" onChange={(e) => setVendor(e.target.value)} value={vendor}>
                        {
                          vendorList.map((option, ind) => {
                            return (
                              <option key={ind} value={option}>{option}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </div>
                  </Col>
                }
                <Col sm={3} className="filter-buttons d-flex flex-sm-column flex-lg-row justify-content-between mb-auto">
                  <Button
                    className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                    variant="primary"
                    style={{ width: '100%' }}
                    onClick={onClickFilter}
                  >Apply
                  </Button>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      }
      { loading &&
        <Loader />
      }
      {
        !loading && items && !showAdminPrompt &&
        <ToolkitProvider
          keyField='id'
          data={items}
          columns={columns}
          exportCSV={{
            exportAll: true
          }}
          search={{
            searchFormatted: true
          }}
        >
          {
            props => (
              <>
                <Container fluid className="mb-3 d-flex gap-3 flex-row flex-wrap">
                  <Col>
                  </Col>
                  <Col xs={4}>
                    <SearchBar {...props.searchProps}
                      style={{ borderRadius: '25px' }} placeholder="Search" />
                  </Col>
                </Container>

                <BootstrapTable
                  {...props.baseProps}
                  bordered={false}
                  selectRow={selectRowProp}
                  pagination={paginationFactory(options)}
                  hover
                  options={{
                    expandBy: "column",
                    sizePerPage: 20,
                    paginationShowsTotal: true,
                    sortName: "created_at",
                    sortOrder: "desc"
                  }}
                  noDataIndication={() => 'You have no archived products'}
                />
              </>
            )
          }
        </ToolkitProvider>
      }
      { showConfirmUnarchive &&
        <ConfirmModal onClickYes={handleUnarchive} onClose={() => setShowConfirmUnarchive(false)} title={`Unarchive ${selectedIds.length} product(s)`}>
          <p>Unarchiving will change these products' status to draft so it will be visible on your product list again.</p>
        </ConfirmModal>
      }
    </>
  )
}

export default ArchivedProducts