import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Well, Button, Alert, Col, Modal, Anchor, Row, Form, Container } from 'react-bootstrap';
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import config from '../config'
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import * as Promise from "bluebird";
import _ from "lodash"
import * as taggingsActionCreators from '../redux/actions/taggings';

class CreateTitlePrefixModal extends Component{

    constructor(props) {
        super(props);
    
        this.state = {
            loading: false,
            selectedTagging: null
        };
    }
    
    async componentDidMount() {
        const { fetchTaggings } = this.props;

        await fetchTaggings()
    }

    handleOnClose = (shouldRefresh) => {
        this.props.onClose(shouldRefresh);
    }

    handleOnSelectChange = (e) => {

        let value = e.target.value

        //remove spaces and symbols except underscore and make it lowercase
        value = value.replace(/[^a-zA-Z0-9_]/g, '').toLowerCase()

        this.setState({
            selectedTagging: value
        })
    }

    submit = async (e) => {
        e.preventDefault();
        const {formTagging, formTitlePrefix, type} = e.target.elements;

        let tag = formTagging.value.replace(/[^a-zA-Z0-9_]/g, '').toLowerCase()

        return api(`/title_prefix`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                prefix: formTitlePrefix.value,
                tag,
                type: type.value
            })
        }).then(result => {
            if(result._id){
                this.handleOnClose(true);
            }
        })
    }

    render() {
        const { taggings: {items} } = this.props;
        const { loading, selectedTagging } = this.state;

        return (
            <div>
                <Modal className="manual-order-modal" show={true} onHide={this.handleOnClose} size="xl">
                    <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton><h4 className="mb-0">Create Title Prefix</h4></Modal.Header>
                    <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>

                        <Form onSubmit={this.submit}>
                            <Form.Group className="mt-4" controlId="formTitlePrefix">
                                <Form.Label>Title Decoration</Form.Label>
                                <Form.Control type="text" placeholder="Enter Title Decoration" />
                            </Form.Group>
                            <Form.Group className="mt-4" controlId="formTagging" onChange={this.handleOnSelectChange}>
                                <Form.Label>Tagging to apply</Form.Label>
                                {
                                    items && (items.Occasions || items.Location || items['For Who'] || items["Flower Type"]) &&
                                    <Form.Control as="select">
                                        <option>Select a tag</option>
                                        {
                                            items.Occasions && items.Occasions.length > 0 &&
                                            <optgroup label="Occasions">
                                            {
                                                items.Occasions.map((tagging, index) => {
                                                    return <option key={index} value={tagging.tag}>{tagging.desc}</option>
                                                })
                                            }
                                            </optgroup>
                                        }
                                        {
                                            items.Location && items.Location.length > 0 &&
                                            <optgroup label="Location">
                                            {
                                                items.Location.map((tagging, index) => {
                                                    return <option key={index} value={tagging.tag}>{tagging.desc}</option>
                                                })
                                            }
                                            </optgroup>
                                        }
                                        {
                                            items['For Who'] && items['For Who'].length > 0 &&
                                            <optgroup label="For Who">
                                            {
                                                items['For Who'].map((tagging, index) => {
                                                    return <option key={index} value={tagging.tag}>{tagging.desc}</option>
                                                })
                                            }
                                            </optgroup>
                                        }
                                        {
                                            items["Flower Type"] && items["Flower Type"].length > 0 &&
                                            <optgroup label="Flower Type">
                                            {
                                                items["Flower Type"].map((tagging, index) => {
                                                    return <option key={index} value={tagging.tag}>{tagging.desc}</option>
                                                })
                                            }
                                            </optgroup>
                                        }
                                    </Form.Control>
                                }
                                {
                                    selectedTagging && <span className="text-muted">Apply to tag: {selectedTagging}</span>
                                }
                            </Form.Group>
                            <Form.Group className="mt-4" controlId="type">
                                <Form.Label>Decoration Type</Form.Label>
                                <Form.Control as="select">
                                    <option value="prefix">Prefix</option>
                                    <option value="suffix">Suffix</option>
                                </Form.Control>
                            </Form.Group>
                            {/* <Form.Group controlId="formDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" placeholder="Enter Description" />
                            </Form.Group> */}
                            <Button className="my-4" disabled={loading} variant="primary" type="submit">
                                {loading ? "Submitting" : "Submit"}
                            </Button>
                        </Form>

                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default connect((state, props) => {
    let {taggings: { items } } = state
  
    return {
        taggings: state.taggings,
    }
}, {...taggingsActionCreators})(CreateTitlePrefixModal);