import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Card, Button, Alert, Col, Row, Modal } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import Input from '../components/Input';
import Select from '../components/Select';
import Loader from '../components/Loader';
import commissionTypeOptions from '../data/commission_type.json';
import deliveryMethodOptions from '../data/delivery_method.json';
import api from '../helpers/apiClient';
import Textarea from '../components/Textarea';
import * as merchantRateActionCreators from '../redux/actions/merchantRate';
import * as usersActionCreators from '../redux/actions/users';
import * as merchantsByCountryActionCreators from '../redux/actions/merchantsByCountry';
import _ from 'lodash';
import trackingLinkOptions from '../data/tracking_link.json';
import trackingLinkSGOptions from '../data/tracking_link_sg.json';
import merchantStatusOptions from '../data/merchant_status.json';
import PhoneNumberInput from '../components/PhoneNumberInput';

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

const validate = (values, props) => {
  const errors = {}

  if (!/^([1-9]|[12]\d|9[0-9])$/.test(values.commission_rate)) {
    errors.commissionRate = 'Please enter between 1 to 99';
  }
  if(values.operations_contact_method === 'url' &&
    values.operations_contact &&
    !values.operations_contact.includes('http')){
    errors.operations_contact = 'Please enter a link'
  }
  return errors
}

const contact_methods = [
  {"key":"phone_number","value":"phone_number","name":"Phone Number"},
  {"key":"url","value":"url","name":"Link"},
]

const markup_types = [
  {"key":"fixed","value":"fixed","name":"Fixed"},
  {"key":"percentage","value":"percentage","name":"Percentage"},
]

class Merchant extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { fetchPMs, fetchMerchantRate, match, fetchMerchantsByCountry } = this.props;
    if (match.params.merchantId) {
      fetchMerchantRate(match.params.merchantId);
    }

    fetchPMs()
    fetchMerchantsByCountry(country == "MY" ? "SG" : "MY")

    document.querySelector('.modal-header').style.display = 'none'
  }

  submit = (values, updateMarkup) => {
    const { match } = this.props;

    const { partner_manager, commission_type, commission_rate, gst_registered, discount_prefix, company_name, email, pickup_address, address_line_1, address_line_2, postcode, city, state, country, acc_name, acc_no, bank_name, swift_code, status, delivery_method, primary_logistic, exclude_sat, qna, products, virtual_message, shipments, operations_contact, operations_contact_method, shopify_store_url, shopify_api_password,
      woo_commerce_consumer_key, woo_commerce_store_url, linked_store, markup_type, markup, shopcada_api_key, shopcada_store_url, shopcada_authentication_token, procurement, remarks, exclude_free_message, pickup_address_line_1, pickup_address_line_2, pickup_address_postcode, pickup_address_city, pickup_address_state, pickup_address_remarks,
    } = values;
    const url = `/merchants/${match.params.merchantId}`;
    return api(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        partner_manager, commission_type, commission_rate, discount_prefix, gst_registered, company_name, email, pickup_address, address_line_1, address_line_2, postcode, city, state, country, acc_name, acc_no, bank_name, swift_code, status, delivery_method, primary_logistic, exclude_sat, qna, products, virtual_message, shipments, operations_contact, operations_contact_method, shopify_store_url, shopify_api_password,
        woo_commerce_consumer_key, woo_commerce_store_url, linked_store, markup_type, markup, updateMarkup, shopcada_api_key, shopcada_store_url, shopcada_authentication_token, procurement, remarks, exclude_free_message, pickup_address_line_1, pickup_address_line_2, pickup_address_postcode, pickup_address_city, pickup_address_state, pickup_address_remarks,
      })
    }).then(() => {
      const { history } = this.props;
      history.push('/merchants', { reload: true });
      // or goBack?
    }).catch(err => {
      if (err) {
        window.scrollTo(0, 0);
        if (err.validationError) {
          throw new SubmissionError({
            _error: err.validationError
          });
        }
        else if (err.message) {
          throw new SubmissionError({
            _error: err.message
          });
        }
        else {
          throw new SubmissionError({
            _error: 'Something bad happened'
          });
        }
      }
    });
  }

  getLogistic = () => {
    // let links = country === 'MY' ? trackingLinkOptions : trackingLinkSGOptions
    let links = _.uniqBy([...trackingLinkOptions, ...trackingLinkSGOptions], 'key')

    links = _.map(links, link => {
      link.value = link.name
      return link
    })
    return links
  }

  onClickSubmit = (e) => {
    const { markupChanged } = this.state;

    if (markupChanged) {
      e.preventDefault();
      this.setState({ showMarkupModal: true })
    }
  }

  onChangeExcludeMessage = (e) => {
    const { change } = this.props

    if (e.target.checked) {
      change('virtual_message', false)
    }
  }

  onChangeVirtualMessage = (e) => {
    const { change } = this.props

    if (e.target.checked) {
      change('exclude_free_message', false)
    }
  }

  onChangeMarkup = () => {
    this.setState({ markupChanged: true })
  }

  render() {
    const { users: { items: partnerNames }, merchantRate: { loading, loaded, item }, handleSubmit, error, submitting, contact_method_value, merchantOptions } = this.props
    const { showMarkupModal } = this.state;

    const logisticOptions = this.getLogistic();

    return (
      <div>
        {
          loading &&
          <Loader />
        }
        {
          !loading && loaded ?
          <>
          <Modal.Header className="mx-2 mt-1 pt-0 px-0 merchant-modal-header" closeButton>
            <Row className="align-items-center">
              <Col xs="auto"><h4 className="mb-0">{item.name}</h4></Col>
              <Col xs="auto"><Field className="form-control" noFormGroup={true} name="status" type="select" component={Select} options={merchantStatusOptions} inline={true} defaultOptionName="Select a status" /></Col>
            </Row>
          </Modal.Header>
          <div>
          {
            error &&
            <Alert variant="danger">
              {
                Array.isArray(error) ?
                <ul>
                {
                  error.map((err, i) => (
                    <li key={i}>
                      {err.msg}
                    </li>
                  ))
                }
                </ul>
                :
                error
              }
            </Alert>
          }
          <form onSubmit={handleSubmit(this.submit)}>
            <Row className="mx-1 px-0 pt-4">
              <Col sm={6} className="ps-1">
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Merchant Details</Card.Header>
                  <Card.Body className="d-grid gap-3 pb-4">
                    <Field className="form-control" name="company_name" label="Company Name" type="text" component={Input} />
                    <Field className="form-control" name="email" label="Contact Email" type="email" component={Input} />
                    <Field className="form-control" name="operations_contact_method" label="Contact Method" type="select" component={Select} options={contact_methods} defaultOptionName="Select a contact method" />
                    {
                      contact_method_value && ( contact_method_value === 'url' ?
                        <Field className="form-control" name="operations_contact" label="Contact Link (For Operational Issues)" type="text" component={Input} />
                        :
                        <Field className="form-control" name="operations_contact" label="Contact Number (For Operational Issues)" type="text" component={PhoneNumberInput} />
                      )
                    }
                    <Field className="form-control" name="partner_manager" label="Partner Manager" type="select" component={Select} options={partnerNames} defaultOptionName="Select a partner manager" />
                    <Field className="form-control" name="discount_prefix" label="Discount Prefix" type="text" component={Input} />
                    <Field className="form-control" name="remarks" label="Internal Note" type="text" component={Textarea} small_label="This will show on the vendor's product page" />
                  </Card.Body>
                </Card>
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Address</Card.Header>
                  <Card.Body className="d-grid gap-3 pb-4">
                    <Field className="form-control" name="address_line_1" label="Address Line 1" type="text" component={Input} />
                    <Field className="form-control" name="address_line_2" label="Address Line 2" type="text" component={Input} />
                    <Row>
                      <Col xs={6}>
                        <Field className="form-control" name="postcode" label="Postcode" type="text" component={Input} />
                      </Col>
                      <Col xs={6}>
                        <Field className="form-control" name="city" label="City" type="text" component={Input} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <Field className="form-control" name="state" label="State" type="text" component={Input} />
                      </Col>
                      <Col xs={6}>
                        <Field className="form-control" name="country" label="Country" type="text" component={Input} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Pickup Address</Card.Header>
                  <Card.Body className="d-grid gap-3 pb-4">
                    <Field className="form-control" name="pickup_address_line_1" label="Address Line 1" type="text" component={Input} />
                    <Field className="form-control" name="pickup_address_line_2" label="Address Line 2" type="text" component={Input} />
                    <Row>
                      <Col xs={6}>
                        <Field className="form-control" name="pickup_address_postcode" label="Postcode" type="text" component={Input} />
                      </Col>
                      <Col xs={6}>
                        <Field className="form-control" name="pickup_address_city" label="City" type="text" component={Input} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <Field className="form-control" name="pickup_address_state" label="State" type="text" component={Input} />
                      </Col>
                    </Row>
                    <Field className="form-control" name="pickup_address_remarks" label="Remarks" type="text" component={Textarea} />
                  </Card.Body>
                </Card>

                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">{country === "MY" ? "Singapore " : "Malaysia "} Store Name</Card.Header>
                  <Card.Body className="d-grid gap-3 pb-4">
                    <Field className="form-control" name="linked_store" label="Store Name" type="select" component={Select} options={merchantOptions} defaultOptionName="Select a store" />
                  </Card.Body>
                </Card>
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Enable Access</Card.Header>
                  <Card.Body className="d-grid gap-3 pb-4">
                    <Field className="form-control" isRenderLabel={false} name="procurement" label="Procurement" type="checkbox" component={Input} />
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} className="pe-1">
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Logistics</Card.Header>
                  <Card.Body className="d-grid gap-3 pb-4">
                    <Field className="form-control" name="delivery_method" label="Delivery Method" type="select" component={Select} options={deliveryMethodOptions} defaultOptionName="Select a delivery method" />
                    <Field className="form-control" name="primary_logistic" label="Primary Logistic" type="select" component={Select} options={logisticOptions} defaultOptionName="Select a delivery method" />
                    <Field className="form-control" isRenderLabel={false} name="exclude_sat" label="Ship by date excl. Saturday" type="checkbox" component={Input} />
                  </Card.Body>
                </Card>
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Commission</Card.Header>
                  <Card.Body className="d-grid gap-3 pb-4">
                    <Field className="form-control" name="commission_type" label="Commission Type" type="select" component={Select} options={commissionTypeOptions} defaultOptionName="Select a commission type" />
                    <Field className="form-control" name="commission_rate" label="Commission Rate (Percent)" type="text" component={Input} />
                    <Field className="form-control" isRenderLabel={false} name="gst_registered" label="SST Registered" type="checkbox" component={Input} />
                    <Field className="form-control" name="markup_type" label="Markup Type" type="select" component={Select} options={markup_types} defaultOptionName="Select a markup type" onChange={this.onChangeMarkup} />
                    <Field className="form-control" name="markup" label="Markup" type="text" component={Input} onChange={this.onChangeMarkup} />
                  </Card.Body>
                </Card>
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Bank Details</Card.Header>
                  <Card.Body className="d-grid gap-3 pb-4">
                    <Field className="form-control" name="acc_name" label="Bank Account Name" type="text" component={Input} />
                    <Field className="form-control" name="acc_no" label="Bank Account Number" type="text" component={Input} />
                    <Field className="form-control" name="bank_name" label="Bank Name" type="text" component={Input} />
                    <Field className="form-control" name="swift_code" label="Swift Code" type="text" component={Input} />
                  </Card.Body>
                </Card>
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Tools &amp; Features</Card.Header>
                  <Card.Body className="d-grid gap-1">
                    <Field className="form-control" isRenderLabel={false} name="products" label="Products" type="checkbox" component={Input} />
                    <Field className="form-control" isRenderLabel={false} name="shipments" label="Shipments" type="checkbox" component={Input} />
                    <hr/>
                    <h6 className="text-muted">Product Defaults</h6>
                    <Field className="form-control" isRenderLabel={false} name="qna" label="Q&amp;A" type="checkbox" component={Input} />
                    <Field className="form-control" isRenderLabel={false} name="virtual_message" label="Virtual Message" type="checkbox" component={Input} onChange={this.onChangeVirtualMessage}/>
                    <Field className="form-control" isRenderLabel={false} name="exclude_free_message" label="Exclude Free Message" type="checkbox" component={Input} onChange={this.onChangeExcludeMessage}/>
                  </Card.Body>
                </Card>
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Shopify API</Card.Header>
                  <Card.Body className="d-grid gap-1">
                    <Field className="form-control" name="shopify_api_password" label="Admin API Access Token" type="password" component={Input} />
                    <Field className="form-control" name="shopify_store_url" label="Store Url" placeholder="mystore.myshopify.com" type="text" component={Input} />
                  </Card.Body>
                </Card>
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Woo Commerce API</Card.Header>
                  <Card.Body className="d-grid gap-1">
                    <Field className="form-control" name="woo_commerce_store_url" label="Store Url" placeholder="abc.com" type="text" component={Input} />
                    <Field className="form-control" name="woo_commerce_consumer_key" label="Consumer Key" placeholder="" type="text" component={Input} />
                  </Card.Body>
                </Card>
                <Card className="tealCard shadow-sm mb-3">
                  <Card.Header className="tealCardHeader">Shopcada API</Card.Header>
                  <Card.Body className="d-grid gap-1">
                    <Field className="form-control" name="shopcada_store_url" label="Store Url" placeholder="abc.com" type="text" component={Input} />
                    <Field className="form-control" name="shopcada_api_key" label="API Key" placeholder="" type="text" component={Input} />
                    <Field className="form-control" name="shopcada_authentication_token" label="Auth Token" placeholder="" type="text" component={Input} />
                    <Field className="form-control" name="shopcada_username" label="Username" placeholder="" type="text" component={Input} />
                    <Field className="form-control" name="shopcada_password" label="Password" placeholder="" type="password" component={Input} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mx-auto">
              <Button
                  variant="primary"
                  className="shadow-sm btn-br-6"
                  disabled={submitting}
                  type="submit"
                  onClick={this.onClickSubmit}
                >
                  {submitting ? 'SUBMITTING' : 'SUBMIT'}
              </Button>
            </Row>
            { showMarkupModal &&
            <Modal style={{top: '15%'}} show={true} onHide={() => this.setState({ showMarkupModal: false })} dialogClassName="confirm-modal">
              <Modal.Header closeButton>
                <h5 className="mb-0">Markup</h5>
              </Modal.Header>
              <Modal.Body className="d-grid gap-4">
                <p>Do you want to update markup for {item.name}'s products?</p>
                <div className="d-flex justify-content-end">
                  <Button variant="danger-secondary" className='me-1' onClick={handleSubmit(data => this.submit(data, false))}>No</Button>
                  <Button className='me-1' onClick={handleSubmit(data => this.submit(data, true))}>Yes</Button>
                </div>
              </Modal.Body>
            </Modal>
            }
            </form>
          </div>
          </>
          :
          null

        }
      </div>
    );
  }
}

Merchant = reduxForm({
  form: 'merchant',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true,
  // asyncValidate,
})(Merchant);

const defaultObject = {};
const selector = formValueSelector('merchant')

export default connect((state, props) => {
  const { match } = props;
  // const commissionTypeValue = selector(state, 'commissionType');

  let merchantOptions = _.map(state.merchantsByCountry.items, (merchant) => {
    return {"key":merchant,"value":merchant,"name":merchant}
  });

  return {
    merchantRate: state.merchantRate,
    // commissionTypeValue,
    initialValues: state.merchantRate.item,
    merchantOptions,
    users: state.users,
    contact_method_value: selector(state, 'operations_contact_method')
  };
}, {
  ...merchantRateActionCreators,
  ...usersActionCreators,
  ...merchantsByCountryActionCreators
})(Merchant);
