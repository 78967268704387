import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Alert, Button, Row, Col } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import Input from '../components/Input';
import * as authActionCreators from '../redux/actions/auth';
import Lottie from 'react-lottie';
import loginAnimation from '../lotties/login-lottie.json';
import LoginNavbar from '../components/LoginNavbar';
import loginText from '../images/giftr-login.svg';
import moved from '../images/wehavemoved.jpg';

const hostname = window.location.hostname.includes('my') ? 'seller.giftr.my' : 'seller.giftr.sg'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loginAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
}

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors
}

class Login extends Component {
  submit = (values) => {
    const { email, password } = values;
    const { history: { push }, login } = this.props;
    return new Promise((resolve, reject) => {
      login(email, password, push).then(() => {
        return resolve();
      });
    });
  }

  render() {
    const { handleSubmit, submitting, globalError } = this.props

    if(window.location.hostname === 'merchant.giftr.my' || window.location.hostname === 'merchant.giftr.sg'){
      return (
        <div className="login-backdrop">
          <Row className="flex-column-reverse flex-lg-row w-100 ms-0 pt-5" id="pwd-container">
            <Col lg={6} className="d-flex flex-column">
              <div className="px-3 text-center" style={{height: '56%', margin: '90px 0 90px auto'}}>
                <img alt="Move house vector created by pch.vector - www.freepik.com" src={moved} style={{maxWidth: '100%', borderRadius: '10px', maxHeight: '100%'}}/>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className="login-form desktop-login d-grid gap-3 mx-3 shadow-sm" onSubmit={handleSubmit(this.submit)}>
                <h4 className="my-3" style={{color:'#000'}}>We Have Moved</h4>
                <p>We've migrated Giftr Seller Center access link to <a href={`https://${hostname}`}>{hostname}</a></p>
                <p>Please bookmark the new link as we will be rolling out new features and developments at the new portal to serve you better! Thanks for your understanding. 🤗</p>
                <a href={`https://${hostname}`}>
                <Button variant="primary">Click Here to New Giftr Seller Center</Button>
                </a>
                  <h5 className='mt-5' id="gifting-made-easy" >- Gifting Made Easy -</h5>
              </div>
              </Col>
          </Row>
        </div>
      )
    }

    return (
      <div className="login-backdrop vh-100">
        <LoginNavbar></LoginNavbar>
        <Row className="flex-column-reverse flex-lg-row w-100 ms-0 mt-5" id="pwd-container">
          <Col lg={7} className="lottie-col">
            <img src={loginText}/>
            <Lottie options={defaultOptions} />
            <span className="d-block d-lg-none mt-4" style={{fontSize: 'smaller',color: '#5A5A5A'}}>&copy; 2022 All Rights Reserved by Giftr</span>
          </Col>
          <Col xs={12} lg={5} className="text-center">
            <form className="login-form desktop-login d-grid gap-3 mx-3 shadow-sm" onSubmit={handleSubmit(this.submit)}>
              <h4 className="mt-3 mb-5" style={{color:'#000'}}>Login to Seller Center</h4>
              {
                globalError &&
                <Alert variant="danger">
                  <div>Incorrect login credentials.</div>
                </Alert>
              }
              <Field className="form-control" name="email" label="Email" placeholder="Email" type="email" isRenderLabel={false} component={Input} />
              <Field className="form-control" name="password" label="Password" placeholder="Password" type="password" isRenderLabel={false} component={Input} />
              <div style={{textAlign:'right'}}>
                <a href="./forgot_password" target="_blank" style={{textDecoration: 'none', color: '#80d7cf', fontSize: '0.9em'}}>Forgot password?</a>
              </div>
              <Button
                type="submit"
                disabled={submitting}
                className="mt-5"
              >
                {submitting ? 'LOGGING IN...' : 'LOG IN'}
              </Button>
            </form>
            </Col>
        </Row>
      </div>
    );
  }
}

Login = reduxForm({
  form: 'login',
  validate,
  destroyOnUnmount: true,
  // forceUnregisterOnUnmount: true,
  // asyncValidate,
  // asyncBlurFields: ['postCode']
})(Login);

export default connect(state => {
  return {
    globalError: state.error,
  }
}, authActionCreators)(Login);
