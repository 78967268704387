import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class GTRModal extends Component {
  handleOnHideModal = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const { title, children } = this.props;
    return (
      <Modal show={true} onHide={this.handleOnHideModal} size="lg">
        <Modal.Header closeButton>
          {
            title &&
            <Modal.Title>{title}</Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
      </Modal>
    );
  }
}

export default GTRModal;
