import qs from "qs";
import { addError } from './error';
import api from '../../helpers/apiClient';
import _ from 'lodash'

export const FETCH_PRODUCT_BULK_REQUEST = 'FETCH_PRODUCT_BULK_REQUEST';
export const FETCH_PRODUCT_BULK_SUCCESS = 'FETCH_PRODUCT_BULK_SUCCESS';
export const FETCH_PRODUCT_BULK_FAILURE = 'FETCH_PRODUCT_BULK_FAILURE';
export const FETCH_PRODUCT_BULK_UPDATE = 'FETCH_PRODUCT_BULK_UPDATE';

function fetchProductsRequest(ids) {
  return {
    type: FETCH_PRODUCT_BULK_REQUEST,
    payload: {
      ids
    }
  };
}

function fetchProductsSuccess(json) {
  return {
    type: FETCH_PRODUCT_BULK_SUCCESS,
    payload: {
      items: json,
      // next: json.next,
      receivedAt: Date.now(),
    }
  };
}

function fetchProductsUpdate(json) {
  return {
    type: FETCH_PRODUCT_BULK_UPDATE,
    payload: {
      items: json
    }
  };
}


function fetchProductsFailure() {
  return {
    type: FETCH_PRODUCT_BULK_FAILURE
  };
}

function shouldFetchProducts(state) {
  const results = state.productBulkEdit;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

function fetchProductsFromApi(ids) {
  const queryString = qs.stringify({
    ids
  }, { skipNulls: true });
  return dispatch => {
    dispatch(fetchProductsRequest(ids));
    return api(`/products?${queryString}`)
      .then(json => {

        let items = [];
        for(let i = 0; i < json.length; i++) {
          let item = json[i];

          if(item.variants.length == 1){
            let variant = item.variants[0];

            item.price = item.price = (variant.metafields && _.find(variant.metafields, {key: "base_price"})) ?
            _.find(variant.metafields, {key: "base_price"}).value : variant.price;
            item.compare_at_price = variant.compare_at_price
            item.sku = variant.sku
            item.weight = variant.weight
            item.include_delivery = item.weight <= 0.01 ? 'true' : 'false'
            items.push(item);
          } else if(item.variants.length > 1) {
            items.push(item)
            for(let j = 0; j < item.variants.length; j++) {
              let variant = item.variants[j];
              variant.parent_id = item.id;
              const basePriceMetafield = variant.metafields?.find(m => m.key === "base_price");
              variant.price = basePriceMetafield?.value ?? variant.price;
              variant.include_delivery = variant.weight <= 0.01 ? 'true' : 'false'
              
              items.push(variant);
            }
          }
        }
        dispatch(fetchProductsSuccess(items));
      })
      .catch(err => {
        dispatch(fetchProductsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchProducts(ids) {
  return (dispatch, getState) => {
    if (shouldFetchProducts(getState())) {
      return dispatch(fetchProductsFromApi(ids));
    }
  }
}

export function saveProduct(id, isStart) {
  return (dispatch, getState) => {
    let results = getState().productBulkEdit;
    
    // iterate results and find the product with the id
    let items = _.cloneDeep(results.items);
    
    for(let i = 0; i < items.length; i++) {

      let item = items[i]
      if(item.id === id && isStart) {
        item.saving = true
      } else {
        item.saving = false;
      }
    }

    return dispatch(fetchProductsUpdate(items));
  }
}
