import {
  FETCH_MANUAL_ORDER_DETAIL_REQUEST,
  FETCH_MANUAL_ORDER_DETAIL_SUCCESS,
  FETCH_MANUAL_ORDER_DETAIL_FAILURE,
  CLEAR_MANUAL_ORDER_DETAIL,
} from '../actions/manualOrderDetail';

const defaultState = {
  loading: false,
  loaded: false,
  orderId: null,
  item: null
};

export default function manualOrders(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_MANUAL_ORDER_DETAIL:
      return {
        ...state,
        defaultState
      };
    case FETCH_MANUAL_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        orderId: action.payload.orderId
      };
    case FETCH_MANUAL_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.payload.item,
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_MANUAL_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
