import { 
  FETCH_MERCHANTRATE_REQUEST, 
  FETCH_MERCHANTRATE_SUCCESS, 
  FETCH_MERCHANTRATE_FAILURE, 
  CLEAR_MERCHANTRATE,
} from '../actions/merchantRate';

const defaultState = {
  loading: false,
  loaded: false,
  item: null,
};

export default function merchantRate(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_MERCHANTRATE:
      return {
        ...state,
        defaultState
      };
    case FETCH_MERCHANTRATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_MERCHANTRATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.payload.item,
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_MERCHANTRATE_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}