import {
  FETCH_LOGS_REQUEST,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_FAILURE
} from '../actions/logs';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
  email: null,
  limit: null,
  vendor: null,
  type: null,
  title: null,
  role: null
};

export default function logs(state = defaultState, action) {
  switch (action.type){
    case FETCH_LOGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_LOGS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
