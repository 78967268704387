import React, { Component } from "react";
import { connect } from "react-redux";
import qs from "qs";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button, Alert, Form, Badge, Accordion, Container, Col } from "react-bootstrap";
import Loader from "../components/Loader";
import MobileDetect from "mobile-detect";
import VendorSelection from "../components/VendorSelection";
import * as ordersActionCreators from "../redux/actions/orders";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import api from "../helpers/apiClient";
import ConfirmModal from "../components/ConfirmModal";
import ReactTooltip from "react-tooltip";
import CreateManualOrder from "./CreateManualOrderModal";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, } from '@fortawesome/fontawesome-svg-core/import.macro'
import promptIcon from '../images/adminPrompt.svg';
import TabHeader from '../components/TabHeader';
import ShippingModal from '../components/ShippingModal';
import MergedShippingModal from '../components/MergedShippingModal';
import Promise from "bluebird";

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;
const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

const fulfillmentStatusOptions = {
  any: "Any",
  fulfilled: "Fulfilled",
  unfulfilled: "Unfulfilled",
  partial: "Partial",
  cancelled: "Cancelled",
};

const financialStatusOptions = {
  any: "Any",
  authorized: "Authorized",
  pending: "Pending",
  paid: "Paid",
  partially_paid: "Partially Paid",
  refunded: "Refunded",
  voided: "Voided",
  unpaid: "Unpaid",
};

class Orders extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const { location } = props;
    let financialStatus = "any";
    let fulfillmentStatus = "any";
    let vendor = "any";
    let startDate, endDate;
    let orderPeriodStart = moment().subtract(2, 'months')
    let orderPeriodEnd = moment()
    // let order_period =
    //   user.role === "admin" || user.role === "staff" ? "2" : "3";
    let forceSyncClicked = false;
    let selectedIds = [];
    let selectedKeys = [];
    let showConfirmBulkFulfillModel = false;
    let showWarningModal = false;
    let showBulkFulfillSuccess = false;
    let showCreateManualOrder = false;
    let showCreateManualOrderSuccess = false;
    let createManualOrderId = "";
    let orderId = ""
    if (location.search) {
      const { financial_status, fulfillment_status } = qs.parse(
        location.search.substr(1)
      );
      const queryString = qs.parse(location.search.substr(1));

      if (queryString.vendor) {
        vendor = queryString.vendor;
      }
      if (queryString.financial_status) {
        financialStatus = queryString.financial_status;
      }
      if (queryString.fulfillment_status) {
        fulfillmentStatus = queryString.fulfillment_status;
      }
      if (queryString.start_date) {
        startDate = moment(queryString.start_date, "D-M-YY");
      }
      if (queryString.end_date) {
        endDate = moment(queryString.end_date, "D-M-YY");
      }
      if (queryString.order_period_start) {
        orderPeriodStart = moment(queryString.order_period_start, "D-M-YY");
      }
      if (queryString.order_period_end) {
        orderPeriodEnd = moment(queryString.order_period_end, "D-M-YY");
      }
      if (queryString.order_id) {
        orderId = queryString.order_id
      }
    }

    this.state = {
      financialStatus,
      fulfillmentStatus,
      vendor,
      startDate,
      endDate,
      forceSyncClicked,
      selectedIds,
      selectedKeys,
      showConfirmBulkFulfillModel,
      showBulkFulfillSuccess,
      showCreateManualOrder,
      showCreateManualOrderSuccess,
      createManualOrderId,
      showWarningModal,
      orderPeriodStart,
      orderPeriodEnd,
      orderId
    };
  }

  componentDidMount() {
    const { fetchOrders, location, user, fetchMerchants } = this.props;
    const { orderPeriodStart, orderPeriodEnd } = this.state;
    const {
      merchantId,
      financial_status,
      fulfillment_status,
      limit,
      createdAtMax,
      start_date,
      end_date,
      vendor,
      order_period_start,
      order_period_end,
      order_id
    } = qs.parse(location.search.substr(1));


    // const defaultOrderPeriod =
    //   user.role === "admin" || user.role === "staff" ? 2 : 3;

    if (!vendor && ["admin", "staff"].includes(user.role)) {
      this.setState({
        adminPrompt: true,
      });
    } else {
      fetchOrders(
        merchantId ? merchantId : vendor,
        financial_status,
        fulfillment_status,
        limit,
        createdAtMax,
        start_date,
        end_date,
        order_period_start ? order_period_start : orderPeriodStart.format("D-M-YY"),
        order_period_end ? order_period_end : orderPeriodEnd.format("D-M-YY"),
        order_id
      );
    }
  }
  //fetchOrders(merchantId ? merchantId : vendor, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date, order_period ? order_period : defaultOrderPeriod)

  componentWillReceiveProps(nextProps) {
    const { location: prevLocation, user } = this.props;
    const { orderPeriodStart, orderPeriodEnd } = this.state;
    const { fetchOrders, location } = nextProps;
    if (location.search !== prevLocation.search) {
      const {
        merchantId,
        financial_status,
        fulfillment_status,
        limit,
        createdAtMax,
        start_date,
        end_date,
        vendor,
        order_period_start,
        order_period_end,
        order_id
      } = qs.parse(location.search.substr(1));

      let adminPrompt = false;
      if (!vendor && ["admin", "staff"].includes(user.role)) {
        adminPrompt = true;
      }

      this.setState({
        adminPrompt,
        vendor: vendor || "any",
        financialStatus: financial_status || "any",
        fulfillmentStatus: fulfillment_status || "any",
      });

      if (!adminPrompt) {
        fetchOrders(
          merchantId ? merchantId : vendor,
          financial_status,
          fulfillment_status,
          limit,
          createdAtMax,
          start_date,
          end_date,
          order_period_start ? order_period_start : orderPeriodStart.format("D-M-YY"),
          order_period_end ? order_period_end : orderPeriodEnd.format("D-M-YY"),
          order_id
        );
      }

      // fetchOrders(merchantId ? merchantId : vendor, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date, order_period ? order_period : 3);
    }
  }

  renderDetailLink = (cell, row) => {
    return (
      <Link
        key={row.id}
        to={{
          pathname: row.manual_order
            ? `/manual_orders/${row.manual_order_id}`
            : `/orders/${row.id}`,
          // state: {modal: true}
        }}
      >
        {row.name}
      </Link>
    );
  };

  renderEscape = (cell, row) => {
    return _.replace(cell, /(?:\r\n|\r|\n)/g, " ");
  };

  renderFulfilledDate = (cell, row) => {
    let deliveryDate = this.getFulfilledDate(row);
    if (deliveryDate) {
      return deliveryDate.format("DD/MM/YYYY");
    }
  };

  renderShipOnTime = (cell, row) => {
    if (!row.line_item_fulfilled) {
      return;
    }

    let fulfilledDate = this.getFulfilledDate(row);

    if (row.delivery) {

      const deliveryMoment = moment(row.delivery, "DD/MM/YYYY").utcOffset(8);

      //Conditions for on time delivery
      //1. No delivery cutoff and fulfillment is before 5pm
      //2. Delivery cutoff is 12pm or 1pm and fulfillment is before 5pm
      //3. Delivery cutoff is not 12pm or 1pm and fulfillment is before end of day
      if ((!row.cutoff && fulfilledDate.isSameOrBefore(deliveryMoment.hour(17))) ||
      (row.cutoff && (row.cutoff === '12pm' || row.cutoff === '1pm') && fulfilledDate.isSameOrBefore(deliveryMoment.hour(17))) ||
      (row.cutoff && (row.cutoff !== '12pm' || row.cutoff !== '1pm') && fulfilledDate.isSameOrBefore(deliveryMoment.endOf('day')))) {
        return true
      } else {
        return false
      }
      return fulfilledDate.isSameOrBefore(
        moment(row.delivery, "DD/MM/YYYY").utcOffset(8).hour(17)
      );
    } else if (row.line_item_ship_by) {
      return fulfilledDate.isSameOrBefore(
        moment(row.line_item_ship_by, "DD/MM/YYYY").utcOffset(8),
        "day"
      );
    }
  };

  getFulfilledDate = (row) => {
    let fulfilledDate;

    if (row.line_item_fulfilled) {
      _.forEach(row.fulfillments, (fulfillment) => {
        if (fulfillment.status === "success") {
          _.forEach(fulfillment.line_items, (lineItem) => {
            if (lineItem.id === row.line_item_id) {
              fulfilledDate = moment(fulfillment.created_at);
            }
          });
        }
      });
    }

    return fulfilledDate;
  };

  renderFulfillmentStatus = (cell, row) => {
    if (row.cancelled_at || row.line_item_cancelled || row.fulfillment_status == 'cancelled') {
      return (
        <Badge bg="danger text-uppercase">Cancelled</Badge>
      );
    } else if (_.lowerCase(row.fulfillment_status) === "fulfilled") {
      return (
        <Badge bg="success text-uppercase">
          {row.fulfillment_status || "Unfulfilled"}
        </Badge>
      );
    } else if (row.fulfillment_status) {
      return (
        <Badge bg="warning text-uppercase">
          {row.fulfillment_status}
        </Badge>
      );
    } else {
      return (
        <Badge bg="warning text-uppercase">Unfulfilled</Badge>
      );
    }
  };

  renderItemsName = (cell, row) => {
    return <span key={`name_${row.line_item.id}`}>{row.line_item.name}</span>;
  };

  renderItemsDelivery = (cell, row) => {
    let shouldAlert = moment(row.line_item.delivery, "DD/MM/YYYY").isBefore(
      moment(),
      "day"
    );

    if (
      shouldAlert &&
      _.lowerCase(row.fulfillment_status) !== "fulfilled" &&
      !row.line_item_fulfilled
    ) {
      return (
        <span className="ship-by-alert" key={`delivery_${row.line_item.id}`}>
          {row.line_item.delivery}
        </span>
      );
    }

    return (
      <span key={`delivery_${row.line_item.id}`}>{row.line_item.delivery}</span>
    );

    {
      /*return (<div className="order-list-items">{
      row.line_items.map((item, i) => {
        return <span key={`delivery_${row.id}_${i}`}>{item.delivery}</span>
      })
      }</div>)*/
    }
  };

  renderShipBy = (cell, row) => {
    let shouldAlert = moment(
      row.line_item.ship_by,
      "DD/MM/YYYY"
    ).isSameOrBefore(moment(), "day");

    if (
      shouldAlert &&
      _.lowerCase(row.fulfillment_status) !== "fulfilled" &&
      !row.cancelled_at &&
      !row.line_item_fulfilled
    ) {
      return (
        <span className="ship-by-alert" key={`delivery_${row.line_item.id}`}>
          {row.line_item.ship_by}
        </span>
      );
    }
    return (
      <span key={`delivery_${row.line_item.id}`}>{row.line_item.ship_by}</span>
    );
  };

  renderDeliveryStatus = (cell, row) => {
    if (cell === 'on time') {
      return (
        <Badge bg="success text-uppercase">{cell}</Badge>
      )
    } else if (cell === 'delayed') {
      return (
        <Badge bg="danger text-uppercase">{cell}</Badge>
      )
    } else {
      return (
        <Badge bg="warning text-uppercase">{cell}</Badge>
      )
    }
  }

  renderFinancialStatus = (cell, row) => {
    const { financial_status } = row
    if (_.lowerCase(financial_status) === "paid") {
      return (
        <Badge bg="success text-uppercase">{financial_status}</Badge>
      );
    } else if (
      _.lowerCase(financial_status) === "refunded" &&
      _.lowerCase(financial_status) === "partially refunded"
    ) {
      return (
        <Badge bg="danger text-uppercase">{financial_status}</Badge>
      );
    }

    return (
      <Badge bg="warning text-uppercase">{financial_status}</Badge>
    );
  };

  renderSelectFilter = (options, defaultValue, onChange) => {
    return (
      <Form.Select className="form-control" onChange={onChange} value={defaultValue}>
        {Object.keys(options).map((key) => {
          return (
            <option key={key} value={key}>
              {options[key]}
            </option>
          );
        })}
      </Form.Select>
    );
  };

  renderVendor = (options, defaultValue, onChange) => {
    return (
      <select className="form-control" onChange={onChange} value={defaultValue}>
        {options.map((option) => {
          return (
            <option key={option.key} value={option.value}>
              {option.name}
            </option>
          );
        })}
      </select>
    );
  };

  renderExpandedNoteColumn = (row) => {
    return <div>{row.note}</div>;
  };

  renderReadyToShip = (cell, row) => {
    if (!row.line_item_ready_to_ship) {
      return (
        <Badge bg="warning text-uppercase">Pending</Badge>
      );
    }
    return <Badge bg="success text-uppercase">Accepted</Badge>;
  };

  handleResetFilter = () => {
    const { user } = this.props;

    this.setState({
      vendor: "any",
      financialStatus: "any",
      fulfillmentStatus: "any",
      startDate: null,
      endDate: null,
      orderPeriodStart: moment().subtract(2, 'months'),
      orderPeriodEnd: moment()
    });
  }

  handleOnClickFilter = () => {
    const {
      history,
      location: { pathname },
    } = this.props;
    // const { fulfillmentStatus, financialStatus, startDate, endDate, vendor, orderPeriod} = this.state;
    const {
      fulfillmentStatus,
      financialStatus,
      startDate,
      endDate,
      orderPeriodStart,
      orderPeriodEnd,
      vendor,
      orderId
    } = this.state;

    let startDateShortFormat, endDateShortFormat;
    if (startDate) {
      startDateShortFormat = startDate.format("D-M-YY");
    }

    if (endDate) {
      endDateShortFormat = endDate.format("D-M-YY");
    }

    let orderPeriodStartShortFormat, orderPeriodEndShortFormat
    if (orderPeriodStart) {
      orderPeriodStartShortFormat = orderPeriodStart.format("D-M-YY");
    }

    if(orderPeriodEnd){
      orderPeriodEndShortFormat = orderPeriodEnd.format("D-M-YY");
    }

    const queryString = qs.stringify({
      // merchantId,
      start_date: startDateShortFormat,
      end_date: endDateShortFormat,
      financial_status: financialStatus,
      fulfillment_status: fulfillmentStatus,
      vendor,
      order_period_start: orderPeriodStartShortFormat,
      order_period_end: orderPeriodEndShortFormat,
      order_id: orderId
    });
    history.push(`${pathname}?${queryString}`);

    this.setState({ selectedIds: [], selectedKeys: [] });
  }

  handleEnter = e => {
    if (e.key === 'Enter') {
      this.handleOnClickFilter();
    }
  }

  handleOnClickForceSync = () => {
    this.setState({
      forceSyncClicked: true,
    });

    return api(`/orders/force/sync`, {
      method: "POST",
    }).then((res) => {
      console.log("sync", res);
    });
  };

  handleOnFulfillmentStatusChange = (e) => {
    this.setState({ fulfillmentStatus: e.target.value });
  };

  handleOnFinancialStatusChange = (e) => {
    this.setState({ financialStatus: e.target.value });
  };

  handleOnInputChange = (e) => {

    const { name, value, type } = e.target;

    this.setState({
      [name]: value
    });
  }

  // handleOnOrderPeriodChange = (e) => {
  //   this.setState({ orderPeriod: e.target.value });

  //   const { history, location: { pathname } } = this.props;
  //   const { fulfillmentStatus, financialStatus, startDate, endDate, vendor } = this.state;

  //   let startDateShortFormat, endDateShortFormat
  //   if(startDate){
  //     startDateShortFormat = startDate.format('D-M-YY')
  //   }

  //   if(endDate){
  //     endDateShortFormat = endDate.format('D-M-YY')
  //   }

  //   const queryString = qs.stringify({
  //     // merchantId,
  //     start_date: startDateShortFormat,
  //     end_date: endDateShortFormat,
  //     financial_status: financialStatus,
  //     fulfillment_status: fulfillmentStatus,
  //     vendor,
  //     order_period: e.target.value
  //   });

  //   console.log('e.target.value', e.target.value)
  //   history.push(`${pathname}?${queryString}`);

  // }

  handleOnVendorChange = (e) => {
    this.setState({ vendor: e.target.value });
  };

  deliveryDateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = "01/01/2010";
    }

    if (!date2) {
      date2 = "01/01/2010";
    }

    if (order === "desc") {
      return moment(date2, "DD/MM/YYYY").unix() - moment(date1, "DD/MM/YYYY").unix();
    } else {
      return moment(date1, "DD/MM/YYYY").unix() - moment(date2, "DD/MM/YYYY").unix();
    }
  };

  dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = "01/01/2010";
    }

    if (!date2) {
      date2 = "01/01/2010";
    }

    if (order === "desc") {
      return (
        moment(date2, "DD/MM/YYYY").unix() - moment(date1, "DD/MM/YYYY").unix()
      );
    } else {
      return (
        moment(date1, "DD/MM/YYYY").unix() - moment(date2, "DD/MM/YYYY").unix()
      );
    }
  };

  priceSort = (price1, price2, order) => {
    price1 = Number(price1);
    price2 = Number(price2);

    if (!price1) {
      price1 = 0
    }

    if (!price2) {
      price2 = 0
    }

    if (order === 'desc') {
      return price2 - price1
    } else {
      return price1 - price2
    }
  }

  onRowSelect = (row, isSelected, e) => {

    let rows = [row];
    if (row.line_item_aoid) {
      const { orders: { items } } = this.props;

      const foundLinkedItem = _.find(items, item => item.line_item_id !== row.line_item_id && item.line_item_aoid === row.line_item_aoid)

      if (foundLinkedItem) {
        rows.push(foundLinkedItem);
      }
    }

    let { selectedIds, selectedKeys } = this.state;

    if (isSelected) {
      _.each(rows, row => {
        selectedIds.push({
          order_id: row.id,
          item_id: row.line_item_id,
          id: row.line_item.id,
          order_name: row.name,
          email: row.email,
          delivery:row.delivery,
          order_status_url: row.order_status_url,
          note_attributes: row.note_attributes,
          line_item_name: row.line_item_name,
          shipping_address: row.shipping_address,
          shipping_fee: row.shipping_fee,
          grams: row.line_item_grams,
          price: row.line_item_price,
          vendor: row.line_item_vendor,
          addon: row.addon,
        })
        selectedKeys.push(row.line_item_id)
      })
    } else {
      _.each(rows, row => {
        selectedIds = _.reject(selectedIds, { item_id: row.line_item_id });
        selectedKeys = _.filter(selectedKeys, id => id !== row.line_item_id)
      })
    }

    this.setState({ selectedIds, selectedKeys });
  };

  onSelectAll = (isSelected, rows) => {
    let { selectedIds, selectedKeys } = this.state;

    if (isSelected) {
      _.each(rows, function (row) {
        selectedIds.push({
          order_id: row.id,
          item_id: row.line_item.id,
          id: row.line_item.id,
          order_name: row.name,
          email: row.email,
          delivery: row.delivery,
          order_status_url: row.order_status_url,
          note_attributes: row.note_attributes,
          line_item_name: row.line_item_name,
          shipping_address: row.shipping_address,
          shipping_fee: row.shipping_fee,
          grams: row.line_item_grams,
          price: row.line_item_price,
          vendor: row.line_item_vendor,
          addon: row.addon,
        })
        selectedKeys.push(row.line_item_id)
      })
    } else {
      _.each(rows, function (row) {
        selectedIds = _.reject(selectedIds, { item_id: row.line_item.id });
        selectedKeys = _.filter(selectedKeys, id => id !== row.line_item_id)
      });
    }

    this.setState({ selectedIds, selectedKeys });

    return true;
  };

  handleOnClickBulkFulfill = () => {
    const { selectedIds } = this.state;

    let containsNonD2D = false
    _.each(selectedIds, (item) => {

      if(!item.delivery && !item.addon){
        containsNonD2D = true
      }
    })

    if(containsNonD2D){
      this.setState({ showWarningModal: true });
    } else {
      this.setState({ showConfirmBulkFulfillModel: true });
    }
  };

  handleOnCloseBulkFulfillModal = () => {
    this.setState({ showConfirmBulkFulfillModel: false });
  };

  handleOnCloseWarningModal = () => {
    this.setState({ showWarningModal: false });
  }

  handleOnBulkfulfill = () => {
    const { selectedIds } = this.state;
    const { updateOrders } = this.props;

    this.setState({ loading: true });

    return api(`/orders/fulfill`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedIds),
    })
      .then((result) => {
        updateOrders(result);

        this.setState({
          showConfirmBulkFulfillModel: false,
          selectedIds: [],
          showBulkFulfillSuccess: true,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err) {
          window.scrollTo(0, 0);
          alert(err.message);
        }
      });
  };

  handleDismissBulkFulfill = () => {
    this.setState({ showBulkFulfillSuccess: false });
  };

  handleOnClickArrangeShipments = () => {
    const { selectedIds } = this.state;

    let filteredLineItems = _.filter(selectedIds, lineItem => {
      if (!lineItem.shipping_address) {
        return false;
      }

      const foundShipment = _.find(lineItem.note_attributes, note => {
        return note.name === `shipment_${lineItem.item_id}`
      });

      if (!foundShipment)
        return true;
    })

    filteredLineItems = _.map(filteredLineItems, lineItem => {
      return {
        ...lineItem,
        name: lineItem.line_item_name,
      }
    })

    let groupByOrder = {}
    _.each(filteredLineItems, lineItem => {
      if(groupByOrder[lineItem.order_id]){
        groupByOrder[lineItem.order_id].push(lineItem)
      } else {
        groupByOrder[lineItem.order_id] = [lineItem];
      }
    })

    let parcels = _.map(Object.values(groupByOrder), line_items => {
      let weight = _.reduce(line_items, (acc, lineItem) => {
        return acc + lineItem.grams;
      }, 0);

      let price = _.reduce(line_items, (acc, lineItem) => {
        return acc + parseFloat(lineItem.price);
      }, 0)

      let shipping_fee = _.find(line_items, line_item => line_item.vendor).shipping_fee

      return {
        order_id: line_items[0].order_id,
        order_name: line_items[0].order_name,
        shipping_address: line_items[0].shipping_address,
        shipping_fee: shipping_fee,
        weight: weight,
        line_items: line_items,
        value: price,
        vendor: line_items[0].vendor,
      }
    })

    this.setState({
      parcels,
      showShippingModal: true,
    });
  };

  handleOnClickMergeShipment = () => {
    const { selectedIds } = this.state;

    let filteredLineItems = _.uniqBy(_.filter(selectedIds, lineItem => {
      const foundShipment = _.find(lineItem.note_attributes, note => {
        return note.name === `shipment_${lineItem.item_id}`
      });

      if (!foundShipment)
        return true;
    }), 'id')

    filteredLineItems = _.map(filteredLineItems, (lineItem, i) => {
      return {
        ...lineItem,
        name: lineItem.line_item_name,
      }
    })

    let weight = _.reduce(filteredLineItems, (acc, lineItem) => {
      return acc + lineItem.grams;
    }, 0);

    let price = _.reduce(filteredLineItems, (acc, lineItem) => {
      return acc + parseFloat(lineItem.price);
    }, 0)

    let shipping_fee = _.reduce(filteredLineItems, (acc, lineItem) => {
      return acc + parseFloat(lineItem.shipping_fee);
    }, 0)

    let parcels = []
    if (filteredLineItems.length > 0) {
      const uniqShippingAddresses = _.uniqWith(_.map(filteredLineItems, item => {
        return {
          ...item.shipping_address,
          phone: item.shipping_address.phone.replace(/\s|-/g, ''),
        }
      }), _.isEqual);

      parcels.push({
        order_id: filteredLineItems[0].order_id,
        order_name: filteredLineItems[0].order_name,
        shipping_addresses: uniqShippingAddresses,
        shipping_fee: shipping_fee,
        weight: weight,
        line_items: filteredLineItems,
        value: price,
        vendor: filteredLineItems[0].vendor,
      });
    }

    this.setState({
      parcels,
      showMergedShippingModal: true,
    });
  };

  handleConfirmShipment = async (shipments, pickupDate, service) => {
    const { parcels } = this.state;
    const handleOnSaveNoteAttributes = this.handleOnSaveNoteAttributes

    if (parcels.length === 0) {
      return
    }

    return Promise.map(shipments, function (shipment) {
      let shipment_notes = []
      let parcel
      if (service === 'dhl') {
        parcel = _.find(parcels, parcel => {
          return shipment.dhlShipmentId.includes(parcel.order_name.replace(/\#|\,/g, ''))
        })

        let itemGroup = _.groupBy(parcel.line_items, function (item) {
          return item.order_id
        })

        const lineItemsSet = _.values(itemGroup)
        return Promise.map(lineItemsSet, async (lineItems) => {
          let shipment_notes = [];

          _.each(lineItems, item => {
            const { item_id } = item;

            shipment_notes.push({ name: `shipment_${item_id}`, value: `${shipment.dhlShipmentId}${pickupDate ? `_${pickupDate}` : ''}` })
          })

          return handleOnSaveNoteAttributes(shipment_notes, lineItems[0].note_attributes, lineItems[0].order_id)
        }, {concurrency: 1})

      } else if (service === 'easyparcel') {
        parcel = _.find(parcels, parcel => {
          return shipment['REQ_ID'] === parcel.order_name
        })

        _.each(parcel.line_items, item => {
          const { item_id } = item;

          if (shipment.messagenow === 'Payment Done') {
            shipment_notes.push({ name: `shipment_${item_id}`, value: `${shipment.parcel[0].awb}${pickupDate ? `_${pickupDate}` : ''}` })
          }
        })
      }

      return handleOnSaveNoteAttributes(shipment_notes, parcel.line_items[0].note_attributes, parcel.line_items[0].order_id)
    }, {
      concurrency: 1
    }).then(() => {
      const { fetchOrders, location } = this.props;
      const {
        merchantId,
        financial_status,
        fulfillment_status,
        limit,
        createdAtMax,
        start_date,
        end_date,
        vendor,
        order_period_start,
        order_period_end,
        order_id
      } = qs.parse(location.search.substr(1));
      fetchOrders(
        merchantId ? merchantId : vendor,
        financial_status,
        fulfillment_status,
        limit,
        createdAtMax,
        start_date,
        end_date,
        order_period_start,
        order_period_end,
        order_id
      );
    })
  }

  handleOnSaveNoteAttributes = async (notes, note_attributes, order_id) => {

    _.each(notes, note => {
      const { name, value } = note;
      const foundAttribute = _.find(note_attributes, n => n.name === name)

      if(foundAttribute){
        note_attributes = _.map(note_attributes, (attribute) => {
          return {
            name: attribute.name,
            value: attribute.name === name ? value : attribute.value
          }
        })
      } else {
        note_attributes.push({ name, value })
      }
    })

    let body = {
      note_attributes,
    }

    return api(`/orders/${order_id}/note_attribute`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
      }
    });

  }

  handleOnCloseShowShippingModal = () => {
    this.setState({
      showShippingModal: false,
    });
  }

  handleOnCloseMergedShippingModal = () => {
    this.setState({
      showMergedShippingModal: false,
    });
  }

  handleOnClickPrintMessages = () => {
    const { selectedIds } = this.state;

    let params = [], log = [];
    _.each(selectedIds, (line) => {
      params.push(line.order_id + "-" + line.item_id);
      log.push({order_id: line.order_id, order_name: line.order_name, line_item_id: line.item_id});
    });

    api(`/log/print/vm`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ line_items: log })
    })

    window.open(location.origin + "/messages?ids=" + params.join(","));
  };

  handleCreateManualOrder = () => {
    this.setState({ showCreateManualOrder: true });
  };

  handleOnCloseCreateManualOrder = () => {
    this.setState({ showCreateManualOrder: false });
  };

  handleOnSubmitCreateManualOrder = (result) => {
    this.setState({
      showCreateManualOrder: false,
      showCreateManualOrderSuccess: true,
      createManualOrderId: result.id,
    });
    const { fetchOrders, location, user, fetchMerchants } = this.props;
    const {
      merchantId,
      financial_status,
      fulfillment_status,
      limit,
      createdAtMax,
      start_date,
      end_date,
      vendor,
      order_period_start,
      order_period_end,
      order_id
    } = qs.parse(location.search.substr(1));
    fetchOrders(
      merchantId ? merchantId : vendor,
      financial_status,
      fulfillment_status,
      limit,
      createdAtMax,
      start_date,
      end_date,
      order_period_start,
      order_period_end,
      order_id
    );
  };

  handleDismissManualOrder = () => {
    this.setState({
      showCreateManualOrderSuccess: false,
      createManualOrderId: "",
    });
  };

  render() {
    const {
      orders: { loading, loaded, items, next },
      location: { pathname, search, routeState },
      previousRoutes,
      previousPreviousRoutes,
      previousRoutePathName,
      previousRouteSearch,
      user,
      merchantsOptions,
    } = this.props;
    const {
      fulfillmentStatus,
      financialStatus,
      vendor,
      forceSyncClicked,
      selectedIds,
      selectedKeys,
      showConfirmBulkFulfillModel,
      showBulkFulfillSuccess,
      showCreateManualOrder,
      showCreateManualOrderSuccess,
      createManualOrderId,
      adminPrompt,
      showWarningModal,
      orderId,
      showShippingModal,
      showMergedShippingModal,
      parcels,
    } = this.state;

    const isAdmin = user.role === "admin";
    const isStaff = user.role === "staff";
    const isMobile =
      new MobileDetect(window.navigator.userAgent).mobile() !== null;

    let unselectable = [];
    let virtualMessage = false;

    _.each(items, (item) => {
      const { line_item_id, line_item_fulfilled, line_item_cancelled, line_item_properties } = item
      const line_item_vm = _.find(line_item_properties, p => p.name === 'Audio/Video Message')

      if(line_item_fulfilled || line_item_cancelled){// || !item.delivery){
        unselectable.push(line_item_id)
      }
      if(line_item_vm){
        virtualMessage = true
      }
    });

    let selectRowProp = {
      mode: "checkbox",
      clickToSelect: true,
      selected: selectedKeys,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      nonSelectable: unselectable,
      headerColumnStyle: { width: '30px' },
    };

    const ordersCol = [
      {
        dataField: 'created_date',
        text: 'Date',
        sort: true,
        sortFunc: this.dateSort,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'created_at',
        text: 'Time',
        hidden: true,
        csvExport: false,
      },
      {
        dataField: 'name',
        text: 'Order Id',
        hidden: true,
      },
      {
        dataField: 'line_item_id',
        text: 'Line Item Id',
        hidden: true,
        csvExport: false,
      },
      {
        dataField: 'id',
        text: 'Order Id',
        csvExport: false,
        sort: true,
        formatter: this.renderDetailLink,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'receiver',
        text: 'Receiver',
        sort: true,
        headerStyle: {
          width: '170px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'financial_status',
        text: 'Paid',
        formatter: this.renderFinancialStatus,
        sort: true,
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'fulfillment_status',
        text: 'Fulfillment',
        formatter: this.renderFulfillmentStatus,
        sort: true,
        headerStyle: {
          width: '110px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'line_item_sku',
        hidden: true,
        text: 'Item',
        headerStyle: {
          resize: 'horizontal'
        }
      },
      {
        dataField: 'line_item_name',
        text: 'Items',
        hidden: isMobile,
        sort: true,
        formatter: this.renderItemsName,
        headerStyle: {
          width: '170px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'delivery',
        text: 'Delivery',
        sort: true,
        sortFunc: this.deliveryDateSort,
        formatter: this.renderItemsDelivery,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'line_item_ship_by',
        sort: true,
        sortFunc: this.deliveryDateSort,
        formatter: this.renderShipBy,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: '',
        headerFormatter: () => {
          return (
            <>
              <span data-tip="To be fufilled on or before this date.">
                Ship by <i className="fa fa-info-circle"></i>
              </span>
              <ReactTooltip/>
            </>
          )
        }
      },
      {
        dataField: 'delivery_status',
        hidden: !isAdmin && !isStaff,
        sort: true,
        formatter: this.renderDeliveryStatus,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: '',
        headerFormatter: () => {
          return (
            <>
              <span data-html="true" data-tip="<p>PENDING: Order is unfulfilled<br/>ON TIME: Order fulfillment is on time<br/>DELAYED: Order fulfillment is delayed</p>">
                Status <i className="fa fa-info-circle"></i>
              </span>
              <ReactTooltip/>
            </>
          )
        }
      },
      {
        dataField: 'address',
        hidden: true,
        text: 'Address',
      },
      {
        dataField: 'phone',
        hidden: true,
        text: 'Phone',
      },
      {
        dataField: 'line_item_vendor',
        hidden: !isAdmin && !isStaff,
        sort: true,
        text: 'Vendor',
        headerStyle: {
          width: '170px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'line_item_price',
        hidden: !isAdmin && !isStaff,
        sort: true,
        text: 'Price',
        sortFunc: this.priceSort,
        headerStyle: {
          width: '70px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'line_item_quantity',
        text: 'Qty',
        headerStyle: {
          width: '45px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'source',
        hidden: true,
        sort: true,
        text: 'Source',
      },
      {
        dataField: 'line_item_ready_to_ship',
        formatter: this.renderReadyToShip,
        text: 'Accepted',
        sort: true,
        headerStyle: {
          width: '95px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'fulfillments',
        csvText: 'Fulfill Date',
        hidden: true,
        csvFormatter: this.renderFulfilledDate,
        text: 'Fulfilled Date',
      },
      {
        dataField: 'fulfillments',
        csvText: 'Ship On Time',
        hidden: true,
        csvFormatter: this.renderShipOnTime,
        text: 'Fulfilled Date',
      },
      {
        dataField: 'feedback_sms_sent',
        hidden: !isAdmin && !isStaff,
        text: 'SMS Sent',
        sort: true,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'pod',
        hidden: !isAdmin && !isStaff,
        text: 'POD Image',
        sort: true,
        headerStyle: {
        csvFormatter: (cell) => cell || '',
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'line_item_vm',
        hidden: !isAdmin && !isStaff,
        text: 'Virtual Message',
        sort: true,
        csvFormatter: (cell) => cell || '',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'line_item_thanks',
        hidden: !isAdmin && !isStaff,
        text: 'Thanks',
        sort: true,
        csvFormatter: (cell) => cell || '',
        formatter: (cell) => {
          if (cell) {
            return <Badge bg="success text-uppercase">true</Badge>;
          }
        },
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'line_item_other',
        hidden: true,
        csvFormatter: this.renderEscape,
        text: 'Other',
      },
      {
        dataField: 'line_item_message',
        hidden: true,
        csvFormatter: this.renderEscape,
        text: 'Message',
      }
    ];

    const options = {
      showTotal: true,
      sizePerPage: 20,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: items.length
      }],
    };

    const searchPlaceholder = window.location.hostname === 'seller.giftr.my' ? 'GTR83000' : 'GSR3400'

    return (
      <div>
        <TabHeader title="Orders" tab_id="orders" user={user} />
        {showBulkFulfillSuccess && (
          <Alert variant="success" dismissible onClose={this.handleDismissBulkFulfill}>
            <p>Bulk fulfill success</p>
          </Alert>
        )}
        {showCreateManualOrderSuccess && (
          <Alert variant="success" dismissible onClose={this.handleDismissManualOrder}>
            <p>
              Created manual order.{" "}
              <a href={'/manual_orders/' + createManualOrderId}>
                Show
              </a>
            </p>
          </Alert>
        )}

        <Accordion style={{borderRadius: '18px'}} className="dashboard-card shadow-sm" defaultActiveKey="0">
          <Accordion.Item style={{borderRadius: '18px', border: 'none'}} eventKey="0">
            <Accordion.Header><h5 style={{marginBottom: '0'}}>Filters</h5></Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-xs-12 col-sm-5 col-xl-3 mb-3 mb-sm-3">
                      <label style={{marginBottom: '5px'}}>Fullfillment</label>
                      {this.renderSelectFilter(
                        fulfillmentStatusOptions,
                        fulfillmentStatus,
                        this.handleOnFulfillmentStatusChange
                      )}
                    </div>
                    <div className="col-xs-12 col-sm-5 col-xl-3 mb-3 mb-sm-3">
                      <label style={{marginBottom: '5px'}}>Financial Status</label>
                      {this.renderSelectFilter(
                        financialStatusOptions,
                        financialStatus,
                        this.handleOnFinancialStatusChange
                      )}
                    </div>
                    {(isAdmin || isStaff) && (
                      <div className="col-xs-12 col-sm-5 col-xl-4 mb-3 mb-sm-3" style={{textAlign: 'left'}}>
                        <VendorSelection
                          handleOnVendorChange={this.handleOnVendorChange}
                          selectedVendor={vendor}
                        />
                      </div>
                    )}

                  </div>
                  <div className="row">
                    <div className="d-grid col-xs-12 col-sm-10 col-xl-5 mb-3 mb-sm-3">
                      <label style={{marginBottom: '5px'}}>Delivery Date</label>
                      <DateRangePicker
                        small
                        minimumNights={0}
                        numberOfMonths={1}
                        displayFormat="D/M/YY"
                        showClearDates={true}
                        showDefaultInputIcon={true}
                        isOutsideRange={() => false}
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="delivery-date-start"
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="delivery-date-end"
                        onDatesChange={({ startDate, endDate }) =>
                          this.setState({ startDate, endDate })
                        } // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(focusedInput) =>
                          this.setState({ focusedInput })
                        } // PropTypes.func.isRequired,
                      />
                    </div>
                    <div className="d-grid col-xs-12 col-sm-10 col-xl-5 mb-3 mb-sm-3">
                      <label style={{marginBottom: '5px'}}>Order Period</label>
                      <DateRangePicker
                        small
                        minimumNights={0}
                        numberOfMonths={1}
                        displayFormat="D/M/YY"
                        showClearDates={false}
                        showDefaultInputIcon={true}
                        isOutsideRange={() => false}
                        startDate={this.state.orderPeriodStart} // momentPropTypes.momentObj or null,

                        startDateId="orders-period-start"
                        endDate={this.state.orderPeriodEnd} // momentPropTypes.momentObj or null,
                        endDateId="orders-period-end"
                        onDatesChange={({ startDate, endDate }) => {
                          this.setState({ orderPeriodStart: startDate, orderPeriodEnd: endDate })
                        }
                        } // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput1} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(focusedInput1) =>
                          this.setState({ focusedInput1 })
                        } // PropTypes.func.isRequired,
                      />
                    </div>

                  </div>
                </div>

                <div className="filter-buttons col-sm-3 d-flex flex-sm-column flex-lg-row justify-content-between my-2 mb-auto">
                <Button
                    className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                    variant="secondary"
                    style={{width: '100%'}}
                    onClick={this.handleResetFilter}
                  >Reset
                  </Button>
                <Button
                    className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                    variant="primary"
                    style={{width: '100%'}}
                    onClick={this.handleOnClickFilter}
                  >Apply
                  </Button>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap align-items-lg-end">
          {(isAdmin || isStaff) && (
              <Col sm="auto">
                <Button
                  variant="primary"
                  className="w-100 btn-br-6"
                  onClick={this.handleCreateManualOrder}
                >
                  Create Manual Order
                </Button>
              </Col>
            )}
          <Col sm="auto">
            <Button
              variant="secondary"
              disabled={selectedIds.length === 0}
              className="w-100 btn-br-6"
              onClick={this.handleOnClickBulkFulfill}
            >
              Bulk Fulfill <small>(For Door-to-door Delivery Only)</small>
            </Button>
          </Col>

          {virtualMessage && (
            <Col sm="auto">
              <Button
                variant="secondary"
                disabled={selectedIds.length === 0}
                className="w-100 btn-br-6"
                onClick={this.handleOnClickPrintMessages}
              >
                Print Message Card
              </Button>
            </Col>
          )}
          {
            false &&
              <Col sm="auto">
                <Button
                  variant="primary"
                  disabled={selectedIds.length === 0}
                  className="w-100 btn-br-6"
                  onClick={this.handleOnClickArrangeShipments}
                >
                  Arrange Shipments
                </Button>
              </Col>
          }
          { country === 'MY' &&
          <Col sm="auto">
            <Button
              variant="primary"
              disabled={selectedIds.length <= 1}
              className="w-100 btn-br-6"
              onClick={this.handleOnClickMergeShipment}
            >
              Merge Shipment
            </Button>
          </Col>
          }
          {/* <Col sm="auto">
            <Button
              variant="secondary"
              disabled={forceSyncClicked}
              className="w-100 btn-br-6"
              onClick={this.handleOnClickForceSync}
            >
              {(isAdmin || isStaff)
                ? forceSyncClicked
                  ? "Syncing... please refresh in 30 minutes"
                  : "Force Sync Last 1000 Orders"
                : forceSyncClicked
                ? "Syncing... please refresh in 5 minutes"
                : "Resync orders for past 7 days"}
            </Button>
          </Col> */}
          {
            (isAdmin || isStaff) &&
            <>
            <Col sm="auto" className="me-auto">
              <div className="d-flex flex-row align-items-end">
                <div className="me-2">
                  <label>Order ID</label>
                  <input type="text" className="form-control" id="orderId" name="orderId" placeholder={searchPlaceholder} value={orderId} onChange={this.handleOnInputChange} onKeyPress={this.handleEnter}/>
                </div>
                <div className="search-order-btn">
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={this.handleOnClickFilter}
                  >
                    Search Order
                  </Button>
                </div>
              </div>

            </Col>
            </>
          }
        </Container>
        <div className="clearfix"></div>
        {loading && <Loader />}
        {adminPrompt && (
          <div className="admin-prompt">
            <img src={promptIcon}></img>
            <h3 className="mb-0">Please select filter options</h3>
          </div>
        )}
        {!loading && items && !adminPrompt && (
          <>
            <ToolkitProvider
              keyField='line_item_id'
              data={items}
              columns={ordersCol}
              exportCSV={{
                exportAll: false
              }}
              search
            >
              {
                props => (
                  <>
                    <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                      <Col>
                        { !isStaff &&
                        <ExportCSVButton
                          className="btn-info"
                          style={{borderRadius:'8px'}}
                          { ...props.csvProps }
                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                          Export to CSV</ExportCSVButton>
                        }
                      </Col>
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps }
                        style={{borderRadius:'25px'}} placeholder="Search"/>
                      </Col>
                    </Container>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      selectRow={selectRowProp}
                      pagination={paginationFactory(options)}
                      hover
                      defaultSorted={[{
                        dataField: 'created_at',
                          order: 'desc',
                      }]}
                      noDataIndication={() => 'There is no data to display'}
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </>
        )}
        {showConfirmBulkFulfillModel &&
          <ConfirmModal
            onClickYes={this.handleOnBulkfulfill}
            onClose={this.handleOnCloseBulkFulfillModal}
            title="Bulk Fulfill"
          >
            <p>
              Are you sure you want to fulfill {selectedIds.length} item(s)?
            </p>
          </ConfirmModal>
        }
        {
          showWarningModal &&
          <ConfirmModal
            onClose={this.handleOnCloseWarningModal}
            noBtnTitle="Close"
            title="Cannot Bulk Fulfill">
            <p>The following line item(s) cannot be bulk fulfilled.<br/>
              <small><i>*Only door-to-door item can be bulk fulfilled.</i></small>
            </p>
            <ul>
            {
              _.map(selectedIds, (item) => {
                if(!item.delivery){
                  return (<li key={item.item_id}>{item.order_name}</li>)
                }
              })
            }
            </ul>
          </ConfirmModal>
        }
        {
          showCreateManualOrder &&
          <CreateManualOrder
            onClose={this.handleOnCloseCreateManualOrder}
            onSubmit={this.handleOnSubmitCreateManualOrder}/>
        }
        {
          showShippingModal &&
            <ShippingModal user={user} parcels={parcels} onClose={this.handleOnCloseShowShippingModal} confirmShipment={this.handleConfirmShipment} match={this.props.match}/>
        }
        {
          showMergedShippingModal &&
            <MergedShippingModal user={user} parcels={parcels} onClose={this.handleOnCloseMergedShippingModal} confirmShipment={this.handleConfirmShipment} match={this.props.match}/>
        }
      </div>
    );
  }
}

const defaultOptions = [{ key: "any", value: "any", name: "Any" }];

export default connect(
  (state, props) => {
    const { pathname, search, routeState } = props.location;
    let previousRoutePathName;
    let previousRouteSearch;
    if (
      routeState &&
      routeState.previousRoutes &&
      routeState.previousRoutes.length
    ) {
      const previousRoute =
        routeState.previousRoutes[routeState.previousRoutes.length - 1];
      previousRoutePathName =
        previousRoute.indexOf("?") > -1
          ? previousRoute.substr(0, previousRoute.indexOf("?"))
          : previousRoute;
      previousRouteSearch =
        previousRoute.indexOf("?") > -1
          ? previousRoute.substr(previousRoute.indexOf("?"))
          : null;
    }
    return {
      user: state.auth.user,
      orders: state.orders,
      previousRoutePathName,
      previousRouteSearch,
      previousRoutes:
        routeState &&
        routeState.previousRoutes &&
        routeState.previousRoutes.length
          ? [...routeState.previousRoutes, `${pathname}${search}`]
          : [`${pathname}${search}`],
      previousPreviousRoutes:
        routeState &&
        routeState.previousRoutes &&
        routeState.previousRoutes.length
          ? routeState.previousRoutes.slice(
              0,
              routeState.previousRoutes.length - 1
            )
          : null,
    };
  },
  { ...ordersActionCreators }
)(Orders);
