import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash'
import moment from 'moment';
import api from '../helpers/apiClient';
import { Button, Badge, Row, Col, Container } from 'react-bootstrap';
import Loader from '../components/Loader';
import 'react-dates/lib/css/_datepicker.css';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import * as notificationsActionCreators from '../redux/actions/notifications';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import MobileDetect from "mobile-detect";
import MobileTicketModal from '../components/MobileTicketModal';
import TabHeader from '../components/TabHeader';
import { Link } from 'react-router-dom';

const { ExportCSVButton } = CSVExport;
const { SearchBar, ClearSearchButton } = Search;

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'


class Tickets extends Component {
  constructor(props) {
    super(props);

    let selectTicketId = null;
    const { location } = this.props

    if (location.search) {
      const { ticket_id } = qs.parse(location.search.substr(1));

      if(ticket_id){
        selectTicketId = ticket_id
      }
    }

    this.state = {
      loading: true,
      tickets: [],
      selectTicketId,
      showMobileTicket: false,
      mobileTicket: {},
      submitting: false,
    };
  }

  componentDidMount() {
    return api(`/merchant_tickets`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      this.setState({ loading: false, tickets: result });
    }).catch(err => {
      this.setState({ loading: false });
    });
  }

  renderPrice = (cell, row) => {

    if(row.price){
      return `${currency} ${row.price}`
    }

    return
  }

  renderStatus = (cell, row) => {

    if(cell === 'solved'){
      return <Badge bg="success" className="text-uppercase">{cell}</Badge>
    } else if(cell === 'closed'){
      return <Badge bg="secondary" className="text-uppercase">{cell}</Badge>
    } else if(cell === 'new'){
      return <Badge bg="primary" className="text-uppercase">{cell}</Badge>
    } else if(cell === 'open'){
      return <Badge bg="info" className="text-uppercase">{cell}</Badge>
    } else if(cell === 'on-hold'){
      return <Badge bg="warning" className="text-uppercase">{cell}</Badge>
    }
  }

  handleSubmit = async (e) => {
    this.setState({ submitting: true })

    const isMobile = new MobileDetect(window.navigator.userAgent).mobile() !== null;
    const { openTicketsCount } = this.props;
    const { id, name } = e.target;
    const text = this.state[id];
    const { tickets } = this.state

    let body = {
      on_hold: false
    }

    if (name !== 'solved') {
      body.body = text;
    }

    if(name === 'on-hold'){
      body.on_hold = true
    }

    let result = await api(`/merchant_tickets/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })

    let newResult = []
    let mobileTicket

    for(let i = 0; i < tickets.length; i++) {

      if(tickets[i]._id === id){
        newResult.push(result)
        if(isMobile){
          mobileTicket = result
        }
      } else {
        newResult.push(tickets[i])
      }
    }

    this.setState({
      [id]: "",
      tickets: newResult,
      ...(mobileTicket && { mobileTicket }),
      submitting: false,
    });

    return api(`/merchant_open_tickets`, {
      method: 'GET'
    }).then(result => {
      openTicketsCount(result)
    })
  }

  handleOnInputChange = (e) => {

    const { value, id } = e.target;

    this.setState({
      [id]: value
    });
  }

  getIconColor = (str) => {

    let total = 0;
    let charlength = 0
    if(str && str.length){
      charlength = str.length
    }

    for(let i = 0; str && i < str.length; i++){
      total += str.charCodeAt(i);
    }

    if (charlength > 1) {
      while(total > 1){
        total = total/charlength
      }
    } else {
      total = 1 
    }

    return Math.floor(total*16777215).toString(16)
  }

  renderDateTime = (cell, row) => {

    if(!cell){
      return
    }

    return (
      <span>
        {moment(cell).format('DD/MM/YYYY HH:mm')}
      </span>
    )
  }

  renderFirstReply = (cell, row) => {

    if(!cell){
      return
    }

    return moment().subtract(cell, 'seconds').toNow()
  }

  renderReplies = (row) => {
    const { submitting } = this.state;
    const replyText = this.state[row._id]
    const isAdmin = this.props.user.role === 'admin'
    const isStaff = this.props.user.role === 'staff'

    const buyerReply = {
      "width": "auto",
      "maxWidth": "70%",
      "display": "inline-block",
      "backgroundColor": "#fff",
      "marginRight" : "auto",
      "marginLeft" : "0",
      "lineHeight": "1.6",
      "fontSize": "1.2em",
      "fontWeight": "400",
      "fontFamily": "Varela Round, sans-serif",
      "padding": "1em",
      "boxShadow": "none",
      "borderRadius": "10px"
    }

    const vendorReply = {
      "width": "auto",
      "maxWidth": "70%",
      "display": "inline-block",
      "backgroundColor": "#80d7cf",
      "lineHeight": "1.6",
      "fontSize": "1.2em",
      "marginRight" : "0",
      "marginLeft" : "auto",
      "fontWeight": "400",
      "color" : "#fff",
      "fontFamily": "Varela Round, sans-serif",
      "padding": "1em",
      "boxShadow": "none",
      "borderRadius": "10px"
    }

    const titleStyle = {
      "fontSize": "1.2em",
      "fontWeight": "300",
      "fontFamily": "Varela Round, Arial, sans-serif",
    }

    const iconStyle	= {
      "fontWeight": "600",
      "fontSize": "16px"
    }

    return  <div className="ticket-expanded d-flex flex-column-reverse">
      <Row className="justify-content-around my-2 mx-auto" style={{width: '95%'}}>
        <Col xs={7}>
          <input type="text" id={row._id} placeholder="Enter your reply here" value={replyText} onChange={this.handleOnInputChange} className="form-control"/>
        </Col>
        <Col xs="auto">
          <Button variant="primary"
           className="btn-br-6 mx-1"
            id={row._id}
            name="submit"
            onClick={this.handleSubmit}
            disabled={!replyText || submitting}
          >
            Submit as Solved
          </Button>
          <Button variant="danger-secondary"
              className="btn-br-6 mx-1"
            id={row._id}
            name="on-hold"
            onClick={this.handleSubmit}
            disabled={submitting}
          >
            Change Status to On-Hold
          </Button>
          { (isAdmin || isStaff) &&
          <Button variant="success"
            className="btn-br-6 mx-1"
            id={row._id}
            name="solved"
            onClick={this.handleSubmit}
            disabled={submitting}
          >
            Close Ticket
          </Button>
          }
        </Col>
      </Row>
      <Timeline lineStyle={{background: 'none'}}>
        <TimelineEvent
          title={row.created_by}
          key={row.created}
          createdAt={moment(row.created).format('DD/MM h:mm a')}
          iconColor={"#" + this.getIconColor(row.created_by)}
          icon={_.toUpper(row.created_by.substr(0, 1))}
          bubbleStyle={{background: '#fff', borderColor: '#7f35ff', color: '#7f35ff'}}
          titleStyle={titleStyle}
          contentStyle={buyerReply}
          iconStyle={iconStyle}
          >
            <div className="buyer-speech-bubble">
              <a href={row.link}>
                <Row className="align-items-center justify-content-start">
                  <Col xs="auto" className="pe-0">
                    <img target="_blank" src={row.product_image_url} alt={row.product_name} height="70px"/>
                  </Col>
                  <Col xs="auto">
                    <div style={{fontSize: 'smaller', whiteSpace: 'pre-line'}}>
                      {row.product_name}<br/>
                      {currency}{row.price}<br/>
                    </div>
                  </Col>
                </Row>
              </a>
              <hr/>
              <div style={{whiteSpace: 'pre-line', overflowWrap: 'break-word'}} dangerouslySetInnerHTML={{__html: row.body}}>
              </div>
            </div>
        </TimelineEvent>
      {
        row.replies.map(reply =>
          {
            if (reply.created_by == row.created_by){  //Buyer
              return (
                <TimelineEvent
                title={reply.created_by}
                key={reply.created}
                createdAt={moment(reply.created).format('DD/MM h:mm a')}
                iconColor={"#" + this.getIconColor(reply.created_by)}
                icon={_.toUpper(reply.created_by ? reply.created_by.substr(0, 1) : "A")}
                bubbleStyle={{background: '#fff', borderColor: '#7f35ff', color: '#7f35ff'}}
                titleStyle={titleStyle}
                contentStyle={buyerReply}
                iconStyle={iconStyle}>
                <div className="buyer-speech-bubble" style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: reply.body}}>
                </div>
              </TimelineEvent>
              )
            }
            else {  //Vendor
              return (
                <TimelineEvent
                  title={reply.created_by}
                  key={reply.created}
                  createdAt={moment(reply.created).format('DD/MM h:mm a')}
                  iconColor={"#" + this.getIconColor(reply.created_by)}
                  icon={_.toUpper(reply.created_by ? reply.created_by.substr(0, 1) : "A")}
                  bubbleStyle={{background: '#fff', borderColor: '#80d7cf', color: '#80D7CF', right: '0', left:"unset"}}
                  titleStyle={titleStyle}
                  contentStyle={vendorReply}
                  iconStyle={iconStyle}
                  style={{textAlign: 'right', paddingLeft: 'unset', paddingRight:'45px'}}>
                  <div className="vendor-speech-bubble" dangerouslySetInnerHTML={{__html: reply.body}}>
                  </div>
                </TimelineEvent>
              )
            }
          })
      }
      </Timeline>
    </div>

  }

  handleOnCloseTicket = () => {
    this.setState({
      mobileTicket: {},
      showMobileTicket: false,
    })
  }

  renderOrderDetailLink = (cell, row) => {
    if (cell && row.order_name) {
      return (
        <Link
          key={row._id}
          to={{
            pathname: `/orders/${cell}`,
          }}
          target="_blank"
        >
          {row.order_name}
        </Link>
      );
    }
  };

  render() {
    const { user } = this.props;
    const { loading, tickets, selectTicketId, showMobileTicket, mobileTicket } = this.state;

    const isAdmin = user.role === 'admin'
    const isStaff = user.role === 'staff'
    const isMobile =
      new MobileDetect(window.navigator.userAgent).mobile() !== null;

    let bootstrapTableOptions = {
      onFilterChange: this.handleOnFilterChange,
      expandBy: "column",
      sizePerPage: 20,
      paginationShowsTotal: true,
      sortName: "_id",
      sortOrder: "desc",
      clearSearch: true
    }

    if(selectTicketId){
      bootstrapTableOptions.defaultSearch = selectTicketId
    }

    const options = {
      showTotal: true,
      sizePerPage: 20,
    };

    const columns = [
      { dataField: '_id', text: 'Bill', hidden: true, },
      { dataField: 'ticket_id', text: 'Ticket Id', headerStyle: { width: '100px', resize: 'horizontal' }, },
      { dataField: 'status', headerStyle: { width: '100px', resize: 'horizontal' }, text: 'Status', sort: true, formatter: this.renderStatus, },
      { dataField: 'created', headerStyle: { width: '150px', resize: 'horizontal' }, text: 'Created', formatter: this.renderDateTime, sort: true },
      { dataField: 'updated', headerStyle: { width: '150px', resize: 'horizontal' }, text: 'Updated', formatter: this.renderDateTime, sort: true },
      { dataField: 'created_by', text: 'Created by', headerStyle: { width: '150px', resize: 'horizontal' }, sort: true },
      { dataField: 'product_name', text: 'Product', headerStyle: { width: '150px', resize: 'horizontal' }, formatter: this.renderDetailLink, sort: true },
      { dataField: 'email', text: 'Email', sort: true, headerStyle: { width: '200px', resize: 'horizontal' }, },
      { dataField: 'order_id', text: 'Order', sort: true, headerStyle: { width: '120px', resize: 'horizontal' }, formatter: this.renderOrderDetailLink, },
      { dataField: 'price', text: 'Price', sort: true, headerStyle: { width: '100px', resize: 'horizontal' }, formatter: this.renderPrice, },
      { dataField: 'body', text: 'Question', hidden: true, },
      { dataField: 'vendor', text: 'Merchant', sort: true, headerStyle: { width: '100px', resize: 'horizontal' }, hidden: !isAdmin && !isStaff, },
      { dataField: 'first_reply_time', text: 'First Reply', sort: true, headerStyle: { width: '110px', resize: 'horizontal' }, hidden: !isAdmin && !isStaff, formatter: this.renderFirstReply, },
    ];

    let expandRow = {}
    if(!isMobile){
      expandRow.renderer = this.renderReplies
    }

    let rowEvents = {}
    if(isMobile){
      rowEvents.onClick = (_, row) => {
        window.history.pushState("forward", null, "")

        this.setState({
          showMobileTicket: true,
          mobileTicket: row
        })
      }
    }

    return (
      <div className="tickets-table">
        <TabHeader title="Q&amp;A" tab_id="tickets" user={user} />
        {
          loading &&
          <Loader />
        }
        {
          !loading && tickets &&
            <ToolkitProvider
              keyField='_id'
              data={tickets}
              columns={columns}
              exportCSV={{
                exportAll: false
              }}
              search
            >
              {
                props => (
                  <>
                    <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                      <Col>
                        { !isStaff &&
                        <ExportCSVButton
                          className="btn-info"
                          style={{borderRadius:'8px'}}
                          { ...props.csvProps }
                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                        Export to CSV</ExportCSVButton>
                        }
                      </Col>
                      <Col xs={12} md={4} className="d-flex flex-row">
                        <SearchBar { ...props.searchProps }  style={{borderRadius:'25px'}}/>
                        <Button variant="clear" as={ClearSearchButton} { ...props.searchProps }/>
                      </Col>
                    </Container>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      pagination={paginationFactory(options)}
                      expandRow={expandRow}
                      rowEvents={rowEvents}
                      defaultSorted={[{
                        dataField: '_id',
                          order: 'desc',
                      }]}
                    />
                  </>
                )
              }
            </ToolkitProvider>
        }
        {
          showMobileTicket &&
            <MobileTicketModal ticket={mobileTicket} onClose={this.handleOnCloseTicket} setReplyText={this.handleOnInputChange} replyText={this.state[mobileTicket._id]} handleSubmit={this.handleSubmit} isMobile={isMobile} isAdmin={isAdmin} />
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    user: state.auth.user,
    tickets: state.tickets,
  };
}, {...notificationsActionCreators})(Tickets);
