import qs from "qs";
import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_SHIPMENTS_REQUEST = 'FETCH_SHIPMENTS_REQUEST';
export const FETCH_SHIPMENTS_SUCCESS = 'FETCH_SHIPMENTS_SUCCESS';
export const FETCH_SHIPMENTS_FAILURE = 'FETCH_SHIPMENTS_FAILURE';
export const CLEAR_SHIPMENTS = 'CLEAR_SHIPMENTS';

function fetchShipmentsRequest() {
  return {
    type: FETCH_SHIPMENTS_REQUEST
  };
}

function fetchShipmentsSuccess(json) { 
  return {
    type: FETCH_SHIPMENTS_SUCCESS,
    payload: {
      items: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchShipmentsFailure() {
  return {
    type: FETCH_SHIPMENTS_FAILURE
  };
}

function shouldFetchShipments(state) {
  const results = state.shipments;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

function fetchShipmentsFromApi() {
  // const queryString = qs.stringify({
  //   merchantId, 
  //   financial_status, 
  //   fulfillment_status, 
  //   limit, 
  //   createdAtMax,
  //   start_date,
  //   end_date
  // }, { skipNulls: true });
  return dispatch => {
    dispatch(fetchShipmentsRequest());
    return api(`/shipments`)
      .then(json => {
        dispatch(fetchShipmentsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchShipmentsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchShipments() {
  return (dispatch, getState) => {
    if (shouldFetchShipments(getState())) {
      return dispatch(fetchShipmentsFromApi());
    }
  };
}

export function clearShipments() {
  return {
    type: CLEAR_SHIPMENTS,
  };
}