import {
  FETCH_MERCHANTSTORE_REQUEST,
  FETCH_MERCHANTSTORE_SUCCESS,
  FETCH_MERCHANTSTORE_FAILURE,
} from '../actions/merchantStore';

const defaultState = {
  loading: false,
  loaded: false,
  item: null,
};

export default function merchantStore(state = defaultState, action) {
  switch (action.type) {
    case FETCH_MERCHANTSTORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_MERCHANTSTORE_SUCCESS:
      //console.log(action.payload)
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.payload.item,
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_MERCHANTSTORE_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
