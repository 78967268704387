import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from '../helpers/apiClient';
import * as errorActionCreators from '../redux/actions/error';
import OverlayLoader from '../components/OverlayLoader';
import TabHeader from '../components/TabHeader';
import { Card, Button, Badge, ButtonToolbar, Row, Col, Form } from 'react-bootstrap';
import moment from 'moment';
import LineItems from '../components/LineItems';
import MobileDetect from "mobile-detect";
import TrackingInfoModal from '../components/TrackingInfoModal';
import Fulfillment from '../components/Fulfillment';
import ConfirmModal from '../components/ConfirmModal';
import ShippingModal from '../components/ShippingModal';

const currency = 'RM'
const shopifyAdminDomain = window.location.hostname === 'seller.giftr.my' ? 'https://helpr-asia.myshopify.com/admin' : 'https://giftr-singapore.myshopify.com/admin'
const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

class ProcurementOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      item: null,
      selectedUnfulfilledItemIds: [],
      selectedFulfillableIds: [],
      isShowAddFulfillmentModal: false,
      selectedFulfillmentId: null,
      isShowConfirmCancelFulfillmentModal: false,
      showShippingModal: false,
    };
  }

  componentDidMount() {
    this.fetchOrderDetail();
  }

  fetchOrderDetail = (orderId) => {
    const { match } = this.props;

    this.setState({ loading: true });
    return api(`/procurement_orders/${match.params.orderId}`).then(result => {
      if (result) {
        this.setState({ loading: false, loaded: true, item: result });
      }
    })
  }

  handleOnCheckUnfulfilledItem = (selected) => {
    const { item: { note_attributes } } = this.state;

    let selectedFulfillableIds = []
    _.each(selected, selectedId => {
      const shipment = _.find(note_attributes, note => note.name === `shipment_${selectedId}`)

      if(!shipment || (shipment && shipment.value === "")){
        selectedFulfillableIds.push(selectedId)
      }
    })

    this.setState({
      selectedUnfulfilledItemIds: selected,
      selectedFulfillableIds,
    });
  }

  handleOnClickShowAddFulfillImentModal = () => {
    this.setState({ isShowAddFulfillmentModal: true });
  }

  handleOnCloseAddFulfillmentModal = () => {
    this.setState({ isShowAddFulfillmentModal: false });
  }

  handleOnClickFulfill = async (deliveryType, trackingCompany, trackingNumber, trackingUrl, file) => {
    const { match } = this.props;
    const { selectedUnfulfilledItemIds, item } = this.state;

    const orderStatusUrl = item.order_status_url;
    const name = item.name

    let file_url = ''
    if(file){
      file_url = await this.uploadFile(file)
    }

    return api(`/procurement_orders/${match.params.orderId}/fulfill`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deliveryType,
        itemIds: selectedUnfulfilledItemIds,
        trackingCompany,
        trackingNumber,
        trackingUrl,
        orderStatusUrl,
        name,
        file_url
      })
    }).then(fulfillment => {
      this.setState({
        isShowAddFulfillmentModal: false,
        selectedUnfulfilledItemIds: []
      })

      if(file_url){
        let body = {
          note_attributes: [
            ...item.note_attributes,
          ]
        }

        if (fulfillment.ids && fulfillment.ids.length) {
          _.each(fulfillment.ids, (id) => {
            body.note_attributes.push({
              "name": `proof_of_delivery_${id}`,
              "value": file_url
            })
          })
        }

        return api(`/procurement_orders/${match.params.orderId}/note_attribute`, {
          method: 'PUT',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(body)
        })
      }

    }).then(() => {
      this.fetchOrderDetail()
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          isShowAddFulfillmentModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    });
  }

  handleOnClickShowConfirmCancelFulfillmentModal = (fulfillmentId) => {
    this.setState({
      isShowConfirmCancelFulfillmentModal: true,
      selectedFulfillmentId: fulfillmentId
    });
  }

  handleOnCloseConfirmCancelFulfillmentModal = () => {
    this.setState({ isShowConfirmCancelFulfillmentModal: false });
  }

  handleOnClickCancelFulfillment = () => {
    const { match } = this.props;
    const { selectedFulfillmentId } = this.state;
    return api(`/procurement_orders/${match.params.orderId}/fulfillments/${selectedFulfillmentId}/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((order) => {
      this.setState({
        isShowConfirmCancelFulfillmentModal: false,
        selectedFulfillmentId: null,
      })
      this.fetchOrderDetail()
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          isShowConfirmCancelFulfillmentModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    });
  }

  handleOnClickShowShippingModal = async () => {
    const { selectedFulfillableIds, item } = this.state;
    const { user } = this.props;

    let line_items = item.line_items.filter(lineItem => { return selectedFulfillableIds.includes(lineItem.id.toString())})
    line_items = _.map(line_items, lineItem => {
      return {
        ...lineItem,
        order_id: item.id,
        order_name: item.name,
      }
    })

    let weight = _.reduce(line_items, (acc, lineItem) => {
      return acc + lineItem.grams;
    }, 0);

    let value = _.reduce(line_items, (acc, lineItem) => {
      return acc + parseFloat(lineItem.price);
    }, 0)

    let vendor = _.find(line_items, line_item => line_item.vendor).vendor

    let shipping_fee = 0;
    if(item.shipping_lines && item.shipping_lines[0]) {
      if(item.shipping_lines[0].breakdown) {
        let foundBreakdown = _.find(item.shipping_lines[0].breakdown, function (shippingBreakdown) {
          return vendor === shippingBreakdown.vendor
        })
        if (foundBreakdown) {
          shipping_fee = foundBreakdown.shipping_fee
        }
      } else {
        shipping_fee = item.shipping_lines[0].price
      }
    }

    const addresses = await api('/procurement_shipping_addresses?selected=1')

    let parcel = {
      order_id: item._id,
      order_name: item.name,
      shipping_address: addresses.length ? addresses[0] : null,
      shipping_lines: item.shipping_lines,
      shipping_fee: shipping_fee,
      weight: weight,
      line_items: line_items,
      value: value,
      vendor: vendor,
    }

    this.setState({
      showShippingModal: true,
      parcel: parcel
    });
  }

  handleOnCloseShowShippingModal = () => {
    this.setState({ showShippingModal: false });
  }

  handleConfirmShipment = async (shipments, pickupDate, service) => {
    const { parcel } = this.state;

    let shipment_notes = []

    if (service === 'dhl') {
      _.each(parcel.line_items, item => {
        const { id } = item;

        shipment_notes.push({ name: `shipment_${id}`, value: `${shipments[0].dhlShipmentId}${pickupDate ? `_${pickupDate}` : ''}` })
      })
    } else if (service === 'lalamove') {
      _.each(parcel.line_items, item => {
        const { id } = item;

        shipment_notes.push({ name: `shipment_${id}`, value: shipments.shareLink })
      })

      shipment_notes.push({ name: `lalamove_${parcel.line_items[0].id}`, value: shipments.priceBreakdown.total })
    } else if (service === 'easyparcel') {
      _.each(parcel.line_items, item => {
        const { id } = item;

        if (shipments[0].messagenow === 'Payment Done') {
          shipment_notes.push({ name: `shipment_${id}`, value: `${shipments[0].parcel[0].awb}${pickupDate ? `_${pickupDate}` : ''}` })
        }
      })
    }

    this.handleOnSaveNoteAttributes(shipment_notes)
  }

  handleOnSaveNoteAttributes = async (notes, is_remark = false) => {

    const { item } = this.state
    const { match } = this.props

    let note_attributes = [...item.note_attributes]

    _.each(notes, note => {
      const { name, value } = note;
      const foundAttribute = _.find(note_attributes, n => n.name === name)

      if(foundAttribute){
        note_attributes = _.map(note_attributes, (attribute) => {
          return {
            name: attribute.name,
            value: attribute.name === name ? value : attribute.value
          }
        })
      } else {
        note_attributes.push({ name, value })
      }
    })

    let body = {
      note_attributes,
      is_remark,
      order_name: item.name,
    }

    return api(`/procurement_orders/${match.params.orderId}/note_attribute`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).then(() => {
      this.setState({
        showRemarkModal: false,
        selectedFulfillmentId: null,
        selectedUnfulfilledItemIds: [],
        selectedFulfillableIds: [],
      })
      this.fetchOrderDetail();
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          showRemarkModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    });

  }

  handleOnClickMarkAsReady = () => {
    const { selectedUnfulfilledItemIds } = this.state;
    const { match } = this.props;

    return api(`/procurement_orders/${match.params.orderId}/ready_to_ship`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        itemIds: selectedUnfulfilledItemIds
      })
    }).then(() => {
      this.setState({
        selectedUnfulfilledItemIds: []
      })
      this.fetchOrderDetail();
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
      }
    });
  }

  handleOnClickShowEditFulfillmentModal = (fulfillmentId) => {
    this.setState({
      isShowEditFulfillmentModal: true,
      selectedFulfillmentId: fulfillmentId
    });
  }

  handleOnCloseEditFulfillmentModal = () => {
    this.setState({ isShowEditFulfillmentModal: false });
  }

  handleOnClickEditFulfillment = async (deliveryType, trackingCompany, trackingNumber, trackingUrl, file) => {
    const { match } = this.props;
    const { selectedFulfillmentId, item } = this.state;

    if(file) {
      const file_url = await this.uploadFile(file)

      let body = {
        note_attributes: [
          ...item.note_attributes,
          {
            "name": `proof_of_delivery_${selectedFulfillmentId}`,
            "value": file_url
          }
        ]
      }

      await api(`/procurement_orders/${match.params.orderId}/note_attribute`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })
    }

    return api(`/procurement_orders/${match.params.orderId}/fulfillments/${selectedFulfillmentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        trackingCompany,
        trackingNumber,
        trackingUrl
      })
    }).then(() => {
      this.setState({
        isShowEditFulfillmentModal: false,
        selectedFulfillmentId: null
      })
      this.fetchOrderDetail();
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          isShowEditFulfillmentModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    });
  }

  render() {
    const { error, user } = this.props;
    const { item, loading, loaded, selectedUnfulfilledItemIds, selectedFulfillableIds, isShowAddFulfillmentModal, isShowConfirmCancelFulfillmentModal, showShippingModal, parcel, isShowEditFulfillmentModal } = this.state;

    const isAdmin = user && (user.role === 'admin' || user.role === 'staff')
    const isMobile = new MobileDetect(window.navigator.userAgent).mobile() !== null;

    let merchantTotalItemPrice = 0, merchantShippingFeeBreakdown = [], merchantShippingFeeTotal = 0;
    if (item && user.merchantId){
      let merchantIds = user.merchantId.split('#,')

      merchantTotalItemPrice = _.reduce(item.line_items, (sum, lineItem) => {
        return sum + Number(lineItem.price * lineItem.quantity)
      }, 0)
      if (item.shipping_lines && item.shipping_lines[0] && item.shipping_lines[0].breakdown) {
        _.each(item.shipping_lines[0].breakdown, breakdown => {
          if (breakdown && (breakdown.vendor === merchantIds[0] || breakdown.vendor === merchantIds[1])) {
            merchantShippingFeeBreakdown.push(breakdown)
          }
        })
      }

      merchantShippingFeeTotal = _.sumBy(merchantShippingFeeBreakdown, breakdown => breakdown.shipping_fee)
    }

    return (
      <>
        {
          loading &&
          <OverlayLoader />
        }
        {
          !loading && item &&
          <>
          <TabHeader title="Procurement Order Detail" tab_id="procurement_order_detail" user={user} />
          <div className='d-grid gap-4'>
            <div className="d-none d-print-block">⚠️ KINDLY DO NOT PASS THIS ORDER SLIP TO RECEIVER</div>
            <Row>
              <Col sm={6} md={5} lg={4}>
              <Card className="dashboard-card shadow-sm p-2">
                <Card.Body>
                    <h4 className="mb-0" style={{color: '#80d7cf'}}>{item.name}</h4>
                    <p className="d-print-none my-3">placed on {moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                    {
                      item.cancelled_at &&
                      <Badge bg="danger" style={{lineHeight: '1.5em'}}>CANCELLED</Badge>
                    }
                  <div className="d-grid gap-1" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr)'}}>
                    {
                      isAdmin &&
                      <a href={`/orders/${item.order_id}`} className="btn-br-6 d-print-none btn btn-secondary btn-br-6">View B&G Order</a>
                    }
                  </div>
                </Card.Body>
              </Card>
              </Col>
              <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
              <Col>
              <Card className="d-print-none tealCard mt-4 mt-sm-0 shadow-sm">
                <Card.Header className="tealCardHeader">Order Info</Card.Header>
                <Card.Body className="grey-label">
                    <Row className="flex-row">
                      <Col lg={4} className="m-2">
                        <Row className="flex-lg-column w-100">
                          <Col xs={6} lg={12} className="d-grid mb-2">
                          <label>Financial Status</label>
                          <span className="text-uppercase">{item.financial_status}</span>
                          </Col>
                          <Col xs={6} lg={12} className="d-grid mb-2">
                            <label>Fulfillment Status</label>
                            <span className="text-uppercase" style={{color: item.fulfillment_status === 'fulfilled' ? '#4e8767' : '#ffc107'}}>{item.fulfillment_status}</span>
                          </Col>
                          {
                            item.cancelled_at &&
                            <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Order Status</label>
                              <span  className="text-uppercase" style={{color: '#d15d5d'}}>Cancelled</span>
                            </Col>
                          }
                          {
                            item.note &&
                            <Col  xs={6} lg={12} className="d-grid mb-2">
                              <label>Note</label>
                              <span style={{color: '#d15d5d'}}>{item.note}</span>
                            </Col>
                          }
                        </Row>
                      </Col>
                    <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
                      { isAdmin ?
                    <Col className="m-2 mt-2 my-lg-auto label-strong">
                        <Row>
                          <Col className="text-end"><label>Total Item Price</label></Col>
                          <Col>{currency} {item.current_subtotal_price}</Col>
                        </Row>
                        <Row>
                        <Col className='text-end'><label>Subtotal</label></Col>
                        <Col>{currency} {item.current_subtotal_price}</Col>
                        </Row>
                        {
                          item.shipping_lines && item.shipping_lines[0] && item.shipping_lines[0].price !== '0.00' &&
                          <Row>
                            <Col className='text-end'><label>Shipping Subtotal</label></Col>
                            <Col>{currency} {item.shipping_lines[0].price}</Col>
                          </Row>
                        }
                        <Row>
                          <Col className='text-end'><label>Total</label></Col>
                          <Col>{currency} {item.current_total_price}</Col>
                        </Row>
                        {
                          item.total_item_adjustment && item.total_item_adjustment !== '0.00' &&
                          <Row>
                          <hr/>
                            <Col className='text-end'><label>Item Price Adjustment</label></Col>
                            <Col>{currency} {item.total_item_adjustment}</Col>
                          </Row>
                        }
                        {
                          item.total_shipping_adjustment && item.total_shipping_adjustment !== '0.00' &&
                          <Row>
                            <Col className='text-end'><label>Shipping Adjustment</label></Col>
                            <Col>{currency} {item.total_shipping_adjustment}</Col>
                          </Row>
                        }
                    </Col>
                    :
                    <Col className="m-2 mt-2 my-lg-auto label-strong">
                        <Row>
                          <Col className="text-end"><label>Total Item Price</label></Col>
                          <Col>{currency} {merchantTotalItemPrice.toFixed(2)}</Col>
                        </Row>
                        {
                          item.merchant_discount &&
                          <Row>
                            <Col className='text-end'><label>Discounts</label></Col>
                            <Col>-{currency} {item.merchant_discount.amount} ({item.merchant_discount.code})</Col>
                          </Row>
                        }
                      { merchantShippingFeeBreakdown.length > 1 &&
                        <>
                          <Row>
                            <Col className='text-end'><label>Shipping Subtotal</label></Col>
                            <Col>{currency} {merchantShippingFeeTotal.toFixed(2)}</Col>
                          </Row>
                          {
                            merchantShippingFeeBreakdown.map(breakdown => (
                              <Row key={breakdown.vendor} className="shipping-lines">
                                <Col className='text-end'>Shipping Fee({breakdown.vendor})</Col>
                                <Col>{currency} { parseFloat(breakdown.shipping_fee).toFixed(2)}</Col>
                              </Row>
                            ))
                          }
                        </>
                      }
                      { merchantShippingFeeBreakdown.length === 1 &&
                      <Row>
                        <Col className='text-end'><label>Shipping Fee</label></Col>
                        <Col>{currency} { parseFloat(merchantShippingFeeBreakdown[0].shipping_fee).toFixed(2)}</Col>
                      </Row>
                      }
                        <Row>
                          <Col className='text-end'><label>Total</label></Col>
                          <Col>{currency} {(merchantTotalItemPrice + merchantShippingFeeTotal - (item.merchant_discount ? Number(item.merchant_discount.amount) : 0)).toFixed(2)}</Col>
                        </Row>
                        {
                          (item.total_item_adjustment || item.total_shipping_adjustment) && (item.total_item_adjustment !== '0.00' || item.total_shipping_adjustment !== '0.00') &&
                          <hr/>
                        }
                        {
                          item.total_item_adjustment && item.total_item_adjustment !== '0.00' &&
                          <Row>
                            <Col className='text-end'><label>Item Price Adjustment</label></Col>
                            <Col>{currency} {item.total_item_adjustment}</Col>
                          </Row>
                        }
                        {
                          item.total_shipping_adjustment && item.total_shipping_adjustment !== '0.00' &&
                          <Row>
                            <Col className='text-end'><label>Shipping Adjustment</label></Col>
                            <Col>{currency} {item.total_shipping_adjustment}</Col>
                          </Row>
                        }
                    </Col>
                      }
                  </Row>
                </Card.Body>
              </Card>
              </Col>
            </Row>
            <Row className="overflowX-auto">
              <Col>
              <Card className="tealCard shadow-sm">
                <Card.Header className="tealCardHeader">Items</Card.Header>
                <Card.Body className="mx-2 my-1">
                {
                  item.unfulfilled_items && item.unfulfilled_items.length ?
                  <>
                    <div className="mb-2">
                      <h4 className="d-inline-block mt-2" style={{color: '#FFC107'}}>UNFULFILLED</h4>
                      <ButtonToolbar className="pull-right d-print-none gap-3">
                        <Button className="btn-br-6" variant="secondary" disabled={!selectedUnfulfilledItemIds.length} onClick={this.handleOnClickMarkAsReady}>Mark As Accepted</Button>
                        { country === 'MY' &&
                        <Button className="btn-br-6" variant="primary" disabled={!selectedFulfillableIds.length} onClick={this.handleOnClickShowShippingModal}>Arrange Shipment</Button>
                        }
                        <Button className="btn-br-6" variant="primary" disabled={!selectedFulfillableIds.length} onClick={this.handleOnClickShowAddFulfillImentModal}>Mark selected items as Fulfilled</Button>
                      </ButtonToolbar>
                    </div>
                    <div className="clearfix"></div>
                    <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                      <LineItems onPrintMessage={this.handleOnClickPrintMessage} orderId={item.id} noteAttributes={item.note_attributes} fulfillmentStatus={item.fulfillment_status} user={user} items={item.unfulfilled_items} style={{width: '100%', whiteSpace: 'nowrap'}} onCheckItem={ !item.cancelled_at && this.handleOnCheckUnfulfilledItem} cancelledAt={item.cancelled_at} handleOnClickAdjustment={this.handleOnClickShowAdjustmentModal} onClickSaveAttribute={this.handleOnSaveNoteAttributes} selectedIds={selectedUnfulfilledItemIds} displaySection={"unfulfilled"} isMobile={isMobile} currency={currency}/>
                    </div>
                  </>
                  :
                  null
                }
                {
                  item.fulfilled_items && item.fulfilled_items.length ?
                  <>
                    <Row className="mb-2">
                    <Col lg={3}>
                      <h4 style={{color: '#4e8767'}}>FULFILLED</h4>
                    </Col>
                    </Row>
                    <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                    <LineItems onPrintMessage={this.handleOnClickPrintMessage} orderId={item.id} noteAttributes={item.note_attributes} displaySection={"fulfilled"} user={user} fulfillmentStatus={item.fulfillment_status} items={item.fulfilled_items} style={{width: '100%', whiteSpace: 'nowrap'}} handleOnClickAdjustment={this.handleOnClickShowAdjustmentModal} onClickSaveAttribute={this.handleOnSaveNoteAttributes} isMobile={isMobile} currency={currency}/>
                    </div>
                  </>
                  :
                  null
                }
                {
                  item.cancelled_items && item.cancelled_items.length ?
                  <>
                    <Row className="mb-2">
                    <Col lg={3}>
                      <h4 style={{color: '#d15d5d'}}>CANCELLED</h4>
                    </Col>
                    </Row>
                    <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                    <LineItems user={user} onPrintMessage={this.handleOnClickPrintMessage} orderId={item.id} noteAttributes={item.note_attributes} displaySection={"cancelled"} fulfillmentStatus={item.fulfillment_status} items={item.cancelled_items} style={{width: '100%', whiteSpace: 'nowrap'}} handleOnClickAdjustment={this.handleOnClickShowAdjustmentModal} onClickSaveAttribute={this.handleOnSaveNoteAttributes} isMobile={isMobile} currency={currency}/>
                    </div>
                  </>
                  :
                  null
                }
                </Card.Body>
              </Card>
              </Col>
            </Row>
            {
              item.fulfillments && item.fulfillments.length ?
              <Row className="overflowX-auto">
                <Col>
                <Card className="d-print-none tealCard shadow-sm fulfillments">
                  <Card.Header className="tealCardHeader">Fulfillments</Card.Header>
                  <Card.Body className="mx-2 my-0 py-0">
                  {
                    item.fulfillments.map((fulfillment, i) => {
                      return (
                        <Fulfillment
                          key={fulfillment.id}
                          fulfillment={fulfillment}
                          order={item}
                          index={i}
                          onClickEditFulfillment={this.handleOnClickShowEditFulfillmentModal}
                          onClickCancelFulfillment={this.handleOnClickShowConfirmCancelFulfillmentModal}
                        />

                      )
                    })
                  }
                  </Card.Body>
                </Card>
                </Col>
              </Row>
              :
              null
            }
          </div>
          </>
        }
        {
          isShowAddFulfillmentModal &&
          <TrackingInfoModal isMobile={isMobile} onClose={this.handleOnCloseAddFulfillmentModal} onClickFulfill={this.handleOnClickFulfill} />
        }
        {
          isShowEditFulfillmentModal &&
          <TrackingInfoModal onClose={this.handleOnCloseEditFulfillmentModal} onClickFulfill={this.handleOnClickEditFulfillment} Badge="Save" />
        }
        {
          isShowConfirmCancelFulfillmentModal &&
          <ConfirmModal onClickYes={this.handleOnClickCancelFulfillment} onClose={this.handleOnCloseConfirmCancelFulfillmentModal} title="Cancel Fulfillment">
            <p>Are you sure you want to cancel this fulfillment?</p>
          </ConfirmModal>
        }
        {
          showShippingModal &&
            <ShippingModal user={user} parcels={[parcel]} item={item} items={item.line_items.filter(lineItem => { return selectedFulfillableIds.includes(lineItem.id.toString())})} onClose={this.handleOnCloseShowShippingModal} confirmShipment={this.handleConfirmShipment} match={this.props.match} hasShippingFee={true} isProcurement={true}/>
        }
      </>
    )
  }

}

export default connect((state, props) => {
  return {
    // orderDetail: state.orderDetail,
    error: state.error,
    user: state.auth.user
  };
}, {
  // ...orderDetailActionCreators,
  ...errorActionCreators
})(ProcurementOrderDetail);
