import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Alert, Row, Col } from 'react-bootstrap';
import { reduxForm } from 'redux-form';

class StoreDesc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store_desc: '',
      submitting: false
    }
  }

  componentDidMount() {
    const { submitting, store_desc } = this.props;
    this.setState({ submitting, store_desc });
  }

  submit = (values) => {
    const { store_desc } = this.state
    const { onClickSave } = this.props;

    this.setState({ submitting: true });
    onClickSave(store_desc)
  }


  handleOnInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
      submitting: false
    });

  }

  render() {
    const { handleSubmit , error} = this.props;
    const { store_desc, submitting } = this.state;

    return (
        <form onSubmit={handleSubmit(this.submit)}>
        {
          error &&
          <Alert variant="danger">
          {
              Array.isArray(error) ?
              <ul>
              {
              error.map((err, i) => (
                  <li key={i}>
                  {err.msg}
                  </li>
              ))
              }
              </ul>
              :
              error
          }
          </Alert>
        }
        <div>
          <Row className="justify-content-end mb-3">
            <Col sm="auto">
              <Button
                variant="primary"
                type="submit"
                disabled={submitting}
                className="btn-br-6 w-100"
                >
                {submitting ? 'Changes saved' : 'Save changes'}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col style={{minHeight: '20em'}}>
              <textarea className="form-control desc-input" name="store_desc" value={store_desc} onChange={this.handleOnInputChange}></textarea>
            </Col>
          </Row>
        </div>
        </form>
    );
  }
}

StoreDesc = reduxForm({
    form: 'StoreDesc',
    destroyOnUnmount: true,
    enableReinitialize: true,
  })(StoreDesc);

export default connect((state, props) => {
  const { match } = props;
  return {};
}, {
})(StoreDesc);
