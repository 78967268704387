import { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../components/Loader';
import TabHeader from '../components/TabHeader';
import { Card, Row, Col, Button } from 'react-bootstrap';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import moment from 'moment';
import * as deliveryActionCreators from '../redux/actions/deliveryDetail'
import ConfirmModal from '../components/ConfirmModal';
import api from '../helpers/apiClient';
import toast from 'react-hot-toast';
import PriorityFeeModal from '../components/PriorityFeeModal';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class DeliveryDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCancelModal: false,
      showPriorityFeeModal: false,
    }
  }

  componentDidMount() {
    const { match, fetchDeliveryDetail } = this.props;

    fetchDeliveryDetail(match.params.id)
  }

  onClickCancel = () => {
    this.setState({ showCancelModal: true })
  }

  handleOnHideCancelModal = () => {
    this.setState({ showCancelModal: false })
  }

  handleCancel = async () => {
    const { delivery: { item }, match, fetchDeliveryDetail } = this.props

    return api(`/delivery/order`, {
      method: 'DELETE',
      body: JSON.stringify({ id: item.deliveryId })
    }).then(() => {
      this.setState({ showCancelModal: false })
      fetchDeliveryDetail(match.params.id)
    }).catch(err => {
      this.setState({ showCancelModal: false })
      return toast.error(err);
    })
  }

  onClickAddPriorityFee = () => {
    this.setState({ showPriorityFeeModal: true })
  }

  handleOnHidePriorityFeeModal = () => {
    this.setState({ showPriorityFeeModal: false })
  }

  handleSetPriorityFee = (fee) => {
    const { delivery: { item }, match, fetchDeliveryDetail } = this.props

    return api(`/delivery/priority`, {
      method: 'POST',
      body: JSON.stringify({
        id: item.deliveryId,
        amount: fee,
        total: item.priceBreakdown.totalExcludePriorityFee,
      })
    }).then(() => {
      this.setState({ showPriorityFeeModal: false })
      fetchDeliveryDetail(match.params.id)
    }).catch(err => {
      this.setState({ showPriorityFeeModal: false })
      return toast.error(err);
    })
  }

  renderStatus = (status) => {
    switch (status) {
      case 'COMPLETED':
        return <span className="text-uppercase" style={{color: '#4e8767'}}>{status}</span>
      case 'CANCELLED':
      case 'REJECTED':
      case 'EXPIRED':
        return <span className="text-uppercase" style={{color: 'rgb(209, 93, 93)'}}>{status}</span>
      case 'ASSIGNING_DRIVER':
        return <span className="text-uppercase" style={{color: '#6c757d'}}>{status}</span>
      default:
        return <span className="text-uppercase" style={{color: '#ffc107'}}>{status}</span>
    }
  }

  render() {
    const { delivery: { loading, loaded, item }, user } = this.props
    const { showCancelModal, showPriorityFeeModal } = this.state

    return (
      <>
        <TabHeader
          title="On-Demand Shipment Detail"
          tab_id="deliveries"
          user={user}
        />
        {
          loading &&
            <Loader />
        }
        {
          !loading && item &&
            <div className='d-grid gap-4'>
              <Row>
                <Col sm={6} md={5} lg={4}>
                  <Card className="dashboard-card shadow-sm p-2">
                    <Card.Body>
                      { (item.status === 'ASSIGNING_DRIVER' || item.status === 'ON_GOING') &&
                      <Button variant="danger" className="pull-right ms-auto py-0 d-print-none" onClick={this.onClickCancel}>Cancel</Button>
                      }
                      <label className="mb-1">On-demand delivery for:</label>
                      <h4 className="mb-0" style={{color: '#80d7cf'}}>{item.orderName}</h4>
                      <p className="d-print-none my-3 text-muted">placed on {moment(item.created).format('YYYY-MM-DD hh:mm:ss a')}<br/>updated on {moment(item.updatedAt).format('YYYY-MM-DD hh:mm:ss a')}</p>
                    </Card.Body>
                  </Card>
                </Col>
                <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
                <Col>
                  <Card className="d-print-none tealCard mt-4 mt-sm-0 shadow-sm">
                    <Card.Header className="tealCardHeader">Shipment Info</Card.Header>
                    <Card.Body className="grey-label">
                      <Row className="flex-row">
                        <Col lg={4} className="m-2">
                          <Row className="flex-lg-column w-100">
                            <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Service</label>
                              <span>{item.serviceName}</span>
                            </Col>
                            <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Tracking Link</label>
                              <a target="_blank" href={item.shareLink} className="overflow-hidden white-space-no-wrap" style={{textOverflow: 'ellipsis'}}>{item.shareLink}</a>
                            </Col>
                            <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Status</label>
                              { this.renderStatus(item.status) }
                            </Col>
                            { item.scheduleAt &&
                            <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Schedule At</label>
                              <span>{moment(item.scheduleAt).format('YYYY-MM-DD hh:mm a')}</span>
                            </Col>
                            }
                            <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Distance</label>
                              <span>{item.distance.value/1000} km</span>
                            </Col>
                            { item.specialRequests && item.specialRequests.length > 0 &&
                            <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Additional Services</label>
                              { item.specialRequests.map((request) => (
                                <span>- {request.description}</span>
                              ))}
                            </Col>
                            }
                          </Row>
                        </Col>
                        <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
                        <Col className="m-2 mt-2 my-lg-auto label-strong">
                          <Row>
                            <Col className="text-end"><label>Base Fare</label></Col>
                            <Col>{currency} {item.priceBreakdown.base.toFixed(2)}</Col>
                          </Row>
                          { item.priceBreakdown.extraMileage &&
                          <Row>
                            <Col className="text-end"><label>Additional Distance Fee</label></Col>
                            <Col>{currency} {item.priceBreakdown.extraMileage.toFixed(2)}</Col>
                          </Row>
                          }
                          { item.priceBreakdown.surcharge &&
                          <Row>
                            <Col className="text-end"><label>Surcharge</label></Col>
                            <Col>{currency} {item.priceBreakdown.surcharge.toFixed(2)}</Col>
                          </Row>
                          }
                          { item.priceBreakdown.specialRequests &&
                          <Row>
                            <Col className="text-end"><label>Additional Services</label></Col>
                            <Col>{currency} {item.priceBreakdown.specialRequests.toFixed(2)}</Col>
                          </Row>
                          }
                          { item.priceBreakdown.priorityFee &&
                          <Row>
                            <Col className="text-end"><label>Priority Fee</label></Col>
                            <Col>{currency} {item.priceBreakdown.priorityFee.toFixed(2)}</Col>
                          </Row>
                          }
                          <Row>
                            <Col className="text-end"><label>Total</label></Col>
                            <Col>{currency} {item.priceBreakdown.total.toFixed(2)}</Col>
                          </Row>
                          { item.status === 'ASSIGNING_DRIVER' &&
                          <Row>
                            <Col></Col>
                            <Col>
                              <Button variant="secondary" className="mt-1 py-0 d-print-none" onClick={this.onClickAddPriorityFee}>Add Priority Fee</Button>
                            </Col>
                          </Row>
                          }
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="overflowX-auto">
                <Col>
                  <Card className="tealCard shadow-sm">
                    <Card.Header className="tealCardHeader">Route</Card.Header>
                    <Card.Body className="mx-2 grey-label">
                      { item.driverName && item.driverPhone && item.driverPlateNumber &&
                          <Card className="position-relative mb-3">
                            <Card.Header className="greyCardHeader">Driver</Card.Header>
                            <Card.Body className="d-grid gap-1">
                              <Row>
                                <Col className="d-grid">
                                  <label>Name</label>
                                  <span>{item.driverName}</span>
                                </Col>
                                <Col className="d-grid">
                                  <label>Phone</label>
                                  <span>{item.driverPhone}</span>
                                </Col>
                                <Col className="d-grid">
                                  <label>Plate Number</label>
                                  <span>{item.driverPlateNumber}</span>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                      }
                      <Timeline>
                        { _.map(item.stops, (stop) => (
                          <TimelineEvent
                            title={stop.address}
                            key={stop._id}>
                            <div>{stop.name}</div>
                            <div>{stop.phone}</div>
                          </TimelineEvent>
                        ))}
                      </Timeline>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
        }
        { showCancelModal &&
          <ConfirmModal onClickYes={this.handleCancel} onClose={this.handleOnHideCancelModal} title="Cancel Shipment">
            <p>Are you sure you want to cancel this shipment?</p>
          </ConfirmModal>
        }
        { showPriorityFeeModal &&
          <PriorityFeeModal onClickSave={this.handleSetPriorityFee} onClose={this.handleOnHidePriorityFeeModal}/>
        }
      </>
    )
  }
}

export default connect((state, props) => {
  return {
    delivery: state.deliveryDetail,
    user: state.auth.user,
  }
}, deliveryActionCreators)(DeliveryDetail)
