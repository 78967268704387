import {
    SET_PREVIEWPRODUCT
  } from '../actions/previewProduct';
  
  const defaultState = {
    loading: false,
    loaded: false,
    item: null,
  };
  
  export default function previewProduct(state = defaultState, action) {
    switch (action.type) {
      case SET_PREVIEWPRODUCT:
        return {
          ...state,
          item: action.payload.item
        }
      default:
        return state;
    }
  }
  