import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from '../images/Giftr-Logo-Main.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import flagMY from '../images/malaysia.png';
import flagSG from '../images/singapore.png'

class LoginNavbar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <nav className="login-header navbar shadow-sm text-center fixed-top">
                <a href="/">
                    <img src={logo} alt="Giftr Seller Center" className="nav-logo d-inline-block align-text-bottom"/>
                    <h5 style={{display: 'inline-block'}}>Seller Center</h5>
                </a>
                <div className="d-none d-lg-block d-flex">
                    <a href="https://seller.giftr.my/" target="_blank" style={{textDecoration: 'none'}}><img src={flagMY} width="25" height="25" className="mx-1" alt="seller.giftr.my"></img></a>
                    <a href="https://seller.giftr.sg/" target="_blank" style={{textDecoration: 'none'}}><img src={flagSG} width="25" height="25" className="mx-1" alt="seller.giftr.sg"></img></a>
                    <div className="vr mx-3 align-text-bottom" style={{width: '2px', height: '1.5em', color: '#B2B2B2'}}></div>
                        {
                            window.location.hostname == "seller.giftr.my" ?
                            <>
                                <a href="https://www.facebook.com/giftrmy" target="_blank"><FontAwesomeIcon icon={brands('facebook-f')} size="lg" className="mx-2" style={{color: '#5A5A5A'}}/></a>
                                <a href="https://www.instagram.com/giftrmy" target="_blank"><FontAwesomeIcon icon={brands('instagram')} size="lg" className="mx-3" style={{color: '#5A5A5A'}}/></a>
                            </>
                            :
                            <>
                                <a href="https://www.facebook.com/giftrsg" target="_blank"><FontAwesomeIcon icon={brands('facebook-f')} size="lg" className="mx-2" style={{color: '#5A5A5A'}}/></a>
                                <a href="https://www.instagram.com/giftrsg" target="_blank"><FontAwesomeIcon icon={brands('instagram')} size="lg" className="mx-3" style={{color: '#5A5A5A'}}/></a>
                            </>
                        }
                </div>
            </nav>
        )
    }
}


export default LoginNavbar;
