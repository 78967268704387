import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, Form, Button, Label, ButtonToolbar } from 'react-bootstrap';
import LineItems from '../components/LineItems';
import Fulfillment from '../components/Fulfillment';
import TrackingInfoModal from '../components/TrackingInfoModal';
import EditDeliveryModal from '../components/EditDeliveryModal';
import GTRAlert from '../components/GTRAlert';
import Loader from '../components/Loader';
import VendorSelection from '../components/VendorSelection'
import api from '../helpers/apiClient';
import _ from 'lodash'
import {Timeline, TimelineEvent} from 'react-event-timeline'
import * as dashboardActionCreators from '../redux/actions/dashboard';
import * as errorActionCreators from '../redux/actions/error';
import config from '../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import TabHeader from '../components/TabHeader';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

let BarChart = require('react-chartjs').Bar;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const { user } = props
    let vendor = user.merchantId;

    if(user.role === 'admin' || user.role === 'staff'){
        vendor = 'Giftr'
    }

    this.state = {
      vendor,
      vendorList: [],
      ann_count: 3
    };
  }

  componentDidMount() {
    const { fetchDashboard, match } = this.props;
    const { vendor } = this.state;

    const vendorList = vendor.split('#,')
    this.setState({ vendorList })

    fetchDashboard(vendorList.length > 1 ? vendorList[0] : vendor);
  }

  handleOnVendorChange = (e) => {
    const { fetchDashboard } = this.props;
    this.setState({ vendor: e.target.value });

    fetchDashboard(e.target.value);
  }

  render() {
    const { dashboard: { loading, loaded, item }, error, user } = this.props;
    const { vendor, vendorList, ann_count } = this.state

    const eventStyle = {
        "backgroundColor": "#fff",
        "boxShadow": 'none',
        "borderRadius": "18px",
        "lineHeight": "1.6",
        "fontSize": "16px",
        "fontWeight": "400",
        "fontFamily": "Varela Round, sans-serif",
        "padding": "1.5em 1em",
    }

    let chartOptions = {scaleShowVerticalLines: false}
    const isAdmin = user.role === 'admin' || user.role === 'staff'

    return (
      <div>
        {
          loading &&
          <Loader />
        }
        {
          error &&
          <GTRAlert variant="danger" alert={error} />
        }
        {
          !loading && item &&
          <div>
            <TabHeader
              title="Dashboard"
              tab_id="dashboard"
              user={user}
              children={
                <>
                  {
                    isAdmin &&
                    <VendorSelection handleOnVendorChange={this.handleOnVendorChange} selectedVendor={vendor} skipDefaultOption={true} />
                  }
                  { //For dual vendor
                    vendorList.length > 1 &&
                    <div className="vendor-selection">
                      <label style={{ marginBottom: '5px' }}>Vendor</label>
                      <Form.Select className="form-control" onChange={this.handleOnVendorChange} value={vendor}>
                        {
                          vendorList.map((option, ind) => {
                            return (
                              <option key={ind} value={option}>{option}</option>
                            )
                          })
                        }
                      </Form.Select>
                    </div>
                  }
                </>
              }
            />
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 col-xl-4-half">
                {/*First panel */}
                <Card className="dashboard-card shadow-sm mb-3">
                  <Card.Body>
                    <div>
                      <span className="dashboard-sales-label">Today's Sales</span>
                      <span className="pull-right dashboard-sales-period-label">{moment().format("MMM D")}</span><br />
                      <span className="dashboard-number-text">{currency}{item.sales.today_sales}</span>
                      <span className="pull-right dashboard-orders-label">{item.sales.today_order === 1 ? `${item.sales.today_order} order` : `${item.sales.today_order} orders`}</span><br />
                    </div>
                  </Card.Body>
                </Card>
                <Card className="dashboard-card shadow-sm mb-3">
                  <Card.Body>
                    <div>
                      <span className="dashboard-sales-label">This Week's Sales</span>
                      <span className="pull-right dashboard-sales-period-label">{moment().startOf('isoweek').format("MMM D")}-{moment().endOf('isoweek').format("D")} (W{moment().format('WW')})</span><br />
                      <span className="dashboard-number-text">{currency}{item.sales.this_week_sales}</span>
                      <span className="pull-right dashboard-orders-label">{item.sales.this_week_order === 1 ? `${item.sales.this_week_order} order` : `${item.sales.this_week_order} orders`}</span><br />
                    </div>
                  </Card.Body>
                </Card>
                <Card className="dashboard-card shadow-sm mb-3">
                  <Card.Body>
                    <div>
                      <span className="dashboard-sales-label">This Month's Sales</span>
                      <span className="pull-right dashboard-sales-period-label">{moment().startOf('month').format("MMM D")}-{moment().endOf('month').format("D")}</span><br />
                      <span className="dashboard-number-text">{currency}{item.sales.this_month_sales}</span>
                      <span className="pull-right dashboard-orders-label">{item.sales.this_month_order === 1 ? `${item.sales.this_month_order} order` : `${item.sales.this_month_order} orders`}</span><br />
                    </div>
                  </Card.Body>
                </Card>
                <div className="dashboard-small-text">
                  *Figures above exclude shipping fee and discounts.
                </div>
              </div>
              <div className="vr d-none d-xl-block col-lg-1 col-xl-1 dashboard-vr"></div>
              {/* Second panel */}
              <div className="col-xs-12 col-sm-6 col-md-6 col-xl-4">
                <Card className="dashboard-card shadow-sm mb-3">
                  <Card.Body>
                    <div className="row">
                      <div className="col text-center p-0">
                        <div className="dashboard-todo-label">Today's Delivery</div>
                        <div className="dashboard-todo-link">{item.delivery.today - item.delivery.today_unfulfill} / {item.delivery.today} <span className="dashboard-today-unfulfilled">fulfilled</span></div>
                      </div>
                      <div className="col text-center p-0">
                        <div className="dashboard-todo-label">Tomorrow's Delivery</div>
                        <div className="dashboard-todo-link">{item.delivery.tomorrow}</div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col text-center">
                        <div className="dashboard-todo-label">Today's Unfulfilled Delivery</div>
                        <a href="orders?financial_status=any&fulfillment_status=unfulfilled" style={{ color: "#D15D5D", textDecoration: 'none' }}><div className="dashboard-todo-link">{item.delivery.today_unfulfill}</div></a>
                      </div>
                      <div className="col text-center">
                        <div className="dashboard-todo-label">Unfulfilled Orders (Past 10 Months)</div>
                        <a href="orders?financial_status=any&fulfillment_status=unfulfilled" style={{ color: "#D15D5D", textDecoration: 'none' }}><div className="dashboard-todo-link">{item.delivery.unfulfilled}</div></a>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="dashboard-card shadow-sm mb-3 text-center">
                  <Card.Body>
                    <div className="dashboard-todo-label" style={{ textAlign: 'left' }}>Orders Over Time</div><br />
                    <BarChart data={item.chart.order_over_time} options={chartOptions} height="200" />
                  </Card.Body>
                </Card>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-xl-4">
                <Card className="dashboard-card shadow-sm mb-3">
                  <Card.Body>
                    <span className="dashboard-todo-label">Ship on Time</span>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col text-center">
                        <div className="dashboard-todo-link" style={{ color: '#80d7cf' }}>{item.performance.score > 0 ? `${item.performance.score.toFixed(1)}/5.0` : "N/A"}</div>
                        <div className="dashboard-small-text text-center">Ship On Time Rating</div>
                      </div>
                      <div className="col text-center">
                        <div className="dashboard-todo-link" style={{ color: '#80d7cf' }}>{item.performance.totalOrders}<span className="dashboard-today-unfulfilled"> orders</span></div>
                        <div className="dashboard-small-text text-center">from {moment().subtract(3, 'months').format("DD/MM/YY")} to {moment().format("DD/MM/YY")}</div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-xs-12 dashboard-small-text" style={{ color: '#5A5A5A', textAlign: 'left' }}>
                        *Rating will only be calculated if there are >10 orders in past 3 months.<br />
                        *For door-to-door order(s) with same day delivery cut-off time before 1pm, it needs to be fulfilled before 5:00pm.<br />
                        *For door-to-door order(s) with same day delivery cut-off time after 1pm, it needs to be fulfilled before 11:59pm.<br />
                        *For courier delivery order(s), it needs to be fulfilled on or before 'Ship by' date.
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>


              <div className="row mt-5">
                <h2 className="sub-header">Announcements</h2>
                <Timeline lineStyle={{ background: 'none' }} style={{ margin: '0', width: '100%' }}>
                  {
                    _.slice(item.announcements, 0, ann_count).map(event => (
                      <TimelineEvent
                        key={event._id}
                        title={event.title}
                        createdAt={moment(event.created).format('ddd DD/MM/YYYY h.mmA')}
                        icon={<FontAwesomeIcon icon={solid('bullhorn')} transform="grow-4" style={{ color: '#80d7cf' }} />}
                        bubbleStyle={{ background: 'none', border: 'none' }}
                        contentStyle={eventStyle}
                      >
                        <div className="timeline_desc" dangerouslySetInnerHTML={{ __html: event.desc }} />
                        {event.file_url && event.is_image &&
                          <img className="img-responsive" src={event.file_url} />
                        }
                        {event.file_url && !event.is_image &&
                          <p>
                            Attached File: <a target="_blank" href={event.file_url}>Download file</a>
                          </p>
                        }
                      </TimelineEvent>
                    ))
                  }
                  <div className="d-flex justify-content-center">
                    {
                      ann_count < item.announcements.length &&
                      <Button
                        variant="primary"
                        onClick={() => {
                          this.setState({
                            ann_count: ann_count + 3
                          })
                        }}
                      >Show More
                      </Button>
                    }
                  </div>
                </Timeline>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    dashboard: state.dashboard,
    error: state.error,
    user: state.auth.user
  };
}, {
  ...dashboardActionCreators,
  ...errorActionCreators
})(Dashboard);
