const env =  process.env.NODE_ENV || "development";
const globby = require('globby');

let configEnv;
if (env === "development"){
  configEnv = require("./env/development");
}
else if (env === "production"){
  configEnv = require("./env/production");
}
module.exports = Object.assign({},
	require('./env/all'),
	configEnv
);

/**
 * Get files by glob patterns
 */
module.exports.getGlobbedFiles = function (globPatterns, removeRoot) {
  return globby.sync(globPatterns);
};