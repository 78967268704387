import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }
  }

  handleOnClickYes = () => {
    const { onClickYes } = this.props;
    this.setState({ isLoading: true });
    onClickYes();
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    const { isLoading } = this.state;
    if (!isLoading) {
      onClose();
    }
  }

  render() {
    const { title, children, noBtnTitle = "No", yesBtnTitle = "Yes", showNoBtn = true, onClickYes, size = "md", contentClassName} = this.props;
    const { isLoading } = this.state;
    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose} size={size} contentClassName={contentClassName}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          {
            title &&
            <h4 className="mb-0">{title}</h4>
          }
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          {children}
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          { showNoBtn &&
          <Button variant="danger-secondary" onClick={this.handleOnClose} disabled={isLoading}>{noBtnTitle}</Button>
          }
          {
            onClickYes &&
            <Button variant="primary" onClick={this.handleOnClickYes} disabled={isLoading}>
              {isLoading ? "Loading..." : yesBtnTitle}
            </Button>
          }
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmModal;
