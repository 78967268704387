import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const HelpModal = ({onClose, tab_id}) => {
  const { item: quick_helps } = useSelector(state => state.quick_helps)
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if(quick_helps && quick_helps.length){
      const tab = _.find(quick_helps, i => i.tab_id === tab_id)

      if(tab){
        setPages(tab.pages)

        if(tab.pages.length > 1){
          setCurrentPage(-1);
        }
      }
    }
  }, [])

  const onClickPrev = () => {
    let page = currentPage - 1

    if(page < 0){
      page = pages.length - 1;
    }

    setCurrentPage(page)
  }

  const onClickNext = () => {
    let page = currentPage + 1

    if(page >= pages.length){
      page = 0;
    }

    setCurrentPage(page)
  }

  return (
    <Modal show={true} onHide={onClose} size="lg" dialogClassName={`${currentPage < 0 ? '' : 'h-100'} my-0 py-5`} contentClassName="h-100">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        {
          currentPage < 0 &&
            <h4 className="mb-0">Help Options</h4>
        }
        {
          currentPage >= 0 && pages.length > 0 &&
            <h4 className="mb-0 d-grid gap-2 align-items-center" style={{gridTemplateColumns: pages.length > 1 ? '40px auto' : ''}}>
              {
                pages.length > 1 &&
                <Button onClick={() => setCurrentPage(-1)} variant="secondary">
                  <FontAwesomeIcon icon={solid('chevron-left')}/>
                </Button>
              }
              {pages[currentPage].title}
            </h4>
        }
      </Modal.Header>
      <Modal.Body className="p-0" style={{borderTop: '1px solid #dee2e6'}}>
        {
          currentPage < 0 &&
            <div className="my-4 d-grid gap-3">
              {
                _.map(pages, (page, i) => {
                  return (
                    <Card onClick={() => setCurrentPage(i)} className="help-option mx-4 tealCard shadow-sm">
                      <Card.Body className="m-2 d-grid gap-3">
                        {page.title}
                      </Card.Body>
                    </Card>
                  )
                })
              }
            </div>
        }
        {
          currentPage >= 0 && pages.length > 0 &&
            <iframe src={pages[currentPage].link} width="100%" height="100%" style={{background: 'transparent', border: '1px solid #ccc'}}></iframe>
        }
      </Modal.Body>
      {
        currentPage >= 0 && pages.length > 1 &&
          <Modal.Footer>
            <div className='d-grid gap-1' style={{gridTemplateColumns: '1fr 1fr'}}>
              {
                currentPage !== 0 ?
                  <Button onClick={onClickPrev}>Prev</Button>
                  :
                  <div></div>
              }
              {
                currentPage !== pages.length - 1 &&
                  <Button onClick={onClickNext}>Next</Button>
              }
            </div>
          </Modal.Footer>
      }
    </Modal>
  )
}

export default HelpModal;
