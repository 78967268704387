import React, { Component, useState } from 'react';
import config from '../config'
import _ from 'lodash'
import moment from 'moment';
import { Button, Badge, Modal, Row, Col } from 'react-bootstrap';
import EditMessageModal from './EditMessageModal';
import EditVMModal from './EditVMModal';
import api from '../helpers/apiClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'
const shopifyAdminDomain = window.location.hostname === 'seller.giftr.my' ? 'https://helpr-asia.myshopify.com/admin/products' : 'https://giftr-singapore.myshopify.com/admin/products'


const ManualLineItems = ({ user, items, style, onCheckItem, fulfillmentStatus, cancelledAt, displaySection, reload }) => {
  const [showEditMessageModal, setShowEditMessageModal] = useState(false);
  const [showEditOrderFileModal, setShowEditOrderFileModal] = useState(false);
  const [orderFile, setOrderFile] = useState('');
  const [uploading, setUploading] = useState(false);

  const handleSaveMessage = (message) => {
    return api(`/manual_orders/${items[0]._id}/message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message_on_card: message })
    }).then(() => {
      setShowEditMessageModal(false);
      reload();
    }).catch(err => {
      console.log(err);
      setShowEditMessageModal(false);
    })
  }

  const addFile = e => {
    setUploading(true);
    const file = e.target.files[0];

    if(file){
      let formData = new FormData();
      formData.append('file', file);
      const data = api('/file/upload/manual_order_file', {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'})
        .then(data => {
          const url = data.file_short_url
          setOrderFile(url)
          setUploading(false);
        }).catch(err => {
          console.log(err);
          setUploading(false);
        })
    }
  }

  const handleSaveOrderFile = () => {
    return api(`/manual_orders/${items[0]._id}/order_file`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ order_file: orderFile })
    }).then(() => {
      setShowEditOrderFileModal(false);
      reload();
    }).catch(err => {
      console.log(err);
      setShowEditOrderFileModal(false);
    })
  }

  return (
    <>
    <table className="table" style={style}>
      <tbody>
        {
          items.map(item => {

            //let shouldAlert = item.ship_by ? moment(item.ship_by, 'DD/MM/YYYY').isSameOrBefore(moment(), 'day') : false

            return (
              <tr key={item._id}>
                {
                  item.fulfillment_status !== "fulfilled" && displaySection !== "cancelled" &&
                  <td width="20">
                    <div className="checkbox d-print-none">
                      <label>
                        <input disabled={user && user.role === 'merchant' && !_.includes(user.merchantId,item.vendor)} type="checkbox" value={item._id} onClick={onCheckItem} />
                      </label>
                    </div>
                  </td>
                }
                {/*<td width="70">
                  <div className="line-item-image">
                    <div height="70px" width="70px">
                      <img src={item.image} alt={item.title} height="70px"/>
                    </div>
                    <span className="badge badge-quantity">{item.quantity}</span>
                  </div>
                </td>*/}
                <td className="line-item-detail">
                  {/*
                    user && user.role === 'admin' ?
                    <div className="text-primary"><a target="_blank" href={`${shopifyAdminDomain}/${item.product_id}`}><strong>{item.title} - {item.quantity} Unit(s)</strong></a></div>
                    :
                    <div className="text-primary"><strong>{item.title} - {item.quantity} Unit(s)</strong></div>
                  */}
                  {/*<p>{item.name}</p>*/}
                  {/*
                    item.sku ?
                    <div>SKU: {item.sku}</div>
                    :
                    null
                  */}
                  {
                    item.vendor ?
                    <div className="d-grid mb-2">
                      <label>Vendor Name</label>
                      <span>{item.vendor}</span>
                    </div>
                    :
                    null
                  }
                  {
                    item.delivery_date &&
                    <div className="d-grid mb-2">
                      <label>Delivery Date</label>
                      <span>{moment(item.delivery_date).format('DD/MM/YYYY')}</span>
                    </div>
                  }
                  {
                    item.ship_by_date &&
                    <div className="d-grid mb-2">
                      <label>Ship By Date</label>
                      <span>{moment(item.ship_by_date).format('DD/MM/YYYY')}</span>
                    </div>
                  }
                  {/*
                    item.est_delivery ?
                    <dl className="dl-horizontal order-detail-item">
                      <dt className="order-detail-item-properties-name">Est. Delivery</dt>
                      <dd className="order-detail-item-properties-value">{item.est_delivery}</dd>
                    </dl>
                    :
                    null
                  */}
                  {/*
                    item.ship_by ?
                    <dl className="dl-horizontal order-detail-item">
                      <dt className="order-detail-item-properties-name">Ship By</dt>
                      { shouldAlert && displaySection !== 'fulfilled' && _.lowerCase(fulfillmentStatus) !== 'fulfilled' && !cancelledAt ? <dd className="order-detail-item-properties-value ship-by-alert">{item.ship_by}</dd> : <dd className="order-detail-item-properties-value">{item.ship_by}</dd>}
                    </dl>
                    :
                    null
                  */}
                  {/*
                    item.grams ?
                    <dl className="dl-horizontal order-detail-item">
                      <dt className="order-detail-item-properties-name">Weight</dt>
                      <dd className="order-detail-item-properties-value">{item.grams} grams</dd>
                    </dl>
                    :
                    null
                  */}
                  <div className="d-grid mb-2">
                    <div>
                      {
                        item.order_file &&
                          <label className="me-2">Order File</label>
                      }
                      {
                        user && (user.role === 'admin' || user.role === 'staff') &&
                          <>
                            <Button className="print-vm-button copy-to-clipboard py-1" variant="secondary" onClick={() => setShowEditOrderFileModal(true)}>Edit Order File</Button>
                          </>
                      }
                    </div>
                    <a href={item.order_file} target="_blank">{item.order_file}</a>
                  </div>
                  {
                    item.item_details &&
                    <div className="d-grid mb-2">
                      <label>Item Details</label>
                      <p className="order-detail-item-desc">{item.item_details}</p>
                    </div>
                  }
                  {
                    item.message_on_card &&
                    <div className="d-grid">
                      <div>
                        <label>Message on Card</label>
                        <Button className="copy-to-clipboard ms-2 py-1" variant="secondary" data-clipboard-text={item.message_on_card}>
                          COPY
                        </Button>
                        {
                          user && (user.role === 'admin' || user.role === 'staff') &&
                            <>
                              <Button className="print-vm-button copy-to-clipboard py-1" variant="secondary" onClick={() => setShowEditMessageModal(true)}>Edit Message on Card</Button>
                            </>
                        }
                      </div>
                      <p className="order-detail-item-desc">{item.message_on_card}</p>
                    </div>
                  }
                  {/*
                    item.properties ?

                    item.properties.map((property, i) => {
                      return (
                        <div key={i}>
                          <span className="order-detail-item-title">{property.name}</span>
                          {(property.name === 'Message on Card' && property.value !== "") ? <div><button className="copy-to-clipboard" data-clipboard-text={property.value}>COPY</button></div> : ''}
                          <p className="order-detail-item-desc">{property.name === 'Message on Card' ? (property.value === "" ? <span style={{color: 'red'}}><i>Reminder from Giftr: *No message left by sender. Please proceed to send an empty message card.</i></span> : property.value) : property.value}</p>
                        </div>)
                    })
                    :
                    null
                  */}
                </td>
                <td className="text-end" style={{verticalAlign: "middle"}}>
                  { item.ready_to_ship &&
                  <div>
                    <Badge bg="success" className="text-uppercase mb-2">Accepted</Badge><br/>
                  </div>
                  }
                </td>
                {
                  showEditMessageModal &&
                    <EditMessageModal message={item.message_on_card} onClose={() => setShowEditMessageModal(false)} onClickSave={handleSaveMessage}/>
                }
              </tr>
            )
          })
        }
      </tbody>
    </table>
      { showEditOrderFileModal &&
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={() => setShowEditOrderFileModal(false)}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0">Edit Order File</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
          <div className="d-grid gap-1">
            <label htmlFor="order_file">Order File</label>
            <Row>
              <Col xs={5}>
                <label className="custom-file-upload" style={{width:'100%'}}>
                  <FontAwesomeIcon icon={solid('folder-open')} className="me-2"/>Choose File
                  <input type="file" name="order_file" onChange={addFile}/>
                </label>
              </Col>
              <Col xs={7} className="d-grid p-0" style={{fontSize: '0.9em', color: '#5A5A5A'}}>
                {
                  orderFile &&
                    <span>{orderFile}</span>
                }
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={() => setShowEditOrderFileModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleSaveOrderFile} disabled={uploading}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      }
    </>
  );
}

export default ManualLineItems;
