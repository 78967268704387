import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import TabHeader from '../components/TabHeader';
import { Card, Button, Badge, Row, Col, Container } from 'react-bootstrap';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Link } from 'react-router-dom';
import ConfirmProcurementBillModal from '../components/ConfirmProcurementBillModal';
import Dropzone from 'react-dropzone'
import ConfirmModal from '../components/ConfirmModal';

const currency = 'RM'

class ProcurementBillDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      item: null,
      uploadingFile: false,
    }
  }

  componentDidMount() {
    this.fetchBillDetail()
  }

  fetchBillDetail = () => {
    const { match } = this.props;

    return api(`/procurement_bills/${match.params.billId}`).then(item => {
      this.setState({ item, loading: false })
    }).catch(err => {
      console.log(err)
      this.setState({ loading: false })
    })
  }

  renderFulfillAt = (cell, row) => {
    return <span>{moment(cell).format('DD-MM-YYYY')}</span>
  }

  renderLink = (cell, row) => {

    return <Link
      key={row._id}
      to={{
        pathname: `/procurement_orders/${row.order_id}`,
        state: {modal: false}
      }}>
      {row.order_name}
    </Link>
  }

  renderUnitPrice = (cell, row) => {
    return <span>{currency} {row.price.toFixed(2)}</span>
  }

  renderLineItemPrice = (cell, row) => {
    return<span>{currency} {row.base_price.toFixed(2)}</span>
  }

  renderShippinFee = (cell, row) => {
    return <span>
      {currency} {cell.toFixed(2)}
    </span>
  }

  renderTotalBalance = (cell, row) => {

    return <>
      <span data-html={true} data-tip={`
      <p>
        Total Item Price ${currency}${((row.price * row.quantity) + row.adjustment_item_price).toFixed(2)}<br/>
        ${row.shipping_fee ?
          `Shipping Fee (Fulfilled by Seller)  ${currency}${(row.shipping_fee).toFixed(2)}<br/>`: ""
        }
        ${row.adjustment_shipping ?
          `Shipping Fee (Adjustment, Fulfilled by Seller) ${currency}${(row.adjustment_shipping).toFixed(2)}<br/>`: ""
        }
      </p>`}>
        {currency} {(
          (row.price * row.quantity)
          + row.shipping_fee
          + (row.adjustment_shipping ? row.adjustment_shipping : 0)
          + (row.adjustment_item_price ? row.adjustment_item_price : 0)).toFixed(2)}<i className="fa fa-info-circle"></i>
      </span><ReactTooltip />
    </>
  }

  handleOnClickShowConfirmModal = () => {
    this.setState({ showConfirmModal: true });
  }

  handleOnCloseShowConfirmModal = () => {
    this.setState({ showConfirmModal: false });
  }

  handleConfirmBill = (file) => {
    this.setState({ showConfirmModal: false });

    const { item: { vendor } } = this.state;
    const { match } = this.props;
    let billId = match.params.billId

    var formData = new FormData();
    formData.append('invoice', file);
    formData.append('vendor', vendor);

    const url = window.location.pathname
    return api(url + '/confirm' , {
      method: 'POST',
      body: formData
    }, {'Accept': 'application/json'}).then(() => {

      this.fetchBillDetail(billId);
    }).catch(err => {
      if (err) {
        alert(err.toString())
      }
    });
  }

  handleDownloadInvoice = () => {
    const { item } = this.state;
    window.open(item.invoice_link);
  }

  handleUnbill = () => {

    const { match } = this.props;
    let billId = match.params.billId

    const url = window.location.pathname
    return api(url + `/unbill` , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((result) => {

      this.fetchBillDetail(billId);
    }).catch(err => {
      if (err) {
        alert(err.toString())
      }
    });
  }

  handleExportBill = () => {

    const url = window.location.pathname
    return api(url + `/export` , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((result) => {

      if(result.url){
        window.location.href = result.url;
      }
    }).catch(err => {
      if (err) {
        alert(err.toString())
      }
    });
  }

  handleOnShowDeleteBillModel = () => {
    this.setState({ showDeleteBillModal: true });
  }

  handleOnHideDeleteBillModel = () => {
    this.setState({ showDeleteBillModal: false });
  }

  handleDeleteBill = () => {

    const { history } = this.props;

    const url = window.location.pathname
    return api(url + `/delete` , {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((result) => {
      history.push('/procurement_orders')
    })
  }

  render() {
    const { user } = this.props;
    const { loading, item, showConfirmModal, uploadingFile, showDeleteBillModal } = this.state;

    const isAdmin = user.role === 'admin'

    let selectRowProps = {
      selectRow: {
        mode: "checkbox",
        headerColumnStyle: { width: '30px' },
        onSelect: this.onRowSelect,
        onSelectAll: this.onSelectAll,
      }
    }

    let adjustmentSelectRowProps = {
      selectRow: {
        mode: "checkbox",
        clickToSelect: true,
        onSelect: this.adjustmentOnRowSelect,
        onSelectAll: this.adjustmentOnSelectAll,
        headerColumnStyle: { width: '30px' },
      }
    }

    const columns = [
      {
        dataField: 'order_created_at',
        text: 'Purchase Date',
        formatter: this.renderFulfillAt,
        headerStyle: {
          width: '130px',
          resize: 'horizontal'
        },
      },
      {
        dataField: '_id',
        text: 'Bill',
        hidden: true,
      },
      {
        dataField: 'order_name',
        sort: true,
        text: 'Order',
        formatter: this.renderLink,
        headerStyle: {
          width: '120px',
          resize: 'horizontal',
        }
      },
      {
        dataField: 'fulfilled_at',
        text: 'Fulfill Date',
        formatter: this.renderFulfillAt,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'name',
        text: 'Name',
        headerStyle: {
          resize: 'horizontal'
        }
      },
      {
        dataField: 'price',
        text: 'Unit Price',
        formatter: this.renderUnitPrice,
        headerStyle: {
          width: '110px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'quantity',
        text: 'Qty',
        headerStyle: {
          width: '50px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'total_price',
        text: 'Total Item Price',
        formatter: this.renderLineItemPrice,
        headerStyle: {
          width: '130px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'shipping_fee',
        text: 'Total Ship. Fee',
        formatter: this.renderShippinFee,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'payout_bal',
        text: 'Total Payout Bal.',
        formatter: this.renderTotalBalance,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
      },
    ];

    const adjustment_columns = [
      {
        dataField: 'purchase_date',
        text: 'Purchase Date',
        formatter: this.renderAdjustmentDate,
        headerStyle: {
          width: '130px',
          resize: 'horizontal'
        },
      },
      {
        dataField: '_id',
        text: 'id',
        hidden: true,
      },
      {
        dataField: 'name',
        text: 'Order ID',
        headerStyle: {
          resize: 'horizontal',
        }
      },
      {
        dataField: 'created',
        text: 'Created',
        sort: true,
        formatter: this.renderAdjustmentDate,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'adjustment_info',
        text: 'Adjustment Info',
        headerStyle: {
          resize: 'horizontal'
        }
      },
      {
        dataField: 'amount',
        text: 'Item Price',
        formatter: this.renderAdjustmentAmount,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'shipping_fee',
        text: 'Shipping Fee',
        formatter: this.renderAdjustmentAmount,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'balance',
        text: 'Balance',
        csvFormatter: this.exportAdjustmentBalance,
        formatter: this.renderAdjustmentBalance,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
      },
    ];

    const options = {
      showTotal: true,
      sizePerPage: 30,
      sizePerPageList: [],
    };

    return (
      <>
        {
          loading &&
          <Loader />
        }
        {
          !loading && item &&
            <>
              <TabHeader title="Procurement Bill Detail" tab_id="procurement_bill_detail" user={user} />
              <div className='d-grid gap-4'>
                <Row>
                  <Col sm={6} md={5} lg={4} className="d-grid gap-4">
                    <Card className="dashboard-card shadow-sm p-2">
                      <Card.Body>
                        <h4 className="mb-0 d-inline-block" style={{color: '#80d7cf'}}>{item.name} bill</h4>
                        {
                          isAdmin &&
                            <Button variant="danger" className="pull-right ms-auto py-0 d-print-none" onClick={this.handleOnShowDeleteBillModel}>Delete Bill</Button>
                        }
                        <p className="mt-3 mb-0">created on {moment(item.created).format('DD/MM/YYYY')}</p>
                      </Card.Body>
                    </Card>
                    <Card className="tealCard shadow-sm">
                      <Card.Header className="tealCardHeader">Merchant Info</Card.Header>
                      <Card.Body className="grey-label label-strong p-4">
                        <Row className="flex-row">
                          <Col xs={6} className="d-grid gap-1 order-1 mb-3">
                            <label>Name</label>
                            {item.vendor}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
                  <Col>
                    <Card className="tealCard mt-4 mt-sm-0 shadow-sm">
                      <Card.Header className="tealCardHeader">Bill Info</Card.Header>
                      <Card.Body className="grey-label label-strong">
                        <Row className='flex-row'>
                          <Col className="pt-2">
                            <Row className="mb-1">
                              <Col className="text-end"><label>Status</label></Col>
                              <Col>{item.status === 'paid' ? <Badge bg="success" className="text-uppercase">{item.status}</Badge> : <Badge bg="warning" className="text-uppercase">{item.status}</Badge>}</Col>
                            </Row>
                            <Row className="mb-1">
                              <Col className="text-end"><label>Total Item Price</label></Col>
                              <Col>{currency}{item.item_price.toFixed(2)}</Col>
                            </Row>
                            {
                              item.total_markup_price > 0 &&
                                <Row className="shipping-lines">
                                  <Col className='text-end'>Item Price (Markup)</Col>
                                  <Col>{currency} { parseFloat(item.total_markup_price).toFixed(2)}</Col>
                                </Row>
                            }
                            {
                              item.total_adjustment_item_price !== 0 &&
                                <Row className="shipping-lines">
                                  <Col className='text-end'>Item Price (Adjustment)</Col>
                                  <Col>{currency} { item.total_adjustment_item_price.toFixed(2)}</Col>
                                </Row>
                            }
                            {
                              item.total_markup_price > 0 &&
                                <Row className="shipping-lines">
                                  <Col className='text-end'>Comm. (Markup)</Col>
                                  <Col>{currency} { parseFloat(item.total_markup_price).toFixed(2)}</Col>
                                </Row>
                            }
                            {
                              item.total_discount > 0 &&
                                <Row className="mb-1">
                                  <Col className="text-end"><label>Discount</label></Col>
                                  <Col>-{currency}{item.total_discount.toFixed(2)}</Col>
                                </Row>
                            }
                            {
                              ((item.total_shipping_via_seller && item.total_shipping_on_demand) || item.total_shipping > 0) &&
                                <Row className="mb-1">
                                  <Col className="text-end"><label>Total Shipping Fee</label></Col>
                                  <Col>{currency}{item.total_shipping.toFixed(2)}</Col>
                                </Row>
                            }
                            {
                              item.total_shipping_via_seller > 0 &&
                                <Row className="shipping-lines">
                                  <Col className='text-end'>Shipping Fee (Fulfilled by Seller) </Col>
                                  <Col>{currency} { item.total_shipping_via_seller.toFixed(2) }</Col>
                                </Row>
                            }
                            {
                              item.total_shipping_via_giftr > 0 && item.total_shipping_via_giftr !== 0 &&
                                <Row className="shipping-lines">
                                  <Col className='text-end'>Shipping Fee (Fulfilled by Giftr) </Col>
                                  <Col>{currency} { item.total_shipping_via_giftr.toFixed(2) }</Col>
                                </Row>
                            }
                            {
                              (item.total_shipping_on_demand > 0 || item.total_shipping_on_demand < 0) &&
                                <Row className="shipping-lines">
                                  <Col className='text-end'>Shipping Fee (Fulfilled by Giftr - On-Demand) </Col>
                                  <Col>{currency} { item.total_shipping_on_demand.toFixed(2) }
                                    <FontAwesomeIcon icon={solid('external-link')} style={{marginLeft: '5px'}} className="cursor-pointer" onClick={this.handleOnClickOnDemandShipmentsLink}/>
                                  </Col>
                                </Row>
                            }
                            {
                              item.total_adjustment_shipping !== 0 &&
                                <Row className="shipping-lines">
                                  <Col className='text-end'>Shipping Fee (Adjustment, Fulfilled by Seller)</Col>
                                  <Col>{currency} { item.total_adjustment_shipping.toFixed(2)}</Col>
                                </Row>
                            }
                            <Row className="mb-1">
                              <Col className="text-end"><label>Total Payout Balance</label></Col>
                              <Col><span className="mw-1" data-html={true} data-tip={`
                                        <p>
                                        Total Item Price ${currency}${item.item_price.toFixed(2)}<br/>
                                        ${ item.total_shipping_via_seller > 0 && (item.total_shipping_via_seller !== 0 || item.total_adjustment_shipping !== 0) ?
                                            `Shipping Fee (Fulfilled by Seller)  ${currency}${item.total_shipping_via_seller.toFixed(2)}<br/>` : ""
                                        }
                                        ${ item.total_adjustment_shipping !== 0 ?
                                            `Shipping Fee (Adjustment, Fulfilled by Seller) ${currency}${ item.total_adjustment_shipping.toFixed(2)}<br/>`: ""
                                        }
                                        ${ item.total_shipping_on_demand > 0 ?
                                            `Shipping Fee (Fulfilled by Giftr - On-Demand)  -${currency}${item.total_shipping_on_demand.toFixed(2)}<br/>` : ""
                                        }
                                        ${ item.total_discount > 0 ?
                                            `Discount -${currency}${item.total_discount.toFixed(2)}` : ""
                                        }
                                      </p>
                              `}>{currency}{item.balance.toFixed(2)} <i className="fa fa-info-circle"></i></span><ReactTooltip /></Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="align-items-center justify-content-center my-3">
                          {
                            item.status === 'pending' &&
                              <Col xs="auto">
                                <Button className="btn-br-6" variant="primary" onClick={this.handleOnClickShowConfirmModal}>Confirm Bill</Button>
                              </Col>
                          }
                          <Col xs="auto">
                            <Button className="btn-br-6" variant="secondary" onClick={this.handleExportBill}>Export Bill</Button>
                          </Col>
                          {
                            user && user.role === 'admin' && item.status === 'billed' &&
                              <Col xs="auto">
                                <Button className="btn-br-6" variant="danger-secondary" onClick={this.handleUnbill}>Unbill</Button>
                              </Col>
                          }
                          {
                            user && (user.role === 'admin' || user.role === 'staff') && (item.status === 'billed' || item.status === 'paid') &&
                              <Col xs="auto">
                                <Button variant="primary" className="btn-br-6" onClick={this.handleOnClickShowPaymentDateModal}>Update Payment Date</Button>
                              </Col>
                          }
                          {
                            item.payment_date &&
                              <Col xs="auto">
                                <p className="m-0 text-center" style={{whiteSpace: 'pre-line'}}>{`Paid on ${moment(item.payment_date).format('DD-MM-YYYY')}
(refer to email notification by bank)`}</p></Col>
                          }
                          {
                            item.payment_slip &&
                              <Col xs="auto">
                                <a target="_blank" href={item.payment_slip} style={{color: '#80d7cf'}}>Payment Slip</a>
                              </Col>
                          }
                          {
                            item.revised_statement &&
                              <Col xs="auto">
                                <a target="_blank" href={item.revised_statement} style={{color: '#80d7cf'}}>Revised Statement</a>
                              </Col>
                          }
                        </Row>
                        <Row className="align-items-center justify-content-center m-0">
                          {
                            (item.status === 'billed' || item.status === 'paid') && item.invoice_link &&
                              <>
                                <Col xs="auto my-2 my-xl-0">
                                  <Button className="btn-br-6" variant="info" onClick={this.handleDownloadInvoice}>Open Invoice</Button>
                                </Col>
                                {
                                  user && (user.role === 'admin' || user.role === 'staff') &&
                                    <Col xs="auto my-2 my-xl-0">
                                      <Button className="btn-br-6" variant="secondary" onClick={this.handleOnClickShowAttachRevisedInvoiceModal}>Overwrite Invoice</Button>
                                    </Col>
                                }
                              </>
                          }
                          {
                            user && (user.role === 'admin' || user.role === 'staff') && item.status === 'billed' &&
                              <Col xs="auto my-2 my-xl-0">
                                <Dropzone
                                  style={{}}
                                  onDrop={() => {}}
                                  disabled={item.status !== 'billed'}
                                  multiple={false}
                                  accept='application/pdf,application/zip'>
                                  <Button className="btn-br-6" variant="primary" disabled={uploadingFile} >{uploadingFile ? "Uploading" : "Attach Payment Slip"}</Button>
                                </Dropzone>
                              </Col>
                          }
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-3 bill-detail-line-items table-responsive">
                  <h4>Line Items</h4>
                  <Col xs={12}>
                    <ToolkitProvider
                      keyField='_id'
                      data={item.line_item}
                      columns={columns}
                      exportCSV={{
                        fileName: `${item.vendor}-line-items.csv`,
                        exportAll: false
                      }}
                    >
                      {
                        props => (
                          <>
                            <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                              <Col>
                                { isAdmin && item && item.status === 'pending' &&
                                <Button
                                  variant="danger"
                                  className="btn-br-6 pull-right"
                                  onClick={this.customConfirm}
                                >
                                  <FontAwesomeIcon icon={solid('trash')} style={{marginRight: '15px'}}/>
                                  Delete
                                </Button>
                                }
                              </Col>
                            </Container>
                            <BootstrapTable
                              { ...props.baseProps }
                              bordered={ false }
                              pagination={paginationFactory(options)}
                              { ...(item && item.status === 'pending' && selectRowProps) }
                              hover
                              defaultSorted={[{
                                dataField: 'order_name',
                                  order: 'asc',
                              }]}
                              noDataIndication={() => 'There is no data to display'}
                            />
                          </>
                        )
                      }
                    </ToolkitProvider>
                  </Col>
                </Row>
                <Row className="mt-3 bill-detail-line-items table-responsive">
                  <h4>Adjustments</h4>
                  <Col xs={12}>
                    <ToolkitProvider
                      keyField='_id'
                      data={item.adjustment}
                      columns={adjustment_columns}
                      exportCSV={{
                        filename: `${item.vendor}-adjustment.csv`,
                      }}
                    >
                      {
                        props => (
                          <>
                            <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                              <Col>
                                { isAdmin && !(item.status === 'paid' || item.status === 'billed') &&
                                <Button className="pull-right btn-br-6" variant="primary" onClick={this.handleOnClickShowAdjustmentModal}>Add Adjustment</Button>
                                }
                                { isAdmin && item && item.status === 'pending' &&
                                  <Button
                                    variant="outline-danger"
                                    className="btn-br-6 pull-right mx-2"
                                    onClick={this.confirmDeleteAdjustment}
                                  >
                                    <FontAwesomeIcon icon={solid('trash')} style={{marginRight: '15px'}}/>
                                    Delete
                                  </Button>
                                }
                              </Col>
                            </Container>
                            <BootstrapTable
                              { ...props.baseProps }
                              bordered={ false }
                              { ...(item && item.status === 'pending' && adjustmentSelectRowProps) }
                              hover
                              defaultSorted={[{
                                dataField: 'created',
                                  order: 'asc',
                              }]}
                              noDataIndication={() => 'There is no data to display'}
                            />
                          </>
                        )
                      }
                    </ToolkitProvider>
                  </Col>
                </Row>
              </div>
            </>
        }
        {
          showConfirmModal &&
          <ConfirmProcurementBillModal item={item} onClose={this.handleOnCloseShowConfirmModal} onClickSave={this.handleConfirmBill}/>
        }
        {
          showDeleteBillModal &&
          <ConfirmModal onClickYes={this.handleDeleteBill} onClose={this.handleOnHideDeleteBillModel} title="Delete Bill">
            <p>Are you sure you want to delete this bill?</p>
          </ConfirmModal>
        }
      </>
    )
  }
}

export default connect((state, props) => {
  return {
    user: state.auth.user
  }
})(ProcurementBillDetail)
