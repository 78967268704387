import {
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
} from '../actions/customers';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
};

export default function customers(state = defaultState, action) {
  switch (action.type) {
    case FETCH_CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
