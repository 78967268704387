import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import moment from 'moment';
import api from '../helpers/apiClient';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';

const ActivityLogModal = ({ onClose, productId, orderId, logType }) => {

    const [activity, setActivity] = useState('')
    const [loading, setLoading] = useState(true)
    const [logs, setLogs] = useState([])

    useEffect(() => {
        getActivityLogs()
    }, [productId, orderId])

    const getActivityLogs = () => {

        let url = `/logs/${logType}`
        if (productId) {
            url = `/logs/products/${productId}`
        } else if (orderId) {
            url = `/logs/orders/${orderId}`
        }

        api(url, { method: 'GET' }, {'Accept': 'application/json'})
        .then((response) => {
            setLogs(response)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    const saveActivity = () => {
        setLoading(true)
    }

    const dateSort = (date1, date2, order) => {

        if (!date1) {
          date1 = moment("01/01/2010", "DD/MM/YYYY");
        }
    
        if (!date2) {
          date2 = moment("01/01/2010", "DD/MM/YYYY");
        }
    
        if (order === "desc") {
          return (
            moment(date2).unix() - moment(date1).unix()
          );
        } else {
          return (
            moment(date1).unix() - moment(date2).unix()
          );
        }
    }
  
    let columns = [
        {
            dataField: 'id',
            hidden: true,
            text: 'Id',
        },
        {
            dataField: 'created',
            sort: true,
            formatter: (cell, row) => {
                return moment(cell).format('YYYY-MM-DD h:mm a')
            },
            text: 'Date',
            headerStyle: {
                width: '180px'
            },
            sortFunc: dateSort
        },
        {
            dataField: 'email',
            text: 'Email',
            headerStyle: {
            width: '150px',
                resize:'horizontal'
            }
        },
        {
            dataField: 'role',
            text: 'Role',
            headerStyle: {
                width: '100px',
                resize:'horizontal'
            }
        },
        {
            dataField: 'type',
            text: 'Type',
            headerStyle: {
                width: '120px',
                resize:'horizontal'
            }
        }
    ];

    const showVariantTitle = (cell, row) => {
        return (
          <div>
            <span data-tip={cell}>{cell}</span><ReactTooltip place="bottom"/>
          </div>
        )
    }

    const renderMessage = (cell, row) => {
        let message
        if (cell || row.contact_message) {
          try {
            let url = new URL(cell || row.contact_message);
            message = url.searchParams.get('text');
          } catch (e) {
            console.log(e)
          }
        }
    
        return (
          <div>
            <span data-html={true} data-tip={`<div style="max-width: 500px"><span class="white-space-pre-line">${message || row.contact_message}</span></div>`}>{message || row.contact_message}</span><ReactTooltip place="bottom"/>
          </div>
        )
    }

    if(logType === 'product') {
        columns.push(
        {
            dataField: 'product_title',
            formatter: showVariantTitle,
            text: 'Product Variant',
            headerStyle: {
                width: '400px',
                resize: 'horizontal'
            }
        })
    } else if (logType === 'order') {
        columns.push(
        {
            dataField: 'whatsapp_link',
            text: 'Message',
            headerStyle: {
              width: '150px',
              resize: 'horizontal'
            },
            formatter: renderMessage
        },
        {
            dataField: 'line_item_id',
            text: 'Line Item ID',
            headerStyle: {
                width: '150px',
                resize: 'horizontal'
            }
        })
    }
    
    return (
        <Modal show={true} onHide={onClose} size="lg">
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
            <h4 className="mb-0">Activity Log</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
            <>
                { loading && <div className="text-center">Loading...</div> }
                { logs && logs.length === 0 && !loading && <div className="text-center">No logs found</div> }
                { 
                    logs && logs.length > 0 &&
                    <BootstrapTable
                        keyField='id'
                        data={logs}
                        columns={columns}
                        pagination={paginationFactory()}
                        striped
                        hover
                    />
                }         
            </>
        </Modal.Body>
        </Modal>
    )
}

export default ActivityLogModal;