import {
  FETCH_PENDING_PRODUCTS_REQUEST,
  FETCH_PENDING_PRODUCTS_SUCCESS,
  FETCH_PENDING_PRODUCTS_FAILURE
} from '../actions/pendingProducts';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
  limit: null
}

export default function pendingProducts(state = defaultState, action){
  switch(action.type){
    case FETCH_PENDING_PRODUCTS_REQUEST:
      return{
        ...state,
        loading: true
      };
    case FETCH_PENDING_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt
      };
    case FETCH_PENDING_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
