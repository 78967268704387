import { 
  FETCH_BILL_DETAIL_REQUEST, 
  FETCH_BILL_DETAIL_SUCCESS, 
  FETCH_BILL_DETAIL_FAILURE, 
  CLEAR_BILL_DETAIL,
} from '../actions/billDetail';

const defaultState = {
  loading: false,
  loaded: false,
  billId: null,
  item: null
};

export default function billDetail(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_BILL_DETAIL:
      return {
        ...state,
        defaultState
      };
    case FETCH_BILL_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        billId: action.payload.billId
      };
    case FETCH_BILL_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.payload.item,
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_BILL_DETAIL_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}