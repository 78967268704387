import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import Input from './Input';
import Loader from './Loader';
import config from '../config';
import Select from 'react-select';
import { components } from 'react-select';
import { DropdownIndicator } from 'react-select';
import noImage from '../images/no_image.jpg';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
const { Option, SingleValue } = components;

class BulkManualSyncModal extends Component {
  constructor(props) {
    super(props);

    const { sourceProducts } = props;
    let {products} = props

    //sort products by title
    products = _.sortBy(products, product => {
      return product.title
    })

    //group products by tags where tags contains "sync_"
    let groupedProducts = _.groupBy(products, product => {
      let tags = product.tags
      if(tags && tags.length > 0){

        //split tags by comma
        tags = tags.split(',')

        //check if tags starts with "sync_"
        let syncTag = _.find(tags, tag => {
          //tag tolowercase and trim then check if it starts with "sync_"
          tag = tag.toLowerCase().trim()
          return tag.startsWith('sync_')
        })

        if(syncTag){
          return 'linked'
        }
      }
      return 'unlinked'
    })

    groupedProducts.linked = _.sortBy(groupedProducts.linked, product => {
      return product.title
    })

    groupedProducts.unlinked = _.sortBy(groupedProducts.unlinked, product => {
      return product.title
    })

    let options = []
    let linkedOptions = this.getOptions(groupedProducts.linked)
    let unlinkedOptions =  this.getOptions(groupedProducts.unlinked)

    options.push({
      label: "Unlinked",
      options:unlinkedOptions
    })

    options.push({
      label: "Linked",
      options: linkedOptions
    })

    let updateMap = {}

    
    let updateMapping = _.map(sourceProducts, sourceProduct => {
      return {
        source_product_id: sourceProduct.id,
        selected_id: sourceProduct.preselectedId
      }
    })

    this.state = {
      options,
      updateMapping
    }
  }

  componentDidMount() {
    const { products } = this.state
  }

  getOptions = (products) => {

    return _.map(products, product => {

      //get product.image.src
      let src = noImage

      if(product.image && product.image.src){
        src = product.image.src
      }

      return {
        label: product.title,
        value: product.id,
        image: src
      }
    })
  }
  
  submit = () => {
    const { updateMapping } = this.state
    const { onClickSave } = this.props;

    onClickSave(updateMapping)
  }

  handleUnlink = () => {

    //promt user to confirm
    if(!window.confirm('Are you sure you want to unlink all products?')){
      return
    }

    const { onBulkUnsyncProduct, sourceProducts } = this.props;

    let preselectedIds = _.map(sourceProducts, sourceProduct => {
      return sourceProduct.preselectedId
    })

    //remove null values
    preselectedIds = _.filter(preselectedIds, id => {
      return id !== null
    })

    onBulkUnsyncProduct(preselectedIds)
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  onSelectChange = (sourceProductId, selectedId) => {
    
    let { updateMapping } = this.state

    //find sourceProductId from updateMapping
    let updateMap = _.find(updateMapping, map => {
      return map.source_product_id === sourceProductId
    })

    if(updateMap){
      updateMap.selected_id = selectedId
    }

    this.setState({
      updateMapping
    })
  }

  render() {
    const { error, title, products, sourceProducts, updating } = this.props
    const { options } = this.state

    const IconOption = (props) => (

      <Option {... props}>
        <Row>
          <Col xs="auto">
            <img
              src={props.data.image}
              style={{"width": '30px'}}
            />
          </Col>
          <Col>
            <p>{props.data.label}</p>
          </Col>
        </Row>
      </Option>
    );

    const IconSingleValue = (props) => (
      <SingleValue {...props}>
        <img
              src={props.data.image}
              style={{"width": '30px', marginRight: '10px'}}
            />
        <span>{props.data.label}</span>
      </SingleValue>
    );

    return (
      <Modal style={{border: 'none'}} show={true} onHide={this.handleOnClose} size="md">
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0">{title}</h4>
        </Modal.Header>
          <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
            {
              error &&
              <Alert variant="danger">
                {
                  Array.isArray(error) ?
                  <ul>
                  {
                    error.map((err, i) => (
                      <li key={i}>
                        {err.msg}
                      </li>
                    ))
                  }
                  </ul>
                  :
                  error
                }
              </Alert>
            }
            <p>Select a product from your Giftr store associated with</p>
            {
              //iterate through sourceProducts
              _.map(sourceProducts, sourceProduct => {


                let imageUrl = noImage

                if(sourceProduct.images && sourceProduct.images && sourceProduct.images[0]){
                  imageUrl = sourceProduct.images[0].src
                }

                //find the preselectedId in linkedOptions
                let preselectedOption = _.find(options[1].options, option => {
                  return option.value === sourceProduct.preselectedId
                })

                if (!preselectedOption) {
                  preselectedOption = _.find(options[0].options, option => {
                    return option.value === sourceProduct.preselectedId
                  })
                }

                return (
                  <Row key={sourceProduct.id}>
                    <Col xs="auto">
                      <img
                        src={imageUrl}
                        style={{"height": '80px', marginBottom: '10px'}}
                      />
                    </Col>
                    <Col>
                      <p>{sourceProduct.title}</p>
                    </Col>
                    <Select
                      isSearchable
                      defaultValue={preselectedOption}
                      onChange={(selected) => {
                          this.onSelectChange(sourceProduct.id, selected.value)
                        }
                      }
                      components={{ DropdownIndicator: () => null, Option: IconOption, SingleValue: IconSingleValue }}
                      options={options}
                    />
                    {/* horizontal line */}
                    <hr style={{width: '100%', marginTop: '1em', marginBottom: '1em'}}/>
                  </Row>
                )
              })
            }
          </Modal.Body>
          <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
            <Button
              variant="danger"
              onClick={this.handleUnlink}
              disabled={ updating }
            >
              {updating ? 'Updating' : 'Unlink All'}
            </Button>
            <Button
              variant="warning"
              disabled={ updating }
              onClick={this.handleOnClose}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={this.submit}
              disabled={ updating }
            >
              {updating ? 'Updating' : 'Update All'}
            </Button>
          </Modal.Footer>
      </Modal>
    );
  }
}

export default connect((state, props) => {
  const { match } = props;
  return {};
}, {
})(BulkManualSyncModal);
