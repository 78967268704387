import qs from "qs";
import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const CLEAR_USERS = 'CLEAR_USERS';

function fetchUsersRequest() {
  return {
    type: FETCH_USERS_REQUEST,
  };
}

function fetchUsersSuccess(json) {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: {
      items: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchUsersFailure() {
  return {
    type: FETCH_USERS_FAILURE
  };
}

function shouldFetchUsers(state) {
  const results = state.users;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

function fetchUsersFromApi() {
  return dispatch => {
    dispatch(fetchUsersRequest());
    return api('/users')
      .then(json => {
        dispatch(fetchUsersSuccess(json));
      })
      .catch(err => {
        dispatch(fetchUsersFailure());
        dispatch(addError(err));
      });
  };
}

function fetchPMsFromApi() {
  return dispatch => {
    dispatch(fetchUsersRequest());
    return api('/users/filter/pm')
      .then(json => {
        dispatch(fetchUsersSuccess(json));
      })
      .catch(err => {
        dispatch(fetchUsersFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchPMs(){
  return (dispatch, getState) => {
    if (shouldFetchUsers(getState())) {
      return dispatch(fetchPMsFromApi());
    }
  };
}

export function fetchUsers() {
  return (dispatch, getState) => {
    if (shouldFetchUsers(getState())) {
      return dispatch(fetchUsersFromApi());
    }
  };
}

export function clearUsers() {
  return {
    type: CLEAR_USERS,
  };
}

