import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Loader from '../components/Loader';
import { Card, Button, Row, Col, Form, Alert } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import api from '../helpers/apiClient';
import * as profileActionCreators from '../redux/actions/profile';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import _ from 'lodash'
import TabHeader from '../components/TabHeader';
import VendorSelection from '../components/VendorSelection'
class OffDays extends Component {
    constructor(props) {
        super(props);

        const { user } = props
        let vendor = user.merchantId;

        if (user.role === 'admin' || user.role === 'staff') {
            vendor = 'Giftr'
        }

        let offStart1, offStart2, offStart3, offEnd1, offEnd2, offEnd3, disabled = false

        this.state = {
            vendor,
            showChangePasswordModal: false,
            offStart1, offStart2, offStart3, offEnd1, offEnd2, offEnd3, disabled
        }
    }

    componentDidMount() {
        let now = moment().utcOffset(8);
        if (now.hour() === 0 && now.minute() < 45) {
            this.setState({ disabled : true})
        }

        if (now.hour() === 7 && now.minute() < 45) {
            this.setState({ disabled : true})
        }

        this.fetchOffDays();
    }

    fetchOffDays = () => {
        this.setState({ loading: true })

        const { vendor } = this.state;

        const merchants = vendor.split('#,');
        const selectedMerchant = merchants.length > 0 ? merchants[0] : ''
        this.setState({merchants, selectedMerchant})

        return api(`/merchant/off_days`).then((result) => {
            if (result.off_days) {
                const offDaysStr = result.off_days[selectedMerchant]
                this.setState(this.getOffDaySlots(offDaysStr))
                this.setState({ offDays: result.off_days })
            }
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    handleAdminVendorChange = (e) => {
        const { offDays } = this.state

        const offDaysStr = offDays[e.target.value]
        this.setState(this.getOffDaySlots(offDaysStr))
        this.setState({ selectedMerchant: e.target.value })
    }

    handleMerchantSelectInputChange = (e) => {

        const { offDays = {}, selectedMerchant } = this.state
        const { value } = e.target;

        if(value === 'both'){
            this.setState({selectedMerchant: value})
            return
        }

        let toSave = offDays
        toSave[selectedMerchant] = this.getOffDaysStr()

        this.setState(this.getOffDaySlots(offDays[value]))
        this.setState({offDays: toSave, selectedMerchant: value})
    }


    handleSaveOffDays = () => {
        const { merchants, selectedMerchant } = this.state

        let toSave = {}

        if(selectedMerchant === 'both'){
            console.log(merchants)
            _.each(merchants, (merchant) => {
                toSave[merchant] = this.getOffDaysStr()
            })
        } else {
            toSave[selectedMerchant] = this.getOffDaysStr()
        }

        const url = '/merchant/off_days'

        return api(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                off_days: toSave
            })
        }).then((result) => {
            this.setState({offDaysSaved: true})
        }).catch(err => {
            if (err) {
                window.scrollTo(0, 0);
                if (err.validationError) {
                    throw new SubmissionError({
                        _error: err.validationError
                    });
                }
                else if (err.message) {
                    throw new SubmissionError({
                        _error: err.message
                    });
                }
                else {
                    throw new SubmissionError({
                        _error: 'Something bad happened'
                    });
                }
            }
        });
    }

    getOffDaysStr = () => {
        const { offStart1, offStart2, offStart3, offEnd1, offEnd2, offEnd3 } = this.state

        let offSlots = []
        if(offStart1 && offEnd1) {
            offSlots.push(`${offStart1.format('DD/MM/YYYY')}-${offEnd1.format('DD/MM/YYYY')}`)
        }

        if(offStart2 && offEnd2) {
            offSlots.push(`${offStart2.format('DD/MM/YYYY')}-${offEnd2.format('DD/MM/YYYY')}`)
        }

        if(offStart3 && offEnd3) {
            offSlots.push(`${offStart3.format('DD/MM/YYYY')}-${offEnd3.format('DD/MM/YYYY')}`)
        }

        let offSlotStr = _.join(offSlots, ',')

        return offSlotStr
    }

    getOffDaySlots = (offDaysStr) => {

        const offDaySplit = _.split(offDaysStr, ',')

        let offStart1, offStart2, offStart3, offEnd1, offEnd2, offEnd3

        for(let i = 0; i < offDaySplit.length; i++){

            let offDays = []
            const offDaysSplit = _.split(offDaySplit[i], '-')
            if(offDaysSplit.length === 2){
                offDays = [moment(offDaysSplit[0], 'DD/MM/YYYY'), moment(offDaysSplit[1], 'DD/MM/YYYY')]
            }

            if(i === 0 && offDays.length === 2){
                offStart1 = offDays[0]
                offEnd1 = offDays[1]
            } else if(i === 1 && offDays.length === 2){
                offStart2 = offDays[0]
                offEnd2 = offDays[1]
            } else if(i === 2 && offDays.length === 2){
                offStart3 = offDays[0]
                offEnd3 = offDays[1]
            }
        }

        return {offStart1, offStart2, offStart3, offEnd1, offEnd2, offEnd3}
    }

    render() {

        const { merchants, selectedMerchant, offDaysSaved, loading, disabled } = this.state
        const { user } = this.props

        const isAdmin = user.role === 'admin' || user.role === 'staff'

        return (
            <div>
                <TabHeader title="Off Days" tab_id="off_days" user={user} />
            {
                loading && <Loader />
            }
            {
                offDaysSaved &&
                <div className='alert alert-success'>
                    Applying changes. This may take up to 5 minutes to complete.
                </div>
            }
            <Row className="justify-content-center">
                { disabled &&
                    <Alert variant="warning">
                        This feature is temporarily unavailable at the following times:
                        <ul className="my-2">
                            <li>12.00am to 12.45am</li>
                            <li>7.00am to 7.45am</li>
                        </ul>
                        We apologize for the inconvenience and appreciate your patience.
                    </Alert>
                }
                    <Col lg={6}>
                        <Card className="tealCard shadow-sm">
                            <Card.Header className="tealCardHeader">Off Days</Card.Header>
                            <Card.Body className="mx-2">
                                {
                                    isAdmin &&
                                    <div className="col-xs-12 col-md-3" >
                                        <VendorSelection handleOnVendorChange={this.handleAdminVendorChange} selectedVendor={selectedMerchant} skipDefaultOption={true} rootClass="mb-3" />
                                    </div>
                                }
                            {   //For dual vendor
                                merchants && merchants.length > 1 &&
                                <Row>
                                    <Col lg={6}>
                                    <div className="vendor-selection mb-3" style={{textAlign: 'left'}}>
                                        <label style={{marginBottom: '5px'}}>Vendor</label>
                                        <Form.Select className="" name="merchant" defaultValue={selectedMerchant} onChange={this.handleMerchantSelectInputChange}>
                                        {
                                            merchants.map(merchant => (
                                                <option key={merchant} value={merchant}>{merchant}</option>
                                            ))
                                        }
                                        <option key="both" value="both">Both</option>
                                        </Form.Select>
                                    </div>
                                    </Col>
                                </Row>
                            }
                            <div className="d-grid gap-2">
                                <div className="d-grid gap-1">
                                    <label>Slot 1</label>
                                    <DateRangePicker
                                        minimumNights={0}
                                        displayFormat="D/M/YY"
                                        showClearDates={true}
                                        showDefaultInputIcon={true}
                                        isOutsideRange={() => false}
                                        startDateId="slot-1-start"
                                        endDateId="slot-1-end"
                                        startDate={this.state.offStart1} // momentPropTypes.momentObj or null,
                                        endDate={this.state.offEnd1} // momentPropTypes.momentObj or null,
                                        onDatesChange={({ startDate, endDate }) => this.setState({ offStart1: startDate, offEnd1: endDate, offDaysSaved: false })} // PropTypes.func.isRequired,
                                        focusedInput={this.state.focusedInput1} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={focusedInput => this.setState({ focusedInput1: focusedInput })} // PropTypes.func.isRequired,
                                    />
                                </div>
                                <div className="d-grid gap-1">
                                    <label>Slot 2</label>
                                    <DateRangePicker
                                        minimumNights={0}
                                        displayFormat="D/M/YY"
                                        showClearDates={true}
                                        showDefaultInputIcon={true}
                                        isOutsideRange={() => false}
                                        startDateId="slot-2-start"
                                        endDateId="slot-2-end"
                                        startDate={this.state.offStart2} // momentPropTypes.momentObj or null,
                                        endDate={this.state.offEnd2} // momentPropTypes.momentObj or null,
                                        onDatesChange={({ startDate, endDate }) => this.setState({ offStart2: startDate, offEnd2: endDate, offDaysSaved: false })} // PropTypes.func.isRequired,
                                        focusedInput={this.state.focusedInput2} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={focusedInput => this.setState({ focusedInput2: focusedInput })} // PropTypes.func.isRequired,
                                    />
                                </div>
                                <div className="d-grid gap-1">
                                    <label>Slot 3</label>
                                    <DateRangePicker
                                        minimumNights={0}
                                        displayFormat="D/M/YY"
                                        showClearDates={true}
                                        showDefaultInputIcon={true}
                                        isOutsideRange={() => false}
                                        startDateId="slot-3-start"
                                        endDateId="slot-3-end"
                                        startDate={this.state.offStart3} // momentPropTypes.momentObj or null,
                                        endDate={this.state.offEnd3} // momentPropTypes.momentObj or null,
                                        onDatesChange={({ startDate, endDate }) => this.setState({ offStart3: startDate, offEnd3: endDate, offDaysSaved: false })} // PropTypes.func.isRequired,
                                        focusedInput={this.state.focusedInput3} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={focusedInput => this.setState({ focusedInput3: focusedInput })} // PropTypes.func.isRequired,
                                    />
                                </div>
                            </div>
                                { !disabled &&
                                <div className="mt-2">
                                    <Button className="btn-br-6 mt-3 mx-auto w-100 text-uppercase" style={{width: '50%'}}
                                        onClick={this.handleSaveOffDays}
                                        disabled={offDaysSaved}
                                    >
                                        {offDaysSaved ? 'Saved' : 'Save'}</Button>
                                </div>
                                }

                            </Card.Body>
                        </Card>
                    </Col>
            </Row>
            </div>
        )
    }

}

export default connect((state, props) => {
    return {
        user: state.auth.user,
    };
}, {...profileActionCreators})(OffDays);
