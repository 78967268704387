import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import moment from 'moment'
import { Link } from 'react-router-dom';
import { Card, Row, Col, Button, Alert, Form } from 'react-bootstrap';
import Loader from '../components/Loader';
import _ from 'lodash'
import api from '../helpers/apiClient';
import { DateRangePicker,  DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import TabHeader from '../components/TabHeader';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class Promotion extends Component {
  constructor(props) {
    super(props);

    const { match } = props;

    this.state = {
      loading: match.params.promotionId,
      submitting: false,
      discountCode: "",
      minRequirements: "None",
      startDate: null,
      endDate: null,
      displayPromotionCreatedSuccess: false,
      oncePerCustomer: false,
      usageLimitValue: 0,
      editMode: match.params.promotionId,
      errorMessage: ""
    }
  }

  async componentDidMount() {

    const { match, user } = this.props;

    const isAdmin = user.role === 'admin'
    let prefix

    if(!isAdmin){
      let result = await api(`/merchant/prefix` , {
        method: 'GET',
      }, {'Accept': 'application/json'})

      prefix = result[0].prefix

      if(result.length > 0){
        prefix = result[0].prefix
        this.setState({merchants: result})
        this.setState({prefix, selectedMerchant: result[0].name})
      }
    }

    if(match.params.promotionId) {

      let result = await api(`/discounts/${match.params.promotionId}` , {
        headers: {
          'Content-Type': 'application/json',
        }
      })

      this.setState({loading: false})

      if(result){

        const isAdmin = user.role === 'admin'

        this.setState({
          discountCode: isAdmin ? result.title : (_.startsWith(result.title, prefix) ? result.title.slice(prefix.length) : result.title),
          oncePerCustomer: result.once_per_customer,
          type: result.value_type,
          value: -result.value,
          startDate: moment(result.starts_at, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
          endDate: moment(result.ends_at, "YYYY-MM-DDTHH:mm:ss.SSSZ"),
          priceRuleId: result.price_rule_id,
          discountCodeId: result.discount_code_id,
        })

        if(result.prerequisite_quantity_range && result.prerequisite_quantity_range.length > 0){
          this.setState({
            minValue: result.prerequisite_quantity_range[0].greater_than_or_equal_to,
            minRequirements : "Min Quantity"
          })
        }

        if(result.prerequisite_subtotal_range && result.prerequisite_subtotal_range.length > 0){
          this.setState({
            minValue: result.prerequisite_subtotal_range[0].greater_than_or_equal_to,
            minRequirements : "Min Amount"
          })
        }

        if(result.usage_limit && result.usage_limit > 0){
          this.setState({
            usageLimit: true,
            usageLimitValue : result.usage_limit
          })
        }
      }
    }
  }

  handleOnInputChange = (e) => {

    const { name, value, type } = e.target;

    if(type === 'checkbox'){
      this.setState({
        [name]: e.target.checked
      });
    } else if (name === 'discountCode'){

      const { user } = this.props;
      const isAdmin = user.role === 'admin'

      if(!isAdmin && value.length > 5) return
      else this.setState({
        [name]: value
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleMerchantSelectInputChange = (e) => {

    const { name, value, selectedIndex, options } = e.target;

    this.setState({
      [name]: value,
      selectedMerchant: options[selectedIndex].text
    });
  }

  handleSubmit = async () => {

    const {editMode, priceRuleId, discountCodeId, selectedMerchant, discountCode, prefix, type, value, minValue, minRequirements, startDate, endDate, oncePerCustomer, usageLimit, usageLimitValue} = this.state

    if(usageLimit && usageLimitValue <= 0){
      this.setState({ submitting: false, displayPromotionCreatedFail: true, displayPromotionCreatedSuccess: false, errorMessage: "Usage limit must be greater than 0"})
      return
    }

    const { user, match } = this.props;
    const isAdmin = user.role === 'admin'

    const { history } = this.props;

    let body = {
      title: isAdmin ? discountCode : prefix + discountCode,
      value_type: type,
      value: -value,
      starts_at: startDate.utcOffset(8).hour(0).format("YYYY-MM-DDTHH:mm:ssZ"),
      ends_at: endDate.utcOffset(8).hour(23).minute(59).format("YYYY-MM-DDTHH:mm:ssZ"),
      once_per_customer: oncePerCustomer
    }

    if(minRequirements === 'Min Amount'){
      body.prerequisite_subtotal_range = {
        "greater_than_or_equal_to": `${minValue}`
      }
    } else if (minRequirements === 'Min Quantity'){
      body.prerequisite_quantity_range = {
        "greater_than_or_equal_to": minValue
      }
    }

    if(usageLimit && usageLimitValue > 0){
      body.usage_limit = parseInt(usageLimitValue)
    }

    if(selectedMerchant){
      body.selectedMerchant = selectedMerchant
    }

    if(editMode){
      body.priceRuleId = priceRuleId
      body.discountCodeId = discountCodeId
      body.promotionId = match.params.promotionId
    }

    this.setState({ submitting: true })

    let result = await api(`/discounts` , {
      method: editMode ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })

    if(result.errors){
      this.setState({ submitting: false, displayPromotionCreatedFail: true, displayPromotionCreatedSuccess: false, errorMessage: "Error: Duplicate discount code. Please try a different code."})
    } else {
      this.setState({ submitting: false, displayPromotionCreatedFail: false, displayPromotionCreatedSuccess: true })
    }
  }

  render() {
    const { error, match, user } = this.props
    const { editMode, merchants, loading, submitting, discountCode, prefix, type, value, minValue, minRequirements, startDate, endDate, displayPromotionCreatedSuccess, displayPromotionCreatedFail, oncePerCustomer, usageLimit, usageLimitValue, errorMessage } = this.state

    const isAdmin = user.role === 'admin'

    let incomplete = false
    if(!value || !type || !discountCode){
      incomplete = true
    }

    return (
      <div>
        <TabHeader title="Create Promotion" tab_id="promotion_detail" user={user} />
        {
          loading &&
          <Loader />
        }
        {
            error &&
            <Alert variant="danger">
              {
                Array.isArray(error) ?
                <ul>
                {
                  error.map((err, i) => (
                    <li key={i}>
                      {err.msg}
                    </li>
                  ))
                }
                </ul>
                :
                error
              }
            </Alert>
          }
          {
            displayPromotionCreatedSuccess &&
            <div className="sticky-alert" style={{zIndex:'1000'}}>
              <Alert variant="success">
                <span>Promotion { editMode ? "updated" : "created" } successfully. </span>
                  <Link
                        to={{
                          pathname: `/promotions`,
                        }}
                  >Click here to go back.</Link>
              </Alert>
            </div>
          }
          {
            displayPromotionCreatedFail &&
            <div className="sticky-alert" style={{zIndex:'1000'}}>
              <Alert variant="danger">
                <span>{errorMessage}</span>
              </Alert>
            </div>
          }
        <form>
          <Row>
            <Col xl={7} className="d-grid gap-4">
              <Card className="tealCard shadow-sm">
                <Card.Header className="tealCardHeader">Discount Code*</Card.Header>
                <Card.Body className="grey-label label-strong d-grid gap-3 mx-2">
                  { //For dual vendor
                      merchants && merchants.length > 1 &&
                      <div>
                        <label style={{marginBottom: '5px'}}>Select merchant: </label>
                        <Form.Select className="form-control" name="prefix" onChange={this.handleMerchantSelectInputChange}>
                          {
                            merchants.map(merchant => (
                              <option key={merchant.prefix} value={merchant.prefix}>{merchant.name}</option>
                            ))
                          }
                        </Form.Select>
                      </div>
                  }
                  <Row>
                    {
                      prefix &&
                      <Col xs="auto"><span>{prefix}</span></Col>
                    }
                    <Col xs="auto">
                      <input type="text" className="form-control d-inline" id="discountCode" name="discountCode" value={`${discountCode}`} onChange={this.handleOnInputChange}/>
                      { !isAdmin && <small style={{color: '#5A5A5A'}}>{5 - discountCode.length} characters remaining.</small>}<br/>
                    </Col>
                  </Row>
                  <Row style={{color: '#333'}}>
                    <small>Customers will enter this discount code at checkout as it will be displayed on your product pages.</small><br />
                    <small>To generate list of codes for your own distribution, do contact your account manager or email marketplace@giftr.my (for Giftr MY) or marketplace@giftr.sg (for Giftr SG).</small>
                  </Row>
                </Card.Body>
              </Card>
              <Card className='tealCard shadow-sm'>
                <Card.Header className="tealCardHeader">Type*</Card.Header>
                <Card.Body className="d-grid gap-2 mx-2">
                  <div>
                    <label className='custom-radio' style={{marginTop: '2px', color: '#333'}} htmlFor="percentage"> Percentage
                      <input type="radio" id="percentage" value="percentage" name="type" checked={type === "percentage"} onChange={this.handleOnInputChange}/>
                      <span className="radio"></span>
                    </label>
                  </div>
                  <div>
                  <label className='custom-radio' style={{marginTop: '2px', color: '#333'}} htmlFor="fixedAmount"> Fixed Amount
                    <input type="radio" id="fixedAmount" value="fixed_amount" name="type" checked={type === "fixed_amount"} onChange={this.handleOnInputChange}/>
                    <span className="radio"></span>
                  </label>
                  </div>
                </Card.Body>
              </Card>
              <Card className='tealCard shadow-sm'>
                <Card.Header className="tealCardHeader">Value*</Card.Header>
                <Card.Body className="d-grid gap-2 m-2" style={{color: '#333'}}>
                  <span>Discount value</span>
                  <Row>
                    <Col xs="auto">
                      <div className="d-flex flex-row gap-2 align-items-center">
                        {type === 'fixed_amount' && (<span>{currency}</span>)}
                        <input type="number" className={`form-control d-inline ${type === 'fixed_amount' && 'promotion-prefix'} ${type === 'percentage' && 'promotion-surfix'}`} id="discountCode" name="value" value={value} onChange={this.handleOnInputChange}/>
                        {type === 'percentage' && (<span>%</span>)}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className='tealCard shadow-sm'>
                <Card.Header className="tealCardHeader">Active Dates</Card.Header>
                <Card.Body className="m-2" style={{color: '#333'}}>
                  <div style={{width: '50%'}}>
                  <DateRangePicker
                      small
                      minimumNights={0}
                      displayFormat="D/M/YY"
                      showClearDates={true}
                      showDefaultInputIcon={true}
                      isOutsideRange={() => false}
                      startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                      startDateId="active-dates-start"
                      endDateId="active-dates-end"
                      endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                      onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                    />
                  </div>
                </Card.Body>
              </Card>
              <Card className='tealCard shadow-sm'>
                <Card.Header className="tealCardHeader">Minimum Requirements</Card.Header>
                <Card.Body className="d-grid gap-2 mx-2" style={{color: '#333'}}>
                  <div>
                    <label className='custom-radio' style={{marginTop: '2px', color: '#333'}} htmlFor="none"> None
                      <input type="radio" id="none" name="minRequirements" value="None" checked={minRequirements=== 'None'} onChange={this.handleOnInputChange}/>
                      <span className="radio"></span>
                    </label>
                  </div>
                  <div>
                    <label className='custom-radio' style={{marginTop: '2px', color: '#333'}} htmlFor="minAmount">  Minimum purchase amount ({currency})
                      <input type="radio" id="minAmount" name="minRequirements" value="Min Amount" checked={minRequirements=== 'Min Amount'} onChange={this.handleOnInputChange}/>
                      <span className="radio"></span>
                    </label>
                    { minRequirements === "Min Amount" &&
                      <div className="d-grid gap-2">
                      <div className="d-flex flex-row gap-2 align-items-center mx-4 mt-2">
                        <span>{currency}</span>
                        <input type="number" className="form-control d-inline" style={{width:'auto'}}name="minValue" value={minValue} onChange={this.handleOnInputChange}/>
                      </div>
                      <div className="mx-5 px-1" style={{fontSize: 'smaller'}}>Applies to all products</div>
                      </div>
                    }
                  </div>
                  <div>
                    <label className='custom-radio' style={{marginTop: '2px', color: '#333'}} htmlFor="minQuantity"> Minimum quantity of items
                    <input type="radio" id="minQuantity" name="minRequirements" value="Min Quantity" checked={minRequirements=== 'Min Quantity'} onChange={this.handleOnInputChange}/>
                      <span className="radio"></span>
                    </label>
                    { minRequirements === "Min Quantity" &&
                      <div className="d-grid gap-2">
                      <div className="d-flex flex-row gap-2 align-items-center mx-4 mt-2">
                        <input type="number" className="form-control d-inline" style={{width: 'auto'}} name="minValue" value={minValue} onChange={this.handleOnInputChange}/><br/>
                      </div>
                      <div className="mx-4" style={{fontSize: 'smaller'}}>Applies to all products</div>
                      </div>
                    }
                  </div>
                </Card.Body>
              </Card>

              <Card className='tealCard shadow-sm'>
                <Card.Header className="tealCardHeader">Usage Limits</Card.Header>
                <Card.Body className="d-grid gap-2 mx-2" style={{color: '#333'}}>
                  <div>
                    <label className='custom-checkbox' htmlFor="usageLimit" style={{marginTop: '2px', color: '#333'}}> Limit number of times this discount can be used in total
                      <input type="checkbox" id="usageLimit" value="usageLimit" name="usageLimit" checked={usageLimit} onChange={this.handleOnInputChange}/>
                      <span className="checkmark checkmark-smaller"></span>
                    </label>
                    { usageLimit &&
                      <div className="d-grid gap-2">
                        <div className="d-flex flex-row gap-2 align-items-center mx-4 mt-2">
                          <input type="number" className="form-control"  style={{width: 'auto'}} name="usageLimitValue" value={usageLimitValue} onChange={this.handleOnInputChange}/><br/>
                        </div>
                      </div>
                    }
                  </div>
                  <div>
                    <label className='custom-checkbox' htmlFor="oncePerCustomer" style={{marginTop: '2px', color: '#333'}}> Limit to one use per customer
                      <input type="checkbox" onChange={this.handleOnInputChange} id="oncePerCustomer" value="true" name="oncePerCustomer" checked={oncePerCustomer}/>
                      <span className="checkmark checkmark-smaller"></span>
                    </label>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <div className="vr d-none d-xl-block col-xl-1 dashboard-vr" style={{maxHeight: '20em'}}></div>
            <Col>
              <div className="sticky-summary mt-4 mt-xl-0">
                <h4>Summary</h4>
                <div className="sticky-voucher"></div>
                  <div className="voucher-content">
                  <h4 style={{color: '#80d7cf'}}>{prefix}{discountCode}</h4>
                  <ul>
                    { type && value && <li>{type === 'fixed_amount' && currency}{value}{type === 'percentage' && '%'} off all products</li>}
                    { minRequirements != 'None' && minValue && <li>Minimum purchase of {minRequirements === 'Min Amount' && currency}{minValue}{minValue === 1 && minRequirements === 'Min Quantity' && ' items'}{minValue > 1 && minRequirements === 'Min Quantity' && ' items'}</li>}
                    { startDate && endDate && <li>Active from {startDate.format("D MMM")} to {endDate.format("D MMM")}</li>}
                    { usageLimit && usageLimitValue > 0 && <li>Limit to {usageLimitValue} uses</li> }
                    { oncePerCustomer && <li>One user per customer</li> }
                  </ul>
                </div>
              </div>
            </Col>
            <Row className="mt-5 mb-3">
              <Col className="w-100">
                <Button
                    className="w-100 btn-br-6"
                    variant="primary"
                    onClick={this.handleSubmit}
                    disabled={submitting || incomplete || displayPromotionCreatedSuccess}
                  >
                    {editMode ? (submitting ? 'UPDATING' : 'UPDATE') : (submitting ? 'CREATING' : 'CREATE')}
                </Button>
              </Col>
            </Row>
          </Row>
        </form>
      </div>
    );
  }
}

export default connect((state, props) => {
  const { match } = props;
  return {
    user: state.auth.user,
  };
}, {})(Promotion);
