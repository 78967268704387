import { Container, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickups } from '../redux/actions/pickups';
import _ from 'lodash';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import TabHeader from '../components/TabHeader';
import Loader from '../components/Loader'

const { SearchBar } = Search;

const Pickups = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { items: pickups, loading, loaded } = useSelector(state => state.pickups);
  const isAdmin = user && (user.role === 'admin' || user.role === 'staff')

  useEffect(() => {
    dispatch(fetchPickups());
  }, [dispatch])

  const renderDate = (cell) => {

    if(cell){
      return (
        <div>{moment(cell, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>
      )
    }
  }

  const dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = "2010-01-01";
    }

    if (!date2) {
      date2 = "2010-01-01";
    }

    if (order === "desc") {
      return moment(date2, "YYYY-MM-DD").unix() - moment(date1, "YYYY-MM-DD").unix();
    } else {
      return moment(date1, "YYYY-MM-DD").unix() - moment(date2, "YYYY-MM-DD").unix();
    }
  };

  const renderPeriod = (_, row) => {
    if(row.pickupStartTime && row.pickupEndTime) {
      return (
        `${row.pickupStartTime} - ${row.pickupEndTime}`
      )
    }
  }

  const renderAddress = (cell) => {
    return (
      <span>{cell.pick_name} <i data-html={true} data-tip={`
          <p>
            ${cell.pick_name}<br/>
            ${cell.pick_addr1}<br/>
            ${cell.pick_addr2}<br/>
            ${cell.pick_postcode}, ${cell.pick_city}<br/>
            ${cell.pick_state}, ${cell.pick_country}<br/>
            ${cell.pick_contact}<br/>
          </p>
      `} data-for="inventory-header"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="bottom" effect="solid" id="inventory-header" />
      </span>
    )
  }

  const renderShipments = (cell, row) => {
    if (row.shipments.length) {
      const shipment_lines = _.map(row.shipments, shipment => {
        return `${shipment.orderName} - ${shipment.awb}`
      })

      return (
        <span>{cell} <i data-html={true} data-tip={`
            <p>
              ${shipment_lines.join('<br/>')}
            </p>
        `} data-for="shipment-lines"><FontAwesomeIcon icon={solid('info-circle')} style={{ color: '#80d7cf' }} /></i><ReactTooltip place="bottom" effect="solid" id="shipment-lines" />
        </span>
      )
    }
  }

  const pickups_col = [
    { dataField: '_id', hidden: true, text: 'ID', exportCSV: false, },
    { dataField: 'created', headerStyle: { width: '120px', resize: 'horizontal' }, sort: true, sortFunc: dateSort, formatter: renderDate, text: 'Created', exportCSV: false, },
    { dataField: 'merchantId', headerStyle: { width: '120px', resize: 'horizontal' }, hidden: !isAdmin, text: 'Merchant' },
    { dataField: 'serviceName', headerStyle: { width: '120px', resize: 'horizontal' }, sort: true, text: 'Courier' },
    { dataField: 'pickupDate', headerStyle: { width: '120px', resize: 'horizontal' }, sort: true, sortFunc: dateSort, formatter: renderDate, text: 'Pickup date', exportCSV: false, },
    { dataField: 'pickupStartTime', headerStyle: { width: '120px', resize: 'horizontal' }, formatter: renderPeriod, text: 'Period' },
    { dataField: 'pickupAddress', headerStyle: { width: '120px', resize: 'horizontal' }, formatter: renderAddress, text: 'Pickup Address' },
    { dataField: 'totalShipments', headerStyle: { width: '120px', resize: 'horizontal' }, formatter: renderShipments, text: 'Shipments' },
    { dataField: 'totalWeight', headerStyle: { width: '120px', resize: 'horizontal' }, text: 'Weight (g)' },
  ]

  return (
    <>
      <TabHeader
        title="Pick-up Jobs"
        tab_id="pickups"
        user={user}
      />
      {
        loading &&
          <Loader />
      }
      {
        !loading && loaded &&
          <>
            <p>DHL eCommerce: For cancellation of pickup, please contact DHL Customer Service: +603 2779 2300 (Monday to Friday - 8am - 7pm, Weekends & PH - 9am - 6pm) and quote your Pickup Account ID.</p>
            <ToolkitProvider
              keyField='_id'
              data={pickups}
              columns={pickups_col}
              search
            >
              {
                props => (
                  <>
                    <Container fluid className="d-flex gap-3 flex-row-reverse">
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps }
                          style={{borderRadius:'25px'}} placeholder="Search"/>
                      </Col>
                    </Container>
                    <BootstrapTable
                      { ...props.baseProps }
                      pagination={paginationFactory({
                        showTotal: true,
                        sizePerPage: 20,
                      })}
                      bordered={ false }
                      hover
                      defaultSorted={[{
                        dataField: 'created',
                          order: 'desc',
                      }]}
                      noDataIndication={() => 'There is no data to display'}
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </>
      }
    </>
  )
}

export default Pickups;
