import {
  FETCH_BILLINGS_REQUEST,
  FETCH_BILLINGS_SUCCESS,
  FETCH_BILLINGS_FAILURE,
  CLEAR_BILLINGS,
} from '../actions/billings';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
  limit: null,
  next: null
};

export default function orders(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_BILLINGS:
      return {
        ...state,
        defaultState
      };
    case FETCH_BILLINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_BILLINGS_SUCCESS:

      let unselectableRows = []
      let unbilledRows = []
      let billedRows = []
      const { bills, first_bill_month } = action.payload.items
      for(let i = 0; i < bills.length; i++) {

        let bill = bills[i]
        if(bill.status !== 'unbilled' && bill.status !== 'billed'){
          unselectableRows.push(bill._id)
        }

        if(bill.status === 'unbilled'){
          unbilledRows.push(bill._id)
        }

        if(bill.status === 'billed' || bill.status === 'paid'){
          billedRows.push(bill._id)
        }
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        first_bill_month,
        items: [...bills],
        lastUpdated: action.payload.receivedAt,
        unselectableRows,
        unbilledRows,
        billedRows,
      };
    case FETCH_BILLINGS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
