import {
  SHIPPING_REQUEST,
  SHIPPING_REQUEST_FAILURE,
  SHIPPING_REQUEST_SUCCESS,
  UNDO_MAKE_ORDER_REQUEST,
  MAKE_ORDER_REQUEST,
  MAKE_ORDER_SUCCESS,
  MAKE_ORDER_FAILURE,
  MAKE_PAYMENT_FAILURE,
  MAKE_PAYMENT_REQUEST,
  MAKE_PAYMENT_SUCCESS,
  MAKE_DHL_SHIPMENT_REQUEST,
  MAKE_DHL_SHIPMENT_SUCCESS,
  MAKE_DHL_SHIPMENT_FAILURE,
  RESET_DATA,
  RESET_RATES
} from '../actions/shipping';

const defaultState = {
  loading: false,
  loaded: false,
  rates: [],
  order: null,
  shipments: [],
  payment: null
};

export default function orders(state = defaultState, action) {
  switch (action.type) {
    // case CLEAR_ORDER_DETAIL:
    //   return {
    //     ...state,
    //     defaultState
    //   };
    case SHIPPING_REQUEST:
      return {
        ...state,
        rates: [],
        shipments: [],
        loading: true,
      };
    case MAKE_ORDER_REQUEST:
    case MAKE_PAYMENT_REQUEST:
    case MAKE_DHL_SHIPMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SHIPPING_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        rates: action.payload.rates,
      };
    case SHIPPING_REQUEST_FAILURE:
    case MAKE_ORDER_FAILURE:
    case MAKE_PAYMENT_FAILURE:
    case MAKE_DHL_SHIPMENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case MAKE_ORDER_SUCCESS:
    case MAKE_DHL_SHIPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        shipments: action.payload.shipments
      }
    case MAKE_PAYMENT_SUCCESS: {
     return {
        ...state,
        loading: false,
        payment: action.payload.payment,
      }
    }
    case UNDO_MAKE_ORDER_REQUEST:
      return {
        ...state,
        loading: false,
        order: null,
        shipments: []
      }
    case RESET_DATA: {
      return {
        ...state,
        loading: false,
        loaded: true,
        rates: [],
        order: null,
        shipments: [],
        payment: null
      }
    }
    case RESET_RATES: {
      return {
        ...state,
        rates: []
      }
    }
    default:
      return state;
  }
}
