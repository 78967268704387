import { Modal, Card, Col, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ReactTooltip from 'react-tooltip';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SingleDatePicker } from 'react-dates';
import "react-dates/lib/css/_datepicker.css";
import moment from 'moment';
import _ from 'lodash';

import api from '../helpers/apiClient';
import Loader from "../components/Loader";
import GTRAlert from './GTRAlert';

const CreatePickupModal = ({ addPickupAddress, onClose, onConfirm, shipments = [] }) => {
  const [loading, setLoading] = useState(true);
  const [totalWeight, setTotalWeight] = useState(0);
  const [address, setAddress] = useState({ loaded: false });
  const [pickupDate, setPickupDate] = useState(null);
  const [focused, setFocused] = useState(null);
  const [error, setError] = useState('');
  const [holidays, setHolidays] = useState([]);
  const [confirmed, setConfirmed] = useState(false);
  const [pickupShipments] = useState(shipments);

  useEffect(() => {
    fetchAddress();
    fetchHolidays();

    let total_weight = _.reduce(pickupShipments, (weight, shipment) => {
      const line_items_total = _.reduce(shipment.lineItems, (weight, line_item) => {
        return weight + line_item.weight
      }, 0)

      return weight + line_items_total
    }, 0)

    setTotalWeight(total_weight);
  }, [])

  const fetchHolidays = async () => {
    const hol = await api(`/shipments/holidays`);
    setHolidays(hol)
    setLoading(false);
  }

  const fetchAddress = async () => {
    return api(`/profile`).then(function(profile){

      setAddress({
        ...profile,
        loaded: true,
      })

      if(!profile.name || !profile.address1 || !profile.postcode){
        return
      }
    })
  }

  const handleOnClickConfirm = async () => {
    let body = {};
    body.pickup_date = pickupDate.format("YYYY-MM-DD");
    body.pick_name = address.name
    body.pick_addr1 = address.address1
    body.pick_addr2 = address.address2
    body.pick_postcode = address.postcode
    body.pick_city = address.city
    body.pick_state = address.state
    body.pick_country = address.country
    body.pick_contact = address.contact
    body.total_shipments = pickupShipments.length
    body.merchantId = pickupShipments[0].merchantId
    body.serviceName = pickupShipments[0].serviceName
    body.weight = totalWeight;
    body.shipments = _.map(pickupShipments, shipment => {
      return {
        ...shipment,
        shipment_id: shipment._id
      }
    })

    if (moment().isSame(pickupDate, 'day')) {
      body.period = 'PM'
    } else {
      body.period = 'AM'
    }

    return api('/dhl/pickup', {
      method: 'POST',
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(body)
    }).then((result) => {
      onConfirm();
      setConfirmed(true);
    }).catch(err => {
      setError(err.message);
    })
  }

  const handleOnClose = () => {
    onClose();
  }

  const renderOrderId = (cell, row) => {

    return (
      <Link
        key={row._id}
        to={{
          pathname: `/orders/${row.orderId}`,
          state: {modal: true}
        }}
      >
        {row.orderName}
      </Link>
    )
  }

  const orderNameSort = (name1, name2, order) => {
    if (!name1) {
      name1 = 0;
    } else {
      name1 = parseInt(name1.substring(4))
    }

    if (!name2) {
      name2 = 0;
    } else {
      name2 = parseInt(name2.substring(4))
    }

    if (order === "desc") {
      return name2 - name1;
    } else {
      return name1 - name2;
    }
  }

  const renderLineItems = (cell) => {
    const items = _.map(cell, line_item => {
      return line_item.name
    })

    return (
      <div>
        <span data-tip={items.join('; ')}>{items.join('; ')}</span><ReactTooltip place="bottom"/>
      </div>
    )
  }

  const isOutsideRange = (date) => {

    let now = moment()
    let startDate = moment().startOf('day')
    let endDate = moment().endOf('day')
    if(now.hour() > 11){
      startDate.add(1, 'days')
      endDate.add(1, 'days')
    }

    if(startDate.day() === 0){
      endDate.add(5, 'days')
    } else if(startDate.day() === 6){
      endDate.add(6, 'days')
    } else if(startDate.day() === 5){
      endDate.add(6, 'days')
    } else if(startDate.day() === 4){
      endDate.add(6, 'days')
    } else {
      endDate.add(4, 'days')
    }

    let isHoliday = false;
    _.each(holidays, holiday => {
      if (date.isSame(moment(holiday, 'DD/MM/YYYY'), 'day')) {
        isHoliday = true;
      }
    })

    return isHoliday || date.day() == 0 || date.isBefore(startDate) || date.isAfter(endDate);
  }

  const shipments_col = [
    { dataField: '_id', hidden: true, text: 'ID', exportCSV: false, },
    { dataField: 'orderName', headerStyle: { width: '120px', resize: 'horizontal' }, sort: true, sortFunc: orderNameSort, formatter: renderOrderId, text: 'Order Id', exportCSV: false, },
    { dataField: 'lineItems', headerStyle: { width: '150px', resize: 'horizontal' }, sort: true, formatter: renderLineItems, text: 'Items' },
    { dataField: 'awb', exportCSV: true, headerStyle: { resize: 'horizontal', width: '150px', }, text: 'AWB', },
    { dataField: 'serviceName', sort: true, headerStyle: { width: '110px', resize: 'horizontal' }, text: 'Courier', exportCSV: false, },
  ]

  return (
    <Modal style={{ border: 'none' }} show={true} onHide={handleOnClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
        <h4>Request Pick-up</h4>
      </Modal.Header>
      <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{ borderTop: '1px solid #dee2e6' }}>
        {
          (!address.name || !address.address1 || !address.postcode) && address.loaded ?
            <Card className="redCard">
              <Card.Header className="redCardHeader">Pickup address not found</Card.Header>
              <Card.Body>
                Please enter your pickup address <a onClick={addPickupAddress} href="">here</a>.
              </Card.Body>
            </Card>
            :
            <>
              <Card className="tealCard">
                <Card.Header className="tealCardHeader">Shipments Selected</Card.Header>
                <Card.Body className="d-grid gap-2 label-strong grey-label">
                  <ToolkitProvider
                    keyField='_id'
                    data={pickupShipments}
                    columns={shipments_col}
                  >
                    {
                      props => (
                        <>
                          <BootstrapTable
                            { ...props.baseProps }
                            bordered={ false }
                            hover
                            defaultSorted={[{
                              dataField: 'orderName',
                                order: 'desc',
                            }]}
                          />
                        </>
                      )
                    }
                  </ToolkitProvider>
                </Card.Body>
              </Card>

              {
                loading &&
                  <Loader />
              }

              { holidays.length > 0 &&
              <Card className="tealCard">
                <Card.Header className="tealCardHeader">Select Pickup Date</Card.Header>
                <Card.Body className="d-grid gap-2 label-strong grey-label">
                  <SingleDatePicker
                    numberOfMonths={1}
                    block
                    placeholder='Pickup Date'
                    isOutsideRange={isOutsideRange}
                    displayFormat="D/M/YY"
                    date={pickupDate} // momentPropTypes.momentObj or null
                    onDateChange={(date) => setPickupDate(date)} // PropTypes.func.isRequired
                    focused={focused} // PropTypes.bool
                    onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                  />
                </Card.Body>
              </Card>
              }

              { address.loaded &&
              <Card className="tealCard">
                <Card.Header className="tealCardHeader">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>Pickup Address</span>
                    <Button variant="secondary" className="btn-sm" onClick={addPickupAddress}>
                      Edit
                    </Button>
                  </div>
                  </Card.Header>
                <Card.Body className="d-grid gap-2 label-strong grey-label">
                  <div className="d-grid gap-1">
                    <label>{address.name}</label>
                    {
                      address.company &&
                        <>{address.company}<br/></>
                    }
                    {address.address1},<br/>
                    {
                      address.address2 &&
                        <>{address.address2},<br/></>
                    }
                    {address.postcode}, {address.city},<br/>
                    {address.state}, {address.country}<br/>
                    {address.contact}
                  </div>
                </Card.Body>
              </Card>
              }

              <Card className="tealCard">
                <Card.Header className="tealCardHeader d-flex justify-content-between">
                  <span>Shipments: {pickupShipments.length}</span>
                  <span>Total Weight: {totalWeight || 0}g</span>
                </Card.Header>
                <Card.Body className="d-grid gap-2 label-strong grey-label">
                  {
                    _.map(pickupShipments, (shipment, i) => {
                      return (
                        <div key={i} className="mb-3">
                          <h5 className="d-inline-block" style={{ color: '#80d7cf' }}>{shipment.orderName}</h5>
                          <Col lg={6} className="d-grid mb-2 p-lg-0">
                            <label>Tracking Number</label>
                            <span>{shipment.awb}</span>
                          </Col>
                          <div>
                            <label>Items</label>
                            {
                              _.map(shipment.lineItems, (line_item, i) => {
                                return (
                                  <div key={i}>
                                    {line_item.name}
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </Card.Body>
              </Card>

            </>
        }
      </Modal.Body>
      <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
        { !confirmed &&
        <Button variant="primary" onClick={handleOnClickConfirm} disabled={!pickupDate}>
          Confirm
        </Button>
        }
        { confirmed &&
        <Button variant="secondary" onClick={onClose}>
          Done
        </Button>
        }
      </Modal.Footer>
      {
        error &&
          <GTRAlert className="m-0 text-center" variant="danger" alert={error} />
      }
    </Modal>
  )
}

export default CreatePickupModal;
