import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_MERCHANTSTORE_REQUEST = 'FETCH_MERCHANTSTORE_REQUEST';
export const FETCH_MERCHANTSTORE_SUCCESS = 'FETCH_MERCHANTSTORE_SUCCESS';
export const FETCH_MERCHANTSTORE_FAILURE = 'FETCH_MERCHANTSTORE_FAILURE';

function fetchMerchantStoreRequest() {
  return {
    type: FETCH_MERCHANTSTORE_REQUEST,
  };
}

function fetchMerchantStoreSuccess(json) {
  return {
    type: FETCH_MERCHANTSTORE_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchMerchantStoreFailure() {
  return {
    type: FETCH_MERCHANTSTORE_FAILURE
  };
}

function shouldFetchMerchantStore(state) {
  const results = state.merchantStore;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

export function fetchMerchantStoreFromApi(merchantId) {
  return dispatch => {
    dispatch(fetchMerchantStoreRequest(merchantId));
    return api(`/merchants/store/${merchantId}`)
      .then(json => {
        dispatch(fetchMerchantStoreSuccess(json));
      })
      .catch(err => {
        dispatch(fetchMerchantStoreFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchMerchantStore(merchantId) {
  return (dispatch, getState) => {
    if (shouldFetchMerchantStore(getState())) {
      return dispatch(fetchMerchantStoreFromApi(merchantId));
    }
  };
}
