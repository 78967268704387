import { useRef } from 'react'
import _ from 'lodash'
import { useDrag, useDrop } from 'react-dnd'
import { Row, Col, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const SortAddOnsRow = ({ moveField, addon, index }) => {
  const ref = useRef(null)
  const [{ isDragging }, drag] = useDrag({
    type: 'addon',
    item: { addon, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [{ handlerId }, drop] = useDrop({
    accept: 'addon',
    collect: monitor => ({
      handlerId: monitor.getHandlerId()
    }),
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveField(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <Row ref={ref} style={{opacity}} data-handler-id={handlerId} className="mx-2 align-items-center justify-content-center border mb-1 cursor-move">
      <Col xs={1} className='justify-content-center d-flex gap-2'>
        <div className="text-secondary d-flex align-items-center">
          <FontAwesomeIcon icon={solid('grip-vertical')}/>
        </div>
      </Col>
      <Col className="my-2 d-flex gap-2 align-items-center">
        <div style={{height: '70px', maxWidth: '70px'}} className="d-flex justify-content-center align-items-center">
          <img className="w-100" src={addon.images[0].thumbnail || addon.images[0].src}/>
        </div>
        <span>{addon.title}</span>
      </Col>
    </Row>
  )
}

export default SortAddOnsRow
