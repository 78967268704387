import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import { Container, Button, Col, Row, Badge, Modal, Card } from 'react-bootstrap';
import api from '../helpers/apiClient';
import * as shipmentsActionCreators from '../redux/actions/shipments'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import TabHeader from '../components/TabHeader';
import { exportAsPDFCN, exportAsPDFCNMulti } from '../helpers/htmlToCanvas';
import _ from 'lodash';
import OverlayLoader from '../components/OverlayLoader';
import ConfirmModal from '../components/ConfirmModal';
import CreatePickupModal from '../components/CreatePickupModal';
import PrintPickupHandoverModal from '../components/PrintPickupHandoverModal';
import Profile from './Profile';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class Shipments extends Component {
    constructor(props) {
        super(props);
        this.cnRef = createRef();
        this.cnMultiRef = createRef();
        this.state = {
            dhlLabel: '',
            dhlLabels: [],
            selectedShipments: [],
            shipmentsRequirePickup: [],
            printLoading: false,
            showPrintOption: false,
            shipmentToPrint: null,
            showConfirmCancelShipment: false,
            showCreatePickupModal: false,
            showPickupAddressModal: false,
            showPrintHandoverSheet: false,
            hasSenderAddress: false,
            senderAddress: {},
            showSenderAddressModal: false,
            senderAddressBack: '',
        }
    }

    componentDidMount() {
        const { fetchShipments } = this.props;
        fetchShipments();
    }

    onClickPrintDHLLabel = (e, shipment) => {
        e.preventDefault();
        e.stopPropagation();

        return this.fetchProfile().then(({ hasSenderAddress, senderAddress }) => {
            this.setState({
                showPrintOption: true,
                shipmentToPrint: shipment,
                hasSenderAddress,
                senderAddress,
                senderAddressBack: '',
            });
        })

    }

    printDHLLabel = async () => {
        const { shipmentToPrint } = this.state;
        this.setState({ printLoading: true });

        const {
          dhlDeliveryDepotCode,
          dhlPrimarySortCode,
          dhlSecondarySortCode,
          dhlShipmentId
        } = shipmentToPrint;

        if (dhlDeliveryDepotCode && dhlPrimarySortCode && dhlSecondarySortCode) {
          return api(`/dhl/label/custom/print?ids=${dhlShipmentId}&isDoc=false`).then(function(result) { // INFO: new method
            window.open(result, '_blank');
          }).then(() => {
            this.setState({ printLoading: false });
          })
        }


        let result = await api('/dhl/label/reprint', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                shipment_ids: [dhlShipmentId]
            })
        })

        const { labelReprintResponse: labelreprint } = result
        let content = ''

        if (labelreprint) {
            const shipmentItem = labelreprint.bd.shipmentItems[0]

            content = shipmentItem.content
        }

        this.setState({
            dhlLabel: content,
            printLoading: false,
        }, () => {
            exportAsPDFCN(this.cnRef.current)
        })
    }

    renderDownloadLink = (cell, row) => {
        if(row.awbLink) {
            return (
                <a href={row.awbLink} onClick={e => e.stopPropagation()} target="_blank" className="btn btn-primary btn-br-6" style={{color: 'white'}}>
                Print
            </a>
            )
        } else if (row.dhlShipmentId && row.status !== 'cancelled') {
            return (
                <a href="" onClick={(e) => this.onClickPrintDHLLabel(e, row)} className="btn btn-primary btn-br-6" style={{color: 'white'}}>
                Print
            </a>
            )
        }
    }

    renderCreatedAt = (cell, row) => {

        return (
            <div>{moment(row.created).format('DD-MM-YYYY')}</div>
        )
    }

    renderPickupDate = (cell, row) => {

        if(row.pickupDate){
            return (
                <div>{moment(row.pickupDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>
            )
        }
    }

    renderPrice = (cell, row) => {

        return `${currency} ${row.price}`
    }

    renderPaid = (cell, row) => {

        return row.status === 'Payment Done' ? 'Paid' : row.status
    }

    renderPickup = (cell, row) => {

        switch (cell) {
            case 'none':
                return <Badge bg="secondary" className="text-uppercase">none</Badge>
            case 'pending':
                return <Badge bg="warning" className="text-uppercase">pending</Badge>
            case 'success':
                return <Badge bg="success" className="text-uppercase">success</Badge>
            case 'cancelled':
                return <Badge bg="danger" className="text-uppercase">cancelled</Badge>
            default:
                break;
        }
    }

    renderDelivered = (cell, row) => {

        if(row.status === 'cancelled') {
            return <Badge bg="danger" className="text-uppercase">Cancelled</Badge>
        }

        if(row.delivered){
            return <Badge bg="success" className="text-uppercase">Delivered</Badge>
        }

        return <Badge bg="warning" className="text-uppercase">No</Badge>
    }

    renderOrderId = (cell, row) => {
        return (
            <Link
                key={row._id}
                to={{
                    pathname: `/${row.isProcurement ? 'procurement_' : ''}orders/${row.orderId}`,
                }}
            >
                {row.orderName}
            </Link>
        )
    }

    orderNameSort = (name1, name2, order) => {
        if (!name1) {
            name1 = 0;
        } else {
            name1 = parseInt(name1.substring(4))
        }

        if (!name2) {
            name2 = 0;
        } else {
            name2 = parseInt(name2.substring(4))
        }

        if (order === "desc") {
            return name2 - name1;
        } else {
            return name1 - name2;
        }
    }

    dateSort = (date1, date2, order) => {

        if (!date1) {
            date1 = "2010-01-01";
        }

        if (!date2) {
            date2 = "2010-01-01";
        }

        if (order === "desc") {
            return moment(date2, "YYYY-MM-DD").unix() - moment(date1, "YYYY-MM-DD").unix();
        } else {
            return moment(date1, "YYYY-MM-DD").unix() - moment(date2, "YYYY-MM-DD").unix();
        }
    };

    onRowSelect = (row, isSelected, e) => {
        let { selectedShipments, shipmentsRequirePickup } = this.state;

        if (isSelected) {
            selectedShipments.push({
                id: row._id,
                awb: row.awb,
                dhlDeliveryDepotCode: row.dhlDeliveryDepotCode,
                dhlPrimarySortCode: row.dhlPrimarySortCode,
                dhlSecondarySortCode: row.dhlSecondarySortCode,
            })

            if (row.pickup_status === 'none' || row.pickup_status === 'pending') {
                shipmentsRequirePickup.push({
                    id: row._id,
                    ...row,
                })
            }
        } else {
            selectedShipments = _.reject(selectedShipments, { id: row._id });
            shipmentsRequirePickup = _.reject(shipmentsRequirePickup, { id: row._id});
        }

        this.setState({ selectedShipments, shipmentsRequirePickup });
    }

    onSelectAll = (isSelected, rows) => {
        let { selectedShipments, shipmentsRequirePickup } = this.state;

        if (isSelected) {
            _.each(rows, function (row) {
                selectedShipments.push({
                    id: row._id,
                    awb: row.awb,
                    dhlDeliveryDepotCode: row.dhlDeliveryDepotCode,
                    dhlPrimarySortCode: row.dhlPrimarySortCode,
                    dhlSecondarySortCode: row.dhlSecondarySortCode,
                })
                if (row.pickup_status === 'none' || row.pickup_status === 'pending') {
                    shipmentsRequirePickup.push({
                        id: row._id,
                        ...row,
                    })
                }
            })
        } else {
            _.each(rows, function (row) {
                selectedShipments = _.reject(selectedShipments, { id: row._id });
                shipmentsRequirePickup = _.reject(shipmentsRequirePickup, { id: row._id});
            });
        }

        this.setState({ selectedShipments, shipmentsRequirePickup });

        return true;
    }

    handleClearSelected = () => {
        this.setState({ selectedShipments: [], shipmentsRequirePickup: [] });
    }

    handleBulkPrintDHL = async (isModal) => {
        this.setState({ printLoading: true });
        const { selectedShipments, shipmentToPrint } = this.state;

        let shipment_ids = []
        if (!isModal) {
            let { hasSenderAddress } = await this.fetchProfile();

            if (!hasSenderAddress) {
                this.setState({ printLoading: false, senderAddressBack: 'bulk_print' });
                return this.handleEditPickupAddress();
            }

            let uniqShipments = _.uniqBy(selectedShipments, "awb");

            let newCn = [], oldCn = [];
            _.each(uniqShipments, shipment => {
                if (shipment.dhlDeliveryDepotCode && shipment.dhlPrimarySortCode && shipment.dhlSecondarySortCode) {
                    newCn.push(shipment)
                } else {
                    oldCn.push(shipment)
                }
            })

            if (newCn.length > 0) { // INFO: new method
                api(`/dhl/label/custom/print?ids=${_.join(_.map(newCn, 'awb'), ',')}&isDoc=true`).then(function(result) {
                    window.open(result, '_blank');
                })
            }

            if (oldCn.length > 0) {
                shipment_ids = _.map(oldCn, shipment => {
                    return shipment.awb
                })

                api('/dhl/label/reprint', {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        shipment_ids
                    })
                }).then(result => {
                    if (result.labelReprintResponse) {
                        const labels = _.map(result.labelReprintResponse.bd.shipmentItems, item => {
                            return item.content
                        })

                        this.setState({
                            dhlLabels: labels
                        }, () => {
                            exportAsPDFCNMulti(this.cnMultiRef.current)
                        })
                    }
                    this.setState({ printLoading: false });
                })
            }

            return
        }

        const {
            dhlDeliveryDepotCode,
            dhlPrimarySortCode,
            dhlSecondarySortCode,
            dhlShipmentId
        } = shipmentToPrint;

        if (dhlDeliveryDepotCode && dhlPrimarySortCode && dhlSecondarySortCode) {
            return api(`/dhl/label/custom/print?ids=${dhlShipmentId}&isDoc=true`).then(function(result) { // INFO: new method
                window.open(result, '_blank');
            }).then(() => {
                this.setState({ printLoading: false });
            })
        }

        shipment_ids.push(dhlShipmentId)

        let result = await api('/dhl/label/reprint', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                shipment_ids
            })
        })

        if (result.labelReprintResponse) {
            const labels = _.map(result.labelReprintResponse.bd.shipmentItems, item => {
                return item.content
            })

            this.setState({
                dhlLabels: labels
            }, () => {
                exportAsPDFCNMulti(this.cnMultiRef.current)
            })
        }
        this.setState({ printLoading: false });
    }

    handleCancelShipment = async () => {
        const { fetchShipments } = this.props;
        const { shipmentToCancel } = this.state;

        let result = await api('/dhl/shipment/cancel', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ shipment: shipmentToCancel })
        })

        this.setState({ showConfirmCancelShipment: false, shipmentToCancel: null })
        fetchShipments();
    }

    onClickCancelShipment = (e, shipment) => {
        e.stopPropagation();

        this.setState({
            showConfirmCancelShipment: true,
            shipmentToCancel: shipment
        });
    }

    renderCancel = (_, row) => {
        if (row.dhlShipmentId &&
            row.status !== 'cancelled' &&
            !row.delivered &&
            row.pickup_status !== 'success') {
            return (
                <Button variant="danger" onClick={(e) => this.onClickCancelShipment(e, row)}>Cancel</Button>
            )
        }
    }

    handleOnClickShowPickupModal = () => {
        this.setState({ showCreatePickupModal: true });
    }

    handleOnClosePickupModal = () => {
        this.setState({ showCreatePickupModal: false });
    }

    handleAddPickupAddress = (e) => {
        e.preventDefault();
        this.setState({ showCreatePickupModal: false, showPickupAddressModal: true });
    }

    handleOnHidePickupAddressModal = () => {
        this.setState({ showPickupAddressModal: false });
    }

    handleBackToCreatePickup = () => {
        this.setState({ showCreatePickupModal: true, showPickupAddressModal: false });
    }

    handleOnConfirmPickup = () => {
        this.setState({ shipmentsRequirePickup: [] })
        const { fetchShipments } = this.props;
        fetchShipments();
    }

    handleOnClickPrintHandoverSheet = () => {
        this.setState({ showPrintHandoverSheet: true })
    }

    handleOnClosePrintHandoverSheet = () => {
        this.setState({ showPrintHandoverSheet: false })
    }

    handleEditPickupAddress = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({ showSenderAddressModal: true })
    }

    handleSenderAddressBack = (dest) => {
        this.setState({ showSenderAddressModal: false })

        if (dest === 'bulk_print') {
            return this.handleBulkPrintDHL();
        }

        return this.fetchProfile().then(({ hasSenderAddress, senderAddress }) => {
            this.setState({
                hasSenderAddress,
                senderAddress,
            });
        })
    }

    fetchProfile = async () => {
        let profile = await api(`/profile`);

        let hasSenderAddress = true;
        let senderAddress = profile;

        if(!profile.name || !profile.address1 || !profile.postcode){
            hasSenderAddress = false;
            senderAddress = {};
        }

        return { hasSenderAddress, senderAddress }
    }

    render() {

        const { shipments: { loading, loaded, items }, user, match} = this.props
        const { dhlLabel, selectedShipments, dhlLabels, printLoading, showPrintOption, showConfirmCancelShipment, shipmentToPrint, shipmentToCancel, shipmentsRequirePickup, showCreatePickupModal, showPickupAddressModal, showPrintHandoverSheet, hasSenderAddress, senderAddress, showSenderAddressModal, senderAddressBack } = this.state;

        let shipmentToCancelOrders = [];
        if (shipmentToCancel) {
            shipmentToCancelOrders = _.uniqBy(_.map(shipmentToCancel.lineItems, lineItem => {
                return {
                    orderName: lineItem.orderName || shipmentToCancel.orderName,
                }}), "orderName");
        }

        const options = {
            showTotal: true,
            sizePerPage: 20,
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '25', value: 25
            }, {
                text: '50', value: 50
            }, {
                text: 'All', value: items.length
            }],
        };

        let unselectable = [];
        _.each(items, item => {
            if (item.awbLink || item.status === 'cancelled') {
                unselectable.push(item._id);
            }
        })

        let selectRowProp = {
            mode: "checkbox",
            selected: selectedShipments ? _.map(selectedShipments, "id") : [],
            clickToSelect: true,
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll,
            nonSelectable: unselectable,
            headerColumnStyle: { width: '30px' },
        };

        const columns = [
            { dataField: 'lineItem_id', hidden: true, text: 'ID', exportCSV: false, },
            { dataField: 'orderName', headerStyle: { width: '120px', resize: 'horizontal' }, sort: true, sortFunc: this.orderNameSort, formatter: this.renderOrderId, text: 'Order Id', exportCSV: false, },
            { dataField: 'merchantId', headerStyle: { width: '160px', resize: 'horizontal' }, sort: true, text: 'Vendor', exportCSV: false, },
            { dataField: 'shippingFee',  sort: true, text: 'Shipping Fee', exportCSV: true, hidden: true},
            { dataField: 'pickup_status', sort: true, headerStyle: { width: '100px', resize: 'horizontal' }, formatter: this.renderPickup, text: 'Pick-up', exportCSV: false, },
            { dataField: 'pickupDate', headerStyle: { width: '120px', resize: 'horizontal' }, sort: true, sortFunc: this.dateSort, formatter: this.renderPickupDate, text: 'Pick-up date', exportCSV: false, },
            { dataField: 'serviceName', sort: true, headerStyle: { width: '140px', resize: 'horizontal' }, text: 'Courier', exportCSV: false, },
            // { dataField: 'paid', sort: true, headerStyle: { width: '100px', resize: 'horizontal' }, formatter: this.renderPaid, text: 'Paid', exportCSV: false, },
            { dataField: 'delivered', sort: true, headerStyle: { width: '100px', resize: 'horizontal' }, formatter: this.renderDelivered, text: 'Delivered', exportCSV: false, },
            { dataField: 'shipmentStatus', sort: true, text: 'Delivery Status', headerStyle: { resize: 'horizontal' }, exportCSV: false, },
            { dataField: 'awb', exportCSV: true, headerStyle: { resize: 'horizontal', width: '180px', }, text: 'AWB', },
            { dataField: 'awbLink', headerStyle: { width: '100px', resize: 'horizontal' }, formatter: this.renderDownloadLink, text: 'Print', exportCSV: false, },
            { dataField: 'cancel', headerStyle: { width: '100px', resize: 'horizontal' }, text: 'Cancel', formatter: this.renderCancel, exportCSV: false, },
        ]

        return (
            <>
                {
                    printLoading &&
                        <OverlayLoader />
                }
                <TabHeader
                    title="Courier Shipments"
                    tab_id="shipments"
                    children={
                        <>
                            <Button variant="primary" disabled={shipmentsRequirePickup.length === 0} onClick={this.handleOnClickShowPickupModal} className="btn-br-6">Request Pick-up</Button>
                            <Button variant="primary" disabled={selectedShipments.length === 0} onClick={this.handleOnClickPrintHandoverSheet} className="btn-br-6">Print Pick-up Handover Sheet</Button>
                            <Link
                                className="btn btn-secondary btn-br-6"
                                to={{
                                    pathname: `/pickups`,
                                }}
                            >Pick-up Jobs</Link>
                            <Button variant="primary" disabled={selectedShipments.length <= 1} onClick={() => this.handleBulkPrintDHL()} className="btn-br-6">Bulk Print as Document</Button>
                        </>
                    }
                    user={user}
                />
                {
                    loading &&
                    <Loader />
                }
                {
                    !loading && items &&
                    <ToolkitProvider
                      keyField='_id'
                      data={items}
                      columns={columns}
                      exportCSV={{
                        exportAll: false
                      }}
                      search
                    >
                      {
                        props => (
                          <>
                            <Container fluid className="mb-3 mt-4">
                                <Row>
                                    <Col md={8}>
                                        <ExportCSVButton
                                            className="btn-info"
                                            style={{borderRadius:'8px'}}
                                            { ...props.csvProps }
                                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                                            Export current page to CSV</ExportCSVButton>
                                    </Col>
                                    <Col md={4}>
                                        <SearchBar { ...props.searchProps }
                                            style={{borderRadius:'25px'}} placeholder="Search"/>
                                    </Col>
                                </Row>
                            </Container>
                            <div className="d-flex gap-2 align-items-center">
                                <p className="mb-0 small">Selected: {selectedShipments.length}</p>
                                <Button variant="link" size="sm" className="p-0" onClick={this.handleClearSelected}>Clear</Button>
                            </div>
                            <BootstrapTable
                                { ...props.baseProps }
                                selectRow={selectRowProp}
                                pagination={paginationFactory(options)}
                                bordered={ false }
                                hover
                                defaultSorted={[{
                                    dataField: 'orderName',
                                        order: 'desc',
                                }]}
                                noDataIndication={() => 'There is no data to display'}
                            />
                          </>
                        )
                      }
                    </ToolkitProvider>
                }
                {
                    dhlLabel &&
                        <div className='position-relative'>
                            <div className="text-center position-absolute" style={{left: '-9999px', top: '-9999px'}}>
                                <div ref={this.cnRef}>
                                    <div className="cn-container">
                                        <img src={`data:image/png;base64, ${dhlLabel}`}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                {
                    <div className='position-relative' ref={this.cnMultiRef}>
                        {
                            _.map(dhlLabels, (label, i) => {
                                return (
                                    <div key={i} className="cn-container text-center position-absolute" style={{left: '-9999px', top: '-9999px'}}>
                                        <img src={`data:image/png;base64, ${label}`}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    showPrintOption &&
                        <Modal style={{top: '15%'}} show={true} onHide={() => this.setState({ showPrintOption: false })} size="lg">
                            <Modal.Header closeButton>
                                <h5 className="mb-0">Select Print Option</h5>
                            </Modal.Header>
                            <Modal.Body className="d-grid gap-4">
                                <Card className="tealCard shadow-sm">
                                    <Card.Header className="tealCardHeader">Sender Address</Card.Header>
                                    <Card.Body className="d-grid gap-2 label-strong grey-label">
                                        {
                                            !hasSenderAddress && !loading &&
                                                <Card className="redCard">
                                                    <Card.Header className="redCardHeader">Sender address not found</Card.Header>
                                                    <Card.Body>
                                                        Please enter your address <a onClick={this.handleEditPickupAddress} href="">here</a>.
                                                    </Card.Body>
                                                </Card>
                                        }
                                        {
                                            hasSenderAddress && senderAddress.name &&
                                                <Card className="position-relative">
                                                    <Button variant="secondary" className="btn-sm position-absolute btn-sm end-0 top-0 m-3" onClick={this.handleEditPickupAddress}>
                                                        Edit
                                                    </Button>
                                                    <Card.Body className="d-grid gap-1">
                                                        <label>{senderAddress.name}</label>
                                                        {
                                                            senderAddress.company &&
                                                                <>{senderAddress.company}<br/></>
                                                        }
                                                        {senderAddress.address1},<br/>
                                                        {
                                                            senderAddress.address2 &&
                                                                <>{senderAddress.address2},<br/></>
                                                        }
                                                        {senderAddress.postcode}, {senderAddress.city},<br/>
                                                        {senderAddress.state}, {senderAddress.country}<br/>
                                                        {senderAddress.contact}
                                                    </Card.Body>
                                                </Card>
                                        }
                                    </Card.Body>
                                </Card>
                                <Card className="tealCard shadow-sm">
                                    <Card.Header className="tealCardHeader">AWB</Card.Header>
                                    <Card.Body className="d-grid gap-2 label-strong grey-label">
                                        <p>{shipmentToPrint.orderName} - {shipmentToPrint.dhlShipmentId}</p>
                                    </Card.Body>
                                </Card>
                                <div>
                                <Button disabled={!hasSenderAddress} className='me-1' onClick={this.printDHLLabel}>Print as Label</Button>
                                <Button disabled={!hasSenderAddress} className='me-1' onClick={() => this.handleBulkPrintDHL(true)}>Print as Document</Button>
                                </div>
                            </Modal.Body>
                        </Modal>
                }
                {
                    showConfirmCancelShipment &&
                        <ConfirmModal onClickYes={this.handleCancelShipment} onClose={() => this.setState({ showConfirmCancelShipment: false })} title="Cancel Shipment">
                            <p>Are you sure you want to cancel this shipment?</p>
                            { shipmentToCancelOrders.map(order => (
                                <p key={order.orderName}>{order.orderName} - {shipmentToCancel.dhlShipmentId}</p>
                            ))}
                        </ConfirmModal>
                }
                {
                    showCreatePickupModal &&
                        <CreatePickupModal shipments={shipmentsRequirePickup} addPickupAddress={this.handleAddPickupAddress} onConfirm={this.handleOnConfirmPickup} onClose={this.handleOnClosePickupModal}/>
                }
                {
                    showPickupAddressModal &&
                        <Modal show={true} onHide={this.handleOnHidePickupAddressModal} size="lg">
                            <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
                                <h4 className="mb-0">Edit Pickup Address</h4>
                            </Modal.Header>
                            <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{ borderTop: '1px solid #dee2e6' }}>
                                <Profile showHeader={false} match={match} prevModal="Request Pick-up" backToPrevModal={this.handleBackToCreatePickup}/>
                            </Modal.Body>
                        </Modal>
                }
                { showPrintHandoverSheet &&
                    <PrintPickupHandoverModal onClose={this.handleOnClosePrintHandoverSheet} shipments={selectedShipments} />
                }
                { showSenderAddressModal &&
                  <Modal show={true} onHide={this.handleSenderAddressBack} size="lg" dialogClassName="modal-shadow">
                    <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
                      <h4 className="mb-0">Edit Sender Address</h4>
                    </Modal.Header>
                    <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{ borderTop: '1px solid #dee2e6' }}>
                      Sender Address is incomplete. Please fill in your address details below.
                      <Profile showHeader={false} showPassword={false} match={match} prevModal="Print CN" backToPrevModal={this.handleSenderAddressBack} backDest={senderAddressBack}/>
                    </Modal.Body>
                  </Modal>
                }
            </>
        )
    }

}

export default connect((state, props) => {

    return {
        shipments: state.shipments,
        user: state.auth.user,
    };
}, shipmentsActionCreators)(Shipments);
