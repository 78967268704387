import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import GTRModal from './GTRModal';
import OrderDetail from './OrderDetail';

class OrderDetailModal extends Component {
  render() {
    const { history } = this.props;
    return (
      <GTRModal history={history} title={"Order Detail"}>
        <OrderDetail {...this.props} />
      </GTRModal>
    );
  }
}

export default OrderDetailModal;