import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import Input from './Input';
import Select from './Select';
import Loader from './Loader';
import config from '../config'

class RedeemGiftcardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balanceCode: undefined
    }
  }

  componentDidMount() {
  }

  submit = (values) => {
    const { balanceCode } = this.state
    const { onClickSave, giftcardPrefix } = this.props;

    onClickSave(giftcardPrefix + balanceCode)
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  onChangeBalanceCode = (value) => {
    this.setState({balanceCode: value.target.value})
  }

  render() {
    const { handleSubmit, error, match, giftcardPrefix, title, redeeming, redeemError } = this.props
    const { file, invoiceName, balanceCode } = this.state
    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose} size="md">
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0">{title}</h4>
        </Modal.Header>
        <form className="d-grid" onSubmit={handleSubmit(this.submit)}>
          <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
            {
              error &&
              <Alert variant="danger">
                {
                  Array.isArray(error) ?
                  <ul>
                  {
                    error.map((err, i) => (
                      <li key={i}>
                        {err.msg}
                      </li>
                    ))
                  }
                  </ul>
                  :
                  error
                }
              </Alert>
            }
            <p>Please enter the remaining code below:</p>
            <Row>
              <Col xs="auto"><span>{giftcardPrefix}</span></Col>
              <Col xs="auto">
                <input type="text" className="form-control d-inline" onChange={this.onChangeBalanceCode}/>
              </Col>
            </Row>
            {
              redeemError &&
              <div className="text-center mt-3">
                <h5 className="mb-0" style={{color: '#d15d5d'}}><FontAwesomeIcon icon={solid('warning')}/> Unable to redeem the giftcard, please try again.</h5>
              </div>
            }
          </Modal.Body>
          <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
            <Button
              variant="success"
              type="submit"
              disabled={ redeeming || !balanceCode }
            >
              {redeeming ? 'Redeeming' : 'Redeem'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

RedeemGiftcardModal = reduxForm({
  form: 'AttachedRevisedInvoice',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(RedeemGiftcardModal);

export default connect((state, props) => {
  const { match } = props;
  return {};
}, {
})(RedeemGiftcardModal);
