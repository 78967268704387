import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Col, Row, Button, Alert } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import Input from './Input';
import Select from './Select';
import Loader from './Loader';
import config from '../config'

class ResendGiftcardModal extends Component {
  constructor(props) {
    super(props);
    const { receiverEmail, receiverMobile } = props
    this.state = {
      email: receiverEmail,
      mobile: receiverMobile
    };
  }

  componentDidMount() {
  }

  submit = (values) => {
    const { email, mobile } = this.state
    const { onClickSave } = this.props;

    onClickSave(email, mobile)
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleOnInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    const { handleSubmit, error, match, title, loading, redeemError } = this.props
    const { email, mobile } = this.state
    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose} size="md">
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(this.submit)}>
          <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
            {
              error &&
              <Alert variant="danger">
                {
                  Array.isArray(error) ?
                  <ul>
                  {
                    error.map((err, i) => (
                      <li key={i}>
                        {err.msg}
                      </li>
                    ))
                  }
                  </ul>
                  :
                  error
                }
              </Alert>
            }
            <div className="d-grid gap-1">
              <label htmlFor="email">Email address</label>
              <input type="email" className="form-control" id="email" name="email" placeholder="Enter email" value={email} onChange={this.handleOnInputChange}/>
            </div>

            <div className="d-grid gap-1">
              <label htmlFor="email">Mobile number</label>
              <input type="text" className="form-control" id="mobile" name="mobile" placeholder="Enter mobile number" value={mobile} onChange={this.handleOnInputChange}/>
            </div>
          </Modal.Body>
          <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
            <Button
              variant="warning"
              type="submit"
              disabled={ loading || (!email && !mobile) }
            >
              {loading ? 'Resending' : 'Resend'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

ResendGiftcardModal = reduxForm({
  form: 'ResendGiftcard',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(ResendGiftcardModal);

export default connect((state, props) => {
  const { match } = props;
  return {};
}, {
})(ResendGiftcardModal);
