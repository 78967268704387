import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_MERCHANTS_REQUEST = 'FETCH_MERCHANTS_REQUEST';
export const FETCH_MERCHANTS_SUCCESS = 'FETCH_MERCHANTS_SUCCESS';
export const FETCH_MERCHANTS_FAILURE = 'FETCH_MERCHANTS_FAILURE';
export const CLEAR_MERCHANTS = 'CLEAR_MERCHANTS';

function fetchMerchantsRequest() {
  return {
    type: FETCH_MERCHANTS_REQUEST,
  };
}

function fetchMerchantsSuccess(json) { 
  return {
    type: FETCH_MERCHANTS_SUCCESS,
    payload: {
      items: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchMerchantsFailure() {
  return {
    type: FETCH_MERCHANTS_FAILURE
  };
}

function shouldFetchMerchants(state) {
  const results = state.merchants;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

function fetchMerchantsFromApi() {
  return dispatch => {
    dispatch(fetchMerchantsRequest());
    return api('/lookup/merchants')
      .then(json => {
        dispatch(fetchMerchantsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchMerchantsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchMerchants() {
  return (dispatch, getState) => {
    if (shouldFetchMerchants(getState())) {
      return dispatch(fetchMerchantsFromApi());
    }
  };
}

export function clearMerchants() {
  return {
    type: CLEAR_MERCHANTS,
  };
}