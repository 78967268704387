import React from 'react';
import { Loader as RLoader } from 'react-loaders';
import PropTypes from 'prop-types';

const Loader = ({ isVerticalCenter }) => {
  return (
    isVerticalCenter ?
    <div className="myloader v-center">
      <div className="text-center">
        <RLoader active type="line-scale" color="#2BCCB1" />
      </div>
    </div>
    :
    <div className="text-center">
      <RLoader active type="line-scale" color="#2BCCB1" />
    </div>
  );
}

Loader.propTypes = {
  isVerticalCenter: PropTypes.bool,
};

export default Loader;
