import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { Button, Alert, Row, Col, Container } from 'react-bootstrap';
import ConfirmModal from '../components/ConfirmModal';
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import * as usersActionCreators from '../redux/actions/users';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

const { SearchBar } = Search;

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reload: false,
      error: null,
      showConfirmModal: false,
      _id: null,
      email: null
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.fetchUsers(location);
  }

  componentWillReceiveProps(nextProps) {
    const { location: prevLocation } = this.props;
    const { location, history } = nextProps;
    if (location.state && location.state.reload != null &&
      history.action !== 'POP' &&
      location.state !== prevLocation.state) {
      this.setState({ reload: location.state.reload });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { reload: prevReload } = this.state;
    const { reload } = nextState;
    if (reload && reload !== prevReload) {
      const { location } = nextProps;
      this.fetchUsers(location);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { reload: prevReload } = prevState;
    const { reload } = this.state;
    if (reload && reload !== prevReload) {
      this.setState({ reload: false });
    }
  }

  fetchUsers = (location) => {
    const { fetchUsers } = this.props;
    const { merchantId, email, role, page, limit } = qs.parse(location.search.substr(1));
    this.setState({ error: null });
    fetchUsers(merchantId, email, role, page, limit);
  }

  handleOnClickRemoveButton = (_id, email) => {
    this.setState({
      showConfirmModal: true,
      _id,
      email
    });
  }

  handleOnClickTestSendButton = (email, contact) => {
    return api(`/users/testSend`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, contact })
    }).then(() => {

      let text = ''
      if (email) {
        text = `Test send email has been sent to ${email}.`;
      }

      if(contact) {
        text = text + ` SMS has been sent to ${contact}`;
      }
      alert(text);
    }).catch(err => {
      if (err) {
        window.scrollTo(0, 0);
        console.log(err);
      }
    })
  }

  handleOnCloseConfirmModal = () => {
    this.setState({
      showConfirmModal: false,
      _id: null,
      email: null
    });
  }

  deleteUser = () => {
    this.handleOnCloseConfirmModal();
    const { _id } = this.state;
    return api(`/users/${_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      this.fetchUsers(location);
    }).catch(err => {
      if (err) {
        window.scrollTo(0, 0);
        if (err.validationError) {
          this.setState({ error: err.validationError });
        }
        else if (err.message) {
          this.setState({ error: err.message });
        }
        else {
          this.setState({ error: err });
        }
      }
    });
  }

  renderDetailLink = (cell, row) => {
    return (
      <Link
        className="merchant-modal"
        key={row._id}
        to={{
          pathname: `/users/${row._id}`,
          state: { modal: true }
        }}
      >
        {row._id}
      </Link>
    )
  }

  renderRemoveButton = (cell, row) => {
    return (
      <Button variant="danger" onClick={() => this.handleOnClickRemoveButton(row._id, row.email)}>Delete</Button>
    )
  }

  renderTestSendButton = (cell, row) => {
    return (
      <Button variant="primary" onClick={() => this.handleOnClickTestSendButton(row.email, row.contact)}>Test Send</Button>
    )
  }

  render() {
    const { users: { loading, loaded, items } } = this.props;
    const { showConfirmModal, email, error } = this.state;

    const options = {
      showTotal: true,
      sizePerPage: 20,
      hideSizePerPage: true,
    };

    const columns = [
      {
        dataField: '_id',
        text: 'Id',
        sort: true,
        formatter: this.renderDetailLink,
        headerStyle: {
          resize: 'horizontal'
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
        headerStyle: {
          resize: 'horizontal'
        }
      },
      {
        dataField: 'contact',
        text: 'Contact',
        sort: true,
        headerStyle: {
          resize: 'horizontal'
        }
      },
      {
        dataField: 'role',
        text: 'Role',
        sort: true,
        headerStyle: {
          resize: 'horizontal'
        }
      },
      {
        dataField: 'merchantId',
        text: 'Merchant',
        sort: true,
        headerStyle: {
          resize: 'horizontal'
        }
      },
      {
        dataField: 'supplierPrefix',
        text: 'Supplier Prefix',
        sort: true,
        headerStyle: {
          resize: 'horizontal'
        }
      },
      {
        text: 'Action',
        formatter: this.renderRemoveButton
      },
      {
        text: 'Test Send',
        formatter: this.renderTestSendButton
      },
    ];

    return (
      <div>
        <div className="row mt-3 mb-4">
          <div className="col-xs-12 col-md-9">
            <h2 className="sub-header">Users</h2>
          </div>
        </div>
        {
          loading &&
          <Loader />
        }
        {
          error &&
          <Alert variant="danger">
            {
              Array.isArray(error) ?
              <ul>
              {
                error.map((err, i) => (
                  <li key={i}>
                    {err.msg}
                  </li>
                ))
              }
              </ul>
              :
              error
            }
          </Alert>
        }
        {
          !loading && items &&
          <>
          <Container fluid className="my-3 d-flex gap-3 flex-column flex-wrap align-items-lg-end">
            <Col sm="auto">
            <Link
              className="btn btn-primary btn-br-6 w-100"
              to={{
                pathname: `/users/create`,
                state: {modal: true}
              }}
            >
              Create User
            </Link>
            </Col>
          </Container>
            <ToolkitProvider
              keyField='_id'
              data={items}
              columns={columns}
              search
            >
              {
                props => (
                  <>
                    <Container fluid className="mb-3 mt-4 d-flex gap-3 justify-content-end flex-row flex-wrap">
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps } style={{borderRadius:'25px'}} />
                      </Col>
                    </Container>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      pagination={paginationFactory(options)}
                      hover
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </>
        }
        {
          showConfirmModal &&
          <ConfirmModal onClickYes={this.deleteUser} onClose={this.handleOnCloseConfirmModal} title="Delete User">
            <p>Confirm delete this user ({email})?</p>
          </ConfirmModal>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    users: state.users,
  };
}, usersActionCreators)(Users);
