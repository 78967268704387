import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { Modal, Button, Card, Row, Col, Form } from 'react-bootstrap';
import Loader from './Loader';
import moment from 'moment';
import _ from 'lodash';
import api from "../helpers/apiClient";
import BootstrapTable from 'react-bootstrap-table-next';
import * as shippingActionCreators from '../redux/actions/shipping';
import { SingleDatePicker } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { exportAsPDFCN, exportAsPDFCNMulti } from '../helpers/htmlToCanvas';
import Profile from '../containers/Profile';
import GTRAlert from './GTRAlert';
import * as errorActionCreators from '../redux/actions/error';
import motorcycleImg from '../images/lalamove_motorcycle.png'
import carImg from '../images/lalamove_car.png'
import pickupImg from '../images/lalamove_4x4.png'
import vanImg from '../images/lalamove_van.png'
import lorryImg from '../images/lalamove_lorry.png'
import ReactTooltip from "react-tooltip";
import { parseNumber } from 'libphonenumber-js';
import { getCountryCallingCode } from 'react-phone-number-input';
import toast from 'react-hot-toast';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import config from '../config'
import ConfirmAddressModal from './ConfirmAddressModal';
import ReactDomServer from 'react-dom/server';

const DATE_FORMAT = config.lalamoveDateFormat;
const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

const ShippingModalSG = (props) => {
  const { user, onClose, confirmShipment, match, parcels = [], error, resetError, hasShippingFee } = props;
  const { fetchShippingRatesSG, makeOrderSG, undoMakeOrder, makePayment, resetData, resetRates } = props;
  const isAdmin = user && user.role === 'admin' || user.role === 'staff'

  const { shipping } = useSelector(state => state);
  const [address, setAddress] = useState({});
  const [confirmedSenderAddress, setConfirmedSenderAddress] = useState(null);
  const [senderConfirmedAddressChanged, setSenderConfirmedAddressChanged] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [hasSenderAddress, setHasSenderAddress] = useState(false);
  const [pickupDate, setPickupDate] = useState(null);
  const [focused, setFocused] = useState(null);
  const [service, setService] = useState("easyparcel");
  const [selectedRow, setSelectedRow] = useState(null);
  const [showPickupAddressModal, setShowPickupAddressModal] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [editingDeliveryAddress, setEditingDeliveryAddress] = useState(false);
  const [parcelShippingAddress, setParcelShippingAddress] = useState([]);
  const [confirmedParcelShippingAddress, setConfirmedParcelShippingAddress] = useState(null);
  const [specialRequests, setSpecialRequests] = useState([]);
  const [quotation, setQuotation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [defaultTime] = useState(moment());
  const [scheduleAt, setScheduleAt] = useState(null);
  const [showConfirmAddressModal, setShowConfirmAddressModal] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState('');
  const [addressToConfirm, setAddressToConfirm] = useState('');
  const [weightToUse, setWeightToUse] = useState();
  const [weight, setWeight] = useState();
  const [length, setLength] = useState();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [easyparcelCategories, setEasyparcelCategories] = useState([]);
  const [parcelContent, setParcelContent] = useState('');
  const [parcelCategory, setParcelCategory] = useState('');
  const [parcelValue, setParcelValue] = useState();
  const cnMultiRef = useRef(null);

  const easyparcelShipmentCreated = shipping.payment && shipping.payment[0]['REQ_ID'];

  useEffect(() => {
    let parcelShippingAddress = _.map(parcels, parcel => {
      return {
        ...parcel.shipping_address,
        phone: parcel.shipping_address.phone.replace(/\s|-/g, '')
      }
    })
    setParcelShippingAddress(JSON.parse(JSON.stringify(parcelShippingAddress)));
    resetError();
    loadService(service);
    fetchEasyparcelCategories();
  }, [])

  useEffect(() => {
    if (!address || !parcelShippingAddress || !specialRequests || !confirmedParcelShippingAddress || !confirmedSenderAddress) {
      return
    }
    getQuotation()
  }, [address, parcelShippingAddress, specialRequests, scheduleAt, confirmedParcelShippingAddress, confirmedSenderAddress])

  useEffect(() => {
    if (service) {
      fetchHolidays();
    }
  }, [service])

  const fetchHolidays = async () => {
    const hol = await api(`/shipments/holidays`);
    setHolidays(hol)
  }

  const fetchEasyparcelCategories = async () => {
    const categories = await api(`/lookup/easyparcel/categories`);
    setEasyparcelCategories(categories)
  }

  useEffect(() => {
    if(easyparcelShipmentCreated && pickupDate){
      confirmShipment(shipping.payment, pickupDate.format("YYYY-MM-DD"), service);
    }
    if(shipping.rates.length !== 0 && !easyparcelShipmentCreated && !selectedRow) { // default select pgeon
      // let row = _.find(shipping.rates, rate => {
      //   return rate.service_id === 'EP-CS0K3'
      // })
      // setServiceId(row.service_id);
      // setSelectedRow(row);
      setLoading(false)
    }
  }, [shipping])

  const handleOnClose = () => {
    resetData();

    if (!shipping.loading) {
      onClose();
    }
  }

  const handleRowSelected = (row, isSelected, e) => {

    setServiceId(row.service_id);
    setSelectedRow(row);
  }

  const handleOnClickNext = () => {

    resetError()

    if (service === 'easyparcel') {
      makeOrderSG(pickupDate.format("YYYY-MM-DD"), serviceId, address, parcelShippingAddress, parcels[0].order_name, 
      weightToUse, parcelCategory, parcelContent, parcelValue, length, width, height)
    }
  }

  const handleOnClickConfirm = () => {

    makePayment(parcels, selectedRow.courier_name, pickupDate.format("YYYY-MM-DD"), address);
  }

  const handleOnClickBack = () => {
    undoMakeOrder();
  }

  const isOutsideRange = (date) => {

    let now = moment()
    let startDate = moment().startOf('day')
    let endDate = moment().endOf('day')
    if(now.hour() > 11){
      startDate.add(1, 'days')
      endDate.add(1, 'days')
    }

    if(startDate.day() === 0){
      endDate.add(5, 'days')
    } else if(startDate.day() === 6){
      endDate.add(6, 'days')
    } else if(startDate.day() === 5){
      endDate.add(6, 'days')
    } else if(startDate.day() === 4){
      endDate.add(6, 'days')
    } else {
      endDate.add(4, 'days')
    }

    let isHoliday = false;
    _.each(holidays, holiday => {
      if (date.isSame(moment(holiday, 'MM/DD/YYYY'), 'day')) {
        isHoliday = true;
      }
    })

    if(isHoliday) {
      return true
    }

    if (service === 'easyparcel' && date.day() == 6) {
      return true
    }

    return date.day() == 0 || date.isBefore(startDate) || date.isAfter(endDate);
  }

  const scheduleIsOutsideRange = (date) => {

    let startDate = moment().startOf('day')
    let endDate = moment().endOf('day')
    endDate.add(29, 'days')

    return date.isBefore(startDate) || date.isAfter(endDate);
  }

  const handleSelectPickupDate = (date) => {
    if(moment().isSame(date, 'day')){
      date = moment(date).utcOffset(8).set('hour', '1')
    } else {
      date = moment(date).utcOffset(8).set('hour', '10')
    }

    setPickupDate(date);
  }

  const handleSelectScheduleAt = (date, type) => {
    if (type === 'date') {
      let scheduleDate = scheduleAt ? moment(scheduleAt) : moment().add(5, 'minutes')
      date = scheduleDate.set({
        'year': moment(date).get('year'),
        'month': moment(date).get('month'),
        'date': moment(date).get('date')
      })
    }

    setScheduleAt(date);
  }

  const handleClearScheduleAt = () => {
    setScheduleAt(null)
  }

  const fetchProfile = async () => {
    return api(`/profile`).then(profile => {
      setAddress(profile);
      if (profile.confirmedLocation && profile.confirmedLocation.saved) {
        const { latitude, longitude , address } = profile.confirmedLocation
        setConfirmedSenderAddress({
          coordinates: {
            lat: latitude.toString(),
            lng: longitude.toString(),
          },
          address: address,
        })
        setSenderConfirmedAddressChanged(false);
      }

      return profile
    })
  }

  const loadService = async (service) => {
    setLoading(true)

    let profile = await fetchProfile()

    if(!profile.name || !profile.address1 || !profile.postcode){
      setHasSenderAddress(false);
      setLoading(false)
      return
    }

    setHasSenderAddress(true);

    // if (service === "easyparcel") {
    //   return fetchShippingRates(profile.postcode, parcels)
    // }

    setLoading(false)
  }

  // const printDHLCN = async (isDoc) => {
  //   if (loading) {
  //     return
  //   }

  //   if (dhlLabels.length > 0) { // INFO: old method
  //     if (isDoc) {
  //       exportAsPDFCNMulti(cnMultiRef.current);
  //     } else {
  //       exportAsPDFCN(cnMultiRef.current);
  //     }
  //     return
  //   }

  //   setLoading(true);
  //   let ids = _.map(shipping.shipments, "dhlShipmentId");
  //   // INFO: new method
  //   return api(`/dhl/label/custom/print?ids=${_.join(ids, ',')}&isDoc=${isDoc}`).then(function(result) {
  //     window.open(result, '_blank');
  //     setLoading(false);
  //   })
  // }

  const handleEditPickupAddress = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowPickupAddressModal(true);
  }

  const handleBackToShipment = async () => {
    setShowPickupAddressModal(false);

    return loadService(service);
  }

  const editDeliveryAddress = () => {
    setEditingDeliveryAddress(true);
  }

  const saveDeliveryAddress = (e, index) => {
    e.preventDefault();
    let addresses = [...parcelShippingAddress];
    addresses[index].zip = e.target.zip.value
    addresses[index].phone = e.target.phone.value
    setParcelShippingAddress(addresses);
    setEditingDeliveryAddress(false);
  }

  const onCheckSpecialRequest = async (e, key, item) => {
    let selectedSpecialRequests = _.filter(specialRequests, request => request && request.name && !request.name.includes(key))
    if (e.target.checked) {
      selectedSpecialRequests.push(item)
      setSpecialRequests(selectedSpecialRequests)
    } else {
      setSpecialRequests(selectedSpecialRequests)
    }
  }

  const onClickConfirmSenderAddress = () => {
    setShowConfirmAddressModal(true)
    let defaultAddress = address.address1
    if (address.address2) {
      defaultAddress += " " + address.address2
    }
    defaultAddress += " " + address.postcode + " " + address.city
    setDefaultAddress(defaultAddress)
    setAddressToConfirm('sender')
  }

  const onClickConfirmDeliveryAddress = () => {
    setShowConfirmAddressModal(true)
    let defaultAddress = parcelShippingAddress[0].address1
    if (parcelShippingAddress[0].address2) {
      defaultAddress += " " + parcelShippingAddress[0].address2
    }
    defaultAddress += " " + parcelShippingAddress[0].zip + " " + parcelShippingAddress[0].city
    setDefaultAddress(defaultAddress)
    setAddressToConfirm('delivery')
  }

  const handleConfirmLocation = async (latitude, longitude, address) => {
    let url = `/profile/confirm/location`
    return api(url, {
      method: 'POST',
      body: JSON.stringify({
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        address,
      })
    }).then(fetchProfile)
  }

  const onClickSaveSenderConfirmedAddress = () => {
    const { coordinates, address } = confirmedSenderAddress
    handleConfirmLocation(coordinates.lat, coordinates.lng, address)
  }

  const getServiceInfo = (serviceId, imageUrl) => {

    return (
      <>
        { imageUrl &&
          <img src={imageUrl} className='shipping-service-image' />
        }
        {
          serviceId === 'EP-CS0AH7' &&
          <ul>
            <li>Pick up available for Kuala Lumpur and Selangor only.</li>
            <li>Book before 11:50am for same day pick up.</li>
            <li>Pick up time is from 8am - 8pm on Monday to Sunday.</li>
            <li>Estimated delivery time duration: One day</li>
            <li>Delivery service is unavailable to PO box (Post Office box) / APO (Army Post Office) / FPO (Fleet Post Office) / DPO (Diplomatic Post Office) addresses</li>
            <li>Maximum weight: 5kg</li>
            <li>Maximum dimension: 60cm x 20cm x 20cm</li>
            <li>Parcel weight will be determined by either actual or volumetric weight (VW) or whichever is higher. VM also applies if either one dimension is above 30cm.</li>
            <li>VW formula =(height x width x length)cm /6000</li>
            <li>All rates quoted are nett rate inclusive of shipping fee except address correction fee by courier, customs duty and taxes, temporary import export charges, import taxes or duties and shipment return charges.</li>
            <li>RM300 maximum insurance coverage.</li>
          </ul>  
        }
        {
          (serviceId === 'EP-CS0AOD' || serviceId === "EP-CS0A2Q") &&
          <>
            <h4>Booking and Pickup:</h4>
            <ul>
              <li>Book before 9.30 am for same day pick up.</li>
              <li>Pick up time is from 12 pm - 5.30 pm</li>
              <li>Estimated delivery time frame: 1 working day (subject to custom clearance).</li>
              <li>Delivery service is unavailable to PO box (Post Office box) / APO (Army Post Office) / FPO (Fleet Post Office) / DPO (Diplomatic Post Office) addresses</li>
            </ul>

            <h4>Weight and Dimension Limits:</h4>
            <ul>
              <li>Maximum weight: 70kg</li>
              <li>Parcel weight will be determined by either actual or volumetric weight (VW) or whichever is higher. VW also applies if either one dimension is above 30cm.</li>
              <li>VW formula =(height x width x length)cm /5000</li>
            </ul>

            <h4>Shipping Rates and Surcharges:</h4>
            <ul>
              <li>All rates quoted are net rates inclusive of shipping fee and do not include additional surcharges incurred by courier / custom such as address correction fee, customs duty and taxes, temporary import export charges, import taxes or duties or shipment return charges.</li>
              <li>Maximum coverage: USD 20/kg or max USD100 per shipment for air / non road transportation, USD 10/kg or max USD100 per shipment for road transportation (not applicable to US).</li>
            </ul>

            <h4>Coverage Restrictions:</h4>
            <ul>
              <li>Pickup from postcode 07000 and 07100 Langkawi currently out of coverage.</li>
              <li>Delivery for postcode, 35600, 35013, and 38359 (Spain), 39310 (Pakistan), BX9 1AS (United Kingdom), and 97400 (France) currently out of coverage.</li>
              <li>Delivery for Britain Jersey (UK) currently out of coverage.</li>
            </ul>
          </>
        }
        {
          (serviceId === "EP-CS0A75" || serviceId === "EP-CS0ATR") &&
          <>
            <h4>Booking and Pickup:</h4>
            <ul>
              <li>Pick up available within Peninsular Malaysia.</li>
              <li>Book before 2pm for same day pick up.</li>
              <li>Pick up time is from 9am to 5.30pm.</li>
              <li>Estimated delivery time frame: 1 working day (subject to custom clearance).</li>
              <li>Delivery service is unavailable to PO box (Post Office box) / APO (Army Post Office) / FPO (Fleet Post Office) / DPO (Diplomatic Post Office) addresses</li>
            </ul>
            <h4>Weight and Dimension Limits:</h4>
            <ul>
              <li>Maximum weight : 30kg</li>
              <li>Maximum dimension (LxWxH): 100x50cmx50cm</li>
              <li>Parcel weight will be determined by either actual or volumetric weight (VW) or whichever is higher.</li>
              <li>VW formula =(height x width x length)cm / 5000</li>
            </ul>
            <h4>Shipping Rates and Surcharges:</h4>
            <ul>
              <li>All rates quoted are nett rate inclusive of shipping fee and does not include additional surcharges incurred by courier / custom such as address correction fee, customs duty and taxes, temporary import export charges, import taxes or duties or shipment return charges.</li>
              <li>Maximum insurance coverage: USD100 per tracking number at declared value; whichever is lower.</li>
              <li>Claims for damaged shipment or shortages should be provided by receiver 1 day upon successful delivery.</li>
            </ul>
          </>
        }
        {
          serviceId === 'EP-CS0A7G' &&
          <ul>
            <li>Pick up available for Kuala Lumpur and Selangor only.</li>
            <li>Book before 11:50am for same day pick up.</li>
            <li>Pick up time is from 8am - 8pm on Monday to Sunday.</li>
            <li>Estimated delivery time duration: One day</li>
            <li>Delivery service is unavailable to PO box (Post Office box) / APO (Army Post Office) / FPO (Fleet Post Office) / DPO (Diplomatic Post Office) addresses</li>
            <li>Maximum weight: 5kg</li>
            <li>Maximum dimension: 60cm x 20cm x 20cm</li>
            <li>Parcel weight will be determined by either actual or volumetric weight (VW) or whichever is higher. VM also applies if either one dimension is above 30cm.</li>
            <li>VW formula =(height x width x length)cm /6000</li>          
            <li>All rates quoted are nett rate inclusive of shipping fee except address correction fee by courier, customs duty and taxes, temporary import export charges, import taxes or duties and shipment return charges.</li>
            <li>RM300 maximum insurance coverage.</li>
          </ul>
        }
        {
          serviceId === 'EP-CS0A2F' &&
          <ul>
            <li>Same-day pickup : For orders made before 11:30am. Parcel will be collected today from 1pm to 6.00pm.</li>
            <li>Next-day pickup: Parcels will be collected on the next working day from 9am to 6.00pm.</li>
            <li>Delivery time frame for documents are as below. Delivery time frame for parcel shipments are subjected to custom clearance.</li>
            <li>Singapore - 3 working days</li>
            <li>For delivery to rural areas, there will be a delay of 1 day from normal service lead time.</li>
            <li>All rates quoted are nett rate inclusive of all surcharges except customs duty and taxes, temporary import export charges, import taxes or duties and shipment return charges.</li>
            <li>Maximum basic insurance: RM300 per tracking number at declared value; whichever is lower.</li>
            <li>Maximum weight : 30kgs</li>
            <li>Parcel weight will be determined by either actual or volumetric weight (VW) or whichever is higher. VW formula =(height x width x length)cm /6000</li>
            <li>Maximum dimension (LxWxH): 100x50cmx50cm</li>
            <li>Claims for damaged shipment or shortages should be provided by consignee 1 day upon successful delivery.</li>
          </ul>
        }
        {
          serviceId === 'EP-CS0A7Q' &&
          <>
            <h4>Booking and Pickup:</h4>
            <ul>
              <li>Book before 9.30am for same day pick up.</li>
              <li>Pick up time is from 12pm - 5.30pm</li>
              <li>Estimated delivery time frame: 3-5 working days (subject to custom clearance).</li>
            </ul>
            <h4>Delivery:</h4>
            <ul>
              <li>Delivery is only valid for shipments being delivered to Singapore only.</li>
            </ul>
            <h4>Weight and Dimension Limits:</h4>
            <ul>
              <li>Maximum weight: 10kg</li>
              <li>Shipments that exceeds 10kg rate will follow our SF Economy Express Rate.</li>
              <li>Parcel weight will be determined by either actual or volumetric weight (VW) or whichever is higher. VM also applies if either one dimension is above 30cm.</li>
              <li>VW formula =(height x width x length)cm /5000</li>
            </ul>
            <h4>Shipping Rates and Surcharges:</h4>
            <ul>
              <li>All rates quoted are nett rate inclusive of shipping fee and does not include additional surcharges incurred by courier / custom such as address correction fee, customs duty and taxes, temporary import export charges, import taxes or duties or shipment return charges.</li>
              <li>Maximum coverage: USD 20/kg or max USD100 per shipment for air / non road transportation, USD 10/kg or max USD100 per shipment for road transportation (not applicable to US).</li>
            </ul>
          </>
        }
      </>
    )

  }

  const handleConfirmAddress = (location, formatted_address) => {
    if (addressToConfirm === 'sender') {
      setConfirmedSenderAddress({
        coordinates: {
          lat: location.lat.toString(),
          lng: location.lng.toString(),
        },
        address: formatted_address,
      })
      if (!address.confirmedLocation.saved) {
        handleConfirmLocation(location.lat, location.lng, formatted_address)
      } else {
        setSenderConfirmedAddressChanged(true)
      }
    }

    if (addressToConfirm === 'delivery') {
      setConfirmedParcelShippingAddress({
        coordinates: {
          lat: location.lat.toString(),
          lng: location.lng.toString(),
        },
        address: formatted_address,
      })
    }

    setShowConfirmAddressModal(false)
  }

  const handleFetchShippingRates = () => {
    setLoading(true);

    let volumetricWeight = length*width*height/5000;
    let weightToUse = weight;

    if (volumetricWeight > weight) {
      weightToUse = volumetricWeight;
    }

    setWeightToUse(weightToUse)
    fetchShippingRatesSG(address.postcode, parcelShippingAddress[0].zip, weightToUse)
  }

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: handleRowSelected,
    headerColumnStyle: { width: '30px' },
  };

  const ordersCol = [
    {
      dataField: 'service_id',
      text: 'Service ID',
      hidden: true,
    },
    {
      dataField: 'service_name',
      text: 'Courier Company',
      headerStyle: { width: '300px' },
      formatter: (_, row) => {
        return (
          <div className="d-grid">
            <img src={row.courier_logo} alt={row.service_name} className='shipping-service-image' />
            <small>{row.service_name}</small>
          </div>
        )
      }
    },
    {
      dataField: 'service_detail',
      text: '',
      headerStyle: { width: '150px' },
      formatter: (cell, row) => {
        return (
          <div className="d-grid">
            <>
              <span><FontAwesomeIcon icon={solid("truck")}/> {_.capitalize(cell)}</span>
            </>
          </div>
        )
      }
    },
    {
      dataField: 'pickup_date',
      text: 'Est. Delivery Duration',
      headerStyle: { width: '200px' },
      formatter: (_, row) => {
        return (
          <div>
            <span>{row.delivery}</span>
          </div>
        )
      },
    },
    {
      dataField: 'shipment_price',
      text: 'Price',
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            <span>RM {cell}</span>
          </div>
        )
      },
    },
  ];

  return (
    <>
      <Modal style={{border: 'none'}} show={true} onHide={handleOnClose} size="xl">
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0 text-capitalize">Arrange Shipment</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{borderTop: '1px solid #dee2e6'}}>
          {
            parcels.length === 0 &&
              <p className="text-center mb-0">Shipments created for all orders</p>
          }
          {
            service === 'easyparcel' && shipping.shipments > 0 && shipping.shipments[0].status === 'Fail' &&
              <div role="alert" className="alert alert-danger">{shipping.shipments[0].status}: {shipping.shipments[0].remarks}</div>
          }
          { (service === 'easyparcel') && parcels &&
              parcels.map((parcel, i) => {
                return (
                  <Card key={i} className="tealCard shadow-sm">
                    <Card.Header className="tealCardHeader">Parcel #{i + 1}</Card.Header>
                    <Card.Body className="d-grid gap-2 grey-label label-strong">
                      <div className="d-grid gap-1">
                        <label>Items</label>
                        {parcel.line_items.map((single, j) => {
                          return (
                            <div key={j} className="delivery-info-value">{single.name || single.title} x {single.quantity} {service === 'dhl' ? single.grams + "g" : ""}</div>
                          )
                        })}
                      </div>
                      {
                        isAdmin && parcel.shipping_lines && parcel.shipping_lines[0] && parcel.shipping_lines[0].price !== '0.00' &&
                          <div className="d-grid gap-1">
                            <label>Shipping Paid</label>
                            <span>{currency} {parcel.shipping_lines[0].price}</span>
                          </div>
                      }
                    </Card.Body>
                  </Card>
                )
              })
          }
          { (service === 'easyparcel') &&
            <Row>
              <div className="col-sm-6 col-xs-12">
                <Card className="tealCard shadow-sm">
                  <Card.Header className="tealCardHeader">
                    Sender Address
                  </Card.Header>
                  <Card.Body className="d-grid gap-2 label-strong grey-label">
                    {
                      !hasSenderAddress && !loading &&
                        <Card className="redCard">
                          <Card.Header className="redCardHeader">
                            { service === 'lalamove' ?
                                'Pick-up address not found'
                                :
                                'Sender address not found'
                            }
                          </Card.Header>
                          <Card.Body>
                            Please enter your address <a onClick={handleEditPickupAddress} href="">here</a>.
                          </Card.Body>
                        </Card>
                    }
                    {
                      !hasSenderAddress && loading &&
                        <Loader/>
                    }
                    {
                      hasSenderAddress && address.name &&
                        <Card className="position-relative">
                          <Button variant="secondary" className="btn-sm position-absolute btn-sm end-0 top-0 m-3" onClick={handleEditPickupAddress}>
                            Edit
                          </Button>
                          <Card.Body className="d-grid gap-1">
                            <label>{address.name}</label>
                            {
                              address.company &&
                                <>{address.company}<br/></>
                            }
                            {address.address1},<br/>
                            {
                              address.address2 &&
                                <>{address.address2},<br/></>
                            }
                            {address.postcode}, {address.city},<br/>
                            {address.state}, {address.country}<br/>
                            {address.contact}
                          </Card.Body>
                        </Card>
                    }
                  </Card.Body>
                </Card>
              </div>
              <div className="col-sm-6 col-xs-12">
                <Card className="tealCard shadow-sm">
                  <Card.Header className="tealCardHeader">Delivery Address</Card.Header>
                  <Card.Body className="d-grid gap-2 label-strong grey-label">
                    { parcels &&
                        parcels.map((parcel, i) => {
                          return (
                            <React.Fragment key={i}>
                              <label>Parcel #{i + 1}</label>
                              <Card>
                                <Card.Body className="position-relative">
                                  { editingDeliveryAddress ?
                                    <>
                                      <Form onSubmit={(e) => saveDeliveryAddress(e, i)}>
                                        <Button type="submit" className="position-absolute btn-sm end-0 top-0 m-3">
                                          Save
                                        </Button>
                                        <Form.Group className="mb-3">
                                          <Form.Label>Postcode</Form.Label>
                                          <Form.Control defaultValue={parcelShippingAddress[i].zip} name="zip" type="text" placeholder="Enter postcode" />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                          <Form.Label>Phone Number</Form.Label>
                                          <Form.Control defaultValue={parcelShippingAddress[i].phone} name="phone" type="text" placeholder="Enter phone number" />
                                        </Form.Group>
                                      </Form>
                                    </>
                                    :
                                    <>
                                      <Button variant="secondary" className="position-absolute btn-sm end-0 top-0 m-3" onClick={editDeliveryAddress}>
                                        Edit
                                      </Button>
                                      {
                                        parcelShippingAddress[i] &&
                                          <>
                                            <label>{parcelShippingAddress[i].name}</label><br/>
                                            {parcelShippingAddress[i].address1},<br/>
                                            {
                                              parcelShippingAddress[i].address2 &&
                                                <>{parcelShippingAddress[i].address2},<br/></>
                                            }
                                            {parcelShippingAddress[i].zip}, {parcelShippingAddress[i].city}<br/>
                                            {parcelShippingAddress[i].province_code}, {parcelShippingAddress[i].country_code}<br/>
                                            {parcelShippingAddress[i].phone}
                                          </>
                                      }
                                    </>
                                  }
                                </Card.Body>
                              </Card>
                            </React.Fragment>
                          )
                        })
                    }
                  </Card.Body>
                </Card>
              </div>
            </Row>
          }
          {
            shipping.shipments.length === 0 &&
            <Card className="tealCard shadow-sm">
              <Card.Header className="tealCardHeader">Parcel Details</Card.Header>
              <Card.Body className="d-grid gap-2 grey-label label-strong">
                { 
                  easyparcelCategories && easyparcelCategories.length > 0 &&
                  <div className="d-grid gap-1">
                    <label>Parcel Category</label>
                    <select className="form-select" value={parcelCategory} onChange={(e) => setParcelCategory(e.target.value)}>
                      <option disabled value="">Please select one category</option>
                      {
                        easyparcelCategories.map((category, index) => (
                          <option key={index} value={category.parcel_category_id}>{category.parcel_category}</option>))
                      }
                    </select>
                  </div>
                }
                <div className="d-grid gap-1">
                  <label>Parcel Content</label>
                  <input type="text" className="form-control" value={parcelContent} placeholder="Nike Cotton Baseball Cap" onChange={(e) => {
                    setParcelContent(e.target.value);
                  }} />
                  <small>Please provide detailed and correct parcel content (brand, material and item) to avoid shipment delay or confiscation with penalty charges. <br/>
                    For eg: NIKE cotton baseball cap instead of cap. For sending multiple items, kindly separate each item with comma.</small>
                </div>
                <div className="d-grid gap-1">
                  <label>Parcel Value (RM)</label>
                  <input type="number" className="form-control" value={parcelValue} placeholder="" onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    setParcelValue((!isNaN(value) && value > 0) ? value.toString() : 0);
                  }} />
                </div>
                <hr/>
                <div className="d-grid gap-1">
                  <label>Actual Weight (kg)</label>
                  <input type="number" className="form-control" value={weight} placeholder="Please enter Weight (kg)" onChange={(e) => {
                    resetRates()
                    const value = parseFloat(e.target.value);
                    setWeight((!isNaN(value) && value > 0) ? value.toString() : 0);
                  }} />
                </div>
                <div className="d-grid gap-1">
                  <div className="row">
                    <div className="col-sm-4 col-xs-12">
                      <div className="form-group">
                        <label>Length (cm)</label>
                        <input type="number" className="form-control" value={length} placeholder="Length (cm)" onChange={(e) => {
                          resetRates()
                          const value = parseInt(e.target.value);
                          setLength((!isNaN(value) && value > 0) ? value.toString() : 0);
                        }} />
                      </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                      <label>Width (cm)</label>
                      <input type="number" className="form-control" value={width} placeholder="Width (cm)" onChange={(e) => {
                        resetRates()
                        const value = parseInt(e.target.value);
                        setWidth((!isNaN(value) && value > 0) ? value.toString() : 0);
                      }} />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                      <label>Height (cm)</label>
                      <input type="number" className="form-control" value={height} placeholder="Height (cm)" onChange={(e) => {
                        resetRates()
                        const value = parseInt(e.target.value);
                        setHeight((!isNaN(value) && value > 0) ? value.toString() : 0);
                      }} />
                    </div>
                  </div>
                </div>
                <div className="d-grid gap-1">
                  <label>Volumetric Weight (kg)</label>
                  <input type="number" disabled className="form-control" value={isNaN(length*width*height/5000) ? 0 : (length*width*height/5000).toFixed(2)}/>
                </div>
                <Button variant="primary" style={{ marginTop: '10px' }} disabled={!weight || !length || !width || !height} onClick={handleFetchShippingRates}>Fetch Shipping Rates</Button>
                <small>Remarks: Actual weight or volumetric weight (whichever is higher) will be defined as the shipping weight.</small>
              </Card.Body>
            </Card>
          }
          {/* { 
            dhlShipmentCreated &&
            <Card className="tealCard">
              <Card.Header className="tealCardHeader">DHL Consignment Note</Card.Header>
              <Card.Body className="d-grid gap-2 label-strong grey-label">
                {
                  shipping.shipments.map((item, i) => {
                    return (
                      <Card key={i}>
                        <Card.Body className="d-grid gap-1">
                          <div className="d-grid gap-1">
                            <label>Shipment ID/AWB</label>
                            <span>{item.dhlShipmentId}</span>
                          </div>
                        </Card.Body>
                      </Card>
                    )
                  })
                }
                {
                  shipping.shipments.length > 0 &&
                    <div>
                      <Button className='me-1' onClick={() => printDHLCN(false)}>Print as Label</Button>
                      <Button className='me-1' onClick={() => printDHLCN(true)}>Print as Document</Button>
                    </div>
                }
              </Card.Body>
            </Card>
          } */}
          {
            (shipping.shipments.length > 0) && 
            <Card className="tealCard shadow-sm"> 
              <Card.Header className="tealCardHeader">Service Info</Card.Header> 
              <Card.Body className="d-grid gap-2 label-strong grey-label"> 
                { getServiceInfo(serviceId, selectedRow.courier_logo) }
              </Card.Body> 
            </Card> 
          }
            { 
               (shipping.shipments.length > 0 || shipping.rates.length > 0) && 
               <Card className="tealCard shadow-sm"> 
                 <Card.Header className="tealCardHeader">Pick-up</Card.Header> 
                 <Card.Body className="d-grid gap-2 label-strong grey-label"> 
                   { 
                    <> 
                      { 
                        shipping.shipments.length === 0 && !shipping.payment && shipping.rates.length > 0 && 
                          <> 
                            <label>Select Pickup Service: </label> 
                            <BootstrapTable 
                              keyField='service_id' 
                              data={shipping.rates} 
                              columns={ordersCol} 
                              bordered={ false } 
                              selectRow={selectRowProp} 
                              hover 
                              defaultSorted={[{ 
                                dataField: 'created_at', 
                                  order: 'desc', 
                              }]} 
                              noDataIndication={() => 'There is no data to display'} 
                            /> 
                          </> 
                      }                       
                      { 
                        shipping.rates.length > 0 && shipping.shipments.length === 0 && // holidays.length > 0 && 
                        <> 
                          <label>Select Pickup Date: </label> 
                          <SingleDatePicker 
                            numberOfMonths={1} 
                            isOutsideRange={isOutsideRange} 
                            displayFormat="D/M/YY" 
                            date={pickupDate} // momentPropTypes.momentObj or null 
                            onDateChange={handleSelectPickupDate} // PropTypes.func.isRequired 
                            focused={focused} // PropTypes.bool 
                            onFocusChange={({ focused }) => setFocused( focused )} // PropTypes.func.isRequired 
                          /> 
                        </> 
                      } 
                      { 
                        service === 'easyparcel' && shipping.rates.length == 0 && !shipping.loading && shipping.loaded && shipping.shipments.length === 0 && 
                          <div className="text-center my-3"> 
                            <h5 className="mb-0" style={{color: '#d15d5d'}}><FontAwesomeIcon icon={solid('warning')}/> No pickup service available at this time</h5> 
                          </div> 
                      } 
                      { 
                        shipping.shipments.length === 0 && 
                          <div> 
                            All pick-up is available on working days only (exclude Sat, Sun and PH).<br/> 
                            Pick-up date subject to delivery partner's availability.<br/> 
                          </div> 
                      } 
                      { 
                        shipping.shipments.length > 0 && pickupDate && 
                          <div className="d-grid gap-1"> 
                            <label>Pick-up date</label> 
                            <span>{pickupDate.format('D-M-YYYY')}</span> 
                          </div> 
                      } 
                      { 
                        shipping.shipments.length > 0 && selectedRow && 
                        <> 
                          <div className="d-grid gap-1"> 
                            <label>Courier name</label> 
                            <span>{selectedRow.service_name}</span> 
                          </div> 
                          { 
                            isAdmin && 
                              <div className="d-grid gap-1"> 
                                <label>Shipping fee</label> 
                                <span>RM {selectedRow.price}</span> 
                              </div> 
                          }
                          <div className="d-grid gap-1"> 
                            <label>Shipping Weight</label> 
                            <span>{weightToUse} kg</span> 
                          </div> 
                        </> 
                      } 
                      {/* <hr/> 
                      <Row className="align-items-center justify-content-between"> 
                        <Col xs="auto"><label>Pickup Address</label></Col> 
                        <Col xs="auto"> 
                          { 
                            address.name && shipping.shipments.length === 0 && !shipping.loading && 
                            <Button variant="secondary" className="btn-sm" onClick={handleEditPickupAddress}> 
                              Edit 
                            </Button> 
                          } 
                        </Col> 
                      </Row> 
                      { 
                        !address && 
                        <Card className="redCard"> 
                          <Card.Header className="redCardHeader">Pickup address not found</Card.Header> 
                          <Card.Body> 
                            Please enter your pickup address <a onClick={handleEditPickupAddress} href="">here</a>. 
                          </Card.Body> 
                        </Card> 
                      } 
                      { 
                        address && address.name && 
                        <Card> 
                          <Card.Body className="d-grid gap-1"> 
                            <label>{address.name}</label> 
                            { 
                              address.company && 
                                <>{address.company}<br/></> 
                            } 
                            {address.address1},<br/> 
                            { 
                              address.address2 && 
                                <>{address.address2},<br/></> 
                            } 
                            {address.postcode}, {address.city},<br/> 
                            {address.state}, {address.country}<br/> 
                            {address.contact} 
                          </Card.Body> 
                        </Card> 
                      }  */}
                    </> 
                  } 
                 </Card.Body> 
               </Card> 
           } 
           { // easyparcel 
             shipping.payment && 
               <Card className="tealCard"> 
                 <Card.Header className="tealCardHeader">Status</Card.Header> 
                 <Card.Body className="d-grid gap-2 label-strong grey-label"> 
                   { 
                     shipping.payment.map((item, i) => { 
                       return ( 
                         <Card key={i}> 
                           <Card.Body className="d-grid gap-1"> 
                             <div className="d-grid gap-1"> 
                               <label>Order No.</label> 
                               <span className="delivery-info-value">{item.orderno}</span> 
                             </div> 
                             <div className="d-grid gap-1"> 
                               <label>Status</label> 
                               <span className="delivery-info-value">{item.messagenow}</span> 
                             </div> 
                             <div className="d-grid gap-1"> 
                               <label>AWB No.</label> 
                               <dd className="delivery-info-value">{item.parcel[0].awb}</dd> 
                             </div> 
                             <div className="d-grid gap-1"> 
                               <label>AWB Link</label> 
                               <a className="delivery-info-value" href={item.parcel[0].awb_id_link} target="_blank">Download</a> 
                             </div> 
                           </Card.Body> 
                         </Card> 
                       ) 
                     }) 
                   } 
                 </Card.Body> 
               </Card> 
           } 
          { 
            service === 'easyparcel' && shipping.loading && 
              <Loader /> 
          } 
          { error &&
            <GTRAlert variant="danger" alert={error}/>
          }
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          {
            service === 'easyparcel' && 
            <> 
              { 
                ((!shipping.payment && shipping.shipments.length === 0) || shipping.payment) &&
                <Button variant="danger-secondary" onClick={handleOnClose} disabled={shipping.loading}>
                  {!shipping.payment ? "Cancel" : "Done"}
                </Button>
              }
              {
                shipping.shipments.length === 0 && !shipping.payment &&
                <Button variant="primary" onClick={handleOnClickNext} disabled={!pickupDate || shipping.loading || !selectedRow || !parcelContent || !parcelCategory}>
                  Next
                </Button>
              }
              {
                shipping.shipments.length > 0 && !shipping.payment &&
                <>
                  <Button variant="danger-secondary" onClick={handleOnClickBack} disabled={shipping.loading}>
                    Back
                  </Button>
                  <Button variant="primary" onClick={handleOnClickConfirm} disabled={shipping.loading}>
                    Confirm
                  </Button>
                </>
              }
            </>
          }
        </Modal.Footer>
      </Modal>
      {
        showPickupAddressModal &&
          <Modal show={true} onHide={handleBackToShipment} size="lg" dialogClassName="modal-shadow">
            <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
              <h4 className="mb-0">Edit Pickup Address</h4>
            </Modal.Header>
            <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{ borderTop: '1px solid #dee2e6' }}>
              <Profile showHeader={false} showPassword={false} match={match} prevModal="Arrange Shipment" backToPrevModal={handleBackToShipment}/>
            </Modal.Body>
          </Modal>
      }
      {/* {
        <div className='position-relative' ref={cnMultiRef}>
          {
            _.map(dhlLabels, (label, i) => {
              return (
                <div key={i} className="cn-container text-center position-absolute" style={{left: '-9999px', top: '-9999px'}}>
                  <img src={`data:image/png;base64, ${label}`}/>
                </div>
              )
            })
          }
        </div>
      } */}
      { 
        showConfirmAddressModal &&
        <ConfirmAddressModal onHide={() => setShowConfirmAddressModal(false)} backdropClassName="nested-modal-backdrop" defaultAddress={defaultAddress} confirmAddress={handleConfirmAddress}/>
      }
    </>
  );
}

export default connect((state, props) => {
  return {
    error: state.error,
  }
}, {
  ...shippingActionCreators,
  ...errorActionCreators,
})(ShippingModalSG);
