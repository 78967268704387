import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { Button, Alert, Badge, Container, Row, Col } from 'react-bootstrap';
import ConfirmModal from '../components/ConfirmModal';
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import * as merchantRatesActionCreators from '../redux/actions/merchantRates';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import whatsappLogo from '../images/whatsapp.svg';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

class Merchants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      syncing: false,
      reload: false,
      error: null,
      showConfirmModal: false,
      _id: null,
      email: null
    };
  }

  componentDidMount() {
    this.fetchMerchantRates();
  }

  componentWillReceiveProps(nextProps) {
    const { location: prevLocation } = this.props;
    const { location, history } = nextProps;
    if (location.state && location.state.reload != null &&
      history.action !== 'POP' &&
      location.state !== prevLocation.state) {
      this.setState({ reload: location.state.reload });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { reload: prevReload } = this.state;
    const { reload } = nextState;
    if (reload && reload !== prevReload) {
      const { location } = nextProps;
      this.fetchMerchantRates();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { reload: prevReload } = prevState;
    const { reload } = this.state;
    if (reload && reload !== prevReload) {
      this.setState({ reload: false });
    }
  }

  fetchMerchantRates = (location) => {
    const { fetchMerchantRates } = this.props;
    this.setState({ error: null });
    fetchMerchantRates();
  }

  handleOnClickRemoveButton = (_id, email) => {
    this.setState({
      showConfirmModal: true,
      _id,
      email
    });
  }

  handleOnCloseConfirmModal = () => {
    this.setState({
      showConfirmModal: false,
      _id: null,
      email: null
    });
  }

  deleteUser = () => {
    this.handleOnCloseConfirmModal();
    const { _id } = this.state;
    return api(`/users/${_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      this.fetchMerchantRates();
    }).catch(err => {
      if (err) {
        window.scrollTo(0, 0);
        if (err.validationError) {
          this.setState({ error: err.validationError });
        }
        else if (err.message) {
          this.setState({ error: err.message });
        }
        else {
          this.setState({ error: err });
        }
      }
    });
  }

  renderDetailLink = (cell, row) => {
    return (
      <Link
        className="merchant-modal"
        key={row._id}
        to={{
          pathname: `/merchants/${row._id}`,
          state: { modal: true }
        }}
      >
        {row.name}
      </Link>
    )
  }

  renderGstRegistered = (cell, row) => {

    if(row.gst_registered){
      return <Badge bg="success" className="text-uppercase">{row.gst_registered.toString()}</Badge>
    }

    return <Badge bg="warning" className="text-uppercase">{row.gst_registered.toString()}</Badge>

  }

  renderStatus = (cell, row) => {
    const { status } = row

    if(status){
      switch(status.toLowerCase()){
        case 'active':
          return <Badge bg="success" className="text-uppercase">active</Badge>
        case 'inactive':
          return <Badge bg="warning" className="text-uppercase">inactive</Badge>
        case 'on-hold':
          return <Badge bg="default" className="text-uppercase">on hold</Badge>
        default:
          break;
      }
    }
  }

  renderQna = (cell, row) => {

    if(row.qna){
      return <Badge bg="success" className="text-uppercase">true</Badge>
    }

    return <Badge bg="warning" className="text-uppercase">false</Badge>

  }

  renderProducts = (cell, row) => {

    if(row.products){
      return <Badge bg="success" className="text-uppercase">true</Badge>
    }

    return <Badge bg="warning" className="text-uppercase">false</Badge>

  }

  renderVM = (cell, row) => {

    if(row.virtual_message){
      return <Badge bg="success" className="text-uppercase">true</Badge>
    }

    return <Badge bg="warning" className="text-uppercase">false</Badge>

  }

  renderShipments = (cell, row) => {

    if(row.shipments){
      return <Badge bg="success" className="text-uppercase">true</Badge>
    }

    return <Badge bg="warning" className="text-uppercase">false</Badge>

  }

  renderPickupAddress = (cell, row) => {

    if(row.pickup_address){
      return <Badge bg="success" className="text-uppercase">true</Badge>
    }

    return <Badge bg="warning" className="text-uppercase">false</Badge>

  }

  renderScore = (cell, row) => {

    return <span>{cell.toFixed(1)}</span>

  }

  renderRemoveButton = (cell, row) => {
    return (
      <Button variant="danger" onClick={() => this.handleOnClickRemoveButton(row._id, row.email)}>Delete</Button>
    )
  }


  handleSync = () => {

    this.setState({
      syncing: true
    });

    return api(`/merchants/force/sync`, {
      method: 'POST'
    }).then((res) => {

      console.log('sync', res)

      alert("Syncing merchants... this may take up to a couple minutes, please refresh the page in a moment")
    }).catch(err => {
      if (err) {
        window.scrollTo(0, 0);
        if (err.validationError) {
          this.setState({ error: err.validationError });
        }
        else if (err.message) {
          this.setState({ error: err.message });
        }
        else {
          this.setState({ error: err });
        }
      }
    });
  }

  renderContactNumber = (cell, row) => {
    if(row.operations_contact){
      return (
        <a href={`${row.operations_contact_method === 'phone_number' ? 'https://wa.me/' : ''}${row.operations_contact}`} target="_blank">
          <img height='25px' src={whatsappLogo}/>
        </a>
      )
    }
  }

  render() {
    const { merchantRates: { loading, loaded, items }, user } = this.props;
    const { showConfirmModal, email, error, syncing } = this.state;

    const isAdmin = user.role === 'admin'
    const isStaff = user.role === 'staff'

    function excludeNull(cell, row){
      return cell ? cell : ''
    }

    function formatBankAcc(cell, row){
      return cell ? `'${cell}` : ''
    }

    const options = {
      showTotal: true,
      sizePerPage: 20,
      hideSizePerPage: true
    };

    const columns = [
      {
        dataField: 'name',
        formatter: this.renderDetailLink,
        sort: true,
        headerStyle: {
          width: '140px',
          resize: 'horizontal'
        },
        text: 'Name',
      },
      {
        dataField: 'company_name',
        sort: true,
        csvFormatter: excludeNull,
        text: 'Company',
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'email',
        sort: true,
        csvFormatter: excludeNull,
        text: 'Contact Email',
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'operations_contact',
        sort: true,
        csvFormatter: excludeNull,
        text: 'Operations Contact',
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'whatsapp',
        csvFormatter: excludeNull,
        text: 'WA',
        formatter: this.renderContactNumber,
        headerStyle: {
          width: '40px',
        },
      },
      {
        dataField: 'commission_type',
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
        text: 'Commission Type',
      },
      {
        dataField: 'commission_rate',
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
        text: 'Commission Rate',
      },
      {
        dataField: 'gst_registered',
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
        formatter: this.renderGstRegistered,
        sort: true,
        text: 'SST'
      },
      {
        dataField: 'status',
        formatter: this.renderStatus,
        csvFormatter: excludeNull,
        sort: true,
        text: 'Status',
        headerStyle: {
          width: '85px',
        },
      },
      {
        dataField: 'partner_manager',
        sort: true,
        text: 'Partner Manager',
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'discount_prefix',
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
        sort: true,
        text: 'Discount Prefix',
      },
      {
        dataField: 'pickup_address',
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
        formatter: this.renderPickupAddress,
        sort: true,
        csvFormatter: excludeNull,
        text: 'Pickup Address',
      },
      {
        dataField: 'rating',
        headerStyle: {
          width: '90px',
          resize: 'horizontal'
        },
        sort: true,
        text: 'Rating',
      },
      {
        dataField: 'reviews',
        headerStyle: {
          width: '90px',
          resize: 'horizontal'
        },
        sort: true,
        text: 'Reviews',
      },
      {
        dataField: 'total_orders',
        headerStyle: {
          width: '90px',
          resize: 'horizontal'
        },
        sort: true,
        text: '# of Orders (Past 3 Months)',
      },
      {
        dataField: 'score',
        headerStyle: {
          width: '90px',
          resize: 'horizontal'
        },
        formatter: this.renderScore,
        sort: true,
        text: 'Ship On Time Rating',
      },
      {
        dataField: 'acc_name',
        csvFormatter: excludeNull,
        hidden: true,
        text: 'Bank Account Name',
      },
      {
        dataField: 'acc_no',
        csvFormatter: formatBankAcc,
        hidden: true,
        text: 'Bank Account Number',
      },
      {
        dataField: 'bank_name',
        csvFormatter: excludeNull,
        hidden: true,
        text: 'Bank Name',
      },
      {
        dataField: 'swift_code',
        csvFormatter: excludeNull,
        hidden: true,
        text: 'Swift Code',
      },
      {
        dataField: 'qna',
        formatter: this.renderQna,
        csvFormatter: excludeNull,
        sort: true,
        text: 'Q&A',
        headerStyle: {
          width: '80px',
        },
      },
      {
        dataField: 'products',
        formatter: this.renderProducts,
        csvFormatter: excludeNull,
        sort: true,
        text: 'Products',
        headerStyle: {
          width: '80px',
        },
      },
      {
        dataField: 'virtual_message',
        formatter: this.renderVM,
        csvFormatter: excludeNull,
        sort: true,
        text: 'Virtual Message',
        headerStyle: {
          width: '80px',
        },
      },
      {
        dataField: 'shipments',
        formatter: this.renderShipments,
        csvFormatter: excludeNull,
        sort: true,
        text: 'Shipments',
        headerStyle: {
          width: '80px',
        },
      },
    ]

    return (
      <div>
        <div className="row mt-3 mb-4">
          <div className="col-xs-12 col-md-9">
            <h2 className="sub-header">Merchants</h2>
          </div>
        </div>
        <Container fluid className="my-3 d-flex gap-3 flex-column flex-sm-row-reverse flex-wrap align-items-lg-end">
          <Col sm="auto">
            <Button variant="secondary" className='btn-br-6 w-100' onClick={this.handleSync} disabled={syncing}>
              { syncing ? 'Syncing' : 'Sync Merchants' }
            </Button>
          </Col>
        </Container>
        {
          loading &&
          <Loader />
        }
        {
          error &&
          <Alert variant="danger">
            {
              Array.isArray(error) ?
              <ul>
              {
                error.map((err, i) => (
                  <li key={i}>
                    {err.msg}
                  </li>
                ))
              }
              </ul>
              :
              error
            }
          </Alert>
        }
        {
          !loading && items &&
          <div className="merchants-table">
            <ToolkitProvider
              keyField='name'
              data={items}
              columns={columns}
              exportCSV={{
                exportAll: true
              }}
              search
            >
              {
                props => (
                  <>
                  <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                      <Col>
                        { isAdmin &&
                        <ExportCSVButton
                          className="btn-info"
                          style={{borderRadius:'8px'}}
                          { ...props.csvProps }
                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                          Export to CSV</ExportCSVButton>
                        }
                      </Col>
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps }
                        style={{borderRadius:'25px'}} placeholder="Search"/>
                      </Col>
                    </Container>

                  <BootstrapTable
                    { ...props.baseProps }
                    bordered={ false }
                    hover
                    pagination={paginationFactory(options)}
                    options={{
                      expandBy: "column",
                      sizePerPage: 20,
                      paginationShowsTotal: true,
                    }}
                  />
              </>
            )
          }
            </ToolkitProvider>
          </div>
        }
        {
          showConfirmModal &&
          <ConfirmModal onClickYes={this.deleteUser} onClose={this.handleOnCloseConfirmModal} title="Delete User">
            <p>Confirm delete this user ({email})?</p>
          </ConfirmModal>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    merchantRates: state.merchantRates,
    user: state.auth.user,
  };
}, merchantRatesActionCreators)(Merchants);
