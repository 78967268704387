import { useRef } from 'react'
import _ from 'lodash'
import { useDrag, useDrop } from 'react-dnd'
import { Row, Col, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const SortCustomizeFieldRow = ({ moveField, field, index }) => {
  const ref = useRef(null)
  const [{ isDragging }, drag] = useDrag({
    type: 'field',
    item: { field, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [{ handlerId }, drop] = useDrop({
    accept: 'field',
    collect: monitor => ({
      handlerId: monitor.getHandlerId()
    }),
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveField(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  const conditionalCount = field.conditionalFields ? field.conditionalFields.length : 0

  return (
    <Row ref={ref} style={{opacity}} data-handler-id={handlerId} className="mx-2 align-items-center justify-content-center border mb-1 cursor-move">
      <Col xs={1} className='justify-content-center d-flex gap-2'>
        <div className="text-secondary d-flex align-items-center">
          <FontAwesomeIcon icon={solid('grip-vertical')}/>
        </div>
      </Col>
      <Col className="my-2 d-flex justify-content-between align-items-center">
        <span>{field.primaryTitle}</span>
        <div className="d-flex flex-column align-items-end gap-1">
          <Badge size="sm" bg="secondary">{field.title}</Badge>
         {conditionalCount > 0 && <Badge size="sm" bg="primary">{conditionalCount} Conditional Field{conditionalCount > 1 ? 's' : ''}</Badge>}
        </div>
      </Col>
    </Row>
  )
}

export default SortCustomizeFieldRow
