import { useEffect, useState } from 'react';
import { Modal, Container, Col, Row, Badge, ToggleButton, ToggleButtonGroup, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/actions/products';
import Loader from './Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import api from '../helpers/apiClient';
import toast from 'react-hot-toast';
import ConfirmModal from './ConfirmModal';
import qs from 'qs';

const { SearchBar } = Search;

const EnableAddOnsModal = (props) => {
  const { item, deliveryType, updateForm, user, location } = props;
  const { loading, loaded, items } = useSelector(state => state.products)
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState([]);
  const [saved, setSaved] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [enabledFilter, setEnabledFilter] = useState('');

  useEffect(() => {
    if(user.merchantId){
      dispatch(fetchProducts(user.merchantId));
    }
  }, [])

  useEffect(() => {
    if (location && location.search && (user.role === 'admin' || user.role === 'staff')) {
      const queryString = qs.parse(location.search.substr(1));

      if (queryString.vendor && queryString.vendor !== 'any') {
        dispatch(fetchProducts(queryString.vendor));
      }
    }
  }, [location])

  useEffect(() => {
    if (!loaded) {
      return
    }

    if (items && items.length && _.every(items, item => !_.includes(item.tags, 'ADDONS'))) {
      setSelected(_.map(items, item => item.id));
      setSaved(false)
    } else {
      let enabled = _.filter(items, item => _.includes(item.tags, 'ADDONS'))
      setSelected(_.map(enabled, item => item.id));
      setSaved(true)
    }
  }, [loaded, items])

  const showImage = (cell) => {
    if(cell && cell.length && cell[0]){
      return <img style={{height: '70px'}} src={cell[0].src}/>;
    }
  }

  const renderStatus = cell => {
    switch (cell){
      case 'active':
        return <Badge bg="success">ACTIVE</Badge>
      case 'draft':
        return <Badge bg="default">DRAFT</Badge>
      case 'pending':
        return <Badge bg="warning">PENDING</Badge>
      case 'disapproved':
        return <Badge bg="danger">REJECTED</Badge>
      case 'reviewing':
        return <Badge bg="primary">IN REVIEW</Badge>
      case 'more_info':
        return <Badge bg="info">MORE INFO</Badge>
      default:
        break;
    }
  }

  const renderDate = (cell) => {

    return cell ? moment(cell).format("DD/MM/YYYY") : ''
  }

  const onRowSelect = (row, isSelected, e) => {

    if (isSelected) {
      setSelected([...selected, row.id])
    } else {
      setSelected(_.filter(selected, i => i !== row.id))
    }
  }

  const save = () => {
    setSubmitting(true);

    let body = {
      ids: selected,
    }

    if (user.role === 'admin' || user.role === 'staff') {
      if (location && location.search) {
        const queryString = qs.parse(location.search.substr(1));

        if (queryString.vendor) {
          body.vendor = queryString.vendor;
        }
      }
    }

    api(`/products/enable_addon`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then((result) => {
      if (result.error) {
        return toast.error(result.error);
      }
      toast.success('Saved!', { position: 'top-center' });
      setSaved(selected.length > 0)
      setSubmitting(false);
    })
  }

  const onClickClear = () => {
    setSelected([]);
  }

  const onClickSelectAll = () => {
    setSelected(_.map(items, item => item.id))
  }

  const handleHideModal = () => {
    setShow(false)

    let enabled = _.filter(items, item => _.includes(item.tags, 'ADDONS'))
    setSelected(_.map(enabled, item => item.id));
  }

  const columns = [
    { dataField: 'id', text: 'Id', hidden: true, },
    { dataField: 'images', text: 'Image', headerStyle: { width: '100px', resize: 'horizontal' }, align: 'center', formatter: showImage },
    { dataField: 'title', headerStyle: { width: '350px', resize: 'horizontal' }, sort: true, text: 'Title' },
    { dataField: 'status', headerStyle: { width: '100px', resize: 'horizontal' }, text: 'Status', sort: true, formatter: renderStatus, },
    { dataField: 'product_type', text: 'Type', sort: true, headerStyle: { width: '150px', resize: 'horizontal' }, },
  ];

  const selectRowProp = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: onRowSelect,
    headerColumnStyle: { width: '30px' },
    selected: selected,
    hideSelectAll: true,
  }

  const options = {
    showTotal: true,
    sizePerPage: 5,
    hideSizePerPage: true,
    sortName: "title",
    sortOrder: "asc",
  };

  return (
    <>
      <Button className="btn-br-6 d-flex align-items-center" variant="secondary" onClick={() => setShow(true)}>
        Enable Add-ons
        { selected.length > 0 && saved &&
          <div className="ms-2 info-badge px-2">
            {selected.length}
          </div>
        }
      </Button>
    <Modal style={{border: 'none'}} show={show} onHide={handleHideModal} size="xl">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4 className="mb-0">Enable Add-ons for Products</h4>
      </Modal.Header>
      <Modal.Body className="mx-4 px-0 pt-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
        {
          loading &&
            <Loader />
        }
        {
          !loading && items &&
            <ToolkitProvider
              keyField='id'
              data={_.filter(items, item => {
                let validEnabled = false

                if (enabledFilter === 'yes' && _.includes(item.tags, 'ADDONS')) {
                  validEnabled = true
                } else if (enabledFilter === 'no' && !_.includes(item.tags, 'ADDONS')) {
                  validEnabled = true
                } else if (enabledFilter === '') {
                  validEnabled = true
                }

                return validEnabled && item.template_suffix !== 'bulk'
              })}
              columns={columns}
              exportCSV={{
                exportAll: true
              }}
              search={{
                searchFormatted: true
              }}
            >
              {
                props => (
                  <>
                    <Container fluid>
                      <Row>
                        <Col md={6} className="d-flex gap-2 align-items-center">
                          <Button variant="link" size="sm" className="p-0" onClick={onClickSelectAll}>Select All</Button>
                          <Button variant="link" size="sm" className="p-0" onClick={onClickClear}>Clear</Button>
                        </Col>
                        <Col md={6}>
                          <SearchBar { ...props.searchProps }
                            style={{borderRadius:'25px'}} className="w-100" placeholder="Search"/>
                        </Col>
                      </Row>
                    </Container>
                    <div className="d-flex align-items-center gap-2">
                      <label>Enabled ({selected.length > 0 ? selected.length + ' Yes' : 0}):</label>
                      <Form.Select className="form-control w-auto" onChange={(e) => setEnabledFilter(e.target.value)} value={enabledFilter}>
                        <option value="">Any</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Form.Select>
                    </div>

                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={false}
                      selectRow={selectRowProp}
                      pagination={paginationFactory(options)}
                      hover
                      sort={{
                        dataField: "title",
                        order: "asc"
                      }}
                      options={{
                        expandBy: "column",
                        sizePerPage: 20,
                        paginationShowsTotal: true,
                      }}
                    />
                  </>
                )
              }
            </ToolkitProvider>
        }
      </Modal.Body>
      <Modal.Footer className="pt-0 justify-content-end mx-4 px-0" style={{borderTop: 'none'}}>
        <Button onClick={save} disabled={loading || submitting}>Save</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default EnableAddOnsModal;
