import { addError } from './error';
import api from '../../helpers/apiClient';
import _ from 'lodash';

export const FETCH_TAGGINGS_REQUEST = 'FETCH_TAGGINGS_REQUEST';
export const FETCH_TAGGINGS_SUCCESS = 'FETCH_TAGGINGS_SUCCESS';
export const FETCH_TAGGINGS_FAILURE = 'FETCH_TAGGINGS_FAILURE';

function fetchTaggingsRequest() {
  return {
    type: FETCH_TAGGINGS_REQUEST,
  };
}

function fetchTaggingsSuccess(json) {
  let items = _.reduce(json, (groups, item) => {
    if(groups[item.group]){
      groups[item.group].push(item)
    } else {
      groups[item.group] = [item]
    }
    return groups
  }, {})

  _.each(items, (group, key) => {
    items[key] = _.sortBy(group, 'seq')
  })

  return {
    type: FETCH_TAGGINGS_SUCCESS,
    payload: {
      items,
      receivedAt: Date.now(),
    }
  };
}

function fetchTaggingsFailure() {
  return {
    type: FETCH_TAGGINGS_FAILURE
  };
}

function shouldFetchTaggings(state) {
  const results = state.taggings;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

function fetchTaggingsFromApi() {
  return dispatch => {
    dispatch(fetchTaggingsRequest());
    return api(`/product/taggings`)
      .then(json => {
        dispatch(fetchTaggingsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchTaggingsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchTaggings() {
  return (dispatch, getState) => {
    if (shouldFetchTaggings(getState())) {
      return dispatch(fetchTaggingsFromApi());
    }
  }
}

