import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, Routes, Router } from 'react-router-dom'
import { Provider, ReactReduxContext } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist';
import configureStore, { history } from './redux/store/configureStore';
import App from './containers/App';
import Login from './containers/Login';
import OrderMessage from './containers/OrderMessage';
import TrackOrder from './containers/OrderTracking'
import OrderTrackingThankYou from './containers/OrderTrackingThankYou'
import ForgotPassword from './containers/ForgotPassword';
import PreviewProduct from './containers/PreviewProduct';
import Loader from './components/Loader';
import { setStore } from './helpers/apiClient';
import HttpsRedirect from 'react-https-redirect';
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/css/bootstrap-theme.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'loaders.css/loaders.min.css';
import './styles/app.css';
import persistCombineReducers from 'redux-persist/es/persistCombineReducers';
import rootReducer from './redux/reducers/index'
import storage from 'redux-persist/lib/storage'
import 'react-dates/initialize';
import 'iframe-resizer/js/iframeResizer.contentWindow';

export const { store, persistor } = configureStore();

class Root extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.querySelector('body').dataset.url = (window.location != window.parent.location) ? document.referrer : document.location.href;
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <HttpsRedirect>
            <ConnectedRouter history={history} context={ReactReduxContext} navigator={history}>
              <Switch>
                <Route path="/login" component={Login}/>
                <Route path="/forgot_password" component={ForgotPassword} />
                <Route path="/order_tracking" component={TrackOrder} />
                <Route path="/order_tracking_thank_you" component={OrderTrackingThankYou} />
                <Route path="/messages" component={OrderMessage} />
                <Route path="/products/preview" exact component={PreviewProduct} />
                <Route path="/" component={App}/>
              </Switch>
            </ConnectedRouter>
          </HttpsRedirect>
        </PersistGate>
      </Provider>
    );
  }
}

ReactDOM.render(
  <Root/>,
  document.getElementById('react-root')
)
