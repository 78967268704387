import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'
import invariant from 'redux-immutable-state-invariant';
import { REHYDRATE, persistCombineReducers, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
// import localforage from 'localforage';
import createActionBuffer from 'redux-action-buffer';
import rootReducer from '../reducers';
import { createBrowserHistory } from "history";

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
  // let enhancer;
  // const middleware = routerMiddleware(history);
  // if (process.env.NODE_ENV === 'production') {
  //   enhancer = compose(
  //     applyMiddleware(thunk, createActionBuffer(REHYDRATE), middleware),
  //     // applyMiddleware(thunk, middleware),
  //   )
  // } else {
  //   const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  //   enhancer = composeEnhancers(
  //     applyMiddleware(invariant(), thunk, createActionBuffer(REHYDRATE), middleware),
  //   )p
  // }
  // const store = createStore(rootReducer, enhancer);
  // if (module.hot) {
  //   module.hot.accept('../reducers', () =>
  //     store.replaceReducer(require('../reducers'))
  //   );
  // }

  const composeEnhancer = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

  const persistedReducer = persistReducer({
    key: 'root',
    storage,
    whitelist: ['auth', 'previewProduct']
  }, rootReducer(history)) // root reducer with router state

  const store = createStore(
    persistedReducer,
    composeEnhancer(
      applyMiddleware(
        thunk,
        routerMiddleware(history), // for dispatching history actions
        // ... other middlewares ...
      ),
    ),
  )

  const persistor = persistStore(store);

  // Hot reloading
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      store.replaceReducer('../reducers');
    });
  }

  return { store, persistor };
}
