import { useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { removeInlineStyles, removeEntities, removeBlockTypes } from '../helpers/draftjsClearFormat';

const ClearFormatButton = ({clearFormat}) => {
  return (
    <Button variant="secondary" size="sm" className="mb-2" onClick={clearFormat}>Clear Format</Button>
  )
}

const DeliveryFinePrintsEditModal = ({ onClose, count, dfpId, name, content, onSaved }) => {

    const { contentBlocks, entityMap } = convertFromHTML(content ? content.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '') : '')
    let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)

    const [deliveryFinePrintsEditorState, setDeliveryFinePrintsEditorState] = useState(EditorState.createWithContent(contentState));
    const [deliveryFinePrintsName, setDeliveryFinePrintsName] = useState(name);

    const deliveryFinePrintsEditorChange = deliveryFinePrintsEditorState => {

        setDeliveryFinePrintsEditorState(deliveryFinePrintsEditorState);
    }

    const submit = () => {

        let html = stateToHTML(deliveryFinePrintsEditorState.getCurrentContent())
        onSaved(html, deliveryFinePrintsName);
    }

    const onChangeDeliveryFinePrintsNameChange = e => {

        const { value } = e.target
        setDeliveryFinePrintsName(value)
    }

    const editorClearFormat = () => {
        const stateWithoutFormat = _.flow([
            removeInlineStyles,
            removeEntities,
            removeBlockTypes,
        ])(deliveryFinePrintsEditorState)

        setDeliveryFinePrintsEditorState(stateWithoutFormat)
    }

    return (
        <Modal show={true} onHide={onClose} size="lg">
            <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
                <h4 className="mb-0">Edit Delivery Fine Prints</h4>
            </Modal.Header>
            <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
                {
                    count > 0 &&
                    <p><a href={`/products?dfpId=${dfpId}`} target="_blank">{count} product(s) using this template.</a></p>
                }
                {
                    count === 0 &&
                    <p>{count} product(s) using this template.</p>
                }
                <input
                    type="text"
                    style={{backgroundColor: 'white'}}
                    className="form-control"
                    placeholder='Delivery Fine Print Name'
                    onChange={onChangeDeliveryFinePrintsNameChange}
                    value={deliveryFinePrintsName}/>
                <div style={{ border: '1px solid #ced4da', borderRadius: '4px', marginBottom: '1rem', marginTop: '1rem' }}></div>
                {/* set a white back ground */}
                <div style={{ backgroundColor: 'white' }} className="border border-gray-300 rounded-lg">
                    <Editor
                        toolbarCustomButtons={[<ClearFormatButton clearFormat={editorClearFormat} />]}
                        onEditorStateChange={deliveryFinePrintsEditorChange}
                        editorState={deliveryFinePrintsEditorState}
                        editorStyle={{ resize: 'vertical', height: '150px', padding: '0 1rem' }} />
                </div>
            </Modal.Body>
            <Modal.Footer className="pt-0" style={{ borderTop: 'none' }}>
                <Button variant="danger-secondary" onClick={onClose}>Cancel</Button>
                <Button variant="primary" onClick={submit}>{count > 0 ? `Save & Apply to ${count} Product(s)` : 'Save'}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeliveryFinePrintsEditModal;
