import qs from "qs";
import { addError } from './error';
import api from '../../helpers/apiClient';
import _ from 'lodash'

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

function fetchProductsRequest(merchantId, limit) {
  return {
    type: FETCH_PRODUCTS_REQUEST,
    payload: {
      merchantId,
      limit,
    }
  };
}

function fetchProductsSuccess(json) {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: {
      items: json,
      // next: json.next,
      receivedAt: Date.now(),
    }
  };
}

function fetchProductsFailure() {
  return {
    type: FETCH_PRODUCTS_FAILURE
  };
}

function shouldFetchProducts(state) {
  const results = state.products;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

function fetchProductsFromApi(merchantId, status, inventory, limit, dfpId) {
  const queryString = qs.stringify({
    merchantId,
    status,
    inventory,
    limit,
    dfpId
  }, { skipNulls: true });
  return dispatch => {
    dispatch(fetchProductsRequest(merchantId, limit));
    return api(`/products?${queryString}`)
      .then(json => {
        dispatch(fetchProductsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchProductsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchProducts(merchantId, status, inventory, limit, dfpId) {
  return (dispatch, getState) => {
    if (shouldFetchProducts(getState())) {
      return dispatch(fetchProductsFromApi(merchantId, status, inventory, limit, dfpId));
    }
  }
}

export function fetchProductsSimple(merchantId, dfpId) {
  return (dispatch, getState) => {
    if (shouldFetchProducts(getState())) {
      return dispatch(fetchProductsFromApi(merchantId, undefined, undefined, undefined, dfpId));
    }
  }
}
