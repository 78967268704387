import { 
  FETCH_USER_REQUEST, 
  FETCH_USER_SUCCESS, 
  FETCH_USER_FAILURE, 
  CLEAR_USER,
} from '../actions/user';

const defaultState = {
  loading: false,
  loaded: false,
  item: null,
};

export default function orders(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_USER:
      return {
        ...state,
        defaultState
      };
    case FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        item: action.payload.item,
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}