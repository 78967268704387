import {
  addError
} from './error';
import api from '../../helpers/apiClient';

export const SHIPPING_REQUEST = 'SHIPPING_REQUEST';
export const SHIPPING_REQUEST_SUCCESS = 'SHIPPING_REQUEST_SUCCESS';
export const SHIPPING_REQUEST_FAILURE = 'SHIPPING_REQUEST_FAILURE';
export const UNDO_MAKE_ORDER_REQUEST = 'UNDO_MAKE_ORDER_REQUEST';
export const MAKE_ORDER_REQUEST = 'MAKE_ORDER_REQUEST';
export const MAKE_ORDER_SUCCESS = 'MAKE_ORDER_SUCCESS';
export const MAKE_ORDER_FAILURE = 'MAKE_ORDER_FAILURE';
export const MAKE_PAYMENT_REQUEST = 'MAKE_PAYMENT_REQUEST';
export const MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS';
export const MAKE_PAYMENT_FAILURE = 'MAKE_PAYMENT_FAILURE';
export const MAKE_DHL_SHIPMENT_REQUEST = 'MAKE_DHL_SHIPMENT_REQUEST';
export const MAKE_DHL_SHIPMENT_SUCCESS = 'MAKE_DHL_SHIPMENT_SUCCESS';
export const MAKE_DHL_SHIPMENT_FAILURE = 'MAKE_DHL_SHIPMENT_FAILURE';
export const RESET_DATA = 'RESET_DATA';
export const RESET_RATES = 'RESET_RATES';

function fetchShippingRatesRequest(orderId) {
  return {
    type: SHIPPING_REQUEST
  };
}

function fetchShippingRatesSuccess(json) {
  return {
    type: SHIPPING_REQUEST_SUCCESS,
    payload: {
      rates: json,
    }
  };
}

function fetchShippingRatesFailure() {
  return {
    type: SHIPPING_REQUEST_FAILURE
  };
}

function makeOrderRequest() {
  return {
    type: MAKE_ORDER_REQUEST
  };
}

function makeOrderFailure() {
  return {
    type: MAKE_ORDER_FAILURE
  };
}

function makeOrderSuccess(json) {
  return {
    type: MAKE_ORDER_SUCCESS,
    payload: {
      shipments: json,
    }
  };
}

function makePaymentRequest() {
  return {
    type: MAKE_PAYMENT_REQUEST
  };
}

function makePaymentSuccess(json) {
  return {
    type: MAKE_PAYMENT_SUCCESS,
    payload: {
      payment: json,
    }
  };
}

function makePaymentFailure() {
  return {
    type: MAKE_PAYMENT_FAILURE
  };
}

function makeDHLShipmentRequest() {
  return {
    type: MAKE_DHL_SHIPMENT_REQUEST
  };
}

function makeDHLShipmentSuccess(json) {
  return {
    type: MAKE_DHL_SHIPMENT_SUCCESS,
    payload: {
      shipments: json
    }
  };
}

function makeDHLShipmentFailure() {
  return {
    type: MAKE_DHL_SHIPMENT_FAILURE
  };
}

export function undoMakeOrder() {
  return dispatch => {
    dispatch({
      type: UNDO_MAKE_ORDER_REQUEST
    })
  }
}

export function resetData() {
  return dispatch => {
    dispatch({
      type: RESET_DATA
    })
  }
}

export function resetRates() {
  return dispatch => {
    dispatch({
      type: RESET_RATES
    })
  }
}

function shouldFetchShippingRates(state) {
  const results = state.shipping;
  if (results && results.loading) {
    return false;
  } else {
    return true;
  }
}

function fetchShippingRatesFromApi(pickupPostcode, items) {
  return dispatch => {
    dispatch(fetchShippingRatesRequest());

    let parcels = _.map(items, parcel => {
      return {
        weight: parcel.weight,
        pickup_postcode: pickupPostcode,
        send_postcode: parcel.shipping_address.zip,
      }
    })

    return api(`/easyparcel/quote`, {
        method: 'POST',
        body: JSON.stringify({
          parcels: parcels
        })

      })
      .then(json => {
        dispatch(fetchShippingRatesSuccess(json.result));
      })
      .catch(err => {
        console.log('fetch error', err)
        dispatch(fetchShippingRatesFailure());
        dispatch(addError(err));
      });
  };
}

function fetchShippingRatesFromApiSG(pickupPostcode, deliveryPostcode, weight) {
  return dispatch => {
    dispatch(fetchShippingRatesRequest());

    let parcels = [{
      weight: weight,
      pickup_postcode: pickupPostcode,
      pickup_country: 'MY',
      send_postcode: deliveryPostcode,
      send_country: 'SG'
    }]

    return api(`/easyparcel/quote`, {
        method: 'POST',
        body: JSON.stringify({
          parcels: parcels
        })

      })
      .then(json => {
        dispatch(fetchShippingRatesSuccess(json.result));
      })
      .catch(err => {
        console.log('fetch error', err)
        dispatch(fetchShippingRatesFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchShippingRates(pickupPostcode, parcels) {
  return (dispatch, getState) => {
    if (shouldFetchShippingRates(getState())) {
      return dispatch(fetchShippingRatesFromApi(pickupPostcode, parcels));
    }
  };
}

export function fetchShippingRatesSG(pickupPostcode, deliveryPostcode, weight) {
  return (dispatch, getState) => {
    if (shouldFetchShippingRates(getState())) {
      return dispatch(fetchShippingRatesFromApiSG(pickupPostcode, deliveryPostcode, weight));
    }
  };
}

export function makeOrder(pickupDate, serviceId, pickupAddress, items) {
  let parcels = items.map(parcel => {
    return {
      order_name: parcel.order_name,
      service_id: serviceId,
      weight: parcel.weight,
      content: "Gifts",
      value: parcel.value,
      collect_date: pickupDate,
      pick_name: pickupAddress.name,
      pick_contact: pickupAddress.contact,
      pick_company: pickupAddress.company,
      pick_addr1: pickupAddress.address1,
      pick_addr2: pickupAddress.address2,
      pick_code: pickupAddress.postcode,
      pick_city: pickupAddress.city,
      pick_state: pickupAddress.state,
      pick_country: pickupAddress.country,
      send_name: parcel.shipping_address.name,
      send_contact: parcel.shipping_address.phone,
      send_addr1: parcel.shipping_address.address1,
      send_addr2: parcel.shipping_address.address2,
      send_code: parcel.shipping_address.zip,
      send_city: parcel.shipping_address.city,
      send_state: parcel.shipping_address.province_code,
      send_country: parcel.shipping_address.country_code
    }
  })

  const body = {
    parcels,
  }

  return dispatch => {
    dispatch(makeOrderRequest());
    return api(`/easyparcel/order`, {
        method: 'POST',
        body: JSON.stringify(body)
      })
      .then(json => {
        if (json[0].status.toLowerCase() === 'fail') {
          throw new Error(json.remarks);
        }

        dispatch(makeOrderSuccess(json));
      })
      .catch(err => {
        console.log('fetch error', err)
        dispatch(makeOrderFailure());
        dispatch(addError(err));
      });
  };
}

export function makeOrderSG(pickupDate, serviceId, pickupAddress, shippingAddresses, 
  orderName, weight, parcelCategory, parcelContent, parcelValue, length, width, height) {
  let parcels = shippingAddresses.map(shippingAddress => {
    return {
      order_name: orderName,
      service_id: serviceId,
      weight: weight,
      content: parcelContent,
      width: width,
      length: length,
      height: height,
      value: parcelValue,
      parcel_category: parcelCategory,
      collect_date: pickupDate,
      pick_name: pickupAddress.name,
      pick_contact: pickupAddress.contact,
      pick_company: pickupAddress.company,
      pick_addr1: pickupAddress.address1,
      pick_addr2: pickupAddress.address2,
      pick_code: pickupAddress.postcode,
      pick_city: pickupAddress.city,
      pick_state: pickupAddress.state,
      pick_country: pickupAddress.country,
      send_name: shippingAddress.name,
      send_contact: shippingAddress.phone,
      send_addr1: shippingAddress.address1,
      send_addr2: shippingAddress.address2,
      send_code: shippingAddress.zip,
      send_city: shippingAddress.city,
      send_state: shippingAddress.province_code,
      send_country: shippingAddress.country_code
    }
  })

  const body = {
    parcels,
  }

  return dispatch => {
    dispatch(makeOrderRequest());
    return api(`/easyparcel/order`, {
        method: 'POST',
        body: JSON.stringify(body)
      })
      .then(json => {
        if (json[0].status.toLowerCase() === 'fail') {
          throw new Error(json[0].remarks);
        }

        dispatch(makeOrderSuccess(json));
      })
      .catch(err => {
        console.log('fetch error', err)
        dispatch(makeOrderFailure());
        dispatch(addError(err));
      });
  };
}

export function makePayment(parcels, serviceName, pickupDate, pickupAddress) {

  return (dispatch, getState) => {
    const state = getState()
    const body = {
      orders: state.shipping.shipments,
      parcels: parcels,
      serviceName: serviceName,
      pickupDate: pickupDate,
      pick_name: pickupAddress.name,
      pick_addr1: pickupAddress.address1,
      pick_addr2: pickupAddress.address2,
      pick_city: pickupAddress.city,
      pick_state: pickupAddress.state,
      pick_postcode: pickupAddress.postcode,
      pick_country: pickupAddress.country,
      pick_contact: pickupAddress.contact,
      pick_email: pickupAddress.email,
    }

    dispatch(makePaymentRequest());
    return api(`/easyparcel/pay`, {
        method: 'POST',
        body: JSON.stringify(body)
      })
      .then(json => {

        dispatch(makePaymentSuccess(json));
        return json
      }).catch(err => {
        console.log('fetch error', err)
        dispatch(makePaymentFailure());
        dispatch(addError(err));
      });
  };
}

export function makeDHLShipment(items, itemsAddress, isProcurement, returnAddress) {
  let parcels = items.map((parcel, i) => {
    const shipping_address = itemsAddress[i];

    return {
      order_name: parcel.order_name,
      order_id: parcel.order_id,
      line_items: parcel.line_items,
      weight: parcel.weight,
      send_name: shipping_address.name,
      send_addr1: shipping_address.address1,
      send_addr2: shipping_address.address2,
      send_postcode: shipping_address.zip,
      send_city: shipping_address.city,
      send_state: shipping_address.province || shipping_address.province_code,
      send_country: shipping_address.country_code,
      send_phone: shipping_address.phone,
      shipping_fee: parcel.shipping_fee,
      vendor: parcel.vendor,
    }
  })

  return dispatch => {
    dispatch(makeDHLShipmentRequest());
    return api(`/dhl/label`, {
        method: 'POST',
        body: JSON.stringify({ parcels, isProcurement, returnAddress })
      })
      .then(json => {
        dispatch(makeDHLShipmentSuccess(json));
      })
      .catch(err => {
        console.log('fetch error', err)
        dispatch(makeDHLShipmentFailure());
        dispatch(addError(err));
      });
  };
}

export function makeDHLShipmentWithPickup(items, itemsAddress, pickupDateTime, pickupAddress) {
  let parcels = items.map((parcel, i) => {
    const shipping_address = itemsAddress[i];

    return {
      order_name: parcel.order_name,
      order_id: parcel.order_id,
      line_items: parcel.line_items,
      weight: parcel.weight,
      send_name: shipping_address.name,
      send_addr1: shipping_address.address1,
      send_addr2: shipping_address.address2,
      send_postcode: shipping_address.zip,
      send_city: shipping_address.city,
      send_state: shipping_address.province,
      send_country: shipping_address.country_code,
      send_phone: shipping_address.phone,
      shipping_fee: parcel.shipping_fee,
    }
  })

  const body = {
    parcels: parcels,

    pickup_date: pickupDateTime,
    pick_name: pickupAddress.name,
    pick_addr1: pickupAddress.address1,
    pick_addr2: pickupAddress.address2,
    pick_city: pickupAddress.city,
    pick_state: pickupAddress.state,
    pick_postcode: pickupAddress.postcode,
    pick_country: pickupAddress.country,
    pick_contact: pickupAddress.contact,
    pick_email: pickupAddress.email,
  }

  return dispatch => {
    dispatch(makeDHLShipmentRequest());
    return api(`/dhl/label_pickup`, {
        method: 'POST',
        body: JSON.stringify(body)
      })
      .then(json => {
        dispatch(makeDHLShipmentSuccess(json));
      })
      .catch(err => {
        console.log('fetch error', err)
        dispatch(makeDHLShipmentFailure());
        dispatch(addError(err));
      });
  };
}

// export function clearOrderDetail() {
//   return {
//     type: CLEAR_ORDER_DETAIL,
//   };
// }
