import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card, Button, Badge, ButtonToolbar, Row, Col, Anchor } from 'react-bootstrap';
import Fulfillment from '../components/Fulfillment';
import TrackingInfoModal from '../components/TrackingInfoModal';
import EditDeliveryModal from '../components/EditDeliveryModal';
import GTRAlert from '../components/GTRAlert';
import Loader from '../components/Loader';
import ShippingModal from '../components/ShippingModal';
import ConfirmModal from '../components/ConfirmModal';
import RemarkModal from '../components/RemarkModal';
import CreateManualOrderModal from "./CreateManualOrderModal";
import api from '../helpers/apiClient';
import _ from 'lodash'
import * as manualOrderDetailActionCreators from '../redux/actions/manualOrderDetail';
import * as errorActionCreators from '../redux/actions/error';
import config from '../config'
import Clipboard from 'clipboard';
import ManualOrderLineItems from '../components/ManualOrderLineItems';
import FinancialStatusModal from '../components/FinancialStatusModal';
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import EditDeliveryInfoModal from '../components/EditDeliveryInfoModal';
import EditBillingAddressModal from '../components/EditBillingAddressModal';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class ManualOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowAddFulfillmentModal: false,
      isShowEditFulfillmentModal: false,
      selectedUnfulfilledItemIds: [],
      selectedFulfillmentId: null,
      isShowConfirmCancelFulfillmentModal: false,
      showShippingModal: false,
      showEditDeliveryModal: false,
      showRemarkModal: false,
      showFinancialStatusModal: false,
      showCancelManualOrderModal: false,
      showEditManualOrderModal: false,
      uploadingFile: false,
      showEditDeliveryInfoModal: false,
      showEditBillingAddressModal: false,
    };
  }

  componentDidMount() {
    const { fetchManualOrderDetail, match } = this.props;
    fetchManualOrderDetail(match.params.orderId);
    let clipboard = new Clipboard('.copy-to-clipboard');

    clipboard.on('success', function(e) {

      e.trigger.innerText = 'COPIED'
      e.clearSelection();
    });
  }

  handleOnClickShowShippingModal = () => {
    this.setState({ showShippingModal: true });
  }

  handleOnCloseShowShippingModal = () => {
    this.setState({ showShippingModal: false });
  }

  handleOnClickShowAddFulfillImentModal = () => {
    this.setState({ isShowAddFulfillmentModal: true });
  }

  handleOnCloseAddFulfillmentModal = () => {
    this.setState({ isShowAddFulfillmentModal: false });
  }

  handleOnClickShowRemarkModal = () => {
    this.setState({ showRemarkModal: true });
  }

  handleOnCloseRemarkModal = () => {
    this.setState({ showRemarkModal: false });
  }

  handleOnClickShowEditDeliveryModal = () => {
    this.setState({ showEditDeliveryModal: true });
  }

  handleOnCloseEditDeliveryModal = () => {
    this.setState({ showEditDeliveryModal: false });
  }

  handleOnClickShowEditFulfillmentModal = () => {
    this.setState({
      isShowEditFulfillmentModal: true,
    });
  }

  handleOnCloseEditFulfillmentModal = () => {
    this.setState({ isShowEditFulfillmentModal: false });
  }

  handleOnClickShowConfirmCancelFulfillmentModal = () => {
    this.setState({
      isShowConfirmCancelFulfillmentModal: true,
    });
  }

  handleOnCloseConfirmCancelFulfillmentModal = () => {
    this.setState({ isShowConfirmCancelFulfillmentModal: false });
  }

  handleOnClickShowFinancialStatusModal = () => {
    this.setState({ showFinancialStatusModal: true });
  }

  handleOnCloseFinancialStatusModal = () => {
    this.setState({ showFinancialStatusModal: false });
  }

  handleOnClickShowCancelManualOrder = () => {
    this.setState({ showCancelManualOrderModal: true });
  }

  handleOnCloseCancelManualOrderModal = () => {
    this.setState({ showCancelManualOrderModal: false });
  }

  handleOnClickShowEditManualOrder = () => {
    this.setState({ showEditManualOrderModal: true });
  }

  handleOnCloseEditManualOrderModal = () => {
    this.setState({ showEditManualOrderModal: false });
  }

  handleOnCheckUnfulfilledItem = (e) => {
    const { selectedUnfulfilledItemIds } = this.state;
    const { checked, value } = e.target;
    if (checked) {
      this.setState({
        selectedUnfulfilledItemIds: [...selectedUnfulfilledItemIds, value]
      });
    }
    else {
      this.setState({
        selectedUnfulfilledItemIds: selectedUnfulfilledItemIds.filter(id => id !== value)
      });
    }
  }

  handleOnClickMarkAsReady = () => {
    const { selectedUnfulfilledItemIds } = this.state;
    const { fetchManualOrderDetail, match } = this.props;

    return api(`/manual_orders/${match.params.orderId}/ready_to_ship`, {
      method: 'POST',
    }).then(() => {
      this.setState({
        selectedUnfulfilledItemIds: []
      })
      fetchManualOrderDetail(match.params.orderId);
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
      }
    });
  }

  handleDeliveryDateChange = async (date) => {

    const { setManualOrderDetail, match } = this.props;

    return api(`/manual_orders/${match.params.orderId}/update`, {
      method: 'POST',
      body: JSON.stringify({ 
        delivery_date: moment(date).format('DD/MM/YYYY'),
        ship_by_date: undefined,
      }),
      headers: {'Content-Type': 'application/json'}
    }).then((order) => {
      this.setState({
        showEditDeliveryModal: false,
        selectedUnfulfilledItemIds: []
      })
      setManualOrderDetail(order);
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          showEditDeliveryModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    })
  }

  handleOnClickFulfill = async (deliveryType, trackingCompany, trackingNumber, trackingUrl, file) => {
    console.log('fulfilling...', trackingNumber, trackingUrl, file);
    const { fetchManualOrderDetail, match, orderDetail: { item } } = this.props;
    const { selectedUnfulfilledItemIds } = this.state;

    const file_url = file ? await this.uploadFile(file) : '';

    return api(`/manual_orders/${match.params.orderId}/fulfill`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tracking_number: trackingNumber,
        tracking_url: trackingUrl,
        pod: file_url
      })
    }).then(() => {
      this.setState({ isShowAddFulfillmentModal: false, isShowEditFulfillmentModal: false})
      fetchManualOrderDetail(match.params.orderId);
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          isShowAddFulfillmentModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    })
  }

  uploadFile = async file => {
    let formData = new FormData();
    formData.append('file', file)
    const data = await api('/file/upload/proof_of_delivery', {
      method: 'POST',
      body: formData
    }, {'Accept': 'application/json'})

    return data.file_short_url
  }

  handleOnClickCancelFulfillment = () => {
    const { fetchManualOrderDetail, match } = this.props;

    return api(`/manual_orders/${match.params.orderId}/fulfill`, { method: 'DELETE' })
      .then(() => {
        this.setState({ isShowConfirmCancelFulfillmentModal: false });
        fetchManualOrderDetail(match.params.orderId);
      })
      .catch((err) => {
        if (err) {
          const { addError } = this.props;
          if (err.validationError) {
            addError(err.validationError);
          }
          else if (err.message) {
            addError(err.message);
          }
          else {
            addError(err);
          }
          this.setState({
            isShowConfirmCancelFulfillmentModal: false,
          }, () => {
            window.scrollTo(0, 0);
          })
        }
      })
  }

  printOrder = () => {
    window.print();
  }

  handleOnSaveRemark = (remarks) => {
    const { match, orderDetail: { item }, fetchManualOrderDetail } = this.props

    return api(`/manual_orders/${match.params.orderId}/remarks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ remarks: remarks[0].value })
    }).then(() => {
      this.setState({
        showRemarkModal: false,
      })
      fetchManualOrderDetail(match.params.orderId);
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
        this.setState({
          showRemarkModal: false,
        }, () => {
          window.scrollTo(0, 0);
        })
      }
    });

  }

  handleOnSaveFinancialStatus = (financial_status, partial_paid_amount, payment_due_date) => {
    const { match, fetchManualOrderDetail } = this.props;

    return api(`/manual_orders/${match.params.orderId}/financial_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        financial_status,
        partial_paid_amount,
        payment_due_date,
      })
    }).then(() => {
      this.setState({
        showFinancialStatusModal: false
      });
      fetchManualOrderDetail(match.params.orderId);
    })
  }

  handleOnSaveManualOrder = () => {
    const { match, fetchManualOrderDetail } = this.props;

    this.setState({
      showEditManualOrderModal: false
    });

    fetchManualOrderDetail(match.params.orderId);
  }

  handleOnClickCancelManualOrder = () => {
    const { fetchManualOrderDetail, match } = this.props;

    return api(`/manual_orders/${match.params.orderId}`, {
      method: 'DELETE',
    }).then(() => {
      this.setState({
        showCancelManualOrderModal: false,
      });
      fetchManualOrderDetail(match.params.orderId);
    })
  }

  onDropOrderInvoice(files) {

    if(files && files.length > 0){
      this.setState({
        uploadingFile: true
      });

      const { fetchManualOrderDetail, match, orderDetail: { item : {vendor }} } = this.props;

      var formData = new FormData();
      formData.append('order_invoice', files[0]);
      formData.append('vendor', vendor)

      const url = window.location.pathname
      api(url + '/attach/invoice' , {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'}).then(data => {
        this.setState({
          uploadingFile: false
        });
        fetchManualOrderDetail(match.params.orderId);
      }).catch(err =>{
        this.setState({
          uploadingFile: false
        });
        console.log(err)
      })
    }
  }

  onDropSalesReceipt = (files) => {
    if(files && files.length > 0){
      this.setState({
        uploadingFile: true
      });

      const { fetchManualOrderDetail, match, orderDetail: { item : {vendor }} } = this.props;

      var formData = new FormData();
      _.each(files, (file, i) => {
        formData.append(`sales_receipt_${i}`, file);
      })
      formData.append('vendor', vendor)

      const url = window.location.pathname
      api(url + '/sales_receipts' , {
        method: 'POST',
        body: formData
      }, {'Accept': 'application/json'}).then(data => {
        console.log(data);
        this.setState({
          uploadingFile: false
        });
        fetchManualOrderDetail(match.params.orderId);
      }).catch(err =>{
        this.setState({
          uploadingFile: false
        });
        console.log(err)
      })
    }
  }

  handleReload = () => {
    const { fetchManualOrderDetail, match } = this.props;
    fetchManualOrderDetail(match.params.orderId);
  }

  onClickEditDeliveryInfo = () => {
    this.setState({ showEditDeliveryInfoModal: true });
  }

  handleOnCloseEditDeliveryInfo = () => {
    this.setState({ showEditDeliveryInfoModal: false });
  }

  handleSavedDeliveryInfo = (order) => {
    const { setManualOrderDetail } = this.props;

    this.setState({ showEditDeliveryInfoModal: false });
    setManualOrderDetail(order);
  }

  onClickEditBillingAddress = () => {
    this.setState({ showEditBillingAddressModal: true });
  }

  handleOnCloseEditBillingAddress = () => {
    this.setState({ showEditBillingAddressModal: false });
  }

  handleSavedBillingAddress = (order) => {
    const { setManualOrderDetail } = this.props;

    this.setState({ showEditBillingAddressModal: false });
    setManualOrderDetail(order);
  }

  render() {
    const { orderDetail: { loading, loaded, item }, error, user } = this.props;
    const { isShowAddFulfillmentModal, selectedUnfulfilledItemIds, isShowEditFulfillmentModal, isShowConfirmCancelFulfillmentModal, showShippingModal, showRemarkModal, showEditDeliveryModal, showFinancialStatusModal, showCancelManualOrderModal, uploadingFile, showEditDeliveryInfoModal, showEditBillingAddressModal, showEditManualOrderModal } = this.state;

    const isAdmin = user && (user.role === 'admin' || user.role === 'staff');

    return (
      <>
        {
          loading &&
          <Loader />
        }
        {
          error &&
          <GTRAlert variant="danger" alert={error} />
        }
        {
          !loading && item &&
          <>
            <div className="row mt-3 mb-4">
              <div className="col-xs-12 col-md-9">
                <h2 className="sub-header">Order Detail</h2>
              </div>
            </div>
            <div className='d-grid gap-4'>
              <div className="d-none d-print-block">⚠️ KINDLY DO NOT PASS THIS ORDER SLIP TO RECEIVER</div>
              <Row>
                <Col sm={6} md={5} lg={4}>
                <Card className="dashboard-card shadow-sm p-2">
                  <Card.Body>
                      <div>
                        <h4 className="mb-0 d-inline-block" style={{color: '#80d7cf'}}>{item.order_id}</h4>
                        {
                          user && user.role === 'admin' && !item.cancelled_at &&
                          <Button className="pull-right ms-auto py-0 d-print-none" variant="danger-secondary" onClick={this.handleOnClickShowCancelManualOrder}>Cancel</Button>
                        }
                        {
                          user && user.role === 'admin' && !item.cancelled_at && item.fulfillment_status === 'unfulfilled' &&
                          <Button className="pull-right ms-auto py-0 me-1 d-print-none" variant="secondary" onClick={this.handleOnClickShowEditManualOrder}>Edit</Button>
                        }
                      </div>
                      <p className="d-print-none my-3">placed on {moment(item.created).format('YYYY-MM-DD HH:mm:ss')}</p>
                      {
                        !item.cancelled_at &&
                        <Button className="btn-br-6 d-print-none" variant="primary" onClick={this.printOrder}>Print Order Slip</Button>
                      }
                      {
                        item.cancelled_at &&
                        <Badge bg="danger" style={{lineHeight: '1.5em'}}>CANCELLED</Badge>
                      }
                  </Card.Body>
                </Card>
                </Col>
                <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
                <Col>
                  <Card className="d-print-none tealCard mt-4 mt-sm-0 shadow-sm">
                    <Card.Header className="tealCardHeader">
                      Order Info
                      {
                        user && user.role === 'admin' &&
                        <Button className="pull-right d-print-none py-0 btn-br-6" variant="secondary" onClick={this.handleOnClickShowFinancialStatusModal}>Update Financial Status</Button>
                      }
                    </Card.Header>
                    <Card.Body className="grey-label">
                        <Row className="flex-row">
                          <Col lg={4} className="m-2">
                            <Row className="flex-lg-column w-100">
                              <Col xs={6} lg={12} className="d-grid mb-2">
                              <label>Financial Status</label>
                              <span className="text-uppercase">{item.financial_status.replace("_", " ")}</span>
                              </Col>
                              {
                                item.financial_status === 'partially_paid' &&
                                  <>
                                    <Col xs={6} lg={12} className="d-grid mb-2">
                                      <label>Partial Paid Amount</label>
                                      <span>{currency} {item.partial_paid_amount}</span>
                                    </Col>
                                    <Col xs={6} lg={12} className="d-grid mb-2">
                                      <label>Due Date</label>
                                      <span>{moment(item.payment_due_date).format("YYYY-MM-DD")}</span>
                                    </Col>
                                  </>
                              }
                              <Col  xs={6} lg={12} className="d-grid mb-2">
                              <label>Fulfillment Status</label>
                              {
                                item.fulfillment_status ?
                                <span className="text-uppercase" style={{color: '#4e8767'}}>{item.fulfillment_status}</span>
                                :
                                <span className="text-uppercase" style={{color: '#ffc107'}}>Unfulfilled</span>
                              }
                              </Col>
                              {
                                item.cancelled_at &&
                                <Col xs={6} lg={12} className="d-grid mb-2">
                                  <label>Order Status</label>
                                  <span  className="text-uppercase" style={{color: '#d15d5d'}}>Cancelled</span>
                                </Col>
                              }
                          </Row>
                        </Col>
                        <div className="vr d-none d-lg-block col-lg-1 dashboard-vr"></div>
                        <Col className="m-2 mt-2 my-lg-auto label-strong">
                          {
                            isAdmin &&
                              <>
                            <Row>
                              <Col className="text-end"><label>Item Price</label></Col>
                              <Col>{currency} {item.item_price.toFixed(2)}</Col>
                            </Row>
                          { item.markup_type && item.markup && item.markup_total &&
                          <>
                            <Row>
                              <Col className="text-end"><label>Markup</label></Col>
                              <Col>{item.markup_type === 'percentage' ? `${item.markup}%` : item.markup_type}</Col>
                            </Row>
                            <Row>
                              <Col className="text-end"><label>Markup Total</label></Col>
                              <Col>{currency} {item.markup_total.toFixed(2)}</Col>
                            </Row>
                          </>
                          }
                            <Row>
                              <Col className="text-end"><label>Total Item Price</label></Col>
                              <Col>{currency} {(item.item_price + item.markup_total).toFixed(2)}</Col>
                            </Row>
                                <hr style={{margin: '5px', color:'#707070'}} />
                                <Row>
                                  <Col className='text-end'><label>Subtotal</label></Col>
                                  <Col>{currency} {item.item_price.toFixed(2)}</Col>
                                </Row>
                                <Row>
                                  <Col className='text-end'><label>Shipping Subtotal</label></Col>
                                  <Col>{currency} {item.shipping_fee.toFixed(2)}</Col>
                                </Row>
                                <Row>
                                  <Col className='text-end'><label>Commission</label></Col>
                                  <Col>{currency} {parseFloat(item.commission).toFixed(2)}</Col>
                                </Row>
                                <Row>
                                  <Col className='text-end'><label>SST</label></Col>
                                  <Col>{currency} {item.sst.toFixed(2)}</Col>
                                </Row>
                                <Row>
                                  <Col className='text-end'><label>Balance</label></Col>
                                  <Col>{currency} {(item.item_price - item.commission - item.sst + item.shipping_fee).toFixed(2)}</Col>
                                </Row>
                                <hr style={{margin: '5px', color:'#707070'}} />
                                <Row>
                                  <Col className='text-end'><label>Total</label></Col>
                                  <Col>{currency} {(item.item_price + item.shipping_fee + item.markup_total).toFixed(2)}</Col>
                                </Row>
                                <Row>
                                  <Col className='text-end'><label>Order Invoice</label></Col>
                                  <Col>
                                    {
                                      item.order_invoice &&
                                        <a href={item.order_invoice} target="_blank" style={{wordBreak: 'break-word'}}>{item.order_invoice}</a>
                                    }
                                    <Dropzone
                                      style={{}}
                                      onDrop={this.onDropOrderInvoice.bind(this)}
                                      multiple={false}
                                      accept='application/pdf,application/zip'>
                                      <Button className="copy-to-clipboard" variant="secondary" disabled={uploadingFile}>{uploadingFile ? "Uploading" : "Attach Order Invoice"}</Button>
                                    </Dropzone>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className='text-end'><label>Sales Receipt</label></Col>
                                  <Col>
                                    {
                                      item.sales_receipt && item.sales_receipt.length > 0 &&
                                        _.map(item.sales_receipt, (receipt, i) => {
                                          return (
                                            <div key={i} className="d-grid">
                                              <span>{receipt.filename}</span>
                                              <Anchor target="_blank" href={receipt.link} variant="primary" style={{wordBreak: 'break-all'}}>{receipt.link}</Anchor>
                                            </div>
                                          )
                                        })
                                    }
                                    <Dropzone
                                      style={{}}
                                      onDrop={this.onDropSalesReceipt}
                                      multiple={true}
                                    >
                                      <Button className="copy-to-clipboard" variant="secondary" disabled={uploadingFile}>{uploadingFile ? "Uploading" : "Attach Files"}</Button>
                                    </Dropzone>
                                  </Col>
                                </Row>
                            </>
                          }
                          {!isAdmin &&
                            <>
                              <Row>
                                <Col className='text-end'><label>Subtotal</label></Col>
                                <Col>{currency} {item.item_price.toFixed(2)}</Col>
                              </Row>
                              <Row>
                                <Col className='text-end'><label>Shipping Subtotal</label></Col>
                                <Col>{currency} {item.shipping_fee.toFixed(2)}</Col>
                              </Row>
                              <Row>
                                <Col className='text-end'><label>Commission</label></Col>
                                <Col>{currency} {parseFloat(item.commission).toFixed(2)}</Col>
                              </Row>
                              <Row>
                                <Col className='text-end'><label>SST</label></Col>
                                <Col>{currency} {item.sst.toFixed(2)}</Col>
                              </Row>
                              <Row>
                                <Col className='text-end'><label>Balance</label></Col>
                                <Col>{currency} {(item.item_price - item.commission - item.sst + item.shipping_fee).toFixed(2)}</Col>
                              </Row>
                            </>
                          }
                          {
                            item.financial_status === 'partially_paid' &&
                              <Row>
                                <Col className='text-end'><label>Total Due</label></Col>
                                <Col>{currency} {(item.item_price + item.shipping_fee + item.markup_total - item.partial_paid_amount).toFixed(2)}</Col>
                              </Row>
                          }
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
              <Col>
              <Card className="tealCard shadow-sm">
                <Card.Header className="tealCardHeader">Customer Info</Card.Header>
                <Card.Body className="mx-2">
                  <Row className="overflowX-auto">
                    <Col lg={2} className="d-print-none grey-label">
                      <div className="d-grid mb-2">
                        <label>Customer Name</label>
                        <span>{item.first_name} {item.last_name}</span>
                      </div>
                      <div className="d-grid mb-2">
                        <label>Email</label>
                        <span style={{wordBreak: 'break-all'}}>{item.email}</span>
                      </div>
                      <div className="d-grid mb-2">
                        <label>Sender's Phone Number</label>
                        <span className="delivery-info-value">{item.sender_phone_number}</span>
                      </div>
                    </Col>
                      {
                        (item.note || (item.note_attributes && item.note_attributes.length)) || item.delivery_address ?
                        <Col lg={6} className="mt-2 mt-lg-0">
                          <Card className="greyCard">
                            <Card.Header className="greyCardHeader">
                              Delivery Info
                              {
                                user && user.role === 'admin' &&
                                <Button className="pull-right d-print-none add-remark" variant="link" onClick={this.handleOnClickShowRemarkModal}>Add Remark</Button>
                              }
                              { user && (user.role === 'admin' || user.role === 'staff') &&
                                <Button className="d-print-none edit-delivery-info mx-2" variant="secondary" onClick={this.onClickEditDeliveryInfo}>Edit</Button>
                              }
                              </Card.Header>
                            <Card.Body className="label-strong d-grid gap-2 overflowX-auto">
                            {
                              item.remarks &&
                              <Row>
                                <label className="text-uppercase">Internal Remarks</label>
                                <span className="delivery-info-value" style={{color: '#d15d5d'}}><FontAwesomeIcon icon={solid('warning')}/> {item.remarks}</span>
                              </Row>
                            }
                            {
                              item.delivery_address &&
                              <>
                              <Row>
                                <label>Receiver</label>
                                <span className="delivery-info-value">{item.receiver}</span>
                              </Row>
                              <Row>
                                <div className="mt-2">
                                  <label>Delivery Address</label>
                                  <Button className="copy-to-clipboard ms-2 py-1" variant="secondary" data-clipboard-text={item.delivery_address}>COPY ADDRESS</Button>
                                </div>
                                <span className="delivery-info-value">
                                  <address>
                                    {item.delivery_address}
                                  </address>
                                </span>
                              </Row>
                              <Row>
                                <label>Contact</label>
                                <span>{item.contact}</span>
                              </Row>
                              </>
                            }
                            </Card.Body>
                          </Card>
                        </Col>
                      : null
                      }
                      {
                        user && user.role !== 'supplier' &&
                        <Col lg={4} className="d-print-none mt-3 mt-lg-0">
                          <Card className="greyCard label-strong">
                            <Card.Header className="greyCardHeader">
                              Billing Address
                              { user && (user.role === 'admin' || user.role === 'staff') &&
                                <Button className="d-print-none edit-delivery-info mx-2" variant="secondary" onClick={this.onClickEditBillingAddress}>Edit</Button>
                              }
                            </Card.Header>
                            <Card.Body>
                            <address>
                              <label>{item.first_name_billing} {item.last_name_billing}</label><br />
                              {item.address_billing}<br/>
                              {item.phone_number_billing}
                            </address>
                            </Card.Body>
                          </Card>
                        </Col>
                      }
                  </Row>
                </Card.Body>
              </Card>
              </Col>
            </Row>
            <Row className="overflowX-auto">
              <Col>
              <Card className="tealCard shadow-sm">
                <Card.Header className="tealCardHeader">Items</Card.Header>
                <Card.Body className="mx-2 my-1">
                {
                  item.fulfillment_status==='unfulfilled' && !item.cancelled_at &&
                  <>
                    <div className="mb-2">
                      <h4 className="d-inline-block mt-2" style={{color: '#FFC107'}}>UNFULFILLED</h4>
                      <ButtonToolbar className="pull-right d-print-none gap-3">
                        {
                          user && (user.role === 'admin' || user.role === 'staff') &&
                            <Button className="btn-br-6" variant="secondary" disabled={!selectedUnfulfilledItemIds.length} onClick={this.handleOnClickShowEditDeliveryModal}>Edit delivery date</Button>
                        }
                        <Button className="btn-br-6" variant="secondary" disabled={!selectedUnfulfilledItemIds.length} onClick={this.handleOnClickMarkAsReady}>Mark As Accepted</Button>
                        <Button className="btn-br-6" variant="primary" disabled={!selectedUnfulfilledItemIds.length || item.redemption_type === 'pre'} onClick={this.handleOnClickShowAddFulfillImentModal}>Mark selected items as Fulfilled</Button>
                      </ButtonToolbar>
                    </div>
                    <div className="clearfix"></div>
                    <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                      <ManualOrderLineItems user={user} items={[item]} style={{width: '100%', whiteSpace: 'nowrap'}} onCheckItem={ !item.cancelled_at && this.handleOnCheckUnfulfilledItem} cancelledAt={item.cancelled_at} reload={this.handleReload}/>
                    </div>
                  </>
                }
                {
                  item.fulfillment_status==='fulfilled' && !item.cancelled_at &&
                  <>
                    <Row className="mb-2">
                    <Col lg={3}>
                      <h4 style={{color: '#4e8767'}}>FULFILLED</h4>
                    </Col>
                    </Row>
                    <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                      <ManualOrderLineItems user={user} displaySection={"fulfilled"} fulfillmentStatus={item.fulfillment_status} items={[item]} style={{width: '100%', whiteSpace: 'nowrap'}} reload={this.handleReload}/>
                    </div>
                  </>
                }
                {
                  item.cancelled_at ?
                  <>
                    <Row className="mb-2">
                    <Col lg={3}>
                      <h4 style={{color: '#d15d5d'}}>CANCELLED</h4>
                    </Col>
                    </Row>
                    <div className="table-responsive label-strong mt-2 mt-md-0" style={{borderTop: '1px solid #dee2e6'}}>
                      <ManualOrderLineItems user={user} displaySection={"cancelled"} fulfillmentStatus={item.fulfillment_status} items={[item]} style={{width: '100%', whiteSpace: 'nowrap'}} reload={this.handleReload}/>
                    </div>
                  </>
                  :
                  null
                }
                </Card.Body>
              </Card>
              </Col>
            </Row>
            {
              item.fulfillment_status ==='fulfilled' &&
              <Row className="overflowX-auto">
                <Col>
                <Card className="d-print-none tealCard shadow-sm fulfillments">
                  <Card.Header className="tealCardHeader">Fulfillments</Card.Header>
                  <Card.Body className="mx-2 my-0 py-0">
                    <div className="my-4">
                      <div>
                        <h4 className="d-inline-block" style={{color: '#80d7cf'}}>{item.order_id}</h4>
                        <Badge className="text-uppercase mx-3" style={{fontSize: '1em'}} bg={item.fulfillment_status === "fulfilled" ? "success" : "danger"}>{item.fulfillment_status === "fulfilled" ? "FULFILLED" : item.fulfillment_status}</Badge>
                        <ButtonToolbar className="gap-3 my-2 my-lg-0">
                        {
                          item.fulfillment_status !== "cancelled" &&
                          <>
                            <Button className="btn-br-6" variant="secondary" onClick={() => this.handleOnClickShowEditFulfillmentModal()}>Edit Tracking</Button>
                            <Button className="btn-br-6" variant="danger" onClick={() => this.handleOnClickShowConfirmCancelFulfillmentModal()}>Cancel Fulfillment</Button>
                          </>
                        }
                        </ButtonToolbar>
                      </div>
                      <Row className="flex-column flex-lg-row grey-label label-strong">
                      {
                        item.fulfillment && item.fulfillment.tracking_number &&
                        <Col lg={6} className="d-grid mb-2 p-lg-0 order-1">
                          <label>Tracking Number</label>
                          <span>{item.fulfillment.tracking_number}</span>
                        </Col>
                      }
                      {
                        item.fulfillment && item.fulfillment.tracking_url &&
                        <Col lg={6} className="d-grid mb-2 p-lg-0 order-2 order-lg-3">
                          <label>Tracking Url</label>
                          <a href={item.fulfillment.tracking_url} target="_blank">{item.fulfillment.tracking_url}</a>
                        </Col>
                      }
                      {
                        item.fulfillment && item.fulfillment.created &&
                        <Col lg={6} className="d-grid mb-2 p-lg-0 order-3 order-lg-2">
                          <label>Fulfilled At</label>
                          <span>{moment(item.fulfillment.created).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                        </Col>
                      }
                      {
                        item.fulfillment && item.fulfillment.created_original &&
                        <Col lg={6} className="d-grid mb-2 p-lg-0 order-4">
                          <label>Fulfilled At (Original)</label>
                          <span>{moment(item.fulfillment.created_original).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                        </Col>
                      }
                      {
                        item.updated &&
                        <Col lg={6} className="d-grid mb-2 p-lg-0 order-5">
                          <label>Last Updated At</label>
                          <span>{moment(item.updated).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
                        </Col>
                      }
                      {
                        item.fulfillment && item.fulfillment.pod &&
                        <Col lg={6} className="d-grid mb-2 p-lg-0 order-6">
                          <label>Proof of Delivery or Image</label>
                          <a href={item.fulfillment.pod} target="_blank">{item.fulfillment.pod}</a>
                        </Col>
                      }
                      </Row>
                      <Row className="grey-label label-strong">
                      <Col className="d-grid">
                        <label>Item Details</label>
                        {item.item_details}
                      </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
                </Col>
              </Row>
            }
            </div>
          </>
        }
        {
          showRemarkModal &&
          <RemarkModal item={item} remark={item.remarks || ""} onClose={this.handleOnCloseRemarkModal} onClickSave={this.handleOnSaveRemark}/>
        }
        {
          isShowEditFulfillmentModal &&
          <TrackingInfoModal onClose={this.handleOnCloseEditFulfillmentModal} onClickFulfill={this.handleOnClickFulfill} okLabel="Save" />
        }
        {
          isShowConfirmCancelFulfillmentModal &&
          <ConfirmModal onClickYes={this.handleOnClickCancelFulfillment} onClose={this.handleOnCloseConfirmCancelFulfillmentModal} title="Cancel Fulfillment">
            <p>Are you sure you want to cancel this fulfillment?</p>
          </ConfirmModal>
        }
        {
          isShowAddFulfillmentModal &&
          <TrackingInfoModal onClose={this.handleOnCloseAddFulfillmentModal} onClickFulfill={this.handleOnClickFulfill} />
        }
        {
          showFinancialStatusModal &&
          <FinancialStatusModal onClose={this.handleOnCloseFinancialStatusModal} item={item} onClickSave={this.handleOnSaveFinancialStatus} />
        }
        {
          showCancelManualOrderModal &&
          <ConfirmModal onClose={this.handleOnCloseCancelManualOrderModal} onClickYes={this.handleOnClickCancelManualOrder} title="Cancel Manual Order">
            <p>Are you sure you want to cancel this manual order?</p>
          </ConfirmModal>
        }
        {
          showEditManualOrderModal &&
          <CreateManualOrderModal onClose={this.handleOnCloseEditManualOrderModal} item={item} mode="edit" handleSaveManualOrder={this.handleOnSaveManualOrder}/>
        }
        { showEditDeliveryInfoModal &&
          <EditDeliveryInfoModal
            orderType="manual"
            onClose={this.handleOnCloseEditDeliveryInfo}
            receiver={item.receiver}
            deliveryAddress={item.delivery_address}
            contact={item.contact}
            onSaved={this.handleSavedDeliveryInfo}
            orderId={item._id}
          />
        }
        { showEditBillingAddressModal &&
          <EditBillingAddressModal
            onClose={this.handleOnCloseEditBillingAddress}
            firstName={item.first_name_billing}
            lastName={item.last_name_billing}
            address={item.address_billing}
            phoneNumber={item.phone_number_billing}
            onSaved={this.handleSavedBillingAddress}
            orderId={item._id}
          />
        }
        {
          showEditDeliveryModal &&
          <EditDeliveryModal onClose={this.handleOnCloseEditDeliveryModal} onClickEdit={this.handleDeliveryDateChange} />
        }
      </>
    );
  }
}

export default connect((state, props) => {
  return {
    orderDetail: state.manualOrderDetail,
    error: state.error,
    user: state.auth.user
  };
}, {
  ...manualOrderDetailActionCreators,
  ...errorActionCreators
})(ManualOrderDetail);
