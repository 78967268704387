import qs from "qs";
import { addError } from './error';
import api from '../../helpers/apiClient';
import _ from 'lodash'

export const FETCH_PRODUCT_ADDONS_REQUEST = 'FETCH_PRODUCT_ADDONS_REQUEST';
export const FETCH_PRODUCT_ADDONS_SUCCESS = 'FETCH_PRODUCT_ADDONS_SUCCESS';
export const FETCH_PRODUCT_ADDONS_FAILURE = 'FETCH_PRODUCT_ADDONS_FAILURE';

function fetchProductAddOnsRequest(merchantId, limit) {
  return {
    type: FETCH_PRODUCT_ADDONS_REQUEST,
    payload: {
      merchantId,
      limit,
    }
  };
}

function fetchProductAddOnsSuccess(json) {
  return {
    type: FETCH_PRODUCT_ADDONS_SUCCESS,
    payload: {
      items: json,
      // next: json.next,
      receivedAt: Date.now(),
    }
  };
}

function fetchProductAddOnsFailure() {
  return {
    type: FETCH_PRODUCT_ADDONS_FAILURE
  };
}

function shouldFetchProductAddOns(state) {
  const results = state.products;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

function fetchProductAddOnsFromApi(merchantId, status, inventory, limit) {
  const queryString = qs.stringify({
    merchantId,
    status,
    inventory,
    limit,
  }, { skipNulls: true });
  return dispatch => {
    dispatch(fetchProductAddOnsRequest(merchantId, limit));
    return api(`/product_addons?${queryString}`)
      .then(json => {
        dispatch(fetchProductAddOnsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchProductAddOnsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchProductAddOns(merchantId, status, inventory, limit) {
  return (dispatch, getState) => {
    if (shouldFetchProductAddOns(getState())) {
      return dispatch(fetchProductAddOnsFromApi(merchantId, status, inventory, limit));
    }
  }
}

export function setProductAddOns(addons) {
  return (dispatch, getState) => {
    return dispatch(fetchProductAddOnsSuccess(addons));
  }
}
