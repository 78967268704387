import {
  FETCH_PRODUCT_ADDONS_REQUEST,
  FETCH_PRODUCT_ADDONS_SUCCESS,
  FETCH_PRODUCT_ADDONS_FAILURE,
} from '../actions/productAddOns';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
  merchantId: null,
  limit: null,
  next: null
};

export default function productAddOns(state = defaultState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_ADDONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PRODUCT_ADDONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
        // next: action.payload.next
      };
    case FETCH_PRODUCT_ADDONS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
