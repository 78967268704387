import { 
  FETCH_ANNOUNCEMENTS_REQUEST, 
  FETCH_ANNOUNCEMENTS_SUCCESS, 
  FETCH_ANNOUNCEMENTS_FAILURE
} from '../actions/announcement';

const defaultState = {
  loading: false,
  loaded: false,
  detail: [],
  next: null
};

export default function announcement(state = defaultState, action) {
  switch (action.type) {
    case FETCH_ANNOUNCEMENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        detail: action.payload.item,
      };
    case FETCH_ANNOUNCEMENTS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}