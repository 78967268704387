import React, { Component } from 'react';
import { connect } from 'react-redux';
import GTRAlert from '../components/GTRAlert';
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import _ from 'lodash'
import * as errorActionCreators from '../redux/actions/error';
import qs from 'qs';

class PickupHandover extends Component {
  constructor(props) {
    super(props);

    const { shipment_ids, pickup_date, pickup_id } = qs.parse(location.search.substr(1));

    this.state = {
      links: null,
      loading: false,
      shipment_ids,
      pickup_date,
      pickup_id,
    };
  }

  componentDidMount() {
    this.fetchHandoverSheet()
  }

  fetchHandoverSheet = () => {

    const { shipment_ids, pickup_date, pickup_id } = this.state;

    this.setState({ loading: true });

    return api(`/pickup/handover?shipment_ids=${shipment_ids || ''}&pickup_date=${pickup_date || ''}&pickup_id=${pickup_id || ''}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      this.setState({ loading: false });

      if(result.error) {
        this.setState({ error: result.error });
        return;
      }
      if(result){
        this.setState({ links: result });
      }
    }).catch(err => {
      this.setState({ loading: false });
      if (err) {
        window.scrollTo(0, 0);
        if (err.validationError) {
          this.setState({ error: err.validationError });
        }
        else if (err.message) {
          this.setState({ error: err.message });
        }
        else {
          this.setState({ error: err });
        }
      }
    });
  }

  render() {
    const { links, loading, error } = this.state;

    return (
      <div className="container">
        <h1>Handover Sheet:</h1>
        {
          loading &&
          <Loader />
        }
        {
          error &&
          <GTRAlert variant="danger" alert={error} />
        }
        {
          !loading && links && links.sheets &&
          <ul>
              {
                links.sheets.map((link, i) => {
                  return (
                    <li key={i}>
                      <a target="_blank" href={link}>{link}</a>
                    </li>
                  )
                })
              }
          </ul>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    error: state.error,
    user: state.auth.user
  };
}, {
  ...errorActionCreators
})(PickupHandover);
