import {
  FETCH_PRODUCT_BULK_REQUEST,
  FETCH_PRODUCT_BULK_SUCCESS,
  FETCH_PRODUCT_BULK_FAILURE,
  FETCH_PRODUCT_BULK_UPDATE
} from '../actions/productBulkEdit';

const defaultState = {
  loading: false,
  loaded: false,
  items: []
};

export default function productBulkEdit(state = defaultState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_BULK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PRODUCT_BULK_UPDATE:
      return {
        ...state,
        items: [...action.payload.items]
      };
    case FETCH_PRODUCT_BULK_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
        // next: action.payload.next
      };
    case FETCH_PRODUCT_BULK_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
