import { useEffect, useState, useMemo } from 'react';
import { Row, Col, Button, Modal, Card, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ReactTooltip from 'react-tooltip';
import conditionalPreview from '../images/conditional-preview.gif'
import { renderToStaticMarkup } from 'react-dom/server'
import _ from 'lodash'
import FieldsModal from './FieldsModal';
import Select from 'react-select';
import FieldCreate from './FieldCreate';

const EditOptionModal = ({ onHide, handleEditOption, initialOption = {}, mode, deleteOption, options, isAdmin, self = false, variants, customizeFields }) => {
  const [name, setName] = useState(initialOption.name)
  const [values, setValues] = useState([{ new: '', shipping_not_required: false }]);
  const [nameError, setNameError] = useState(false)
  const [valueErrors, setValueErrors] = useState('')
  const [permutationError, setPermutationError] = useState(false)
  const [conditionalFields, setConditionalFields] = useState([])
  const [showAddConditionalField, setShowAddConditionalField] = useState(false)

  const conditionalOptions = useMemo(() => {
    return _.map(_.filter(values, val => val.new && val.new !== ''), (option) => {
      return {
        value: option.new,
        label: option.new
      }
    })
  }, [values])

  useEffect(() => {
    if (variants && initialOption && initialOption.values) {
      setValues(_.map(initialOption.values, (value) => {
        let foundVariant = _.find(variants, variant => variant[`option${initialOption.position}`] === value)
        
        return {
          old: value,
          new: value,
          shipping_not_required: foundVariant ? !foundVariant.requires_shipping : false,
        }
      }))

      if (initialOption.conditionalFields) {
        setConditionalFields(initialOption.conditionalFields)
      }
    }
  }, [])

  useEffect(() => {
    setValueErrors('')
    let acc = []
    for (let value of values) {
      if (value && acc.includes(value.new)) {
        setValueErrors(`Option value "${value.new}" already in use.`)
        break;
      }
      acc.push(value.new)
    }

    let permutations = values.length
    _.each(options, (option) => {
      if (option.position === (initialOption.position || -1)) {
        return
      }
      permutations = permutations * option.values.length
    })
    setPermutationError(permutations > 100)
  }, [values])

  useEffect(() => {
    setNameError(false)
    _.each(options, (option) => {
      if (option.position === (initialOption.position || -1)) {
        return
      }
      if (option.name === name) {
        setNameError(true)
      }
    })
  }, [name])

  const removeOptionValue = (index) => {
    let updateValues = [...values]
    updateValues.splice(index, 1)
    if (updateValues.length === 0) {
      updateValues = [{ new: '', shipping_not_required: false }]
    }
    setValues(updateValues)

    let updateConditionalFields = [...conditionalFields]
    _.each(updateConditionalFields, field => {
      field.valueOptions = _.map(_.filter(updateValues, val => val.new && val.new !== ''), (option) => {
        return {
          value: option.new,
          label: option.new
        }
      })
      field.selected = _.filter(field.selected, selectedOption => {
        return _.some(updateValues, val => val.new === selectedOption.value)
      })
    })

    setConditionalFields(updateConditionalFields)
  }

  const addOptionValue = () => {
    setValues([...values, { new: '', shipping_not_required: false }])
  }

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.target.reportValidity()) {
      return
    }

    handleEditOption(name, values, conditionalFields)
  }

  const onChangeValue = (index, e) => {

    if (_.includes(e.target.name, 'option_value')) {
      let updateValues = [...values]
      updateValues[index] = {
        old: updateValues[index].old,
        new: e.target.value,
        shipping_not_required: updateValues[index].shipping_not_required
      }
      setValues(updateValues)

      let updateConditionalFields = [...conditionalFields]
      _.each(updateConditionalFields, field => {
        field.valueOptions = _.map(_.filter(updateValues, val => val.new && val.new !== ''), (option) => {
          return {
            value: option.new,
            label: option.new
          }
        })
      })

      setConditionalFields(updateConditionalFields)
    }

    if (_.includes(e.target.name, 'option_shipping_not_required')) {
      let updateValues = [...values]
      updateValues[index].shipping_not_required = e.target.checked
      setValues(updateValues)
    }
  }

  const handleAddConditional = (conditionalField) => {
    setConditionalFields([
      ...conditionalFields,
      conditionalField,
    ])

    setShowAddConditionalField(false)
  }

  const handleRemoveConditional = (index) => {
    let updateConditionalFields = [...conditionalFields]
    updateConditionalFields.splice(index, 1)
    setConditionalFields(updateConditionalFields)
  }

  const handleOnChangeConditionalSelected = (selected, conditionalIndex) => {
    let updateConditionalFields = [...conditionalFields]
    updateConditionalFields[conditionalIndex].selected = selected
    setConditionalFields(updateConditionalFields)
  }

  const handleOnChangeConditionalField = (e, conditionalIndex) => {
    let updateConditionalFields = [...conditionalFields]
    const { name, value, checked } = e.target;

    switch(name) {
      case 'primaryTitle':
      case 'maxChar':
      case 'secondTitle':
      case 'options':
      case 'limit':
        updateConditionalFields = [
          ...updateConditionalFields.slice(0, conditionalIndex),
          {
            ...updateConditionalFields[conditionalIndex],
            [name]: value
          },
          ...updateConditionalFields.slice(conditionalIndex + 1)
        ]
        break;

      case 'required':
        updateConditionalFields = [
          ...updateConditionalFields.slice(0, conditionalIndex),
          {
            ...updateConditionalFields[conditionalIndex],
            [name]: checked
          },
          ...updateConditionalFields.slice(conditionalIndex + 1)
        ]
        break;
      default:
        break;
    }

    setConditionalFields(updateConditionalFields)
  }

  const handleAddOptionConditional = (conditionalIndex) => {
    let updateConditionalFields = [...conditionalFields]
    updateConditionalFields[conditionalIndex].options.push({value: ""})
    setConditionalFields(updateConditionalFields)
  }

  const handleEditOptionsConditional = (e, conditionalIndex, optionIndex, tagType) => {
    let { name, value } = e.target

    let updateConditionalFields = [...conditionalFields]
    let split = updateConditionalFields[conditionalIndex].options[optionIndex].value.split('#')

    if (tagType === 'ddaddon' || tagType === 'cbaddon') {
      if (name.includes('name')) {
        updateConditionalFields[conditionalIndex].options[optionIndex].value = value + '#' + (split[1] || '')
      } else if (name.includes('price')) {
        updateConditionalFields[conditionalIndex].options[optionIndex].value = (split[0] || '') + '#' + value
      }
    } else {
      updateConditionalFields[conditionalIndex].options[optionIndex].value = value
    }

    setConditionalFields(updateConditionalFields)
  }

  const handleRemoveOptionConditional = (optionIndex, conditionalIndex) => {
    let updateConditionalFields = [...conditionalFields]

    updateConditionalFields[conditionalIndex].options.splice(optionIndex, 1)

    if (updateConditionalFields[conditionalIndex].options.length === 0) {
      updateConditionalFields[conditionalIndex].options = [{value: ""}]
    }

    setConditionalFields(updateConditionalFields)
  }

  return (
    <>
      <Modal show={true} onHide={onHide} size="lg" style={{zIndex: '10000'}}>
        <Modal.Header>
          <h4 className="mb-0">{mode === 'add' ? 'Add New Option' : 'Edit Option'}</h4>
        </Modal.Header>
        <form onSubmit={submit}>
          <Modal.Body>
            <Row className="d-grid gap-3">
              <Col className="d-grid gap-1">
                <label>Option Name *</label>
                <input className="form-control" type="text" required value={name || ''} onChange={(e) => setName(e.target.value)}/>
              </Col>
            </Row>
            {nameError && <div className="text-danger mb-2">Please use a unique option name.</div>}
            <Row className='table-responsive mx-1'>
              <table className="table" style={{ fontSize: '0.9em' }}>
                <thead>
                  <tr>
                    <th width="70">Option Values *</th>
                    <th width="20">Remove</th>
                    { self &&
                      <th width="20">Shipping Not Required</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  { values.map((value, j) => {
                    return (
                      <tr key={j}>
                        <td>
                          <input autoFocus className="form-control" type="text" value={value.new || ''} required onChange={(e) => onChangeValue(j, e)} name={`option_value_${j}`} />
                        </td>
                        <td style={{verticalAlign: 'middle'}}>
                          <Button className="px-2 py-0" variant="danger" onClick={() => removeOptionValue(j)}><FontAwesomeIcon icon={solid('close')} /></Button>
                        </td>
                        { self &&
                          <td>
                            <label className='custom-checkbox' style={{ marginTop: '2px', marginLeft: '0.1em' }}>
                              <input type="checkbox" name={`option_shipping_not_required_${j}`} onChange={(e) => onChangeValue(j, e)} checked={value.shipping_not_required || ''} />
                              <span className="checkmark"></span>
                            </label>
                          </td>
                        }
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {valueErrors && <div className="text-danger mb-2">{valueErrors}</div>}
              {permutationError && <div className="text-danger mb-2">The number of variants created from these options exceeds 100. Please reduce the number of options.</div>}
              <div>
                <Button className="shadow-sm" variant="primary" onClick={addOptionValue}><FontAwesomeIcon icon={solid('plus')} className="mx-1"/>&nbsp;Add Value</Button>
              </div>
            </Row>
            { _.map(conditionalFields, (conditionalField, j) => {
              let titles = []
              _.each(customizeFields, (field) => {
                if (field.conditionalFields) {
                  _.each(field.conditionalFields, (cond, index) => {
                    titles.push(cond.primaryTitle)
                  })
                }
                titles.push(field.primaryTitle)
              })
              _.each(conditionalFields, (field, index) => {
                if (index === j) {
                  return
                }
                if (field.conditionalFields) {
                  _.each(field.conditionalFields, (cond) => {
                    titles.push(cond.primaryTitle)
                  })
                }
              })

              return (
              <Card key={j} className="greyCard mt-3 mx-2 border border-2">
                <Card.Header className="greyCardHeader">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Badge bg="secondary" className="me-2">CONDITIONAL</Badge>
                      {conditionalField.title}
                    </div>
                    <Button className="mt-auto px-2 py-0" onClick={() => handleRemoveConditional(j)} variant="danger" size="small">
                      <FontAwesomeIcon icon={solid('close')}/>
                    </Button>
                  </div>
                  <hr className="my-2"/>
                  <Row>
                    <Col xs={12} sm={3} className="d-flex align-items-center">
                      <label>When Option(s) Is:</label>
                    </Col>
                    <Col xs={12} sm={9}>
                      <Select
                        value={conditionalField.selected || []}
                        isMulti
                        onChange={(selected) => handleOnChangeConditionalSelected(selected, j)}
                        required={true}
                        options={conditionalField.valueOptions.map(option => {
                          let value = option.value.split('#')[0]
                          return {label: value, value: value}
                        })}
                      />
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body className="d-grid gap-2 label-strong grey-label">
                  <FieldCreate
                    tagType={conditionalField.tagType}
                    index={j}
                    values={conditionalField}
                    onChange={(e) => handleOnChangeConditionalField(e, j)}
                    handleAddOption={() => handleAddOptionConditional(j)}
                    handleEditOptions={(e, optionIndex) => handleEditOptionsConditional(e, j, optionIndex, conditionalField.tagType)}
                    handleRemoveOption={(optionIndex) => handleRemoveOptionConditional(optionIndex, j)}
                    isAdmin={isAdmin}
                    showAddConditionalField={false}
                    titles={titles}
                  />
                </Card.Body>
              </Card>
              )
            })}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <div className="d-flex gap-2">
            { mode === 'edit' &&
            <Button variant="danger" onClick={() => deleteOption()}>
              Delete
            </Button>
            }
            { isAdmin &&
              <Button variant="primary" onClick={() => setShowAddConditionalField(true)}>
                <FontAwesomeIcon icon={solid('plus')} className="mx-1"/>
                &nbsp;Add Conditional Field
                <i className="ms-1" data-html={true} data-tip={renderToStaticMarkup(
                  <div className="d-grid gap-2">
                    <img style={{maxWidth: '500px'}} src={conditionalPreview} />
                    <p className="m-0">Add fields that are displayed based on other fields.<br/>Select condition(s) to display under "When Option Is:"</p>
                  </div>
                )} data-for="conditional-fields"><FontAwesomeIcon icon={solid('info-circle')} /></i><ReactTooltip id="conditional-fields" />
              </Button>
            }
            </div>
            <div className="d-flex gap-2">
              <Button variant="secondary" onClick={onHide}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={valueErrors || permutationError || nameError}>
                {mode === 'add' ? 'Add' : 'Confirm'}
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      { showAddConditionalField &&
      <FieldsModal onSelectField={() => {}} onClose={() => setShowAddConditionalField(false)} conditional={true} mainAddConditional={(field) => handleAddConditional(field)} isAdmin={isAdmin} conditionalOptions={conditionalOptions} style={{ zIndex: '10001' }}/>
      }
    </>
  )
}

export default EditOptionModal
