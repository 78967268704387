import React, { Component } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import trackingLinkOptions from '../data/tracking_link.json';
import trackingLinkSGOptions from '../data/tracking_link_sg.json';
import trackingLinkD2DOptions from '../data/tracking_link_d2d.json';
import trackingLinkD2DSGOptions from '../data/tracking_link_d2d_sg.json';

class TrackingInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingNumber: "",
      trackingUrl: "",
      trackingUrlPlaceholder: "",
      trackingCompany: "",
      isLoading: false,
      file: undefined,
      error: undefined,
      deliveryType: "",
      selfDelivered: false,
    }
  }

  handleOnInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleOnSelectChangeOnDemand = (e) => {
    const { value, selectedOptions } = e.target;

    let state = {}

    if (value === 'other') {
      state.selfDelivered = true
      state.trackingUrl = ""
      state.trackingCompany = ""
    } else {
      state.selfDelivered = false
      state.trackingUrlPlaceholder = value
      state.trackingCompany = selectedOptions[0].innerText
    }

    this.setState(state);
  }

  handleOnSelectChange = (e) => {
    const { value, selectedOptions } = e.target;

    let state = {}

    state.selfDelivered = false
    state.trackingUrl = value
    state.trackingCompany = selectedOptions[0].innerText

    this.setState(state);
  }

  handleOnClickFulfill = (e) => {
    e.preventDefault();
    const { onClickFulfill } = this.props;
    let { trackingCompany, trackingNumber, trackingUrl, file, deliveryType, selfDelivered } = this.state;
    this.setState({ isLoading: true });

    trackingUrl = (deliveryType === 'd2d' && selfDelivered) ? "" : trackingUrl

    onClickFulfill(deliveryType, trackingCompany, trackingNumber, trackingUrl, file);
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    const { isLoading } = this.state;
    if (!isLoading) {
      onClose();
    }
  }

  addFile = e => {

    const {files} = e.target

    if(files && files.length > 0 && !files[0].type){
      this.setState({ error: 'Invalid file extension' })
      return
    }
    this.setState({
      file: files && files.length > 0 ? files[0] : undefined
    })
  }

  render() {
    const { okLabel, isMobile } = this.props;
    const { trackingNumber, trackingUrl, isLoading, file, error, deliveryType, selfDelivered, trackingUrlPlaceholder } = this.state;
    return (
      <Modal dialogClassName={`${isMobile ? "" : "tracking-info-modal-dialog"}`} style={{border: 'none'}} show={true} onHide={this.handleOnClose}>
        <form onSubmit={this.handleOnClickFulfill}>
          <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
            <h4 className="mb-0">Tracking Information (Optional)</h4>
          </Modal.Header>
          <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
            {
              error &&
                <Alert variant="danger">
                  {
                    Array.isArray(error) ?
                      <ul>
                        {
                          error.map((err, i) => (
                            <li key={i}>
                              {err.msg}
                            </li>
                          ))
                        }
                      </ul>
                      :
                      error
                  }
                </Alert>
            }
            <div className="d-grid gap-3">
              <div className="d-grid gap-1">
                <label htmlFor="courierService">Select Delivery Type:</label>
                <div className="d-grid gap-1" style={{gridTemplateColumns: '1fr 1fr'}}>
                  <Button variant="light" className={`${deliveryType === 'courier' ? 'shipping-active': ''} w-100 position-relative`} style={{border: '1px solid #dfdfdf'}} onClick={() => {
                    this.setState({
                      deliveryType: 'courier',
                      trackingNumber: "",
                      trackingUrl: "",
                      trackingCompany: "",
                    })
                  }}>
                    <span className="me-2 d-inline-block">Courier</span>
                  </Button>
                  <Button variant="light" className={`${deliveryType === 'd2d' ? 'shipping-active': ''} w-100 position-relative`} style={{border: '1px solid #dfdfdf'}} onClick={() => {
                    this.setState({
                      deliveryType: 'd2d',
                      trackingNumber: "",
                      trackingUrl: "",
                      trackingCompany: "",
                    })
                  }}>
                    <span className="me-2 d-inline-block">On-Demand</span>
                  </Button>
                </div>

              </div>
              {
                deliveryType === 'courier' &&
                  <div className="d-grid gap-1">
                    <label htmlFor="courierService">Select Courier Service Used:</label>
                    <Form.Select id="courierService" name="courierService" className="form-control" onChange={this.handleOnSelectChange}>
                      <option disabled={trackingUrl !== "" ? true : null} defaultValue value="">Select one</option>
                      <optgroup label="Malaysia">
                        {
                          trackingLinkOptions.map(option => (
                            <option key={option.key} value={option.value}>{option.name}</option>
                          ))
                        }
                      </optgroup>
                      <optgroup label="Singapore">
                        {
                          trackingLinkSGOptions.map(option => (
                            <option key={option.key} value={option.value}>{option.name}</option>
                          ))
                        }
                      </optgroup>
                      <optgroup label="Others">
                        <option value="">Others</option>
                      </optgroup>
                    </Form.Select>
                  </div>
              }
              {
                deliveryType === 'd2d' &&
                  <div className="d-grid gap-1">
                    <label htmlFor="courierService">Select On-Demand Delivery Service Used:</label>
                    <Form.Select id="courierService" name="courierService" className="form-control" onChange={this.handleOnSelectChangeOnDemand}>
                      <option disabled={trackingUrl !== "" ? true : null} defaultValue value="">Select one</option>
                      <optgroup label="Malaysia">
                        {
                          trackingLinkD2DOptions.map(option => (
                            <option key={option.key} value={option.value}>{option.name}</option>
                          ))
                        }
                      </optgroup>
                      <optgroup label="Singapore">
                        {
                          trackingLinkD2DSGOptions.map(option => (
                            <option key={option.key} value={option.value}>{option.name}</option>
                          ))
                        }
                      </optgroup>
                      <optgroup label="Others">
                        <option value="other">Others</option>
                      </optgroup>
                    </Form.Select>
                  </div>
              }
              {
                deliveryType === 'courier' &&
                  <div className="d-grid gap-1">
                    <label htmlFor="trackingNumber">Tracking Number</label>
                    <input type="text" className="form-control" id="trackingNumber" name="trackingNumber" placeholder="Tracking Number" value={trackingNumber} onChange={this.handleOnInputChange} />
                  </div>
              }
              {
                deliveryType === 'courier' &&
                  <div className="d-grid gap-1">
                    <label htmlFor="trackingUrl">Tracking Url</label>
                    <input onFocus={e => e.target.select()} type="text" className="form-control" id="trackingUrl" name="trackingUrl" placeholder="Tracking Url" value={trackingUrl} onChange={this.handleOnInputChange} />
                  </div>
              }
              {
                (deliveryType === 'd2d' && !selfDelivered) &&
                  <div className="d-grid gap-1">
                    <label htmlFor="trackingUrl">Tracking Url</label>
                    <input onFocus={e => e.target.select()} type="text" className="form-control" id="trackingUrl" name="trackingUrl" placeholder={trackingUrlPlaceholder || "Tracking Url"} value={trackingUrl} onChange={this.handleOnInputChange} />
                  </div>
              }
              {
                (deliveryType === 'courier' || deliveryType === 'd2d' && selfDelivered) &&
                  <div className="d-grid gap-1">
                    <label htmlFor="proof">Proof of Delivery or Image</label>
                    <div>
                      <label className="custom-file-upload me-2" style={{width: '35%'}}>
                        <FontAwesomeIcon icon={solid('folder-open')} className="me-2"/>Choose File
                        <input type="file" name="proof" onChange={this.addFile}/>
                      </label>
                      {
                        file &&
                          <span>{file.name}</span>
                      }
                    </div>
                  </div>
              }
            </div>
          </Modal.Body>
          <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
            <Button variant="danger-secondary" onClick={this.handleOnClose} disabled={isLoading}>Cancel</Button>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? "Loading..." : okLabel || "Save"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export default TrackingInfoModal;
