import React from 'react';
import { Loader as RLoader } from 'react-loaders';

const OverlayLoader = () => {
  return (
    <div className="overlay-loader">
      <RLoader active type="line-scale" color="#2BCCB1" />
    </div>
  );
}

export default OverlayLoader;
