import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash'
import moment from 'moment';
import { Form, Button, Col, Container, Accordion } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loader from '../components/Loader';
import * as logsActionCreators from '../redux/actions/logs';
import ReactTooltip from 'react-tooltip';
import { DateRangePicker } from "react-dates";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import api from '../helpers/apiClient';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

class Logs extends Component {
  constructor(props) {
    super(props);
    const { location } = props;

    let logPeriodStart = moment().subtract(1, 'months')
    let logPeriodEnd = moment()

    if (location.search) {
      const queryString = qs.parse(location.search.substr(1));

      if (queryString.log_period_start) {
        logPeriodStart = moment(queryString.log_period_start, "D-M-YY");
      }
      if (queryString.log_period_end) {
        logPeriodEnd = moment(queryString.log_period_end, "D-M-YY");
      }
    }

    this.state = {
      logPeriodStart,
      logPeriodEnd,
      items: [],
      loading: false,
      type: '',
    };
  }

  fetchLogs = () => {
    this.setState({ loading: true })

    const { logPeriodStart, logPeriodEnd, type } = this.state;

    let logPeriodStartShortFormat, logPeriodEndShortFormat
    if (logPeriodStart) {
      logPeriodStartShortFormat = logPeriodStart.format("D-M-YY");
    }

    if(logPeriodEnd){
      logPeriodEndShortFormat = logPeriodEnd.format("D-M-YY");
    }

    const queryString = qs.stringify({
      log_period_start: logPeriodStartShortFormat,
      log_period_end: logPeriodEndShortFormat
    }, { skipNulls: true });

    let url = type === 'off_days' ? '/off_days_logs' : '/logs'

    return api(`${url}?${queryString}`).then(logs => {
      this.setState({ items: logs, loading: false })
    })
  }

  handleResetFilter = () => {
    this.setState({
      logPeriodStart: moment().subtract(2, 'months'),
      logPeriodEnd: moment()
    });
  }

  handleOnClickFilter = () => {
    this.fetchLogs();
    return
    const {
      history,
      location: { pathname },
    } = this.props;
    const {
      logPeriodStart,
      logPeriodEnd,
    } = this.state;

    let logPeriodStartShortFormat, logPeriodEndShortFormat
    if (logPeriodStart) {
      logPeriodStartShortFormat = logPeriodStart.format("D-M-YY");
    }

    if(logPeriodEnd){
      logPeriodEndShortFormat = logPeriodEnd.format("D-M-YY");
    }

    const queryString = qs.stringify({
      log_period_start: logPeriodStartShortFormat,
      log_period_end: logPeriodEndShortFormat
    });
    history.push(`${pathname}?${queryString}`);
  }

  renderExpandedNoteColumn = (row) => {
    return <div>{row.note}</div>
  }

  showVariantTitle = (cell, row) => {
    return (
      <div>
        <span data-tip={cell}>{cell}</span><ReactTooltip place="bottom"/>
      </div>
    )
  }

  showProductId = (cell, row) => {
    if (cell && this.isNumeric(cell)) {
      return <a target="_blank" href={`/products/${cell}`}>{cell}</a>
    } else {
      return <a target="_blank" href={`/products/pending/${cell}`}>{cell}</a>
    }
  }

  isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  }

  renderTime = (cell, row) => {
    if(cell){
      return `${moment.duration(cell, 'seconds').asDays().toFixed(1)} days`
    }
  }

  renderCSVTime = (cell, row) => {
    return cell ? moment.duration(cell, 'seconds').asDays().toFixed(1) : ''
  }

  renderType = (cell, row) => {
    switch (cell){
      case 'edit_product_info':
        return 'Edit Info'
      case 'update_inventory':
        return 'Edit Inventory'
      case 'edit_disapproved_product':
        return 'Edit Rejected'
      case 'edit_moreinfo_product':
        return 'Edit More Info'
      case 'create_product':
        return 'Create'
      case 'approve_product':
        return 'Approve'
      case 'duplicate_product':
        return 'Duplicate'
      case 'disapprove_product':
        return 'Reject'
      case 'review_product':
        return 'Review'
      case 'more_info_product':
        return 'More Info'
      default:
        return '';
    }
  }

  customCSVBtn = (onClick) => {
    return (
      <ExportCSVButton
        className="btn-info"
        style={{borderRadius:'8px'}}
        onClick={ () => onClick()}
      ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
        Export to CSV</ExportCSVButton>
    )
  }

  customSearchField = () => {
    return (
      <SearchField
        style={{borderRadius:'25px'}} placeholder="Search"/>
    )
  }

  dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = moment("01/01/2010", "DD/MM/YYYY");
    }

    if (!date2) {
      date2 = moment("01/01/2010", "DD/MM/YYYY");
    }

    if (order === "desc") {
      return (
        moment(date2).unix() - moment(date1).unix()
      );
    } else {
      return (
        moment(date1).unix() - moment(date2).unix()
      );
    }
  };

  renderOrderId = (cell, row) => {
    if (cell && row.order_name) {
      return <a href={`/orders/${cell}`}>{row.order_name}</a>
    }

    return cell
  }

  renderMessage = (cell, row) => {
    let message
    if (cell || row.contact_message) {
      try {
        let url = new URL(cell || row.contact_message);
        message = url.searchParams.get('text');
      } catch (_) {
      }
    }

    return (
      <div>
        <span data-html={true} data-tip={`<div style="max-width: 500px"><span class="white-space-pre-line">${message || row.contact_message}</span></div>`}>{message || row.contact_message}</span><ReactTooltip place="bottom"/>
      </div>
    )
  }

  render() {
    const { user } = this.props;
    const { items, loading, type } = this.state;

    const options = {
      showTotal: true,
      sizePerPage: 20,
    };

    const columns = [
      {
        dataField: 'id',
        hidden: true,
        text: 'Id',
      },
      {
        dataField: 'created',
        sort: true,
        formatter: (cell, row) => {
          return moment(cell).format('YYYY-MM-DD h:mm a')
        },
        text: 'Date',
        headerStyle: {
          width: '100px',
          resize:'horizontal'
        },
        sortFunc: this.dateSort
      },
      {
        dataField: 'email',
        text: 'Email',
        headerStyle: {
          width: '100px',
          resize:'horizontal'
        }
      },
      {
        dataField: 'role',
        text: 'Role',
        headerStyle: {
          width: '100px',
          resize:'horizontal'
        }
      },
      {
        dataField: 'type',
        text: 'Type',
        headerStyle: {
          width: '100px',
          resize:'horizontal'
        }
      },
    ];

    if (type === 'off_days') {
      columns.push(
        {
          dataField: 'off_days',
          text: 'Off Day',
          headerStyle: {
            width: '200px',
            resize:'horizontal'
          },
          formatter: (cell) => {
            let entries = Object.entries(cell)
            return (
              <>
                {entries.map((entry) => {
                  return (
                    <div>{entry[0]}: {entry[1]}</div>
                  )
                })}
              </>
            )
          }
        }
      )
    } else {
      columns.push(
        {
          dataField: 'product_title',
          formatter: this.showVariantTitle,
          text: 'Product Variant',
          headerStyle: {
            width: '400px',
            resize: 'horizontal'
          },
        },
        {
          dataField: 'product_id',
          text: 'Product ID',
          formatter: this.showProductId,
          headerStyle: {
            width: '200px',
            resize: 'horizontal'
          },
        },
        {
          dataField: 'inventory_item_id',
          hidden: true,
          text: 'Inventory Item ID',
        },
        {
          dataField: 'quantity',
          text: 'Quantity',
          headerStyle: {
            width: '100px',
            resize: 'horizontal'
          },
          style: {
            textAlign: 'center'
          }
        },
        {
          dataField: 'order_id',
          text: 'Order ID',
          headerStyle: {
            width: '150px',
            resize: 'horizontal'
          },
          formatter: this.renderOrderId,
        },
        {
          dataField: 'order_name',
          hidden: true,
          text: 'Order Name',
        },
        {
          dataField: 'line_item_id',
          text: 'Line Item ID',
          headerStyle: {
            width: '150px',
            resize: 'horizontal'
          }
        },
        {
          dataField: 'whatsapp_link',
          text: 'Message',
          headerStyle: {
            width: '150px',
            resize: 'horizontal'
          },
          formatter: this.renderMessage,
          csvFormatter: (cell, row) => {
            if (row.contact_message) {
              return row.contact_message
            }

            if (!cell) {
              return
            }

            let url = new URL(cell);
            let message = url.searchParams.get('text');

            return message
          }
        },
      )
    }

    return (
      <div>
        <div className="row mt-3 mb-4">
          <div className="col-xs-12 col-md-9">
            <h2 className="sub-header">Activity Log</h2>
          </div>
        </div>
          <Accordion style={{borderRadius: '18px'}} className="dashboard-card shadow-sm mt-4" defaultActiveKey="0">
          <Accordion.Item style={{borderRadius: '18px', border: 'none'}} eventKey="0">
            <Accordion.Header><h5 style={{marginBottom: '0'}}>Filters</h5></Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-xs-12 col-sm-10 col-xl-6 mb-3 mb-sm-3">
                      <label style={{marginBottom: '5px'}} className="me-1">Log Period</label>
                      <DateRangePicker
                        small
                        minimumNights={0}
                        displayFormat="D/M/YY"
                        showClearDates={false}
                        showDefaultInputIcon={true}
                        isOutsideRange={() => false}
                        startDate={this.state.logPeriodStart} // momentPropTypes.momentObj or null,
                        endDate={this.state.logPeriodEnd} // momentPropTypes.momentObj or null,
                        startDateId="log-dates-start"
                        endDateId="log-dates-end"
                        onDatesChange={({ startDate, endDate }) => {
                          this.setState({ logPeriodStart: startDate, logPeriodEnd: endDate })
                        }
                        } // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput1} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(focusedInput1) =>
                          this.setState({ focusedInput1 })
                        } // PropTypes.func.isRequired,
                      />
                    </div>
                    <div className="col-xs-12 col-sm-10 col-xl-6 mb-3 mb-sm-3">
                      <label style={{ marginBottom: '5px' }} className="me-1">Type</label>
                      <Form.Select className="form-control w-auto d-inline-block" onChange={(e) => this.setState({ type: e.target.value })} value={type}>
                        <option value="">Any</option>
                        <option value="off_days">Off Days</option>
                      </Form.Select>
                    </div>
                  </div>
                </div>

                <div className="filter-buttons col-sm-3 d-flex flex-sm-column flex-lg-row justify-content-between mb-auto">
                <Button
                    className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                    variant="secondary"
                    style={{width: '100%'}}
                    onClick={this.handleResetFilter}
                  >Reset
                  </Button>
                <Button
                    className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                    variant="primary"
                    style={{width: '100%'}}
                    onClick={this.handleOnClickFilter}
                  >Apply
                  </Button>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
            {
              loading &&
                <Loader />
            }
            {
              !loading && items && items.length > 0 &&
              <ToolkitProvider
              keyField='created'
              data={items}
              columns={columns}
              exportCSV={{
                exportAll: true
              }}
              search
            >
              {
                props => (
                  <>
                  <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                      <Col>
                        <ExportCSVButton
                          className="btn-info"
                          style={{borderRadius:'8px'}}
                          { ...props.csvProps }
                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                          Export to CSV</ExportCSVButton>
                      </Col>
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps }
                        style={{borderRadius:'25px'}} placeholder="Search"/>
                      </Col>
                    </Container>

                    <BootstrapTable
                    { ...props.baseProps }
                      bordered={false}
                      pagination={paginationFactory(options)}
                      hover expandableRow={() => true}
                      options={{
                        expandBy: "column",
                        sizePerPage: 20,
                        paginationShowsTotal: true,
                        sortName: "created",
                        sortOrder: "desc",
                      }}
                      expandComponent={this.renderExpandedNoteColumn}
                      defaultSorted={[{
                        dataField: 'created',
                          order: 'desc',
                      }]}
                    >

                    </BootstrapTable>
                </>
              )
            }
          </ToolkitProvider>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    user: state.auth.user,
    logs: state.logs
  }
}, {...logsActionCreators})(Logs);

