import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Col, Row, Button, Alert } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import Input from '../components/Input';
import Select from '../components/Select';
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import config from '../config'

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

const validate = (values, props) => {
  const { match } = props;
  const errors = {}

  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.amount) {
    errors.role = 'Required';
  } else if (!/^-?\d+\.?\d*$/i.test(values.amount)) {
    errors.email = 'Invalid email address';
  }
  return errors
}

class BillAdjustment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gstTaxable: false,
      commissionChargable : false,
      commissionRate: 0,
      gst: 0,
      commission: 0,
      balance: 0,
      amount: 0,
      shippingFee: 0
    }
  }

  componentDidMount() {

    const { commissionRate } = this.props;
    this.setState({commissionRate: parseInt(commissionRate)})
  }

  submit = (values) => {
    const { commission, gst } = this.state
    const { match, onClickSave } = this.props;
    const { name, amount, shippingFee, adjustmentInfo, purchaseDate } = values;

    onClickSave(name, amount, commission, gst, shippingFee, adjustmentInfo, purchaseDate)
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  onChangeGstTaxable = (value) => {

    const { amount, commissionChargable, commissionRate, shippingFee } = this.state

    this.setState({gstTaxable: value.target.checked})
    this.updateCalculation(amount, commissionChargable, commissionRate, value.target.checked, shippingFee)
  }

  onChangeCommission = (value) => {
    const { amount, gstTaxable, commissionRate, shippingFee } = this.state

    this.setState({commissionChargable: value.target.checked})
    this.updateCalculation(amount, value.target.checked, commissionRate, gstTaxable, shippingFee)
  }

  onChangeAmount = (value) => {
    const { gstTaxable, commissionChargable, commissionRate, shippingFee } = this.state

    let amount = parseFloat(value.target.value)
    this.setState({amount: amount})
    this.updateCalculation(amount, commissionChargable, commissionRate, gstTaxable, shippingFee)
  }

  onChangeShippingFee = (value) => {
    const { amount, gstTaxable, commissionChargable, commissionRate } = this.state

    let shippingFee = parseFloat(value.target.value)
    this.setState({ shippingFee })
    this.updateCalculation(amount, commissionChargable, commissionRate, gstTaxable, shippingFee)
  }

  updateCalculation = (amount, commissionChargable, commissionRate, gstTaxable, shippingFee) => {

    let commission = commissionChargable ? amount * commissionRate / 100 : 0;
    let gst = gstTaxable ? commission * 0.08: 0;
    let balance = amount - commission - gst + shippingFee;

    this.setState({
      commission,
      gst,
      balance
    })
  }

  render() {
    const { handleSubmit, error, submitting, match, commissionRate} = this.props
    const { commission, gst, balance, commissionChargable } = this.state
    return (
      <Modal style={{border: 'none'}} show={true} onHide={this.handleOnClose} size="lg">
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4>Adjustment</h4>
        </Modal.Header>
        <form onSubmit={handleSubmit(this.submit)}>
        <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>

            {
              error &&
              <Alert variant="danger">
                {
                  Array.isArray(error) ?
                  <ul>
                  {
                    error.map((err, i) => (
                      <li key={i}>
                        {err.msg}
                      </li>
                    ))
                  }
                  </ul>
                  :
                  error
                }
              </Alert>
            }
            <Row>
              <Col lg={6} className="d-grid gap-2">
                <Field className="form-control" name="purchaseDate" label="Purchase Date" placeholder="Purchase Date" type="date" component={Input} />
                <Field className="form-control" name="name" label="Order Id" placeholder="Order Id" type="text" component={Input} />
                <Field className="form-control" name="adjustmentInfo" label="Adjustment Info" placeholder="Adjustment Info" type="text" component={Input} />
                <Field className="form-control" name="amount" onChangeValue={this.onChangeAmount} label="Item Price" placeholder="Item Price" type="number" component={Input} />
                <Field className="form-control" name="shippingFee" onChangeValue={this.onChangeShippingFee} label="Shipping Fee" placeholder="Shipping Fee" type="number" component={Input} />
                <Field className="form-control" isRenderLabel={false} onChangeValue={this.onChangeCommission} name="commission_chargable" label={`Commission  chargable (${commissionRate}%)`} type="checkbox" component={Input} />
                <Field className="form-control" disabled={!commissionChargable} isRenderLabel={false} onChangeValue={this.onChangeGstTaxable} name="gst_taxable" label="SST taxable" type="checkbox" component={Input} />
              </Col>
              <div className="vr d-none d-xl-block col-lg-1 col-xl-1 dashboard-vr"></div>
              <Col className="my-auto label-strong">
                <div className="d-grid gap-1 my-2">
                  <label>Commission</label>
                  {currency}{commission.toFixed(2)}
                </div>
                <div className="d-grid gap-1 my-2">
                  <label>SST</label>
                  {currency}{gst.toFixed(2)}
                </div>
                <div className="d-grid gap-1 my-2">
                  <label>Balance</label>
                  {currency}{balance.toFixed(2)}
                </div>
              </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button
              variant="primary"
              type="submit"
              disabled={submitting}
              className="w-100 btn-br-6 mx-2"
            >
              {submitting ? 'SUBMITTING' : 'SUBMIT'}
            </Button>
        </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

BillAdjustment = reduxForm({
  form: 'BillAdjustment',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true,
  // asyncValidate,
})(BillAdjustment);

export default connect((state, props) => {
  const { match } = props;
  return {};
}, {
})(BillAdjustment);
