import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const CLEAR_USER = 'CLEAR_USER';

function fetchProfileRequest() {
  return {
    type: FETCH_PROFILE_REQUEST,
  };
}

function fetchProfileSuccess(json) { 
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchProfileFailure() {
  return {
    type: FETCH_PROFILE_FAILURE
  };
}

function shouldFetchProfile(state) {
  const results = state.profile;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

export function fetchProfileFromApi() {
  return dispatch => {
    dispatch(fetchProfileRequest());
    return api(`/profile`)
      .then(json => {
        // console.log('profile', json)
        dispatch(fetchProfileSuccess(json));
      })
      .catch(err => {
        dispatch(fetchProfileFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchProfile() {
  return (dispatch, getState) => {
    if (shouldFetchProfile(getState())) {
      return dispatch(fetchProfileFromApi());
    }
  };
}
