import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
} from '../actions/products';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
  merchantId: null,
  limit: null,
  next: null,
  unselectableRows: []
};

export default function products(state = defaultState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PRODUCTS_SUCCESS:

      //filter product.id where status is 'reviewing'
      let unselectableRows = action.payload.items.filter(item => item.status ==='reviewing').map(item => item.id)
      
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
        unselectableRows: unselectableRows
        // next: action.payload.next
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
