import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const renderField = (props) => {

  const { disabled, input, label, placeholder, name, onChangeValue, handleSubmit, type, isRenderLabel = true, meta: { touched, error } } = props

  return (
  <div className={`form-group ${touched && error ? "has-error has-feedback" : ""}`}>
    {
      isRenderLabel && type !== "checkbox" &&
      <label htmlFor={name}>{label}</label>
    }
    {
      type === "checkbox" ?
      <label htmlFor={name} className="custom-checkbox">{label}
        <input className={`${type === "checkbox" ? "" : "form-control" }`} {...input} value={input.value} disabled={disabled} placeholder={placeholder} type={type} onChange={(e) => {
          input.onChange(e);
          if (onChangeValue) {
            onChangeValue(e);
        }
        }} />
        <span className="checkmark checkmark-smaller"></span>
      </label>
      :
      <input className={`${type === "checkbox" ? "" : "form-control" }`} {...input} value={input.value} disabled={disabled} placeholder={placeholder} type={type} onChange={(e) => {
        input.onChange(e);
        if (onChangeValue) {
          onChangeValue(e);
        }
      }} />
    }
    {
      touched && error &&
        <span style={{color: '#d15d5d'}}>
        <FontAwesomeIcon icon={solid('exclamation')} style={{marginRight: '6px'}}/>
        {error}
      </span>
    }
  </div>
  )
}

export default renderField;
