import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Modal, Button, Card, Row, Col, Form, ToggleButton, ButtonGroup } from 'react-bootstrap';
import Loader from './Loader';
import moment from 'moment';
import _ from 'lodash';
import api from "../helpers/apiClient";
import BootstrapTable from 'react-bootstrap-table-next';
import * as shippingActionCreators from '../redux/actions/shipping';
import { SingleDatePicker } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { exportAsPDFCN, exportAsPDFCNMulti } from '../helpers/htmlToCanvas';
import Profile from '../containers/Profile';
import GTRAlert from './GTRAlert';
import * as errorActionCreators from '../redux/actions/error';

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

const MergedShippingModal = (props) => {
  const { user, onClose, confirmShipment, match, parcels = [], error, resetError } = props;
  const { fetchShippingRates, makeOrder, undoMakeOrder, makePayment, resetData, makeDHLShipmentWithPickup, makeDHLShipment } = props;
  const isAdmin = user && user.role === 'admin' || user.role === 'staff'

  const { shipping } = useSelector(state => state);
  const [address, setAddress] = useState({});
  const [serviceId, setServiceId] = useState("");
  const [hasPickupAddress, setHasPickupAddresss] = useState(true);
  const [pickupDate, setPickupDate] = useState(null);
  const [focused, setFocused] = useState(null);
  const [service, setService] = useState(null);
  const [pickup, setPickup] = useState(true);
  const [dhlLabels, setDhlLabels] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showPickupAddressModal, setShowPickupAddressModal] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [editingDeliveryAddress, setEditingDeliveryAddress] = useState(false);
  const [parcelShippingAddress, setParcelShippingAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const cnMultiRef = useRef(null);

  const dhlShipmentCreated = shipping.shipments && shipping.shipments.length > 0 && shipping.shipments[0].dhlShipmentId;
  const easyparcelShipmentCreated = shipping.payment && shipping.payment[0]['REQ_ID'];

  useEffect(() => {
    resetData();
    resetError();
    if (parcels[0] && parcels[0].shipping_addresses.length === 1) {
      setSelectedAddress(JSON.stringify(parcels[0].shipping_addresses[0]))
      setParcelShippingAddress([parcels[0].shipping_addresses[0]])
    }
  }, [])

  // useEffect(() => {
  //   if (service) {
  //     fetchHolidays();
  //   }
  // }, [service])

  const fetchHolidays = async () => {
    const hol = await api(`/shipments/holidays`);
    setHolidays(hol)
  }

  useEffect(() => {
    if(dhlShipmentCreated) {
      confirmShipment(shipping.shipments, pickupDate ? pickupDate.format("YYYY-MM-DD") : "", service);
      setDhlLabels(_.map(shipping.shipments, shipment => shipment.content));
    }
    if(easyparcelShipmentCreated && pickupDate){
      confirmShipment(shipping.payment, pickupDate.format("YYYY-MM-DD"), service);
    }
    if(shipping.rates.length !== 0 && !easyparcelShipmentCreated && !selectedRow) { // default select pgeon
      let row = _.find(shipping.rates, rate => {
        return rate.service_id === 'EP-CS0K3'
      })
      setServiceId(row.service_id);
      setSelectedRow(row);
    }
  }, [shipping])

  const handleOnClose = () => {
    setDhlLabels([]);
    resetData();

    if (!shipping.loading) {
      onClose();
    }
  }

  const handleRowSelected = (row, isSelected, e) => {

    setServiceId(row.service_id);
    setSelectedRow(row);
  }

  const handleOnClickNext = () => {

    if (service === 'easyparcel') {
      makeOrder(pickupDate.format("YYYY-MM-DD"), serviceId, address, parcels)
    }

    if (service === 'dhl') {
      setShowDhlConfirm(true);
    }
  }

  const handleOnClickConfirm = () => {

    makePayment(parcels, selectedRow.courier_name, pickupDate.format("YYYY-MM-DD"), address);
  }

  const handleOnClickBack = () => {
    undoMakeOrder();
  }

  const isOutsideRange = (date) => {

    let now = moment()
    let startDate = moment().startOf('day')
    let endDate = moment().endOf('day')
    if(now.hour() > 11){
      startDate.add(1, 'days')
      endDate.add(1, 'days')
    }

    if(startDate.day() === 0){
      endDate.add(5, 'days')
    } else if(startDate.day() === 6){
      endDate.add(6, 'days')
    } else if(startDate.day() === 5){
      endDate.add(6, 'days')
    } else if(startDate.day() === 4){
      endDate.add(6, 'days')
    } else {
      endDate.add(4, 'days')
    }

    let isHoliday = false;
    _.each(holidays, holiday => {
      if (date.isSame(moment(holiday, 'MM/DD/YYYY'), 'day')) {
        isHoliday = true;
      }
    })
    if(isHoliday) {
      return true
    }

    if (service === 'easyparcel' && date.day() == 6) {
      return true
    }

    return date.day() == 0 || date.isBefore(startDate) || date.isAfter(endDate);
  }

  const handleSelectPickupDate = (date) => {
    if(moment().isSame(date, 'day')){
      date = moment(date).utcOffset(8).set('hour', '1')
    } else {
      date = moment(date).utcOffset(8).set('hour', '10')
    }

    setPickupDate(date);
  }

  const loadService = async (service) => {
    return api(`/profile`).then(function(profile){

      setAddress(profile);

      // if(!profile.name || !profile.address1 || !profile.postcode){
      //   setHasPickupAddresss(false);
      //   return
      // }

      // setHasPickupAddresss(true);

      // if (service === "easyparcel") {
      //   return fetchShippingRates(profile.postcode, parcels)
      // }
    })
  }

  const confirmDHLShipment = async () => {
    // if (pickup) {
    //   makeDHLShipmentWithPickup(parcels, parcelShippingAddress, pickupDate.format("YYYY-MM-DDThh:mm:ssZ"), address);
    // } else {
      makeDHLShipment(parcels, parcelShippingAddress)
    // }
  }

  const handleOnClickService = (service) => {
    loadService(service);

    setService(service);
  }

  const handleEditPickupAddress = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowPickupAddressModal(true);
  }

  const handleBackToShipment = async () => {
    setShowPickupAddressModal(false);

    return loadService(service);
  }

  const editDeliveryAddress = () => {
    setEditingDeliveryAddress(true);
  }

  const saveDeliveryAddress = (e, index) => {
    e.preventDefault();
    let addresses = [...parcelShippingAddress];
    addresses[index].zip = e.target.zip.value
    addresses[index].phone = e.target.phone.value
    setParcelShippingAddress(addresses);
    setEditingDeliveryAddress(false);
  }

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    selected: ['EP-CS0K3'],
    onSelect: handleRowSelected,
    headerColumnStyle: { width: '30px' },
  };

  const ordersCol = [
    {
      dataField: 'service_id',
      text: 'Service ID',
      hidden: true,
    },
    {
      dataField: 'service_name',
      text: 'Name',
      headerStyle: { width: '300px' },
      formatter: (_, row) => {
        return (
          <div>
            <span>{row.courier_name}</span><br/>
            <img src={row.courier_logo} alt={row.service_name} className='shipping-service-image' />
          </div>
        )
      }
    },
    {
      dataField: 'pickup_date',
      text: 'Delivery',
      formatter: (_, row) => {
        return (
          <div>
            <span>{row.delivery}</span>
          </div>
        )
      },
    },
  ];

  return (
    <>
      <Modal style={{border: 'none'}} show={true} onHide={handleOnClose} size="lg">
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0 text-capitalize">Arrange Merged Shipment{service && ` - ${service.toUpperCase()}`}</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{borderTop: '1px solid #dee2e6'}}>
          {
            parcels.length === 0 &&
              <p className="text-center mb-0">Shipments created for all orders</p>
          }
          {
            service === 'easyparcel' && shipping.shipments > 0 && shipping.shipments[0].status === 'Fail' &&
              <div role="alert" className="alert alert-danger">{shipping.shipments[0].status}: {shipping.shipments[0].remarks}</div>
          }
          {
            !service && parcels.length > 0 &&
              <div className="my-4 d-grid gap-3">
                <Card onClick={() => handleOnClickService("dhl")} className="help-option tealCard shadow-sm">
                  <Card.Body className="m-2 d-grid gap-3">
                    DHL
                  </Card.Body>
                </Card>
              </div>
          }
          {/* { */}
          {/*   service && */}
          {/*     <Card className="tealCard shadow-sm"> */}
          {/*       <Card.Header className="tealCardHeader">Pick-up</Card.Header> */}
          {/*       <Card.Body className="d-grid gap-2 label-strong grey-label"> */}
          {/*         { */}
          {/*           service === 'dhl' && */}
          {/*             <> */}
          {/*               <Form.Select className="form-control mb-2" value={pickup ? "now" : "later"} onChange={(e) => { setPickup(e.target.value === "now") }}> */}
          {/*                 <option value="now">Schedule Pick-up Now</option> */}
          {/*                 <option value="later">Schedule Pick-up Later</option> */}
          {/*               </Form.Select> */}
          {/*             </> */}
          {/*         } */}
          {/*         { */}
          {/*           pickup && */}
          {/*             <> */}
          {/*               { */}
          {/*                 !(service === "easyparcel" && shipping.rates.length === 0) && shipping.shipments.length === 0 && holidays.length > 0 && */}
          {/*                   <> */}
          {/*                     <label>Select Pickup Date: </label> */}
          {/*                     <SingleDatePicker */}
          {/*                       numberOfMonths={1} */}
          {/*                       isOutsideRange={isOutsideRange} */}
          {/*                       displayFormat="D/M/YY" */}
          {/*                       date={pickupDate} // momentPropTypes.momentObj or null */}
          {/*                       onDateChange={handleSelectPickupDate} // PropTypes.func.isRequired */}
          {/*                       focused={focused} // PropTypes.bool */}
          {/*                       onFocusChange={({ focused }) => setFocused( focused )} // PropTypes.func.isRequired */}
          {/*                     /> */}
          {/*                   </> */}
          {/*               } */}
          {/*               { */}
          {/*                 shipping.shipments.length === 0 && !shipping.payment && shipping.rates.length > 0 && !shipping.loading && */}
          {/*                   <> */}
          {/*                     <label>Select Pickup Service: </label> */}
          {/*                     <BootstrapTable */}
          {/*                       keyField='service_id' */}
          {/*                       data={shipping.rates} */}
          {/*                       columns={ordersCol} */}
          {/*                       bordered={ false } */}
          {/*                       selectRow={selectRowProp} */}
          {/*                       hover */}
          {/*                       defaultSorted={[{ */}
          {/*                         dataField: 'created_at', */}
          {/*                           order: 'desc', */}
          {/*                       }]} */}
          {/*                       noDataIndication={() => 'There is no data to display'} */}
          {/*                     /> */}
          {/*                   </> */}
          {/*               } */}
          {/*               { */}
          {/*                 service === 'easyparcel' && shipping.loading && */}
          {/*                   <Loader /> */}
          {/*               } */}
          {/*               { */}
          {/*                 service === 'easyparcel' && shipping.rates.length == 0 && !shipping.loading && shipping.loaded && shipping.shipments.length === 0 && */}
          {/*                   <div className="text-center my-3"> */}
          {/*                     <h5 className="mb-0" style={{color: '#d15d5d'}}><FontAwesomeIcon icon={solid('warning')}/> No pickup service available at this time</h5> */}
          {/*                   </div> */}
          {/*               } */}
          {/*               { */}
          {/*                 shipping.shipments.length === 0 && */}
          {/*                   <div> */}
          {/*                     Same Day Pick Up Service is only available for booking before 12 p.m.<br/> */}
          {/*                     All pick-up is available on working days only (exclude Sat, Sun and PH).<br/> */}
          {/*                     Pick-up date subject to delivery partner's availability.<br/> */}
          {/*                   </div> */}
          {/*               } */}
          {/*               { */}
          {/*                 shipping.shipments.length > 0 && pickupDate && */}
          {/*                   <div className="d-grid gap-1"> */}
          {/*                     <label>Pick-up date</label> */}
          {/*                     <span>{pickupDate.format('D-M-YYYY')}</span> */}
          {/*                   </div> */}
          {/*               } */}
          {/*               { */}
          {/*                 shipping.shipments.length > 0 && selectedRow && */}
          {/*                   <> */}
          {/*                     <div className="d-grid gap-1"> */}
          {/*                       <label>Courier name</label> */}
          {/*                       <span>{selectedRow.courier_name}</span> */}
          {/*                     </div> */}
          {/*                     { */}
          {/*                       isAdmin && */}
          {/*                         <div className="d-grid gap-1"> */}
          {/*                           <label>Shipping fee</label> */}
          {/*                           <span>{currency} {selectedRow.price}</span> */}
          {/*                         </div> */}
          {/*                     } */}
          {/*                   </> */}
          {/*               } */}
          {/*               <hr/> */}
          {/*               <Row className="align-items-center justify-content-between"> */}
          {/*                 <Col xs="auto"><label>Pickup Address</label></Col> */}
          {/*                 <Col xs="auto"> */}
          {/*                   { */}
          {/*                     hasPickupAddress && address.name && shipping.shipments.length === 0 && !shipping.loading && */}
          {/*                       <Button variant="secondary" className="btn-sm" onClick={handleEditPickupAddress}> */}
          {/*                         Edit */}
          {/*                       </Button> */}
          {/*                   } */}
          {/*                 </Col> */}
          {/*               </Row> */}
          {/*               { */}
          {/*                 !hasPickupAddress && */}
          {/*                   <Card className="redCard"> */}
          {/*                     <Card.Header className="redCardHeader">Pickup address not found</Card.Header> */}
          {/*                     <Card.Body> */}
          {/*                       Please enter your pickup address <a onClick={handleEditPickupAddress} href="">here</a>. */}
          {/*                     </Card.Body> */}
          {/*                   </Card> */}
          {/*               } */}
          {/*               { */}
          {/*                 hasPickupAddress && address.name && */}
          {/*                   <Card> */}
          {/*                     <Card.Body className="d-grid gap-1"> */}
          {/*                       <label>{address.name}</label> */}
          {/*                       { */}
          {/*                         address.company && */}
          {/*                           <>{address.company}<br/></> */}
          {/*                       } */}
          {/*                       {address.address1},<br/> */}
          {/*                       { */}
          {/*                         address.address2 && */}
          {/*                           <>{address.address2},<br/></> */}
          {/*                       } */}
          {/*                       {address.postcode}, {address.city},<br/> */}
          {/*                       {address.state}, {address.country}<br/> */}
          {/*                       {address.contact} */}
          {/*                     </Card.Body> */}
          {/*                   </Card> */}
          {/*               } */}
          {/*             </> */}
          {/*         } */}
          {/*       </Card.Body> */}
          {/*     </Card> */}
          {/* } */}
          {
            service && parcels.map((parcel, i) => {
              return (
                <Card key={i} className="tealCard shadow-sm">
                  <Card.Header className="tealCardHeader">Parcel #{i + 1}</Card.Header>
                  <Card.Body className="d-grid gap-2 grey-label label-strong">
                    <div className="d-grid gap-1">
                      <label>Items</label>
                      {parcel.line_items.map((single, j) => {
                        return (
                          <div key={j} className="delivery-info-value">{single.order_name} - {single.name} x {single.quantity} {single.grams}g</div>
                        )
                      })}
                    </div>
                    <div className="d-grid gap-1">
                      <label>Total Weight</label>
                      <span className="delivery-info-value">{parcel.weight}g</span>
                    </div>
                    {
                      isAdmin && parcel.shipping_lines && parcel.shipping_lines[0] && parcel.shipping_lines[0].price !== '0.00' &&
                        <div className="d-grid gap-1">
                          <label>Shipping Paid</label>
                          <span>{currency} {parcel.shipping_lines[0].price}</span>
                        </div>
                    }
                    <div className="d-grid gap-1">
                      <label>Delivery Address</label>
                      { selectedAddress &&
                      <Button variant="secondary" onClick={() => setSelectedAddress(null)}>
                        Change Address
                      </Button>
                      }
                      { !selectedAddress &&
                      <Card>
                        <Card.Body className="position-relative">
                          <label>Select an address</label>
                          <ButtonGroup className="flex-column gap-2 w-100">
                            {parcel.shipping_addresses.map((address, idx) => (
                              <ToggleButton
                                className="rounded-3 text-start"
                                key={idx}
                                id={`radio-${idx}`}
                                type="radio"
                                variant="secondary"
                                name="radio"
                                value={JSON.stringify(address)}
                                checked={selectedAddress == JSON.stringify(address)}
                                onChange={(e) => {
                                  setSelectedAddress(e.currentTarget.value)
                                  setParcelShippingAddress([JSON.parse(e.currentTarget.value)])
                                  setEditingDeliveryAddress(false)
                                }}
                              >
                                <AddressOption address={address} />
                              </ToggleButton>
                            ))}
                          </ButtonGroup>
                        </Card.Body>
                      </Card>
                      }
                      { selectedAddress &&
                      <Card>
                        <Card.Body className="position-relative">
                          { editingDeliveryAddress ?
                            <>
                              <Form onSubmit={(e) => saveDeliveryAddress(e, i)}>
                                <Button type="submit" className="position-absolute btn-sm end-0 top-0 m-3">
                                  Save
                                </Button>
                                <Form.Group className="mb-3">
                                  <Form.Label>Postcode</Form.Label>
                                  <Form.Control defaultValue={parcelShippingAddress[i].zip} name="zip" type="text" placeholder="Enter postcode" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Label>Phone Number</Form.Label>
                                  <Form.Control defaultValue={parcelShippingAddress[i].phone} name="phone" type="text" placeholder="Enter phone number" />
                                </Form.Group>
                              </Form>
                            </>
                            :
                            <>
                              <Button variant="secondary" className="position-absolute btn-sm end-0 top-0 m-3" onClick={editDeliveryAddress}>
                                Edit
                              </Button>
                              {
                                parcelShippingAddress[i] &&
                                  <>
                                    <label>{parcelShippingAddress[i].name}</label><br/>
                                    {parcelShippingAddress[i].address1},<br/>
                                    {
                                      parcelShippingAddress[i].address2 &&
                                        <>{parcelShippingAddress[i].address2},<br/></>
                                    }
                                    {parcelShippingAddress[i].zip}, {parcelShippingAddress[i].city}<br/>
                                    {parcelShippingAddress[i].province_code}, {parcelShippingAddress[i].country_code}<br/>
                                    {parcelShippingAddress[i].phone}
                                  </>
                              }
                            </>
                          }
                        </Card.Body>
                      </Card>
                      }
                    </div>
                  </Card.Body>
                </Card>
              )
            })
          }
          { // easyparcel
            shipping.payment &&
              <Card className="tealCard">
                <Card.Header className="tealCardHeader">Status</Card.Header>
                <Card.Body className="d-grid gap-2 label-strong grey-label">
                  {
                    shipping.payment.map((item, i) => {
                      return (
                        <Card key={i}>
                          <Card.Body className="d-grid gap-1">
                            <div className="d-grid gap-1">
                              <label>Order No.</label>
                              <span className="delivery-info-value">{item.orderno}</span>
                            </div>
                            <div className="d-grid gap-1">
                              <label>Status</label>
                              <span className="delivery-info-value">{item.messagenow}</span>
                            </div>
                            <div className="d-grid gap-1">
                              <label>AWB No.</label>
                              <dd className="delivery-info-value">{item.parcel[0].awb}</dd>
                            </div>
                            <div className="d-grid gap-1">
                              <label>AWB Link</label>
                              <a className="delivery-info-value" href={item.parcel[0].awb_id_link} target="_blank">Download</a>
                            </div>
                          </Card.Body>
                        </Card>
                      )
                    })
                  }
                </Card.Body>
              </Card>
          }
          { // dhl
            dhlShipmentCreated &&
              <Card className="tealCard">
                <Card.Header className="tealCardHeader">DHL Consignment Note</Card.Header>
                <Card.Body className="d-grid gap-2 label-strong grey-label">
                  {
                    shipping.shipments.map((item, i) => {
                      return (
                        <Card key={i}>
                          <Card.Body className="d-grid gap-1">
                            <div className="d-grid gap-1">
                              <label>Shipment ID/AWB</label>
                              <span>{item.dhlShipmentId}</span>
                            </div>
                          </Card.Body>
                        </Card>
                      )
                    })
                  }
                  {
                    dhlLabels.length > 0 &&
                      <div>
                        <Button className='me-1' onClick={() => exportAsPDFCN(cnMultiRef.current)}>Print as Label</Button>
                        <Button className='me-1' onClick={() => exportAsPDFCNMulti(cnMultiRef.current)}>Print as Document</Button>
                      </div>
                  }
                </Card.Body>
              </Card>
          }
          {
            service === 'easyparcel' && shipping.loading &&
              <Loader />
          }
          { error &&
            <GTRAlert variant="danger" alert={error}/>
          }
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          {
            service === 'easyparcel' &&
              <>
                {
                  !shipping.payment && shipping.shipments.length === 0 &&
                    <Button variant="danger-secondary" onClick={handleOnClose} disabled={shipping.loading}>
                      {!shipping.payment ? "Cancel" : "Done"}
                    </Button>
                }
                {
                  shipping.payment &&
                    <Button variant="danger-secondary" onClick={handleOnClose} disabled={shipping.loading}>
                      {!shipping.payment ? "Cancel" : "Done"}
                    </Button>
                }
                {
                  shipping.shipments.length === 0 && !shipping.payment &&
                    <Button variant="primary" onClick={handleOnClickNext} disabled={!pickupDate || shipping.loading || !selectedRow}>
                      Next
                    </Button>
                }
                {
                  shipping.shipments.length > 0 && !shipping.payment &&
                    <>
                      <Button variant="danger-secondary" onClick={handleOnClickBack} disabled={shipping.loading}>
                        Back
                      </Button>
                      <Button variant="primary" onClick={handleOnClickConfirm} disabled={shipping.loading}>
                        Confirm
                      </Button>
                    </>
                }
              </>
          }
          {
            service === 'dhl' &&
              <>
                {
                  !dhlShipmentCreated &&
                    <>
                      <Button variant={dhlShipmentCreated ? "primary" : "danger-secondary"} onClick={handleOnClose} disabled={shipping.loading}>
                        Cancel
                      </Button>
                      <Button className="btn-br-6" onClick={confirmDHLShipment} disabled={shipping.loading || !selectedAddress || editingDeliveryAddress}>Generate Consignment Note</Button>
                    </>
                }
                {
                  dhlShipmentCreated &&
                    <Button variant={"danger-secondary"} onClick={handleOnClose}>
                      Done
                    </Button>
                }
              </>
          }
        </Modal.Footer>
      </Modal>
      {
        showPickupAddressModal &&
          <Modal show={true} onHide={handleBackToShipment} size="lg" dialogClassName="modal-shadow">
            <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
              <h4 className="mb-0">Edit Pickup Address</h4>
            </Modal.Header>
            <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{ borderTop: '1px solid #dee2e6' }}>
              <Profile showHeader={false} showPassword={false} match={match} prevModal="Arrange Shipment" backToPrevModal={handleBackToShipment}/>
            </Modal.Body>
          </Modal>
      }
      {
        <div className='position-relative' ref={cnMultiRef}>
          {
            _.map(dhlLabels, (label, i) => {
              return (
                <div key={i} className="cn-container text-center position-absolute" style={{left: '-9999px', top: '-9999px'}}>
                  <img src={`data:image/png;base64, ${label}`}/>
                </div>
              )
            })
          }
        </div>
      }
    </>
  );
}

const AddressOption = ({ address }) => {
  return (
    <div>
      {address.name}<br/>
      {address.address1},<br/>
      {
        address.address2 &&
          <>{address.address2},<br/></>
      }
      {address.zip}, {address.city}<br/>
      {address.province_code}, {address.country_code}<br/>
      {address.phone}
    </div>
  )
}

export default connect((state, props) => {
  return {
    error: state.error,
  }
}, {
  ...shippingActionCreators,
  ...errorActionCreators,
})(MergedShippingModal);
