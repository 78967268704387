import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Alert, Badge, Container } from 'react-bootstrap';
import moment from 'moment';
import Select from '../components/Select';
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import Textarea from '../components/Textarea';
import _ from 'lodash';
import ConfirmModal from '../components/ConfirmModal';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import TabHeader from '../components/TabHeader';
import CreateTitlePrefixModal from "./CreateTitlePrefixModal";

const { SearchBar } = Search;

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class TitlePrefix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      titlePrefixes:[],
      selectedIds: [],
      showConfirmDeleteModel: false,
      showBulkDeleteSuccess: false,
      showCreateTitlePrefix: false
    };
  }

  async componentDidMount() {

    this.setState({ loading: true })

    let result = await api(`/title_prefix` , {
      method: 'GET',
    })

    this.setState({ loading: false, titlePrefixes: result })
  }

  onRowSelect = (row, isSelected, e) => {

    let { selectedIds } = this.state;

    if(isSelected){
      selectedIds.push({
        id: row._id,
        discount_code_id: row.discount_code_id,
        price_rule_id: row.price_rule_id
      })
    } else {
      selectedIds = _.reject(selectedIds, {id: row._id})
    }

    this.setState({ selectedIds });
  }

  onSelectAll = (isSelected, rows) => {

    let { selectedIds } = this.state;

    if(isSelected){
      _.each(rows, function (row) {
        selectedIds.push({
          id: row._id,
          discount_code_id: row.discount_code_id,
          price_rule_id: row.price_rule_id
        })
      })
    } else {
      _.each(rows, function (row) {
        selectedIds = _.reject(selectedIds, {id: row._id})
      })
    }

    this.setState({ selectedIds });

    return true
  }

  renderDate = (cell, row) => {

    return moment(cell).format("DD/MM/YYYY")
  }

  renderOneUsePerCustomer = (cell, row) => {
    return row.once_per_customer ?
      <Badge bg="success" className="text-uppercase">Yes</Badge>
      :
      <Badge bg="warning" className="text-uppercase">No</Badge>
  }

  renderUsageLimit = (cell, row) => {
    return cell ? cell : 'None'
  }

  handleOnClickBulkDelete = () => {
    this.setState({ showConfirmDeleteModel: true });
  }

  handleOnCloseBulkFulfillModal = () => {
    this.setState({ showConfirmDeleteModel: false });
  }

  handleOnBulkfDelete = async () => {
    const { selectedIds } = this.state;

    let ids = _.map(selectedIds, 'id')

    let result = await api(`/title_prefix`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ids})
    })

    this.setState({ showConfirmDeleteModel: false, selectedIds: [], showBulkDeleteSuccess: true, loading: true });

    this.componentDidMount()
  }

  handleDismissBulkDeleteSuccess = () => {
    this.setState({ showBulkDeleteSuccess: false })
  }

  handleOnClickCreate = () => {
    this.setState({ showCreateTitlePrefix: true });
  }

  handleOnCloseCreateTitlePrefix = (shouldRefresh) => {
    this.setState({ showCreateTitlePrefix: false });

    if(shouldRefresh){
      this.componentDidMount()
    }
  }

  handleOnClickBulkUpdate = async (e) => {
    const { id } = e.target
    const [cell, prefix, tag] = id.split('#,')

    let result = await api(`/title_prefix/bulk_update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id:cell })
    })

    let productsToUpdate = result.product_to_update

    if(productsToUpdate === 0){
      alert('No product to update')
      return
    } else {
      alert(`Updating ${productsToUpdate} products`)
    }
    // this.setState({ loading: false, titlePrefixes: result })
  }

  renderBulkUpdate = (cell, row) => {

    if(row.status !== 'active'){
      return
    }

    return (
      <Button variant="success"
          id={`${cell}#,${row.prefix}#,${row.tag}`}
          name="Bulk Update"
          onClick={this.handleOnClickBulkUpdate}
        >
          Bulk Update
      </Button>
    )
  }

  renderStatus = (cell, row) => {
    if(cell === 'active'){
      return <Badge bg="success" className="text-uppercase">{cell}</Badge>
    } else {
      return <Badge bg="danger" className="text-uppercase">{cell}</Badge>
    }
  }

  render() {
    const { error, submitting, match, user } = this.props
    const { loading, titlePrefixes, selectedIds, showBulkDeleteSuccess, showConfirmDeleteModel, showCreateTitlePrefix } = this.state

    let nonSelectable = []

    titlePrefixes.forEach((titlePrefix) => {
      if(titlePrefix.status !== 'active'){
        nonSelectable.push(titlePrefix._id)
      }
    })

    let selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      headerColumnStyle: { width: '30px' },
      nonSelectable,
    }

    const isAdmin = user.role === 'admin'

    const options = {
      showTotal: true,
      sizePerPage: 20,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: titlePrefixes.length
      }],
    };

    const columns = [
      {
        dataField: 'tag',
        sort: true,
        text: 'Tag',
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'prefix',
        sort: true,
        text: 'Decoration Text',
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'type',
        sort: true,
        text: 'Type',
        headerStyle: {
          width: '60px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'status',
        sort: true,
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
        text: 'Status',
        formatter: this.renderStatus
      },
      {
        dataField: 'created',
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
        text: 'Created at',
        sort: true,
        formatter: this.renderDate
      },
      {
        dataField: 'created_by',
        text: 'Created by',
        sort: true,
        headerStyle: {
          width: '150px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'last_updated_count',
        text: 'Last Count',
        sort: true,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
      },
      {
        dataField: '_id',
        text: 'Bulk Update',
        formatter: this.renderBulkUpdate,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        }
      },
    ];

    return (
      <div>
        <TabHeader title="Title Decoration" tab_id="titlePrefix" user={user} children={
          <>
            <Button className="btn-br-6" onClick={this.handleOnClickCreate}>Create Title Decoration</Button>
            { isAdmin &&
              <>
              <Button className="btn-br-6" variant="danger-secondary" disabled={selectedIds.length === 0} onClick={this.handleOnClickBulkDelete}>Disable</Button>
              </>
            }
          </>
        }/>
        {
          showBulkDeleteSuccess &&
          <Alert variant="success" dismissible onClose={this.handleDismissBulkDeleteSuccess}>
            <p>Bulk disable success</p>
          </Alert>
        }
        {
          loading &&
          <Loader />
        }
        {
          !loading &&
          <>
            <ToolkitProvider
              keyField='_id'
              data={titlePrefixes}
              columns={columns}
              search
            >
              {
                props => (
                  <>
                    <Container fluid className="mb-3 mt-4 d-flex gap-3 justify-content-end flex-row flex-wrap">
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps } style={{borderRadius:'25px'}} />
                      </Col>
                    </Container>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      { ...(isAdmin ? { selectRow: selectRowProp } : {}) }
                      pagination={paginationFactory(options)}
                      hover
                      defaultSorted={[{
                        dataField: 'created',
                          order: 'desc',
                      }]}
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </>
        }
        {
          showConfirmDeleteModel &&
          <ConfirmModal onClickYes={this.handleOnBulkfDelete} onClose={this.handleOnCloseBulkFulfillModal} title="Bulk Fulfill">
            <p>Are you sure you want to disable {selectedIds.length} item(s)?</p>
          </ConfirmModal>
        }
        {
          showCreateTitlePrefix &&
          <CreateTitlePrefixModal
            onClose={this.handleOnCloseCreateTitlePrefix}
            // onSubmit={this.handleOnSubmitCreateManualOrder}
            />
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  const { match } = props;
  return {
    user: state.auth.user,
  };
}, {})(TitlePrefix);
