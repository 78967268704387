import React, { Component } from 'react';
import { Modal, Card, Button, Row, Col, Badge } from 'react-bootstrap';
import _ from 'lodash';
import textField from '../images/textField.png'
import textArea from '../images/textArea.png'
import dropdown from '../images/dropdown.png'
import imageUpload from '../images/imageUpload.png'
import multipleImageUpload from '../images/multipleImageUpload.png'
import timeSlot from '../images/timeSlot.png'
import checkboxes from '../images/checkboxes.png'
import mic from '../images/mic.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import FieldCreate from './FieldCreate';
import Select from 'react-select';

const fields = [
  { tagType: 'textfield', title: 'Text Field', maxChar: '', secondTitle: '', src: textField, required: false, },
  { tagType: 'textarea', title: 'Text Area', maxChar: '', secondTitle: '', src: textArea, },
  { tagType: 'dropdown', title: 'Dropdown', options: '', secondTitle: '', src: dropdown, required: false, conditional: true, },
  { tagType: 'imageupload', title: 'Image Upload', src: imageUpload, required: false, },
  { tagType: 'multiupload', title: 'Multiple Images Upload', limit: '', src: multipleImageUpload, required: false, },
  { tagType: 'timeslot', title: 'Preferred Time Slot', options: '', src: timeSlot, },
  { tagType: 'checkbox', title: 'Checkbox', options: '', limit: '', src: checkboxes, conditional: true, },
  { tagType: 'audioupload', title: 'Audio Upload', src: mic, },
  { tagType: 'ddaddon', title: 'Customised Add-On (Dropdown)', src: dropdown, options: '', conditional: true, },
  { tagType: 'cbaddon', title: 'Customised Add-On (Checkbox)', limit: '', src: checkboxes, options: '', conditional: true, },
]

const corporateFields = [
  { tagType: 'textfield', title: 'Text Field', maxChar: '', secondTitle: '', src: textField, required: false, },
  { tagType: 'textarea', title: 'Text Area', maxChar: '', secondTitle: '', src: textArea, },
  { tagType: 'dropdown', title: 'Dropdown', options: '', secondTitle: '', src: dropdown, required: false, conditional: true, },
  { tagType: 'imageupload', title: 'Image Upload', src: imageUpload, required: false, },
  { tagType: 'multiupload', title: 'Multiple Images Upload', limit: '', src: multipleImageUpload, required: false, },
  { tagType: 'checkbox', title: 'Checkbox', options: '', limit: '', src: checkboxes, conditional: true, },
  { tagType: 'audioupload', title: 'Audio Upload', src: mic, },
  { tagType: 'logoupload', title: 'Corporate Logo Upload', required: false },
]

const productAddOnFields = [
  { tagType: 'textfield', title: 'Text Field', maxChar: '', secondTitle: '', src: textField, required: false, },
  { tagType: 'dropdown', title: 'Dropdown', options: '', secondTitle: '', src: dropdown, required: false, conditional: true, },
]

class FieldsModal extends Component {
  constructor(props){
    super(props);

    this.state = {
      showEnterFieldProps: false,
      showAddingConditionalField: false,
      addonOptions: [{value: ''}],
      conditionalFields: [],
      primaryTitle: '',
      maxChar: '',
      secondTitle: '',
      limit: '',
      required: false,
    }
  }

  componentDidMount() {
    const { conditional, conditionalOptions } = this.props

    if (conditional) {
      this.setState({
        showAddingConditionalField: true,
        addonOptions: conditionalOptions
      })
    }
  }

  handleOnClose = () => {
    const { onClose } = this.props;

    onClose();
  }

  handleSelectField = id => {
    const { showAddingConditionalField, conditionalFields, addonOptions } = this.state
    const { conditional, mainAddConditional, isProductAddOn = false, corporate = false } = this.props

    let field
    if (isProductAddOn) {
      field = _.find(productAddOnFields, field => field.tagType === id)
    } else if (corporate) {
      field = _.find(corporateFields, field => field.tagType === id)
    } else {
      field = _.find(fields, field => field.tagType === id)
    }

    if (!showAddingConditionalField) {
      return this.setState({
        showEnterFieldProps: true,
        field
      })
    }

    field = {
      ...field,
      primaryTitle: '',
      maxChar: '',
      secondTitle: '',
      limit: '',
      required: false,
      valueOptions: addonOptions,
      options: [{value: ''}],
      selected: [],
    }

    if (!conditional) {
      return this.setState({
        showAddingConditionalField: false,
        conditionalFields: [...conditionalFields, field],
      })
    }

    mainAddConditional(field)
  }

  submit = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.target.reportValidity()) {
      return
    }

    const {
      field,
      addonOptions,
      primaryTitle,
      maxChar,
      secondTitle,
      limit,
      required,
      conditionalFields
    } = this.state;
    const { onSelectField } = this.props;

    field.primaryTitle = primaryTitle
    field.maxChar = maxChar
    field.secondTitle = secondTitle
    field.limit = limit
    field.required = required
    field.options = addonOptions
    field.conditionalFields = conditionalFields

    onSelectField(JSON.parse(JSON.stringify(field)))
  }

  handleBack = () => {
    const { showAddingConditionalField } = this.state

    if (showAddingConditionalField) {
      this.setState({ showAddingConditionalField: false })
    } else {
      this.setState({ showEnterFieldProps: false })
    }
  }

  handleOptionsChange = selected => {
    this.setState({ selected })
  }

  handleEditOptions = (e) => {
    let { name, value } = e.target
    let index = name.split('_')[1]
    let { addonOptions, conditionalFields, field } = this.state;

    let split = addonOptions[index].value.split('#')

    if (field.tagType === 'ddaddon' || field.tagType === 'cbaddon') {
      if (name.includes('name')) {
        addonOptions[index].value = value + '#' + (split[1] || '')
      } else if (name.includes('price')) {
        addonOptions[index].value = (split[0] || '') + '#' + value
      }
    } else {
      addonOptions[index].value = value
    }

    _.each(conditionalFields, field => {
      field.valueOptions = addonOptions
      if (name.includes('name')) {
        field.selected = _.filter(_.map(field.selected, selectedOption => {
          if (split[0] && selectedOption.value === split[0]) {
            return {
              label: value,
              value: value,
            }
          }

          return selectedOption
        }), selectedOption => selectedOption.value)
      }
    })

    this.setState({ addonOptions, conditionalFields })
  }

  handleRemoveOption = (index) => {
    let { addonOptions, conditionalFields } = this.state;

    addonOptions.splice(index, 1)

    if (addonOptions.length === 0) {
      addonOptions = [{value: ""}]
    }

    _.each(conditionalFields, field => {
      field.valueOptions = addonOptions
      field.selected = _.filter(field.selected, selectedOption => {
        return _.some(addonOptions, option => option.value.includes(selectedOption.value))
      })
    })

    this.setState({ addonOptions, conditionalFields })
  }

  handleAddOption = () => {
    let { addonOptions, conditionalFields } = this.state;

    addonOptions = [...addonOptions, {value: ""}]

    _.each(conditionalFields, field => {
      field.valueOptions = addonOptions
    })

    this.setState({ addonOptions, conditionalFields })
  }

  handleEditOptionsConditional = (e, optionIndex, conditionalIndex, tagType) => {
    let { name, value } = e.target
    let { conditionalFields } = this.state;

    let split = conditionalFields[conditionalIndex].options[optionIndex].value.split('#')

    if (tagType === 'ddaddon' || tagType === 'cbaddon') {
      if (name.includes('name')) {
        conditionalFields[conditionalIndex].options[optionIndex].value = value + '#' + (split[1] || '')
      } else if (name.includes('price')) {
        conditionalFields[conditionalIndex].options[optionIndex].value = (split[0] || '') + '#' + value
      }
    } else {
      conditionalFields[conditionalIndex].options[optionIndex].value = value
    }

    this.setState({ conditionalFields })
  }

  handleRemoveOptionConditional = (optionIndex, conditionalIndex) => {
    let { conditionalFields } = this.state;

    let conditionalField = conditionalFields[conditionalIndex]

    conditionalField.options.splice(optionIndex, 1)

    if (conditionalField.options.length === 0) {
      conditionalFields.options = [{value: ""}]
    }

    this.setState({ conditionalFields })
  }

  handleAddOptionConditional = (conditionalIndex) => {
    let { conditionalFields } = this.state;
    let conditionalField = conditionalFields[conditionalIndex];
    conditionalField.options = [...conditionalField.options, {value: ""}]

    this.setState({
      conditionalFields: [
        ...conditionalFields.slice(0, conditionalIndex),
        conditionalField,
        ...conditionalFields.slice(conditionalIndex + 1)
      ]
    });
  }

  handleAddConditionalField = () => {
    this.setState({ showAddingConditionalField: true })
  }

  handleOnChangeConditionalField = (e) => {
    const { conditionalFields } = this.state
    const { name, value, checked, id } = e.target;
    const index = id && parseInt(id.split('_')[1]);

    switch(name) {
      case 'primaryTitle':
      case 'maxChar':
      case 'secondTitle':
      case 'options':
      case 'limit':
        this.setState({
          conditionalFields: [
            ...conditionalFields.slice(0, index),
            {
              ...conditionalFields[index],
              [name]: value
            },
            ...conditionalFields.slice(index + 1)
          ]
        });
        break;

      case 'required':
        this.setState({
          conditionalFields: [
            ...conditionalFields.slice(0, index),
            {
              ...conditionalFields[index],
              [name]: checked
            },
            ...conditionalFields.slice(index + 1)
          ]
        });
        break;
      default:
        break;
    }
  }

  handleOnChange = (e) => {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  handleRemoveFieldConditional = (index) => {
    const { conditionalFields } = this.state
    this.setState({
      conditionalFields: [
        ...conditionalFields.slice(0, index),
        ...conditionalFields.slice(index + 1)
      ]
    });
  }

  render(){
    const {
      showEnterFieldProps,
      field,
      addonOptions,
      showAddingConditionalField,
      conditionalFields,
      primaryTitle,
      maxChar,
      secondTitle,
      limit,
      required
    } = this.state;

    const { conditional, isAdmin, style = {}, showAddConditionalField = true, isProductAddOn = false, corporate = false } = this.props

    const values = {
      primaryTitle: primaryTitle,
      maxChar: maxChar,
      secondTitle: secondTitle,
      limit: limit,
      required: required,
      options: addonOptions
    }

    return(
      <Modal style={{border: 'none', ...style}} show={true} onHide={this.handleOnClose} size="lg">
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0">
            { showAddingConditionalField ? 'Add New Conditional Field' : 'Add New Input Field'}
          </h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-4" style={{borderTop: '1px solid #dee2e6'}}>
          { showAddingConditionalField && !conditional &&
          <div>
            <Button variant="link" onClick={this.handleBack} style={{textDecoration: 'none', color: '#5A5A5A', padding: '0'}}><FontAwesomeIcon icon={solid('arrow-left')} className="me-2"/>Back</Button>
          </div>
          }
          {
            (!showEnterFieldProps || showAddingConditionalField) ?
              <Row>
                { isProductAddOn &&
                  _.map(productAddOnFields, field => {
                    return (
                      <Col xs={6} md={3} key={field.tagType}>
                        <div style={fStyle.container} onClick={(e => this.handleSelectField(`${field.tagType}`))}>
                          <div style={fStyle.imgBox}>
                            <img src={field.src} style={fStyle.image} />
                          </div>
                          <p style={fStyle.title}>{field.title}</p>
                        </div>
                      </Col>
                    )
                  })
                }
                { corporate &&
                  _.map(corporateFields, field => {
                    console.log(field.tagType)
                    return (
                      <Col xs={6} md={3} key={field.tagType}>
                        <div style={fStyle.container} onClick={(e => this.handleSelectField(`${field.tagType}`))}>
                          <div style={fStyle.imgBox}>
                            { field.tagType === 'logoupload' && 
                              <FontAwesomeIcon icon={brands('google')} className="fa-3x"/>
                            }
                            { field.src &&
                            <img src={field.src} style={fStyle.image} />
                            }
                          </div>
                          <p style={fStyle.title}>{field.title}</p>
                        </div>
                      </Col>
                    )
                  })
                }
                { !isProductAddOn && !corporate &&
                  _.map(fields, field => {
                    return (
                      <Col xs={6} md={3} key={field.tagType}>
                        <div style={fStyle.container} onClick={(e => this.handleSelectField(`${field.tagType}`))}>
                          <div style={fStyle.imgBox}>
                            <img src={field.src} style={fStyle.image} />
                          </div>
                          <p style={fStyle.title}>{field.title}</p>
                          {field.conditional && !showAddingConditionalField && isAdmin &&
                            <Badge bg="secondary" style={fStyle.conditional}>Conditional Fields Allowed</Badge>
                          }
                        </div>
                      </Col>
                    )
                  })
                }
              </Row>
              :
              <div>
                <Button variant="link" onClick={this.handleBack} style={{textDecoration: 'none', color: '#5A5A5A', padding: '0'}}><FontAwesomeIcon icon={solid('arrow-left')} className="me-2"/>Back</Button>
                <form onSubmit={this.submit}>
                  <Card className="tealCard shadow-sm mt-3 mx-2">
                    <Card.Header className="tealCardHeader">{field.title}</Card.Header>
                    <Card.Body className="d-grid gap-2 label-strong grey-label">
                      <FieldCreate tagType={field.tagType} handleAddOption={this.handleAddOption} handleEditOptions={this.handleEditOptions} values={values} handleRemoveOption={this.handleRemoveOption} addConditionalField={this.handleAddConditionalField} onChange={this.handleOnChange} isAdmin={isAdmin} showAddConditionalField={showAddConditionalField && !corporate}/>

                      { _.map(conditionalFields, (conditionalField, i) => (
                        <Card key={i} className="tealCard shadow-sm mt-3 mx-2">
                          <Card.Header className="greyCardHeader">
                            <div className="d-flex align-items-center justify-content-between">
                              <div>
                                <Badge bg="secondary" className="me-2">CONDITIONAL</Badge>
                                {conditionalField.title}
                              </div>
                              <Button className="mt-auto px-2 py-0" onClick={() => this.handleRemoveFieldConditional(i)} variant="danger" size="small">
                                <FontAwesomeIcon icon={solid('close')}/>
                              </Button>
                            </div>
                            <hr className="my-2"/>
                            <Row>
                              <Col xs={12} sm={3} className="d-flex align-items-center">
                                <label>When Option Is:</label>
                              </Col>
                              <Col xs={12} sm={9}>
                                <Select
                                  value={conditionalField.selected || []}
                                  isMulti
                                  onChange={(selected) => {
                                    conditionalFields[i].selected = selected
                                    this.setState({ conditionalFields })
                                  }}
                                  required={true}
                                  options={conditionalField.valueOptions.map(option => {
                                    let value = option.value.split('#')[0]
                                    return {label: value, value: value}
                                  })}
                                />
                              </Col>
                            </Row>
                          </Card.Header>
                          <Card.Body className="d-grid gap-2 label-strong grey-label">
                            <FieldCreate tagType={conditionalField.tagType} index={i} values={conditionalField} onChange={this.handleOnChangeConditionalField} handleAddOption={this.handleAddOptionConditional} handleEditOptions={this.handleEditOptionsConditional} showAddConditionalField={false} handleRemoveOption={this.handleRemoveOptionConditional} isAdmin={isAdmin}/>
                          </Card.Body>
                        </Card>
                      ))}

                      <Button className="mt-4 pull-right w-100 shadow-sm" variant="primary" type="submit">SUBMIT</Button>
                    </Card.Body>
                  </Card>
                </form>
              </div>
          }
        </Modal.Body>
      </Modal>
    )
  }
}

const fStyle = {
  container: {
    position: 'relative',
    padding: '1.2rem',
    background: '#fff',
    margin: '1rem 0',
    height: '200px',
    borderRadius: '6px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 15%)',
    color: '#80d7cf',
    cursor: 'pointer'
  },
  image: {
    maxWidth: '100px',
    width: 'auto',
    height: 'auto',
    margin: 'auto 0',
    maxHeight: '80px',
  },
  imgBox:{
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    textAlign: 'center',
    margin: '0',
    fontSize: '18px'
  },
  conditional: {
    position: 'absolute',
    bottom: '-15px',
    left: '0',
    right: '0',
  }
}

export default FieldsModal;
