import { useState } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';

const DeliveryFinePrintsModal = ({ onClose, deliveryFinePrints, onSaved }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [deliveryFinePrintsTemplate, setDeliveryFinePrintsTemplate] = useState(deliveryFinePrints);
    const [selectedTemplate, setSelectedTemplate] = useState(-1);

    const submit = () => {

        setIsLoading(true);
        let deliveryFinePrints = deliveryFinePrintsTemplate[selectedTemplate].content;
        onSaved(deliveryFinePrints, deliveryFinePrintsTemplate[selectedTemplate]._id);
    }

    const selectRowProp = {
        mode: 'radio',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            setSelectedTemplate(rowIndex);
        },
        headerColumnStyle: { width: '30px' }
    };
  
    const ordersCol = [
        {
            dataField: 'name',
            text: 'Name',
            headerStyle: {
            width: '120px'
            },
        },
        {
            dataField: 'content',
            text: 'Content',
            formatter: (cell, row) => {
                return <div style={{ maxHeight:"500px", overflowY:"auto", overflowX:"hidden" }} dangerouslySetInnerHTML={{__html: cell}}></div>
            }
        }
    ]

    return (
        <Modal show={true} onHide={onClose} size="lg">
            <Modal.Header className="mx-2 mt-1" style={{ borderBottom: 'none' }} closeButton>
                <h4 className="mb-0">Delivery Fine Prints</h4>
            </Modal.Header>
            <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
                <p>
                    <Button
                    bsStyle="primary"
                    onClick={() => window.open(`/delivery_templates`, '_blank')}
                    >Edit Delivery Fine Prints</Button>
                </p>
                <div style={ {"fontFamily":"sans-serif"}}>
                    <BootstrapTable
                        bordered={ true }
                        hover
                        keyField='_id'
                        data={deliveryFinePrintsTemplate}
                        selectRow={selectRowProp}
                        columns={ordersCol}
                        noDataIndication={() => 'No Delivery Fine Prints'}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="pt-0" style={{ borderTop: 'none' }}>
                <Button variant="danger-secondary" onClick={onClose}>Cancel</Button>
                <Button variant="primary" onClick={submit} disabled={selectedTemplate<0 || isLoading}>{isLoading ? 'Importing...' : 'Select'}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeliveryFinePrintsModal;