import { Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useDrag, useDrop } from 'react-dnd'
import { useEffect, useRef } from 'react'

const TaggingRow = ({ index, tag, tagsToRemove, removeMode, reorderMode, addTagToRemove, moveTag }) => {
  if (reorderMode) {
    return (
      <DraggableRow
        index={index}
        tag={tag}
        moveTag={moveTag}
      />
    )
  }

  return (
    <NormalRow
      tag={tag}
      tagsToRemove={tagsToRemove}
      removeMode={removeMode}
      addTagToRemove={addTagToRemove}
    />
  )
}

const NormalRow = ({ tag, tagsToRemove, removeMode, addTagToRemove }) => {
  return (
    <Row className="tagging-item-row mx-2 align-items-center justify-content-center">
      { removeMode &&
      <Col xs={2} className='justify-content-center d-flex'>
        <Button variant="danger" className='px-2 py-0' onClick={() => addTagToRemove(tag.tag)}><FontAwesomeIcon icon={solid('close')}/></Button>
      </Col>
      }
      <Col className="my-2 tagging-item" style={tagsToRemove && tagsToRemove.includes(tag.tag) ? {backgroundColor: '#ff8e8e'} : {}}>
        <p className="mb-0">Tag: {tag.tag}</p>
        <p className="mb-0">Label: {tag.desc}</p>
      </Col>
    </Row>
  )
}

const DraggableRow = ({ index, tag, moveTag }) => {
  const ref = useRef(null)
  const [{ isDragging }, drag] = useDrag({
    type: 'tag',
    item: { tag, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })

  const [{ handlerId }, drop] = useDrop({
    accept: 'tag',
    collect: monitor => ({
      handlerId: monitor.getHandlerId()
    }),
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveTag(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <Row ref={ref} style={{opacity}} data-handler-id={handlerId} className="tagging-item-row mx-2 align-items-center justify-content-center border mb-1 cursor-move">
      <Col xs={1} className='justify-content-center d-flex gap-2'>
        <div className="text-secondary d-flex align-items-center">
          <FontAwesomeIcon icon={solid('grip-vertical')}/>
        </div>
      </Col>
      <Col className="my-2 tagging-item">
        <p className="mb-0">Tag: {tag.tag}</p>
        <p className="mb-0">Label: {tag.desc}</p>
      </Col>
    </Row>
  )
}

export default TaggingRow
