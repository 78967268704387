import { useState, useEffect } from 'react'
import { Col, Button, Accordion } from 'react-bootstrap';
import _ from 'lodash'
import api from '../helpers/apiClient'
import TaggingRow from './TaggingRow'
import update from 'immutability-helper'
import TaggingToolBar from './TaggingToolBar';

const TaggingGroup = ({ title, defaultTags, fetchTaggings }) => {
  const [removeMode, setRemoveMode] = useState(false)
  const [reorderMode, setReorderMode] = useState(false)
  const [tagsToRemove, setTagsToRemove] = useState([])
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState([])

  useEffect(() => {
    setTags(_.sortBy(defaultTags, "seq"))
  }, [])

  const moveTag = (dragIndex, hoverIndex) => {
    setTags(prev => {
      return [...update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex]]
        ]
      })]
    })
  }

  const addTagToRemove = (tag) => {
    if (tagsToRemove.includes(tag)) {
      setTagsToRemove(tagsToRemove.filter(t => t !== tag))
      return
    }
    setTagsToRemove([...tagsToRemove, tag])
  }

  const handleCreateTagging = (e) => {
    e.preventDefault();

    const { id } = e.target

    const group = _.split(id, '_')[1]

    const inputs = e.target.getElementsByTagName('input')
    const tag = inputs[0].value
    const desc = inputs[1].value

    if(group && tag && desc){
      setLoading(true)

      _.each(inputs, input => input.value = '');

      submitTagging(tag, desc, group);
    }
  }

  const submitTagging = async (tag, desc, group) => {
    return api(`/product/taggings`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tag,
        desc,
        group
      })
    }).then(result => {
      if(result.success){
        fetchTaggings();
      }
    })
  }

  const handleRemoveTags = async () => {
    for(let i = 0; i < tagsToRemove.length; i++){
      const tag = tagsToRemove[i];

      await api(`/product/taggings`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          tag
        })
      })
    }

    setRemoveMode(false)
    setTagsToRemove([])

    fetchTaggings()
  }

  const cancelRemoveTags = () => {
    setRemoveMode(false)
    setTagsToRemove([])
  }

  const cancelReorderTags = () => {
    setReorderMode(false)
    setTags(_.sortBy(defaultTags, "seq"))
  }

  const handleReorderTags = async () => {
    let reorderTags = [...tags]
    for(let i = 0; i < reorderTags.length; i++){
      reorderTags[i].seq = i
    }

    await api(`/product/taggings/reorder`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(reorderTags)
    })

    setReorderMode(false)
    fetchTaggings()
  }

  const handleReorderMode = () => {
    setReorderMode(true)
  }

  return (
    <Col sm={6}>
      <Accordion key={title} className="taggingsCard mb-4">
        <Accordion.Item eventKey="0" className="tealCard shadow-sm">
          <Accordion.Header className="tealCardHeader">{title}</Accordion.Header>
          <Accordion.Body>
            <>
              <TaggingToolBar
                removeMode={removeMode}
                reorderMode={reorderMode}
                handleRemoveTags={handleRemoveTags}
                cancelRemoveTags={cancelRemoveTags}
                handleReorderTags={handleReorderTags}
                cancelReorderTags={cancelReorderTags}
                handleReorderMode={handleReorderMode}
                setRemoveMode={setRemoveMode}
              />
              <div className="tagging-list px-0">
                {
                  _.map(tags, (tag, i) => {
                    return (
                      <TaggingRow
                        key={i}
                        index={i}
                        tag={tag}
                        tagsToRemove={tagsToRemove}
                        removeMode={removeMode}
                        addTagToRemove={addTagToRemove}
                        reorderMode={reorderMode}
                        moveTag={moveTag}
                      />
                    )
                  })
                }
              </div>
              <TaggingToolBar
                removeMode={removeMode}
                reorderMode={reorderMode}
                handleRemoveTags={handleRemoveTags}
                cancelRemoveTags={cancelRemoveTags}
                handleReorderTags={handleReorderTags}
                cancelReorderTags={cancelReorderTags}
                handleReorderMode={handleReorderMode}
                setRemoveMode={setRemoveMode}
              />
              <form id={`newtag_${title}`} onSubmit={handleCreateTagging} className="mx-2 my-4 d-grid gap-2 grey-label label-strong">
                <div className="d-grid gap-1">
                  <label htmlFor={`new_tag_${title}`}>New Tag</label>
                  <input className="form-control" type="text" name={`new_tag_${title}`} placeholder="Tag name" required/>
                </div>
                <div className="d-grid gap-1">
                  <label htmlFor={`new_tag_label_${title}`}>Label</label>
                  <input className="form-control" type="text" name={`new_tag_label_${title}`} placeholder="Label" required/>
                </div>
                <Button className="btn-br-6 mt-3 mx-auto" style={{width: '50%'}} type="submit" disabled={loading}>Add</Button>
              </form>
            </>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Col>

  )
}

export default TaggingGroup
