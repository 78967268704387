import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import api from '../helpers/apiClient';
import * as usersActionCreators from '../redux/actions/users';
import _ from 'lodash';

class ReviewerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewer: ''
    }
  }

  componentDidMount(){
    const { fetchUsers } = this.props;

    fetchUsers();
  }

  handleOnClose = () => {
    const { onClose } = this.props;

    onClose();
  }

  handleSelectReviewer = async () => {
    const { products, onClose, reviewerSelected } = this.props;
    const { reviewer } = this.state;

    await api(`/pending_products/assign/reviewer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids: _.map(products, product => product.id),
        reviewer
      })
    }).then(result => {

      return new Promise(resolve => setTimeout(resolve, 500));
    }).then(result => {
      
      reviewerSelected();
    }).catch(err => {
      onClose();
    })
  }

  handleOnChange = e => {
    this.setState({ reviewer: e.target.value })
  }

  render() {
    const { users: { items: userList }, products } = this.props;
    const { reviewer } = this.state;

    const helprUsers = _.filter(userList, user => _.includes(user.email, '@helprnow.com') && user.name)
    const helprNames = _.map(helprUsers, user => {
      if(user.name){
        return user.name
      }
    })

    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0">Assign Reviewer</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4 d-grid gap-2" style={{borderTop: '1px solid #dee2e6'}}>
          <ul>
            {
              _.map(products, product => {
                return (
                  <li key={product.id}>{product.id} - {product.title}</li>
                )
              })
            }
          </ul>
          <label htmlFor="reviewer">Assign reviewer for products: </label>
          <Form.Select name="reviewer" className="form-control" value={reviewer} onChange={this.handleOnChange}>
            <option value="">Please select user</option>
            {
              _.map(helprNames, name => {
                return (
                  <option value={name} key={name}>
                    {name}
                  </option>
                )
              })
            }
          </Form.Select>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="primary" onClick={this.handleSelectReviewer} disabled={!reviewer}>
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect((state, props) => {
  return {
    users: state.users
  }
}, {
  ...usersActionCreators
})(ReviewerModal);
