import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash'
import moment from 'moment';
import { Button, Alert, Badge, Container, Row, Col } from 'react-bootstrap';
import ConfirmModal from '../components/ConfirmModal';
import Loader from '../components/Loader';
import api from '../helpers/apiClient';
import * as merchantRatesActionCreators from '../redux/actions/merchantRates';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import TabHeader from '../components/TabHeader';

const { SearchBar } = Search;

class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      reviews: [],
      rating: null
    };
  }

  componentDidMount() {
    this.fetchReviews()
  }

  fetchReviews = () => {
    return api(`/reviews`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((result) => {
      this.setState({ loading: false });

      if(result.reviews){
        this.setState({ reviews: result.reviews });
      }

      if(result.rating){
        this.setState({ rating: result.rating });
      }
    }).catch(err => {
      this.setState({ loading: false });
      if (err) {
        window.scrollTo(0, 0);
        if (err.validationError) {
          this.setState({ error: err.validationError });
        }
        else if (err.message) {
          this.setState({ error: err.message });
        }
        else {
          this.setState({ error: err });
        }
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { location: prevLocation } = this.props;
    const { location, history } = nextProps;
    if (location.state && location.state.reload != null &&
      history.action !== 'POP' &&
      location.state !== prevLocation.state) {
      this.setState({ reload: location.state.reload });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { reload: prevReload } = this.state;
    const { reload } = nextState;
    if (reload && reload !== prevReload) {
      const { location } = nextProps;
      this.fetchMerchantRates();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { reload: prevReload } = prevState;
    const { reload } = this.state;
    if (reload && reload !== prevReload) {
      this.setState({ reload: false });
    }
  }

  renderProductId = (cell, row) => {

    return (
      <a href={row.productUrl} target="_blank" >
        {row.productTitle}
      </a>
    )
  }

  renderDate = (cell, row) => {

    return (
      <span>
        {moment(cell).format('DD/MM/YYYY')}
      </span>
    )
  }

  dateSort = (a, b, order) => {

    let date1 = a.dateCreated
    let date2 = b.dateCreated

    if(order === 'desc'){
      return moment(date2).unix() - moment(date1).unix()
    } else {
      return moment(date1).unix() - moment(date2).unix()
    }
  }

  renderReview = (row) => {

    let images = []
    if(row.imagesFileName){
      const splits = _.split(row.imagesFileName, ',')
      _.each(splits, (split) => {
        images.push(`http://s3-us-west-2.amazonaws.com/stamped.io/uploads/photos/thumb/${split}`)
      })
    }

    return <div className="container-fluid" style={{color: '#5A5A5A'}}>
          <div>
            {images.map(image => {
              return (
                <img className="review-image" key={image} src={image}/>
              )
            })}
          </div>
          <strong style={{color: '#333', marginRight: '1em'}}>{row.title} </strong>({row.email})<br/>
          <p className="review-body">{row.body}</p>
          {row.reply && <div className="reply-body">
              <strong>Reply</strong><br/>
              <p className="review-body">{row.reply}</p>
            </div>}
      </div>
  }

  renderReplied = (cell, row) => {
    if(row.reply){
      return <Badge bg="success" className="text-uppercase">Yes</Badge>
    } else {
      return <Badge bg="secondary" className="text-uppercase">No</Badge>
    }
  }

  render() {
    const { user } = this.props;
    const { error, loading, reviews, rating } = this.state;

    const isAdmin = user && (user.role === 'admin' || user.role === 'staff')

    const expandRow = {
      renderer: this.renderReview
    }

    const options = {
      showTotal: true,
      sizePerPage: 30,
      hideSizePerPage: true,
    };

    const columns = [
      {
        dataField: 'dateCreated',
        text: 'Date',
        formatter: this.renderDate,
        sort: true,
        sortFunc: this.dateSort,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        }
      },
      {
        dataField: 'id',
        text: 'Review Id',
        hidden: true,
        sort: true,
      },
      {
        dataField: 'productId',
        sort: true,
        formatter: this.renderProductId,
        text: 'Product',
        headerStyle: {
          width: '200px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'reviewRating',
        text: 'Rating',
        headerStyle: {
          width: '70px',
          resize: 'horizontal'
        },
        sort: true
      },
      {
        dataField: 'title',
        text: 'Title',
        headerStyle: {
          width: '200px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'author',
        text: 'Author',
        headerStyle: {
          width: '200px',
          resize: 'horizontal'
        },
      },
      {
        dataField: '',
        isDummyField: true,
        formatter: this.renderReplied,
        text: 'Replied',
        sort: true,
        headerStyle: {
          width: '80px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'vendor',
        hidden: !isAdmin,
        sort: true,
        text: 'Vendor',
        headerStyle: {
          width: '200px',
          resize: 'horizontal'
        },
      },
    ];

    return (
      <div>
        <TabHeader
          title="Reviews"
          tab_id="reviews"
          user={user}
          children={
            <>
              { rating &&
                <h5 className="sub-header">Rating score: {rating.toFixed(2)}/5 <FontAwesomeIcon icon={solid('star')} style={{color: '#80d7cf'}}/></h5>
              }
            </>
          }
        />
        {
          loading &&
          <Loader />
        }
        {
          error &&
          <Alert variant="danger">
            {
              Array.isArray(error) ?
              <ul>
              {
                error.map((err, i) => (
                  <li key={i}>
                    {err.msg}
                  </li>
                ))
              }
              </ul>
              :
              error
            }
          </Alert>
        }
        {
          !loading && reviews &&
          <div className="reviews-table">
            <ToolkitProvider
              keyField='id'
              data={reviews}
              columns={columns}
              search
            >
              {
                props => (
                  <>
                   <Container fluid className="mb-3 mt-4 d-flex gap-3 justify-content-end flex-row flex-wrap">
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps } style={{borderRadius:'25px'}} />
                      </Col>
                    </Container>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      pagination={paginationFactory(options)}
                      hover
                      expandRow={expandRow}
                    />
                  </>
                )
              }
            </ToolkitProvider>
          </div>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    user: state.auth.user,
  };
}, merchantRatesActionCreators)(Reviews);
