import React, { Component } from 'react';
import TabHeader from '../components/TabHeader';
import { connect } from 'react-redux';
import api from '../helpers/apiClient';
import Loader from '../components/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Col, Container, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

class ProcurementBillings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      bills: [],
    }
  }

  componentDidMount() {
    this.fetchBills();
  }

  fetchBills = () => {
    return api(`/procurement_bills`).then(result => {
      this.setState({ loading: false })
      if (result.bills) {
        this.setState({ bills: result.bills })
      }
    }).catch(err => {
      console.log(err)
      this.setState({ loading: false })
    })
  }

  renderDetailLink = (cell, row) => {

    let pathname = `/procurement_bills/${row._id}`

    return (
      <Link
        key={row.id}
        to={{
          pathname,
          state: {modal: false}
        }}
      >
        {row.name}
      </Link>
    )
  }

  renderStatus = (cell, row) => {

    return row.status === 'paid' ?
      <Badge bg="success" className="text-uppercase">{row.status}</Badge>
      :
      <Badge bg="warning" className="text-uppercase">{row.status}</Badge>
  }

  renderPrice = (cell, row) => {

    return <span>{currency} {cell ? cell.toFixed(2) : "0.00"}</span>
  }

  renderDiscount = (cell, row) => {

    return <span>{currency} {row.total_discount.toFixed(2)}</span>
  }

  renderCommission = (cell, row) => {

    return <span>{currency} {row.commission.toFixed(2)}</span>
  }

  renderGst = (cell, row) => {

    return <span>{currency} {row.gst.toFixed(2)}</span>
  }

  renderShipping = (cell, row) => {

    return <span>{currency} {row.total_shipping.toFixed(2)}</span>
  }

  renderInvoiceNumber = (cell, row) => {

    return (
      <a
        target="_blank"
        href={row.invoice_link}>
        {cell}
      </a>
    )
  }

  render() {
    const { user } = this.props
    const { loading, bills } = this.state

    const isAdmin = user.role === 'admin'
    const isStaff = user.role === 'staff'

    let unselectableRows = []
    for(let i = 0; i < bills.length; i++) {

      let bill = bills[i]
      if(bill.status !== 'unbilled' && bill.status !== 'billed'){
        unselectableRows.push(bill._id)
      }
    }

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      nonSelectable: unselectableRows,
      headerColumnStyle: { width: '30px' },
    }

    const options = {
      showTotal: true,
      sizePerPage: 20,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: bills.length
      }],
    };

    const columns = [
      {
        dataField: '_id',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        csvText: 'name',
        csvFormatter: this.exportDetailLink,
        formatter: this.renderDetailLink,
        text: 'Bill',
      },
      {
        dataField: 'name',
        hidden: true,
        text: 'Name',
        headerStyle: {
          resize: 'horizontal'
        },
        csvExport: false,
      },
      {
        dataField: 'status',
        sort: true,
        formatter: this.renderStatus,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Status',
      },
      {
        dataField: 'vendor',
        sort: true,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Merchant',
      },
      {
        dataField: 'item_price',
        sort: true,
        text: 'Total Item Price',
        formatter: this.renderPrice,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'order_count',
        sort: true,
        text: 'No. of Orders',
        hidden: !isAdmin,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'total_discount',
        sort: true,
        formatter: this.renderDiscount,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'Total Discount',
      },
      {
        dataField: 'commission',
        sort: true,
        text: 'Total Commission',
        formatter: this.renderCommission,
        csvFormatter: this.exportCommission,
        headerStyle: {
          width: '140px',
          resize: 'horizontal'
        },
        headerFormatter: () => {
          return (
            <>
              <span data-tip="Included Commission Adjust. (if any)">Total Commission <i className="fa fa-info-circle"></i></span><ReactTooltip />
            </>
          )
        }
      },
      {
        dataField: 'gst',
        sort: true,
        formatter: this.renderGst,
        csvFormatter: this.exportTax,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Total SST',
        headerFormatter: () => {
          return (
            <>
              <span data-tip="Included SST Adjust. (if any)">Total SST <i className="fa fa-info-circle"></i></span><ReactTooltip />
            </>
          )
        }
      },
      {
        dataField: 'total_shipping',
        sort: true,
        formatter: this.renderShipping,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'Total Shipping',
      },
      {
        dataField: 'balance',
        sort: true,
        formatter: this.renderPrice,
        headerStyle: {
          width: '125px',
          resize: 'horizontal'
        },
        text: 'Total Payout Bal.',
      },
      {
        dataField: 'invoice_number',
        formatter: this.renderInvoiceNumber,
        sort: true,
        headerStyle: {
          width: '135px',
          resize: 'horizontal'
        },
        text: 'Invoice Number',
      },
      {
        dataField: 'invoice_link',
        hidden: true,
        sort: true,
        text: 'Invoice Link',
      },
      {
        dataField: 'bank_name',
        hidden: true,
        sort: true,
        text: 'Bank Name',
      },
      {
        dataField: 'acc_no',
        hidden: true,
        sort: true,
        text: 'Account Number',
      },
      {
        dataField: 'acc_name',
        hidden: true,
        sort: true,
        text: 'Account Name',
      },
      {
        dataField: 'swift_code',
        hidden: true,
        sort: true,
        text: 'Swift Code',
      },
      {
        dataField: 'email',
        hidden: true,
        sort: true,
        text: 'Email',
      },
    ];

    return (
      <>
        <TabHeader
          title="Procurement Billings"
          tab_id="procurement_billings"
          user={user}
        />
        {
          loading &&
          <Loader />
        }
        {
          !loading && bills.length > 0 &&
            <ToolkitProvider
              keyField='_id'
              data={bills}
              columns={columns}
              exportCSV={{
                exportAll: false
              }}
              search
            >
              {
                props => (
                  <>
                    <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                      <Col>
                        { !isStaff &&
                        <ExportCSVButton
                          className="btn-info"
                          style={{borderRadius:'8px'}}
                          { ...props.csvProps }
                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                          Export to CSV</ExportCSVButton>
                        }
                      </Col>
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps }
                        style={{borderRadius:'25px'}} placeholder="Search"/>
                      </Col>
                    </Container>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      selectRow={selectRowProp}
                      pagination={paginationFactory(options)}
                      hover
                      defaultSorted={[{
                        dataField: '_id',
                          order: 'desc',
                      }]}
                    />
                  </>
                )
              }
            </ToolkitProvider>
        }
      </>
    )
  }
}

export default connect((state, props) => {
  return {
    user: state.auth.user
  }
})(ProcurementBillings);