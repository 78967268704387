import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash'
import moment from 'moment';
import { Tabs, Tab, Button, Col, Container, Accordion } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loader from '../components/Loader';
import * as logsActionCreators from '../redux/actions/syncErrorLogs';
import ReactTooltip from 'react-tooltip';
import { DateRangePicker } from "react-dates";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

class SyncErrorLogs extends Component {
  constructor(props) {
    super(props);
    const { location } = props;

    let logPeriodStart = moment().subtract(1, 'months')
    let logPeriodEnd = moment()

    if (location.search) {
      const queryString = qs.parse(location.search.substr(1));

      if (queryString.log_period_start) {
        logPeriodStart = moment(queryString.log_period_start, "D-M-YY");
      }
      if (queryString.log_period_end) {
        logPeriodEnd = moment(queryString.log_period_end, "D-M-YY");
      }
    }

    this.state = {
      logPeriodStart,
      logPeriodEnd,
    };
  }

  componentDidMount() {
    const { fetchLogs, location } = this.props;
    const { logPeriodStart, logPeriodEnd } = this.state;

    const {
      limit,
      vendor,
      message,
      log_period_start,
      log_period_end
    } = qs.parse(location.search.substr(1));

    fetchLogs(
      limit,
      vendor,
      message,
      log_period_start ? log_period_start : logPeriodStart.format('D-M-YY'),
      log_period_end ? log_period_end : logPeriodEnd.format('D-M-YY'),
    );
  }

  componentWillReceiveProps(nextProps) {
    const { location: prevLocation } = this.props;
    const { logPeriodStart, logPeriodEnd } = this.state;
    const { fetchLogs, location } = nextProps;
    if (location.search !== prevLocation.search) {
      const {
        limit,
        vendor,
        message,
        log_period_start,
        log_period_end
      } = qs.parse(location.search.substr(1));

      fetchLogs(
        limit,
        vendor,
        message,
        log_period_start ? log_period_start : logPeriodStart.format('D-M-YY'),
        log_period_end ? log_period_end : logPeriodEnd.format('D-M-YY'),
      );
    }
  }

  handleResetFilter = () => {
    this.setState({
      logPeriodStart: moment().subtract(2, 'months'),
      logPeriodEnd: moment()
    });
  }

  handleOnClickFilter = () => {
    const {
      history,
      location: { pathname },
    } = this.props;
    const {
      logPeriodStart,
      logPeriodEnd,
    } = this.state;

    let logPeriodStartShortFormat, logPeriodEndShortFormat
    if (logPeriodStart) {
      logPeriodStartShortFormat = logPeriodStart.format("D-M-YY");
    }

    if(logPeriodEnd){
      logPeriodEndShortFormat = logPeriodEnd.format("D-M-YY");
    }

    const queryString = qs.stringify({
      log_period_start: logPeriodStartShortFormat,
      log_period_end: logPeriodEndShortFormat
    });
    history.push(`${pathname}?${queryString}`);
  }

  renderExpandedNoteColumn = (row) => {
    return <div>{row.note}</div>
  }

  renderTime = (cell, row) => {
    if(cell){
      return `${moment.duration(cell, 'seconds').asDays().toFixed(1)} days`
    }
  }

  renderCSVTime = (cell, row) => {
    return cell ? moment.duration(cell, 'seconds').asDays().toFixed(1) : ''
  }

  customCSVBtn = (onClick) => {
    return (
      <ExportCSVButton
        className="btn-info"
        style={{borderRadius:'8px'}}
        onClick={ () => onClick()}
      ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
        Export to CSV</ExportCSVButton>
    )
  }

  dateSort = (date1, date2, order) => {

    if (!date1) {
      date1 = moment("01/01/2010", "DD/MM/YYYY");
    }

    if (!date2) {
      date2 = moment("01/01/2010", "DD/MM/YYYY");
    }

    if (order === "desc") {
      return (
        moment(date2).unix() - moment(date1).unix()
      );
    } else {
      return (
        moment(date1).unix() - moment(date2).unix()
      );
    }
  };

  render() {
    const { user, logs: { loading, loaded, items } } = this.props;

    console.log(items)

    const options = {
      showTotal: true,
      sizePerPage: 20,
    };

    const columns = [
      {
        dataField: 'id',
        hidden: true,
        text: 'Id',
      },
      {
        dataField: 'created',
        sort: true,
        formatter: (cell, row) => {
          return moment(cell).format('YYYY-MM-DD h:mm a')
        },
        text: 'Date',
        headerStyle: {
          width: '200px',
          resize:'horizontal'
        },
        sortFunc: this.dateSort
      },
      {
        dataField: 'vendor',
        headerStyle: {
          width: '150px',
          resize:'horizontal'
        },
        text: 'Vendor',
        sort: true,
      },
      {
        dataField: 'provider',
        headerStyle: {
          width: '150px',
          resize:'horizontal'
        },
        text: 'Provider',
        sort: true,
      },
      {
        dataField: 'product_id',
        headerStyle: {
          width: '130px',
          resize:'horizontal'
        },
        text: 'Product Id',
        formatter: (cell, row) => {
          return <a href={`/products/${cell}`} target="_blank">{cell}</a>
        },
      },
      {
        dataField: 'source_url',
        headerStyle: {
          width: '130px',
          resize:'horizontal'
        },
        text: 'Source Url',
        formatter: (cell, row) => {
          if(cell){
            return <a href={cell} target="_blank">{cell}</a>
          }
        },
      },
      {
        dataField: 'message',
        text: 'Message',
        headerStyle: {
          width: '250px',
          resize:'horizontal'
        }
      }
    ];

    return (
      <div>
        <div className="row mt-3 mb-4">
          <div className="col-xs-12 col-md-9">
            <h2 className="sub-header">Sync Error Log</h2>
          </div>
        </div>
        <Accordion style={{borderRadius: '18px'}} className="dashboard-card shadow-sm mt-4" defaultActiveKey="0">
        <Accordion.Item style={{borderRadius: '18px', border: 'none'}} eventKey="0">
          <Accordion.Header><h5 style={{marginBottom: '0'}}>Filters</h5></Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-sm-9">
                <div className="row">
                  <div className="col-xs-12 col-sm-10 col-xl-6 mb-3 mb-sm-3">
                    <label style={{marginBottom: '5px'}}>Log Period</label>
                    <DateRangePicker
                      small
                      minimumNights={0}
                      displayFormat="D/M/YY"
                      showClearDates={false}
                      showDefaultInputIcon={true}
                      isOutsideRange={() => false}
                      startDate={this.state.logPeriodStart} // momentPropTypes.momentObj or null,
                      endDate={this.state.logPeriodEnd} // momentPropTypes.momentObj or null,
                      startDateId="log-dates-start"
                      endDateId="log-dates-end"
                      onDatesChange={({ startDate, endDate }) => {
                        this.setState({ logPeriodStart: startDate, logPeriodEnd: endDate })
                      }
                      } // PropTypes.func.isRequired,
                      focusedInput={this.state.focusedInput1} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={(focusedInput1) =>
                        this.setState({ focusedInput1 })
                      } // PropTypes.func.isRequired,
                    />
                  </div>
                </div>
              </div>

              <div className="filter-buttons col-sm-3 d-flex flex-sm-column flex-lg-row justify-content-between my-2 mb-auto">
              <Button
                  className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                  variant="secondary"
                  style={{width: '100%'}}
                  onClick={this.handleResetFilter}
                >Reset
                </Button>
              <Button
                  className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                  variant="primary"
                  style={{width: '100%'}}
                  onClick={this.handleOnClickFilter}
                >Apply
                </Button>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
          {
            loading &&
              <Loader />
          }
          {
            !loading && items &&
            <ToolkitProvider
            keyField='created'
            data={items}
            columns={columns}
            exportCSV={{
              exportAll: true
            }}
            search
          >
            {
              props => (
                <>
                <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                    <Col>
                      <ExportCSVButton
                        className="btn-info"
                        style={{borderRadius:'8px'}}
                        { ...props.csvProps }
                      ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                        Export to CSV</ExportCSVButton>
                    </Col>
                    <Col xs={4}>
                      <SearchBar { ...props.searchProps }
                      style={{borderRadius:'25px'}} placeholder="Search"/>
                    </Col>
                  </Container>

                  <BootstrapTable
                  { ...props.baseProps }
                    bordered={false}
                    pagination={paginationFactory(options)}
                    hover expandableRow={() => true}
                    options={{
                      expandBy: "column",
                      sizePerPage: 20,
                      paginationShowsTotal: true,
                      sortName: "created",
                      sortOrder: "desc",
                    }}
                    expandComponent={this.renderExpandedNoteColumn}
                    defaultSorted={[{
                      dataField: 'created',
                        order: 'desc',
                    }]}
                  >

                  </BootstrapTable>
              </>
              )
            }
          </ToolkitProvider>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  return {
    user: state.auth.user,
    logs: state.syncErrorLogs
  }
}, {...logsActionCreators})(SyncErrorLogs);

