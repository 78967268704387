import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import users from './users';
import user from './user';
import orders from './orders';
import orderDetail from './orderDetail';
import merchants from './merchants';
import merchantRates from './merchantRates';
import merchantRate from './merchantRate';
import shipments from './shipments';
import error from './error';
import shipping from './shipping';
import announcement from './announcement'
import profile from './profile';
import billings from './billings';
import billDetail from './billDetail';
import dashboard from './dashboard';
import manualOrderDetail from './manualOrderDetail';
import products from './products';
import productAddOns from './productAddOns';
import productBulkEdit from './productBulkEdit';
import productDetail from './productDetail';
import logs from './logs';
import syncErrorLogs from './syncErrorLogs';
import pendingProducts from './pendingProducts';
import taggings from './taggings';
import linkedStores from './linkedStores';
import notifications from './notifications';
import merchantsByCountry from './merchantsByCountry'
import merchantStore from './merchantStore';
import previewProduct from './previewProduct';
import customers from './customers';
import customer from './customer';
import quick_helps from './quickHelp';
import pickups from './pickups';
import deliveries from './deliveries';
import deliveryDetail from './deliveryDetail';
import titleDecorations from './titleDecorations';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  error,
  auth,
  users,
  user,
  orders,
  orderDetail,
  merchants,
  merchantsByCountry,
  shipping,
  profile,
  shipments,
  billings,
  billDetail,
  announcement,
  merchantRates,
  merchantRate,
  dashboard,
  form: formReducer,
  manualOrderDetail,
  products,
  productAddOns,
  productDetail,
  productBulkEdit,
  logs,
  syncErrorLogs,
  pendingProducts,
  taggings,
  linkedStores,
  notifications,
  merchantStore,
  previewProduct,
  customers,
  customer,
  quick_helps,
  pickups,
  deliveries,
  deliveryDetail,
  titleDecorations
});

export default rootReducer;
