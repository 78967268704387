import { useEffect, useState } from 'react';
import { Modal, Container, Col, Row, Badge, ToggleButton, ToggleButtonGroup, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/actions/products';
import Loader from './Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import api from '../helpers/apiClient';
import toast from 'react-hot-toast';
import ConfirmModal from './ConfirmModal';

const { SearchBar } = Search;

const LinkProductModal = (props) => {
  const { item, deliveryType, updateForm } = props;
  const { loading, loaded, items } = useSelector(state => state.products)
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [selecting, setSelecting] = useState(true);
  const [selected, setSelected] = useState({});
  const [status, setStatus] = useState('active');
  const [error, setError] = useState('');
  const [saved, setSaved] = useState(false);
  const [showConfirmUnlink, setShowConfirmUnlink] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    api(`/products/delivery/link/${item.id}`, {
      method: 'GET',
    }).then((product) => {
      if (product) {
        setIsEdit(true);
        setError('');
        setSaved(true);
        setSelecting(false);
        setSelected({
          id: product.id,
          title: product.title,
          image: product.image,
          handle: product.handle,
        })
      }
    })
  }, [])

  useEffect(() => {
    if (show && !loaded) {
      dispatch(fetchProducts(item.vendor, status));
    }
  }, [status, show, loaded])

  const showImage = (cell) => {
    if(cell && cell.length && cell[0]){
      return <img style={{height: '70px'}} src={cell[0].src}/>;
    }
  }

  const renderStatus = cell => {
    switch (cell){
      case 'active':
        return <Badge bg="success">ACTIVE</Badge>
      case 'draft':
        return <Badge bg="default">DRAFT</Badge>
      case 'pending':
        return <Badge bg="warning">PENDING</Badge>
      case 'disapproved':
        return <Badge bg="danger">REJECTED</Badge>
      case 'reviewing':
        return <Badge bg="primary">IN REVIEW</Badge>
      case 'more_info':
        return <Badge bg="info">MORE INFO</Badge>
      default:
        break;
    }
  }

  const renderDate = (cell) => {

    return cell ? moment(cell).format("DD/MM/YYYY") : ''
  }

  const onRowSelect = (row, isSelected, e) => {
    setSelecting(false);
    setSaved(false);

    let error = ''
    if (deliveryType === 'd2d' && row.template_suffix === 'door-to-door') {
      error = 'Product delivery should be nationwide'
    }
    if (deliveryType === 'courier' && row.template_suffix === 'courier') {
      error = 'Product delivery should be on-demand'
    }

    setError(error);
    setSubmitting(false);
    setSelected({
      id: row.id,
      title: row.title,
      image: row.images.length > 0 && row.images[0].src,
      handle: row.handle,
      tags: row.tags,
    })
  }

  const save = () => {
    setSubmitting(true);

    let body = {
      item,
      toLink: {
        id: selected.id,
        handle: selected.handle,
      },
    }

    api(`/products/delivery/link/${isEdit ? 'edit' : ''}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then((result) => {
      if (result.error) {
        return toast.error(result.error);
      }
      toast.success('Linked!', { position: 'top-center' });
      setSaved(true);
      setSubmitting(false);
      updateForm({ target: {
        name: 'delivery_link',
        value: `DLINKED_${selected.handle}`,
      }})
    })
  }

  const refresh = () => {
    setSubmitting(true);

    let body = {
      item,
      toLink: {
        id: selected.id,
        handle: selected.handle,
      },
      isRefresh: true,
    }

    api(`/products/delivery/link/edit`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    }).then((result) => {
      if (result.error) {
        return toast.error(result.error);
      }
      toast.success('Link refreshed', { position: 'top-center' });
      setSaved(true);
      setSubmitting(false);
      updateForm({ target: {
        name: 'delivery_link',
        value: `DLINK_${selected.handle}`,
      }})
    })
  }

  const unlink = () => {
    api(`/products/delivery/link/${item.id}`, {
      method: 'DELETE'
    }).then(() => {
      toast.success('Unlinked!', { position: 'top-center' });
      setSelecting(true);
      setShowConfirmUnlink(false);
      updateForm({ target: { name: 'delivery_link' } })
    })
  }

  const columns = [
    { dataField: 'id', text: 'Id', hidden: true, },
    { dataField: 'images', text: 'Image', headerStyle: { width: '100px', resize: 'horizontal' }, align: 'center', formatter: showImage },
    { dataField: 'title', headerStyle: { width: '400px', resize: 'horizontal' }, sort: true, text: 'Title' },
    { dataField: 'status', headerStyle: { width: '100px', resize: 'horizontal' }, text: 'Status', sort: true, formatter: renderStatus, },
    { dataField: 'product_type', text: 'Type', sort: true, headerStyle: { width: '150px', resize: 'horizontal' }, },
    { dataField: 'updated_at', text: 'Updated At', sort: true, formatter: renderDate, headerStyle: { width: '150px', resize: 'horizontal' }, },
    { dataField: 'created_at', text: 'Created At', sort: true, formatter: renderDate, headerStyle: { width: '150px', resize: 'horizontal' }, },
  ];

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
    headerColumnStyle: { width: '30px' },
  }

  const options = {
    showTotal: true,
    sizePerPage: 5,
    hideSizePerPage: true,
  };

  return (
    <>
      <Button className="btn-br-6" variant="secondary" onClick={() => setShow(true)}>
        Link Product
        { saved &&
          <FontAwesomeIcon icon={solid('circle-check')} style={{marginLeft: '8px'}}/>
        }
      </Button>
    <Modal style={{border: 'none'}} show={show} onHide={() => setShow(false)} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4 className="mb-0 text-capitalize">Link Product (Nationwide - On-Demand)</h4>
      </Modal.Header>
      <Modal.Body className="mx-4 px-0 pt-4 d-grid gap-4" style={{borderTop: '1px solid #dee2e6'}}>
        { !selecting &&
        <div className="d-grid gap-1 align-items-center mx-1 p-3" style={{gridTemplateColumns: "repeat(3, 1fr)", justifyItems: 'center'}}>
          <div className='text-center d-flex flex-column align-items-center gap-2'>
            { item.images.length > 0 &&
            <img style={{height: '70px'}} src={item.images[0].src}/>
            }
            <p className="m-0">{item.title}</p>
          </div>
          <div>
            <FontAwesomeIcon icon={solid('arrows-left-right')} size="lg"/>
          </div>
          { selected.id &&
          <div className='text-center d-flex flex-column align-items-center gap-2'>
            { selected.image &&
            <img style={{height: '70px'}} src={selected.image}/>
            }
            <a href={`/products/${selected.id}`} className="text-decoration-none text-black m-0">{selected.title}</a>
            { error &&
            <p className="m-0 text-danger small">{error}</p>
            }
            <Button size="sm" variant="outline-primary" onClick={() => setSelecting(true)}>Change</Button>
          </div>
          }
        </div>
        }
        { isEdit && !selecting &&
          <p className="m-0 small">If banner does not link properly, click <Button disabled={submitting} variant="link" size="sm" className="p-0" onClick={refresh}>here</Button> to refresh the link.</p>
        }
        {
          loading && selecting &&
            <Loader />
        }
        {
          !loading && items && selecting &&
            <ToolkitProvider
              keyField='id'
              data={items}
              columns={columns}
              exportCSV={{
                exportAll: true
              }}
              search={{
                searchFormatted: true
              }}
            >
              {
                props => (
                  <>
                    <Container fluid>
                      <h5>Select a Product</h5>
                      <Row>
                        <Col md={6}>
                          <ToggleButtonGroup size="sm" type="radio" name="status" defaultValue={status} onChange={(val) => setStatus(val)}>
                            <ToggleButton id="tbg-btn-1" variant="outline-primary" value="active">
                              Active
                            </ToggleButton>
                            <ToggleButton id="tbg-btn-2" variant="outline-primary" value="draft">
                              Draft
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Col>
                        <Col md={6}>
                          <SearchBar { ...props.searchProps }
                            style={{borderRadius:'25px'}} className="w-100" placeholder="Search"/>
                        </Col>
                      </Row>
                    </Container>

                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={false}
                      selectRow={selectRowProp}
                      pagination={paginationFactory(options)}
                      hover
                      options={{
                        expandBy: "column",
                        sizePerPage: 20,
                        paginationShowsTotal: true,
                        sortName: "created_at",
                        sortOrder: "desc"
                      }}
                    />
                  </>
                )
              }
            </ToolkitProvider>
        }
      </Modal.Body>
      { !selecting &&
      <Modal.Footer className="pt-0 justify-content-between mx-4 px-0" style={{borderTop: 'none'}}>
        <div>
          { saved &&
          <Button disabled={submitting} variant="outline-danger" onClick={() => setShowConfirmUnlink(true)}>Unlink</Button>
          }
        </div>
        { saved ?
          <Button onClick={() => setShow(false)} disabled={submitting}>Done</Button>
          :
          <Button onClick={save} disabled={error || submitting}>Save</Button>
        }
      </Modal.Footer>
      }
    </Modal>
        {
          showConfirmUnlink &&
          <ConfirmModal size="sm" contentClassName="shadow-lg" onClickYes={unlink} onClose={() => setShowConfirmUnlink(false)} title="Confirm Unlink?" />
        }
    </>
  );
}

export default LinkProductModal;
