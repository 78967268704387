import {
  FETCH_DELIVERIES_REQUEST,
  FETCH_DELIVERIES_SUCCESS,
  FETCH_DELIVERIES_FAILURE,
  CLEAR_DELIVERIES,
} from '../actions/deliveries';

const defaultState = {
  loading: false,
  loaded: false,
  items: []
};

export default function deliveries(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_DELIVERIES:
      return {
        ...state,
        defaultState
      };
    case FETCH_DELIVERIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_DELIVERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_DELIVERIES_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
