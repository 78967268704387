import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

const SetUnpublishModal = ({ onClose, tags, clearSchedule, save }) => {
  const [scheduleDraft, setScheduleDraft] = useState(null)
  const [scheduleDraftTime, setScheduleDraftTime] = useState('12AM')
  const [scheduleDraftFocused, setScheduleDraftFocused] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let tagsSplit = tags.split(', ')
    let foundUnpublish = tagsSplit.find(tag => _.startsWith(tag, 'UNPUB_'))
    console.log(foundUnpublish, tags)

    if (foundUnpublish) {
      let unpublishSplit = foundUnpublish.split('_')
      setScheduleDraft(moment(unpublishSplit[1], 'D-M-YY'))
      setScheduleDraftTime(unpublishSplit[2])
    }
  }, [tags])

  const scheduleIsOutsideRange = (date) => {

    let startDate = moment().startOf('day').add(1, 'days')
    let endDate = moment().endOf('day')
    endDate.add(60, 'days')

    return date.isBefore(startDate) || date.isAfter(endDate);
  }

  const saveSchedule = () => {
    setLoading(true)
    save(scheduleDraft, scheduleDraftTime)
  }

  return (
    <Modal show={true} onHide={onClose} size="lg">
      <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
        <h4 className="mb-0">Set Unpublish Date</h4>
      </Modal.Header>
      <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
        <p>Product will be unpublished/status changed to draft on the selected date and time.</p>
        <p>For example:</p>
        <ol>
          <li>If product is available until end of day 15th Jan 2024, unpublish date should be set to 16/01/2024 at 12AM</li>
          <li>If product is available until midday 15th Jan 2024, unpublish date should be set to 15/01/2024 at 12PM</li>
        </ol>
        <div className="schedule-draft d-flex align-items-center gap-2">
          <span>Date:</span>
          <SingleDatePicker
            numberOfMonths={1}
            isOutsideRange={scheduleIsOutsideRange}
            displayFormat="DD/MM/YYYY"
            small
            showClearDate
            date={scheduleDraft} // momentPropTypes.momentObj or null
            onDateChange={(date) => setScheduleDraft(date)} // PropTypes.func.isRequired
            focused={scheduleDraftFocused} // PropTypes.bool
            onFocusChange={({ focused }) => setScheduleDraftFocused(focused)} // PropTypes.func.isRequired
          />
          <span>Time:</span>
          <Form.Select className="form-control w-auto" name="schedule_draft_time" onChange={(e) => setScheduleDraftTime(e.target.value)} required value={scheduleDraftTime}>
            <option value="12AM">12AM</option>
            <option value="12PM">12PM</option>
          </Form.Select>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" onClick={onClose} disabled={loading}>Cancel</Button>
        <div className="d-flex gap-1">
          <Button variant="danger-secondary" onClick={clearSchedule} disabled={loading}>Clear</Button>
          <Button variant="primary" type="submit" disabled={loading} onClick={saveSchedule}>
            {loading ? "Loading..." : "Confirm"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default SetUnpublishModal;
