import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Nav, Accordion, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import logo from '../images/Giftr-Seller-Center-Logo.svg';

const country = window.location.hostname === 'seller.giftr.my' ? 'MY' : 'SG'

const isActiveRoute = (pathName, route) => {
  return pathName === route;
}

const Sidebar = ({ location: { pathname }, user, notifications: { open_count, pickup_reminder_count } }) => {
  return (
      <Nav className="nav-sidebar flex-column mb-0">
        <img src={logo} width="120" className="mx-auto" style={{marginBottom:'35px'}}/>
        {
          user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'supplier' || user.role === 'staff' || user.role === 'merchant-evouchers') &&
            <Nav.Link
              className={`${(isActiveRoute(pathname, '/dashboard') || isActiveRoute(pathname, '/')) && 'active'}`}
              as={Link}
              to='/dashboard'
            ><FontAwesomeIcon icon={solid('house')} style={{marginRight: '12px'}}/>
            Dashboard</Nav.Link>
        }
        {
          user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'supplier' || user.role === 'staff' || user.role === 'merchant-staff') &&
            <Nav.Link
              className={`${(isActiveRoute(pathname, '/orders')) && 'active'}`}
              as={Link}
              to='/orders'
            ><FontAwesomeIcon icon={solid('clipboard')} transform="grow-2" style={{margin: '0 15px 0 3px'}}/>
            Orders</Nav.Link>
        }
        {
          user && (user.role === 'admin' || user.role === 'staff' || user.allow_procurement) &&
            <Nav.Link
              className={`${(isActiveRoute(pathname, '/procurement_orders')) && 'active'}`}
              as={Link}
              to='/procurement_orders'
            ><FontAwesomeIcon icon={regular('clipboard')} transform="grow-2" style={{margin: '0 15px 0 3px'}}/>
            Procurement Orders</Nav.Link>
        }
        {
          user && (user.role === 'admin' || user.role === 'staff') &&
            <Accordion className="nav-accordion" defaultActiveKey="0">
              <Accordion.Item style={{border:'none'}} eventKey="0">
                <Accordion.Header>
                  <FontAwesomeIcon icon={solid('layer-group')} style={{margin: '0 12px 0 2px'}}/>
                  Products
                </Accordion.Header>
                <Accordion.Body className="pe-2" style={{marginLeft: '1.6rem'}}>
                  <Nav.Link
                    className={`${(isActiveRoute(pathname, '/products')) && 'active'}`}
                    as={Link}
                    to='/products'
                    style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                  >
                    Product Listing
                  </Nav.Link>
                  <Nav.Link
                    className={`${isActiveRoute(pathname, '/product_addons') && 'active'}`}
                    as={Link}
                    to="/product_addons"
                    style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                  >
                    Product Add-ons
                  </Nav.Link>
                  <Nav.Link
                    className={`${isActiveRoute(pathname, '/delivery_templates') && 'active'}`}
                    as={Link}
                    to="/delivery_templates"
                    style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                  >
                    Delivery Templates
                    <Badge className="mx-1" bg="danger">NEW</Badge>
                  </Nav.Link>
                  <Nav.Link
                    className={`${(isActiveRoute(pathname, '/products/pending')) && 'active'}`}
                    as={Link}
                    to='/products/pending'
                    style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                    >Pending Products
                  </Nav.Link>
                  <Nav.Link
                    className={`${(isActiveRoute(pathname, '/taggings/product')) && 'active'}`}
                    as={Link}
                    to='/taggings/product'
                    style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                    >Product Taggings
                  </Nav.Link>
                  <Nav.Link
                    className={`${(isActiveRoute(pathname, '/title_prefix')) && 'active'}`}
                    as={Link}
                    to='/title_prefix'
                    style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                    >Title Decoration
                    <Badge className="mx-1" bg="danger">NEW</Badge>
                  </Nav.Link>
                  <Nav.Link
                    className={`${(isActiveRoute(pathname, '/medias')) && 'active'}`}
                    as={Link}
                    to='/medias'
                    style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                    >Media Files
                  </Nav.Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

        }
        {
          ['merchant', 'merchant-staff'].includes(user.role) &&
          <Accordion className="nav-accordion" defaultActiveKey="0">
            <Accordion.Item style={{border:'none'}} eventKey="0">
              <Accordion.Header style={{color: '#80d7cf'}}>
              <FontAwesomeIcon icon={solid('layer-group')} style={{margin: '0 12px 0 2px'}}/>
                Products
              </Accordion.Header>
              <Accordion.Body className="pe-2" style={{marginLeft: '1.6rem'}}>
                <Nav.Link
                  className={`${(isActiveRoute(pathname, '/products')) && 'active'}`}
                  as={Link}
                  to='/products'
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                >
                  Product Listing
                </Nav.Link>
                <Nav.Link
                  className={`${isActiveRoute(pathname, '/product_addons') && 'active'}`}
                  as={Link}
                  to="/product_addons"
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                >
                  Product Add-ons
                </Nav.Link>
                <Nav.Link
                  className={`${isActiveRoute(pathname, '/delivery_templates') && 'active'}`}
                  as={Link}
                  to="/delivery_templates"
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                >
                  Delivery Templates
                  <Badge className="mx-1" bg="danger">NEW</Badge>
                </Nav.Link>
                <Nav.Link
                    className={`${(isActiveRoute(pathname, '/medias')) && 'active'}`}
                    as={Link}
                    to='/medias'
                    style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                    >Media Files
                    <Badge className="mx-1" bg="danger">NEW</Badge>
                </Nav.Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        }
        {
          user && (user.role === 'admin') &&
          <Accordion className="nav-accordion" defaultActiveKey="0">
            <Accordion.Item style={{border:'none'}} eventKey="0">
              <Accordion.Header style={{color: '#80d7cf'}}>
                <FontAwesomeIcon icon={solid('percent')} style={{ margin: '0 14px 0 3px' }} />
                Promotions
              </Accordion.Header>
              <Accordion.Body className="pe-2" style={{marginLeft: '1.6rem'}}>
                <Nav.Link
                  className={`${(isActiveRoute(pathname, '/promotions')) && 'active'}`}
                  as={Link}
                  to='/promotions'
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                >
                  Merchant
                </Nav.Link>
                <Nav.Link
                  className={`${(isActiveRoute(pathname, '/giftr_promotions')) && 'active'}`}
                  as={Link}
                  to='/giftr_promotions'
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                >
                  Giftr
                </Nav.Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        }
        {
          user && (user.role === 'merchant' || user.role === 'merchant-evouchers') &&
            <Nav.Link
              className={`${(isActiveRoute(pathname, '/promotions')) && 'active'}`}
              as={Link}
              to='/promotions'
            ><FontAwesomeIcon icon={solid('percent')} style={{margin: '0 14px 0 3px'}}/>
              Promotions
            </Nav.Link>
        }
        {
          user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'merchant-evouchers') &&
            <Nav.Link
              className={`${isActiveRoute(pathname, '/giftcards') && 'active'}`}
              as={Link}
              to="/giftcards"
            ><FontAwesomeIcon icon={solid('gift')} style={{margin: '0 11px 0 2px'}}/>
              Giftcards
            </Nav.Link>
        }
        {
          user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'supplier' || user.role === 'staff' || user.role === 'merchant-staff') &&
            <Nav.Link
              className={`${isActiveRoute(pathname, '/tickets') && 'active'}`}
              as={Link}
              to="/tickets"
            ><FontAwesomeIcon icon={solid('comments')} style={{marginRight: '9px'}}/>
              Q&amp;A
              {
                open_count > 0 &&
                  <div className="ms-2 notify-badge">
                    {open_count}
                  </div>
              }
            </Nav.Link>
        }
        {
          user && (user.role === 'admin' || user.role === 'staff') &&
            <Nav.Link
              className={`${isActiveRoute(pathname, '/merchants') && 'active'}`}
              as={Link}
              to="/merchants"
            ><FontAwesomeIcon icon={solid('users')} style={{marginRight: '9px'}}/>
              Merchants
            </Nav.Link>
        }
        { (country === 'MY' || (country === 'SG' || user.role === 'admin')) &&
        <Accordion className="nav-accordion" defaultActiveKey="0">
          <Accordion.Item style={{border:'none'}} eventKey="0">
            <Accordion.Header>
              <FontAwesomeIcon icon={solid('truck')} style={{marginRight: '9px'}}/>
              Shipments
            </Accordion.Header>
            <Accordion.Body className="pe-2" style={{marginLeft: '1.6rem'}}>
              <Nav.Link
                className={`${isActiveRoute(pathname, '/courier') && 'active'}`}
                as={Link}
                to='/courier'
                style={{padding: '0.2rem 0', fontSize: '0.9em'}}
              >
                Courier
                {
                  pickup_reminder_count > 0 &&
                    <div className="ms-2 notify-badge">
                      {pickup_reminder_count}
                    </div>
                }
              </Nav.Link>
              { country === 'MY' &&
                <Nav.Link
                  className={`${isActiveRoute(pathname, '/on_demand') && 'active'}`}
                  as={Link}
                  to="/on_demand"
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                >
                  On-Demand
                </Nav.Link>
              }
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        }
        {
          user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'merchant-evouchers' || user.role === 'staff') &&
            <Nav.Link
              className={`${isActiveRoute(pathname, '/bills') && 'active'}`}
              as={Link}
              to="/bills"
            ><FontAwesomeIcon icon={solid('receipt')} transform="grow-2" style={{margin: '0 15px 0 3px'}}/>
              Billings
            </Nav.Link>
        }
        {
          user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'merchant-evouchers' || user.role === 'staff' || user.role === 'merchant-staff') &&
            <Nav.Link
              className={`${isActiveRoute(pathname, '/reviews') && 'active'}`}
              as={Link}
              to="/reviews"
            ><FontAwesomeIcon icon={solid('star')} style={{marginRight: '12px'}}/>
              Reviews
            </Nav.Link>
        }
        {
          user && (user.role === 'admin' || user.role === 'staff' || user.role === 'merchant' || user.role === 'merchant-evouchers' || user.role === 'merchant-staff') &&
          <Accordion className="nav-accordion" defaultActiveKey="0">
            <Accordion.Item style={{border:'none'}} eventKey="0">
              <Accordion.Header style={{color: '#80d7cf'}}>
              <FontAwesomeIcon icon={solid('store')} style={{marginRight: '15px'}}/>
                Store Settings
              </Accordion.Header>
              <Accordion.Body className="pe-2" style={{marginLeft: '1.6rem'}}>
                {
                  user && (user.role === 'admin' || user.role === 'merchant' || user.role === 'merchant-evouchers' || user.role === 'staff' || user.role === 'merchant-staff') &&
                    <Nav.Link
                      className={`${(isActiveRoute(pathname, '/store')) && 'active'}`}
                      as={Link}
                      to='/store'
                      style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                    >
                      Store Profile
                    </Nav.Link>
                }
                <Nav.Link
                  className={`${isActiveRoute(pathname, '/off_days') && 'active'}`}
                  as={Link}
                  to="/off_days"
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                >
                  Off Days
                </Nav.Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

        }
        <Nav.Link
          className={`${isActiveRoute(pathname, '/profile') && 'active'}`}
          as={Link}
          to="/profile"
        ><FontAwesomeIcon icon={solid('user')} style={{margin: '0 14px 0 3px'}}/>
          My Account
        </Nav.Link>
        {
          user && (user.role === 'admin' || user.role === 'staff' || user.role === 'merchant' || user.role === 'merchant-staff') &&
            <Nav.Link
              className={`${isActiveRoute(pathname, '/help') && 'active'}`}
              as={Link}
              to="/help"
            ><FontAwesomeIcon icon={solid('question')} style={{margin: '0 16px 0 5px'}}/>
              Help
            </Nav.Link>
        }
        {
          user && (user.role === 'admin' || user.role === 'staff') &&
          <Accordion className="nav-accordion" defaultActiveKey="0">
            <Accordion.Item style={{border:'none'}} eventKey="0">
              <Accordion.Header style={{color: '#80d7cf'}}>
              <FontAwesomeIcon icon={solid('wrench')} style={{marginRight: '15px'}}/>
              Admin Tools
              </Accordion.Header>
              <Accordion.Body className="pe-2" style={{marginLeft: '1.6rem'}}>
              {
                user && user.role === 'admin' &&
                  <Nav.Link
                    className={`${isActiveRoute(pathname, '/users') && 'active'}`}
                    as={Link}
                    to="/users"
                    style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                  >
                    Users
                  </Nav.Link>
              }
                <Nav.Link
                  className={`${isActiveRoute(pathname, '/customers') && 'active'}`}
                  as={Link}
                  to="/customers"
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                >
                  Customers
                </Nav.Link>
                <Nav.Link
                  className={`${isActiveRoute(pathname, '/announcements') && 'active'}`}
                  as={Link}
                  to="/announcements"
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                >
                  Announcements
                </Nav.Link>
                <Nav.Link
                  className={`${isActiveRoute(pathname, '/logs') && 'active'}`}
                  as={Link}
                  to="/logs"
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                > Activity Log
                </Nav.Link>
                <Nav.Link
                  className={`${isActiveRoute(pathname, '/sync_error_logs') && 'active'}`}
                  as={Link}
                  to="/sync_error_logs"
                  style={{padding: '0.2rem 0', fontSize: '0.9em'}}
                > Sync Error Log
                </Nav.Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

        }
        <Nav.Link className={`${isActiveRoute(pathname, '/logout') && 'active'} logout-btn`}
          as={Link}
          to="/logout"
          ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="flip-h" style={{marginRight: '15px'}}/>
          Log Out
        </Nav.Link>
      </Nav>
  )
}

export default connect(state => {
  return {
    notifications: state.notifications
  }
})(Sidebar);
