import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TimelineActionButton extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired
  }

  render() {

    const { id, onButtonClick, title, icon, annTitle, desc, startDate, endDate } = this.props  

    return (
      <i className='timeline-button' aria-hidden="true" onClick={(e) => {

        if (onButtonClick) {
          onButtonClick(id, annTitle, desc, startDate, endDate);
        }

        

        }}>{icon} {title}</i>
    )
  }
}

export default TimelineActionButton;
