import qs from "qs";
import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_BILLINGS_REQUEST = 'FETCH_BILLINGS_REQUEST';
export const FETCH_BILLINGS_SUCCESS = 'FETCH_BILLINGS_SUCCESS';
export const FETCH_BILLINGS_FAILURE = 'FETCH_BILLINGS_FAILURE';
export const CLEAR_BILLINGS = 'CLEAR_BILLINGS';

function fetchBillingsRequest(merchantId, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date) {
  return {
    type: FETCH_BILLINGS_REQUEST,
    payload: {
      merchantId, 
      financial_status, 
      fulfillment_status, 
      limit, 
      createdAtMax,
      start_date,
      end_date
    }
  };
}

function fetchBillingsSuccess(json) { 
  return {
    type: FETCH_BILLINGS_SUCCESS,
    payload: {
      items: json,
      next: json.next,
      receivedAt: Date.now(),
    }
  };
}

function fetchBillingsFailure() {
  return {
    type: FETCH_BILLINGS_FAILURE
  };
}

function shouldFetchBillings(state) {
  const results = state.billings;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

function fetchBillingsFromApi(email, role, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date) {
  const queryString = qs.stringify({
    email, 
    role, 
    financial_status, 
    fulfillment_status, 
    limit, 
    createdAtMax,
    start_date,
    end_date
  }, { skipNulls: true });
  return dispatch => {
    dispatch(fetchBillingsRequest(email, role, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date));
    return api(`/bills?${queryString}`)
      .then(json => {
        dispatch(fetchBillingsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchBillingsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchBillings(email, role, financial_status, fulfillment_status, limit, createdAtMax, startDate, endDate) {
  return (dispatch, getState) => {
    if (shouldFetchBillings(getState())) {
      return dispatch(fetchBillingsFromApi(email, role, financial_status, fulfillment_status, limit, createdAtMax, startDate, endDate));
    }
  };
}

export function clearBillings() {
  return {
    type: CLEAR_BILLINGS,
  };
}