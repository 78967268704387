import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

function fetchCustomersRequest() {
  return {
    type: FETCH_CUSTOMERS_REQUEST,
  };
}

function fetchCustomersSuccess(json) {
  return {
    type: FETCH_CUSTOMERS_SUCCESS,
    payload: {
      items: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchCustomersFailure() {
  return {
    type: FETCH_CUSTOMERS_FAILURE
  };
}

function fetchCustomersFromApi(query) {
  return dispatch => {
    dispatch(fetchCustomersRequest());
    return api(`/customers/search?query=${query}`)
      .then(json => {
        dispatch(fetchCustomersSuccess(json));
      })
      .catch(err => {
        dispatch(fetchCustomersFailure());
        dispatch(addError(err));
      });
  };
}

function shouldFetchCustomers(state){
  const results = state.customers;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

export function searchCustomers(query){
  return (dispatch, getState) => {
    if (shouldFetchCustomers(getState())) {
      return dispatch(fetchCustomersFromApi(query));
    }
  };
}
