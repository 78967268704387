import { 
  FETCH_MERCHANTRATES_REQUEST, 
  FETCH_MERCHANTRATES_SUCCESS, 
  FETCH_MERCHANTRATES_FAILURE, 
  CLEAR_MERCHANTRATES,
} from '../actions/merchantRates';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
};

export default function merchantRates(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_MERCHANTRATES:
      return {
        ...state,
        defaultState
      };
    case FETCH_MERCHANTRATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_MERCHANTRATES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_MERCHANTRATES_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}