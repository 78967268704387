import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_TITLE_DECORATIONS_REQUEST = 'FETCH_TITLE_DECORATIONS_REQUEST';
export const FETCH_TITLE_DECORATIONS_SUCCESS = 'FETCH_TITLE_DECORATIONS_SUCCESS';
export const FETCH_TITLE_DECORATIONS_FAILURE = 'FETCH_TITLE_DECORATIONS_FAILURE';
export const CLEAR_TITLE_DECORATIONS = 'CLEAR_TITLE_DECORATIONS';

function fetchTitleDecorationsRequest() {
  return {
    type: FETCH_TITLE_DECORATIONS_REQUEST,
  };
}

function fetchTitleDecorationsSuccess(json) {
  return {
    type: FETCH_TITLE_DECORATIONS_SUCCESS,
    payload: {
      items: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchTitleDecorationsFailure() {
  return {
    type: FETCH_TITLE_DECORATIONS_FAILURE
  };
}

function shouldFetchTitleDecorations(state) {
  const results = state.titleDecorations;
  if (results && results.loading) {
    return false;
  } 
  else {
    return true;
  }
}

function fetchTitleDecorationsFromApi() {
  return dispatch => {
    dispatch(fetchTitleDecorationsRequest());
    return api('/lookup/title_decoration')
      .then(json => {
        dispatch(fetchTitleDecorationsSuccess(json));
      })
      .catch(err => {
        dispatch(fetchTitleDecorationsFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchTitleDecorations() {
  return (dispatch, getState) => {
    if (shouldFetchTitleDecorations(getState())) {
      return dispatch(fetchTitleDecorationsFromApi());
    }
  };
}

export function clearTitleDecorations() {
  return {
    type: CLEAR_TITLE_DECORATIONS,
  };
}