import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import { Modal, Button } from 'react-bootstrap';

class EditDeliveryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }
  }

  handleOnInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleOnClickEdit = () => {
    const { onClickEdit } = this.props;
    const {  deliveryDate } = this.state;
    this.setState({ isLoading: true });
    onClickEdit(deliveryDate);
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    const { isLoading } = this.state;
    if (!isLoading) {
      onClose();
    }
  }

  render() {
    const { onClose, onClickEdit, okLabel } = this.props;
    const { trackingNumber, trackingUrl, isLoading, deliveryDate } = this.state;
    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose}>
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0">Edit Delivery Date</h4>
        </Modal.Header>
        <Modal.Body className="mx-4 px-0 py-4 editDeliveryModal" style={{borderTop: '1px solid #dee2e6'}}>
          <form className="my-2">
            <SingleDatePicker
              small
              displayFormat="D/M/YY"
              date={deliveryDate} // momentPropTypes.momentObj or null
              onDateChange={date => this.setState({ deliveryDate: date })} // PropTypes.func.isRequired
              focused={this.state.focused} // PropTypes.bool
              onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
              enableOutsideDays={true}
              isOutsideRange={() => false}
              numberOfMonths={1}
              showDefaultInputIcon={true}
            />
          </form>
        </Modal.Body>
        <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
          <Button variant="danger-secondary" onClick={this.handleOnClose} disabled={isLoading}>Cancel</Button>
          <Button variant="primary" onClick={this.handleOnClickEdit} disabled={isLoading || !deliveryDate}>
            {isLoading ? "Loading..." : okLabel || "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditDeliveryModal;
