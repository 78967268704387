import React, { Component } from 'react';
import { connect } from 'react-redux'
import * as authActionCreators from '../redux/actions/auth';

class Logout extends Component {
  componentDidMount() {
    const { logout, history: { push } } = this.props;
    logout(push);
  }

  render() {
    return null;
  }
}

export default connect(null, authActionCreators)(Logout);
