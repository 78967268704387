import { 
  FETCH_MERCHANTS_REQUEST, 
  FETCH_MERCHANTS_SUCCESS, 
  FETCH_MERCHANTS_FAILURE, 
  CLEAR_MERCHANTS,
} from '../actions/merchants';

const defaultState = {
  loading: false,
  loaded: false,
  items: [],
};

export default function orders(state = defaultState, action) {
  switch (action.type) {
    case CLEAR_MERCHANTS:
      return {
        ...state,
        defaultState
      };
    case FETCH_MERCHANTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_MERCHANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        items: [...action.payload.items],
        lastUpdated: action.payload.receivedAt,
      };
    case FETCH_MERCHANTS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}