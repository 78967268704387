import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import Input from './Input';
import Loader from './Loader';
import config from '../config';
import Select from 'react-select';
import { components } from 'react-select';
import { DropdownIndicator } from 'react-select';
import noImage from '../images/no_image.jpg';
const { Option, SingleValue } = components;

class ManualSyncModal extends Component {
  constructor(props) {
    super(props);

    let {products, preselectedId} = props

    //sort products by title
    products = _.sortBy(products, product => {
      return product.title
    })

    //group products by tags where tags contains "sync_"
    let groupedProducts = _.groupBy(products, product => {
      let tags = product.tags
      if(tags && tags.length > 0){

        //split tags by comma
        tags = tags.split(',')

        //check if tags starts with "sync_"
        let syncTag = _.find(tags, tag => {
          //tag tolowercase and trim then check if it starts with "sync_"
          tag = tag.toLowerCase().trim()
          return tag.startsWith('sync_')
        })

        if(syncTag){
          return 'linked'
        }
      }
      return 'unlinked'
    })

    groupedProducts.linked = _.sortBy(groupedProducts.linked, product => {
      return product.title
    })

    groupedProducts.unlinked = _.sortBy(groupedProducts.unlinked, product => {
      return product.title
    })

    let options = []
    let linkedOptions = this.getOptions(groupedProducts.linked)
    let unlinkedOptions =  this.getOptions(groupedProducts.unlinked)

    //find the preselectedId in linkedOptions
    let preselectedOption = _.find(linkedOptions, option => {
      return option.value === preselectedId
    })

    if (!preselectedOption) {
      preselectedOption = _.find(unlinkedOptions, option => {
        return option.value === preselectedId
      })
    }

    options.push({
      label: "Unlinked",
      options:unlinkedOptions
    })

    options.push({
      label: "Linked",
      options: linkedOptions
    })

    let selectedId = undefined

    if(preselectedId !== undefined){
      selectedId = preselectedId
    }

    this.state = {
      options,
      selectedId,
      preselectedOption
    }
  }

  componentDidMount() {
    const { products } = this.state
  }

  getOptions = (products) => {

    return _.map(products, product => {

      //get product.image.src
      let src = noImage

      if(product.image && product.image.src){
        src = product.image.src
      }

      return {
        label: product.title,
        value: product.id,
        image: src
      }
    })
  }
  submit = () => {
    const { selectedId } = this.state
    const { onClickSave, sourceProduct } = this.props;

    onClickSave(sourceProduct.id, selectedId)
  }

  handleUnlink = () => {
    const { selectedId } = this.state
    const { onClickUnlink, sourceProduct } = this.props;

    onClickUnlink(sourceProduct.id, selectedId)
  }

  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const { error, title, products, sourceProduct, updating, preselectedId } = this.props
    const { options, preselectedOption } = this.state
    const IconOption = (props) => (

      <Option {... props}>
        <Row>
          <Col xs="auto">
            <img
              src={props.data.image}
              style={{"width": '30px'}}
            />
          </Col>
          <Col>
            <p>{props.data.label}</p>
          </Col>
        </Row>
      </Option>
    );

    const IconSingleValue = (props) => (
      <SingleValue {...props}>
        <img
              src={props.data.image}
              style={{"width": '30px', marginRight: '10px'}}
            />
        <span>{props.data.label}</span>
      </SingleValue>
    );


    let imageUrl = sourceProduct.images && sourceProduct.images.length > 0 ? sourceProduct.images[0].src : ''

    return (
      <Modal style={{top:'15%', border: 'none'}} show={true} onHide={this.handleOnClose} size="md">
        <Modal.Header className="mx-2 mt-1" style={{borderBottom: 'none'}} closeButton>
          <h4 className="mb-0">{title}</h4>
        </Modal.Header>
          <Modal.Body className="mx-4 px-0 py-4" style={{borderTop: '1px solid #dee2e6'}}>
            {
              error &&
              <Alert variant="danger">
                {
                  Array.isArray(error) ?
                  <ul>
                  {
                    error.map((err, i) => (
                      <li key={i}>
                        {err.msg}
                      </li>
                    ))
                  }
                  </ul>
                  :
                  error
                }
              </Alert>
            }
            <p>Select a product from your Giftr store associated with</p>
            {/* create a div with the image of the source product on the left and the title on the right */}
            <Row>
              <Col xs="auto">
                <img
                  src={imageUrl}
                  style={{"height": '80px', marginBottom: '10px'}}
                />
              </Col>
              <Col>
                <p>{sourceProduct.title}</p>
              </Col>
            </Row>

            <Row>
              <Col xs="auto">
              </Col>
            </Row>
            <Select
              isSearchable
              defaultValue={preselectedOption}
              onChange={(selected) => {
                  this.setState({selectedId: selected.value})
                }
              }
              components={{ DropdownIndicator: () => null, Option: IconOption, SingleValue: IconSingleValue }}
              options={options}
            />
          </Modal.Body>
          <Modal.Footer className="pt-0" style={{borderTop: 'none'}}>
            <Button
              variant="danger"
              onClick={this.handleUnlink}
              disabled={ updating || !this.state.selectedId }
            >
              {updating ? 'Updating' : 'Unlink'}
            </Button>
            <Button
              variant="warning"
              onClick={this.handleOnClose}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={this.submit}
              disabled={ updating || !this.state.selectedId }
            >
              {updating ? 'Updating' : 'Link'}
            </Button>
          </Modal.Footer>
      </Modal>
    );
  }
}

export default connect((state, props) => {
  const { match } = props;
  return {};
}, {
})(ManualSyncModal);
