import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash'
import moment from 'moment';
import api from '../helpers/apiClient';
import { Link } from 'react-router-dom';
import { Button, Badge, Accordion, Card, Row, Col, Container, Form } from 'react-bootstrap';
import Loader from '../components/Loader';
import MobileDetect from 'mobile-detect'
import ConfirmModal from '../components/ConfirmModal';
import * as billingsActionCreators from '../redux/actions/billings';
import { DateRangePicker,  DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import ReactTooltip from 'react-tooltip';
import config from '../config'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import PaymentDateModal from '../components/PaymentDateModal';
import TabHeader from '../components/TabHeader';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : 'S$'

const fulfillmentStatusOptions = {
  any: 'Any',
  fulfilled: 'Fulfilled',
  unfulfilled: 'Unfulfilled',
  partial: 'Partial'
};

const financialStatusOptions = {
  any: 'Any',
  authorized: 'Authorized',
  pending: 'Pending',
  paid: 'Paid',
  partially_paid: 'Partially Paid',
  refunded: 'Refunded',
  voided: 'Voided',
};

class Billings extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    let financialStatus = 'any';
    let fulfillmentStatus = 'any';
    let startDate, endDate, generateBillingPeriod, generatingBill, exportMonthlyBillName, exportingBill
    if (location.search) {
      const { financial_status, fulfillment_status } = qs.parse(location.search.substr(1));
      const queryString = qs.parse(location.search.substr(1));

      if (queryString.financial_status) {
        financialStatus = queryString.financial_status;
      }
      if (queryString.fulfillment_status) {
        fulfillmentStatus = queryString.fulfillment_status;
      }
      if (queryString.start_date) {
        startDate = moment(queryString.start_date, 'D-M-YY');
      }
      if (queryString.end_date) {
        endDate = moment(queryString.end_date, 'D-M-YY');
      }
    }

    this.state = {
      financialStatus,
      fulfillmentStatus,
      startDate,
      endDate,
      generateBillingPeriod,
      generatingBill,
      exportMonthlyBillName,
      startMonthName: '',
      endMonthName: '',
      exportingBill,
      showConfirmBillModal: false,
      selectedUnbilledIds: [],
      selectedBilledIds: [],
      showUpdatePaymentDateModal: false,
    };
  }

  componentDidMount() {
    const { fetchBillings, location, user } = this.props;
    const { merchantId, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date } = qs.parse(location.search.substr(1));
    const { email, role } = user
    fetchBillings(email, role, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date);
  }

  componentWillReceiveProps(nextProps) {
    const { location: prevLocation } = this.props;
    const { fetchBillings, location } = nextProps;
    if (location.search !== prevLocation.search) {
      const { merchantId, financial_status, fulfillment_status, limit, createdAtMax, start_date ,end_date} = qs.parse(location.search.substr(1));
      fetchBillings(merchantId, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date);
      this.setState({
        financialStatus: financial_status || 'any',
        fulfillmentStatus: fulfillment_status || 'any'
      });
    }
  }

  renderDetailLink = (cell, row) => {

    let pathname = row.is_v2_billing ? `/bills/${row._id}` : `/legacy_bills/${row._id}`

    return (
      <Link
        key={row.id}
        to={{
          pathname,
          state: {modal: false}
        }}
      >
        {row.name}
      </Link>
    )
  }

  renderInvoiceNumber = (cell, row) => {

    return (
      <a
        target="_blank"
        href={row.invoice_link}>
        {cell}
      </a>
    )
  }

  exportDetailLink = (cell, row) => {

    return row.name
  }

  exportTax = (cell, row) => {

    return row.gst
  }

  renderPrice = (cell, row) => {

    return <span>{currency} {cell ? cell.toFixed(2) : "0.00"}</span>
  }

  exportCommission = (cell, row) => {

    return row.commission
  }

  renderCommission = (cell, row) => {

    return <span>{currency} {row.commission.toFixed(2)}</span>
  }

  renderGst = (cell, row) => {

    return <span>{currency} {row.gst.toFixed(2)}</span>
  }

  renderShipping = (cell, row) => {

    return <span>{currency} {row.total_shipping.toFixed(2)}</span>
  }

  renderDiscount = (cell, row) => {

    return <span>{currency} {row.total_discount.toFixed(2)}</span>
  }

  renderStatus = (cell, row) => {

    return row.status === 'paid' ?
      <Badge bg="success" className="text-uppercase">{row.status}</Badge>
      :
      <Badge bg="warning" className="text-uppercase">{row.status}</Badge>
  }

  renderBillingDate = (options, onChange) => {

    const { generateBillingPeriod } = this.state;

    return (
      <select className="form-control" onChange={onChange}>
        <option key="1" value="" selected hidden>Please select billing period</option>
        {
          options.map(option => {
            return (<option key={option.format('YYYY-MM-DD')} value={option.format('YYYY-MM-DD')}>{option.format('MMM YYYY')}</option>)
          })
        }
      </select>
    )
  }

  renderBillName= (options, onChange) => {

    const { exportMonthlyBillName } = this.state;

    return (
      <select className="form-control" onChange={onChange}>
        <option key="1" value="" hidden>Select billing period</option>
        {
          options.map(option => {
            return (<option key={option.format('MMM YYYY')} value={option.format('MMM YYYY')}>{option.format('MMM YYYY')}</option>)
          })
        }
      </select>
    )
  }

  handleOnPeriodChange = (e) => {

    this.setState({
      generateBillingPeriod: e.target.value,
    });
  }

  handleOnExportBillNameChange = (e) => {

    this.setState({
      exportMonthlyBillName: e.target.value,
    });
  }

  handleGenerateBill = () => {
    const { generateBillingPeriod } = this.state;
    const { fetchBillings, location, user } = this.props;
    const { merchantId, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date } = qs.parse(location.search.substr(1));
    const { email, role } = user

    if(!generateBillingPeriod){
      return;
    }

    this.setState({
      generatingBill: true,
    });

    return api(`/billing/generate/${generateBillingPeriod}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      fetchBillings(email, role, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date);
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
      }
    });
  }

  handleExportMonthlyBill = () => {
    const { exportMonthlyBillName } = this.state;

    if(!exportMonthlyBillName){
      return;
    }

    this.setState({
      exportingBill: true,
    });

    return api(`/bills/export/month`, {
      method: 'POST',
      body: JSON.stringify({'billName': exportMonthlyBillName})
    }, {'Accept': 'application/json', 'Content-Type': 'application/json'}).then((result) => {

      if(result.url){
        window.location.href = result.url;
      }

      this.setState({
        exportingBill: false,
      });
    }).catch(err => {
      if (err) {
        const { addError } = this.props;
        if (err.validationError) {
          addError(err.validationError);
        }
        else if (err.message) {
          addError(err.message);
        }
        else {
          addError(err);
        }
      }
    });
  }

  onRowSelect = (row, isSelected) => {

    let { selectedUnbilledIds, selectedBilledIds } = this.state;
    let { billings: { unbilledRows, billedRows } } = this.props;

    if(isSelected){
      if(_.some(unbilledRows, u => u === row._id)){
        selectedUnbilledIds.push({id: row._id});
      }

      if(_.some(billedRows, u => u === row._id)){
        selectedBilledIds.push({
          id: row._id,
          vendor: row.vendor,
          name: row.name,
        });
      }
    } else {
      selectedUnbilledIds = _.reject(selectedUnbilledIds, {id: row._id})
      selectedBilledIds = _.reject(selectedBilledIds, {id: row._id})
    }

    this.setState({ selectedUnbilledIds, selectedBilledIds });
  }

  onSelectAll = (isSelected, rows) => {
    let { billings: { unbilledRows, billedRows } } = this.props;

    let selectedUnbilledIds = [];
    let selectedBilledIds = [];

    if(isSelected){
      for (let i = 0; i < rows.length; i++){
        if(_.some(unbilledRows, u => u === rows[i]._id)){
          selectedUnbilledIds.push({id: rows[i]._id});
        }

        if(_.some(billedRows, u => u === rows[i]._id)){
          selectedBilledIds.push({
            id: rows[i]._id,
            vendor: rows[i].vendor,
            name: rows[i].name,
          });
        }
      }
    }

    this.setState({ selectedUnbilledIds, selectedBilledIds });

    if(!isSelected){
      return [];
    }
  }

  handleOnClickConfirmBill = () => {
    this.setState({ showConfirmBillModal: true });
  }

  handleOnCloseConfirmBillModal = () => {
    this.setState({ showConfirmBillModal: false });
  }

  handleConfirmBills = () => {

    const { selectedUnbilledIds } = this.state;

    let ids = _.map(selectedUnbilledIds, (bill) => {
      return bill.id
    })

    let body = { bill_ids : ids }

    return api(`/bills/bulk_confirm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    }).then((result) => {
      this.setState({ loading: false, showConfirmBillModal: false, selectedUnbilledIds: [], selectedBilledIds: [] });
      alert("Confirming bills... this may take up to a couple minutes, please refresh the page in a moment")
      this.componentDidMount()
    }).catch(err => {
      this.setState({ loading: false });
      if (err) {
        window.scrollTo(0, 0);
        alert(err.message);
      }
    });

  }

  handleStartPeriodChange = e => {
    let { endMonthName } = this.state;
    const { value } = e.target;

    if(endMonthName !== '' && moment(endMonthName, 'MMM YYYY').isBefore(moment(value, 'MMM YYYY'))){
      endMonthName = value
    } else if (endMonthName === ''){
      endMonthName = value
    }

    this.setState({
      endMonthName,
      startMonthName: value,
    });
  }

  handleEndPeriodChange = e => {
    let { startMonthName } = this.state;
    const { value } = e.target;

    if(startMonthName !== '' && moment(startMonthName, 'MMM YYYY').isAfter(moment(value, 'MMM YYYY'))){
      startMonthName = value
    } else if (startMonthName === ''){
      startMonthName = value
    }

    this.setState({
      startMonthName,
      endMonthName: value,
    });
  }

  handleResetFilter = () => {
    this.setState({
      startMonthName: '',
      endMonthName: '',
    });
  }

  handleOnClickFilter = () => {
    const { fetchBillings, user } = this.props;
    const { email, role } = user
    const { startMonthName, endMonthName } = this.state;
    let { financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date } = qs.parse(location.search.substr(1));

    if(startMonthName && endMonthName){
      let start_date = moment(startMonthName, 'MMM YYYY').format()
      let end_date = moment(endMonthName, 'MMM YYYY').format()

      fetchBillings(email, role, financial_status, fulfillment_status, limit, createdAtMax, start_date, end_date);
    }
  }

  handleOnClickUpdatePaymentDate = () => {
    this.setState({ showUpdatePaymentDateModal: true });
  }

  handleOnCloseShowPaymentDateModal = () => {
    this.setState({ showUpdatePaymentDateModal: false });
  }

  handleSetPaymentDate = async (payment_date) => {
    if(!payment_date){
      return
    }
    const { selectedBilledIds } = this.state;

    let bill_ids = _.map(selectedBilledIds, (bill) => {
      return bill.id
    })

    return api('/bills/bulk_payment_date' , {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ bill_ids, payment_date }),
    }).then(result => {
      if(result.success){
        this.setState({ showUpdatePaymentDateModal: false, selectedBilledIds: [], selectedUnbilledIds: [] });
        this.componentDidMount();
      } else {
        console.log(result.error);
      }
    }).catch(err =>{
      console.log(err)
    })
  }

  render() {
    const { billings: { loading, loaded, items, first_bill_month, next, unselectableRows }, location: { pathname, search, routeState }, previousRoutes, previousPreviousRoutes, previousRoutePathName, previousRouteSearch, user } = this.props;
    const { fulfillmentStatus, financialStatus, generatingBill, exportingBill, showConfirmBillModal, startMonthName, endMonthName, selectedUnbilledIds, selectedBilledIds, showUpdatePaymentDateModal } = this.state;

    const isAdmin = user.role === 'admin'
    const isStaff = user.role === 'staff'

    let firstBillMonth = moment(first_bill_month, 'MMM YYYY')
    let historicalRange = moment().diff(firstBillMonth, 'months')

    let period = []
    for(let i = 1; i <= historicalRange; i++){
      let month = moment().subtract(i, 'months')
      period.push(month)
    }

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      // nonSelectable: unselectableRows,
      headerColumnStyle: { width: '30px' },
    }

    const options = {
      showTotal: true,
      sizePerPage: 20,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '25', value: 25
      }, {
        text: '50', value: 50
      }, {
        text: 'All', value: items.length
      }],
    };

    const columns = [
      {
        dataField: '_id',
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        csvText: 'name',
        csvFormatter: this.exportDetailLink,
        formatter: this.renderDetailLink,
        text: 'Bill',
      },
      {
        dataField: 'name',
        hidden: true,
        text: 'Name',
        headerStyle: {
          resize: 'horizontal'
        },
        csvExport: false,
      },
      {
        dataField: 'status',
        sort: true,
        formatter: this.renderStatus,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Status',
      },
      {
        dataField: 'vendor',
        sort: true,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Merchant',
      },
      {
        dataField: 'item_price',
        sort: true,
        text: 'Total Item Price',
        formatter: this.renderPrice,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'order_count',
        sort: true,
        text: 'No. of Orders',
        hidden: !isAdmin,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
      },
      {
        dataField: 'total_discount',
        sort: true,
        formatter: this.renderDiscount,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'Total Discount',
      },
      {
        dataField: 'commission',
        sort: true,
        text: 'Total Commission',
        formatter: this.renderCommission,
        csvFormatter: this.exportCommission,
        headerStyle: {
          width: '140px',
          resize: 'horizontal'
        },
        headerFormatter: () => {
          return (
            <>
              <span data-tip="Included Commission Adjust. (if any)">Total Commission <i className="fa fa-info-circle"></i></span><ReactTooltip />
            </>
          )
        }
      },
      {
        dataField: 'gst',
        sort: true,
        formatter: this.renderGst,
        csvFormatter: this.exportTax,
        headerStyle: {
          width: '100px',
          resize: 'horizontal'
        },
        text: 'Total SST',
        headerFormatter: () => {
          return (
            <>
              <span data-tip="Included SST Adjust. (if any)">Total SST <i className="fa fa-info-circle"></i></span><ReactTooltip />
            </>
          )
        }
      },
      {
        dataField: 'total_shipping',
        sort: true,
        formatter: this.renderShipping,
        headerStyle: {
          width: '120px',
          resize: 'horizontal'
        },
        text: 'Total Shipping',
      },
      {
        dataField: 'balance',
        sort: true,
        formatter: this.renderPrice,
        headerStyle: {
          width: '125px',
          resize: 'horizontal'
        },
        text: 'Total Payout Bal.',
      },
      {
        dataField: 'invoice_number',
        formatter: this.renderInvoiceNumber,
        sort: true,
        headerStyle: {
          width: '135px',
          resize: 'horizontal'
        },
        text: 'Invoice Number',
      },
      {
        dataField: 'invoice_link',
        hidden: true,
        sort: true,
        text: 'Invoice Link',
      },
      {
        dataField: 'bank_name',
        hidden: true,
        sort: true,
        text: 'Bank Name',
      },
      {
        dataField: 'acc_no',
        hidden: true,
        sort: true,
        text: 'Account Number',
      },
      {
        dataField: 'acc_name',
        hidden: true,
        sort: true,
        text: 'Account Name',
      },
      {
        dataField: 'swift_code',
        hidden: true,
        sort: true,
        text: 'Swift Code',
      },
      {
        dataField: 'email',
        hidden: true,
        sort: true,
        text: 'Email',
      },
    ];

    return (
      <div>
        <TabHeader
          title="Billings"
          tab_id="billings"
          user={user}
          children={
            <>
              {
                (isAdmin || isStaff) &&
                <div className="col-xs-12 col-sm-9 col-lg-6 col-xl-5">
                  <Row className="justify-content-md-end">
                    <Col xs={7}>{this.renderBillName(period, this.handleOnExportBillNameChange)}</Col>
                    <Col xs="auto"><Button variant="primary" onClick={this.handleExportMonthlyBill}>{exportingBill ? "Generating" : "Export Bill" }</Button></Col>
                  </Row>
                </div>
              }
            </>
          }
        />
        <Accordion style={{borderRadius: '18px'}} className="dashboard-card shadow-sm" defaultActiveKey="0">
          <Accordion.Item style={{borderRadius: '18px', border: 'none'}} eventKey="0">
            <Accordion.Header><h5 style={{marginBottom: '0'}}>Filters</h5></Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <div className="col-sm-9">
                  <div className="row">
                    <div className="col-xs-12 col-sm-5 col-xl-4 mb-3 mb-sm-3">
                      <Form.Select value={startMonthName} className="form-control" onChange={this.handleStartPeriodChange}>
                        <option key="1" value="" hidden>Select start month</option>
                        {
                          period.map(option => {
                            return (<option key={option.format('MMM YYYY')} value={option.format('MMM YYYY')}>{option.format('MMM YYYY')}</option>)
                          })
                        }
                      </Form.Select>
                    </div>
                    <div className="col-xs-12 col-sm-5 col-xl-4 mb-3 mb-sm-3">
                      <Form.Select value={endMonthName} className="form-control" onChange={this.handleEndPeriodChange}>
                        <option key="1" value="" hidden>Select end month</option>
                        {
                          period.map(option => {
                            return (<option key={option.format('MMM YYYY')} value={option.format('MMM YYYY')}>{option.format('MMM YYYY')}</option>)
                          })
                        }
                    </Form.Select>
                    </div>
                  </div>
                </div>

                <div className="filter-buttons col-sm-3 d-flex flex-sm-column flex-lg-row justify-content-between my-2 mb-auto">
                <Button
                    className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                    variant="secondary"
                    style={{width: '100%'}}
                    onClick={this.handleResetFilter}
                  >Reset
                  </Button>
                <Button
                    className="apply-filter col-xs-6 mx-2 mb-2 mb-lg-0"
                    variant="primary"
                    style={{width: '100%'}}
                    onClick={this.handleOnClickFilter}
                  >Apply
                  </Button>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {
          !loading && (isAdmin || isStaff) &&
          <Row className="my-3 justify-content-end">
            <Col sm="auto">
              <Button className="btn-br-6" variant="primary" disabled={selectedBilledIds.length === 0} onClick={this.handleOnClickUpdatePaymentDate}>Update Payment Date for {selectedBilledIds.length} bill(s)</Button>
            </Col>
            <Col sm="auto">
              <Button className="btn-br-6" variant="primary" disabled={selectedUnbilledIds.length === 0} onClick={this.handleOnClickConfirmBill}>Confirm {selectedUnbilledIds.length} bill(s)</Button>
            </Col>
          </Row>
        }
        {
          loading &&
          <Loader />
        }
        {
          !loading && items &&
            <ToolkitProvider
              keyField='_id'
              data={items}
              columns={columns}
              exportCSV={{
                exportAll: false
              }}
              search
            >
              {
                props => (
                  <>
                    <Container fluid className="mb-3 mt-4 d-flex gap-3 flex-row flex-wrap">
                      <Col>
                        { !isStaff &&
                        <ExportCSVButton
                          className="btn-info"
                          style={{borderRadius:'8px'}}
                          { ...props.csvProps }
                        ><FontAwesomeIcon icon={solid('arrow-right-from-bracket')} transform="rotate--90" style={{marginRight: '15px'}}/>
                          Export to CSV</ExportCSVButton>
                        }
                      </Col>
                      <Col xs={4}>
                        <SearchBar { ...props.searchProps }
                        style={{borderRadius:'25px'}} placeholder="Search"/>
                      </Col>
                    </Container>
                    <BootstrapTable
                      { ...props.baseProps }
                      bordered={ false }
                      selectRow={selectRowProp}
                      pagination={paginationFactory(options)}
                      hover
                      defaultSorted={[{
                        dataField: '_id',
                          order: 'desc',
                      }]}
                    />
                  </>
                )
              }
            </ToolkitProvider>
        }
        {
          showConfirmBillModal &&
          <ConfirmModal onClickYes={this.handleConfirmBills} onClose={this.handleOnCloseConfirmBillModal} title="Confirm Bills">
            <p>Confirm {selectedUnbilledIds.length} bills?</p>
          </ConfirmModal>
        }
        {
          showUpdatePaymentDateModal &&
          <PaymentDateModal bills={selectedBilledIds} onClose={this.handleOnCloseShowPaymentDateModal} onClickSave={this.handleSetPaymentDate}/>
        }
      </div>
    );
  }
}

export default connect((state, props) => {
  const { pathname, search, routeState } = props.location;
  let previousRoutePathName;
  let previousRouteSearch;
  if (routeState && routeState.previousRoutes && routeState.previousRoutes.length) {
    const previousRoute = routeState.previousRoutes[routeState.previousRoutes.length - 1];
    previousRoutePathName = previousRoute.indexOf('?') > -1 ? previousRoute.substr(0, previousRoute.indexOf('?')) : previousRoute;
    previousRouteSearch = previousRoute.indexOf('?') > -1 ? previousRoute.substr(previousRoute.indexOf('?')) : null;
  }
  return {
    user: state.auth.user,
    billings: state.billings,
    previousRoutePathName,
    previousRouteSearch,
    previousRoutes: (routeState && routeState.previousRoutes && routeState.previousRoutes.length) ? [...routeState.previousRoutes, `${pathname}${search}`] : [`${pathname}${search}`],
    previousPreviousRoutes: (routeState && routeState.previousRoutes && routeState.previousRoutes.length) ? routeState.previousRoutes.slice(0, routeState.previousRoutes.length - 1) : null
  };
}, billingsActionCreators)(Billings);
