import { addError } from './error';
import api from '../../helpers/apiClient';

export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';

function fetchCustomerRequest() {
  return {
    type: FETCH_CUSTOMER_REQUEST,
  };
}

function fetchCustomerSuccess(json) {
  return {
    type: FETCH_CUSTOMER_SUCCESS,
    payload: {
      item: json,
      receivedAt: Date.now(),
    }
  };
}

function fetchCustomerFailure() {
  return {
    type: FETCH_CUSTOMER_FAILURE
  };
}

function shouldFetchCustomer(state) {
  const results = state.customer;
  if (results && results.loading) {
    return false;
  }
  else {
    return true;
  }
}

export function fetchCustomerFromApi(_id) {
  return dispatch => {
    dispatch(fetchCustomerRequest(_id));
    return api(`/customers/${_id}`)
      .then(json => {
        dispatch(fetchCustomerSuccess(json));
      })
      .catch(err => {
        dispatch(fetchCustomerFailure());
        dispatch(addError(err));
      });
  };
}

export function fetchCustomer(_id) {
  return (dispatch, getState) => {
    if (shouldFetchCustomer(getState())) {
      return dispatch(fetchCustomerFromApi(_id));
    }
  };
}
