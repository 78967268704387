import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert , Row, Col, Container} from 'react-bootstrap';
import _ from 'lodash'
import * as errorActionCreators from '../redux/actions/error';
import * as previewProductActionCreators from '../redux/actions/previewProduct';
import ProductPreviewMessage from '../components/ProductPreviewMessage';
import minusCircle from '../images/minus-circle.svg'
import plusCircle from '../images/plus-circle.svg'
import dropHint from "../images/drop-a-hint.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'

const currency = window.location.hostname === 'seller.giftr.my' ? 'RM' : '$'

class CourierTemplate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            topRatedSeller: false,
            shipOnTime: false,
            selfPickUp: false,
            enableVM: false,
            containsBia: false,
            removeNationwide: false,
            emailDelivery: false,
            deliveryIncludedShown: false,
            nextDayDelivery: false,
            sameDayDelivery: false,
            exclusive: false,
            logo_halal: false,
            enable_ann: false,
            enable_postcode: false,
            includeCardMessage: true,
            hideDefaultTitle: false,
            topRatedRating: '',
            shipOnTimeRating: '',
            lastSplit: '',
            dropdowndesc: '',
            addOnsList: [],
            inputList: [],
            selectedOption1: null,
            selectedOption2: null,
            selectedOption3: null,
            hasAddOns: false,
            addOnsTotal: [],
            selectedVariant: null,
            scrollX: null,
            prevValue: 0,
            previewImage: null,
            removePmg: false,
            enableQna: false,
        }
    }

    componentDidMount() {
        const { previewProduct: { item } } = this.props;
        let options = item.options
        let firstVariant = item.variants[0]
        let imageList = item.images

        this.setState({
            removePmg: item.remove_pmg,
            enableQna: item.enable_qna
        })

        if (firstVariant.inventory_management == 'shopify' && firstVariant.inventory_quantity <= 0){
            this.setState({
                selectedVariant: null
            })
        }
        else{
            this.setState({
                selectedVariant: firstVariant.id
            })
        }

        if (item.variants.length == 1 && item.variants[0].title.includes('Default')){
            this.setState({
                hideDefaultTitle: true
            })
        }

        this.setState({
            selectedOption1: options[0].values[0],
        })

        if (options.length == 3){
            this.setState({
                selectedOption2: options[1].values[0],
                selectedOption3: options[2].values[0],
            })
        }
        else if (options.length == 2){
            this.setState({
                selectedOption2: options[1].values[0],
            })
        }

        if (firstVariant.image_id) {
            _.map(imageList, (image) => {
                if (firstVariant.image_id == image.id){
                    this.setState({
                        previewImage: image.src
                    })
                }
            })
        } else {
            this.setState({
                previewImage: imageList[0].src
            })
        }

        this.checkTags(item);

        if (item.hasOwnProperty('metafields')){
            this.checkMetafields(item);
        }
    }

    getFirstAvailableVariant = (obj, criteria) => {
        let firstAvailable;

        for(let i = 0; i < obj.length; i++){
            if (criteria) {
                if (obj[i].option1 == criteria){
                    if (obj[i].inventory_management == 'shopify' && obj[i].inventory_quantity <= 0){
                        continue;
                    }
                    else{
                        firstAvailable = obj[i];
                        break;
                    }
                }
            }
            else {
                if (obj[i].inventory_management == 'shopify' && obj[i].inventory_quantity <= 0){
                    continue;
                }
                else{
                    firstAvailable = obj[i];
                    break;
                }
            }
        }

        return firstAvailable
    }

    handleOnInputChange = (e) => {
        const { previewProduct } = this.props;
        const value = e.target.value;
        this.setState({
            selectedVariant: value,
        });

        _.map(previewProduct.item.variants, (variant) => {
            if (variant.id == value){
                _.map(previewProduct.item.images, (image) => {
                    if (image.id == variant.image_id){
                        this.setState({
                            previewImage: image.src
                        })
                    }
                })
            }
        })
    }


    handleDDADDON = (e) => {
        const input = e.target.value;
        const { addOnsTotal } = this.state

        let inputArray = input.split('_')
        let isInside = false;

        if (addOnsTotal.length > 0){    //for subsequent adds
            for (let i = 0; i < addOnsTotal.length; i++){
                if(addOnsTotal[i].index == inputArray[0]){
                    isInside = true;
                    break;
                }
            }
            if (isInside){
                _.map(addOnsTotal, (addOn, i) => {
                    if (addOn.index == inputArray[0]){
                        this.setState(({addOnsTotal}) => ({
                            addOnsTotal: [
                                ...addOnsTotal.slice(0,i),
                                {
                                    ...addOnsTotal[i],
                                    selectedAddOn: inputArray[1],
                                },
                                ...addOnsTotal.slice(i+1)
                            ]
                        }))
                    }
                })
            }
            else {
                this.setState({
                    addOnsTotal: [
                        ...addOnsTotal,
                        {
                            index: inputArray[0],
                            selectedAddOn: inputArray[1]
                        }
                    ]
                })
            }
        }
        else {  //for first add
            this.setState({
                hasAddOns: true,
                addOnsTotal: [
                    {
                        index: inputArray[0],
                        selectedAddOn: inputArray[1]
                    }
                ]
            })
        }

    }

    handleCBADDON = (e, ) => {
        const input = e.target.value;
        const isChecked = e.target.checked;
        const { addOnsTotal } = this.state
        let inputArray = input.split('_');
        let isInside = false;

        if (addOnsTotal.length > 0){    //for subsequent adds
            for (let i = 0; i < addOnsTotal.length; i++){
                if(addOnsTotal[i].index == inputArray[0]){
                    isInside = true;
                    break;
                }
            }
            if (isInside && isChecked){ //if is inside and new
                _.map(addOnsTotal, (addOn, i) => {
                    if (addOn.index == inputArray[0]){
                        this.setState({
                            addOnsTotal: [
                                ...addOnsTotal.slice(0,i),
                                {
                                    ...addOnsTotal[i],
                                    selectedAddOn: [
                                        ...addOnsTotal[i].selectedAddOn,
                                        inputArray[1]
                                    ],
                                },
                                ...addOnsTotal.slice(i+1)
                            ]
                        })
                    }
                })

            }
            else if (isInside && !isChecked) {  //when unchecked
                _.map(addOnsTotal, (addOn, i) => {
                    if (addOn.index == inputArray[0]){
                        for (let n=0; n < addOn.selectedAddOn.length; n++){
                            if (addOn.selectedAddOn[n] == inputArray[1]){
                                addOnsTotal[i].selectedAddOn.splice(n,1);
                                this.setState({
                                    addOnsTotal: [
                                        ...addOnsTotal.slice(0,i),
                                        {
                                            ...addOnsTotal[i],
                                            selectedAddOn: [
                                                ...addOnsTotal[i].selectedAddOn
                                            ],
                                        },
                                        ...addOnsTotal.slice(i+1)
                                    ]
                                })
                                break;
                            }
                        }
                    }
                })
            }
            else {
                this.setState({
                    addOnsTotal: [
                        ...addOnsTotal,
                        {
                            index: inputArray[0],
                            selectedAddOn: [
                                inputArray[1]
                            ]
                        }
                    ]
                })
            }
        }
        else {  //for first add
            this.setState({
                hasAddOns: true,
                addOnsTotal: [
                    {
                        index: inputArray[0],
                        selectedAddOn: [
                            inputArray[1]
                        ]
                    }
                ]
            })
        }
    }

    renderAddOnSpan = (total) => {
        if (total.length > 0) {
            let sum = total.map(a => Array.isArray(a.selectedAddOn) ? a.selectedAddOn.map(b => parseInt(b)).reduce((b, c) => b + c, 0) : parseInt(a.selectedAddOn)).reduce((a, b) => a + b, 0)
            if (sum !== 0){
                return (
                    <div className="add-ons-info">
                        <span style={{color: '#777'}}>Selection will add {currency}{sum} to the final price</span>
                    </div>
                )
            }

        }
    }

    handleOnVariantChange = (e) => {
        const input = e.target.value;
        const { selectedOption1, selectedOption2, selectedOption3 } = this.state
        const { previewProduct } = this.props;

        let itemOptions = previewProduct.item.options
        let item = previewProduct.item

        let inputArray = input.split('_')
        if (inputArray[0] == itemOptions[0].id){
            _.map(itemOptions[0].values, (value) => {
                if (inputArray[1] == value){
                    for (let i=0; i < item.variants.length; i++){
                        if (item.variants[i].option1 == inputArray[1]){
                            this.setState({
                                selectedOption1: inputArray[1],
                            })
                            this.checkCombination(item, inputArray[1], selectedOption2, selectedOption3)
                            return;
                        }
                    }
                }
            })


        }
        else if (inputArray[0] == itemOptions[1].id){
            _.map(itemOptions[1].values, (value) => {
                if (inputArray[1] == value){
                    for (let i=0; i < item.variants.length; i++){
                        if (item.variants[i].option2 == inputArray[1]){
                            this.setState({
                                selectedOption2: inputArray[1],
                            })
                            this.checkCombination(item, selectedOption1, inputArray[1], selectedOption3)
                            return;
                        }
                    }
                }
            })


        }
        else {
            _.map(itemOptions[2].values, (value) => {
                if (inputArray[1] == value){
                    for (let i=0; i < item.variants.length; i++){
                        if (item.variants[i].option3 == inputArray[1]){
                            this.setState({
                                selectedOption3: inputArray[1],
                            })
                            this.checkCombination(item, selectedOption1, selectedOption2, inputArray[1])
                            return;
                        }
                    }
                }
            })

        }
    }

    checkCombination = (obj, option1, option2, option3) => {
        this.setState({
            selectedVariant: null
        })
        _.map(obj.variants, (variant) => {
            if (variant.option1 == option1 && variant.option2 == option2 && variant.option3 == option3){
                if (variant.inventory_management == 'shopify' && variant.inventory_quantity <= 0){
                    this.setState({
                        selectedVariant: null
                    })
                }
                else {
                    this.setState({
                        selectedVariant: variant.id
                    })
                    _.map(obj.images, (image) => {
                        if (variant.image_id == image.id){
                            this.setState({
                                previewImage: image.src
                            })
                        }
                    })
                }
                return
            }
        })
    }

    checkMetafields = (item) => {
        let addOns = [];
        let inputs = [];

        for(let i = 0; i < item.metafields.length; i++){
            if (item.metafields[i].namespace.toLowerCase().includes('add_ons')){
                addOns.push(item.metafields[i]);
            }
            if (item.metafields[i].namespace.toLowerCase().includes('input')){
                inputs.push(item.metafields[i]);
            }
        }

        this.setState({
            addOnsList: [
                ...addOns
            ],
            inputList: [
                ...inputs
            ]
        })
    }

    checkTags = (item) => {
        let tagsList = item.tags.split(', ');
        let tagsListLower = tagsList.map(tags => tags.toLowerCase());

        for(let i = 0; i < tagsListLower.length; i++){
            if (tagsListLower[i].includes('top-rated')){
                const topRatedTag = (tagsList[i].split('_'));
                this.setState({
                    topRatedSeller: true,
                    topRatedRating: topRatedTag[topRatedTag.length - 1]
                })
            }
            if (tagsListLower[i].includes('ship-on-time')){
                const shipTag = (tagsListLower[i].split('_'));
                this.setState({
                    shipOnTime: true,
                    shipOnTimeRating: shipTag[shipTag.length - 1]
                })
            }
            if (tagsListLower[i].includes('self')){
                this.setState({
                    selfPickUp: true,
                    containsBia: true
                })
            }
            if (tagsListLower[i].includes('exclude-free-message')){
                this.setState({
                    includeCardMessage: false,
                })
            }
            if (tagsListLower[i].includes('enable_vm')){
                this.setState({
                    enableVM: true,
                })
            }
            if (tagsListLower[i].includes('remove-nationwide')){
                this.setState({
                    removeNationwide: true,
                })
            }
            if (tagsListLower[i].includes('email-delivery')){
                this.setState({
                    emailDelivery: true,
                })
            }
            if (tagsListLower[i].includes('bia') || tagsListLower[i].includes('delivery-included')){
                if (tagsListLower[i].includes('bia')){
                    this.setState({
                        containsBia: true
                    })
                }
                this.setState({
                    deliveryIncludedShown: true
                })
                if (tagsListLower[i].includes('bia-1')){
                    this.setState({
                        nextDayDelivery: true
                    })
                }
                if (tagsListLower[i].includes('bia-0')){
                    this.setState({
                        sameDayDelivery: true
                    })
                }


            }
            if (tagsListLower[i].includes('exclusive')){
                this.setState({
                    containsBia: true,
                    exclusive: true,
                })
            }
            if (tagsListLower[i].includes('logo_halal')){
                this.setState({
                    logo_halal: true
                })
            }
            if (tagsListLower[i].includes('enable_ann')){
                this.setState({
                    enable_ann: true
                })
            }
            if (tagsListLower[i].includes('enable_postcode')){
                this.setState({
                    enable_postcode: true
                })
            }
        }
    }

    renderTextInput = (list, listLower, text) => {
        let words = [];
        return (
            _.map(listLower, (key, i) => {
                if (key.includes(text)){
                    words = list[i].split('_');
                    return (
                    <div key={i} className="selector-wrapper">
                        <label className="product-extra-fields-label">{words[1]}</label>
                        <p style={{margin: '0'}}>{words[3]}</p>
                        {
                            text == 'textfield' ?
                            <input className="preview-form-control additional-customization" maxLength={words[2]}/>
                            :
                            <textarea className="preview-form-control additional-customization" maxLength={words[2]}/>
                        }
                        <span className="message-char-count">{words[2]} Characters Max</span>
                    </div>
                        )


                }
            })
        )
    }

    renderCheckbox = (list, listLower) => {
        let words = [];
        let count = 0;
        return (
            _.map(listLower, (key, i) => {
                if (key.includes('checkbox')){
                    words = list[i].split('_');
                    return (
                        <div key={i} className="selector-wrapper">
                            <label className="product-extra-fields-label">{words[1]} (Pick up to {words[2]} options)</label>
                            <label style={{marginBottom: "0"}}>
                                <div className="custom_check">
                                    {
                                        _.map(words, (option, m) => {
                                            if (m > 2) {
                                                if (count == 5){
                                                    count = 0;
                                                    return (
                                                        <br/>
                                                    )
                                                }
                                                count += 1;
                                                return (
                                                    <label key={m} className="custom-checkbox">{option}
                                                        <input value={option} type="checkbox" onClick={this.handleClick}/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    )
                                            }
                                        })
                                    }
                                </div>
                            </label>
                        </div>
                    )
                }
            })
        )
    }

    renderUpload = (list, listLower, text) => {
        let words = [];
        return (
            _.map(listLower, (key, i) => {
                if (text == 'imageupload'){ //imageupload
                    if (key.includes(text)){
                        words = list[i].split('_');
                        return (
                            <div key={i} className="selector-wrapper">
                                <label className="product-extra-fields-label">{words[1]}</label>
                                <div style={{position: "relative", maxWidth: "50%"}}>
                                    <button style={{margin: "0 0 4px 3px"}}>Upload Image Here</button>
                                </div>
                                <p style={{margin: "0"}}><span style={{paddingLeft: "3px"}}>Only image file in .jpg and .png | Skip this step if no image upload required.</span></p>

                            </div>
                        )
                    }
                }
                else if (text == 'multiupload'){  //multiupload
                    if (key.includes(text)){
                        words = list[i].split('_');
                        return (
                            <div key={i} className="selector-wrapper">
                                <label className="product-extra-fields-label">{words[1]}</label>
                                <div style={{position: "relative", maxWidth: "50%"}}>
                                    <button style={{margin: "0 0 4px 3px"}}>Add Images</button>
                                </div>
                                <p style={{margin: "0"}}><span style={{paddingLeft: "3px"}}>Click image to remove | Skip this step if no image upload required.</span></p>
                            </div>
                        )
                    }
                }
                else {  //audioupload
                    if (key.includes('audioupload')){
                        words = list[i].split('_');
                        return (
                            <div key={i} className="selector-wrapper">
                                <label className="product-extra-fields-label">{words[1]}</label>
                                <div style={{position: "relative", maxWidth: "50%"}}>
                                    <button style={{margin: "0 0 4px 3px"}}>Upload Audio Here</button>
                                </div>
                                <p style={{margin: "0"}}><span style={{paddingLeft: "3px"}}>Maximum audio file size is 10 MB | Skip this step if no audio upload required.</span></p>
                            </div>
                        )
                    }
                }
            })
        )
    }

    renderDropdown = (list, listLower) => {
        let words = [];
        let hasLastSplit = false;
        let lastSplit = '';
        let dropdowndesc = '';

        return (
            _.map(listLower, (key, i) => {
                if (key.includes('dropdown')){
                    words = list[i].split('_');
                    if (words.includes('@')){
                        hasLastSplit = true;
                        lastSplit = words[-1].split('@');
                        if (lastSplit.length > 1){
                            dropdowndesc = lastSplit[1];
                        }
                    }
                    return (
                        <div key={i} className="selector-wrapper">
                            <label className="product-extra-fields-label">{words[1]}</label>
                            <p style={{margin: '0'}}>{dropdowndesc}</p>
                            <select className="preview-select">
                                {
                                    _.map(words, (option, m) => {
                                        return (
                                            (m > 1) ?
                                                (!hasLastSplit && m !== words.length) ?
                                                <option key={m} value={option}>{option}</option>
                                                : ""
                                            : ""
                                        )
                                    })
                                }
                            </select>
                        </div>
                        )
                }
            })
        )
    }

    handleGalleryImage = (key) => {
        const {scrollX, prevValue} = this.state
        let scrollValue = 0

        if (key > prevValue){   //forward
            scrollValue = (key - prevValue) * -10
        }
        else {  //backward
            scrollValue = (prevValue - key) * 10
        }

        this.setState({
            scrollX: scrollX + scrollValue,
            prevValue: key
        })

    }

    render() {
        const { previewProduct } = this.props;
        const { selectedVariant, topRatedSeller, shipOnTime, selfPickUp, enableVM, containsBia, removeNationwide, emailDelivery, deliveryIncludedShown, nextDayDelivery, sameDayDelivery, exclusive, logo_halal, enable_ann, enable_postcode, topRatedRating, shipOnTimeRating, includeCardMessage, addOnsList, inputList, hideDefaultTitle, hasAddOns, addOnsTotal, scrollX, previewImage, removePmg, enableQna } = this.state;
        let words = [];
        let inputListLower = [];
        let inputListValues = [];
        let optionSplit = [];

        let tagsListLower, tagsList;

        tagsList = previewProduct.item.tags.split(', ')
        tagsListLower = tagsList.map(tags => tags.toLowerCase());

        _.map(inputList, (input) => {
            inputListValues = [
                ...inputListValues,
                input.value
            ];
            inputListLower = [
                ...inputListLower,
                input.value.toLowerCase()
            ]
        })


        return (
            <div>

                <div className="preview-container">
                <div className="row" style={{textAlign: 'center',}}>
                    <Alert variant="warning">
                    <span>This is a preview of your product.</span>
                    </Alert>
                </div>
                    <Container>
                    <Row className="inner-preview">
                        {/*Begin product image*/}
                        <Col xs={12} md={5} id="product-image">
                            {
                                previewProduct.item.images.length > 0 &&
                                <div className="product-image-inner row justify-content-center" style={{textAlign: 'center'}}>
                                    {/* Main Image */}
                                    <img style={{height: 'auto', width: '80%', marginTop: '20px'}} src={previewImage} alt={previewProduct.item.title} />
                                    {/* Gallery  */}
                                    <div className="more-view-image">
                                        <div className='gallery-container' style={{transform: `translateX(${scrollX}em)`, transition: '0.3s ease-in-out'}}>
                                            {
                                                _.map(previewProduct.item.images, (image, key) => {
                                                    return(
                                                        <div key={key} style={{width: '160px', height: '160px', marginRight: '10px'}}>
                                                            <img key={image.id} src={image.src} style={{maxHeight: '100%', maxWidth:'160px', width: 'auto'}}/>
                                                        </div>
                                                        )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <Row className="justify-content-center mt-1">
                                        {
                                            _.map(previewProduct.item.images, (image, key) => {
                                                return(
                                                    <Col xs="auto" key={image.id} className="dot-container">
                                                        <label>
                                                            {
                                                                key == 0 ?
                                                                <input type="radio" name="radio" onClick={() => this.handleGalleryImage(key)} defaultChecked/>
                                                                : <input type="radio" name="radio" onClick={() => this.handleGalleryImage(key)}/>
                                                            }
                                                            <span className="image-dot"></span>
                                                        </label>
                                                    </Col>
                                                    )
                                            })
                                        }

                                    </Row>
                                </div>
                            }
                        </Col>
                        {/*Begin product info*/}
                        <Col xs={12} md={7} id="product-info" className="product-info" style={{marginBottom: '50px'}}>
                            <h1>{previewProduct.item.title}</h1>
                            <p className="vendorname d-flex justify-content-between">
                                {previewProduct.item.vendor}
                                { enableQna &&
                                <a style={{cursor: "pointer"}} id="have-question-cta">
                                    <FontAwesomeIcon icon={regular('question-circle')} style={{ color: '#80d7cf' }} />
                                    <span>Ask Vendor a Question</span>
                                </a>
                                }
                            </p>
                            {
                                (previewProduct.item.vendor == 'Hallmark' || previewProduct.item.vendor == 'Famous Amos' || previewProduct.item.vendor == 'Tanamera' || previewProduct.item.vendor == 'MBG Fruitshop' || previewProduct.item.vendor == 'Balloon Buzz Party Center' || previewProduct.item.vendor == 'Eat Cake Today') &&
                                <img className="icon-tag" style={{width:'150px',height:'30px',marginBottom:'7px'}} src="https://cdn.shopify.com/s/files/1/1428/2106/files/OFFICIAL_STORE.png?12660125916732699385" alt="OFFICIAL STORE"/>
                            }

                            <div className="price-wrapper">
                                <div className="product-price">
                                    {
                                        selectedVariant ?
                                        _.map(previewProduct.item.variants, (variant) => {
                                            if (variant.id == selectedVariant){
                                                return (
                                                    currency + variant.price.toFixed(2)
                                                )
                                            }
                                        })

                                        :
                                        'Unavailable'
                                    }
                                </div>
                            </div>

                            <div className="row icon-tag-wrapper mx-auto mx-md-0">
                                {
                                    topRatedSeller &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                            <img className="icon-tag" src="https://cdn.shopify.com/s/files/1/1428/2106/files/top_rated_seller_badge.png?v=1587484150" alt="Top Rated seller" />
                                            </Col>
                                            <Col className="p-0">
                                            <span>Top-rated Seller ({topRatedRating}/5 ★)</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    shipOnTime &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                            <img className="icon-tag" src="https://cdn.shopify.com/s/files/1/1428/2106/files/clock_1.png?v=1599759587" alt="Ship on Time" />
                                            </Col>
                                            <Col className="p-0">
                                            <span>Ship on Time ({shipOnTimeRating}/5 ★)</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    includeCardMessage && !enableVM &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                            <img className="icon-tag" src="https://cdn.shopify.com/s/files/1/1428/2106/files/Free_Message_Card.png?2178164599429980147" alt="Free Message Card" />
                                            </Col>
                                            <Col className="p-0">
                                            <span>Free Message Card</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    deliveryIncludedShown &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                            <img className="icon-tag" src="https://cdn.shopify.com/s/files/1/1428/2106/files/Delivery_Included.png?2178164599429980147" alt="Delivery Included" />
                                            </Col>
                                            <Col className="p-0">
                                            <span>Delivery Included</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    nextDayDelivery &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                            <img className="icon-tag" src="https://cdn.shopify.com/s/files/1/1428/2106/files/Next_Day_Delivery.png?2178164599429980147" alt="Next Day Delivery" />
                                            </Col>
                                            <Col className="p-0">
                                            <span>Next Day Delivery</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    sameDayDelivery &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                            <img className="icon-tag" src="https://cdn.shopify.com/s/files/1/1428/2106/files/Same_Day_Delivery_v2.png?2971215347942944182" alt="Same Day Delivery" />
                                            </Col>
                                            <Col className="p-0">
                                            <span>Same Day Delivery</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    exclusive &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                            <img className="icon-tag" src="https://cdn.shopify.com/s/files/1/1428/2106/files/Exclusive.png?2178164599429980147" alt="Exclusive" />
                                            </Col>
                                            <Col className="p-0">
                                            <span>Exclusive</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    logo_halal &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                                <img className="icon-tag" src="https://cdn.shopify.com/s/files/1/1428/2106/files/Halal.png?v=1580833974" alt="Halal Certified" />
                                            </Col>
                                            <Col className="p-0">
                                                <span>Halal Certified</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    selfPickUp &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                                <img className="icon-tag" src="https://cdn.shopify.com/s/files/1/1428/2106/files/Self_Pick-up_Available_v2.png?2971215347942944182" alt="Self Pick-up Available" />
                                            </Col>
                                            <Col className="p-0">
                                                <span>Self Pick-up Available</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    emailDelivery &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                                <img className="icon-tag" src="https://cdn.shopify.com/s/files/1/2116/9129/files/Email_Delivery_Icon_2dfad5e9-72d0-4196-91b9-81bd5a99dbde.png?v=1638955057" alt="Email Delivery" />
                                            </Col>
                                            <Col className="p-0">
                                                <span>Email Delivery</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    !containsBia && !removeNationwide &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                                <img className="icon-tag" src="https://cdn.shopify.com/s/files/1/1428/2106/files/Express_Courier_V2.png?2971215347942944182" alt="Nationwide Courier Delivery" />
                                            </Col>
                                            <Col className="p-0">
                                                <span>Nationwide Courier Delivery</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    enableVM &&
                                    <div className="col-xs-12 col-md-6">
                                        <Row className="align-items-center px-2">
                                            <Col xs="auto" className="p-0 pe-1">
                                                <img className="icon-tag" style={{padding: '2px'}} src="//cdn.shopify.com/s/files/1/1428/2106/files/vm-icon.png?v=1645147135" alt="Free Personalised Message Card" />
                                            </Col>
                                            <Col className="p-0">
                                                <span>Free Personalised Message Card</span>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </div>
                            {
                                enable_ann &&
                                <div className="row">
                                    <iframe className="col-sm-12 col-md-7" height="115px" width="100%" src={`https://app.giftr.my/announcement/${previewProduct.item.vendor}`} style={{border: 'none', paddingLeft: '0px', paddingRight: '0px'}}></iframe>
                                </div>
                            }
                            {
                                enable_postcode &&
                                <div className="row">
                                    <iframe className="col-sm-12 col-md-8" height="180px" width="100%" src={`https://app.giftr.my/postcode/${previewProduct.item.vendor}`} style={{border: 'none', paddingLeft: '0px', paddingRight: '0px'}}></iframe>
                                </div>
                            }
                            <div className="group-cw">
                                {
                                    previewProduct.item.options.length > 1 ?    //Products with > 1 options
                                        _.map(previewProduct.item.options, (option, i) => {
                                            return (
                                                <div key={option.id} className="selector-wrapper">
                                                    <label className="preview-label">{option.name}</label>
                                                    <select className="preview-select" onChange={this.handleOnVariantChange}>
                                                        {
                                                            _.map(option.values, (value, m) => {
                                                                return(
                                                                    <option key={m} value={option.id + '_' + value}>{value}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                )
                                        })
                                    : !hideDefaultTitle &&
                                        <div className="selector-wrapper">
                                            <label className="preview-label">{previewProduct.item.options[0].name}</label>
                                            <select className="preview-select" onChange={this.handleOnInputChange}>
                                                {
                                                    _.map(previewProduct.item.variants, (variant) => {

                                                        return (
                                                            (variant.inventory_management == 'shopify' && variant.inventory_quantity <= 0) ?
                                                            <option key={variant.option1} value={null}>{variant.option1}</option>
                                                            :
                                                            <option key={variant.option1} value={variant.option1}>{variant.option1}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>

                                }
                                {   //Text Field Tag
                                    this.renderTextInput(tagsList, tagsListLower, 'textfield')
                                }
                                {   //Text Area Tag
                                    this.renderTextInput(tagsList, tagsListLower, 'textarea')
                                }
                                {  //Dropdown Tag
                                    this.renderDropdown(tagsList, tagsListLower)
                                }
                                {  //Dropdown Metafield
                                    this.renderDropdown(inputListValues, inputListLower)
                                }
                                {   //Checkbox Tag
                                    this.renderCheckbox(tagsList, tagsListLower)
                                }
                                {   //ImageUpload
                                    this.renderUpload(tagsList, tagsListLower, 'imageupload')
                                }
                                {   //MultiUpload
                                    this.renderUpload(tagsList, tagsListLower, 'multiupload')
                                }
                                {   //AudioUpload
                                    this.renderUpload(tagsList, tagsListLower, 'audioupload')
                                }
                                {   //AddOn Metafields
                                     _.map(addOnsList, (addOn, i) => {
                                         if (addOn.value.toLowerCase().includes('ddaddon')) {   //DDADDON
                                            let words = addOn.value.split('_');
                                            return (
                                                <div key={i} className="selector-wrapper">
                                                <label className="product-extra-fields-label">{words[1]}*</label>
                                                        <select className="preview-select" onChange={this.handleDDADDON}>
                                                        <option value={i  + '_' + 0}>Choose One</option>
                                                        {
                                                            _.map(words, (option, m) => {
                                                                if (m > 1) {
                                                                    optionSplit = option.split('#');
                                                                    return (
                                                                        <option key={m} value={i +'_'+ optionSplit[1]}>{optionSplit[0]} | RM{optionSplit[1]}</option>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        </select>
                                                </div>
                                            )
                                         }
                                         if (addOn.value.toLowerCase().includes('cbaddon')) {   //CBADDON
                                            let words = addOn.value.split('_');
                                            return (
                                                <div key={i} className="selector-wrapper">
                                                    <label className="product-extra-fields-label">{words[1]} (Pick up to {words[2]} options)</label>
                                                    <label style={{marginBottom: "0"}}>
                                                        <div className="custom_check">
                                                            {
                                                                _.map(words, (option, m) => {
                                                                    if (m > 2) {
                                                                        optionSplit = option.split('#');
                                                                        return (
                                                                            <label key={m} className="custom-checkbox">{optionSplit[0]} | RM{optionSplit[1]}
                                                                                <input value={i +'_'+ optionSplit[1]} type="checkbox" onClick={(e) => this.handleCBADDON(e, m)} />
                                                                                <span className="checkmark"></span>
                                                                                <br/>
                                                                            </label>
                                                                            )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </label>
                                                </div>
                                            )
                                         }
                                     })
                                }
                                {
                                    includeCardMessage &&
                                    <div className="row">
                                    <ProductPreviewMessage></ProductPreviewMessage>
                                    </div>
                                }
                                {
                                    hasAddOns &&
                                    this.renderAddOnSpan(addOnsTotal)
                                }
                            </div>
                            <hr/>
                            {/* Add To Cart  */}
                            <div style={{marginBottom: '15px'}}>
                                <div>
                                    <div className="quantity">
                                    <img src={minusCircle}></img>
                                    <span style={{textAlign: 'center', padding: '12px 20px', width: '120px', height: '42px', minHeight: '42px', color: '#777'}}>1</span>
                                    <img src={plusCircle}></img>
                                    </div>
                                    <div className="action-button">
                                    <button id="add-to-cart" className="btn btn-default">Add To Cart</button>
                                    </div>
                                </div>
                                <img style={{width: '20px', height:'20px', float:'left', marginRight:'3px'}} src="https://cdn.shopify.com/s/files/1/1428/2106/files/information.png?7688014946902365522"></img>
                                <span style={{color: '#81d8d0'}}>Click here to order in bulk</span>
                            </div>
                            <div className="row">
                                { !removePmg &&
                                <div className="col-xs-10 col-md-4 col-sm-4" style={{color: '#777'}}>
                                <img style={{width: '25px', height:'25px', float:'left', paddingRight: '4px'}} src="https://cdn.shopify.com/s/files/1/2678/8018/files/price-tag_6.png?14112712024095282610"></img>
                                Price Match Guarantee
                                </div>
                                }
                                <div className="col-xs-8 col-md-4 col-sm-4" style={{color: '#777'}}>
                                <img style={{width: '25px', height:'25px', float:'left', paddingRight: '4px'}} src="https://cdn.shopify.com/s/files/1/2678/8018/files/credit-card.png?14112712024095282610"></img>
                                Secured Payments
                                </div>
                                <div className="col-xs-8 col-md-4 col-sm-4" style={{color: '#777'}}>
                                <img style={{width: '25px', height:'25px', float:'left', paddingRight: '4px'}} src="https://cdn.shopify.com/s/files/1/2678/8018/files/gift_3.png?14112712024095282610"></img>
                                Best Curated Gifts
                                </div>
                            </div>
                            <hr/>
                            <img className="drop-a-hint" src={dropHint}></img>
                            <div style={{color: '#777', marginTop: '10px'}}>
                                Availability:
                                {
                                    selectedVariant ?
                                    <span style={{color: '#80d7cf'}}> In Stock</span>
                                    :
                                    <span style={{color: '#80d7cf'}}> Out of Stock</span>
                                }
                                <hr style={{margin: '10px 0'}}/>
                                Type: <span style={{color: '#80d7cf'}}>{previewProduct.item.product_type}</span>
                                <hr style={{margin: '10px 0'}}/>
                            </div>
                        </Col>
                        {/* Description  */}
                        <Col xs={12}>
                            <h4 className="product-desc-reviews" style={{fontFamily: 'Playfair Display, serif'}}>Description</h4>
                            <div className="desc-items" style={{color: '#777'}} dangerouslySetInnerHTML={{__html:previewProduct.item.body_html}}></div>
                        </Col>
                    </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default connect((state, props) => {
    return {
      error: state.error,
      user: state.auth.user,
      previewProduct: state.previewProduct
    };
  }, {
    ...errorActionCreators,
    ...previewProductActionCreators
  })(CourierTemplate);

