import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { DayPickerSingleDateController } from 'react-dates';

const PreviewSelectDelivery = ({allowedDates, blockedDates, deliveryDays, bia, ubia, self}) => {
  const [selected, setSelected] = useState(null)

  const isOutsideRange = day => {
    let startDate = moment()

    if(allowedDates && !deliveryDays){
      if (ubia && ubia.days_in_advance){
        startDate.add(ubia.days_in_advance, 'days')
      }

      return (
        dateNotFound(allowedDates, day) ||
        day.isBefore(startDate, 'day') ||
        day.isAfter(moment().add(60, "days"))
      );
    }

    if(!allowedDates && deliveryDays){
      const weekDay = _.lowerCase(moment.weekdaysShort(day.weekday()))

      if(bia && bia.days_in_advance){
        startDate.add(bia.days_in_advance, 'days')
      }

      return (
        day.isBefore(startDate, 'day') ||
        day.isAfter(moment().add(60, "days")) ||
        (deliveryDays && _.findIndex(deliveryDays, d => d === weekDay) === -1) ||
        (blockedDates && _.some(blockedDates, d => d.isSame(day, "day")))
      );
    }
  }

  const isDayHighlighted = day => {
    let startDate = moment()

    if(allowedDates && !deliveryDays){
      if (ubia && ubia.days_in_advance){
        startDate.add(ubia.days_in_advance, 'days')
      }

      return (
        dateNotFound(allowedDates, day) ||
        day.isBefore(startDate, 'day') ||
        day.isAfter(moment().add(60, "days"))
      )
    }

    if(!allowedDates && deliveryDays){
      const weekDay = _.lowerCase(moment.weekdaysShort(day.weekday()))

      if(bia && bia.days_in_advance){
        startDate.add(bia.days_in_advance, 'days')
      }

      return (
        day.isBefore(startDate, 'day') ||
        day.isAfter(moment().add(60, "days")) ||
        (deliveryDays && _.findIndex(deliveryDays, d => d === weekDay) === -1) ||
        (blockedDates && _.some(blockedDates, d => d.isSame(day, "day")))
      )
    }
  }

  const dateNotFound = (range, date) => {
    return !range.some(d => d.isSame(date, 'day'))
  }

  return (
    <div className="row preview-delivery-dates calendar-fixed-height" >
      <div className='col'>
        <span>Preview: </span>
        <p className="mb-0">{self ? 'Select Self Pick-up/Delivery Date' : 'Select Delivery Date'}</p>
        <DayPickerSingleDateController
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          isDayHighlighted={isDayHighlighted}
          isOutsideRange={isOutsideRange}
          onDateChange={date => {
            setSelected(date)
          }}
          date={selected}
        />
        <input className="form-control" value={selected ? moment(selected).format('DD/MM/YYYY') : ''} type="text" placeholder="Please select delivery date" readOnly disabled/>
        <span className='small' style={{color: '#5A5A5A'}}>*By default, customer can select delivery date up to 60 days ahead only.</span>
      </div>
      <div className='col'></div>
    </div>
  )
}

export default PreviewSelectDelivery
