import { addError } from './error';
import api from '../../helpers/apiClient';

export const SET_PREVIEWPRODUCT = 'SET_PREVIEWPRODUCT';


export function setPreviewProduct(productData){
  return (dispatch, getState) => {
    return dispatch({
      type: SET_PREVIEWPRODUCT, 
      payload: {
        item: productData
      }})
  }
}